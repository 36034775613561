import { ChevronLeft, Close } from "@mui/icons-material";
import {
  Avatar,
  Box,
  ClickAwayListener,
  Drawer,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import { BusinessProfileClick } from "helpers/businessHelpers/businessProfileClick";
import React from "react";
import { useDispatch } from "react-redux";

function SearchSideDrawer({
  isSearchSidebarOpen,
  setIsSearchSidebarOpen,
  primary,
  primaryLight,
  primaryDark,
  backgroundAlt,
  neutralLight,
  isLargeScreen,
  searchTerm,
  handleSearch,
  searchResults,
  navigate,
  user,
  page,
  setPage,
  totalPages,
  handleAddToSearchHistory,
  searchHistory,
  handleRemoveSearchHistoryItem,
}) {
  const dispatch = useDispatch();
  const isFriend = (searchedUserId) => {
    return user.friends.some((friend) => friend._id === searchedUserId);
  };

  function truncateText(text, maxLength) {
    // Remove HTML tags
    const strippedText = text.replace(/<[^>]*>?/gm, "");

    // Truncate and add ellipsis if necessary
    return strippedText.length > maxLength
      ? strippedText.substring(0, maxLength) + "..."
      : strippedText;
  }

  // Wrap Drawer with ClickAwayListener only when it is open
  const drawerContent = (
    <Drawer
      open={isSearchSidebarOpen}
      onClose={() => setIsSearchSidebarOpen(false)}
      variant="persistent"
      anchor="left"
      sx={{
        width: "350px",
        "& .MuiDrawer-paper": {
          // color: lightSecondary,
          backgroundColor: backgroundAlt,
          boxSizing: "border-box",
          borderWidth: isLargeScreen ? 0 : "2px",
          width: isLargeScreen ? "31%" : "98%",
          top: "75px", // start below the navbar
          height: "calc(100vh - 2rem)", // fill the remaining space (100% - 2rem)
          border: `2px solid ${primary}`,
          borderRadius: "0 10px 10px 0",
        },
      }}
    >
      <Box width="100%">
        <Box margin="0.5rem 1rem">
          <FlexBetween color={primary}>
            <IconButton onClick={() => setIsSearchSidebarOpen(false)}>
              <ChevronLeft />
            </IconButton>
            <Box
              backgroundColor={neutralLight}
              borderRadius="9px"
              gap="3rem"
              padding="0.1rem 1.5rem"
              width="100%"
            >
              <InputBase
                placeholder="Search She is Action"
                value={searchTerm}
                onChange={(e) => {
                  handleSearch(e);
                  setPage(1);
                }}
                fullWidth
              />
            </Box>
          </FlexBetween>

          {/* SEARCH HISTORY */}

          {searchTerm === "" && searchHistory?.length > 0 && (
            <Typography
              variant="h6"
              sx={{ marginTop: "1rem", marginBottom: "0.5rem" }}
            >
              Search History
            </Typography>
          )}

          {searchTerm === "" && (
            <List>
              {searchHistory?.map((item) => (
                <ListItem key={item._id} sx={{ padding: "0.25rem 0" }}>
                  {item.type === "User" && (
                    <ListItemButton
                      onClick={() => {
                        navigate(`/profile/${item.refId._id}`);
                        setIsSearchSidebarOpen(false);
                        handleAddToSearchHistory(
                          user._id,
                          "User",
                          item.refId._id
                        );
                      }}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "0",
                        backgroundColor: neutralLight,
                        borderRadius: "25px",
                        "&:hover": {
                          backgroundColor: primaryLight,
                          color: primaryDark,
                        },
                      }}
                    >
                      <Avatar
                        src={
                          item.refId.picturePath.endsWith("/public")
                            ? item.refId.picturePath.replace(
                                "/public",
                                "/128x128"
                              )
                            : `${item.refId.picturePath}/128x128`
                        }
                        sx={{ width: "40px", height: "40px" }}
                      />
                      <Box sx={{ width: "80%", paddingLeft: "0.5rem" }}>
                        <ListItemText
                          primary={
                            item.refId.firstName + " " + item.refId.lastName
                          }
                          sx={{
                            fontWeight: 500,
                            marginBottom: "0",
                            marginTop: "0",
                          }}
                        />
                        <ListItemText
                          secondary="Fellow sports person"
                          secondaryTypographyProps={{ fontSize: "10px" }}
                          sx={{ marginBottom: "0", marginTop: "0" }}
                        />
                      </Box>
                    </ListItemButton>
                  )}

                  {item.type === "Group" && (
                    <ListItemButton
                      onClick={() => {
                        navigate(`/groups/${item.refId._id}`);
                        setIsSearchSidebarOpen(false);
                        handleAddToSearchHistory(
                          user._id,
                          "Group",
                          item.refId._id
                        );
                      }}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "0",
                        backgroundColor: neutralLight,
                        borderRadius: "25px",
                        "&:hover": {
                          backgroundColor: primaryLight,
                          color: primaryDark,
                        },
                      }}
                    >
                      <Avatar
                        src={
                          item.refId.image.endsWith("/public")
                            ? item.refId.image.replace("/public", "/128x128")
                            : `${item.refId.image}/128x128`
                        }
                        sx={{ width: "40px", height: "40px" }}
                      />
                      <Box sx={{ width: "80%", paddingLeft: "0.5rem" }}>
                        <ListItemText
                          primary={item.refId.name}
                          sx={{
                            fontWeight: 500,
                            marginBottom: "0",
                            marginTop: "0",
                          }}
                        />
                        <ListItemText
                          secondary="GROUP"
                          secondaryTypographyProps={{ fontSize: "10px" }}
                          sx={{ marginBottom: "0", marginTop: "0" }}
                        />
                      </Box>
                    </ListItemButton>
                  )}

                  {item.type === "Brand" && (
                    <ListItemButton
                      onClick={() => {
                        navigate(`/brand/${item.refId._id}`);
                        setIsSearchSidebarOpen(false);
                        handleAddToSearchHistory(
                          user._id,
                          "Brand",
                          item.refId._id
                        );
                        BusinessProfileClick(dispatch, {
                          brandId: item.refId._id,
                          fromPage: "searchHistory",
                        });
                      }}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "0",
                        backgroundColor: neutralLight,
                        borderRadius: "25px",
                        "&:hover": {
                          backgroundColor: primaryLight,
                          color: primaryDark,
                        },
                      }}
                    >
                      <Avatar
                        src={
                          item.refId.logoPath
                            ? item.refId.logoPath.endsWith("/public")
                              ? item.refId.logoPath.replace(
                                  "/public",
                                  "/128x128"
                                )
                              : `${item.refId.logoPath}/128x128`
                            : item.refId.picturePath.endsWith("/public")
                            ? item.refId.picturePath.replace(
                                "/public",
                                "/128x128"
                              )
                            : `${item.refId.picturePath}/128x128`
                        }
                        sx={{ width: "40px", height: "40px" }}
                      />
                      <Box sx={{ width: "80%", paddingLeft: "0.5rem" }}>
                        <ListItemText
                          primary={item.refId.name}
                          sx={{
                            fontWeight: 500,
                            marginBottom: "0",
                            marginTop: "0",
                          }}
                        />
                        <ListItemText
                          secondary="BUSINESS"
                          secondaryTypographyProps={{ fontSize: "10px" }}
                          sx={{ marginBottom: "0", marginTop: "0" }}
                        />
                      </Box>
                    </ListItemButton>
                  )}

                  {item.type === "Article" && (
                    <ListItemButton
                      onClick={() => {
                        navigate(`/news/article/${item.refId._id}`);
                        setIsSearchSidebarOpen(false);
                        handleAddToSearchHistory(
                          user._id,
                          "Article",
                          item.refId._id
                        );
                      }}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "0",
                        backgroundColor: neutralLight,
                        borderRadius: "25px",
                        "&:hover": {
                          backgroundColor: primaryLight,
                          color: primaryDark,
                        },
                      }}
                    >
                      <Avatar
                        src={
                          item.refId.titleImage.endsWith("/public")
                            ? item.refId.titleImage.replace(
                                "/public",
                                "/128x128"
                              )
                            : `${item.refId.titleImage}/128x128`
                        }
                        sx={{ width: "40px", height: "40px" }}
                      />
                      <Box sx={{ width: "80%", paddingLeft: "0.5rem" }}>
                        <ListItemText
                          primary={item.refId.title}
                          sx={{
                            fontWeight: 500,
                            marginBottom: "0",
                            marginTop: "0",
                          }}
                        />
                        <ListItemText
                          secondary="ARTICLE"
                          secondaryTypographyProps={{ fontSize: "10px" }}
                          sx={{ marginBottom: "0", marginTop: "0" }}
                        />
                      </Box>
                    </ListItemButton>
                  )}
                  {/* Close Icon Button */}
                  <IconButton
                    onClick={() =>
                      handleRemoveSearchHistoryItem(
                        user._id,
                        item.type,
                        item.refId._id
                      )
                    }
                    size="small"
                    sx={{
                      marginLeft: "auto", // To align it to the right
                      marginRight: "0.5rem", // For some spacing
                    }}
                  >
                    <Close />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          )}

          {/* USERS */}

          {searchResults?.users?.length > 0 && (
            <Typography
              variant="h6"
              sx={{ marginTop: "1rem", marginBottom: "0.5rem" }}
            >
              Users
            </Typography>
          )}

          <List>
            {searchTerm !== "" &&
              searchResults?.users?.map((searchedUser) => (
                <ListItem key={searchedUser._id} sx={{ padding: "0.25rem 0" }}>
                  <ListItemButton
                    onClick={() => {
                      navigate(`/profile/${searchedUser._id}`);
                      setIsSearchSidebarOpen(false);
                      handleAddToSearchHistory(
                        user._id,
                        "User",
                        searchedUser._id
                      );
                    }}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0",
                      backgroundColor: neutralLight,
                      borderRadius: "25px",
                      "&:hover": {
                        backgroundColor: primaryLight,
                        color: primaryDark,
                      },
                    }}
                  >
                    <Avatar
                      src={
                        searchedUser.picturePath.endsWith("/public")
                          ? searchedUser.picturePath.replace(
                              "/public",
                              "/128x128"
                            )
                          : `${searchedUser.picturePath}/128x128`
                      }
                      sx={{ width: "40px", height: "40px" }}
                    />
                    <Box sx={{ width: "80%", paddingLeft: "0.5rem" }}>
                      <ListItemText
                        primary={
                          searchedUser.firstName + " " + searchedUser.lastName
                        }
                        sx={{
                          fontWeight: 500,
                          marginBottom: "0",
                          marginTop: "0",
                        }}
                      />
                      <ListItemText
                        secondary={
                          isFriend(searchedUser._id) ? "Friend" : undefined
                        }
                        secondaryTypographyProps={{ fontSize: "10px" }}
                        sx={{ marginBottom: "0", marginTop: "0" }}
                      />
                    </Box>
                  </ListItemButton>
                </ListItem>
              ))}
          </List>

          {/* GROUPS */}

          {searchResults?.groups?.length > 0 && (
            <Typography
              variant="h6"
              sx={{ marginTop: "1rem", marginBottom: "0.5rem" }}
            >
              Groups
            </Typography>
          )}

          <List>
            {searchTerm !== "" &&
              searchResults?.groups?.map((searchedGroup) => (
                <ListItem key={searchedGroup._id} sx={{ padding: "0.25rem 0" }}>
                  <ListItemButton
                    onClick={() => {
                      navigate(`/groups/${searchedGroup._id}`);
                      setIsSearchSidebarOpen(false);
                      handleAddToSearchHistory(
                        user._id,
                        "Group",
                        searchedGroup._id
                      );
                    }}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0",
                      backgroundColor: neutralLight,
                      borderRadius: "25px",
                      "&:hover": {
                        backgroundColor: primaryLight,
                        color: primaryDark,
                      },
                    }}
                  >
                    <Avatar
                      src={
                        searchedGroup.image.endsWith("/public")
                          ? searchedGroup.image.replace("/public", "/128x128")
                          : `${searchedGroup.image}/128x128`
                      }
                      sx={{ width: "40px", height: "40px" }}
                    />
                    <Box sx={{ width: "80%", paddingLeft: "0.5rem" }}>
                      <ListItemText
                        primary={searchedGroup.name}
                        sx={{
                          fontWeight: 500,
                          marginBottom: "0",
                          marginTop: "0",
                        }}
                      />
                      <ListItemText
                        secondary={truncateText(searchedGroup.description, 100)}
                        secondaryTypographyProps={{ fontSize: "10px" }}
                        sx={{ marginBottom: "0", marginTop: "0" }}
                      />
                    </Box>
                  </ListItemButton>
                </ListItem>
              ))}
          </List>

          {/* BRANDS */}

          {searchResults?.brands?.length > 0 && (
            <Typography
              variant="h6"
              sx={{ marginTop: "1rem", marginBottom: "0.5rem" }}
            >
              Businesses
            </Typography>
          )}

          <List>
            {searchTerm !== "" &&
              searchResults?.brands?.map((searchedBrand) => (
                <ListItem key={searchedBrand._id} sx={{ padding: "0.25rem 0" }}>
                  <ListItemButton
                    onClick={() => {
                      navigate(`/brand/${searchedBrand._id}`);
                      setIsSearchSidebarOpen(false);
                      handleAddToSearchHistory(
                        user._id,
                        "Brand",
                        searchedBrand._id
                      );
                      BusinessProfileClick(dispatch, {
                        brandId: searchedBrand._id,
                        fromPage: "search",
                      });
                    }}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0",
                      backgroundColor: neutralLight,
                      borderRadius: "25px",
                      "&:hover": {
                        backgroundColor: primaryLight,
                        color: primaryDark,
                      },
                    }}
                  >
                    <Avatar
                      src={
                        searchedBrand && searchedBrand.logoPath
                          ? searchedBrand.logoPath.endsWith("/public")
                            ? searchedBrand.logoPath.replace(
                                "/public",
                                "/128x128"
                              )
                            : `${searchedBrand.logoPath}/128x128`
                          : searchedBrand &&
                            searchedBrand.picturePath &&
                            searchedBrand.picturePath.endsWith("/public")
                          ? searchedBrand.picturePath.replace(
                              "/public",
                              "/128x128"
                            )
                          : searchedBrand && searchedBrand.picturePath
                          ? `${searchedBrand.picturePath}/128x128`
                          : "defaultImagePath" // Placeholder for a default image path
                      }
                      sx={{ width: "40px", height: "40px" }}
                    />
                    <Box sx={{ width: "80%", paddingLeft: "0.5rem" }}>
                      <ListItemText
                        primary={searchedBrand.name}
                        sx={{
                          fontWeight: 500,
                          marginBottom: "0",
                          marginTop: "0",
                        }}
                      />
                      <ListItemText
                        secondary={truncateText(searchedBrand.description, 100)}
                        secondaryTypographyProps={{ fontSize: "10px" }}
                        sx={{ marginBottom: "0", marginTop: "0" }}
                      />
                    </Box>
                  </ListItemButton>
                </ListItem>
              ))}
          </List>

          {/* ARTICLES */}

          {searchResults?.articles?.length > 0 && (
            <Typography
              variant="h6"
              sx={{ marginTop: "1rem", marginBottom: "0.5rem" }}
            >
              Articles
            </Typography>
          )}

          <List>
            {searchTerm !== "" &&
              searchResults?.articles?.map((searchedArticle) => (
                <ListItem
                  key={searchedArticle._id}
                  sx={{ padding: "0.25rem 0" }}
                >
                  <ListItemButton
                    onClick={() => {
                      navigate(`/news/article/${searchedArticle._id}`);
                      setIsSearchSidebarOpen(false);
                      handleAddToSearchHistory(
                        user._id,
                        "Article",
                        searchedArticle._id
                      );
                    }}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0",
                      backgroundColor: neutralLight,
                      borderRadius: "25px",
                      "&:hover": {
                        backgroundColor: primaryLight,
                        color: primaryDark,
                      },
                    }}
                  >
                    <Avatar
                      src={
                        searchedArticle.titleImage.endsWith("/public")
                          ? searchedArticle.titleImage.replace(
                              "/public",
                              "/128x128"
                            )
                          : `${searchedArticle.titleImage}/128x128`
                      }
                      sx={{ width: "40px", height: "40px" }}
                    />
                    <Box sx={{ width: "80%", paddingLeft: "0.5rem" }}>
                      <ListItemText
                        primary={searchedArticle.title}
                        sx={{
                          fontWeight: 500,
                          marginBottom: "0",
                          marginTop: "0",
                        }}
                      />
                      <ListItemText
                        secondary={truncateText(searchedArticle.subtitle, 100)}
                        secondaryTypographyProps={{ fontSize: "10px" }}
                        sx={{ marginBottom: "0", marginTop: "0" }}
                      />
                    </Box>
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Box>
      </Box>
    </Drawer>
  );

  return isSearchSidebarOpen ? (
    <ClickAwayListener onClickAway={() => setIsSearchSidebarOpen(false)}>
      {drawerContent}
    </ClickAwayListener>
  ) : (
    drawerContent
  );
}

export default SearchSideDrawer;
