import { Button, Typography } from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import { getGroup, leaveGroup } from "redux/actions/groupActions";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { patchUser } from "redux/state";

function GroupLeave({ socket, groupId, isPrivate, isMember, isAdmin }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const group = useSelector((state) => state.group);

  // Extract _id values from user.friends and group.members
  const userFriendIds = user.friends.map((friend) => friend._id) || [];
  const groupMemberIds = group.members || [];

  // Create a new array containing _id values that are not in groupMemberIds
  let uniqueUserFriendIds = userFriendIds.filter(
    (userId) => !groupMemberIds.includes(userId)
  );

  uniqueUserFriendIds = uniqueUserFriendIds.filter(
    (userId) => userId !== user._id
  );

  const handleLeaveGroup = async () => {
    try {
      if (isAdmin === false && isMember === true) {
        await dispatch(leaveGroup(groupId, user._id));

        // Remove groupId from groupsJoined
        const updatedUser = {
          ...user,
          groupsJoined: user.groupsJoined.filter((id) => id !== groupId),
        };
        await dispatch(patchUser(user._id, updatedUser));
        await dispatch(getGroup(groupId));

        uniqueUserFriendIds.forEach((friendId) => {
          const notificationData = {
            senderId: user._id,
            receiverId: friendId,
            name: user.firstName + " " + user.lastName,
            groupId: group._id,
            groupName: group.name,
            picturePath: group.image,
            timeStamp: Date.now(),
            type: "leftGroup",
          };

          socket.emit("sendNotification", notificationData);
        });
        // send notification to group members

        groupMemberIds.forEach((memberId) => {
          const notificationData = {
            senderId: user._id,
            receiverId: memberId,
            name: user.firstName + " " + user.lastName,
            groupId: group._id,
            groupName: group.name,
            picturePath: group.image,
            timeStamp: Date.now(),
            type: "leftGroupMembers",
          };

          socket.emit("sendNotification", notificationData);
        });
      } else {
        console.log("removing members");
      }
    } catch (error) {
      console.error(
        "An error occurred while removing the user to the group:",
        error
      );
    }
  };

  return (
    <WidgetWrapper>
      <Typography variant="h5">Leave Group</Typography>
      <Button
        variant="contained"
        color="secondary"
        sx={{ width: "100%", padding: "0.5rem 0", marginTop: "1rem" }}
        onClick={handleLeaveGroup}
      >
        Leave Group
      </Button>
    </WidgetWrapper>
  );
}

export default GroupLeave;
