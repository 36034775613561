import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";

function ReportForm({ formData, formErrors, handleChange, textFieldLabel }) {
  return (
    <div>
      <TextField
        autoFocus={true}
        margin="dense"
        label={textFieldLabel}
        name="details"
        value={formData.details}
        onChange={handleChange}
        fullWidth
        variant="outlined"
        error={!!formErrors.details}
        helperText={formErrors.details}
        multiline
        rows={4}
      />

      <FormControl fullWidth margin="dense" variant="outlined">
        <InputLabel id="report-type-label">Reason for Flagging</InputLabel>
        <Select
          labelId="report-type-label"
          label="Reason for Flagging"
          name="reason"
          value={formData.reason}
          onChange={handleChange}
          error={!!formErrors.reason}
        >
          <MenuItem value="Spam">Spam</MenuItem>
          <MenuItem value="Inappropriate">Inappropriate</MenuItem>
          <MenuItem value="Hate Speech">Hate Speech</MenuItem>
          <MenuItem value="Harassment">Harassment</MenuItem>
          <MenuItem value="Self-Harm">Self-Harm</MenuItem>
          <MenuItem value="Violence">Violence</MenuItem>
          <MenuItem value="False Information">False Information</MenuItem>
          <MenuItem value="Intellectual Property Violation">
            Intellectual Property Violation
          </MenuItem>
          <MenuItem value="Other">Other</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

export default ReportForm;
