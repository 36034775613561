import {
  FormControlLabel,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import SnackbarComponent from "components/SnackbarComponent";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleFriendsInviteReminder } from "redux/actions/userActions";
import { fetchUser } from "redux/state";

function InviteFriends() {
  const dispatch = useDispatch();

  const isLargeScreen = useMediaQuery("(min-width:1000px)");
  const { palette } = useTheme();
  const primary = palette.primary.main;

  const user = useSelector((state) => state.user);
  const userId = user._id;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  useEffect(() => {
    dispatch(fetchUser(userId));
  }, [dispatch, userId]);

  const handleFriendInviteReminder = async () => {
    try {
      await dispatch(toggleFriendsInviteReminder(userId));

      if (!user.friendsInviteReminder) {
        setSnackbarMessage("Reminders to invite friends turned on");
        setSnackbarSeverity("success");
      } else {
        setSnackbarMessage("Reminders to invite friends turned off");
        setSnackbarSeverity("info");
      }
    } catch (error) {
      setSnackbarMessage("Failed to change reminder settings");
      setSnackbarSeverity("error");
    }
    setSnackbarOpen(true);
  };

  return (
    <Box
      flexBasis={isLargeScreen ? "60%" : undefined}
      px={2}
      pb={isLargeScreen ? 0 : "4rem"}
    >
      <WidgetWrapper>
        <Box
          component="h3"
          sx={{
            fontSize: "1.25rem",
            fontWeight: 500,
            mb: 2,
          }}
        >
          Invite Your Circle, Enrich Your Journey
        </Box>
        <Typography mb={2} fontSize="1rem" color="text.secondary">
          At She Is Action, we believe in empowering our community with choice
          and respect. That's why we've made it effortless for you to decide
          whether you'd like to receive gentle reminders to invite your friends
          to join our growing platform. Turning these reminders on or off is a
          breeze and completely within your control, because your comfort and
          privacy are our top priorities.
        </Typography>
        <Typography mb={2} fontSize="1rem" color="text.secondary">
          <Typography variant="span" fontWeight={700} color={primary}>
            Why Invite Your Friends?
          </Typography>{" "}
          It's simple—the more, the merrier! Our platform thrives on the
          diversity and vibrancy of its members. By inviting your friends,
          you're not just offering them a gateway to an empowering and
          supportive community, but you're also enriching your own experience.
          With each new member, She Is Action becomes a more dynamic,
          informative, and inspiring space.{" "}
        </Typography>
        <Typography mb={2} fontSize="1rem" color="text.secondary">
          <Typography variant="span" fontWeight={700} color={primary}>
            The Ripple Effect:
          </Typography>{" "}
          As our community expands, so does our appeal to businesses and
          organisations that align with our values and your needs. This means
          more relevant, high-quality resources and opportunities made available
          to you. Imagine a platform where every addition leads to more valuable
          connections, insights, and possibilities—tailored just for you and the
          ones you care about.
        </Typography>
        <Typography mb={2} fontSize="1rem" color="text.secondary">
          <Typography variant="span" fontWeight={700} color={primary}>
            Your Choice, Your Impact:
          </Typography>{" "}
          Remember, the power is in your hands. While we wholeheartedly
          encourage you to spread the word and help our community flourish, we
          respect your decision above all. Opting in for reminders is a way to
          ensure you don't miss the chance to make a significant impact, but how
          you choose to participate is entirely up to you. Together, let's make
          She Is Action a beacon of empowerment, connection, and growth. Invite
          your friends, encourage businesses, and watch as we all rise by
          lifting each other.
        </Typography>
        <Box>
          <FormControlLabel
            control={<Switch checked={user.friendsInviteReminder} />}
            onChange={handleFriendInviteReminder}
            name="friendsInviteReminder"
            label="Receive reminders to invite friends"
          />
        </Box>
      </WidgetWrapper>
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        severity={snackbarSeverity}
      />
    </Box>
  );
}

export default InviteFriends;
