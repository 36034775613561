import { setDashboardAdminPosts } from "redux/state";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const fetchPostsCreatedThisMonth = () => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/dashboardPosts/createdThisMonth`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();

  dispatch(setDashboardAdminPosts(data));
  return data;
};

export const fetchPostsCreatedLastMonth = () => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/dashboardPosts/createdLastMonth`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();

  dispatch(setDashboardAdminPosts(data));
  return data;
};

export const fetchTotalPostLikesThisMonth =
  () => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/dashboardPosts/likesThisMonth`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();

    dispatch(setDashboardAdminPosts(data));
    return data;
  };

export const fetchTotalPostLikesLastMonth =
  () => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/dashboardPosts/likesLastMonth`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();

    dispatch(setDashboardAdminPosts(data));
    return data;
  };

export const fetchCommentsThisMonth = () => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/dashboardPosts/commentsThisMonth`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();

  dispatch(setDashboardAdminPosts(data));
  return data;
};

export const fetchCommentsLastMonth = () => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/dashboardPosts/commentsLastMonth`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();

  dispatch(setDashboardAdminPosts(data));
  return data;
};

export const fetchTotalCommentLikesThisMonth =
  () => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/dashboardPosts/commentLikesThisMonth`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();

    dispatch(setDashboardAdminPosts(data));
    return data;
  };

export const fetchTotalCommentLikesLastMonth =
  () => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/dashboardPosts/commentLikesLastMonth`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();

    dispatch(setDashboardAdminPosts(data));
    return data;
  };
