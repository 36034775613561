import {
  setGroup,
  setGroups,
  setInvitedGroups,
  setDiscoverGroups,
  setJoinedGroups,
  setTotalGroups,
  setTopGroups,
} from "redux/state";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const createGroup = (group) => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/groups`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(group),
  });

  const data = await response.json();
  dispatch(setGroup({ group: data }));
  return data;
};

export const getGroup = (id) => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/groups/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await response.json();
  dispatch(setGroup({ group: data }));
  return data;
};

export const getGroupsByParams = (params) => async (dispatch, getState) => {
  const token = getState().token;

  // Convert params object to a query string
  const query = Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");

  const response = await fetch(`${BASE_URL}/groups?${query}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await response.json();

  if (response.status === 200) {
    // If params contain invitations, set the user's invitations
    if (params.invitations) {
      dispatch(setInvitedGroups({ groupsInvited: data }));
    } else if (params.isVisible) {
      dispatch(setDiscoverGroups({ groupsDiscover: data }));
    } else {
      dispatch(setGroups({ groups: data }));
    }
    return data;
  } else {
    // Handle errors based on status code
    console.error(`Error ${response.status}: ${response.statusText}`);
    return null;
  }
};

export const getJoinedGroups = (groupIds) => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/groups/joined`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ groupIds: groupIds }),
  });

  const data = await response.json();
  dispatch(setJoinedGroups({ groups: data }));
};

// update group

export const updateGroup = (group) => async (dispatch, getState) => {
  const token = getState().token;

  try {
    const response = await fetch(`${BASE_URL}/groups/${group._id}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...group,
        image: group.image,
      }),
    });

    if (response.status === 200 || response.status === 204) {
      const data = await response.json();
      dispatch(setGroup({ group: data }));
      return data;
    } else {
      // Handle errors based on status code
      console.error(`Error ${response.status}: ${response.statusText}`);
      return null;
    }
  } catch (error) {
    console.error("An error occurred while updating the group:", error);
    return null;
  }
};

// join group

export const joinGroup = (groupId, userId) => async (dispatch, getState) => {
  const token = getState().token;
  try {
    const response = await fetch(`${BASE_URL}/groups/${groupId}/join`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ userId: userId }),
    });

    if (response.status === 200) {
      const data = await response.json();
      dispatch(setGroup({ group: data }));
      return { success: true, message: "Successfully joined the group." };
    } else {
      const data = await response.json();
      return {
        success: false,
        message: data.message || "Failed to join the group.",
      };
    }
  } catch (error) {
    console.error("An error occurred while joining the group:", error);
    return {
      success: false,
      message: "An error occurred while joining the group.",
    };
  }
};

// Leave group
export const leaveGroup = (groupId, userId) => async (dispatch, getState) => {
  const token = getState().token;
  try {
    const response = await fetch(`${BASE_URL}/groups/${groupId}/leave`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ userId: userId }),
    });

    if (response.status === 200) {
      const data = await response.json();
      dispatch(setGroup({ group: data }));
      return { success: true, message: "Successfully left the group." };
    } else {
      const data = await response.json();
      return {
        success: false,
        message: data.message || "Failed to leave the group.",
      };
    }
  } catch (error) {
    console.error("An error occurred while leaving the group:", error);
    return {
      success: false,
      message: "An error occurred while leaving the group.",
    };
  }
};

// Remove user from group
export const removeUserFromGroup =
  (groupId, userId) => async (dispatch, getState) => {
    const token = getState().token;
    try {
      const response = await fetch(`${BASE_URL}/groups/${groupId}/removeUser`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId: userId }),
      });

      if (response.status === 200) {
        const data = await response.json();
        dispatch(setGroup({ group: data }));
        return {
          success: true,
          message: "Successfully removed the user from the group.",
        };
      } else {
        const data = await response.json();
        return {
          success: false,
          message: data.message || "Failed to remove the user from the group.",
        };
      }
    } catch (error) {
      console.error(
        "An error occurred while removing the user from the group:",
        error
      );
      return {
        success: false,
        message: "An error occurred while removing the user from the group.",
      };
    }
  };

// Request to join group
export const requestToJoinGroup =
  (groupId, userId) => async (dispatch, getState) => {
    const token = getState().token;
    try {
      const response = await fetch(
        `${BASE_URL}/groups/${groupId}/requestToJoin`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ userId: userId }),
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        dispatch(setGroup({ group: data }));
        return {
          success: true,
          message: "Successfully sent request to join the group.",
        };
      } else {
        const data = await response.json();
        return {
          success: false,
          message: data.message || "Failed to request to join the group.",
        };
      }
    } catch (error) {
      console.error(
        "An error occurred while requesting to join the group:",
        error
      );
      return {
        success: false,
        message: "An error occurred while requesting to join the group.",
      };
    }
  };

// Accept join request
export const acceptJoinRequest =
  (groupId, userId) => async (dispatch, getState) => {
    const token = getState().token;
    try {
      const response = await fetch(
        `${BASE_URL}/groups/${groupId}/acceptJoinRequest`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },

          body: JSON.stringify({ userId: userId }),
        }
      );

      if (response.status === 200) {
        return {
          success: true,
          message: "Successfully accepted the join request.",
        };
      } else {
        const data = await response.json();
        return {
          success: false,
          message: data.message || "Failed to accept the join request.",
        };
      }
    } catch (error) {
      console.error(
        "An error occurred while accepting the join request:",
        error
      );
      return {
        success: false,
        message: "An error occurred while accepting the join request.",
      };
    }
  };

// Decline join request
export const declineJoinRequest =
  (groupId, userId) => async (dispatch, getState) => {
    const token = getState().token;
    try {
      const response = await fetch(
        `${BASE_URL}/groups/${groupId}/declineJoinRequest`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },

          body: JSON.stringify({ userId: userId }),
        }
      );

      if (response.status === 200) {
        return {
          success: true,
          message: "Successfully declined the join request.",
        };
      } else {
        const data = await response.json();
        return {
          success: false,
          message: data.message || "Failed to decline the join request.",
        };
      }
    } catch (error) {
      console.error(
        "An error occurred while declining the join request:",
        error
      );
      return {
        success: false,
        message: "An error occurred while declining the join request.",
      };
    }
  };

// send invitation

export const sendInvitation =
  (groupId, userId) => async (dispatch, getState) => {
    const token = getState().token;
    try {
      const response = await fetch(`${BASE_URL}/groups/${groupId}/invite`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },

        body: JSON.stringify({ userId: userId }),
      });

      if (response.status === 200) {
        return {
          success: true,
          message: "Successfully sent the invitation.",
        };
      } else {
        const data = await response.json();
        return {
          success: false,
          message: data.message || "Failed to send the invitation.",
        };
      }
    } catch (error) {
      console.error("An error occurred while sending the invitation:", error);
      return {
        success: false,
        message: "An error occurred while sending the invitation.",
      };
    }
  };

// Accept invitation
export const acceptInvitation =
  (groupId, userId) => async (dispatch, getState) => {
    const token = getState().token;
    try {
      const response = await fetch(`${BASE_URL}/groups/${groupId}/accept`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },

        body: JSON.stringify({ userId: userId }),
      });

      if (response.status === 200) {
        return {
          success: true,
          message: "Successfully accepted the invitation.",
        };
      } else {
        const data = await response.json();
        return {
          success: false,
          message: data.message || "Failed to accept the invitation.",
        };
      }
    } catch (error) {
      console.error("An error occurred while accepting the invitation:", error);
      return {
        success: false,
        message: "An error occurred while accepting the invitation.",
      };
    }
  };

// Decline invitation
export const declineInvitation =
  (groupId, userId) => async (dispatch, getState) => {
    const token = getState().token;
    try {
      const response = await fetch(`${BASE_URL}/groups/${groupId}/decline`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },

        body: JSON.stringify({ userId: userId }),
      });

      if (response.status === 200) {
        return {
          success: true,
          message: "Successfully declined the invitation.",
        };
      } else {
        const data = await response.json();
        return {
          success: false,
          message: data.message || "Failed to decline the invitation.",
        };
      }
    } catch (error) {
      console.error("An error occurred while declining the invitation:", error);
      return {
        success: false,
        message: "An error occurred while declining the invitation.",
      };
    }
  };

// Get all groups

export const fetchAllGroups =
  (page, pageSize, sort, search) => async (dispatch, getState) => {
    try {
      const token = getState().token;

      // Construct URL with query parameters
      let url = `${BASE_URL}/groups/all?page=${page}&limit=${pageSize}`;

      if (sort) {
        url += `&sort=${sort}`;
      }
      if (search) {
        url += `&search=${search}`;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      dispatch(setGroups({ groups: data.groups }));
      dispatch(setTotalGroups(data.totalGroups));
      // return data;
    } catch (error) {
      console.log(error);
    }
  };

// get top groups
export const fetchTopGroups = () => async (dispatch, getState) => {
  try {
    const token = getState().token;

    const response = await fetch(`${BASE_URL}/groups/top`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();

    dispatch(setTopGroups(data));
    // return data;
  } catch (error) {
    console.log(error);
  }
};
