import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import { BusinessProfileClick } from "helpers/businessHelpers/businessProfileClick";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

function StoriesJustForYouThreeStoryCards({
  latestFollowedBrandArticles = [],
  primary,
  neutralLight,
  neutralMedium,
}) {
  const dispatch = useDispatch();
  return (
    <Box width="100%" pt={2}>
      {latestFollowedBrandArticles.map(
        ({ title, titleImage, brand, tags, published_at, _id }, index) => {
          return (
            <Card
              sx={{
                display: "flex",
                mb: 2,
                justifyContent: "space-between",
                // height: "175px",
              }}
              key={index}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "100%", // Ensure it takes the full width of the wrapper
                }}
              >
                <CardContent>
                  <Typography component="div" variant="h5">
                    {title}
                  </Typography>
                </CardContent>
                <CardContent>
                  <Box gap={2} display="flex" flexDirection="column">
                    <Link
                      to={`/news/${tags[0]?.name}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Box
                        sx={{
                          display: "inline-flex",
                          alignItems: "center",
                          p: "0.1rem 0.75rem",
                          borderRadius: "1rem",
                          backgroundColor: primary,
                          maxWidth: "fit-content",
                        }}
                      >
                        <Typography
                          variant="h6"
                          color={neutralLight}
                          fontSize={12}
                        >
                          {tags[0]?.name}
                        </Typography>
                      </Box>
                    </Link>
                    <FlexBetween alignItems="center">
                      <Link
                        to={`/brand/${brand._id}`}
                        onClick={() =>
                          BusinessProfileClick(dispatch, {
                            brandId: brand._id,
                            fromPage: "stories",
                          })
                        }
                      >
                        <Box display="flex" alignItems="center" gap="5px">
                          <img
                            src={brand.logoPath}
                            alt={brand.name}
                            style={{ width: "20px" }}
                          />
                          <Typography
                            variant="body1"
                            fontWeight="500"
                            color={neutralMedium}
                            fontSize={13}
                          >
                            {brand.name}
                          </Typography>
                        </Box>
                      </Link>
                      <Typography
                        variant="p"
                        fontWeight="500"
                        color={neutralMedium}
                        fontSize={13}
                      >
                        {moment(published_at).format("MMM Do")}
                      </Typography>
                    </FlexBetween>
                  </Box>
                </CardContent>
              </Box>
              <Link to={`/news/article/${_id}`} style={{ width: "80%" }}>
                <CardMedia
                  component="img"
                  sx={{
                    aspectRatio: "1 / 1", // Keeps the width and height equal, forming a square
                    objectFit: "cover",
                    borderRadius: "3px",
                  }}
                  image={titleImage}
                  alt={title}
                />
              </Link>
            </Card>
          );
        }
      )}
    </Box>
  );
}

export default StoriesJustForYouThreeStoryCards;
