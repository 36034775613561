const BASE_URL = process.env.REACT_APP_BASE_URL;

export const createSponsorsProfileClick =
  (sponsorId, fromPage) => async (dispatch, getState) => {
    const token = getState().token;
    try {
      const response = await fetch(`${BASE_URL}/sponsorsProfileClick/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ sponsorId, fromPage }),
      });

      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
    }
  };

export const fetchSponsorsProfileClicksThisMonthCount =
  (sponsorId) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      fetch(`${BASE_URL}/sponsorsProfileClick/thisMonthCount`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ sponsorId }),
      });
    } catch (error) {
      console.error(error);
    }
  };

export const fetchSponsorsProfileClicksLastMonth =
  (sponsorId) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      fetch(`${BASE_URL}/sponsorsProfileClick/lastMonth`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ sponsorId }),
      });
    } catch (error) {
      console.error(error);
    }
  };

export const fetchSponsorsProfileClicksDateRange =
  (sponsorId, startDate, endDate) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      fetch(`${BASE_URL}/sponsorsProfileClick/dateRange`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ sponsorId, startDate, endDate }),
      });
    } catch (error) {
      console.error(error);
    }
  };
