import { Autocomplete, FormControl, TextField } from "@mui/material";
import React from "react";

const BrandCreateLocation = ({
  location,
  locationOptions,
  handleLocationInputChange,
  handleLocationChange,
  formErrors,
}) => {
  return (
    <FormControl
      variant="outlined"
      fullWidth
      margin="normal"
      // style={{ marginTop: "-5px" }}
    >
      <Autocomplete
        id="location-autocomplete"
        options={locationOptions}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.description
        }
        value={location}
        onInputChange={handleLocationInputChange}
        onChange={handleLocationChange}
        isOptionEqualToValue={(option, value) =>
          option.place_id === value.place_id
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Business Name or Location"
            name="location"
            variant="outlined"
            error={!!formErrors.locationDetails}
            helperText={formErrors.locationDetails}
          />
        )}
      />
    </FormControl>
  );
};

export default BrandCreateLocation;
