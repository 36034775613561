import { Box, Button } from "@mui/material";
import React from "react";

function FilterMembersButton({
  primary,
  primaryDark,
  neutralLight,
  userCount,
  handleSubmit,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
      mt={2}
    >
      <Button
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0.75rem 1.5rem",
          height: "100%",
          backgroundColor: primary,
          borderRadius: "2rem",
          color: neutralLight,
          fontSize: "16px",
          cursor: "pointer",
          transition: "all 0.2s ease-in-out",
          "&:hover": {
            backgroundColor: primaryDark,
          },
          textTransform: "none",
        }}
        onClick={handleSubmit}
      >
        Show {userCount} members
      </Button>
    </Box>
  );
}

export default FilterMembersButton;
