import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { createArticle, fetchCategories } from "redux/actions/articleActions";
import { fetchSports } from "redux/actions/sportActions";
import { handleImageUpload } from "redux/actions/imageUploadUtils";
import { addTag, fetchAllTags } from "redux/actions/tagActions";
import { fetchUser } from "redux/state";
import { useMediaQuery } from "@mui/material";
import * as Yup from "yup";

export const useArticlesState = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sportsList = useSelector((state) => state.sports) || [];
  const categories = useSelector((state) => state.categories);
  const tagsList = useSelector((state) => state.tags);
  const user = useSelector((state) => state.user);
  const userId = user._id;

  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [titleImage, setTitleImage] = useState("");
  const [contentBlocks, setContentBlocks] = useState([]);
  // console.log("🚀 ~ useArticlesState ~ contentBlocks:", contentBlocks);
  const [tags, setTags] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSport, setSelectedSport] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const [newTagName, setNewTagName] = useState("");

  const { selectedBrandId } = useOutletContext();

  const isLargeScreen = useMediaQuery("(min-width: 1000px)");

  useEffect(() => {
    // fetch user data
    dispatch(fetchUser(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    //get cateogies
    dispatch(fetchCategories());
    //get sports

    if (!sportsList.length) dispatch(fetchSports());

    //get tags
    dispatch(fetchAllTags());
  }, [dispatch, sportsList.length]);

  const articleSchema = Yup.object().shape({
    selectedCategory: Yup.string().required("Category is required"),
    selectedSport: Yup.array().min(1, "At least one sport is required"),
    title: Yup.string().required("Title is required"),
    subtitle: Yup.string().required("Subtitle is required"),
    titleImage: Yup.string()
      .url("Must be a valid URL")
      .required("Title image is required"),
    tags: Yup.array().of(Yup.string()).min(1, "At least one tag is required"),
    contentBlocks: Yup.array().min(1, "At least one content block is required"),
  });

  const handleCreateArticle = async () => {
    try {
      // Validate the form data using Yup
      await articleSchema.validate(
        {
          title,
          subtitle,
          titleImage,
          tags,
          selectedCategory,
          selectedSport,
          contentBlocks,
        },
        { abortEarly: false }
      );

      // If validation is successful, proceed with article creation
      const response = await dispatch(
        createArticle(
          userId,
          title,
          subtitle,
          titleImage,
          contentBlocks,
          selectedBrandId,
          selectedCategory,
          selectedSport.map((sport) => sport._id)
        )
      );

      const articleId = response;
      if (articleId) {
        tags.forEach((tagName) => {
          dispatch(addTag(articleId, tagName));
        });
        // Reset form state and navigate to the newly created article
        setTitle("");
        setSubtitle("");
        setTitleImage("");
        setContentBlocks([]);
        setTags([]);
        navigate(`/news/article/${articleId}`);
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        // Convert Yup error object to a more friendly format
        const newErrors = error.inner.reduce((acc, curr) => {
          acc[curr.path] = curr.message;
          return acc;
        }, {});
        setFormErrors(newErrors);
        // Make sure newErrors.contentBlocks is an array
        if (
          newErrors.contentBlocks &&
          typeof newErrors.contentBlocks === "string"
        ) {
          newErrors.contentBlocks = [newErrors.contentBlocks];
        }
      } else {
        console.error("Error in creating article: ", error);
      }
    }
  };

  const addTextBlock = () => {
    // Add a new text block to contentBlocks
    setContentBlocks([...contentBlocks, { type: "text", data: "" }]);
  };

  const addImageBlock = () => {
    // Add a new image block to contentBlocks
    setContentBlocks([...contentBlocks, { type: "image", data: null }]);
  };

  const updateBlockData = (index, data) => {
    setContentBlocks((prevBlocks) => {
      const updatedBlocks = [...prevBlocks];
      updatedBlocks[index] = { ...updatedBlocks[index], data };
      return updatedBlocks;
    });
  };

  const removeBlock = (index) => {
    setContentBlocks((prevBlocks) => {
      const updatedBlocks = [...prevBlocks];
      updatedBlocks.splice(index, 1);
      return updatedBlocks;
    });
  };

  const handleTitleImageUpload = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const publicImageUrl = await handleImageUpload(file);
    if (publicImageUrl) {
      setTitleImage(publicImageUrl);
    } else {
      console.error("Image upload failed");
    }
  };

  const handleAddTag = (newTags) => {
    // Ensure newTags is an array before setting it
    if (Array.isArray(newTags)) {
      setTags(newTags);
    }
  };

  const handleRemoveTag = (tagName) => {
    setTags(tags.filter((tag) => tag !== tagName)); // Remove the tag from the local state
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      // [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline"],
      [{ header: 1 }, { header: 2 }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ color: [] }],
      [{ align: [] }],
      ["clean"],
      ["link"], // add 'video' for video embedding
    ],
  };

  const formats = [
    "header",
    // "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "color",
    "background",
    "align",
  ];

  return {
    title,
    subtitle,
    titleImage,
    contentBlocks,
    tags,
    selectedCategory,
    selectedSport,
    newTagName,
    handleCreateArticle,
    addTextBlock,
    addImageBlock,
    updateBlockData,
    removeBlock,
    handleTitleImageUpload,
    handleAddTag,
    handleRemoveTag,
    setTitle,
    setSubtitle,
    setSelectedCategory,
    setSelectedSport,
    setNewTagName,
    sportsList,
    categories,
    user,
    modules,
    formats,
    tagsList,
    isLargeScreen,
    showPreview,
    setShowPreview,
    formErrors,
    selectedBrandId,
  };
};
