import { Avatar, Box, Tooltip, Typography, useTheme } from "@mui/material";
import React from "react";
import FlexBetween from "./FlexBetween";
import { Link } from "react-router-dom";

import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";

function UserGroupJoined({ group }) {
  const { palette } = useTheme();
  const neutralDark = palette.neutral.dark;
  const primaryDark = palette.primary.dark;
  const main = palette.neutral.main;

  return (
    <FlexBetween key={group._id} alignItems="center">
      <Tooltip
        title={group.description}
        placement="top-start"
        arrow
        enterDelay={500}
        leaveDelay={200}
      >
        <Link to={`/groups/${group._id}`}>
          <Avatar src={group.image} sx={{ width: "40px", height: "40px" }} />
        </Link>
      </Tooltip>
      <Box
        flex="1"
        mx="1rem"
        overflow="hidden"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        sx={{ minWidth: 0 }} // This ensures the Box shrinks as needed
      >
        <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
          <Typography
            color={primaryDark}
            variant="h6"
            fontWeight="500"
            sx={{
              "&:hover": {
                color: palette.primary.light,
                cursor: "pointer",
              },
              wordWrap: "break-word", // Allow wrapping
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "normal", // Allow text to wrap
            }}
          >
            <Link
              to={`/groups/${group._id}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {group.name}
            </Link>
          </Typography>
          <Typography variant="span" color={neutralDark}>
            {" - "}
          </Typography>

          {group.isPrivate ? (
            <Tooltip
              title="Private group"
              placement="top"
              arrow
              enterDelay={500}
              leaveDelay={200}
            >
              <HttpsOutlinedIcon fontSize="small" />
            </Tooltip>
          ) : (
            <Tooltip
              title="Public group"
              placement="top"
              arrow
              enterDelay={500}
              leaveDelay={200}
            >
              <PublicOutlinedIcon fontSize="small" />
            </Tooltip>
          )}
        </Box>
        <Typography
          color={main}
          fontSize="0.75rem"
          sx={{
            wordWrap: "break-word", // Allow wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "normal", // Allow text to wrap
          }}
        >
          {group.location.town
            ? group.location.town
            : group.location.country
            ? group.location.country
            : group.location.address}{" "}
          - {group.sport}
        </Typography>
      </Box>
    </FlexBetween>
  );
}

export default UserGroupJoined;
