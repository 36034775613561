import React, { useState } from "react";
import WidgetWrapper from "components/WidgetWrapper";
import { Box, Typography, useTheme, Button } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import { useDispatch, useSelector } from "react-redux";
import {
  getGroup,
  joinGroup,
  removeUserFromGroup,
  requestToJoinGroup,
  sendInvitation,
} from "redux/actions/groupActions";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import { patchUser } from "redux/actions/userActions";
import UserSearchModal from "scenes/admin/components/UserSearchModal";
import SnackbarComponent from "components/SnackbarComponent";
import { Link } from "react-router-dom";

function toProperCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function GroupAdmin({ isAdmin, isMember, socket, isRequesting }) {
  const dispatch = useDispatch();

  const group = useSelector((state) => state.group);
  const groupId = group._id;
  const groupName = group.name;
  const user = useSelector((state) => state.user);
  const { palette } = useTheme();
  const main = palette.primary.main;
  const mainNeutral = palette.neutral.main;
  const medium = palette.neutral.medium;

  // Extract _id values from user.friends and group.members
  const userFriendIds = user.friends.map((friend) => friend._id) || [];
  const groupMemberIds = group.members || [];
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  const handleSave = async (newUsers) => {
    try {
      // Logic to identify removed users
      const removedUsers = group.members.filter(
        (member) => !newUsers.some((newUser) => newUser._id === member._id)
      );

      // Logic to add new users to the group
      // Ensure that these users are not already part of the group
      const newUniqueUsers = newUsers.filter(
        (newUser) => !group.members.some((member) => member._id === newUser._id)
      );

      newUniqueUsers.forEach((user) => {
        handleInvite(user._id, user.firstName, user.lastName);
      });

      // Logic to remove users from the group
      for (const user of removedUsers) {
        try {
          await dispatch(removeUserFromGroup(group._id, user._id));

          // handle any additional logic (e.g., notifications)
          setSnackbarMessage("User removed from group");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
        } catch (error) {
          console.error("Error removing user from group:", error);
          setSnackbarMessage("Error removing user from group");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      }

      await dispatch(getGroup(group._id));

      setIsSearchModalOpen(false);
    } catch (error) {
      console.error("An error occurred while updating group members:", error);
      setSnackbarMessage("An error occurred while updating group members");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  // Create a new array containing _id values that are not in groupMemberIds
  let uniqueUserFriendIds = userFriendIds.filter(
    (userId) => !groupMemberIds.includes(userId)
  );

  uniqueUserFriendIds = uniqueUserFriendIds.filter(
    (userId) => userId !== user._id
  );

  const handleInvite = async (userId, firstName, lastName) => {
    try {
      await dispatch(sendInvitation(group._id, userId));
      await dispatch(getGroup(group._id));

      const notificationData = {
        senderId: user._id,
        receiverId: userId,
        name: user.firstName + " " + user.lastName,
        groupId: group._id,
        groupName: group.name,
        picturePath: group.image,
        timeStamp: Date.now(),
        type: "inviteToGroup",
      };

      socket.emit("sendNotification", notificationData);

      group.members.forEach((memberId) => {
        const notificationData = {
          senderId: user._id,
          receiverId: memberId,
          name: firstName + " " + lastName,
          senderName: user.firstName + " " + user.lastName,
          groupId: group._id,
          groupName: group.name,
          picturePath: group.image,
          timeStamp: Date.now(),
          type: "inviteToGroupMembers",
        };

        socket.emit("sendNotification", notificationData);

        setSnackbarMessage("Invitation sent");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      });
    } catch (error) {
      console.error("An error occurred while sending the invitation:", error);
      setSnackbarMessage("An error occurred while sending the invitation");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  // Destructuring the group details
  const {
    name = "",
    isPrivate = null,
    isVisible = null,
    members = [],
    image: groupImage = null,
  } = group;

  const privacy = isPrivate ? "private" : "public";
  const visibility = isVisible ? "visible" : "hidden";

  const handleButtonClick = async () => {
    try {
      if (isPrivate === false && isMember === false) {
        await dispatch(joinGroup(group._id, user._id));
        const updatedUser = {
          ...user,
          groupsJoined: [...user.groupsJoined, group._id],
        };
        await dispatch(patchUser(user._id, updatedUser));
        await dispatch(getGroup(group._id));

        // send notification to group members and user.friends

        uniqueUserFriendIds.forEach((friendId) => {
          const notificationData = {
            senderId: user._id,
            receiverId: friendId,
            name: user.firstName + " " + user.lastName,
            groupId: group._id,
            groupName: group.name,
            picturePath: groupImage,
            timeStamp: Date.now(),
            type: "joinGroup",
          };

          socket.emit("sendNotification", notificationData);
        });
        // send notification to group members

        groupMemberIds.forEach((memberId) => {
          const notificationData = {
            senderId: user._id,
            receiverId: memberId,
            name: user.firstName + " " + user.lastName,
            groupId: group._id,
            groupName: group.name,
            picturePath: groupImage,
            timeStamp: Date.now(),
            type: "joinGroupMembers",
          };

          socket.emit("sendNotification", notificationData);
        });
      } else if (
        isPrivate === true &&
        isVisible === true &&
        isMember === false
      ) {
        // Trigger the requestToJoinGroup action if conditions are met
        const { success, message } = await dispatch(
          requestToJoinGroup(group._id, user._id)
        );
        await dispatch(getGroup(group._id));

        // send notification to group adminstrators
        group.administrators.forEach((administratorId) => {
          const notificationData = {
            senderId: user._id,
            receiverId: administratorId,
            name: user.firstName + " " + user.lastName,
            groupId: group._id,
            groupName: group.name,
            picturePath: groupImage,
            timeStamp: Date.now(),
            type: "requestToJoinGroup",
          };

          socket.emit("sendNotification", notificationData);
        });
        if (success) {
          console.log(message);
        } else {
          console.error(message);
        }
        // } else if (isPrivate === true && isAdmin === true) {
      } else if (isAdmin === true) {
        console.log("Inviting members");
        setIsSearchModalOpen(true); // Open the modal when admin wants to invite members
      } else {
        console.log("I am not sure what to do here");
      }
    } catch (error) {
      console.error(
        "An error occurred while adding the user to the group:",
        error
      );
    }
  };

  let buttonText;

  if (isPrivate === true && isMember === false) {
    // If the group is private and the user is not a member, show "Request to Join"
    buttonText = "Request to Join";
  } else if (isPrivate === false && isMember === false) {
    // If the group is public and the user is not a member, show "Join Group"
    buttonText = "Join Group";
  } else {
    // If the user is already a member, regardless of whether the group is private or public, show "+ Invite Members"
    buttonText = "Edit Members";
  }

  const InfoBox = ({ icon: Icon, children }) => (
    <Box
      gap="0.5rem"
      sx={{ display: "flex", alignItems: "center", color: mainNeutral }}
    >
      {Icon && <Icon />}
      {children}
    </Box>
  );

  return (
    <WidgetWrapper>
      <Box
        display="flex"
        alignItems="center"
        gap="0.5rem"
        color={medium}
        mb={1}
      >
        <Link
          to="/groups"
          style={{ textDecoration: "none", cursor: "pointer" }}
          onMouseOver={(e) =>
            (e.currentTarget.style.textDecoration = "underline")
          }
          onMouseOut={(e) => (e.currentTarget.style.textDecoration = "none")}
        >
          <Typography variant="body1">Groups</Typography>
        </Link>
        <Typography variant="body1">&gt;</Typography>
        <Link
          to={`/groups/${groupId}`}
          style={{ textDecoration: "none", cursor: "pointer" }}
          onMouseOver={(e) =>
            (e.currentTarget.style.textDecoration = "underline")
          }
          onMouseOut={(e) => (e.currentTarget.style.textDecoration = "none")}
        >
          <Typography variant="body1" noWrap color={main}>
            {groupName}
          </Typography>
        </Link>
      </Box>
      <FlexBetween>
        <Box
          width="100%"
          position="relative"
          style={{
            height: "75px",
            overflow: "hidden",
            borderRadius: "0.5rem",
          }}
          flex={1}
        >
          <img
            src={groupImage ? groupImage : "../assets/groupShot.png"}
            alt="Group Shot of sports women"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>

        <Box flex={4} sx={{ padding: "0 5px", marginLeft: "5px" }}>
          <InfoBox sx={{ color: main }}>
            <Typography variant="h5" sx={{ color: main }} marginBottom="0.5rem">
              {name.length > 0 ? name : "Group Name"}
            </Typography>
          </InfoBox>

          <InfoBox icon={isPrivate ? HttpsOutlinedIcon : PublicOutlinedIcon}>
            {`${toProperCase(privacy)} (${visibility}) group`}
          </InfoBox>
          <Typography sx={{ color: main }}>
            {`${members.length} member${members.length === 1 ? "" : "s"}`}
          </Typography>
        </Box>
      </FlexBetween>

      {/* do not show if !isPrivate and isMember  */}

      {!isRequesting &&
        ((isPrivate === false && isMember === false) ||
          (isPrivate === true && isVisible === true && isMember === false) ||
          // (isPrivate === true && isAdmin === true)
          isAdmin === true) && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ width: "100%", padding: "0.5rem 0", marginTop: "1rem" }}
            onClick={handleButtonClick}
          >
            {buttonText}
          </Button>
        )}

      {/* Display invite section directly here */}

      <UserSearchModal
        open={isSearchModalOpen}
        onClose={() => setIsSearchModalOpen(false)}
        userData={{ users: group.members }} // Adjust according to your data structure
        onSave={handleSave}
      />
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        severity={snackbarSeverity}
      />
    </WidgetWrapper>
  );
}

export default GroupAdmin;
