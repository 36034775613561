import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Navbar from "scenes/navbar/index.jsx";
import GroupAdmin from "scenes/groups/Group/GroupHome/components/GroupAdmin";
import { getGroup } from "redux/state";

import MyPostWidget from "scenes/widgets/myPostWidget";
import GroupPrivacy from "./components/GroupPrivacySetting";
import GroupDetails from "./components/GroupDetails";
import GroupImageMain from "./components/GroupImageMain";
import PostsWidget from "scenes/widgets/PostsWidget";
import Footer from "components/Footer"; // Import Footer if it is not imported already
import BottomMenu from "components/bottomMenu/BottomMenu";
import GroupLeave from "./components/GroupLeave";
import GroupUserListWidget from "./components/GroupUserListWidget";
import GroupRequest from "./components/GroupRequest";
import GroupRequestsToJoin from "./components/GroupRequestsToJoin";
import GroupAdminUpdateGroupBtn from "./components/GroupAdminUpdateGroupBtn";
import WidgetWrapper from "components/WidgetWrapper";

const Group = ({ socket }) => {
  const { groupId } = useParams();
  const dispatch = useDispatch(); // get the dispatch function
  const isLargeScreen = useMediaQuery("(min-width:1000px)");
  const user = useSelector((state) => state.user);
  const group = useSelector((state) => state.group);
  const isPrivate = useSelector((state) => state.group.isPrivate);
  const isVisible = useSelector((state) => state.group.isVisible);
  const { picturePath, _id } = useSelector((state) => state.user);
  const [isAdmin, setIsAdmin] = useState(false); // set isAdmin to false by default
  const [isMember, setIsMember] = useState(false); // set isMember to false by default
  const [isRequesting, setIsRequesting] = useState(false); // set isRequesting to false by default
  const [isInvited, setIsInvited] = useState(false); // set isInvited to false by default

  const { palette } = useTheme();
  const dark = palette.neutral.dark;

  const isSheIsActionAdmin =
    user?.roles?.some((role) => role.name.toLowerCase() === "admin") || false;

  useEffect(() => {
    dispatch(getGroup(groupId));
  }, [dispatch, groupId]);

  // isAdmin is true if the user is an admin of the group

  useEffect(() => {
    const isAdmin = group?.administrators?.some((administrator) => {
      return administrator === _id;
    });
    setIsAdmin(isAdmin);
  }, [group, _id]);

  useEffect(() => {
    const isMember = group?.members?.some((member) => {
      return member._id === _id;
    });
    setIsMember(isMember || false);
  }, [group, _id]);

  useEffect(() => {
    const isRequesting = group?.joinRequests?.some((request) => {
      return request === _id;
    });
    setIsRequesting(isRequesting);
  }, [group, _id]);

  useEffect(() => {
    const isInvited = group?.invitations?.some((invitedUser) => {
      return invitedUser === _id;
    });
    setIsInvited(isInvited || false);
  }, [group, _id]);

  if (!group) return null;

  // if not visible and not either member or sheisactionadmin, return null
  if (!isVisible && !isMember && !isSheIsActionAdmin) return null;

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        minHeight="100vh" // changed from height="100vh"
      >
        <Navbar socket={socket} />
        <Box
          flexGrow={1}
          overflow="auto"
          width="100%"
          // padding="2rem 6%"
          padding={isLargeScreen ? "0 6%" : "0"}
          display={isLargeScreen ? "flex" : "block"}
          gap="2rem"
          justifyContent="center"
        >
          <Box flexBasis={isLargeScreen ? "35%" : undefined}>
            <GroupAdmin
              groupId={groupId}
              isAdmin={isAdmin}
              isMember={isMember}
              socket={socket}
              isRequesting={isRequesting}
            />
            {isRequesting && <GroupRequest groupId={groupId} />}
            <GroupPrivacy
              groupId={groupId}
              isPrivate={isPrivate}
              isMember={isMember}
              isInvited={isInvited}
              isSheIsActionAdmin={isSheIsActionAdmin}
            />
            <GroupUserListWidget groupId={groupId} socket={socket} />
            {isMember && !isAdmin ? (
              <GroupLeave
                groupId={groupId}
                isAdmin={isAdmin}
                isMember={isMember}
                socket={socket}
              />
            ) : null}
            {isSheIsActionAdmin || isAdmin ? (
              <GroupAdminUpdateGroupBtn groupId={groupId} />
            ) : null}
          </Box>
          <Box
            flexBasis={isLargeScreen ? "60%" : undefined}
            mt={isLargeScreen ? undefined : "2rem"}
          >
            <GroupImageMain groupId={groupId} isAdmin={isAdmin} />
            <GroupDetails groupId={groupId} />
            {isAdmin && (
              <GroupRequestsToJoin groupId={groupId} socket={socket} />
            )}
            {isMember && (
              <MyPostWidget groupId={groupId} picturePath={picturePath} />
            )}

            {isMember ||
            isSheIsActionAdmin ||
            (isPrivate && isInvited) ||
            !isPrivate ? (
              <PostsWidget groupId={groupId} userId={_id} socket={socket} />
            ) : (
              <WidgetWrapper>
                <Typography
                  color={dark}
                  variant="h5"
                  fontWeight="500"
                  sx={{ mb: "1.5rem" }}
                >
                  Hello there!
                </Typography>
                <Box display="flex" flexDirection="column" gap="1rem">
                  <Typography variant="body1">
                    It looks like this is a private group and currently, you're
                    not a member or on the invitation list. But don't worry!
                    Once you join the group, you'll gain full access to all the
                    exciting details and team information. Keep an eye out for
                    an invitation or consider reaching out to the group
                    administrators for an opportunity to join.
                  </Typography>
                  <Typography variant="body1">
                    We're looking forward to having you with us!
                  </Typography>
                </Box>
              </WidgetWrapper>
            )}
          </Box>
        </Box>
        <Footer />
      </Box>
      {!isLargeScreen && <BottomMenu />}
    </>
  );
};

export default Group;
