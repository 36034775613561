import { Avatar, Tooltip, useTheme } from "@mui/material";
import moment from "moment";
import React from "react";

function SingleMessage({
  message,
  i,
  messages,
  user,
  isSameSender,
  isLastMessage,
  isSameSenderMargin,
  isSameUser,
}) {
  const { palette } = useTheme();

  const primaryLight = palette.primary.light;
  const secondaryLight = palette.secondary.light;

  return (
    <div style={{ display: "flex" }} key={message._id}>
      {(isSameSender(messages, message, i, user._id) ||
        isLastMessage(messages, i, user._id)) && (
        <Tooltip
          title={`${message.sender.firstName} ${message.sender.lastName}`}
          placement="bottom"
          arrow
        >
          <Avatar
            sx={{
              mt: 1,
              mr: 0.5,
              height: "25px",
              width: "25px",
              cursor: "pointer",
            }}
            alt={`${message.sender.firstName} ${message.sender.lastName}`}
            src={
              message.sender.picturePath.endsWith("/public")
                ? message.sender.picturePath.replace("/public", "/64x64")
                : `${message.sender.picturePath}/64x64`
            }
          />
        </Tooltip>
      )}
      <Tooltip
        title={moment(message.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
        placement="bottom"
        arrow
      >
        <span
          style={{
            backgroundColor:
              message.sender._id === user._id ? primaryLight : secondaryLight,
            marginLeft: isSameSenderMargin(messages, message, i, user._id),
            marginTop: isSameUser(messages, message, i, user._id) ? 3 : 10,
            borderRadius: "20px",
            padding: "5px 15px",
            maxWidth: "75%",
          }}
        >
          {message.content}
        </span>
      </Tooltip>
    </div>
  );
}

export default SingleMessage;
