import {
  Avatar,
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchTopGroups } from "redux/actions/groupActions";

function YourGroups() {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const primary = palette.primary.main;
  const dark = palette.neutral.dark;

  const topGroups = useSelector((state) => state.topGroups) || [];

  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  const viewMoreStyle = {
    position: "relative",
    display: "inline-block",
    cursor: "pointer",
    textDecoration: "none",
  };

  const underlineStyle = {
    position: "absolute",
    bottom: "-0.5rem", // Adjust this to position the underline correctly
    left: 0,
    right: 0,
    height: "4px",
    background:
      "linear-gradient(180deg, rgba(156, 236, 237, 0.5) 0%, rgba(0, 142, 148, 0.5) 50.52%, rgba(200, 94, 217, 0.5) 100%)",
  };

  useEffect(() => {
    dispatch(fetchTopGroups());
  }, [dispatch]);

  return (
    <WidgetWrapper sx={{ padding: isLargeScreen ? undefined : "1.5rem 0" }}>
      <Typography color={dark} variant={"h4"} fontWeight={700} mb={3}>
        Your Groups
      </Typography>
      {/* Map through topGroups */}

      {Array.isArray(topGroups) && topGroups.length > 0 ? (
        topGroups.map((group, index) => (
          <Box key={group._id}>
            <Link to={`/groups/${group._id}`}>
              <Box
                display="flex"
                alignItems="center"
                gap="1rem"
                px={1}
                borderRadius="0.5rem"
                sx={{ "&:hover": { backgroundColor: palette.neutral.light } }}
              >
                <Avatar
                  src={group.image}
                  alt={group.name}
                  sx={{ width: "40px", height: "40px" }}
                />
                <Typography
                  color={dark}
                  variant="body"
                  fontSize={16}
                  fontWeight={500}
                >
                  {group.name}
                </Typography>
              </Box>
            </Link>
            {/* {index !== topGroups.length - 1 && <Divider sx={{ my: 2 }} />} */}
            <Divider sx={{ my: 2 }} />
          </Box>
        ))
      ) : (
        <Typography
          variant="h6"
          color="textSecondary"
          sx={{ padding: isLargeScreen ? undefined : "0 1rem" }}
        >
          You don't have any groups yet, why not{" "}
          <Link to="/groups/discover">
            <span style={{ color: primary, fontWeight: "bold" }}>discover</span>
          </Link>{" "}
          one that piques your interest? Or,{" "}
          <Link to="/groups/create">
            <span style={{ color: primary, fontWeight: "bold" }}>create</span>
          </Link>{" "}
          one!
        </Typography>
      )}

      {Array.isArray(topGroups) && topGroups.length > 0 && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="1rem"
        >
          <Link to="/groups/discover" style={viewMoreStyle}>
            <Typography variant="body" fontWeight={500} fontSize={16}>
              View more
            </Typography>
            <div style={underlineStyle}></div>
          </Link>
        </Box>
      )}
    </WidgetWrapper>
  );
}

export default YourGroups;
