import { useTheme } from "@emotion/react";
import { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addComment, taggedUserComment } from "redux/actions/postActions";
import * as Yup from "yup";

const useAddComment = ({ socket, postId, postUserId, refreshPosts }) => {
  const [comment, setComment] = useState("");
  const [hover, setHover] = useState(false);
  const [hoverTarget, setHoverTarget] = useState();
  const [hoverForNewFriend, setHoverForNewFriend] = useState();
  const [hoverForNewFriendIndex, setHoverForNewFriendIndex] = useState();
  const loggedInUser = useSelector((state) => state.user);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  const hoverAddFriend = (index) => {
    setHoverForNewFriend(true);
    setHoverForNewFriendIndex(index);
  };

  const { palette } = useTheme();
  const mediumMain = palette.neutral.mediumMain;
  const neutralMedium = palette.neutral.medium;
  const neutralLight = palette.neutral.light;
  const primary = palette.primary.main;
  const primaryLight = palette.primary.light;
  const primaryDark = palette.primary.dark;

  const hoverTargetUser = (index) => {
    setHoverTarget(index);
    setHover(true);
  };

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [showHandleSuggestion, setHandleTaggedSuggestion] = useState(
    false && comment.includes("@")
  );

  const [usersTagged, setUsersTagged] = useState([]);
  const [usersUntagged, setUsersUntagged] = useState([]);
  const [mentionedName, setMentionedName] = useState("");

  const quillRef = useRef(null);

  const displayUser = usersUntagged.filter((option) => {
    if (option.firstName && option.lastName) {
      return (
        option.firstName.toLowerCase().includes(mentionedName) ||
        option.lastName.toLowerCase().includes(mentionedName)
      );
    }
    return false;
  });

  const CommentSchema = Yup.object().shape({
    comment: Yup.string()
      .required("Comment is required")
      .min(1, "Comment must have some text")
      .max(500, "Comment cannot be longer than 500 characters")
      .matches(/^[^\s]+(\s+[^\s]+)*$/, "Comment cannot be just whitespace"),
  });

  const handleCommentSubmit = async (event, type) => {
    event.preventDefault();
    try {
      await CommentSchema.validate({ comment });

      const commentCreatedId = await dispatch(
        addComment(postId, user, comment, usersTagged)
      );

      if (commentCreatedId) {
        const notificationData = {
          senderId: loggedInUser._id,
          receiverId: postUserId,
          name: loggedInUser.firstName + " " + loggedInUser.lastName,
          postId: postId,
          picturePath: loggedInUser.picturePath,
          timeStamp: Date.now(),
          type: type,
        };

        socket.emit("sendNotification", notificationData);
      }
      if (usersTagged) {
        await dispatch(taggedUserComment(user._id, usersTagged, postId));

        usersTagged.forEach((user) => {
          const notificationDataTag = {
            senderId: loggedInUser._id,
            receiverId: user._id,
            name: loggedInUser.firstName + " " + loggedInUser.lastName,
            postId: postId,
            picturePath: loggedInUser.picturePath,
            timeStamp: Date.now(),
            type: "mentionedOnComment",
          };

          socket.emit("sendNotification", notificationDataTag);
        });
      }

      setComment("");
      setMentionedName("");
      resetCommentContent();
      setHandleTaggedSuggestion(false);
      setUsersTagged([]);
      setUsersUntagged([]);
      setComment("");
      setSnackbarMessage("Comment added");
      setSnackbarSeverity("success");
      if (refreshPosts) refreshPosts();
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      setSnackbarMessage(
        errorMessage || "An error occurred while adding the comment"
      );
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const resetCommentContent = () => {
    setComment("");
    if (quillRef.current && quillRef.current.editor) {
      quillRef.current.editor.setContents([]);
    }
  };

  const handleSelectMentionUser = (user) => {
    if (!quillRef.current) return;
    const quill = quillRef.current.editor;
    const range = quill.getSelection(true);

    if (range) {
      const mentionText = `${user.firstName} ${user.lastName}`;
      const mentionTriggerIndex = quill
        .getText(0, range.index)
        .lastIndexOf("@");

      if (mentionTriggerIndex !== -1 && mentionTriggerIndex < range.index) {
        quill.deleteText(
          mentionTriggerIndex,
          range.index - mentionTriggerIndex
        );

        quill.insertText(mentionTriggerIndex, mentionText);
        quill.formatText(mentionTriggerIndex, mentionText.length, {
          color: primary,
        });

        // Insert a space and reset its formatting
        quill.insertText(mentionTriggerIndex + mentionText.length, " ");
        quill.formatText(mentionTriggerIndex + mentionText.length, 1, {
          color: false,
        });

        quill.setSelection(mentionTriggerIndex + mentionText.length + 1);
      }
    }

    setUsersTagged((prevState) => [...prevState, user]);
    setUsersUntagged((prevState) =>
      prevState.filter((untaggedUser) => untaggedUser._id !== user._id)
    );
    setMentionedName("");
    setHandleTaggedSuggestion(false);
  };

  const handleQuillChange = useCallback(
    (value) => {
      setComment(value);

      if (!quillRef.current) return;
      const quill = quillRef.current.editor;
      const range = quill.getSelection();
      if (range && range.index > 0) {
        const textBeforeCursor = quill.getText(0, range.index);
        const mentionTriggerIndex = textBeforeCursor.lastIndexOf("@");

        if (mentionTriggerIndex > -1) {
          const mentionText = textBeforeCursor.slice(mentionTriggerIndex + 1);
          setMentionedName(mentionText.toLowerCase());
          setUsersUntagged(loggedInUser.friends);
          setHandleTaggedSuggestion(true);
        } else {
          setHandleTaggedSuggestion(false);
        }
      }
    },
    [
      setComment,
      setMentionedName,
      setHandleTaggedSuggestion,
      loggedInUser.friends,
      quillRef,
    ]
  );

  const addFriend = (user) => {
    console.log(user);
  };

  return {
    comment,
    setComment,
    hover,
    setHover,
    hoverTarget,
    hoverForNewFriend,
    setHoverForNewFriend,
    hoverForNewFriendIndex,
    hoverAddFriend,
    hoverTargetUser,
    user,
    showHandleSuggestion,
    handleCommentSubmit,
    handleSelectMentionUser,
    addFriend,
    snackbarOpen,
    setSnackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    displayUser,
    mediumMain,
    neutralMedium,
    neutralLight,
    primary,
    primaryLight,
    primaryDark,
    handleQuillChange,
    quillRef,
  };
};

export default useAddComment;
