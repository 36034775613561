import { ArrowBack, Info as ViewMoreIcon } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  Input,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSender } from "scenes/chatPage/config/ChatLogic";
import UpdateGroupChatModal from "../miscellaneous/UpdateGroupChatModal";
import { createMessage, fetchMessages } from "redux/actions/messageActions";
import ScrollableChat from "./ScrollableChat";
import UserDetailsModal from "./UserDetailsModal";
import SnackbarComponent from "components/SnackbarComponent";
import useSocket from "./useSocket";
import AvatarRoleIcons from "components/avatarRoleIcons";

const SingleChat = ({
  fetchAgain,
  setFetchAgain,
  selectedChat,
  setSelectedChat,
  socket,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const chat = useSelector((state) =>
    state.chats.find((chat) => chat._id === selectedChat)
  );

  const isLargeScreen = useMediaQuery("(min-width:1000px)");
  const { palette } = useTheme();
  const background = palette.background.default;
  const backgroundAlt = palette.background.alt;
  const primary = palette.primary.main;

  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(1);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);

  const messageBoxRef = useRef(null);
  const typingTimeoutRef = useRef(null);

  const fetchChatMessages = async (append = false, pageToFetch = page) => {
    if (!selectedChat) return;
    try {
      setIsLoading(true);
      const chatId = chat?._id;
      const data = await dispatch(fetchMessages(chatId, pageToFetch));
      if (data.length < 50) {
        setHasMoreMessages(false);
      }
      if (!append) {
        setMessages(data);
        if (data.length === 50) {
          setHasMoreMessages(true);
        }
      } else {
        setMessages((prevMessages) => {
          const olderMessages = data.filter(
            (msg) =>
              !prevMessages.some((existingMsg) => existingMsg._id === msg._id)
          );
          return [...prevMessages, ...olderMessages];
        });
      }
      setIsLoading(false);

      if (page === 1 && socket) {
        socket.emit("join chat", selectedChat);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
      setSnackbarMessage("Error fetching messages");
      setSnackbarOpen(true);
    }
  };

  const { istyping } = useSocket(
    socket,
    selectedChat,
    user,
    setMessages,
    setFetchAgain,
    fetchAgain
  );

  useEffect(() => {
    return () => {
      if (socket) {
        socket.emit("leave chat", selectedChat);
      }
    };
  }, [selectedChat, socket]);

  const sendMessage = async (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      socket.emit("stop typing", selectedChat);
      if (newMessage) {
        try {
          setNewMessage("");
          const chatId = chat._id;
          const sender = user._id;
          const content = newMessage;
          const data = await dispatch(createMessage(chatId, sender, content));
          socket.emit("new message", data);
          setMessages((prevMessages) => [data, ...prevMessages]);
        } catch (error) {
          console.log(error);
          setSnackbarMessage("Error sending message");
          setSnackbarOpen(true);
        }
      }
    }
  };

  const typingHandler = (e) => {
    setNewMessage(e.target.value);
    clearTimeout(typingTimeoutRef.current);
    if (e.target.value !== "") {
      socket.emit("typing", selectedChat);
      typingTimeoutRef.current = setTimeout(() => {
        socket.emit("stop typing", selectedChat);
      }, 2000);
    } else {
      socket.emit("stop typing", selectedChat);
    }
  };

  useEffect(() => {
    setMessages([]);
    setPage(1);
    setHasMoreMessages(true);
    fetchChatMessages();
  }, [selectedChat]);

  useEffect(() => {
    fetchChatMessages(true);
  }, [page]);

  const onLoadOlder = () => {
    if (isLoading) return;
    setPage((prevPage) => prevPage + 1);
  };

  const [open, setOpen] = useState(false);
  const [openUpdateGroupChatModal, setOpenUpdateGroupChatModal] =
    useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleUpdateGroupChatModalOpen = () =>
    setOpenUpdateGroupChatModal(true);

  return (
    <>
      <UserDetailsModal
        open={open}
        handleClose={handleClose}
        chat={chat}
        user={user}
      />
      {selectedChat ? (
        <>
          <Typography
            variant="h4"
            width="100%"
            marginBottom={3}
            display="flex"
            justifyContent={isLargeScreen ? "center" : "space-between"}
            alignItems="center"
          >
            {!isLargeScreen && (
              <IconButton onClick={() => setSelectedChat(null)}>
                <ArrowBack />
              </IconButton>
            )}
            {chat.isGroupChat ? (
              <>
                {chat.chatName.toUpperCase()}
                <IconButton
                  onClick={handleUpdateGroupChatModalOpen}
                  size="small"
                >
                  <ViewMoreIcon />
                </IconButton>
                <UpdateGroupChatModal
                  fetchAgain={fetchAgain}
                  setFetchAgain={setFetchAgain}
                  selectedChat={selectedChat}
                  fetchChatMessages={fetchChatMessages}
                  openUpdateGroupChatModal={openUpdateGroupChatModal}
                  setOpenUpdateGroupChatModal={setOpenUpdateGroupChatModal}
                />
              </>
            ) : (
              <>
                <Box mr={0.5}>
                  {getSender(user, chat.users).firstName.toUpperCase()}{" "}
                  {getSender(user, chat.users).lastName.toUpperCase()}
                </Box>
                <AvatarRoleIcons
                  user={getSender(user, chat.users)}
                  avatarSize="22px"
                />
                <IconButton onClick={handleOpen} size="small">
                  <ViewMoreIcon />
                </IconButton>
              </>
            )}
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            height="calc(100vh - 14rem)"
            width="100%"
            padding="0 1rem"
            borderRadius="8px"
            overflow="hidden"
            backgroundColor={background}
          >
            {isLoading ? (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="100%"
                width="100%"
              >
                <CircularProgress size={50} width={20} height={20} />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "scroll",
                  scrollbarWidth: "none",
                }}
              >
                <ScrollableChat
                  messages={messages}
                  hasMore={hasMoreMessages}
                  messageBoxRef={messageBoxRef}
                  onLoadOlder={onLoadOlder}
                />
                <Typography
                  variant="caption"
                  style={{
                    opacity: istyping ? 1 : 0,
                    transition: "opacity 0.3s",
                  }}
                >
                  Someone is typing...
                </Typography>
              </Box>
            )}
          </Box>
          <FormControl
            fullWidth
            sx={{
              marginTop: "0.5rem",
              maxWidth: "100%",
              width: "100%",
              border: `1px solid ${primary}`,
              borderRadius: "10px",
            }}
          >
            <Input
              multiline
              variant="filled"
              type="text"
              placeholder="Type a message...."
              onChange={typingHandler}
              value={newMessage}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (!e.shiftKey) {
                    e.preventDefault();
                    sendMessage(e);
                  }
                }
              }}
              disableUnderline
              sx={{
                backgroundColor: backgroundAlt,
                borderRadius: "10px",
                padding: "0.5rem",
              }}
            />
          </FormControl>
        </>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Typography variant="h4">Select a chat to start messaging</Typography>
        </Box>
      )}
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        severity={"error"}
      />
    </>
  );
};

export default SingleChat;
