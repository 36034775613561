import { Typography } from "@mui/material";
import React from "react";

function ArticlePageSubTitle({ article }) {
  return (
    <Typography variant="h5" gutterBottom mt={1}>
      {article.subtitle}
    </Typography>
  );
}

export default ArticlePageSubTitle;
