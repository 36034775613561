import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WidgetWrapper from "components/WidgetWrapper";
import {
  Box,
  Typography,
  useTheme,
  Button,
  useMediaQuery,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import { getGroupsByParams } from "redux/actions/groupActions";
import GroupsInvited from "./GroupsInvited";

function toProperCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function MyGroups({ socket }) {
  const dispatch = useDispatch();
  const [groups, setGroups] = useState([]);
  const userGroups = useSelector((state) => state.user.groupsJoined);
  const { palette } = useTheme();
  const main = palette.primary.main;
  const mainNeutral = palette.neutral.main;
  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  useEffect(() => {
    const fetchGroups = async () => {
      const params = {
        groupIds: userGroups,
      };
      const fetchedGroups = (await dispatch(getGroupsByParams(params))) || [];
      setGroups(fetchedGroups);
    };

    // Only fetch groups if userGroups is not null
    if (userGroups.length > 0) {
      fetchGroups();
    }
  }, [dispatch, userGroups]);

  /* Set groups */

  const InfoBox = ({ icon: Icon, children }) => (
    <Box
      gap="0.5rem"
      sx={{ display: "flex", alignItems: "center", color: mainNeutral }}
    >
      {Icon && <Icon />}
      {children}
    </Box>
  );

  return (
    <Box>
      <Box>
        <GroupsInvited socket={socket} />
      </Box>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: isLargeScreen ? "space-between" : "center",
        }}
      >
        {groups.map((group, index) => {
          // Destructuring the group details
          const {
            _id = "",
            name = "",
            isPrivate = null,
            isVisible = null,
            members = [],
            image: groupImage = null,
          } = group;

          const privacy = isPrivate ? "private" : "public";
          const visibility = isVisible ? "visible" : "hidden";
          // const Icon = isPrivate ? HttpsOutlinedIcon : PublicOutlinedIcon;

          return (
            <WidgetWrapper
              key={index}
              style={{
                flexBasis: isLargeScreen ? "48%" : "100%", // or use calc()
                marginBottom: "20px",
              }}
            >
              <Box>
                <FlexBetween>
                  <Box
                    width="100%"
                    position="relative"
                    style={{
                      height: "75px",
                      overflow: "hidden",
                      borderRadius: "0.5rem",
                    }}
                    flex={1}
                  >
                    <img
                      src={groupImage ? groupImage : "../assets/groupShot.png"}
                      alt="Group Shot of sports women"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>

                  <Box flex={4} sx={{ padding: "0 5px", marginLeft: "5px" }}>
                    <InfoBox sx={{ color: main }}>
                      <Typography
                        variant="h6"
                        sx={{ color: main }}
                        marginBottom="0.5rem"
                      >
                        {name.length > 0 ? name : "Group Name"}
                      </Typography>
                    </InfoBox>

                    {/* <InfoBox icon={Icon}> */}
                    <InfoBox>
                      {`${toProperCase(privacy)} (${visibility}) group`}
                    </InfoBox>
                    <Typography sx={{ color: main }}>
                      {`${members.length} member${
                        members.length === 1 ? "" : "s"
                      }`}
                    </Typography>
                  </Box>
                </FlexBetween>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ width: "100%", padding: "0.5rem 0", marginTop: "1rem" }}
                  // navigate to group page
                  onClick={() => {
                    window.location.href = `/groups/${_id}`;
                  }}
                >
                  View Group
                </Button>
              </Box>
            </WidgetWrapper>
          );
        })}
      </div>
    </Box>
  );
}

export default MyGroups;
