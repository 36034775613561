import React from "react";
import { Link } from "react-router-dom";

function TopStoryImage({
  articleId,
  titleImage,
  title,
  imageContainerStyle,
  imageStyle,
}) {
  return (
    <Link to={`/news/article/${articleId}`}>
      <div style={imageContainerStyle}>
        <img src={titleImage} alt={title} style={imageStyle} />
      </div>
    </Link>
  );
}

export default TopStoryImage;
