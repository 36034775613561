import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Typography,
  useTheme,
  Box,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const RecommendFriendPopup = ({
  open,
  onClose,
  firstName,
  email,
  emailError,
  setEmail,
  validateAndRecommend,
  name,
  setName,
  nameError,
}) => {
  const { palette } = useTheme();
  const primary = palette.primary.main;
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery("(min-width: 1000px)");

  const handleInviteSettings = () => {
    navigate("/settings/invite-friends");
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle variant="h5" fontWeight={700} color={primary}>
        Invite a Friend
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" mb={2}>
          Hey there,{" "}
          <Typography
            variant="span"
            component="span"
            style={{ color: primary }}
          >
            {firstName}!
          </Typography>{" "}
        </Typography>
        <Typography mb={2} color="text.secondary">
          <Typography variant="span" fontWeight={700} color={primary}>
            Why Invite Your Friends?
          </Typography>{" "}
          It's simple—the more, the merrier! Our platform thrives on the
          diversity and vibrancy of its members. By inviting your friends,
          you're not just offering them a gateway to an empowering and
          supportive community, but you're also enriching your own experience.
          With each new member, She Is Action becomes a more dynamic,
          informative, and inspiring space.{" "}
        </Typography>
        <Box>
          <img
            src="https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/7e7a9145-89e5-4fc1-58ca-08d0ff997000/public"
            alt="Recommend a friend"
            style={{ width: "100%", height: "auto" }}
          />
        </Box>
        <Typography>
          Please enter their name and email address below, and let them
          experience the fun.
        </Typography>

        <Box
          gap={2}
          display="flex"
          flexDirection={isLargeScreen ? "row" : "column"}
          justifyContent="space-between"
        >
          <TextField
            margin="dense"
            label="Friend's Name"
            type="text"
            fullWidth
            variant="standard"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={!!nameError} // Show error state if nameError is not empty
            helperText={nameError} // Show name validation error message
          />

          <TextField
            margin="dense"
            label="Friend's Email"
            type="email"
            fullWidth
            variant="standard"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!emailError} // Show error state if emailError is not empty
            helperText={emailError} // Show email validation error message
          />
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <Button
          variant="text"
          onClick={handleInviteSettings}
          sx={{ color: primary, textTransform: "none" }} // Adjust styling as needed
        >
          Friend Invitation Settings
        </Button>

        <Box>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={() => validateAndRecommend(email, name)}
            disabled={!!emailError || !!nameError} // Disable button if there are validation errors
            variant="contained"
            sx={{
              marginLeft: "1rem",
            }}
          >
            Invite
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default RecommendFriendPopup;
