import { createSlice } from "@reduxjs/toolkit";
import {
  fetchFriends,
  fetchNonFriends,
  fetchRecentNonFriends,
  patchFriend,
} from "redux/actions/friendsActions";
import { fetchUser, patchUser } from "redux/actions/userActions";
import { getGroup } from "redux/actions/groupActions";
import { fetchPost } from "redux/actions/postActions";

const initialState = {
  mode: "light",
  user: null,
  token: null,
  posts: [],
  post: null,
  groupPosts: [],
  userGroupPosts: [],
  viewingUser: null,
  viewingUserFriends: [],
  locations: [],
  activation: {
    loading: false,
    success: false,
    message: "",
  },
  sports: [],
  sport: {},
  advancedSearchSports: {
    data: [],
    total: 0,
    page: 0,
    totalPages: 0,
  },
  group: [],
  groups: [],
  topGroups: [],
  totalGroups: 0,
  notifications: {
    noticaitons: [],
    totalPages: 0,
    currentPage: 0,
    hasNextPage: false,
    hasPrevPage: false,
    totalItems: 0,
    totalUnread: 0,
  },
  userSearchResults: {
    data: [],
    total: 0,
    page: 0,
    totalPages: 0,
  },
  messages: [],
  unreadMessagesCount: 0,
  chats: [],
  unreadMessagesCountForChat: {},
  users: [],
  totalUsers: 0,
  roles: [],
  role: null,
  userRoles: [],
  userRolesWithCount: [],
  brands: [],
  totalBrands: 0,
  brand: {},
  brandSubscriptionPrices: [],
  userBrands: [],
  brandLocations: [],
  brandLocation: null,
  brandDirectory: {
    closestBrandsNotFollowed: [],
    brandsFriendsFollowing: [],
    brandsMatchingSports: [],
  },
  locationDetails: null,
  article: null,
  articles: [],
  mostLikesArticle: null,
  latestFollowedBrandArticles: [],
  feedArticles: [],
  brandArticles: [],
  tagArticles: [],
  allBrandArticles: [],
  categories: [],
  tags: [],
  distanceBetweenUsers: {
    distance: 0,
    distanceInMiles: 0,
    distanceInKilometers: 0,
  },
  distanceBetweenUserAndBrand: {
    distance: 0,
    distanceInMiles: 0,
    distanceInKilometers: 0,
  },
  memberSuggestions: [],
  dashboardAdminUser: {
    totalUsers: 0,
    totalVerifiedUsers: 0,
    totalUsersAtEndOfLastMonth: 0,
    totalVerifiedUsersAtEndOfLastMonth: 0,
    usersPerMonth: [],
    verifiedUsersPerMonth: [],
    totalVerifiedUsersThisMonth: 0,
    totalUsersThisMonth: 0,
    totalVerifiedUsersLastMonth: 0,
    totalUsersLastMonth: 0,
    ageRangeCount: [],
    sportsCount: [],
    userLocations: [],
  },
  dashboardAdminPosts: {
    totalPostsThisMonth: 0,
    totalPostsLastMonth: 0,
    totalLikesThisMonth: 0,
    totalLikesLastMonth: 0,
    totalPostCommentsThisMonth: 0,
    totalPostCommentsLastMonth: 0,
    totalCommentLikesThisMonth: 0,
    totalCommentLikesLastMonth: 0,
  },
  dashboardAdminBrands: {
    totalBrands: 0,
    totalBrandsAtEndOfLastMonth: 0,
    totalNumberOfFollowers: 0,
    totalNumberOfArticles: 0,
  },
  dashboardAdminArticles: {
    totalPublishedArticles: 0,
    totalArticlesPublishedAtEndOfLastMonth: 0,
    totalArticleReadsThisMonth: 0,
    totalArticleReadsLastMonth: 0,
    totalLikesThisMonth: 0,
    totalLikesLastMonth: 0,
    totalCommentsThisMonth: 0,
    totalCommentsLastMonth: 0,
  },
  dashboardBrand: {
    totalNumberOfFollowers: 0,
    totalNumberOfFollowersEndOfLastMonth: 0,
    totalArticlesPublishedCount: 0,
    totalArticlesPublishedByEndOfLastMonth: 0,
    totalLikesForBrandArticles: 0,
    totalLikesForBrandArticlesByEndOfLastMonth: 0,
    totalCommentsForBrandArticles: 0,
    totalCommentsForBrandArticlesByEndOfLastMonth: 0,
  },
  sponsors: [],
  currentSponsor: {},
  userNotificationSettings: {
    dnd: false,
    notificationFrequency: "weekly",
    quietHoursStart: null,
    quietHoursEnd: null,
    snoozeEnd: null,
    notificationPreferences: {},
  },
  userSearchCriteria: {
    location: {
      type: "Point",
      coordinates: [0, 0],
      address: "",
    },
    locationRadius: 0,
    sports: [],
    ageRanges: [],
  },
  filteredMembers: {
    users: [],
    page: 0,
    totalPages: 0,
    totalUsers: 0,
  },
  flags: {
    flags: [],
    total: 0,
    page: 0,
    limit: 0,
  },
  flag: null,
  userRecommendations: {
    data: [],
    total: 0,
    page: 0,
    totalPages: 0,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    activateAccountSuccess: (state, action) => {
      state.activation = {
        loading: false,
        success: true,
        message: action.payload,
      };
    },
    activateAccountFail: (state, action) => {
      state.activation = {
        loading: false,
        success: false,
        message: action.payload,
      };
    },
    setLogin: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    setLogout: (state) => {
      return initialState;
    },

    setUser: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
        friends: state.user.friends,
        nonFriends: state.user.nonFriends,
      };
    },

    setViewingUser: (state, action) => {
      state.viewingUser = action.payload;
    },

    setUserSearchResults: (state, action) => {
      state.userSearchResults = action.payload;
    },

    setFriends: (state, action) => {
      if (state.user) {
        state.user.friends = action.payload.friends;
      } else {
        console.error("user friends non-existent :(");
      }
    },
    setNonFriends: (state, action) => {
      if (state.user) {
        state.user.nonFriends = action.payload.nonFriends;
      } else {
        console.error("user nonFriends non-existent :(");
      }
    },
    setRecentNonFriends: (state, action) => {
      if (state.user) {
        state.user.recentNonFriends = action.payload.recentNonFriends;
      } else {
        console.error("user recentNonFriends non-existent :(");
      }
    },
    setViewingUserFriends: (state, action) => {
      state.viewingUserFriends = action.payload.friends;
    },
    setPosts: (state, action) => {
      state.posts = action.payload.posts;
    },

    setGroupPosts: (state, action) => {
      state.groupPosts = action.payload.groupPosts;
    },
    setUserGroupPosts: (state, action) => {
      state.userGroupPosts = action.payload.userGroupPosts;
    },
    setPost: (state, action) => {
      state.post = action.payload.post;

      // Ensure that state.posts is always treated as an array
      if (Array.isArray(state.posts)) {
        state.posts = state.posts.map((post) =>
          post._id === action.payload.post._id ? action.payload.post : post
        );
      }

      // Similar check for groupPosts
      if (Array.isArray(state.groupPosts)) {
        state.groupPosts = state.groupPosts.map((post) =>
          post._id === action.payload.post._id ? action.payload.post : post
        );
      }

      // And for userGroupPosts
      if (Array.isArray(state.userGroupPosts)) {
        state.userGroupPosts = state.userGroupPosts.map((post) =>
          post._id === action.payload.post._id ? action.payload.post : post
        );
      }
    },

    setLocations: (state, action) => {
      state.locations = action.payload.locations;
    },
    clearLocations: (state) => {
      state.locations = [];
    },
    setSports: (state, action) => {
      state.sports = action.payload;
    },
    setSport: (state, action) => {
      state.sport = action.payload.sport;
    },
    setAdvancedSearchSports: (state, action) => {
      state.advancedSearchSports = action.payload;
    },
    setGroup: (state, action) => {
      state.group = action.payload.group;
    },
    setGroups: (state, action) => {
      state.groups = action.payload.groups;
    },
    setTopGroups: (state, action) => {
      state.topGroups = action.payload;
    },
    setTotalGroups: (state, action) => {
      state.totalGroups = action.payload;
    },
    setInvitedGroups: (state, action) => {
      state.user = {
        ...state.user,
        groupsInvited: action.payload.groupsInvited,
      };
    },
    setDiscoverGroups: (state, action) => {
      state.groups = {
        ...state.groups,
        groupsDiscover: action.payload.groupsDiscover,
      };
    },
    setJoinedGroups: (state, action) => {
      state.user = {
        ...state.user,
        groups: action.payload.groups,
      };
    },
    setNotifications: (state, action) => {
      // If it's the first page, replace the existing notifications
      if (action.payload.currentPage === 1) {
        state.notifications = action.payload;
      } else {
        // For subsequent pages, append the new notifications to the existing ones
        state.notifications.notifications = [
          ...state.notifications.notifications,
          ...action.payload.notifications,
        ];
        // Update the pagination and unread counts with the latest payload
        state.notifications.totalPages = action.payload.totalPages;
        state.notifications.currentPage = action.payload.currentPage;
        state.notifications.hasNextPage = action.payload.hasNextPage;
        state.notifications.hasPrevPage = action.payload.hasPrevPage;
        state.notifications.totalItems = action.payload.totalItems;
        state.notifications.totalUnread = action.payload.totalUnread;
      }
    },

    setMessages: (state, action) => {
      state.messages = action.payload; // Assuming action.payload is the array of messages
    },
    addNewMessage: (state, action) => {
      if (!Array.isArray(state.messages)) {
        state.messages = [];
      }
      state.messages = [action.payload, ...state.messages]; // Prepend the new message
    },

    addNewChat: (state, action) => {
      state.chats.push(action.payload);
    },
    setChats: (state, action) => {
      state.chats = action.payload.chats;
    },
    updateChatInState: (state, action) => {
      const updatedChat = action.payload;
      const chatIndex = state.chats.findIndex(
        (chat) => chat._id === updatedChat._id
      );

      if (chatIndex !== -1) {
        state.chats[chatIndex] = updatedChat;
      } else {
        console.warn(`Chat with id ${updatedChat._id} not found in state`);
      }
    },
    unreadMessagesCount: (state, action) => {
      state.unreadMessagesCount = action.payload;
    },

    resetUnreadMessagesCount: (state) => {
      state.unreadMessagesCount = 0;
    },
    // clearOtherNotifications: (state) => {
    //   state.notifications.otherNotifications = [];
    // },
    unreadMessagesCountForChat: (state, action) => {
      state.unreadMessagesCountForChat = {
        ...state.unreadMessagesCountForChat,
        [action.payload.chatId]: action.payload.count,
      };
    },
    setMentionsPosts: (state, action) => {
      state.mentionsPosts = action.payload;
    },
    setMentionsComments: (state, action) => {
      state.mentionsComments = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setTotalUsers: (state, action) => {
      state.totalUsers = action.payload;
    },
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setUserRoles: (state, action) => {
      state.userRoles = action.payload;
    },
    setRolesWithCount: (state, action) => {
      state.userRolesWithCount = action.payload;
    },
    setBrands: (state, action) => {
      state.brands = action.payload;
    },
    setTotalBrands: (state, action) => {
      state.totalBrands = action.payload;
    },
    setBrand: (state, action) => {
      state.brand = action.payload;
    },
    setBrandSubscriptionPrices: (state, action) => {
      state.brand.subscriptions = action.payload;
    },
    setBrandLocations: (state, action) => {
      state.brandLocations = action.payload;
    },
    setBrandLocation: (state, action) => {
      state.brandLocation = action.payload;
    },
    setUserBrands: (state, action) => {
      state.userBrands = action.payload;
    },
    setArticle: (state, action) => {
      state.article = action.payload;
    },
    setArticles: (state, action) => {
      state.articles = action.payload;
    },
    setMostLikedArticle: (state, action) => {
      state.mostLikedArticle = action.payload;
    },
    setLatestFollowedBrandArticles: (state, action) => {
      state.latestFollowedBrandArticles = action.payload;
    },
    setFeedArticles: (state, action) => {
      state.feedArticles = action.payload;
    },
    setBrandArticles: (state, action) => {
      state.brandArticles = action.payload;
    },
    setTagArticles: (state, action) => {
      state.tagArticles = action.payload;
    },
    setAllBrandArticles: (state, action) => {
      state.allBrandArticles = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setTags: (state, action) => {
      state.tags = action.payload;
    },
    setDistanceBetweenUsers: (state, action) => {
      state.distanceBetweenUsers = action.payload;
    },
    setDistanceBetweenUserAndBrand: (state, action) => {
      state.distanceBetweenUserAndBrand = action.payload;
    },
    setMemberSuggestions: (state, action) => {
      state.memberSuggestions = action.payload;
    },

    setDashboardAdminUser: (state, action) => {
      state.dashboardAdminUser = {
        ...state.dashboardAdminUser,
        ...action.payload,
      };
    },

    setDashboardAdminPosts: (state, action) => {
      state.dashboardAdminPosts = {
        ...state.dashboardAdminPosts,
        ...action.payload,
      };
    },

    setDashboardAdminBrands: (state, action) => {
      state.dashboardAdminBrands = {
        ...state.dashboardAdminBrands,
        ...action.payload,
      };
    },

    setDashboardAdminArticles: (state, action) => {
      state.dashboardAdminArticles = {
        ...state.dashboardAdminArticles,
        ...action.payload,
      };
    },
    setDashboardBrand: (state, action) => {
      state.dashboardBrand = {
        ...state.dashboardBrand,
        ...action.payload,
      };
    },
    setSponsors: (state, action) => {
      state.sponsors = action.payload;
    },
    setCurrentSponsor: (state, action) => {
      state.currentSponsor = action.payload;
    },
    setBrandDirectory: (state, action) => {
      state.brandDirectory = {
        ...state.brandDirectory,
        ...action.payload,
      };
    },
    setUserNotificationSettings: (state, action) => {
      state.userNotificationSettings = action.payload;
    },
    setUserSearchCriteria: (state, action) => {
      state.userSearchCriteria = action.payload;
    },
    setFilteredMembers: (state, action) => {
      state.filteredMembers = action.payload;
    },
    setFlags: (state, action) => {
      state.flags = action.payload;
    },
    setFlag: (state, action) => {
      state.flag = action.payload;
    },
    setUserRecommendations: (state, action) => {
      state.userRecommendations = action.payload;
    },
    setBlockedUsers: (state, action) => {
      state.user = {
        ...state.user,
        blockedUsers: action.payload,
      };
    },
    setSoftBlockedUsers: (state, action) => {
      state.user = {
        ...state.user,
        softBlockedUsers: action.payload,
      };
    },
  },
});

export const {
  setMode,
  setLogin,
  setLogout,
  setFriends,
  setNonFriends,
  setRecentNonFriends,
  setViewingUserFriends,
  setPosts,
  setPost,
  setGroupPosts,
  setUserGroupPosts,
  setUser,
  setViewingUser,
  setUserSearchResults,
  setLocations,
  activateAccountSuccess,
  activateAccountFail,
  setSports,
  setSport,
  setAdvancedSearchSports,
  setGroup,
  setGroups,
  setTopGroups,
  setTotalGroups,
  setInvitedGroups,
  setDiscoverGroups,
  setJoinedGroups,
  setNotifications,
  setMessages,
  addNewMessage,
  addNewChat,
  setChats,
  updateChatInState,
  unreadMessagesCount,
  resetUnreadMessagesCount,
  // clearOtherNotifications,
  unreadMessagesCountForChat,
  setMentionsPosts,
  setMentionsComments,
  setUsers,
  setTotalUsers,
  setRoles,
  setRole,
  setUserRoles,
  setRolesWithCount,
  setBrands,
  setBrand,
  setBrandSubscriptionPrices,
  setBrandLocations,
  setBrandLocation,
  setLocationDetails,
  setTotalBrands,
  setArticle,
  setArticles,
  setMostLikedArticle,
  setLatestFollowedBrandArticles,
  setFeedArticles,
  setBrandArticles,
  setTagArticles,
  setAllBrandArticles,
  setUserBrands,
  setCategories,
  setTags,
  setDistanceBetweenUsers,
  setDistanceBetweenUserAndBrand,
  setMemberSuggestions,
  setDashboardAdminUser,
  setDashboardAdminPosts,
  setDashboardAdminBrands,
  setDashboardAdminArticles,
  setDashboardBrand,
  setSponsors,
  setCurrentSponsor,
  setBrandDirectory,
  setUserNotificationSettings,
  setUserSearchCriteria,
  setFilteredMembers,
  setFlags,
  setFlag,
  setUserRecommendations,
  setBlockedUsers,
  setSoftBlockedUsers,
} = authSlice.actions;

export {
  fetchFriends,
  fetchUser,
  fetchNonFriends,
  fetchRecentNonFriends,
  patchFriend,
  patchUser,
  getGroup,
  fetchPost,
};

export default authSlice.reducer;
