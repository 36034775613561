import { IconButton, useTheme } from "@mui/material";
import searchLight from "assets/light/search.png";
import searchDark from "assets/dark/search.png";

const SearchBar = ({ setIsSearchSidebarOpen }) => {
  const { palette } = useTheme();
  const mode = palette.mode;

  const handleSearchClick = () => {
    setIsSearchSidebarOpen(true); // Opens the search sidebar
  };

  return (
    <IconButton onClick={handleSearchClick}>
      <img
        src={mode === "light" ? searchLight : searchDark}
        alt="search"
        style={{
          cursor: "pointer",
          height: "1rem",
          width: "auto",
        }}
      />
    </IconButton>
  );
};

export default SearchBar;
