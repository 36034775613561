import {
  Box,
  Checkbox,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import React from "react";

function SportsSelector({
  userSportNames,
  displayedSportsCount,
  selectedSports,
  handleSportToggle,
  handleViewMore,
  viewMoreStyle,
  underlineStyle,
  skillLevels,
  skillLevelSelection,
  handleSkillLevelChange,
  isAnySportSelected,
}) {
  return (
    <Box>
      <Box>
        <Typography variant="h5" fontWeight={700}>
          Sport
        </Typography>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center"
          gap="1rem"
          px={1}
        >
          {userSportNames.slice(0, displayedSportsCount).map((sport) => (
            <Box
              key={sport}
              width="calc(50% - 0.5rem)" // Adjust based on your gap
              display="flex"
            >
              <FormControlLabel
                control={
                  <Switch
                    name={sport.toLowerCase().replace(/\s+/g, "_")}
                    checked={selectedSports[sport] || false}
                    onChange={() => handleSportToggle(sport)}
                    color="primary"
                  />
                }
                label={sport}
              />
            </Box>
          ))}
        </Box>

        {displayedSportsCount < userSportNames.length && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="1rem"
            onClick={handleViewMore}
            mt={2}
          >
            <Box style={viewMoreStyle}>
              <Typography variant="body" fontWeight={500} fontSize={16}>
                View more
              </Typography>
              <div style={underlineStyle}></div>
            </Box>
          </Box>
        )}
      </Box>
      <Box width="100%">
        {isAnySportSelected && (
          <Typography variant="h5" fontWeight={700} mt={2} mb={1} fontSize={20}>
            Skill Level
          </Typography>
        )}
        <Box width="100%">
          {userSportNames.slice(0, displayedSportsCount).map((sport) => (
            <Box key={sport} width="100%">
              {selectedSports[sport] && (
                <>
                  <Typography variant="h6" my={2}>
                    {sport}
                  </Typography>
                  <Box
                    width="100%"
                    display="flex"
                    style={{
                      backgroundImage:
                        "linear-gradient(90deg, #8BE1E2 0%, #008E94 33.42%, #C85ED9 100%)",
                      borderRadius: "100px",
                      padding: "0.5rem",
                      color: "white",
                    }}
                  >
                    {skillLevels.map((level) => (
                      <Box
                        key={level}
                        flex={1}
                        display="flex"
                        justifyContent={"center"}
                      >
                        <FlexBetween>
                          <Typography
                            variant="body"
                            fontWeight={400}
                            fontSize={13}
                          >
                            {level}
                          </Typography>
                        </FlexBetween>
                      </Box>
                    ))}
                  </Box>
                  <Box display="flex" flexDirection="row" px="0.5rem">
                    {skillLevels.map((level) => (
                      <Box
                        key={level}
                        flex="1"
                        display="flex"
                        justifyContent="center"
                        alignContent="center"
                        pl="2rem"
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                skillLevelSelection[sport]?.includes(level) ||
                                false
                              }
                              onChange={() =>
                                handleSkillLevelChange(sport, level)
                              }
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  // Target the icon within the Checkbox
                                  fontSize: 28, // Set the font size to your preference
                                },
                                "& .MuiCheckbox-root": {
                                  // You can also apply the transform property to scale it
                                  transform: "scale(1.5)", // Adjust the scaling factor as needed
                                },
                              }}
                            />
                          }
                        />
                      </Box>
                    ))}
                  </Box>
                </>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default SportsSelector;
