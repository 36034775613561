import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";

function SportForm({ formData, setFormData, formErrors, handleChange }) {
  return (
    <div>
      <TextField
        autoFocus={true}
        margin="dense"
        label="Sport Name"
        name="name" // Add name attribute
        value={formData.name} // Controlled component
        onChange={handleChange}
        fullWidth
        variant="outlined"
        error={!!formErrors.name}
        helperText={formErrors.name}
      />
      <TextField
        margin="dense"
        label="Description"
        name="description" // Add name attribute
        value={formData.description} // Controlled component
        onChange={handleChange}
        fullWidth
        variant="outlined"
        error={!!formErrors.description}
        helperText={formErrors.description}
        multiline
      />
      <TextField
        margin="dense"
        label="Category"
        name="category" // Add name attribute
        value={formData.category} // Controlled component
        onChange={handleChange}
        fullWidth
        variant="outlined"
        error={!!formErrors.category}
        helperText={formErrors.category}
      />
      {/* Add a select dropdown for popularity */}

      <FormControl fullWidth margin="dense" variant="outlined">
        <InputLabel id="popularity-label">Popularity</InputLabel>
        <Select
          labelId="popularity-label"
          label="Popularity"
          name="popularity"
          value={formData.popularity}
          onChange={handleChange}
          error={!!formErrors.popularity}
        >
          <MenuItem value="very low">Very Low</MenuItem>
          <MenuItem value="low">Low</MenuItem>
          <MenuItem value="medium">Medium</MenuItem>
          <MenuItem value="high">High</MenuItem>
          <MenuItem value="very high">Very High</MenuItem>
        </Select>
        {formErrors.popularity && (
          <p style={{ color: "red", fontSize: "0.8rem" }}>
            {formErrors.popularity}
          </p>
        )}
      </FormControl>

      <TextField
        margin="dense"
        label="Team Size"
        name="teamSize" // Add name attribute
        value={formData.teamSize} // Controlled component
        onChange={handleChange}
        fullWidth
        variant="outlined"
        error={!!formErrors.teamSize}
        helperText={formErrors.teamSize}
      />
      <TextField
        margin="dense"
        label="Equipment"
        name="equipment"
        value={formData.equipment} // directly use the string value
        onChange={handleChange}
        fullWidth
        variant="outlined"
        error={!!formErrors.equipment}
        helperText={
          formErrors.equipment || "Enter equipment separated by commas"
        }
        placeholder="e.g., Ball, Net, Bats"
      />
    </div>
  );
}

export default SportForm;
