import { useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPostWithLikes,
  fetchPostCommentWithLikes,
  markPostAsDeleted,
  patchLike,
  patchLikeComment,
} from "redux/actions/postActions";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import * as yup from "yup";
import { createFlag } from "redux/actions/flagActions";
import { softBlockUser, blockUser } from "redux/actions/userActions";

const usePostWidgetState = ({
  socket,
  postId,
  postUserId,
  likes,
  comments,
  group,
  refreshPosts,
  userId,
}) => {
  const dispatch = useDispatch();

  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  const loggedInUser = useSelector((state) => state.user);

  const isLiked = loggedInUser && likes && Boolean(likes[loggedInUser._id]);
  const likeCount = Object.keys(likes || {}).length;

  const [isComments, setIsComments] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [isEditMode, setIsEditMode] = useState(false);

  const { palette } = useTheme();
  const main = palette.neutral.main;
  const primary = palette.primary.main;
  const primaryLight = palette.primary.light;
  const secondary = palette.secondary.main;
  const lightBackground = palette.background.default;
  const neutralLight = palette.neutral.light;
  const neutralMedium = palette.neutral.medium;
  const mediumMain = palette.neutral.mediumMain;

  const isGroupPost = Boolean(group);

  const isPostAuthor = loggedInUser && loggedInUser._id === postUserId;

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [openReportDialog, setOpenReportDialog] = useState(false);

  const [likesUserNames, setLikesUserNames] = useState([]);
  const [hasFetchedLikes, setHasFetchedLikes] = useState(false);

  const [commentLikesUserNames, setCommentLikesUserNames] = useState([]);
  const [hasFetchedCommentLikes, setHasFetchedCommentLikes] = useState(false);

  const [prevCommentId, setPrevCommentId] = useState(null);

  const groupId = group?._id;
  const groupImage = group?.image;
  const groupName = group?.name;

  const userProfileLink = `/profile/${userId}`;
  const groupLink = `/groups/${groupId}`;

  const [anchorEl, setAnchorEl] = useState(null);

  const showTooltip = commentLikesUserNames.length > 0;

  const handleMouseEnterLikeButton = async () => {
    if (!hasFetchedLikes) {
      await handleGetPostWithLikes(); // Fetch likes only if not already fetched
      setHasFetchedLikes(true); // Set flag to true after fetching
    }
  };

  useEffect(() => {
    // Fetch comment likes when prevCommentId changes and fetching is needed
    const fetchCommentLikesIfNeeded = async () => {
      if (prevCommentId && !hasFetchedCommentLikes) {
        await handleGetPostWithCommentLikes(prevCommentId);
        setHasFetchedCommentLikes(true);
      }
    };

    fetchCommentLikesIfNeeded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevCommentId, hasFetchedCommentLikes]);

  const handleMouseEnterCommentLikeButton = async (commentId) => {
    if (commentId !== prevCommentId) {
      setHasFetchedCommentLikes(false);
      setPrevCommentId(commentId);
    }
  };

  useEffect(() => {
    // Reset hasFetchedLikes when postId changes, or based on any other condition that makes re-fetching necessary
    setHasFetchedLikes(false);
  }, [postId]); // postId is used as an example dependency; adjust according to your needs

  const handleDeleteDialogOpen = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleEditPost = () => {
    setIsEditMode(!isEditMode);
  };

  const performPostDeletion = async () => {
    try {
      const success = await dispatch(markPostAsDeleted(postId));

      if (success) {
        setSnackbarMessage("Post deleted");
        setSnackbarSeverity("info");
        setSnackbarOpen(true);

        if (refreshPosts) refreshPosts();
      } else {
        setSnackbarMessage("An error occurred while deleting the post");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("An error occurred while deleting the post:", error);
      setSnackbarMessage("An error occurred while deleting the post");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleMarkPostAsDeleted = async () => {
    handleDeleteDialogOpen();
  };

  const handlePatchLike = async (type) => {
    if (!loggedInUser) {
      setSnackbarMessage("Please log in to like the post");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      return;
    }

    try {
      await dispatch(patchLike(postId, loggedInUser._id));

      if (isLiked) {
        setSnackbarMessage("Post unliked");
        setSnackbarSeverity("info");
      } else {
        const notificationData = {
          senderId: loggedInUser._id,
          receiverId: postUserId,
          name: loggedInUser.firstName + " " + loggedInUser.lastName,
          postId: postId,
          picturePath: loggedInUser.picturePath,
          timeStamp: Date.now(),
          type: type,
        };
        socket.emit("sendNotification", notificationData);
        setSnackbarMessage("Post liked");
        setSnackbarSeverity("success");
      }
      if (refreshPosts) refreshPosts();
      setSnackbarOpen(true);
    } catch (error) {
      console.error("An error occurred while liking the post:", error);
      setSnackbarMessage("An error occurred while liking the post");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handlePatchLikeComment = async (commentId, authorId) => {
    if (!loggedInUser) {
      setSnackbarMessage("Please log in to like the comment");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      return;
    }

    try {
      // Find the comment in the comments array
      const comment = comments.find((c) => c._id === commentId);
      const isCommentLiked =
        comment && comment.likes.hasOwnProperty(loggedInUser._id);

      await dispatch(patchLikeComment(postId, commentId, loggedInUser._id));

      // Check if the comment was already liked to determine the message
      if (isCommentLiked) {
        setSnackbarMessage("Comment unliked");
        setSnackbarSeverity("info");
      } else {
        const notificationData = {
          senderId: loggedInUser._id,
          receiverId: authorId,
          name: loggedInUser.firstName + " " + loggedInUser.lastName,
          postId: postId,
          picturePath: loggedInUser.picturePath,
          timeStamp: Date.now(),
          type: "likeComment",
        };
        socket.emit("sendNotification", notificationData);
        setSnackbarMessage("Comment liked");
        setSnackbarSeverity("success");
      }
      if (refreshPosts) refreshPosts();
      setSnackbarOpen(true);
    } catch (error) {
      console.error(
        "An error occurred while liking/unliking the comment:",
        error
      );
      setSnackbarMessage("An error occurred while liking/unliking the comment");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleGetPostWithLikes = async () => {
    try {
      // Clearing previous likes user names before fetching new ones
      setLikesUserNames([]);

      const data = await dispatch(fetchPostWithLikes(postId));
      setLikesUserNames(data.likesUserNames);
    } catch (error) {
      console.error("An error occurred while fetching the post likes:", error);
      // Handle any errors, perhaps by setting an error message in state
    }
  };

  const handleGetPostWithCommentLikes = async (commentId) => {
    try {
      // Clearing previous comment likes user names before fetching new ones
      setCommentLikesUserNames([]);

      const data = await dispatch(fetchPostCommentWithLikes(postId, commentId));

      setCommentLikesUserNames(data.likesUserNames);
    } catch (error) {
      console.error(
        "An error occurred while fetching the post comment likes:",
        error
      );
    }
  };

  const closeComments = () => {
    setIsComments(false);
  };

  /* Post, Post Comment and User Reporting */

  const validationReportSchema = yup.object().shape({
    reason: yup.string().required("Reason is required"),
    details: yup.string().required("Description is required"),
  });

  const handleOpenReportDialog = (contentType, contentId) => {
    setFormData({
      ...formData,
      contentId: contentId,
      contentType: contentType,
    });
    setOpenReportDialog(true);
  };

  const handleCloseReportDialog = () => {
    setOpenReportDialog(false);
  };

  const resetReportForm = () => {
    setFormData({
      contentId: "",
      reason: "",
      flaggerId: loggedInUser._id,
      details: "",
      contentType: "",
    });
  };

  const handleReport = async () => {
    try {
      await validationReportSchema.validate(formData, { abortEarly: false });
      setFormErrors({});

      dispatch(createFlag(formData));

      setSnackbarMessage(
        "Report submitted, one of the She Is Action team will review it soon"
      );
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

      resetReportForm();

      handleCloseReportDialog();
    } catch (error) {
      console.error("An error occurred while submitting the report:", error);
      setSnackbarMessage("An error occurred while submitting the report");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      // Handle any errors, perhaps by setting an error message in state
    }
  };

  const [formData, setFormData] = useState({
    contentId: "",
    reason: "",
    flaggerId: loggedInUser?._id,
    details: "",
    contentType: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const contentType = formData.contentType;

  // switch between content types to change the label of the text field
  let textFieldLabel = "";
  let dialogTitle = "";
  switch (contentType) {
    case "Post":
      textFieldLabel = "What is the issue with this post?";
      dialogTitle = "Report Post";
      break;
    case "PostComment":
      textFieldLabel = "What is the issue with this comment?";
      dialogTitle = "Report Comment";
      break;
    case "Article":
      textFieldLabel = "What is the issue with this article?";
      dialogTitle = "Report Article";
      break;
    case "ArticleComment":
      textFieldLabel = "What is the issue with this comment?";
      dialogTitle = "Report Comment";
      break;
    case "User":
      textFieldLabel = "What is the issue with this user?";
      dialogTitle = "Report User";
      break;
    case "Brand":
      textFieldLabel = "What is the issue with this brand?";
      dialogTitle = "Report Brand";
      break;

    default:
      textFieldLabel = "What is the issue?";
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReportPost = () => {
    handleOpenReportDialog("Post", postId);
    handleClose();
  };

  const handleReportComment = (commentId) => {
    handleOpenReportDialog("PostComment", commentId);
    handleClose();
  };

  const handleReportCommentUser = (commentAuthorId) => {
    handleOpenReportDialog("User", commentAuthorId);
    handleClose();
  };

  const handleReportUser = () => {
    handleOpenReportDialog("User", userId);
    handleClose();
  };

  const handleSoftBlockUser = (softBlockId) => {
    dispatch(softBlockUser(loggedInUser._id, softBlockId));
    handleClose();
  };

  const handleBlockUser = (blockId) => {
    dispatch(blockUser(loggedInUser._id, blockId));
    handleClose();
  };

  return {
    loggedInUser,
    isLiked,
    likeCount,
    isComments,
    setIsComments,
    snackbarOpen,
    setSnackbarOpen,
    setSnackbarMessage,
    setSnackbarSeverity,
    snackbarMessage,
    snackbarSeverity,
    main,
    primary,
    primaryLight,
    secondary,
    lightBackground,
    neutralLight,
    neutralMedium,
    mediumMain,
    isGroupPost,
    handlePatchLike,
    handlePatchLikeComment,
    closeComments,
    isPostAuthor,
    isEditMode,
    handleEditPost,
    handleMarkPostAsDeleted,
    isDeleteDialogOpen,
    handleDeleteDialogClose,
    performPostDeletion,
    likesUserNames,
    handleMouseEnterLikeButton,
    commentLikesUserNames,
    handleMouseEnterCommentLikeButton,
    ShareOutlinedIcon,
    openReportDialog,
    handleCloseReportDialog,
    handleReport,
    formData,
    formErrors,
    handleChange,
    isLargeScreen,
    textFieldLabel,
    dialogTitle,
    handleSoftBlockUser,
    handleBlockUser,
    handleClick,
    handleClose,
    handleReportPost,
    handleReportUser,
    groupImage,
    groupName,
    userProfileLink,
    groupLink,
    anchorEl,
    showTooltip,
    handleReportComment,
    handleReportCommentUser,
  };
};

export default usePostWidgetState;
