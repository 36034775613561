import { Typography, Box, useTheme } from "@mui/material";

import React from "react";

function Header({ title, subtitle }) {
  const { palette } = useTheme();
  const primary = palette.primary.main;
  const neutralDark = palette.neutral.dark;

  return (
    <Box>
      <Typography
        variant="h2"
        color={neutralDark}
        fontWeight="bold"
        sx={{ mb: "5px" }}
      >
        {title}
      </Typography>

      <Typography variant="h5" color={primary}>
        {subtitle}
      </Typography>
    </Box>
  );
}

export default Header;
