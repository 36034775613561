import React from "react";
import { Box } from "@mui/material";
import DOMPurify from "dompurify";

function ArticlePageContentBlocks({ article }) {
  // Function to replace consecutive '<p><br></p>' tags
  const replaceConsecutiveBreaks = (match) => {
    // Count the number of occurrences
    const count = match.split("<p><br></p>").length - 1;
    // Replace with one fewer '<br>' than the count
    return "<br>".repeat(Math.max(count - 1, 0));
  };

  const sanitizeContent = (htmlContent) => {
    // Replace consecutive occurrences of '<p><br></p>'
    const updatedContent = DOMPurify.sanitize(htmlContent).replace(
      /(<p><br><\/p>)+/g,
      replaceConsecutiveBreaks
    );

    return {
      __html: updatedContent,
    };
  };

  return (
    article &&
    article.content &&
    article.content.map((block, index) => (
      <Box key={index} marginY={2}>
        {block.type === "text" ? (
          <div dangerouslySetInnerHTML={sanitizeContent(block.data)} />
        ) : (
          <img
            src={block.data}
            alt="Article content"
            style={{ width: "100%", borderRadius: "10px" }}
          />
        )}
      </Box>
    ))
  );
}

export default ArticlePageContentBlocks;
