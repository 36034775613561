import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import UserSearchModal from "../../../components/UserSearchModal";

function BrandManagerEdit({ brandData, setBrandData }) {
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  const handleSave = (newManagers) => {
    setBrandData({
      ...brandData,
      managers: [...newManagers],
    });
    setIsSearchModalOpen(false);
  };

  return (
    <Box m="1rem 0">
      <Typography variant="h5" marginBottom="-8px">
        Brand Managers
      </Typography>
      <Box m="1rem 0">
        {brandData.managers && brandData.managers.length > 0 ? (
          brandData.managers.map((manager, index) => (
            <Box key={index}>
              <TextField
                label="Name"
                variant="outlined"
                value={`${manager.firstName} ${manager.lastName}`}
                disabled
                margin="dense"
              />
              <TextField
                label="Email"
                variant="outlined"
                value={manager.email}
                disabled
                margin="dense"
                style={{ width: "300px", marginLeft: "1rem" }}
              />
            </Box>
          ))
        ) : (
          <Typography variant="body1">No managers assigned.</Typography>
        )}
      </Box>
      <Button variant="contained" onClick={() => setIsSearchModalOpen(true)}>
        Edit Managers
      </Button>
      <UserSearchModal
        open={isSearchModalOpen}
        onClose={() => setIsSearchModalOpen(false)}
        userData={{ users: brandData.managers }}
        onSave={handleSave}
      />
    </Box>
  );
}

export default BrandManagerEdit;
