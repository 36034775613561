import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMostLikedArticle } from "redux/actions/articleActions";

const useTopStoriesState = () => {
  const dispatch = useDispatch();

  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  const mostLikedArticle = useSelector((state) => state.mostLikedArticle) || {};

  const brandName = mostLikedArticle.brand?.name || "Brand Name";
  const brandLogoPath = mostLikedArticle.brand?.logoPath || "";
  const brandId = mostLikedArticle.brand?._id || "";
  const sportName = mostLikedArticle.sport?.name || "Sport Name";
  const title = mostLikedArticle.title || "Title";
  const titleImage = mostLikedArticle.titleImage || "";
  const publishedAt = mostLikedArticle.published_at || "";
  const tags = mostLikedArticle.tags || [];
  const articleId = mostLikedArticle._id || "";

  const { palette } = useTheme();
  const primary = palette.primary.main;
  const neutralLight = palette.neutral.light;
  const neutralMedium = palette.neutral.medium;
  const neutralDark = palette.neutral.dark;

  useEffect(() => {
    try {
      dispatch(fetchMostLikedArticle());
    } catch (err) {
      console.log(err);
    }
  }, [dispatch]);

  const imageContainerStyle = {
    position: "relative",
    borderRadius: isLargeScreen ? "10px" : "0",
    overflow: "hidden", // Ensures the overlay is contained within the border radius
  };

  const containerStyle = {
    padding: isLargeScreen ? undefined : "0 1.5rem",
  };

  const imageStyle = {
    height: "300px",
    width: "100%",
    objectFit: "cover",
    borderRadius: isLargeScreen ? "10px" : "0",
    marginLeft: isLargeScreen ? undefined : "-marginValue", // Adjust accordingly
    marginRight: isLargeScreen ? undefined : "-marginValue", // Adjust accordingly
  };

  // Check if there's a valid article. If not, do not render the component.
  if (!articleId) {
    return {
      isLargeScreen: false,
      brandName: "",
      brandLogoPath: "",
      brandId: "",
      sportName: "",
      title: "",
      titleImage: "",
      publishedAt: "",
      tags: [],
      articleId: "",
      primary: "",
      neutralLight: "",
      neutralMedium: "",
      neutralDark: "",
      imageContainerStyle: {},
      containerStyle: {},
      imageStyle: {},
    };
  }

  return {
    isLargeScreen,
    brandName,
    brandLogoPath,
    brandId,
    sportName,
    title,
    titleImage,
    publishedAt,
    tags,
    articleId,
    primary,
    neutralLight,
    neutralMedium,
    neutralDark,
    imageContainerStyle,
    containerStyle,
    imageStyle,
  };
};

export default useTopStoriesState;
