import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import "../../../../styles/index.css";
import { useDispatch } from "react-redux";
import { updatePost } from "redux/actions/postActions";
import FlexBetween from "components/FlexBetween";
import { ImageOutlined } from "@mui/icons-material";
import ImageUploadComponent from "scenes/widgets/myPostWidget/components/ImageUploadComponent";
import { handleImageUpload } from "redux/actions/imageUploadUtils";

function PostWidgetEdit({
  initialDescription,
  initialPicturePath,
  initialTaggedUsers,
  postId,
  primary,
  primaryLight,
  neutralLight,
  mediumMain,
  neutralMedium,
  handleEditPost,
  setSnackbarMessage,
  setSnackbarSeverity,
  setSnackbarOpen,
  refreshPosts,
}) {
  const dispatch = useDispatch();
  const quillRef = useRef(null);

  const [post, setPost] = useState(initialDescription);
  const [isImage, setIsImage] = useState(false);
  const [image, setImage] = useState(initialPicturePath);
  const [loading, setLoading] = useState(false);
  const [usersTagged, setUsersTagged] = useState(initialTaggedUsers || []);

  useEffect(() => {
    setPost(initialDescription);
    setImage(initialPicturePath);
    setUsersTagged(initialTaggedUsers || []);
  }, [initialDescription, initialPicturePath, initialTaggedUsers]);

  useEffect(() => {
    if (quillRef.current && !quillRef.current.editor) {
      quillRef.current.editor = new Quill(quillRef.current, {
        theme: "snow",
        modules: {
          toolbar: false,
        },
        placeholder: "What's on your mind...",
      });

      quillRef.current.editor.on("text-change", () => {
        setPost(quillRef.current.editor.root.innerHTML);
      });

      quillRef.current.editor.root.innerHTML = post;
    }
  }, [post]);

  const handleImageChange = (newImage) => {
    setImage(newImage);
  };

  const handlePostUpdate = async () => {
    const trimmedPost = post.trim();

    if (
      !trimmedPost ||
      /^<p>(<br>|\s)*<\/p>(<p>(<br>|\s)*<\/p>)*$/.test(trimmedPost)
    ) {
      setSnackbarMessage("Post cannot be empty");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);

    const updatedData = {
      description: post,
      picturePath:
        image instanceof File ? await handleImageUpload(image) : image,
    };

    const success = await dispatch(updatePost(postId, updatedData));

    if (success) {
      if (refreshPosts) {
        refreshPosts();
      }

      setSnackbarMessage("Post updated");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } else {
      setSnackbarMessage("An error occurred while updating the post");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }

    setLoading(false);
    handleEditPost();
  };

  return (
    <>
      <Box
        my={2}
        flexGrow={1}
        sx={{
          borderRadius: "2rem",
          overflow: "hidden",
          border: "1px solid #ccc",
        }}
      >
        <div
          ref={quillRef}
          className="myPostWidgetQuill"
          style={{
            border: "none",
          }}
        />
      </Box>

      {initialPicturePath && (
        <img
          width="100%"
          height="auto"
          alt="post"
          style={{ borderRadius: "0.75rem", margin: "0.75rem 0" }}
          src={
            initialPicturePath.endsWith("/public")
              ? initialPicturePath.replace("/public", "/768x576")
              : `${initialPicturePath}/768x576`
          }
        />
      )}

      {isImage && (
        <ImageUploadComponent
          neutralMedium={neutralMedium}
          setImage={handleImageChange}
          primary={primary}
          image={image}
        />
      )}

      <FlexBetween mb={2}>
        <FlexBetween gap="0.25rem" onClick={() => setIsImage(!isImage)}>
          <ImageOutlined sx={{ color: mediumMain }} />
          <Typography
            color={mediumMain}
            sx={{ "&:hover": { cursor: "pointer", color: neutralMedium } }}
          >
            {initialPicturePath ? "Update" : "Add"} Image
          </Typography>
        </FlexBetween>

        <Button
          disabled={!post || loading}
          onClick={handlePostUpdate}
          sx={{
            borderRadius: "3rem",
          }}
          type="submit"
          variant="contained"
          color="primary"
        >
          {loading ? "Updating..." : "Update Post"}
        </Button>
      </FlexBetween>
    </>
  );
}

export default PostWidgetEdit;
