import React from "react";
import { Box } from "@mui/material";
import ImageUploadAndPreview from "./components/ImageUploadAndPreview";
import BrandCreateDetails from "./components/BrandCreateDetails";
import BrandCreateSport from "./components/BrandCreateSport";
import BrandCreateLocation from "./components/BrandCreateLocation";
import ImageLogoUploadAndPreview from "./components/ImageLogoUploadAndPreview";

function BrandForm({
  handleChange,
  handleLocationChange,
  handleLocationInputChange,
  locationOptions,
  location,
  modules,
  formats,
  sportsList,
  selectedSport,
  setSelectedSport,
  formData,
  setFormData,
  image,
  logo,
  handleEditClick,
  handleFileChange,
  handleLogoChange,
  formErrors,
  fileInputRef,
  logoInputRef,
  handleSportChange,
  defaultImageURL,
  googleImages,
  handleGoogleImageSelect,
  locationSelected,
  setLocationSelected,
  isLargeScreen,
}) {
  return (
    <Box width={isLargeScreen ? "450px" : "250px"}>
      {/* Location */}
      <BrandCreateLocation
        location={location}
        locationOptions={locationOptions}
        handleLocationChange={(event, newValue) => {
          handleLocationChange(event, newValue);
          if (newValue) setLocationSelected(true);
        }}
        handleLocationInputChange={handleLocationInputChange}
        formErrors={formErrors}
      />

      {locationSelected && (
        <>
          {/* Image Upload and Preview */}
          <ImageUploadAndPreview
            image={image}
            handleFileChange={handleFileChange}
            fileInputRef={fileInputRef}
            defaultImageURL={defaultImageURL}
            handleEditClick={() => handleEditClick(fileInputRef)}
            googleImages={googleImages}
            handleGoogleImageSelect={handleGoogleImageSelect}
          />

          <ImageLogoUploadAndPreview
            logo={logo}
            handleLogoChange={handleLogoChange}
            fileInputRef={logoInputRef}
            defaultImageURL={defaultImageURL}
            handleEditClick={() => handleEditClick(logoInputRef)}
          />

          {/* Brand Name, Description, Telephone and Website */}
          <BrandCreateDetails
            handleChange={handleChange}
            formData={formData}
            formErrors={formErrors}
            modules={modules}
            formats={formats}
            setFormData={setFormData}
          />

          {/* Sport */}
          <BrandCreateSport
            sportsList={sportsList}
            selectedSport={selectedSport}
            setSelectedSport={setSelectedSport}
            formErrors={formErrors}
            handleSportChange={handleSportChange}
          />
        </>
      )}
    </Box>
  );
}

export default BrandForm;
