import React from "react";
import { Box, Typography } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";

function FilterAndResultsTitle({ showResults, handleHideResults, primary }) {
  return (
    <FlexBetween>
      <Typography variant="h4" fontWeight={700}>
        {showResults ? "Results" : "Filter Members"}
      </Typography>

      <Box
        display={showResults ? "flex" : "none"}
        onClick={handleHideResults}
        sx={{ cursor: "pointer" }}
        gap={1}
      >
        <Typography variant="h6" fontWeight={700} color={primary}>
          Show Filters
        </Typography>
        <TuneOutlinedIcon
          sx={{
            color: primary,
          }}
        />
      </Box>
    </FlexBetween>
  );
}

export default FilterAndResultsTitle;
