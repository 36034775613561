import { Avatar, Tooltip, AvatarGroup, useTheme } from "@mui/material";
import React from "react";

const AvatarRoleIcons = ({ user, avatarSize = "20px" }) => {
  const { palette } = useTheme();
  const primary = palette.primary.main;

  if (!user) {
    return null;
  }

  const rolesWithIcons = user.roles?.filter((role) => role.icon);

  if (!rolesWithIcons || rolesWithIcons.length === 0) {
    return null;
  }

  return (
    <AvatarGroup>
      {rolesWithIcons.map((role, index) => (
        <Tooltip
          key={index}
          title={role.toolTip || role.name}
          placement="top"
          arrow
          sx={{ backgroundColor: primary }}
        >
          <Avatar
            src={role.icon}
            sx={{ width: avatarSize, height: avatarSize }}
          />
        </Tooltip>
      ))}
    </AvatarGroup>
  );
};

export default AvatarRoleIcons;
