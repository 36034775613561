import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLocationDetails,
  fetchLocations,
} from "redux/actions/locationActions";
import { debounce } from "lodash";
import { useState, useMemo, useEffect } from "react";
import { Box, FormHelperText } from "@mui/material";

const FormLocation = ({
  touched,
  errors,
  handleBlur,
  setFieldValue,
  values = {}, // Provide a default empty object
  fieldName = "location",
}) => {
  const dispatch = useDispatch();
  const defaultLocations = useMemo(() => [], []);
  const locations = useSelector((state) => state.locations || defaultLocations);

  const [value, setValue] = useState(
    values[fieldName]?.address
      ? {
          description: values[fieldName].address,
          place_id: values[fieldName].placeId || "",
        }
      : null
  );

  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);

  const debouncedFetchLocations = useMemo(
    () =>
      debounce((input) => {
        if (input.trim() !== "") dispatch(fetchLocations(input));
      }, 500),
    [dispatch]
  );

  useEffect(() => {
    if (values[fieldName]?.address && (!value || !value.place_id)) {
      setInputValue(values[fieldName].address);
    }
  }, [values, fieldName, value]);

  useEffect(() => {
    if (inputValue !== "") {
      debouncedFetchLocations(inputValue);
    } else {
      setOptions(value ? [value] : []);
    }

    return () => {
      if (!inputValue) {
        debouncedFetchLocations.cancel();
      }
    };
  }, [inputValue, value, debouncedFetchLocations]);

  useEffect(() => {
    setOptions(locations.length > 0 ? locations : value ? [value] : []);
  }, [locations, value]);

  const handleLocationSelect = async (newValue) => {
    if (!newValue) return;

    try {
      const locationDetails = await dispatch(
        fetchLocationDetails(newValue.place_id)
      );

      if (locationDetails) {
        setFieldValue(fieldName, {
          type: "Point",
          coordinates: [
            locationDetails.locationDetails.lng,
            locationDetails.locationDetails.lat,
          ],
          address: locationDetails.locationDetails.address,
          town: locationDetails.locationDetails.town,
          country: locationDetails.locationDetails.country,
          placeId: locationDetails.locationDetails.placeId,
        });
      }
    } catch (error) {
      console.error("Error fetching location details:", error);
    }
  };

  return (
    <Box sx={{ gridColumn: "span 2" }}>
      <Autocomplete
        id="google-map-demo"
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.description
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        noOptionsText="No locations"
        isOptionEqualToValue={(option, value) =>
          option.place_id === value.place_id
        }
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          handleLocationSelect(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={values[fieldName]?.address ?? "Add a location"}
            fullWidth
            onBlur={handleBlur}
            error={Boolean(touched?.address) && Boolean(errors?.address)}
          />
        )}
        name={fieldName}
      />
      {Boolean(touched?.address) && Boolean(errors?.address) && (
        <FormHelperText
          error={true}
          style={{
            margin: "4px 1rem",
          }}
        >
          {errors?.address}
        </FormHelperText>
      )}
    </Box>
  );
};

export default FormLocation;
