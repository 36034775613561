import { Box } from "@mui/material";
import React from "react";
import searchDark from "assets/light/search.png";
import searchLight from "assets/dark/search.png";
import { Link } from "react-router-dom";

function StoriesJustForYouExploreButton({
  primary,
  neutralLight,
  primaryDark,
  mode,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        margin: "2rem 0 1rem 0",
      }}
    >
      <Link to="/news">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0.75rem 1.5rem",
            height: "100%",
            backgroundColor: primary,
            borderRadius: "2rem",
            color: neutralLight,
            fontSize: "20px",
            cursor: "pointer",
            transition: "all 0.2s ease-in-out",
            "&:hover": {
              backgroundColor: primaryDark,
            },
          }}
        >
          <img
            src={mode === "light" ? searchLight : searchDark}
            alt="search"
            style={{ width: "1.5rem", marginRight: "0.5rem" }}
          />
          Explore Stories
        </Box>
      </Link>
    </Box>
  );
}

export default StoriesJustForYouExploreButton;
