import { Box } from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import React from "react";
import useTopStoriesState from "./hooks/useTopStoriesState";
import SegmentTitleAndDate from "./components/SegmentTitleAndDate";
import TopStoryTags from "./components/TopStoryTags";
import TopStoryImage from "./components/TopStoryImage";
import TopStoryBottomDetail from "./components/TopStoryBottomDetail";
import TopStoryTitle from "./components/TopStoryTitle";

function TopStories() {
  const storiesState = useTopStoriesState();

  if (!storiesState || !storiesState.articleId) {
    return null; // or a placeholder component
  }

  const {
    isLargeScreen,
    brandName,
    brandLogoPath,
    brandId,
    sportName,
    title,
    titleImage,
    publishedAt,
    tags,
    articleId,
    primary,
    neutralLight,
    neutralMedium,
    neutralDark,
    imageContainerStyle,
    containerStyle,
    imageStyle,
  } = storiesState;

  return (
    <WidgetWrapper
      style={
        isLargeScreen
          ? {}
          : { overflow: "visible", padding: "1.5rem 0 0.75rem 0" }
      }
    >
      <Box style={containerStyle}>
        <SegmentTitleAndDate neutralMedium={neutralMedium} />

        <TopStoryTags
          tags={tags}
          neutralDark={neutralDark}
          neutralLight={neutralLight}
        />
      </Box>

      <TopStoryImage
        articleId={articleId}
        titleImage={titleImage}
        title={title}
        imageContainerStyle={imageContainerStyle}
        imageStyle={imageStyle}
      />

      <TopStoryBottomDetail
        brandName={brandName}
        brandLogoPath={brandLogoPath}
        brandId={brandId}
        sportName={sportName}
        publishedAt={publishedAt}
        neutralMedium={neutralMedium}
        primary={primary}
        neutralLight={neutralLight}
        containerStyle={containerStyle}
      />
      <TopStoryTitle
        title={title}
        neutralMedium={neutralMedium}
        containerStyle={containerStyle}
      />
    </WidgetWrapper>
  );
}

export default TopStories;
