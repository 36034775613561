import React from "react";
import { Box, Button } from "@mui/material";

function ActionButtons({ isSubmitting, onCancel }) {
  return (
    <Box marginTop={2} display="flex" justifyContent="flex-end" gap={1}>
      <Button variant="outlined" onClick={onCancel}>
        Cancel
      </Button>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={isSubmitting}
      >
        Submit
      </Button>
    </Box>
  );
}

export default ActionButtons;
