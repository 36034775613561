import { useTheme } from "@emotion/react";
import DOMPurify from "dompurify";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRandomSponsor } from "redux/actions/sponsorActions";
import { createSponsorsProfileClick } from "redux/actions/sponsorsProfileClickActions";

function useAdvertWidgetState() {
  const dispatch = useDispatch();
  const currentSponsor = useSelector((state) => state.currentSponsor); // Update the state path as necessary

  const { palette } = useTheme();
  const dark = palette.neutral.dark;
  const medium = palette.neutral.medium;
  const main = palette.neutral.main;

  useEffect(() => {
    dispatch(fetchRandomSponsor());
  }, [dispatch]);

  if (!currentSponsor) {
    return <div>Loading...</div>; // Or any other loading state representation
  }

  // Function to remove http://www. or https://www. from urls and truncate if longer than 20 characters
  const formatWebsiteAddress = (websiteUrl) => {
    let simpleUrl = websiteUrl.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");

    simpleUrl = simpleUrl.replace(
      /(.*?\.(?:com|co\.uk|org|net|io|info))(\/|\?|#).*/i,
      "$1"
    );

    // Truncate and add dots if longer than 50 characters
    if (simpleUrl.length > 20) {
      simpleUrl = `${simpleUrl.substring(0, 17)}...`;
    }

    return simpleUrl;
  };

  // Function to replace consecutive '<p><br></p>' tags
  const replaceConsecutiveBreaks = (match) => {
    // Count the number of occurrences
    const count = match.split("<p><br></p>").length - 1;
    // Replace with one fewer '<br>' than the count
    return "<br>".repeat(Math.max(count - 1, 0));
  };

  const sanitizeContent = (htmlContent) => {
    // Replace consecutive occurrences of '<p><br></p>'
    const updatedContent = DOMPurify.sanitize(htmlContent).replace(
      /(<p><br><\/p>)+/g,
      replaceConsecutiveBreaks
    );

    return {
      __html: updatedContent,
    };
  };

  const sponsorProfileClick = async ({ fromPage }) => {
    console.log("Sponsor profile clicked from: ", fromPage);
    console.log("Current sponsor: ", currentSponsor._id);
    try {
      await dispatch(createSponsorsProfileClick(currentSponsor._id, fromPage));
    } catch (error) {
      console.error(error);
    }
  };

  return {
    currentSponsor,
    dark,
    medium,
    main,
    formatWebsiteAddress,
    sanitizeContent,
    sponsorProfileClick,
  };
}

export default useAdvertWidgetState;
