import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Box,
  TextField,
  FormControl,
  Button,
  Select,
  MenuItem,
  InputLabel,
  useTheme,
  Typography,
  Avatar,
  Autocomplete,
} from "@mui/material";
import { GroupEditContext } from "./GroupEditContext";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, Link } from "react-router-dom";
import { updateGroup } from "redux/actions/groupActions"; // Import your action to update the group
import WidgetWrapper from "components/WidgetWrapper";
import SportSelection from "scenes/SettingsAndPrivacy/profileEdit/components/SportSelection";
import { debounce } from "lodash";
import {
  fetchLocationDetails,
  fetchLocations,
} from "redux/actions/locationActions";
import SnackbarComponent from "components/SnackbarComponent";

const GroupEditWidget = ({ userId, userPicturePath, fullName }) => {
  const {
    groupName,
    setGroupName,
    privacy,
    setPrivacy,
    visibility,
    setVisibility,
    selectedSport,
    setSelectedSport,
    experienceLevel,
    setExperienceLevel,
    description,
    setDescription,
    selectedLocation,
    setSelectedLocation,
  } = useContext(GroupEditContext);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { groupId } = useParams();
  const { palette } = useTheme();

  const main = palette.primary.main;
  const medium = palette.neutral.medium;
  const dark = palette.neutral.dark;

  const [locationInput, setLocationInput] = useState("");
  const [locationOptions, setLocationOptions] = useState([]);
  // eslint-disable-next-line
  const [locationDetails, setLocationDetails] = useState(null);
  // eslint-disable-next-line
  const [locationSelected, setLocationSelected] = useState(false);
  const locations = useSelector((state) => state.locations);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  const handleLocationChange = async (event, newValue) => {
    if (newValue) {
      const details = await dispatch(fetchLocationDetails(newValue.place_id));

      if (details) {
        setSelectedLocation({
          address: details.locationDetails.address,
          town: details.locationDetails.town,
          country: details.locationDetails.country,
          coordinates: [
            details.locationDetails.lng,
            details.locationDetails.lat,
          ],
          placeId: details.locationDetails.placeId,
          locality:
            details.locationDetails.address ===
              details.locationDetails.country &&
            details.locationDetails.town === ""
              ? "National"
              : "Local",
        });
      }
    } else {
      setSelectedLocation({});
    }
  };

  // Debounce function for fetching locations
  const debouncedFetchLocations = useMemo(
    () =>
      debounce(async (input) => {
        try {
          await dispatch(fetchLocations(input));
        } catch (error) {
          console.error("Error fetching locations:", error);
        }
      }, 500),
    [dispatch]
  );

  useEffect(() => {
    if (locationInput !== "") {
      debouncedFetchLocations(locationInput);
    } else {
      setLocationOptions([]);
    }
    return () => {
      if (locationInput === "") {
        debouncedFetchLocations.cancel();
      }
    };
  }, [locationInput, debouncedFetchLocations]);

  useEffect(() => {
    const formattedLocations = locations.map((location) => ({
      ...location,
      description: location.description || "Unknown location",
    }));

    setLocationOptions(formattedLocations);
  }, [locations]);

  useEffect(() => {
    if (locationSelected && locationSelected.place_id) {
      const fetchDetails = async () => {
        const details = await dispatch(
          fetchLocationDetails(locationSelected.place_id)
        );
        setLocationDetails(details.locationDetails);
      };
      fetchDetails();
    }
  }, [locationSelected, dispatch]);

  const handleUpdateGroup = async () => {
    try {
      const payload = {
        _id: groupId,
        name: groupName,
        description: description,
        isPrivate: privacy === "private",
        isVisible: visibility === "visible",
        sport: selectedSport,
        experienceLevel: experienceLevel,
        location: selectedLocation,
      };

      await dispatch(updateGroup(payload));
      setSnackbarMessage("Group updated successfully");
      setSnackbarSeverity("success");

      // wait 2 seconds before navigating to group home
      setTimeout(() => {
        navigate(`/groups/${groupId}`);
      }, 2000);
    } catch (error) {
      console.error("Error updating group:", error);
      setSnackbarMessage("Error updating group");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      <WidgetWrapper title="Edit Group">
        <Box width="100%">
          <Box display="flex" alignItems="center" gap="0.5rem" color={medium}>
            <Link
              to="/groups"
              style={{ textDecoration: "none", cursor: "pointer" }}
              onMouseOver={(e) =>
                (e.currentTarget.style.textDecoration = "underline")
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.textDecoration = "none")
              }
            >
              <Typography variant="body1">Groups</Typography>
            </Link>
            <Typography variant="body1">&gt;</Typography>
            <Link
              to={`/groups/${groupId}`}
              style={{ textDecoration: "none", cursor: "pointer" }}
              onMouseOver={(e) =>
                (e.currentTarget.style.textDecoration = "underline")
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.textDecoration = "none")
              }
            >
              <Typography variant="body1" noWrap>
                {groupName}
              </Typography>
            </Link>
            <Typography variant="body1">&gt;</Typography>
            <Typography variant="body1" color={main} noWrap>
              Edit Group
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5" color={main}>
              Edit Group
            </Typography>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            gap="1rem"
            color={main}
            mt="0.5rem"
          >
            <Avatar
              src={userPicturePath}
              sx={{ width: "60px", height: "60px" }}
              onClick={() => navigate(`/profile/${userId}`)}
              style={{ cursor: "pointer" }}
            />
            <Box>
              <Typography
                variant="h5"
                color={dark}
                fontWeight="500"
                sx={{
                  "&:hover": {
                    color: palette.primary.light,
                    cursor: "pointer",
                  },
                }}
                onClick={() => navigate(`/profile/${userId}`)}
              >
                {fullName}
              </Typography>
              <Typography color={medium}>Admin</Typography>
            </Box>
          </Box>

          <Box mt="1rem">
            {/* Group Name */}
            <TextField
              label="Group Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            />

            {/* Description */}

            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              margin="normal"
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />

            {/* Sports Selection */}

            <FormControl
              fullWidth
              margin="normal"
              variant="outlined"
              style={{ marginTop: "-5px" }}
            >
              <SportSelection
                formProps={{
                  setFieldValue: (fieldPath, newValue) => {
                    setSelectedSport(newValue);
                  },
                  handleBlur: () => {}, // you can add a function to handle blur events
                  touched: {}, // you can update this to reflect the form's touched state
                  errors: {}, // you can update this to reflect the form's error state
                }}
                index={0} // Replace with appropriate index if you have multiple sports
                sport={{ sport: selectedSport }}
              />
            </FormControl>

            {/* Experience Level */}

            <FormControl
              variant="outlined"
              fullWidth
              margin="normal"
              style={{ marginTop: "3px" }}
            >
              <InputLabel id="experience-level-label">
                Experience Level (optional)
              </InputLabel>
              <Select
                labelId="experience-level-label"
                id="experience-level"
                multiple
                value={experienceLevel}
                onChange={(e) => setExperienceLevel(e.target.value)}
                label="Experience Level (optional)"
              >
                <MenuItem value="Beginner">Beginner</MenuItem>
                <MenuItem value="Intermediate">Intermediate</MenuItem>
                <MenuItem value="Advanced">Advanced</MenuItem>
                <MenuItem value="Expert">Expert</MenuItem>
              </Select>
            </FormControl>

            {/* Add FormLocation component */}

            <Autocomplete
              id="group-location-autocomplete"
              options={locationOptions}
              getOptionLabel={(option) => option.description || ""}
              value={selectedLocation.address ? selectedLocation.address : null}
              onInputChange={(event, newInputValue, reason) => {
                if (reason === "input") {
                  setLocationInput(newInputValue);
                }
              }}
              isOptionEqualToValue={(option, value) =>
                option.description === value.description
              } // Added custom equality check function
              onChange={handleLocationChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Address"
                  name="address"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              )}
            />

            {/* Privacy */}

            <FormControl
              variant="outlined"
              fullWidth
              margin="normal"
              style={{ marginTop: "12px" }}
            >
              <InputLabel htmlFor="privacy-select">Privacy</InputLabel>
              <Select
                labelId="privacy-select"
                value={privacy}
                onChange={(e) => setPrivacy(e.target.value)}
                label="Privacy"
              >
                <MenuItem value="public">Public</MenuItem>
                <MenuItem value="private">Private</MenuItem>
              </Select>
            </FormControl>

            {privacy === "private" && (
              <>
                <Typography sx={{ color: medium }}>
                  This is a private group. Only members can see the group's
                  name, description, and posts.
                </Typography>
                <FormControl variant="outlined" fullWidth margin="normal">
                  <InputLabel htmlFor="visibility-select">
                    Visibility
                  </InputLabel>
                  <Select
                    labelId="visibility-select"
                    value={visibility}
                    onChange={(e) => setVisibility(e.target.value)}
                    label="Visibility"
                  >
                    <MenuItem value="visible">Visible</MenuItem>
                    <MenuItem value="hidden">Hidden</MenuItem>
                  </Select>
                </FormControl>
              </>
            )}

            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateGroup}
              disabled={!groupName || !selectedSport || !selectedLocation}
            >
              Update Group
            </Button>
          </Box>
        </Box>
      </WidgetWrapper>
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default GroupEditWidget;
