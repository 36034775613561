import {
  // clearOtherNotifications,
  setNotifications,
  unreadMessagesCount,
} from "../state/index.js";

const BASE_URL = process.env.REACT_APP_BASE_URL;

// fetch notifications

export const fetchNotifications =
  (userId, page = 1, limit = 25) =>
  async (dispatch, getState) => {
    try {
      const token = getState().token;
      const url = `${BASE_URL}/notifications/${userId}/notifications?page=${page}&limit=${limit}`;
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch notifications");
      }

      const {
        notifications,
        totalPages,
        currentPage,
        hasNextPage,
        hasPrevPage,
        totalItems,
        totalUnread,
        totalUnreadMessages,
      } = await response.json();

      // Dispatch actions to update the state as needed
      dispatch(
        setNotifications({
          notifications,
          totalPages,
          currentPage,
          hasNextPage,
          hasPrevPage,
          totalItems,
          totalUnread,
        })
      );

      dispatch(unreadMessagesCount(totalUnreadMessages));
    } catch (error) {
      console.error(error);
    }
  };

// add notification

export const addNotification = (notification) => (dispatch, getState) => {
  const { notifications } = getState(); // Adjusted for clarity
  const updatedNotifications = [...notifications.notifications, notification]; // Make sure this aligns with your state structure
  // Increment totalUnread by 1 for every new notification
  const newTotalUnread = notifications.totalUnread + 1;

  dispatch(
    setNotifications({
      ...notifications,
      notifications: updatedNotifications,
      totalUnread: newTotalUnread,
    })
  );
};

// mark notification as read

export const markNotificationAsRead =
  (notificationId) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      const response = await fetch(
        `${BASE_URL}/notifications/${notificationId}/read`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const updatedNotification = await response.json();
      dispatch(fetchNotifications(updatedNotification.receiverId));
    } catch (error) {
      console.error(error);
    }
  };

// Mark all notifications as read for a user
export const markAllNotificationsAsRead =
  (userId) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      const response = await fetch(
        `${BASE_URL}/notifications/${userId}/readAll`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        dispatch(fetchNotifications(userId));
      }
    } catch (error) {
      console.error(error);
    }
  };

// Mark all "other" notifications as read for a user
export const markAllOtherNotificationsAsRead =
  (userId) => async (dispatch, getState) => {
    try {
      const token = getState().token;

      await fetch(`${BASE_URL}/notifications/${userId}/readAllOther`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // After marking them as read, fetch the notifications to update the state
      dispatch(fetchNotifications(userId));
    } catch (error) {
      console.error(error);
    }
  };

// Mark all notifications as read for a specific chat for a user
export const markChatNotificationsAsRead =
  (userId, chatId) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      const response = await fetch(
        `${BASE_URL}/notifications/${userId}/chat/${chatId}/readAll`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        dispatch(fetchNotifications(userId));
      }
    } catch (error) {
      console.error(error);
    }
  };
