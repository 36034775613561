import { Box, TextField, Typography, useTheme } from "@mui/material";
import React from "react";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/material.css";

function BrandInfoFormEdit({ brandData, setBrandData, formErrors }) {
  const { palette } = useTheme();
  const backgroundAlt = palette.background.alt;
  const neutralDark = palette.neutral.dark;

  const handlePhoneChange = (phone) => {
    // Check if the phone number starts with '+'; if not, prepend it
    const formattedPhone = phone.startsWith("+") ? phone : `+${phone}`;
    setBrandData({ ...brandData, phone: formattedPhone });
  };

  return (
    <Box display="flex" flexDirection="column" gap="0.5rem" width="100%">
      <Typography variant="h5" marginBottom="-8px">
        Brand Info
      </Typography>
      <TextField
        margin="dense"
        label="Brand Name"
        name="name"
        value={brandData.name || ""}
        onChange={(e) => setBrandData({ ...brandData, name: e.target.value })}
        fullWidth
        variant="outlined"
        error={!!formErrors.name}
        helperText={formErrors.name}
      />
      {/* Phone Input Field */}
      <Box my={1}>
        <PhoneInput
          country={"gb"} // default country
          preferredCountries={["gb", "us", "ca", "au", "nz", "ie", "za"]}
          value={brandData.phone || ""}
          onChange={handlePhoneChange}
          inputProps={{
            name: "phone",
            required: true,
            autoFocus: true,
          }}
          inputStyle={{
            width: "100%",
            backgroundColor: backgroundAlt,
            color: neutralDark,
            fontSize: 14,
            borderColor: formErrors.phone ? "#d74343" : undefined,
          }} // Adjust styling as needed
          error={!!formErrors.phone}
          helperText={formErrors.phone}
          international
          withCountryCallingCode
        />
        {formErrors.phone && (
          <p
            style={{
              color: "#d74343",
              fontSize: "10px",
              marginLeft: "1rem",
              fontWeight: 500,
              marginTop: "3px",
            }}
          >
            {formErrors.phone}
          </p>
        )}
      </Box>

      <TextField
        margin="dense"
        label="Website"
        name="website"
        value={brandData.website || ""}
        onChange={(e) =>
          setBrandData({ ...brandData, website: e.target.value })
        }
        fullWidth
        variant="outlined"
        error={!!formErrors.website}
        helperText={formErrors.website}
      />
    </Box>
  );
}

export default BrandInfoFormEdit;
