import { Box, Button } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import React from "react";

function FormActionButtons({
  addTextBlock,
  addImageBlock,
  handleCreateArticle,
}) {
  return (
    <FlexBetween>
      <Box>
        <Button
          variant="outlined"
          color="primary"
          onClick={addTextBlock}
          sx={{
            marginRight: "1rem",
          }}
        >
          Add Text Block
        </Button>
        <Button variant="outlined" color="primary" onClick={addImageBlock}>
          Add Image Block
        </Button>
      </Box>

      <Button variant="contained" color="primary" onClick={handleCreateArticle}>
        Create Article
      </Button>
    </FlexBetween>
  );
}

export default FormActionButtons;
