import { Typography } from "@mui/material";
import React from "react";

function StoriesJustForYouSegmentTitle({ isLargeScreen }) {
  return (
    <Typography
      variant="h3"
      fontWeight="bold"
      pb={1}
      sx={{ padding: isLargeScreen ? undefined : "0 1rem" }}
    >
      Just for You
    </Typography>
  );
}

export default StoriesJustForYouSegmentTitle;
