import { useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLatestFollowedBrandArticles } from "redux/actions/articleActions";

const useStoriesJustForYouState = () => {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const primary = palette.primary.main;
  const primaryDark = palette.primary.dark;
  const neutralLight = palette.neutral.light;
  const neutralMedium = palette.neutral.medium;

  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  useEffect(() => {
    try {
      dispatch(fetchLatestFollowedBrandArticles());
    } catch (err) {
      console.log(err);
    }
  }, [dispatch]);

  const latestFollowedBrandArticles =
    useSelector((state) => state.latestFollowedBrandArticles) || [];

  const latestFollowedBrandArticlesCount = latestFollowedBrandArticles.length;

  const mode = useSelector((state) => state.mode);

  return {
    latestFollowedBrandArticles,
    latestFollowedBrandArticlesCount: latestFollowedBrandArticlesCount || 0,
    primary: primary || "", // Ensure default values
    primaryDark: primaryDark || "",
    neutralLight: neutralLight || "",
    neutralMedium: neutralMedium || "",
    isLargeScreen: isLargeScreen || false,
    mode: mode || "",
  };
};

export default useStoriesJustForYouState;
