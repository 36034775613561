import { ChevronLeft } from "@mui/icons-material";
import {
  Avatar,
  Box,
  ClickAwayListener,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createChat } from "redux/actions/chatActions";
import { searchUsers } from "redux/actions/userActions";

function SideDrawer({ isSidebarOpen, setIsSidebarOpen, setSelectedChat }) {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const isLargeScreen = useMediaQuery("(min-width:1000px)");
  const user = useSelector((state) => state.user);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [loadingChat, setLoadingChat] = useState(false);

  const neutralLight = palette.neutral.light;
  const backgroundAlt = palette.background.alt;
  const primaryLight = palette.primary.light;
  const primary = palette.primary.main;
  const primaryDark = palette.primary.dark;

  const chats = useSelector((state) => state.chats);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const accessChat = async (userId) => {
    try {
      // Check if there's an existing chat with the selected user
      const existingChat = chats.find((chat) =>
        chat.users.some((user) => user._id === userId)
      );

      if (existingChat) {
        // Redirect to the existing chat
        setSelectedChat(existingChat._id);
      } else {
        // Logic to create a new chat
        setLoadingChat(true);
        const newChat = await dispatch(
          createChat(userId, false, [userId, user._id], [user._id])
        );
        setSelectedChat(newChat._id);
      }
      setIsSidebarOpen(!isSidebarOpen);
    } catch (error) {
      console.error("Failed to create/access chat:", error);
    } finally {
      setLoadingChat(false);
    }
  };

  useEffect(() => {
    if (searchTerm) {
      const page = 1;
      const limit = 10;

      dispatch(searchUsers(searchTerm, page, limit))
        .then((response) => {
          // Access the 'data' property of the response
          const responseData = response.data;

          // Filter out the logged-in user from the search results
          const filteredResults = responseData.filter(
            (searchedUser) => searchedUser._id !== user._id
          );

          setSearchResults(filteredResults);
        })
        .catch((error) => {
          console.log("An error occurred while searching:", error);
        });
    } else {
      setSearchResults([]);
    }
  }, [searchTerm, dispatch, user._id]);

  const drawerContent = (
    <Drawer
      open={isSidebarOpen}
      onClose={() => setIsSidebarOpen(false)}
      variant="persistent"
      anchor="left"
      sx={{
        width: "350px",
        "& .MuiDrawer-paper": {
          // color: lightSecondary,
          backgroundColor: backgroundAlt,
          boxSizing: "border-box",
          borderWidth: isLargeScreen ? 0 : "2px",
          width: isLargeScreen ? "31%" : "98%",
          top: "93px", // start below the navbar
          height: "calc(100vh - 93px)",
          border: `2px solid ${primary}`,
          borderRadius: "0 10px 10px 0",
        },
      }}
    >
      <Box width="100%">
        <Box margin="1.5rem 2rem 1rem 3rem">
          <FlexBetween color={primary}>
            <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
              <ChevronLeft />
            </IconButton>
            <Box display="flex" alignItems="center" gap="0.5rem">
              <Typography variant="h5" fontWeight="bold">
                Search Users
              </Typography>
            </Box>
          </FlexBetween>
        </Box>
        <Divider />
        <Box margin="0 1rem">
          <Box>
            <TextField
              placeholder="Search by name"
              value={searchTerm}
              onChange={handleSearch}
              sx={{ width: "100%", marginTop: "1rem" }}
            />
            <List>
              {searchResults?.map((searchedUser) => (
                <ListItem key={searchedUser._id} sx={{ padding: "0.5rem 0" }}>
                  <ListItemButton
                    onClick={() => {
                      accessChat(searchedUser._id);
                    }}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0",
                      backgroundColor: neutralLight,
                      borderRadius: "25px",
                      "&:hover": {
                        backgroundColor: primaryLight,
                        color: primaryDark,
                      },
                    }}
                  >
                    <Avatar
                      src={
                        searchedUser.picturePath.endsWith("/public")
                          ? searchedUser.picturePath.replace(
                              "/public",
                              "/128x128"
                            )
                          : `${searchedUser.picturePath}/128x128`
                      }
                      sx={{ width: "50px", height: "50px" }}
                    />
                    <Box sx={{ width: "80%", paddingLeft: "0.5rem" }}>
                      <ListItemText
                        primary={
                          searchedUser.firstName + " " + searchedUser.lastName
                        }
                        sx={{
                          fontWeight: 500,
                        }}
                      />
                      <ListItemText
                        secondary={searchedUser.sports[0]?.sport}
                        secondaryTypographyProps={{ fontSize: "10px" }}
                      />
                    </Box>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );

  return isSidebarOpen ? (
    <ClickAwayListener onClickAway={() => setIsSidebarOpen(false)}>
      {drawerContent}
    </ClickAwayListener>
  ) : (
    drawerContent
  );
}

export default SideDrawer;
