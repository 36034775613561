import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Avatar,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import WidgetWrapper from "components/WidgetWrapper";
import React from "react";

import useBlockUsers from "./hooks/useBlockUsers";
import { Link } from "react-router-dom";

function BlockedUsers() {
  const {
    isLargeScreen,
    blockedUsers,
    softBlockedUsers,
    handleUnblock,
    handleUnsoftBlock,
    blockUserCount,
    softBlockUserCount,
  } = useBlockUsers();

  return (
    <Box flexBasis={isLargeScreen ? "60%" : undefined} px={2}>
      <WidgetWrapper>
        <Box
          component="h3"
          sx={{
            fontSize: "1.25rem",
            fontWeight: 500,
            mb: 2,
          }}
        >
          Users You've Blocked
        </Box>
        <Box
          // component="p"
          sx={{
            fontSize: "1rem",
            color: "text.secondary",
            mb: 2,
          }}
        >
          {blockUserCount === 0 && softBlockUserCount === 0 ? (
            <p>
              You haven't blocked any users yet. When you block a user, they
              can't see your posts, comments, or profile. Soft-blocked users can
              see your posts and comments but can't interact with you.
            </p>
          ) : (
            <p>
              Manage the users you've blocked. Blocked users can't see your
              posts, comments, or profile. Soft-blocked users can see your posts
              and comments but can't interact with you.
            </p>
          )}
        </Box>

        <Box display="flex" flexDirection="column" gap={2}>
          {blockUserCount > 0 && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">
                  Blocked Users - {blockUserCount}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {blockedUsers.map((blockedUser) => (
                    <ListItem key={blockedUser._id}>
                      <Link to={`/profile/${blockedUser._id}`}>
                        <Box
                          display="flex"
                          alignItems="center"
                          flexDirection="row"
                          gap={2}
                        >
                          <Avatar
                            src={blockedUser.picturePath}
                            alt={`${blockedUser.firstName} ${blockedUser.lastName}`}
                          />
                          <ListItemText
                            primary={`${blockedUser.firstName} ${blockedUser.lastName}`}
                          />
                        </Box>
                      </Link>
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="unblock"
                          onClick={() => handleUnblock(blockedUser._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          )}

          {softBlockUserCount > 0 && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">
                  Soft-Blocked Users - {softBlockUserCount}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {softBlockedUsers.map((softBlockedUser) => (
                    <ListItem key={softBlockedUser._id}>
                      <Link to={`/profile/${softBlockedUser._id}`}>
                        <Box
                          display="flex"
                          alignItems="center"
                          flexDirection="row"
                          gap={2}
                        >
                          <Avatar
                            src={softBlockedUser.picturePath}
                            alt={`${softBlockedUser.firstName} ${softBlockedUser.lastName}`}
                          />
                          <ListItemText
                            primary={`${softBlockedUser.firstName} ${softBlockedUser.lastName}`}
                          />
                        </Box>
                      </Link>
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="unsoftblock"
                          onClick={() => handleUnsoftBlock(softBlockedUser._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
      </WidgetWrapper>
    </Box>
  );
}

export default BlockedUsers;
