import { Avatar, Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllGroups } from "redux/actions/groupActions";
import DataGridCustomToolbar from "../components/DataGridCustomToolbar";
import { EditOutlined } from "@mui/icons-material";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function GroupGrid() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { palette } = useTheme();
  const backgroundAlt = palette.background.alt;
  const primaryLight = palette.primary.light;
  const primaryDark = palette.primary.dark;

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");

  const [searchInput, setSearchInput] = useState("");

  const groups = useSelector((state) => state.groups) || []; // Assuming you store groups in state.groups
  const totalGroups = useSelector((state) => state.totalGroups) || 0; // Similarly for total groups

  useEffect(() => {
    dispatch(fetchAllGroups(page, pageSize, sort, search)); // Using useDispatch to call fetchGroups
  }, [page, pageSize, sort, search, dispatch]);

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      renderCell: (params) => {
        // Assuming each group might have an 'avatarUrl' field. Adjust if different.
        const avatarUrl = params.row.image.endsWith("/public")
          ? params.row.image.replace("/public", "/128x128")
          : `${params.row.image}/128x128`;
        return (
          <Box display="flex" alignItems="center">
            <Avatar src={avatarUrl} alt={params.value} sx={{ mr: 1 }} />
            {params.value}
          </Box>
        );
      },
    },
    {
      field: "sport",
      headerName: "Sport",
      flex: 2,
    },
    {
      field: "isPrivate",
      headerName: "Private",
      flex: 1,
    },
    {
      field: "isVisible",
      headerName: "Visible",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      valueFormatter: (params) => moment(params.value).format("DD/MM/YYYY"),
    },
    {
      field: "edit",
      headerName: "Edit",
      flex: 0.5,
      renderCell: (params) => (
        <button
          style={{ backgroundColor: "transparent", border: "none" }} // Set button background to transparent and remove border
          // onClick navigate the user to the edit page for the group
          onClick={() => {
            navigate(`/groups/${params.row._id}/edit`);
          }}
        >
          <EditOutlined
            sx={{ color: primaryDark }} // Set icon color to primaryDark
          />
        </button>
      ),
    },
  ];

  return (
    <Box
      mt="40px"
      height="75vh"
      sx={{
        "& .MuiDataGrid-root": {
          // border: "none",
        },
        "& .MuiDataGrid-cell": {
          // borderBottom: "none",
          whiteSpace: "normal",
          wordBreak: "break-word",
          overflow: "visible",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: backgroundAlt,
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: backgroundAlt,
        },
        "& .MuiDataGrid-footerContainer": {
          backgroundColor: primaryLight,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {},
        "& .MuiDataGrid-row": {
          height: "auto", // This ensures the row height adapts to content
        },
      }}
    >
      <DataGrid
        getRowId={(row) => {
          return row._id;
        }}
        rows={groups}
        columns={columns}
        page={page}
        pageSize={pageSize}
        rowCount={totalGroups}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        onPageChange={(params) => {
          setPage(params);
        }}
        onPageSizeChange={(newPageSize) => {
          setPage(0);
          setPageSize(newPageSize);
        }}
        disableSelectionOnClick
        paginationMode="server"
        autoHeight={true}
        onSortModelChange={(model) => {
          if (model.length) {
            setSort(`${model[0].field}:${model[0].sort}`);
          } else {
            setSort("");
          }
        }}
        components={{
          Toolbar: DataGridCustomToolbar,
        }}
        rowHeight={50}
        componentsProps={{
          toolbar: {
            onSearch: (value) => {
              setSearch(value);
              setPage(0); // Reset page when a new search is executed
            },
            searchInput,
            setSearchInput,
            setSearch,
          },
        }}
      />
    </Box>
  );
}

export default GroupGrid;
