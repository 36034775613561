import React from "react";
import { IoPersonAddOutline } from "react-icons/io5";
import { IconContext } from "react-icons";
import { Avatar, Typography } from "@mui/material";

const UserTagSuggestionComponent = ({
  displayUser,
  hover,
  hoverTarget,
  hoverTargetUser,
  setHover,
  handleSelectMentionUser,
  addFriend,
  user,
  hoverForNewFriend,
  hoverForNewFriendIndex,
  setHoverForNewFriend,
  hoverAddFriend,
  primaryLight,
  primaryDark,
  mediumMain,
  neutralLight,
}) => {
  return (
    <ul style={{ height: "auto", overflow: "auto", maxHeight: "400px" }}>
      {displayUser.map((person, index) => {
        return (
          <li
            key={index}
            style={{
              width: "95%",
              listStyleType: "none",
              display: "flex",
              justifyContent: "space-between",
              height: "55px",
              alignItems: "center",
              cursor: "pointer",
              scale: hover && hoverTarget === index && "1.03",
              transition: "all 0.1s",
              backgroundColor:
                hover && hoverTarget === index ? mediumMain : neutralLight,
              borderRadius: "10px",
              margin: "0.5rem 0",
            }}
          >
            <div
              onMouseEnter={() => {
                hoverTargetUser(index);
              }}
              onMouseLeave={() => {
                setHover(false);
              }}
              style={{
                width: "95%",
                listStyleType: "none",
                display: "flex",
                justifyContent: "space-around",
                height: "60px",
                alignItems: "center",
                cursor: "pointer",
                scale: hover && hoverTarget === index && "1.05",
                transition: "all 0.1s",
              }}
              onClick={() => {
                handleSelectMentionUser(person);
              }}
            >
              <Avatar
                src={
                  person.picturePath.endsWith("/public")
                    ? person.picturePath.replace("/public", "/128x128")
                    : `${person.picturePath}/128x128`
                }
                sx={{ width: "40px", height: "40px" }}
              />
              <Typography
                variant="h6"
                color={
                  hover && hoverTarget === index ? primaryLight : primaryDark
                }
                sx={{ fontWeight: "500" }}
                display="flex"
                style={{ width: "50%" }}
              >
                {person.firstName} {person.lastName}
              </Typography>
            </div>
            {user.friends.filter((user) => {
              return user._id === person._id;
            }).length === 0 ? (
              <IconContext.Provider
                value={{
                  color:
                    hoverForNewFriend && hoverForNewFriendIndex === index
                      ? "#D6F1F3"
                      : "#00686f",
                  size: "25px",
                }}
              >
                <IoPersonAddOutline
                  onClick={(person) => {
                    addFriend(person);
                  }}
                  onMouseEnter={() => {
                    hoverAddFriend(index);
                  }}
                  onMouseLeave={() => {
                    setHoverForNewFriend(false);
                  }}
                  style={{ height: "100%" }}
                />
              </IconContext.Provider>
            ) : (
              <IconContext.Provider value={{ color: "#14213d", size: "25px" }}>
                <IoPersonAddOutline style={{ opacity: "0" }} />
              </IconContext.Provider>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default UserTagSuggestionComponent;
