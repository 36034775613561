import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useParams,
} from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme/theme";

import { useSocket } from "./useSocket"; // Import the new useSocket hook

import HomePage from "scenes/homePage";
import LoginPage from "scenes/loginPage";
import ActivatePage from "scenes/loginPage/activatePage";
import VerificationEmailSent from "scenes/loginPage/verificationEmailSent";
import ResetPasswordPage from "scenes/loginPage/ResetPasswordPage";
import ProfilePage from "scenes/profilePage";
import MyGroups from "scenes/groups/Groupshome/MyGroups";
import PostPage from "scenes/PostPage";
import Discover from "scenes/groups/Groupshome/Discover";
import Groups from "scenes/groups/Groupshome";
import Group from "scenes/groups/Group/GroupHome";
import Create from "scenes/groups/GroupCreate";
import PostsWidget from "scenes/widgets/PostsWidget";
import ChatPage from "scenes/chatPage";
import Layout from "scenes/admin/layout";
import Dashboard from "scenes/admin/dashboard";
import Users from "scenes/admin/users";
import Sports from "scenes/admin/sports";
import UserLeads from "scenes/admin/userLeads";
import News from "scenes/news";
import FindMembers from "scenes/directory/findMembers";
import Roles from "scenes/admin/Roles";
import Location from "scenes/admin/locations";
import ProtectedElement from "protectedRoute";
import BrandLayout from "scenes/BrandPortal/layout";
import BrandDashboard from "scenes/BrandPortal/dashboard";
import BrandUsers from "scenes/BrandPortal/users";
import BrandCreate from "scenes/BrandPortal/createArticle/create";
import BrandPreviousArticles from "scenes/BrandPortal/previousArticles";
import BrandStats from "scenes/BrandPortal/stats";
import BrandLeads from "scenes/BrandPortal/leads";
import BrandLandingPage from "scenes/BrandPortal/landing";
// import BrandSubscriptions from "scenes/BrandPortal/subscription";
// import BrandStripeSuccess from "scenes/BrandPortal/subscription/stripeSuccess";
// import BrandStripeCancel from "scenes/BrandPortal/subscription/stripeCancel";
// import BrandSubscriptionAccount from "scenes/BrandPortal/subscription/brandSubscriptionAccount";
import Directory from "scenes/admin/directory";
import BrandDirectory from "scenes/directory/brand";
import BrandPage from "scenes/BrandPage";
import BrandEdit from "scenes/admin/directory/BrandEdit";
import BrandArticle from "scenes/article/ArticlePage";
import ArticleEdit from "scenes/article/ArticleEdit/articleEdit";
import UserProfileEdit from "scenes/SettingsAndPrivacy/profileEdit";
import PostFeedPage from "scenes/postFeedPage";
import AdminGroups from "scenes/admin/adminGroups";
import GroupEditWidget from "scenes/groups/Group/GroupEdit";
import SportsDirectoy from "scenes/directory/sports";
import FriendsPage from "scenes/friendsPage";
import Sponsors from "scenes/admin/sponsors";
import PrivacyPolicy from "scenes/siaAdminPages/privacyPolicy";
import TermsAndConditions from "scenes/siaAdminPages/termsAndConditions";
import SettingsAndPrivacy from "scenes/SettingsAndPrivacy/layout";
import NotificationSettings from "scenes/SettingsAndPrivacy/notifications";
import DarkMode from "scenes/SettingsAndPrivacy/darkMode";
import { HelmetProvider } from "react-helmet-async";
import InviteFriends from "scenes/SettingsAndPrivacy/InviteFriends";
import ProfileQuestionnaire from "scenes/SettingsAndPrivacy/profileQuestionnaire";
import FilterMembers from "scenes/directory/filterMembers";
import SettingsLandingPage from "scenes/SettingsAndPrivacy/SettingsLandingPage";
import Flagged from "scenes/admin/flagged";
import ContactSupport from "scenes/siaAdminPages/contactSupport";
import ContactUs from "scenes/siaAdminPages/contactUs";
import ResendActivationEmail from "scenes/loginPage/resendActivationEmail";
import RecommnededUsers from "scenes/admin/recommendedUsers";
import BlockedUsers from "scenes/SettingsAndPrivacy/blockUsers";
import ScrollTop from "components/ScrollTop";
import FollowedBrandsPage from "scenes/FollowedBrandsPage";
import UserGroupsPage from "scenes/UserGroupsPage";

function ResetPasswordPageRoute() {
  const { token } = useParams();
  return <ResetPasswordPage token={token} />;
}

function App() {
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const socket = useSocket(); // Use the custom socket hook
  const isAuth = Boolean(useSelector((state) => state.token));

  return (
    <div className="app">
      <HelmetProvider>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <ScrollTop />
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/activate/:token" element={<ActivatePage />} />
              <Route
                path="/verification-sent"
                element={<VerificationEmailSent />}
              />
              <Route
                path="/resend-activation"
                element={<ResendActivationEmail />}
              />
              <Route
                path="/reset-password/:token"
                element={<ResetPasswordPageRoute />}
              />
              <Route
                path="/home"
                element={
                  isAuth ? <HomePage socket={socket} /> : <Navigate to="/" />
                }
              />
              <Route
                path="/news"
                element={
                  isAuth ? <News socket={socket} /> : <Navigate to="/" />
                }
              />
              <Route
                path="/news/:tag"
                element={
                  isAuth ? <News socket={socket} /> : <Navigate to="/" />
                }
              />

              <Route
                path="/news/article/:articleId"
                element={<BrandArticle socket={socket} />}
              />
              <Route
                path="/news/article/:articleId/edit"
                element={
                  isAuth ? <ArticleEdit socket={socket} /> : <Navigate to="/" />
                }
              />

              <Route
                path="/findmembers"
                element={
                  isAuth ? <FindMembers socket={socket} /> : <Navigate to="/" />
                }
              />

              <Route
                path="/findmembers/filter"
                element={
                  isAuth ? (
                    <FilterMembers socket={socket} />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />

              <Route
                path="/sports"
                element={
                  isAuth ? (
                    <SportsDirectoy socket={socket} />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />

              <Route
                path="/profile/:userId"
                element={
                  isAuth ? <ProfilePage socket={socket} /> : <Navigate to="/" />
                }
              />

              <Route
                path="/profile/:userId/friends"
                element={
                  isAuth ? <FriendsPage socket={socket} /> : <Navigate to="/" />
                }
              />
              <Route
                path="/profile/:userId/brands"
                element={
                  isAuth ? (
                    <FollowedBrandsPage socket={socket} />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/profile/:userId/groups"
                element={
                  isAuth ? (
                    <UserGroupsPage socket={socket} />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />

              <Route
                path="/post"
                element={
                  isAuth ? (
                    <PostFeedPage socket={socket} />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />

              <Route
                path="/post/:postId"
                element={
                  <PostPage socket={socket} /> // No need to check for auth here
                }
              />
              <Route
                path="/groups"
                element={
                  isAuth ? <Groups socket={socket} /> : <Navigate to="/" />
                }
              >
                <Route
                  path="/groups"
                  element={
                    isAuth ? (
                      <Navigate to="/groups/feed" />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/groups/feed"
                  element={
                    isAuth ? (
                      <PostsWidget socket={socket} />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/groups/discover"
                  element={
                    isAuth ? <Discover socket={socket} /> : <Navigate to="/" />
                  }
                />
                <Route
                  path="/groups/mygroups"
                  element={
                    isAuth ? <MyGroups socket={socket} /> : <Navigate to="/" />
                  }
                />
              </Route>
              <Route
                path="/groups/create"
                element={
                  isAuth ? <Create socket={socket} /> : <Navigate to="/" />
                }
              />
              <Route
                path="/groups/:groupId"
                element={
                  isAuth ? <Group socket={socket} /> : <Navigate to="/" />
                }
              />
              <Route
                path="/groups/:groupId/edit"
                element={
                  isAuth ? (
                    <GroupEditWidget socket={socket} />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/chat"
                element={
                  isAuth ? <ChatPage socket={socket} /> : <Navigate to="/" />
                }
              />

              <Route
                path="brand-directory"
                element={
                  isAuth ? (
                    <BrandDirectory socket={socket} />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />

              <Route
                path="/settings"
                element={
                  isAuth ? (
                    <SettingsAndPrivacy socket={socket} />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              >
                <Route path="/settings" element={<SettingsLandingPage />} />
                <Route path="profile" element={<UserProfileEdit />} />
                <Route
                  path="profile-questions"
                  element={<ProfileQuestionnaire />}
                />
                <Route
                  path="notifications"
                  element={<NotificationSettings />}
                />
                <Route path="dark-mode" element={<DarkMode />} />
                <Route path="invite-friends" element={<InviteFriends />} />
                <Route path="blocked-users" element={<BlockedUsers />} />
              </Route>

              <Route
                path="/brand"
                element={
                  isAuth ? <BrandLayout socket={socket} /> : <Navigate to="/" />
                }
              >
                <Route path="/brand" element={<BrandDashboard />} />
                <Route path="dashboard" element={<BrandDashboard />} />
                <Route path="create" element={<BrandCreate />} />
                <Route
                  path="history"
                  element={<BrandPreviousArticles socket={socket} />}
                />
                <Route path="stats" element={<BrandStats />} />
                <Route path="leads" element={<BrandLeads />} />
                <Route path="landing" element={<BrandLandingPage />} />
                <Route path="roles" element={<BrandUsers />} />
                {/* <Route path="subscription" element={<BrandSubscriptions />} />
              <Route
                path="subscription/success"
                element={<BrandStripeSuccess />}
              />
              <Route
                path="subscription/cancel"
                element={<BrandStripeCancel />}
              />
              <Route
                path="subscription/account"
                element={<BrandSubscriptionAccount />}
              /> */}
              </Route>
              <Route
                path="/brand/:brandId"
                element={
                  isAuth ? <BrandPage socket={socket} /> : <Navigate to="/" />
                }
              />

              <Route
                path="/brand/:brandId/edit"
                element={
                  isAuth ? <BrandEdit socket={socket} /> : <Navigate to="/" />
                }
              />

              <Route
                path="/admin"
                element={
                  <ProtectedElement isAdminRequired>
                    <Layout socket={socket} />
                  </ProtectedElement>
                }
              >
                <Route path="/admin" element={<Dashboard />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="users" element={<Users />} />
                <Route path="roles" element={<Roles />} />
                <Route path="locations" element={<Location />} />
                <Route path="groups" element={<AdminGroups />} />
                <Route path="sports" element={<Sports />} />
                <Route path="leads" element={<UserLeads />} />
                <Route path="directory" element={<Directory />} />
                <Route path="sponsors" element={<Sponsors />} />
                <Route path="flagged" element={<Flagged />} />
                <Route path="recommended" element={<RecommnededUsers />} />
              </Route>

              <Route
                path="/privacy-policy"
                element={<PrivacyPolicy socket={socket} />}
              />
              <Route
                path="/user-terms-and-conditions"
                element={<TermsAndConditions socket={socket} />}
              />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/contact-support" element={<ContactSupport />} />
              <Route path="*" element={<Navigate to="/home" />} />
            </Routes>
          </ThemeProvider>
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
}

export default App;
