import WidgetWrapper from "components/WidgetWrapper";
import React from "react";
import StoriesJustForYouSegmentTitle from "./StoriesJustForYouSegmentTitle";
import { Typography, useMediaQuery, useTheme } from "@mui/material";

import { Link } from "react-router-dom";

function StoriesJustForYouNoStories() {
  const isLargeScreen = useMediaQuery("(min-width:1000px)");
  const { palette } = useTheme();
  const primary = palette.primary.main;

  return (
    <WidgetWrapper sx={{ padding: isLargeScreen ? undefined : "1.5rem 0" }}>
      <StoriesJustForYouSegmentTitle isLargeScreen={isLargeScreen} />
      {/* Message to the use to follow brands and then stories will appear for them */}
      <Typography
        variant="h6"
        color="textSecondary"
        sx={{ padding: isLargeScreen ? undefined : "0 1rem" }}
      >
        Follow{" "}
        <Link to="/brand-directory">
          {" "}
          <span style={{ color: primary, fontWeight: "bold" }}>
            brands
          </span>{" "}
        </Link>{" "}
        to see stories just for you
      </Typography>
    </WidgetWrapper>
  );
}

export default StoriesJustForYouNoStories;
