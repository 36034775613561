import {
  ChatBubbleOutlineOutlined,
  DeleteOutline,
  EditOffOutlined,
  EditOutlined,
  FavoriteBorderOutlined,
  FavoriteOutlined,
} from "@mui/icons-material";
import { Box, IconButton, Popover, Tooltip, Typography } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import React, { Fragment, useState } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";

function PostWidgetLikeAndAddCommentButton({
  isLiked,
  likeCount,
  comments,
  setIsComments,
  handlePatchLike,
  secondary,
  isComments,
  isPostAuthor,
  isEditMode,
  handleEditPost,
  handleMarkPostAsDeleted,
  likesUserNames,
  handleMouseEnterLikeButton,
  ShareOutlinedIcon,
  postId,
  description,
}) {
  const showTooltip = likesUserNames.length > 0;

  // get the website url
  const websiteUrl = window.location.origin;
  // get page url
  const pageUrl = `${websiteUrl}/post/${postId}`;

  // State to manage the visibility of the share options
  const [shareAnchorEl, setShareAnchorEl] = useState(null);

  // Function to handle clicking the share button
  const handleShareClick = (event) => {
    setShareAnchorEl(event.currentTarget);
  };

  // Function to close the share options popover
  const handleShareClose = () => {
    setShareAnchorEl(null);
  };

  const open = Boolean(shareAnchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <FlexBetween mt="0.25rem" marginTop="-10px">
      <FlexBetween gap="1rem">
        <FlexBetween gap="0.3rem">
          {showTooltip ? (
            <Tooltip
              title={
                <Fragment>
                  {likesUserNames.map((name, index) => (
                    <Typography
                      key={index}
                      variant="body2"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {name}
                    </Typography>
                  ))}
                </Fragment>
              }
              placement="top"
              arrow
              PopperProps={{
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -10],
                    },
                  },
                ],
              }}
            >
              <IconButton
                onClick={() => handlePatchLike("likePost")}
                onMouseEnter={handleMouseEnterLikeButton}
              >
                {isLiked ? (
                  <FavoriteOutlined sx={{ color: secondary }} />
                ) : (
                  <FavoriteBorderOutlined />
                )}
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton
              onClick={() => handlePatchLike("likePost")}
              onMouseEnter={handleMouseEnterLikeButton}
            >
              {isLiked ? (
                <FavoriteOutlined sx={{ color: secondary }} />
              ) : (
                <FavoriteBorderOutlined />
              )}
            </IconButton>
          )}

          <Typography>{likeCount}</Typography>
        </FlexBetween>

        <FlexBetween gap="0.3rem">
          <IconButton onClick={() => setIsComments(!isComments)}>
            <ChatBubbleOutlineOutlined />
          </IconButton>
          <Typography>{comments?.length}</Typography>
        </FlexBetween>
      </FlexBetween>

      <Box display="flex">
        {isPostAuthor && (
          <>
            <IconButton onClick={handleEditPost}>
              {isEditMode ? <EditOffOutlined /> : <EditOutlined />}
            </IconButton>
            <IconButton onClick={handleMarkPostAsDeleted}>
              <DeleteOutline />
            </IconButton>
          </>
        )}
        <IconButton onClick={handleShareClick}>
          <ShareOutlinedIcon />
        </IconButton>
        {/* Popover for sharing options */}
        <Popover
          id={id}
          open={open}
          anchorEl={shareAnchorEl}
          onClose={handleShareClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box p={2}>
            <Typography variant="body2" mb={1}>
              Share on:
            </Typography>
            <FacebookShareButton
              url={pageUrl}
              quote={"Check this out on SheIsAction!"}
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton
              url={pageUrl}
              title={"Check this out on SheIsAction!"}
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <LinkedinShareButton
              url={pageUrl}
              title={"Check this out on SheIsAction!"}
              source={websiteUrl}
              summary={description}
            >
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
          </Box>
        </Popover>
      </Box>
    </FlexBetween>
  );
}

export default PostWidgetLikeAndAddCommentButton;
