import { Box } from "@mui/material";
import React from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

function UserWidgetMobileTopImage({
  isLargeScreen,
  primaryMain,
  picturePath,
  firstName,
  lastName,
  handleBackClick,
}) {
  return !isLargeScreen ? (
    <Box position="relative">
      <ArrowBackIosNewIcon
        sx={{
          position: "absolute",
          top: "1rem",
          left: "1rem",
          zIndex: 1,
          color: primaryMain,
          fontSize: "2rem",
          cursor: "pointer",
          backgroundColor: "white",
          borderRadius: "50%",
          padding: "0.25rem",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }}
        onClick={handleBackClick}
      />
      <img
        src={picturePath}
        alt={`${firstName} ${lastName}`}
        style={{
          width: "100%",
          height: "350px",
          objectFit: "cover",
        }}
      />
    </Box>
  ) : undefined;
}

export default UserWidgetMobileTopImage;
