import { Box, useMediaQuery } from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { fetchBrand } from "redux/actions/brandActions";
import {
  fetchTotalNumberOfFollowers,
  fetchTotalNumberOfFollowersEndOfLastMonth,
  fetchTotalArticlesPublishedCount,
  fetchArticlesPublishedCountEndOfLastMonth,
  fetchTotalLikesForBrandArticles,
  fetchTotalLikesForBrandArticlesEndOfLastMonth,
  fetchTotalCommentsForBrandArticles,
  fetchTotalCommentsForBrandArticlesByEndOfLastMonth,
} from "redux/actions/dashboardBrandActions";
import Header from "scenes/admin/components/Header";
import StatBox from "../components/StatBox";

const useDashboardData = (selectedBrandId) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedBrandId) {
      dispatch(fetchBrand(selectedBrandId));
      dispatch(fetchTotalNumberOfFollowers(selectedBrandId));
      dispatch(fetchTotalNumberOfFollowersEndOfLastMonth(selectedBrandId));
      dispatch(fetchTotalArticlesPublishedCount(selectedBrandId));
      dispatch(fetchArticlesPublishedCountEndOfLastMonth(selectedBrandId));
      dispatch(fetchTotalLikesForBrandArticles(selectedBrandId));
      dispatch(fetchTotalLikesForBrandArticlesEndOfLastMonth(selectedBrandId));
      dispatch(fetchTotalCommentsForBrandArticles(selectedBrandId));
      dispatch(
        fetchTotalCommentsForBrandArticlesByEndOfLastMonth(selectedBrandId)
      );
    }
  }, [dispatch, selectedBrandId]);

  const brand = useSelector((state) => state.brand);
  const totalNumberOfFollowers = useSelector(
    (state) => state.dashboardBrand.totalNumberOfFollowers
  );
  const totalNumberOfFollowersEndOfLastMonth = useSelector(
    (state) => state.dashboardBrand.totalNumberOfFollowersEndOfLastMonth
  );
  const totalArticlesPublishedCount = useSelector(
    (state) => state.dashboardBrand.totalArticlesPublishedCount
  );
  const articlesPublishedCountLastMonth = useSelector(
    (state) => state.dashboardBrand.totalArticlesPublishedByEndOfLastMonth
  );
  const totalLikesForBrandArticles = useSelector(
    (state) => state.dashboardBrand.totalLikesForBrandArticles
  );
  const totalLikesForBrandArticlesEndOfLastMonth = useSelector(
    (state) => state.dashboardBrand.totalLikesForBrandArticlesByEndOfLastMonth
  );
  const totalCommentsForBrandArticles = useSelector(
    (state) => state.dashboardBrand.totalCommentsForBrandArticles
  );
  const totalCommentsForBrandArticlesByEndOfLastMonth = useSelector(
    (state) =>
      state.dashboardBrand.totalCommentsForBrandArticlesByEndOfLastMonth
  );

  return {
    brand,
    totalNumberOfFollowers,
    totalNumberOfFollowersEndOfLastMonth,
    totalArticlesPublishedCount,
    articlesPublishedCountLastMonth,
    totalLikesForBrandArticles,
    totalLikesForBrandArticlesEndOfLastMonth,
    totalCommentsForBrandArticles,
    totalCommentsForBrandArticlesByEndOfLastMonth,
  };
};

const calculateIncrease = (current, previous) =>
  current !== 0 && previous !== 0
    ? Math.round(((current - previous) / previous) * 100)
    : 0;

function BrandDashboard() {
  const { selectedBrandId } = useOutletContext();
  const isLargeScreen = useMediaQuery("(min-width:1000px)");
  const {
    brand,
    totalNumberOfFollowers,
    totalNumberOfFollowersEndOfLastMonth,
    totalArticlesPublishedCount,
    articlesPublishedCountLastMonth,
    totalLikesForBrandArticles,
    totalLikesForBrandArticlesEndOfLastMonth,
    totalCommentsForBrandArticles,
    totalCommentsForBrandArticlesByEndOfLastMonth,
  } = useDashboardData(selectedBrandId);

  const followerIncrease = calculateIncrease(
    totalNumberOfFollowers,
    totalNumberOfFollowersEndOfLastMonth
  );
  const articlesPublishedIncrease = calculateIncrease(
    totalArticlesPublishedCount,
    articlesPublishedCountLastMonth
  );
  const likesIncrease = calculateIncrease(
    totalLikesForBrandArticles,
    totalLikesForBrandArticlesEndOfLastMonth
  );
  const commentsIncrease = calculateIncrease(
    totalCommentsForBrandArticles,
    totalCommentsForBrandArticlesByEndOfLastMonth
  );

  if (!selectedBrandId) return null;

  return (
    <Box
      m={isLargeScreen ? "1.5rem 2rem 2rem 3rem" : "1.5rem 0.5rem 2rem 0.5rem"}
      pb={isLargeScreen ? 0 : "4rem"}
    >
      <WidgetWrapper>
        <Header
          title="DASHBOARD"
          subtitle={`Welcome to your ${brand.name} dashboard`}
        />
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="160px"
          gap="20px"
          sx={{
            "& > div": { gridColumn: isLargeScreen ? undefined : "span 12" },
          }}
        >
          <StatBox
            title="Followers"
            valuePrimary={totalNumberOfFollowers}
            increasePrimary={`${followerIncrease}%`}
            description={`increase since last month`}
          />
          <StatBox
            title="Articles Published"
            valuePrimary={totalArticlesPublishedCount}
            increasePrimary={`${articlesPublishedIncrease}%`}
            description={`increase since last month`}
          />
          <StatBox
            title="Likes"
            valuePrimary={totalLikesForBrandArticles}
            increasePrimary={`${likesIncrease}%`}
            description={`increase since last month`}
          />
          <StatBox
            title="Comments"
            valuePrimary={totalCommentsForBrandArticles}
            increasePrimary={`${commentsIncrease}%`}
            description={`increase since last month`}
          />
        </Box>
      </WidgetWrapper>
    </Box>
  );
}

export default BrandDashboard;
