import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";

function DialogUpdateYourProfile({
  dialogOpen,
  handleGoBack,
  handleEditProfile,
  primary,
  handleCloseDialog,
}) {
  return (
    <Dialog
      open={dialogOpen}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="span" fontWeight={700} color={primary}>
          Let's Get Your Profile Updated!
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box>
          <img
            src="https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/d04c142e-cc1a-482a-aa41-c661904f3f00/public"
            alt="Update your profile"
            style={{ width: "100%", height: "auto" }}
          />
        </Box>
        <DialogContentText id="alert-dialog-description">
          We've noticed you haven't added any sports to your profile yet.
          Completing your profile with sports you're interested in allows us to
          connect you with like-minded enthusiasts and find the best matches for
          you. Why not take a moment to update your profile now?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleGoBack} color="primary">
          Maybe Later
        </Button>
        <Button onClick={handleEditProfile} color="primary" autoFocus>
          Update Profile
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogUpdateYourProfile;
