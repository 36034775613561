import { Box, Typography, useTheme } from "@mui/material";
import Friend from "components/Friend";
import WidgetWrapper from "components/WidgetWrapper";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchFriends } from "redux/state";
import { createSelector } from "reselect";

// Basic selectors to get parts of the state
const selectRawFriends = (state) => state.user.friends;
const selectCurrentUser = (state) => state.user;

// Memoized selector
const selectFilteredFriends = createSelector(
  [selectRawFriends, selectCurrentUser],
  (friends, currentUser) => {
    if (Array.isArray(friends)) {
      return friends.filter((friend) => friend._id !== currentUser._id);
    }
    return [];
  }
);

const FriendListWidgetAll = ({ userId, socket }) => {
  const dispatch = useDispatch();
  const { palette } = useTheme();

  const loggedInUserId = useSelector((state) => state.user._id);
  const viewingUserFriends = useSelector((state) => state.viewingUserFriends);
  const loggedInUserFriends = useSelector(selectFilteredFriends);

  const friends =
    userId === loggedInUserId ? loggedInUserFriends : viewingUserFriends;

  useEffect(() => {
    const isViewingUser = userId !== loggedInUserId;
    dispatch(fetchFriends(userId, isViewingUser));
  }, [dispatch, userId, loggedInUserId]);

  if (friends.length === 0) {
    return null;
  }

  return (
    <WidgetWrapper data-testid="mock-friends">
      <Typography
        color={palette.neutral.dark}
        variant="h5"
        fontWeight="500"
        sx={{ mb: "1.5rem" }}
      >
        Friend List
      </Typography>
      <Box display="flex" flexDirection="column" gap="1.5rem">
        {friends.map((friend, index) => (
          <Friend
            key={index} // Consider using friend._id if possible
            friendId={friend._id}
            name={`${friend.firstName} ${friend.lastName}`}
            userPicturePath={friend.picturePath}
            socket={socket}
            friend={friend}
          />
        ))}
      </Box>
    </WidgetWrapper>
  );
};

export default FriendListWidgetAll;
