import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { recommendFriend } from "redux/actions/userActions";
import { fetchNonFriends, fetchUser } from "redux/state";
import * as Yup from "yup";

const useHomePageState = ({ selectNonFriends }) => {
  const isLargeScreen = useMediaQuery("(min-width:1000px)");
  const { _id, picturePath } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const nonFriends = useSelector(selectNonFriends);
  // State to manage popup visibility
  const [recommendPopupOpen, setRecommendPopupOpen] = useState(false);

  const firstName = useSelector((state) => state.user.firstName);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  // Use optional chaining and provide a fallback value for lastRecommendation
  const lastRecommendation = useSelector(
    (state) =>
      state.user.recommendations?.[state.user.recommendations.length - 1]
        ?.recommendedAt
  );

  const isReminderRequested = useSelector(
    (state) => state.user.friendsInviteReminder
  );

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  // Define email validation schema using Yup
  const emailSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });
  const nameSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

  // Function to validate email and recommend friend if valid
  const validateAndRecommend = async (email, name) => {
    try {
      // Validate email and name separately and handle their errors individually
      await emailSchema.validate({ email });
      setEmailError(""); // Clear previous email error if validation is successful
    } catch (error) {
      setEmailError(error.message);
    }

    try {
      await nameSchema.validate({ name });
      setNameError(""); // Clear previous name error if validation is successful
    } catch (error) {
      setNameError(error.message);
    }

    // Only proceed with recommendation if both email and name are valid
    if (!emailError && !nameError) {
      handleRecommend(email, name);
      setEmail(""); // Clear email field
      setName(""); // Clear name field
    }
  };

  useEffect(() => {
    dispatch(fetchUser(_id));
  }, [dispatch, _id]);

  const contentBoxStyle = isLargeScreen
    ? { flexGrow: 1, overflow: "auto" }
    : { flexGrow: 1 };

  useEffect(() => {
    if (_id) dispatch(fetchNonFriends(_id)); // dispatch the fetchNonFriends action
  }, [dispatch, _id]);

  // Logic to determine if the RecommendFriendPopup should be shown
  useEffect(() => {
    // Convert lastRecommendation to a Date object and get the time in milliseconds
    const lastRecommendationTime = lastRecommendation
      ? new Date(lastRecommendation).getTime()
      : null;
    const currentTime = new Date().getTime();
    const daysSinceLastRecommendation = lastRecommendationTime
      ? (currentTime - lastRecommendationTime) / (1000 * 3600 * 24)
      : Infinity; // Use Infinity to ensure the popup shows if there's no last recommendation

    // Show popup if it's been more than 7 days or there are no recommendations
    if (daysSinceLastRecommendation > 7 && isReminderRequested) {
      setRecommendPopupOpen(true);
    }
  }, [_id, lastRecommendation, isReminderRequested]); // Dependency on _id and lastRecommendation

  // Handlers for the popup
  const handleRecommend = (recommendedEmail, recommendedName) => {
    // Dispatch the recommendation action

    try {
      const success = dispatch(
        recommendFriend(_id, recommendedEmail, recommendedName)
      );
      if (success) {
        setSnackbarMessage("Recommendation sent successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage("Failed to send recommendation");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error(error);
      setSnackbarMessage("Failed to send recommendation");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }

    setRecommendPopupOpen(false);
  };

  const handlePopupClose = () => {
    dispatch(recommendFriend(_id, "declined"));
    setRecommendPopupOpen(false);
  };

  return {
    isLargeScreen,
    _id,
    picturePath,
    dispatch,
    nonFriends,
    recommendPopupOpen,
    setRecommendPopupOpen,
    firstName,
    snackbarOpen,
    setSnackbarOpen,
    snackbarMessage,
    setSnackbarMessage,
    snackbarSeverity,
    setSnackbarSeverity,
    lastRecommendation,
    contentBoxStyle,
    handleRecommend,
    handlePopupClose,
    email,
    emailError,
    setEmail,
    validateAndRecommend,
    name,
    nameError,
    setName,
  };
};

export default useHomePageState;
