// ImageLogoUploadAndPreview.js
import { ModeEditOutlineOutlined } from "@mui/icons-material";
import { Box, Button, useTheme } from "@mui/material";
import React from "react";

const ImageLogoUploadAndPreview = ({
  logo,
  defaultImageURL,
  fileInputRef,
  handleEditClick,
  handleLogoChange,
}) => {
  const { palette } = useTheme();
  const neutralLight = palette.neutral.light;

  return (
    <Box marginBottom="1rem" display="flex" alignItems="center" gap={5}>
      <img
        src={logo || defaultImageURL}
        alt="Uploaded for Brand"
        style={{ width: "50px", height: "auto", borderRadius: "10px" }}
      />

      <Button
        onClick={handleEditClick}
        startIcon={<ModeEditOutlineOutlined />}
        style={{
          backgroundColor: neutralLight,
        }}
      >
        {logo ? "Edit Logo" : "Add Logo"}
      </Button>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleLogoChange}
      />
    </Box>
  );
};

export default ImageLogoUploadAndPreview;
