import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Box, FormControl, TextField } from "@mui/material";
import React, { useRef, useEffect } from "react";
import Quill from "quill";
import { DatePicker } from "@mui/x-date-pickers";
import FlexBetween from "components/FlexBetween";
import dayjs from "dayjs";
import "quill/dist/quill.snow.css";

function SponsorCreateDetails({
  formData,
  setFormData,
  formErrors,
  handleChange,
  modules,
  formats,
  handleDateChange,
}) {
  const quillRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current && !quillRef.current) {
      quillRef.current = new Quill(containerRef.current, {
        theme: "snow",
        modules: modules,
        formats: formats,
        placeholder: "Description",
      });

      quillRef.current.on("text-change", () => {
        const content = quillRef.current.root.innerHTML;
        setFormData((prevFormData) => ({
          ...prevFormData,
          description: content,
        }));
      });

      quillRef.current.root.innerHTML = formData.description;
    }
  }, [modules, formats, setFormData, formData.description]);

  return (
    <div>
      <TextField
        autoFocus={true}
        margin="dense"
        label="Sponsor Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        fullWidth
        variant="outlined"
        error={!!formErrors.name}
        helperText={formErrors.name}
      />
      <TextField
        margin="dense"
        label="Website"
        name="website"
        value={formData.website}
        onChange={handleChange}
        fullWidth
        variant="outlined"
        error={!!formErrors.website}
        helperText={formErrors.website}
      />
      <FormControl margin="dense" fullWidth variant="outlined">
        <div ref={containerRef} />
        {formErrors.description && (
          <p
            style={{
              color: "#d74343",
              fontSize: "11px",
              marginTop: "0.25rem",
              marginLeft: "1rem",
              fontWeight: "500",
            }}
          >
            {formErrors.description}
          </p>
        )}
      </FormControl>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box mt={2}>
          <FlexBetween gap={1}>
            <DatePicker
              label="Scheduled Start Date"
              format="DD/MM/YYYY"
              value={dayjs(formData.scheduledStart)}
              onChange={(newValue) =>
                handleDateChange("scheduledStart", newValue)
              }
              slotProps={{
                textField: {
                  error: !!formErrors.scheduledStart,
                  helperText: formErrors.scheduledStart,
                },
              }}
            />

            <DatePicker
              label="Scheduled End Date"
              format="DD/MM/YYYY"
              value={dayjs(formData.scheduledEnd)}
              onChange={(newValue) =>
                handleDateChange("scheduledEnd", newValue)
              }
              slotProps={{
                textField: {
                  error: !!formErrors.scheduledEnd,
                  helperText: formErrors.scheduledEnd,
                },
              }}
            />
          </FlexBetween>
        </Box>
      </LocalizationProvider>
    </div>
  );
}

export default SponsorCreateDetails;
