import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import React from "react";

function StatBox({
  title,
  title2,
  icon,
  valuePrimary,
  increasePrimary,
  valueSeconday,
  increaseSecondary,
  description,
}) {
  const { palette } = useTheme();
  const background = palette.background.default;
  const primary = palette.primary.main;
  const primaryDark = palette.primary.dark;
  const neutral = palette.neutral.main;
  const neutralDark = palette.neutral.dark;

  return (
    <Box
      gridColumn="span 2"
      gridRow="span 1"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p="1.25rem 1rem"
      flex="1 1 100%"
      backgroundColor={background}
      borderRadius="0.55rem"
      minWidth={"150px"}
    >
      <FlexBetween>
        <Typography variant="h6" color={primaryDark}>
          {title}
        </Typography>
        {icon}
      </FlexBetween>

      <FlexBetween gap="1rem">
        <Typography variant="h3" color={primary} fontWeight={700}>
          {valuePrimary}
        </Typography>
        <Typography
          variant="h5"
          fontStyle="italic"
          sx={{ color: primary }}
          fontWeight={700}
        >
          {increasePrimary}
        </Typography>
      </FlexBetween>

      <Typography color={neutralDark} fontSize={12} mt={1}>
        {title2}
      </Typography>

      <FlexBetween gap="1rem">
        <Typography variant="h5" fontSize={16} color={neutral} ml={1}>
          {valueSeconday}
        </Typography>
        <Typography
          variant="h6"
          fontStyle="italic"
          fontSize={13}
          sx={{ color: neutral }}
          mr={1}
        >
          {increaseSecondary}
        </Typography>
      </FlexBetween>

      <FlexBetween gap="1rem">
        <Typography sx={{ color: primaryDark }}> {description} </Typography>
      </FlexBetween>
    </Box>
  );
}

export default StatBox;
