import React from "react";
import WidgetWrapper from "components/WidgetWrapper";
import { Box, Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";

import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import SportsSoccerOutlinedIcon from "@mui/icons-material/SportsSoccerOutlined";
import WaterfallChartOutlinedIcon from "@mui/icons-material/WaterfallChartOutlined";

function GroupDetails({ groupId }) {
  const { palette } = useTheme();
  const main = palette.primary.main;
  const mainNeutral = palette.neutral.main;

  // Get group data from redux
  const group = useSelector((state) => state.group);

  // Destructuring the group details
  const {
    name = "",
    sport = null,
    location = null,
    experienceLevel = [],
  } = group;

  const InfoBox = ({ icon: Icon, children }) => (
    <Box
      gap="0.5rem"
      sx={{ display: "flex", alignItems: "center", color: mainNeutral }}
    >
      {Icon && <Icon />}
      {children}
    </Box>
  );

  return (
    <WidgetWrapper>
      <Box width="100%">
        <InfoBox sx={{ color: main }}>
          <Typography variant="h4" sx={{ color: main }}>
            {name.length > 0 ? name : "Group Name"}
          </Typography>
        </InfoBox>

        {sport && (
          <InfoBox icon={SportsSoccerOutlinedIcon}>Sport: {sport}</InfoBox>
        )}
        {experienceLevel.length > 0 ? (
          <InfoBox icon={WaterfallChartOutlinedIcon}>
            Experience Level: {experienceLevel.join(", ")}
          </InfoBox>
        ) : undefined}
        {location && (
          <InfoBox icon={LocationOnOutlinedIcon}>
            {location.address || location}
          </InfoBox>
        )}
      </Box>
    </WidgetWrapper>
  );
}

export default GroupDetails;
