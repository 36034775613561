import { IconButton, InputAdornment, TextField } from "@mui/material";
import React from "react";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";

function ArticlePageAddComment({
  handleCommentSubmit,
  background,
  comment,
  handleCommentChange,
}) {
  return (
    <form onSubmit={(e) => handleCommentSubmit(e, "commentOnArticle")}>
      <TextField
        value={comment}
        onChange={handleCommentChange}
        label="Write a comment"
        fullWidth
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={(e) => handleCommentSubmit(e, "commentOnArticle")}
                edge="end"
              >
                <SendOutlinedIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "1rem",
            background: background,
          },
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: "none",
            },
        }}
      />
    </form>
  );
}

export default ArticlePageAddComment;
