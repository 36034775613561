import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Formik } from "formik";
import {
  Box,
  TextField,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  baseURL: BASE_URL,
});

const passwordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required()
    .min(5, "Password should have a minimum length of 5")
    .max(25, "Password should have a maximum length of 25")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-])[A-Za-z\d@$!%*?&-]{6,25}$/,
      "Password must be 5-25 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one of the following special characters: @$!%*?&"
    ),
});

const ResetPasswordPage = ({ token }) => {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const theme = useTheme();
  const isLargeScreen = useMediaQuery("(min-width: 1000px)");
  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting }) => {
    setErrorMessage("");
    setSuccessMessage("");

    try {
      const response = await api.post("/auth/reset-password", {
        token,
        newPassword: values.newPassword,
      });
      setSuccessMessage(response.data.message);
      setTimeout(() => {
        navigate("/");
      }, 4000);
    } catch (error) {
      setErrorMessage("Error resetting password.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ newPassword: "" }}
      validationSchema={passwordSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Box>
          <Box
            width="100%"
            backgroundColor={theme.palette.background.alt}
            p="1rem 6%"
            textAlign="center"
          >
            <Typography color="primary" variant="h1">
              SHE IS ACTION
            </Typography>
          </Box>
          <Box
            width={isLargeScreen ? "50%" : "93%"}
            p="2rem"
            m="2rem auto"
            borderRadius="1.5rem"
            backgroundColor={theme.palette.background.alt}
            data-testid="box"
          >
            <Typography variant="h4">Reset Password</Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                label="New Password"
                type="password"
                name="newPassword"
                value={values.newPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                fullWidth
                margin="normal"
                error={touched.newPassword && !!errors.newPassword}
                helperText={touched.newPassword && errors.newPassword}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Reset Password
              </Button>
            </form>
            {successMessage && (
              <p>
                {successMessage}. You will now be redirected to the login page
              </p>
            )}
            {errorMessage && <p>{errorMessage}</p>}
          </Box>
        </Box>
      )}
    </Formik>
  );
};

export default ResetPasswordPage;
