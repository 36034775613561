import {
  ModeEditOutlineOutlined,
  ChevronLeft,
  ChevronRight,
} from "@mui/icons-material";
import { Box, Button, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";

const ImageUploadAndPreview = ({
  image,
  defaultImageURL,
  fileInputRef,
  handleEditClick,
  handleFileChange,
  googleImages = [],
  handleGoogleImageSelect,
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = [defaultImageURL, ...googleImages];

  useEffect(() => {
    if (image) {
      setCurrentImageIndex(0); // Reset index to the uploaded image
    } else if (googleImages.length > 0) {
      setCurrentImageIndex(1); // Start with the first Google image if available
    } else {
      setCurrentImageIndex(0); // Default image index
    }
  }, [image, googleImages]);

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleImageSelect = () => {
    if (currentImageIndex === 0) {
      handleEditClick(fileInputRef);
    } else {
      handleGoogleImageSelect(images[currentImageIndex]);
    }
  };

  return (
    <Box marginBottom="1rem" position="relative">
      <Box
        width="100%"
        position="relative"
        style={{
          height: "250px",
          overflow: "hidden",
          borderRadius: "1rem",
          marginBottom: "1rem",
          border: "1px dashed gray",
        }}
      >
        <img
          src={
            currentImageIndex === 0 && image ? image : images[currentImageIndex]
          }
          alt="Uploaded for Brand"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
        {images.length > 1 && (
          <>
            <IconButton
              onClick={handlePreviousImage}
              style={{
                position: "absolute",
                top: "50%",
                left: "10px",
                transform: "translateY(-50%)",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
              }}
            >
              <ChevronLeft />
            </IconButton>
            <IconButton
              onClick={handleNextImage}
              style={{
                position: "absolute",
                top: "50%",
                right: "10px",
                transform: "translateY(-50%)",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
              }}
            >
              <ChevronRight />
            </IconButton>
          </>
        )}
      </Box>
      {currentImageIndex === 0 && (
        <Button
          onClick={handleImageSelect}
          startIcon={<ModeEditOutlineOutlined />}
        >
          {image ? "Edit Image" : "Add Image"}
        </Button>
      )}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </Box>
  );
};

export default ImageUploadAndPreview;
