import React from "react";
import { FieldArray } from "formik";
import {
  Box,
  Button,
  Select,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import useMediaQuery from "@mui/material/useMediaQuery";
import ExperienceForm from "./ExperienceForm";
import SportSelection from "./SportSelection";

function SportsField({ formProps, experienceLevels, frequencyUnits, theme }) {
  const isLargeScreen = useMediaQuery("(min-width: 1000px)");

  return (
    <FieldArray name="sports">
      {({ push, remove }) => (
        <>
          {formProps.values.sports.map((sport, index) => (
            <Box key={index}>
              {isLargeScreen ? (
                <FlexBetween gap="10px">
                  <SportSelection {...{ formProps, index, sport }} />
                  <ExperienceForm
                    {...{ formProps, index, experienceLevels, sport, theme }}
                  />
                </FlexBetween>
              ) : (
                <>
                  <SportSelection {...{ formProps, index, sport }} />
                  <ExperienceForm
                    {...{ formProps, index, experienceLevels, sport, theme }}
                  />
                </>
              )}
              <FlexBetween gap="10px">
                <TextField
                  label="How often do you play?"
                  type="number"
                  onBlur={formProps.handleBlur}
                  onChange={formProps.handleChange}
                  value={sport.frequency.amount}
                  name={`sports[${index}].frequency.amount`}
                  error={
                    formProps.touched.sports &&
                    formProps.touched.sports[index] &&
                    Boolean(formProps.errors.sports?.[index]?.frequency?.amount)
                  }
                  helpertext={
                    formProps.touched.sports &&
                    formProps.touched.sports[index] &&
                    formProps.errors.sports?.[index]?.frequency?.amount
                  }
                  fullWidth
                  // margin="normal"
                  inputProps={{ min: 0 }}
                />
                <FormControl
                  fullWidth
                  // margin="normal"
                >
                  <InputLabel
                    id={`frequencyUnit-label-${index}`}
                    sx={{
                      backgroundColor: theme.palette.background.alt,
                      padding: "0 5px",
                    }}
                  >
                    times per
                  </InputLabel>
                  <Select
                    labelId={`frequencyUnit-label-${index}`}
                    value={sport.frequency.unit}
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    name={`sports[${index}].frequency.unit`}
                    error={
                      formProps.touched.sports &&
                      formProps.touched.sports[index] &&
                      Boolean(formProps.errors.sports?.[index]?.frequency?.unit)
                    }
                    helpertext={
                      formProps.touched.sports &&
                      formProps.touched.sports[index] &&
                      formProps.errors.sports?.[index]?.frequency?.unit
                    }
                  >
                    {frequencyUnits.map((unit) => (
                      <MenuItem key={unit} value={unit}>
                        {unit}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FlexBetween>
              <Button
                onClick={() => remove(index)}
                color="error"
                sx={{
                  marginBottom: "10px",
                  padding: "2px 5px",
                }}
              >
                Remove
              </Button>
            </Box>
          ))}
          <Button
            onClick={() =>
              push({
                sport: "",
                experienceLevel: "Beginner",
                frequency: { amount: 0, unit: "day" },
              })
            }
          >
            Add Another Sport
          </Button>
        </>
      )}
    </FieldArray>
  );
}

export default SportsField;
