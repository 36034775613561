import { useState, Fragment } from "react";
import {
  Box,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Checkbox,
  FormControlLabel,
  Typography,
  Divider,
  Tooltip,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { registerUser, loginUser } from "redux/actions/authActions.js";
import FormLocation from "./FormComponents/FormLocation.jsx";
import FormNames from "./FormComponents/FormNames.jsx";
import FormUserImg from "./FormComponents/FormUserImg.jsx";
import FormEmailPassword from "./FormComponents/FormEmailPassword.jsx";
import FormBtns from "./FormComponents/FormBtns.jsx";
import PasswordResetForm from "./PasswordResetForm.jsx";
import { handleImageUpload } from "redux/actions/imageUploadUtils.js";
import FormAgeRange from "./FormComponents/FormAgeRange.jsx";
import SnackbarComponent from "components/SnackbarComponent.jsx";
import BusinessForm from "./FormComponents/BusinessForm.jsx";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const businessLocationSchema = yup.object().shape({
  address: yup.string().required("required"),
  town: yup.string().notRequired(),
  country: yup.string().required("required"),
  coordinates: yup.array().of(yup.number()).required("required"),
  placeId: yup.string().required("required"),
  locality: yup
    .string()
    .notRequired()
    .matches(/^(Local|Regional|National|International)$/, "invalid locality"),
});

const businessFormSchema = yup.object().shape({
  name: yup.string().required("required"),
  description: yup.string().required("required"),
  picturePath: yup.string().required("required"),
  logoPath: yup.string().required("required"),
  website: yup.string().url("invalid URL").required("required"),
  phone: yup.string().required("required"),
  sportsPromoted: yup
    .array()
    .of(yup.string())
    .min(1, "At least one sport must be selected")
    .required("required"),
  location: businessLocationSchema,
});

const registerSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  password: yup
    .string()
    .required("Password is required")
    .min(5, "Password should have a minimum length of 5")
    .max(25, "Password should have a maximum length of 25")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-])[A-Za-z\d@$!%*?&-]{6,25}$/,
      "Password must be 5-25 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one of the following special characters: @$!%*?&"
    ),
  picture: yup.string().required("required"),
  ageRange: yup
    .string()
    .required("required")
    .matches(
      /^(18-24|25-34|35-44|45-54|55-64|65-74|75\+)$/,
      "invalid age range"
    ),
  location: yup.object().shape({
    address: yup.string().required("required"),
    town: yup.string(),
    country: yup.string(),
    coordinates: yup.array().of(yup.number()).required("required"),
    placeId: yup.string().required("required"),
  }),
  isBusiness: yup.boolean(),
  businessForm: yup.object().when("isBusiness", {
    is: true,
    then: () => businessFormSchema,
    otherwise: () =>
      yup.object().shape({
        name: yup.string().notRequired(),
        description: yup.string().notRequired(),
        picturePath: yup.string().notRequired(),
        logoPath: yup.string().notRequired(),
        website: yup.string().url("invalid URL").notRequired(),
        phone: yup.string().notRequired(),
        sportsPromoted: yup.array().of(yup.string()).notRequired(),
        location: yup.object().shape({
          address: yup.string().notRequired(),
          town: yup.string().notRequired(),
          country: yup.string().notRequired(),
          coordinates: yup.array().of(yup.number()).notRequired(),
          placeId: yup.string().notRequired(),
          locality: yup
            .string()
            .notRequired()
            .matches(
              /^(Local|Regional|National|International)$/,
              "invalid locality"
            ),
        }),
      }),
  }),
});

const loginSchema = yup.object().shape({
  email: yup.string().email("invalid email").required("required"),
  password: yup.string().required("required"),
});

const initialValuesRegister = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  location: {
    address: "",
    town: "",
    country: "",
    coordinates: [],
    placeId: "",
  },
  picture: "",
  ageRange: "25-34",
  isBusiness: false,
  businessForm: {
    name: "",
    description: "",
    picturePath: "",
    logoPath: "",
    website: "",
    phone: "",
    sportsPromoted: [],
    location: {
      address: "",
      town: "",
      country: "",
      coordinates: [],
      placeId: "",
      locality: "Local",
    },
  },
};

const FormComponent = () => {
  const [pageType, setPageType] = useState("login");
  const [isLoading, setIsLoading] = useState(false);

  const { palette } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isLogin = pageType === "login";
  const isRegister = pageType === "register";
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Something went wrong. Please try again later"
  );
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  const handleFormSubmit = async (values, onSubmitProps) => {
    setError(false);
    setErrorMessage("");
    setIsLoading(true);
    // console.log("Form Submitted with values:", values);

    // remove "Point" from location
    if (values.location.type === "Point") {
      delete values.location.type;
    }

    if (values.businessForm.location.type === "Point") {
      delete values.businessForm.location.type;
    }

    if (
      values.businessForm.location.address ===
        values.businessForm.location.country &&
      values.businessForm.location.town === ""
    ) {
      values.businessForm.location.locality = "National";
    } else {
      values.businessForm.location.locality = "Local";
    }

    try {
      if (isLogin) {
        const loggedInUser = await dispatch(loginUser(values));

        if (loggedInUser.error) {
          setIsLoading(false);
          setError(true);
          setErrorMessage(loggedInUser.error);
        } else {
          setIsLoading(false);
          navigate("/home");
          onSubmitProps.resetForm();
        }
      }

      if (isRegister) {
        const savedUser = await dispatch(registerUser(values));
        // console.log("Saved user:", savedUser);

        if (savedUser.error) {
          setIsLoading(false);
          setError(true);
          setErrorMessage(savedUser.error);
          setSnackbarMessage(savedUser.error);
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        } else {
          setIsLoading(false);
          onSubmitProps.resetForm();
          navigate(`/verification-sent`, { state: { userId: savedUser.id } });
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      setError(true);
      setErrorMessage(error.message);
      setSnackbarMessage(error.message);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValuesRegister}
        validationSchema={isLogin ? loginSchema : registerSchema}
        onSubmit={handleFormSubmit}
        validateOnBlur={true}
        validateOnChange={true}
        validate={(values) => {
          const errors = {};
          // Perform custom validation here if needed
          return errors;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          resetForm,
        }) => (
          <Fragment>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                // console.log("Form values before submission:", values);
                // console.log("Form errors before submission:", errors);
                handleSubmit(e);
              }}
            >
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                {isRegister && (
                  <>
                    <FormNames
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                    <FormAgeRange
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                      values={values}
                    />
                    <FormLocation
                      palette={palette}
                      touched={touched?.location || {}}
                      errors={errors?.location || {}}
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      fieldName="location"
                    />
                    <FormUserImg
                      values={values}
                      setFieldValue={setFieldValue}
                      setSnackbarMessage={setSnackbarMessage}
                      setSnackbarSeverity={setSnackbarSeverity}
                      setSnackbarOpen={setSnackbarOpen}
                      handleImageUpload={handleImageUpload}
                      setIsLoading={setIsLoading}
                    />
                    <Divider sx={{ gridColumn: "span 4" }} />
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{ width: "100%", gridColumn: "span 4" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.isBusiness}
                            onChange={() =>
                              setFieldValue("isBusiness", !values.isBusiness)
                            }
                            color="primary"
                          />
                        }
                        label="Are you a Business User?"
                      />
                      <Tooltip
                        title="Business users can create and manage their own business pages and articles"
                        placement="right"
                      >
                        <InfoOutlinedIcon
                          color="primary"
                          sx={{ fontSize: 20 }}
                        />
                      </Tooltip>
                    </Box>
                    {values.isBusiness && (
                      <Box sx={{ gridColumn: "span 4" }}>
                        <Typography variant="h5" mb={2}>
                          Business Registration
                        </Typography>
                        <BusinessForm
                          formData={values.businessForm}
                          setFormData={(data) =>
                            setFieldValue("businessForm", data)
                          }
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          setSnackbarMessage={setSnackbarMessage}
                          setSnackbarSeverity={setSnackbarSeverity}
                          setSnackbarOpen={setSnackbarOpen}
                        />
                      </Box>
                    )}
                  </>
                )}
                <Divider sx={{ gridColumn: "span 4" }} />
                <FormEmailPassword
                  touched={touched}
                  errors={errors}
                  values={values}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
              </Box>
              {isLoading && (
                <Box display="flex" justifyContent="center">
                  <CircularProgress color="primary" />
                </Box>
              )}
              <Box>
                <FormBtns
                  palette={palette}
                  values={values}
                  isLogin={isLogin}
                  setPageType={setPageType}
                  resetForm={resetForm}
                  error={error}
                  errorMessage={errorMessage}
                  pageType={pageType}
                  isLoading={isLoading}
                />
              </Box>
              {pageType === "forgotPassword" && <PasswordResetForm />}
            </Form>
          </Fragment>
        )}
      </Formik>
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default FormComponent;
