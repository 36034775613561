import {
  CallOutlined,
  PlaceOutlined,
  PublicOutlined,
  VerifiedOutlined,
  Facebook,
  Twitter,
  Instagram,
  LinkedIn,
  YouTube,
  ExpandLessOutlined,
  ExpandMoreOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import DOMPurify from "dompurify";
import { BusinessProfileClick } from "helpers/businessHelpers/businessProfileClick";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toggleFollowBrand } from "redux/actions/brandActions";
import TikTokIcon from "scenes/admin/directory/BrandEdit/components/TikTokIcon";
import ConnectWithoutContactOutlinedIcon from "@mui/icons-material/ConnectWithoutContactOutlined";

export const BrandCard = ({ brand }) => {
  const { palette } = useTheme();

  const background = palette.background.default;
  const primaryDark = palette.primary.dark;
  const primary = palette.primary.main;
  const neutralLight = palette.neutral.light;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user); // Assuming you have a user object in your state

  const roundToNearest = (num) => Math.round(num);

  // convert from meters to miles and round to nearest mile
  const distanceInMiles = brand.distance
    ? roundToNearest(brand.distance / 1609.34)
    : null;
  // convert from meters to kilometers and round to nearest kilometer
  const distanceInKm = brand.distance
    ? roundToNearest(brand.distance / 1000)
    : null;

  // const isFollowing = brand.followers.includes(userId);
  const isFollowing =
    user && user.brandFollows ? user.brandFollows.includes(brand._id) : false;
  const [isExpanded, setIsExpanded] = useState(false);

  const handleFollowToggle = () => {
    dispatch(toggleFollowBrand(brand._id));
  };

  const sanitizedDescription = DOMPurify.sanitize(brand.description);

  const renderSocialMediaIcon = (platform, url) => (
    <a href={url} target="_blank" rel="noopener noreferrer" key={platform}>
      {platform === "facebook" && <Facebook />}
      {platform === "twitter" && <Twitter />}
      {platform === "instagram" && <Instagram />}
      {platform === "linkedIn" && <LinkedIn />}
      {platform === "youtube" && <YouTube />}
      {platform === "tiktok" && (
        <Box height="20px" width="20px">
          <TikTokIcon color={primaryDark} />
        </Box>
      )}
    </a>
  );

  const socialMediaIcons = brand.verified
    ? Object.keys(brand.socialMedia).map((platform) => {
        const url = brand.socialMedia[platform];
        return url ? renderSocialMediaIcon(platform, url) : null;
      })
    : null;

  // Function to remove http://www. or https://www. from urls and truncate if longer than 20 characters
  const formatWebsiteAddress = (websiteUrl) => {
    let simpleUrl = websiteUrl.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");

    simpleUrl = simpleUrl.replace(
      /(.*?\.(?:com|co\.uk|org|net|io|info))(\/|\?|#).*/i,
      "$1"
    );

    return simpleUrl;
  };

  return (
    <Card
      key={brand._id}
      sx={{
        backgroundImage: "none",
        backgroundColor: background,
        borderRadius: "0.55rem",
        position: "relative",
        paddingBottom: "2.5rem",
      }}
    >
      <CardContent>
        <Box>
          <Link
            to={`/brand/${brand._id}`}
            style={{ display: "block" }}
            onClick={() =>
              BusinessProfileClick(dispatch, {
                brandId: brand._id,
                fromPage: "directory",
              })
            }
          >
            <img
              // if brand.src is null, use a default image
              src={
                brand.picturePath
                  ? brand.picturePath
                  : "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/6d0f5d8b-96b6-48d8-7bf8-ba57dbcea500/512x512"
              }
              alt={brand.name}
              style={{
                width: "100%",
                height: "200px",
                objectFit: "cover",
                borderRadius: "10px",
              }}
            />
          </Link>
          <Box mt="1rem" display="flex" alignItems="center" gap="0.5rem">
            <Typography variant="h5">{brand.name}</Typography>
            {brand.verified ? (
              <Tooltip
                title="She is Action - Verified"
                placement="top-start"
                arrow
              >
                <VerifiedOutlined sx={{ color: primary }} />
              </Tooltip>
            ) : null}
          </Box>
        </Box>
        <Box color={primaryDark} mt={1}>
          {brand.website ? (
            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <PublicOutlined />
              <Typography fontSize={12}>
                <a
                  href={brand.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {/* {brand.website} */}
                  {formatWebsiteAddress(brand.website)}
                </a>
              </Typography>
            </Box>
          ) : null}

          {brand.phone ? (
            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <CallOutlined />
              <Typography fontSize={12}>{brand?.phone}</Typography>
            </Box>
          ) : null}
          {brand.locations[0]?.address ? (
            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <PlaceOutlined />
              <Typography fontSize={12}>
                {brand.locations[0]?.address}
              </Typography>
            </Box>
          ) : null}

          {distanceInMiles && distanceInKm ? (
            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <ConnectWithoutContactOutlinedIcon />
              <Typography fontSize={12}>
                {distanceInMiles} miles / {distanceInKm} km
              </Typography>
            </Box>
          ) : null}

          {/* Social Media icons */}
          <Box mt="5px" sx={{ display: "flex", gap: "0.75rem" }}>
            {socialMediaIcons}
          </Box>

          {/* Promoted Sports */}

          {brand.sportsPromoted && brand.sportsPromoted.length > 0 ? (
            brand.verified ? (
              brand.sportsPromoted.map((sport, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "inline-block",
                    backgroundColor: primary,
                    p: "0.25rem 0.5rem",
                    borderRadius: 3,
                    mt: 1,
                    mr: 1, // added margin-right for spacing between boxes
                  }}
                >
                  <Typography variant="h6" color={neutralLight} fontSize={10}>
                    {sport}
                  </Typography>
                </Box>
              ))
            ) : (
              <Box
                sx={{
                  display: "inline-block",
                  backgroundColor: primary,
                  p: "0.25rem 0.5rem",
                  borderRadius: 3,
                  mt: 1,
                  mr: 1, // added margin-right for spacing between boxes
                }}
              >
                <Typography variant="h6" color={neutralLight} fontSize={10}>
                  {brand.sportsPromoted[0]}
                </Typography>
              </Box>
            )
          ) : null}
        </Box>
      </CardContent>
      <Box
        sx={{ position: "absolute", bottom: 10, left: "1rem", right: "1rem" }}
      >
        <FlexBetween width="100%">
          <Tooltip
            title={
              isFollowing ? `Unfollow ${brand.name}` : `Follow ${brand.name}`
            }
            placement="top"
            arrow
          >
            <Button
              onClick={() => handleFollowToggle(brand._id)}
              variant={isFollowing ? "outlined" : "contained"}
              color={isFollowing ? "primary" : "secondary"}
              fontWeight="500"
              sx={{
                borderRadius: "1rem",
                padding: "0.25rem 1rem",
                fontSize: "10px",
              }}
            >
              {isFollowing ? "Following" : "Follow"}
            </Button>
          </Tooltip>
          <Button
            variant="primary"
            size="small"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? (
              <ExpandLessOutlined sx={{ color: primary }} />
            ) : (
              <ExpandMoreOutlined sx={{ color: primary }} />
            )}

            {isExpanded ? (
              <Typography variant="p" fontSize={10}>
                SEE LESS
              </Typography>
            ) : (
              <Typography variant="p" fontSize={10}>
                SEE MORE
              </Typography>
            )}
          </Button>
        </FlexBetween>
      </Box>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography variant="h6">Description</Typography>
          <div dangerouslySetInnerHTML={{ __html: sanitizedDescription }} />
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default BrandCard;
