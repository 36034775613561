import React from "react";

function ArticlePageTitleImage({ article, isLargeScreen }) {
  const containerStyle = {
    position: "relative",
    borderRadius: isLargeScreen ? "10px" : "0",
    overflow: "hidden", // Ensures the overlay is contained within the border radius
  };

  const imageStyle = {
    height: isLargeScreen ? undefined : "600px",
    width: "100%",
    objectFit: "cover",
    borderRadius: isLargeScreen ? "10px" : "0",
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.2)", // Black with 20% opacity
    pointerEvents: "none", // Ensures the overlay doesn't interfere with user interaction
  };

  return (
    <div style={containerStyle}>
      <img src={article.titleImage} alt={article.title} style={imageStyle} />
      <div style={isLargeScreen ? undefined : overlayStyle}></div>
    </div>
  );
}

export default ArticlePageTitleImage;
