import { EditOutlined } from "@mui/icons-material";
import { Avatar, Box, useMediaQuery, useTheme } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "redux/actions/userActions";
import UserSideDrawer from "./UserSideDrawer";
import { DataGrid } from "@mui/x-data-grid";

import DataGridCustomToolbar from "../components/DataGridCustomToolbar";
import { Link } from "react-router-dom";

function UserGrid({ isDashboard = false }) {
  const { palette } = useTheme();
  const backgroundAlt = palette.background.alt;
  const primaryLight = palette.primary.light;
  const primaryDark = palette.primary.dark;

  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");

  const [searchInput, setSearchInput] = useState("");

  const dispatch = useDispatch();
  const users = useSelector((state) => state.users) || []; // Assuming you store users in state.users
  const totalUsers = useSelector((state) => state.totalUsers) || 0; // Similarly for total users

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserRoles, setSelectedUserRoles] = useState([]);

  useEffect(() => {
    dispatch(fetchUsers(page, pageSize, sort, search)); // Using useDispatch to call fetchUsers
  }, [page, pageSize, sort, search, dispatch]);

  const columns = [
    // {
    //   field: "_id",
    //   headerName: "ID",
    //   flex: 0.75,
    // },
    {
      field: "fullName",
      headerName: "Full Name",
      flex: 1,
      valueGetter: (params) => `${params.row.firstName} ${params.row.lastName}`,
      renderCell: (params) => {
        // Assuming each user might have an 'avatarUrl' field. Adjust if different.
        const avatarUrl = params.row.picturePath.endsWith("/public")
          ? params.row.picturePath.replace("/public", "/128x128")
          : `${params.row.picturePath}/128x128`;
        return (
          <Link to={`/profile/${params.row._id}`}>
            <Box display="flex" alignItems="center">
              <Avatar src={avatarUrl} alt={params.value} sx={{ mr: 1 }} />
              {params.value}
            </Box>
          </Link>
        );
      },
    },

    // if not large screen, hide email
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      hide: !isLargeScreen,
    },
    {
      field: "verified",
      headerName: "Verified",
      flex: 0.5,
      hide: !isLargeScreen,
    },
    {
      field: "roles",
      headerName: "Roles",
      flex: 0.75,
      hide: true,
      renderCell: (params) => {
        if (!params.row.roles || params.row.roles.length === 0) {
          return null;
        }

        const getInitials = (name) =>
          name
            .split(" ")
            .map((word) => word[0])
            .join("")
            .toUpperCase();

        const roleNames = params.row.roles.map((role) => (
          <Box
            key={role._id}
            display="flex"
            alignItems="center"
            px="5px"
            py="3px"
            borderRadius="5px"
            m="3px"
            mb="5px"
            variant="filled"
            fontSize={10}
            backgroundColor={primaryDark}
            color={primaryLight}
            cursor="pointer"
          >
            {getInitials(role.name)}
          </Box>
        ));

        return (
          <Box display="flex" flexDirection="row" flexWrap="wrap">
            {roleNames}
          </Box>
        );
      },
    },

    !isDashboard && {
      field: "sports",
      headerName: "Sports",
      flex: 1,
      valueGetter: (params) => {
        // Check if the sports array exists and has elements
        if (!params.row.sports || params.row.sports.length === 0) {
          return "";
        }
        // Map the sports to their names and join them with a comma
        return params.row.sports.map((sport) => sport.sport).join(", ");
      },
      renderCell: (params) => params.value,
      hide: !isLargeScreen,
    },

    !isDashboard && {
      field: "Address",
      headerName: "Address",
      flex: 2,
      valueGetter: (params) => {
        if (!params.row.location) {
          return null;
        }
        return `${params.row.location.address}`;
      },
      hide: !isLargeScreen,
    },
    {
      field: "Country",
      headerName: "Country",
      flex: 1,
      valueGetter: (params) => {
        if (!params.row.location) {
          return null;
        }
        return `${params.row.location.country}`;
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      valueFormatter: (params) =>
        moment(params.value).format("Do MMM YY, HH:mm"), // For example, 'LL' will format like 'January 1, 2021'. Adjust format string as needed.
    },

    {
      field: "lastActive",
      headerName: "Last Active",
      flex: 1,
      valueFormatter: (params) =>
        params.value ? moment(params.value).format("Do MMM YY, HH:mm") : "",
    },
    !isDashboard && {
      field: "edit",
      headerName: "Edit",
      flex: 0.5,
      renderCell: (params) => (
        <button
          style={{ backgroundColor: "transparent", border: "none" }} // Set button background to transparent and remove border
          onClick={() => {
            setIsSidebarOpen(true);
            setSelectedUserId(params.row._id);
            setSelectedUserRoles(params.row.roles || []);
          }}
        >
          <EditOutlined
            sx={{ color: primaryDark }} // Set icon color to primaryDark
          />
        </button>
      ),
      hide: !isLargeScreen,
    },
  ].filter(Boolean);

  return (
    <>
      <UserSideDrawer
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        userId={selectedUserId}
        selectedUserRoles={selectedUserRoles}
        setSelectedUserRoles={setSelectedUserRoles}
      />
      <Box
        mt={isDashboard ? undefined : "40px"}
        height={isDashboard ? "450px" : "75vh"}
        sx={{
          "& .MuiDataGrid-root": {
            // border: "none",
          },
          "& .MuiDataGrid-cell": {
            // borderBottom: "none",
            whiteSpace: "normal",
            wordBreak: "break-word",
            overflow: "visible",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: backgroundAlt,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: backgroundAlt,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: primaryLight,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {},
          "& .MuiDataGrid-row": {
            height: "auto", // This ensures the row height adapts to content
          },
        }}
      >
        <DataGrid
          getRowId={(row) => {
            return row._id;
          }}
          rows={users}
          columns={columns}
          page={page}
          pageSize={pageSize}
          rowCount={totalUsers}
          rowsPerPageOptions={isDashboard ? [5, 10] : [5, 10, 25, 50, 100]}
          onPageChange={(params) => {
            setPage(params);
          }}
          onPageSizeChange={(newPageSize) => {
            setPage(0);
            setPageSize(newPageSize);
          }}
          disableSelectionOnClick
          paginationMode="server"
          autoHeight={true}
          onSortModelChange={(model) => {
            if (model.length) {
              setSort(`${model[0].field}:${model[0].sort}`);
            } else {
              setSort("");
            }
          }}
          // isDashboard then no toolbar
          disableColumnMenu={isDashboard}
          disableColumnFilter={isDashboard}
          // no components for dashboard
          components={{
            Toolbar: isDashboard ? null : DataGridCustomToolbar,
          }}
          rowHeight={isDashboard ? 32 : 50}
          componentsProps={{
            toolbar: {
              onSearch: (value) => {
                setSearch(value);
                setPage(0); // Reset page when a new search is executed
              },
              searchInput,
              setSearchInput,
              setSearch,
            },
          }}
        />
      </Box>
    </>
  );
}

export default UserGrid;
