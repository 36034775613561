const styles = {
  beginner: {
    display: "block",
    width: "100%",
    backgroundImage:
      "linear-gradient(90deg, #8be2e4 0%, #ffffff 33.42%, #ffffff 100%)",
    borderRadius: "100px",
    padding: "0 0.5rem",
    color: "#454545",
    fontWeight: "500",
  },
  intermediate: {
    display: "block",
    width: "100%",
    backgroundImage:
      "linear-gradient(90deg, #8be2e4 0%, #3eb4b8 40.1%, #ffffff 64.06%)",
    borderRadius: "100px",
    padding: "0 0.5rem",
    color: "#454545",
    fontWeight: "500",
  },
  advanced: {
    display: "block",
    width: "100%",
    backgroundImage:
      "linear-gradient(90deg, #8be2e4 0%, #3eb4b8 28.13%, #3482a6 65.1%, #e5b5f4 75%, #ffffff 86.98%)",
    borderRadius: "100px",
    padding: "0 0.5rem",
    color: "#ffffff",
    fontWeight: "500",
  },
  expert: {
    display: "block",
    width: "100%",
    backgroundImage:
      "linear-gradient(90deg, #8be1e2 0%, #008e94 50.52%, #c85ed9 100%)",
    borderRadius: "100px",
    padding: "0 0.5rem",
    color: "#ffffff",
    fontWeight: "500",
  },
};

const ExperienceType = ({ expertType }) => {
  if (expertType === "Beginner") {
    return <span style={styles.beginner}>Beginner</span>;
  } else if (expertType === "Intermediate") {
    return <span style={styles.intermediate}>Intermediate</span>;
  } else if (expertType === "Advanced") {
    return <span style={styles.advanced}>Advanced</span>;
  } else if (expertType === "Expert") {
    return <span style={styles.expert}>Expert</span>;
  } else {
    return null;
  }
};

export default ExperienceType;
