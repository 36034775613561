import {
  setFriends,
  setNonFriends,
  setRecentNonFriends,
  setViewingUserFriends,
} from "redux/state";
import { fetchMemberSuggestions, fetchUser } from "./userActions";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const patchFriend =
  (userId, friendId, isDifferentUser = false) =>
  async (dispatch, getState) => {
    let user1 = isDifferentUser ? friendId : userId;
    let user2 = isDifferentUser ? userId : friendId;

    try {
      const token = getState().token;
      const response = await fetch(`${BASE_URL}/users/${user1}/${user2}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Could not add friend.");
      }
      const data = await response.json();

      dispatch(setFriends({ friends: data }));

      dispatch(fetchNonFriends(userId));
      dispatch(fetchMemberSuggestions(userId));
      dispatch(fetchRecentNonFriends(userId));
      if (isDifferentUser) {
        dispatch(fetchUser(userId));
      }

      // dispatch(fetchPosts(userId));
    } catch (error) {
      console.error("Error while updating like:", error);
      throw error;
    }
  };

export const fetchFriends =
  (userId, isViewingUser = false) =>
  async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/users/${userId}/friends`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    if (isViewingUser) {
      dispatch(setViewingUserFriends({ friends: data }));
    } else {
      dispatch(setFriends({ friends: data }));
    }
  };

export const fetchNonFriends = (userId) => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/users/${userId}/nonfriends`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();
  const nonFriends = data.filter((user) => user._id !== userId);
  dispatch(setNonFriends({ nonFriends }));
};

export const fetchRecentNonFriends = (userId) => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/users/${userId}/recentNonFriends`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();
  const recentNonFriends = data.filter((user) => user._id !== userId);
  dispatch(setRecentNonFriends({ recentNonFriends }));
};
