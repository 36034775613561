import { MenuItem, TextField } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import React, { useRef, useEffect } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";

export const NameFields = ({ formProps }) => (
  <FlexBetween gap="10px">
    <TextField
      label="First Name"
      onBlur={formProps.handleBlur}
      onChange={formProps.handleChange}
      value={formProps.values.firstName}
      name="firstName"
      error={formProps.touched.firstName && Boolean(formProps.errors.firstName)}
      helpertext={formProps.touched.firstName && formProps.errors.firstName}
      fullWidth
      margin="normal"
    />

    <TextField
      label="Last Name"
      onBlur={formProps.handleBlur}
      onChange={formProps.handleChange}
      value={formProps.values.lastName}
      name="lastName"
      error={formProps.touched.lastName && Boolean(formProps.errors.lastName)}
      helpertext={formProps.touched.lastName && formProps.errors.lastName}
      fullWidth
      margin="normal"
    />
  </FlexBetween>
);

export const EmailField = ({ formProps }) => (
  <TextField
    label="Email"
    onBlur={formProps.handleBlur}
    onChange={formProps.handleChange}
    value={formProps.values.email}
    name="email"
    error={formProps.touched.email && Boolean(formProps.errors.email)}
    helpertext={formProps.touched.email && formProps.errors.email}
    fullWidth
    margin="normal"
  />
);

export const AgeRangeField = ({ formProps }) => (
  <TextField
    select
    label="Age Range"
    onBlur={formProps.handleBlur}
    onChange={formProps.handleChange}
    value={formProps.values.ageRange}
    name="ageRange"
    error={formProps.touched.ageRange && Boolean(formProps.errors.ageRange)}
    helpertext={formProps.touched.ageRange && formProps.errors.ageRange}
    fullWidth
    margin="normal"
  >
    {["18-24", "25-34", "35-44", "45-54", "55-64", "65-74", "75+"].map(
      (option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      )
    )}
  </TextField>
);

export const PicturePathField = ({ formProps }) => (
  <TextField
    label="Picture Path"
    onBlur={formProps.handleBlur}
    onChange={formProps.handleChange}
    value={formProps.values.picturePath}
    name="picturePath"
    error={
      formProps.touched.picturePath && Boolean(formProps.errors.picturePath)
    }
    helpertext={formProps.touched.picturePath && formProps.errors.picturePath}
    fullWidth
    margin="normal"
  />
);

export const AboutMeField = ({ formProps }) => {
  const quillRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current && !quillRef.current) {
      quillRef.current = new Quill(containerRef.current, {
        theme: "snow",
        placeholder: "About Me",
      });

      quillRef.current.on("text-change", () => {
        const content = quillRef.current.root.innerHTML;
        formProps.setFieldValue("aboutMe", content);
      });

      quillRef.current.root.innerHTML = formProps.values.aboutMe;
    }
  }, [formProps]);

  return (
    <div style={{ marginBottom: "1rem" }}>
      <label htmlFor="aboutMe">About Me</label>
      <div id="aboutMe" ref={containerRef} />
      {formProps.touched.aboutMe && formProps.errors.aboutMe && (
        <div style={{ color: "red", marginTop: ".5rem" }}>
          {formProps.errors.aboutMe}
        </div>
      )}
    </div>
  );
};

export const LocationField = ({ formProps }) => (
  <TextField
    label="Location"
    onBlur={formProps.handleBlur}
    onChange={formProps.handleChange}
    value={formProps.values.location.address}
    name="location"
    error={formProps.touched.location && Boolean(formProps.errors.location)}
    helpertext={formProps.touched.location && formProps.errors.location}
    fullWidth
    margin="normal"
  />
);
