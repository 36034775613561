import { setDashboardAdminUser } from "redux/state";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const fetchAllUsers = () => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/dashboardUser/totalUsers`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();

  dispatch(setDashboardAdminUser(data));
  return data;
};

export const fetchAllVerifiedUsers = () => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/dashboardUser/totalVerifiedUsers`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();

  dispatch(setDashboardAdminUser(data));
  return data;
};

export const fetchUsersAtEndOfLastMonth = () => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/dashboardUser/allEndOfLastMonth`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();

  dispatch(setDashboardAdminUser(data));
  return data;
};

export const fetchVerifiedUsersAtEndOfLastMonth =
  () => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/dashboardUser/verifiedEndOFLastMonth`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();

    dispatch(setDashboardAdminUser(data));
    return data;
  };

export const fetchUsersCreatedByMonth = () => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/dashboardUser/createdByMonth`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();

  dispatch(setDashboardAdminUser(data));
  return data;
};

export const fetchVerifiedUsersCreatedByMonth =
  () => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/dashboardUser/verifiedByMonth`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();

    dispatch(setDashboardAdminUser(data));
    return data;
  };

export const fetchUsersCreatedThisMonth = () => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/dashboardUser/createdThisMonth`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();

  dispatch(setDashboardAdminUser(data));
  return data;
};

export const fetchVerifiedCreatedThisMonth =
  () => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/dashboardUser/verifiedCreatedThisMonth`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();

    dispatch(setDashboardAdminUser(data));
    return data;
  };

export const fetchUsersCreatedLastMonth = () => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/dashboardUser/createdLastMonth`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();

  dispatch(setDashboardAdminUser(data));
  return data;
};

export const fetchVerifiedUsersCreatedLastMonth =
  () => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/dashboardUser/verifiedLastMonth`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();

    dispatch(setDashboardAdminUser(data));
    return data;
  };

export const fetchUsersCountByAgeRange = () => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/dashboardUser/ageRange`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();

  dispatch(setDashboardAdminUser(data));
  return data;
};

export const fecthSportCount = () => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/dashboardUser/countSports`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();

  dispatch(setDashboardAdminUser(data));
  return data;
};

export const fetchUserLocationsByCountry = () => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/dashboardUser/locations`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();

  dispatch(setDashboardAdminUser(data));
  return data;
};
