import { Autocomplete, FormControl, TextField } from "@mui/material";
import React from "react";

const BrandCreateSport = ({
  sportsList = [], // Provide a default empty array
  selectedSport = [], // Provide a default empty array
  setSelectedSport,
  handleSportChange,
  formErrors = {}, // Provide a default empty object
  touched = {}, // Provide a default empty object
}) => {
  return (
    <FormControl
      variant="outlined"
      fullWidth
      margin="normal"
      style={{ marginTop: "-5px" }}
    >
      <Autocomplete
        multiple
        options={sportsList ? sportsList.map((s) => s.name) : []}
        value={selectedSport}
        onChange={(event, newValue) => {
          setSelectedSport(newValue);
          handleSportChange(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Sport"
            error={formErrors?.sportsPromoted}
            helperText={touched?.sportsPromoted && formErrors?.sportsPromoted}
            fullWidth
            margin="normal"
          />
        )}
      />
    </FormControl>
  );
};

export default BrandCreateSport;
