import React, { useState } from "react";
import {
  Menu,
  MenuItem,
  IconButton,
  Avatar,
  Typography,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLogout } from "redux/state";

export const UserDropdown = ({
  profilePic,
  fullName,
  handleProfileClick,
  handleGroupsClick,
  handleChatClick,
  handleAdminClick,
  handleNewsClick,
  handleFindMembersClick,
  handleBrandClick,
  handleBrandDirectoryClick,
  handlePostFeedPageClick,
  handleSportDirectoryClick,
  handleSettingsClick,
  mediumLight,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const roles = useSelector((state) => state.user.roles);
  const isAdmin =
    roles &&
    roles?.some((role) => role.name && role.name.toLowerCase() === "admin");
  const isBrandAdmin =
    roles &&
    roles?.some(
      (role) =>
        (role.name && role.name.toLowerCase() === "brand manager") ||
        (role.name && role.name.toLowerCase() === "article writer")
    );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        onClick={handleClick}
        sx={{
          color: "grey",
          backgroundColor: "transparent",
          borderRadius: "1.25rem",
          padding: "0.25rem 0.25rem 0.25rem 0.5rem",
          // border: `1px solid ${neutralMedium}`,
          border: `1px solid ${mediumLight}`,

          "&:hover": {
            boxShadow: 3,
          },
        }}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={0.75}
        >
          <MenuIcon />
          <Avatar
            src={
              profilePic.endsWith("/public")
                ? profilePic.replace("/public", "/128x128")
                : `${profilePic}/128x128`
            }
            alt={fullName}
            sx={{
              width: "32px",
              height: "32px",
              backgroundColor: "transparent",
            }}
          />
        </Box>
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem value={fullName} onClick={handleProfileClick}>
          <Typography>{fullName}</Typography>
        </MenuItem>

        <MenuItem onClick={handleNewsClick}>
          <Typography>News</Typography>
        </MenuItem>
        <MenuItem onClick={handlePostFeedPageClick}>
          <Typography>Posts</Typography>
        </MenuItem>
        <MenuItem onClick={handleFindMembersClick}>
          <Typography>Find Members</Typography>
        </MenuItem>
        <MenuItem onClick={handleGroupsClick}>
          <Typography>Groups</Typography>
        </MenuItem>
        <MenuItem onClick={handleChatClick}>
          <Typography>Chat</Typography>
        </MenuItem>
        <MenuItem onClick={handleBrandDirectoryClick}>
          <Typography>Brand Directory</Typography>
        </MenuItem>
        <MenuItem onClick={handleSportDirectoryClick}>
          <Typography>Sports Directory</Typography>
        </MenuItem>
        {isBrandAdmin && (
          <MenuItem onClick={handleBrandClick}>
            <Typography>Brand Portal</Typography>
          </MenuItem>
        )}

        {isAdmin && (
          <MenuItem onClick={handleAdminClick}>
            <Typography>Admin Portal</Typography>
          </MenuItem>
        )}
        <MenuItem onClick={handleSettingsClick}>
          <Typography>Settings & Preferences</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(setLogout());
            navigate("/");
          }}
        >
          Log Out
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserDropdown;
