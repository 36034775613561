import React from "react";
import searchDark from "assets/light/search.png";
import searchLight from "assets/dark/search.png";
import { Box, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function BrowseMembersBtn() {
  const { palette } = useTheme();
  const primary = palette.primary.main;
  const primaryDark = palette.primary.dark;
  const neutralLight = palette.neutral.light;
  const mode = useSelector((state) => state.mode);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        // margin: "2rem 0 1rem 0",
      }}
    >
      <Link to="/findMembers">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0.75rem 1.5rem",
            height: "100%",
            backgroundColor: primary,
            borderRadius: "2rem",
            color: neutralLight,
            fontSize: "20px",
            cursor: "pointer",
            transition: "all 0.2s ease-in-out",
            "&:hover": {
              backgroundColor: primaryDark,
            },
          }}
        >
          <img
            src={mode === "light" ? searchLight : searchDark}
            alt="search"
            style={{ width: "1.5rem", marginRight: "0.5rem" }}
          />
          Browse Members
        </Box>
      </Link>
    </Box>
  );
}

export default BrowseMembersBtn;
