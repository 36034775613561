import { PlaceOutlined } from "@mui/icons-material";
import { Box, Card, CardContent, Typography } from "@mui/material";
import AvatarRoleIcons from "components/avatarRoleIcons";
import React from "react";
import { Link } from "react-router-dom";

function UserCard({ user, background, primary, neutralLight, primaryDark }) {
  return (
    <Card
      key={user._id}
      sx={{
        backgroundImage: "none",
        backgroundColor: background,
        borderRadius: "0.55rem",
        position: "relative",
      }}
    >
      <CardContent>
        <Box>
          <Link to={`/profile/${user._id}`}>
            <img
              src={user.picturePath}
              alt={`${user.firstName} ${user.lastName}`}
              style={{
                width: "100%",
                height: "250px",
                objectFit: "cover",
                borderRadius: "10px",
              }}
            />
          </Link>
          <Link to={`/profile/${user._id}`}>
            <Box mt="1rem" display="flex" alignItems="center" gap="0.5rem">
              <Typography variant="h5">
                {user.firstName} {user.lastName}
              </Typography>
              <AvatarRoleIcons user={user} />
            </Box>
          </Link>

          <Box
            mt={1}
            sx={{ display: "flex", alignItems: "center", gap: "1rem" }}
            color={primaryDark}
          >
            <PlaceOutlined />
            <Typography variant="h6">
              {user.location.town ? user.location.town : user.location.country}
            </Typography>
          </Box>

          {user.sports && user.sports.length > 0 ? (
            <Box mt="1rem">
              <Box display="flex" gap="0.5rem" flexWrap="wrap">
                {user.sports.map((sport, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "inline-block",
                      backgroundColor: primary,
                      p: "0.25rem 0.5rem",
                      borderRadius: 3,
                      mt: 1,
                      mr: 1, // ensures spacing between boxes
                    }}
                  >
                    <Typography variant="h6" color={neutralLight} fontSize={10}>
                      {sport.sport}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          ) : null}
        </Box>
      </CardContent>
    </Card>
  );
}

export default UserCard;
