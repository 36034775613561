import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import LocationAutocomplete from "scenes/loginPage/FormComponents/FormLocation";

function LocationSelector({
  searchCriteria,
  filterValues,
  handleLocationSelect,
  handleLocationRadiusChange,
}) {
  return (
    <Box my={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={9}>
          <LocationAutocomplete
            setFieldValue={(field, value) => handleLocationSelect(value)}
            defaultValue={searchCriteria.location.address}
            isOptionEqualToValue={(option, value) =>
              option.place_id === value.place_id
            }
            touched={{ location: false }}
            errors={{}}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <FormControl fullWidth>
            <InputLabel id="location-radius-label">Location Radius</InputLabel>
            <Select
              labelId="location-radius-label"
              id="location-radius-select"
              value={filterValues.locationRadius}
              label="Location Radius"
              onChange={handleLocationRadiusChange}
              defaultValue={searchCriteria.locationRadius}
            >
              {[5, 10, 25, 50, 100].map((radius) => (
                <MenuItem key={radius} value={radius}>
                  {radius} km
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
}

export default LocationSelector;
