import {
  Avatar,
  Box,
  Button,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBrand, toggleFollowBrand } from "redux/actions/brandActions";
import WidgetWrapper from "components/WidgetWrapper";
import FlexBetween from "components/FlexBetween";
import { Link, useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import BrandBioTable from "./BrandBioTable";
import {
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
  VerifiedOutlined,
  YouTube,
} from "@mui/icons-material";
import MapComponent from "components/MapComponent";
import TikTokIcon from "scenes/admin/directory/BrandEdit/components/TikTokIcon";
import { fetchDistanceBetweenUserAndBrand } from "redux/actions/userActions";
import { BusinessProfileClick } from "helpers/businessHelpers/businessProfileClick";

const BrandWidget = ({ brandId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery("(min-width:1000px)");
  const { palette } = useTheme();
  const primary = palette.primary.main;
  const medium = palette.neutral.medium;
  const dark = palette.neutral.dark;
  const primaryDark = palette.primary.dark;
  const neutralLight = palette.neutral.light;
  const neutralDark = palette.neutral.dark;
  const brand = useSelector((state) => state.brand);
  const user = useSelector((state) => state.user);
  const brandLogo = useSelector((state) => state.brand.logoPath);
  const milesBetweenUserAndBrand = useSelector(
    (state) => state.distanceBetweenUserAndBrand.distanceInMiles
  );
  const kilometersBetweenUserAndBrand = useSelector(
    (state) => state.distanceBetweenUserAndBrand.distanceInKilometers
  );
  const isBrandLogo = brandLogo && brandLogo.length > 0 ? true : false;
  const isBrandNameTooLong =
    brand.name && brand.name.length > 10 ? true : false;

  // Function to replace consecutive '<p><br></p>' tags
  const replaceConsecutiveBreaks = (match) => {
    const count = match.split("<p><br></p>").length - 1;
    return "<br>".repeat(Math.max(count - 1, 0));
  };

  useEffect(() => {
    if (brandId) {
      dispatch(fetchBrand(brandId));
    }
  }, [dispatch, brandId]);

  useEffect(() => {
    if (user && brandId) {
      dispatch(fetchDistanceBetweenUserAndBrand(user._id, brandId));
    }
  }, [dispatch, brandId, user?._id, user]);

  const [lng, lat] =
    (brand.locations && brand.locations[0] && brand.locations[0].coordinates) ||
    [];

  const location = { lat, lng };

  const fullLocation = brand.locations && brand.locations[0];
  // Determine the zoom level based on locality
  const zoomLevel =
    fullLocation && fullLocation.locality === "National" ? 5 : 12; // For example, zoom level 5 for a country

  const sanitizedDescription = DOMPurify.sanitize(brand.description).replace(
    /(<p><br><\/p>)+/g,
    replaceConsecutiveBreaks
  );

  // is the user following brand?
  const isFollowingBrand =
    user && user.brandFollows && user.brandFollows.includes(brand._id);

  const handleFollowToggle = () => {
    dispatch(toggleFollowBrand(brand._id));
  };

  const renderSocialMediaIcon = (platform, url) => (
    <a href={url} target="_blank" rel="noopener noreferrer" key={platform}>
      {platform === "facebook" && <Facebook />}
      {platform === "twitter" && <Twitter />}
      {platform === "instagram" && <Instagram />}
      {platform === "linkedIn" && <LinkedIn />}
      {platform === "youtube" && <YouTube />}
      {platform === "tiktok" && (
        <Box height="20px" width="20px">
          <TikTokIcon color={primaryDark} />
        </Box>
      )}
    </a>
  );

  const socialMediaIcons = brand.verified
    ? Object.keys(brand.socialMedia).map((platform) => {
        const url = brand.socialMedia[platform];
        return url ? renderSocialMediaIcon(platform, url) : null;
      })
    : null;

  const isSocialMediaIcons =
    socialMediaIcons && socialMediaIcons.length > 0 ? true : false;

  return (
    <>
      {!isLargeScreen ? (
        <Box position="relative">
          <ArrowBackIosNewIcon
            sx={{
              position: "absolute",
              top: "1rem",
              left: "1rem",
              zIndex: 1,
              color: primary,
              fontSize: "2rem",
              cursor: "pointer",
              backgroundColor: "white",
              borderRadius: "50%",
              padding: "0.25rem",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            }}
            onClick={() => navigate(-1)}
          />
          <img
            src={brand.picturePath}
            alt={brand.name}
            style={{
              width: "100%",
              height: "350px",
              objectFit: "cover",
            }}
          />
          {isBrandLogo ? (
            <Link
              to={`/brand/${brandId}`}
              onClick={() =>
                BusinessProfileClick(dispatch, {
                  brandId,
                  fromPage: "BrandPage",
                })
              }
            >
              <Avatar
                src={brand.logoPath}
                sx={{
                  width: "144px",
                  height: "144px",
                  position: "absolute",
                  top: "235px",
                  left: "20px",
                  zIndex: 1,
                  backgroundColor: "white",
                }}
                style={{ cursor: "pointer" }}
              />
            </Link>
          ) : undefined}
        </Box>
      ) : undefined}
      <WidgetWrapper
        marginTop={!isLargeScreen ? "-50px" : undefined}
        position={!isLargeScreen ? "relative" : undefined}
        borderRadius={!isLargeScreen ? "2rem" : "0.75rem"}
      >
        {!isLargeScreen ? (
          <>
            {isBrandNameTooLong ? (
              <Box
                display="flex"
                gap={1}
                alignItems="center"
                mt={isBrandLogo ? "50px" : undefined}
              >
                <Typography variant="h3" color={dark} fontWeight="700">
                  <span style={{ color: neutralDark }}> {brand.name}</span>
                </Typography>
                {brand.verified ? (
                  <Tooltip
                    title="She is Action - Verified"
                    placement="top-start"
                    arrow
                  >
                    <VerifiedOutlined
                      fontSize="large"
                      sx={{ color: primary }}
                    />
                  </Tooltip>
                ) : undefined}
              </Box>
            ) : undefined}
            <FlexBetween
              mt={isBrandLogo && !isBrandNameTooLong ? "50px" : undefined}
            >
              {!isBrandNameTooLong ? (
                <Box display="flex" gap={1} alignItems="center">
                  <Typography variant="h3" color={dark} fontWeight="700">
                    <span style={{ color: neutralDark }}> {brand.name}</span>
                  </Typography>
                  {brand.verified ? (
                    <Tooltip
                      title="She is Action - Verified"
                      placement="top-start"
                      arrow
                    >
                      <VerifiedOutlined
                        fontSize="large"
                        sx={{ color: primary }}
                      />
                    </Tooltip>
                  ) : undefined}
                </Box>
              ) : undefined}

              {isSocialMediaIcons ? (
                <Box
                  mt="3px"
                  sx={{ display: "flex", gap: "0.75rem", color: primaryDark }}
                >
                  {socialMediaIcons}
                </Box>
              ) : (
                <>
                  <Typography color={medium}>
                    {brand.followers?.length} follower
                    {brand.followers?.length === 1 ? undefined : "s"}
                  </Typography>

                  {milesBetweenUserAndBrand && kilometersBetweenUserAndBrand ? (
                    <Typography color={medium}>
                      {milesBetweenUserAndBrand} miles /{" "}
                      {kilometersBetweenUserAndBrand} km away
                    </Typography>
                  ) : undefined}
                </>
              )}

              <Tooltip
                title={
                  isFollowingBrand
                    ? `Unfollow ${brand.name}`
                    : `Follow ${brand.name}`
                }
                placement="top"
                arrow
              >
                <Button
                  onClick={() => handleFollowToggle(brand._id)}
                  variant="contained"
                  // color of button secondary
                  color={isFollowingBrand ? "primary" : "secondary"}
                  fontWeight="500"
                  fontSize={14}
                  sx={{
                    borderRadius: "1rem",
                    padding: "0.25rem 1rem",
                  }}
                >
                  {isFollowingBrand ? "Following" : "Follow"}
                </Button>
              </Tooltip>
            </FlexBetween>
            {isSocialMediaIcons ? (
              <>
                <Typography color={medium}>
                  {brand.followers?.length} follower
                  {brand.followers?.length === 1 ? undefined : "s"}
                </Typography>

                {milesBetweenUserAndBrand && kilometersBetweenUserAndBrand ? (
                  <Typography color={medium}>
                    {milesBetweenUserAndBrand} miles /{" "}
                    {kilometersBetweenUserAndBrand} km away
                  </Typography>
                ) : undefined}
              </>
            ) : undefined}
          </>
        ) : undefined}
        {isLargeScreen ? (
          <FlexBetween gap="0.5rem" pb="1.1rem">
            <FlexBetween gap="1rem">
              <Link
                to={`/brand/${brandId}`}
                onClick={() =>
                  BusinessProfileClick(dispatch, {
                    brandId,
                    fromPage: "BrandPage",
                  })
                }
              >
                <Avatar
                  src={brand.picturePath}
                  sx={{ width: "60px", height: "60px" }}
                  style={{ cursor: "pointer" }}
                />
              </Link>

              <Box>
                <Link
                  to={`/brand/${brandId}`}
                  onClick={() =>
                    BusinessProfileClick(dispatch, {
                      brandId,
                      fromPage: "BrandPage",
                    })
                  }
                >
                  <Typography
                    variant="h5"
                    color={dark}
                    fontWeight="500"
                    sx={{
                      "&:hover": {
                        color: palette.primary.light,
                        cursor: "pointer",
                      },
                    }}
                  >
                    {brand.name}
                  </Typography>
                </Link>
                <>
                  <Typography color={medium}>
                    {brand.followers?.length} follower
                    {brand.followers?.length === 1 ? undefined : "s"}
                  </Typography>

                  {milesBetweenUserAndBrand && kilometersBetweenUserAndBrand ? (
                    <Typography color={medium}>
                      {milesBetweenUserAndBrand} miles /{" "}
                      {kilometersBetweenUserAndBrand} km away
                    </Typography>
                  ) : undefined}
                </>
                {/* Social Media icons */}
                <Box
                  mt="3px"
                  sx={{ display: "flex", gap: "0.75rem", color: primaryDark }}
                >
                  {socialMediaIcons}
                </Box>
              </Box>
            </FlexBetween>
            {brand.verified ? (
              <Tooltip
                title="She is Action - Verified"
                placement="top-start"
                arrow
              >
                <VerifiedOutlined sx={{ color: primary }} />
              </Tooltip>
            ) : undefined}
          </FlexBetween>
        ) : undefined}

        {/* Promoted Sports */}

        {brand.sportsPromoted && brand.sportsPromoted.length > 0 ? (
          brand.verified ? (
            brand.sportsPromoted.map((sport, index) => (
              <Box
                key={index}
                sx={{
                  display: "inline-block",
                  backgroundColor: primary,
                  p: "0.25rem 0.5rem",
                  borderRadius: 3,
                  mt: 1,
                  mr: 1, // added margin-right for spacing between boxes
                }}
              >
                <Typography variant="h6" color={neutralLight} fontSize={10}>
                  {sport}
                </Typography>
              </Box>
            ))
          ) : (
            <Box
              sx={{
                display: "inline-block",
                backgroundColor: primary,
                p: "0.25rem 0.5rem",
                borderRadius: 3,
                mt: 1,
                mr: 1, // added margin-right for spacing between boxes
              }}
            >
              <Typography variant="h6" color={neutralLight} fontSize={10}>
                {brand.sportsPromoted[0]}
              </Typography>
            </Box>
          )
        ) : null}
        {brand.description && (
          <Box p="1rem 0">
            <Typography
              variant="h5"
              fontSize="1rem"
              color={neutralDark}
              fontWeight="500"
              mb="1rem"
            >
              Description
            </Typography>
            <div dangerouslySetInnerHTML={{ __html: sanitizedDescription }} />
            <Box p="1rem 0 0.5rem 0">
              <BrandBioTable brand={brand} />
            </Box>
            {location && (
              <MapComponent
                location={location}
                brandName={brand.name}
                zoom={zoomLevel}
              />
            )}
          </Box>
        )}
      </WidgetWrapper>
    </>
  );
};

export default BrandWidget;
