import { Box } from "@mui/material";
import React from "react";
import ImageUploadAndPreview from "scenes/admin/directory/BrandCreateForm/components/ImageUploadAndPreview";
import SponsorCreateDetails from "./SponsorCreateDetails";

function SponsorForm({
  formData,
  setFormData,
  image,
  handleEditClick,
  handleFileChange,
  formErrors,
  fileInputRef,
  defaultImageURL,
  handleDateChange,
  handleChange,
  modules,
  formats,
}) {
  if (!formData || !setFormData) return null;

  return (
    <Box>
      {/* Image Upload and Preview */}
      <ImageUploadAndPreview
        image={image}
        handleFileChange={handleFileChange}
        fileInputRef={fileInputRef}
        defaultImageURL={defaultImageURL}
        handleEditClick={handleEditClick}
      />
      {/* Sponsor Name, Description and Website */}
      <SponsorCreateDetails
        handleChange={handleChange}
        formData={formData}
        formErrors={formErrors}
        modules={modules}
        formats={formats}
        setFormData={setFormData}
        handleDateChange={handleDateChange}
      />
    </Box>
  );
}

export default SponsorForm;
