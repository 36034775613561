import { useTheme } from "@emotion/react";
import { Avatar, Box, Button, useMediaQuery } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRolesWithCount } from "redux/actions/userRolesActions";
import * as yup from "yup";
import { handleImageUpload } from "redux/actions/imageUploadUtils";
import { createRole, updateRole } from "redux/actions/roleActions";

function useRoles() {
  const { palette } = useTheme();
  const backgroundAlt = palette.background.alt;
  const primaryLight = palette.primary.light;

  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  const dispatch = useDispatch();

  const rolesWithCount = useSelector((state) => state.userRolesWithCount) || [];

  useEffect(() => {
    dispatch(fetchRolesWithCount());
  }, [dispatch]);

  const columns = [
    {
      field: "roleId",
      headerName: "ID",
      flex: 1,
      hide: true,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "icon",
      headerName: "Icon",
      flex: 0.25,
      renderCell: (params) => {
        return (
          <Box display="flex" alignItems="center">
            {params.value && (
              <Avatar
                src={params.value}
                alt={params.row.roleName}
                sx={{ width: "24px", height: "24px", mr: 1 }}
              />
            )}
          </Box>
        );
      },
    },
    {
      field: "toolTip",
      headerName: "Icon Tooltip",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
    },
    {
      field: "count",
      headerName: "Members",
      flex: 0.5,
    },
    // {
    //   field: "isActive",
    //   headerName: "Active",
    //   flex: 0.5,
    //   renderCell: (params) => {
    //     return params.value ? "Yes" : "No";
    //   },
    // },
    {
      field: "edit",
      headerName: "Edit",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEdit(params.row)}
            sx={{
              padding: "5px 7px",
              fontSize: "0.5rem",
            }}
          >
            Edit
          </Button>
        );
      },
    },
  ];

  const [image, setImage] = useState(null);
  const [open, setOpen] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const fileInputRef = useRef(null);

  const defaultImageURL =
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/6d0f5d8b-96b6-48d8-7bf8-ba57dbcea500/512x512";

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    icon: "",
    toolTip: "",
    isActive: true,
  });

  // console.log("🚀 ~ useRoles ~ formData:", formData);

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required("Role name is required.")
      .min(2, "Role name should be at least 2 characters.")
      .max(100, "Role name should be at most 100 characters."),
    description: yup
      .string()
      .required("Description is required.")
      .min(10, "Description should be at least 10 characters."),
    icon: yup.string(),
    toolTip: yup.string(),
    isActive: yup.boolean(),
  });

  const handleChange = useCallback((name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  }, []);

  const resetForm = () => {
    setFormData({
      name: "",
      description: "",
      icon: "",
      toolTip: "",
      isActive: true,
    });
    setImage(null);
  };

  const handleOpen = useCallback(() => {
    resetForm();
    setImage(null);
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleEditClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const uploadedImageUrl = await handleImageUpload(file);
        if (uploadedImageUrl) {
          setFormData((prevData) => ({ ...prevData, icon: uploadedImageUrl }));
          setImage(URL.createObjectURL(file));
        } else {
          console.error("Error uploading image");
          setFormData((prevData) => ({ ...prevData, icon: defaultImageURL }));
        }
      } catch (error) {
        console.error("Error uploading image", error);
        setFormData((prevData) => ({ ...prevData, icon: defaultImageURL }));
      }
    }
  };

  const handleSubmit = async () => {
    // console.log("🚀 ~ useRoles ~ handleSubmit ~ formData", formData);
    // console.log("🚀 ~ _id", formData._id);

    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setFormErrors({});
      console.log("passed validation");
      if (formData._id) {
        console.log("update role");
        await dispatch(updateRole(formData._id, formData));
      } else {
        console.log("new role");
        await dispatch(createRole(formData));
      }

      // Reset form data
      resetForm();
      // Close the dialog
      handleClose();
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const errors = error.inner.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.path]: curr.message,
          }),
          {}
        );
        setFormErrors(errors);
      } else {
        console.error("Submission error:", error);
      }
    }
  };

  const handleEdit = (roleData) => {
    setFormData(roleData);
    setImage(roleData.icon);
    setOpen(true);
  };

  return {
    backgroundAlt,
    primaryLight,
    rolesWithCount,
    columns,
    isLargeScreen,
    image,
    open,
    formErrors,
    fileInputRef,
    defaultImageURL,
    formData,
    handleChange,
    handleOpen,
    handleClose,
    handleFileChange,
    handleSubmit,
    handleEdit,
    handleEditClick,
    setFormData,
  };
}

export default useRoles;
