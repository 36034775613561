import {
  Box,
  Typography,
  useMediaQuery,
  TextField,
  Button,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "scenes/navbar";
import NavbarPublic from "scenes/navbar/navbarPublic";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"; // Changed icon to reflect support context
import { sendEmail } from "redux/actions/mailerActions";
import SnackbarComponent from "components/SnackbarComponent";

function ContactSupport({ socket }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const isLargeScreen = useMediaQuery("(min-width:1000px)");
  const { palette } = useTheme();

  const [name, setName] = useState(
    user ? `${user.firstName} ${user.lastName}` : ""
  );
  const [email, setEmail] = useState(user ? user.email : "");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const emailType = "support";

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  let isPublicPage = !user;

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formValues = {
      name,
      email,
      subject,
      message,
      emailType,
    };
    // Dispatch the action to send the email
    const response = await dispatch(sendEmail(formValues));
    if (response) {
      setSnackbarMessage("Support request sent successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } else {
      setSnackbarMessage("Failed to send support request");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      height="100vh"
      display="flex"
      flexDirection="column"
      marginBottom="1rem"
    >
      {isPublicPage ? <NavbarPublic /> : <Navbar socket={socket} />}
      <Box
        height="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        marginBottom="1rem"
        sx={{
          background: `linear-gradient(135deg, ${palette.primary.light} 50%, ${palette.secondary.light} 50%)`,
        }}
      >
        <Box
          width="100%"
          maxWidth="800px"
          padding={isLargeScreen ? "2rem" : "1rem"}
          display="flex"
          flexDirection="column"
          gap="2rem"
          mb={5}
          bgcolor="background.paper"
          boxShadow={3}
          borderRadius={2}
          p={4}
        >
          <Typography variant="h4" gutterBottom color="textPrimary">
            Need Help? Contact Support
          </Typography>
          <Typography variant="body1" color="textSecondary">
            We're here to help you navigate any issues you might be facing.
            Whether it's a technical glitch or a query about our platform, our
            support team is ready to assist you. Please provide us with details
            below.
          </Typography>
          <TextField
            label="Your Name"
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            label="Your Email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Support Topic"
            variant="outlined"
            fullWidth
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          <TextField
            label="Describe your issue or question"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            endIcon={<HelpOutlineIcon />}
            sx={{ alignSelf: "start", mt: 2 }}
          >
            Submit Request
          </Button>
          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            mt={2}
          >
            For immediate assistance, you can also reach out to us directly at{" "}
            <a href="mailto:hello@sheisaction.com">hello@sheisaction.com</a>
          </Typography>
        </Box>
      </Box>
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        severity={snackbarSeverity}
      />
    </Box>
  );
}

export default ContactSupport;
