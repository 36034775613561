import React from "react";
import {
  Box,
  Drawer,
  Typography,
  ClickAwayListener,
  Button,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import DisplayNotification from "./displayNotifications";

function NotificatonSideDrawer({
  isNotificationSidebarOpen,
  setIsNotificationSidebarOpen,
  primary,
  backgroundAlt,
  isLargeScreen,
  user,
  notifications,
  handleMarkAllOtherNotificationsAsRead,
  unreadNotificationsCount,
  handleLoadMoreNotifications,
}) {
  // Function to handle click away
  const handleClickAway = () => {
    setIsNotificationSidebarOpen(false);
  };

  // Wrap Drawer with ClickAwayListener only when it is open
  const drawerContent = (
    <Drawer
      anchor="right"
      open={isNotificationSidebarOpen}
      variant="persistent"
      sx={{
        width: "350px",
        "& .MuiDrawer-paper": {
          backgroundColor: backgroundAlt,
          boxSizing: "border-box",
          borderWidth: isLargeScreen ? 0 : "2px",
          width: isLargeScreen ? "31%" : "98%",
          top: "75px",
          height: "calc(100vh - 75px)",
          border: `2px solid ${primary}`,
          borderRadius: "10px 0 0 10px",
        },
      }}
    >
      <FlexBetween gap={2} m={2}>
        <Typography variant="h3" fontWeight="bold">
          Notifications
        </Typography>
        {unreadNotificationsCount > 0 && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              handleMarkAllOtherNotificationsAsRead(user._id);
            }}
          >
            Mark all as Read
          </Button>
        )}
      </FlexBetween>
      <Box mx={3}>
        {notifications &&
        notifications.notifications &&
        notifications.notifications.length > 0 ? (
          <>
            {[
              ...new Map(
                notifications.notifications.map((notification) => [
                  notification._id,
                  notification,
                ])
              ).values(),
            ]
              .sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp))
              .map((notification, index) => (
                <Box key={notification._id} my={2}>
                  {" "}
                  {/* Use notification._id for the key */}
                  <DisplayNotification {...notification} />
                </Box>
              ))}
          </>
        ) : (
          <span sx={{ padding: "15px" }}>No new notifications</span>
        )}
      </Box>

      {/* Add this button inside your Drawer component, after displaying the current notifications */}
      {notifications.currentPage < notifications.totalPages && (
        <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              // Handler to load more notifications
              handleLoadMoreNotifications();
            }}
          >
            Load More
          </Button>
        </Box>
      )}
    </Drawer>
  );

  return isNotificationSidebarOpen ? (
    <ClickAwayListener onClickAway={handleClickAway}>
      {drawerContent}
    </ClickAwayListener>
  ) : (
    drawerContent
  );
}

export default NotificatonSideDrawer;
