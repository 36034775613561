import * as yup from "yup";

function updateUserSchema() {
  return yup.object().shape({
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    email: yup.string().email("Invalid email").required("Required"),
    picturePath: yup.string(),
    ageRange: yup
      .string()
      .required("required")
      .matches(
        /^(18-24|25-34|35-44|45-54|55-64|65-74|75\+)$/,
        "invalid age range"
      ),
    location: yup.object().shape({
      address: yup.string().required("required"),
      town: yup.string(),
      country: yup.string(),
      coordinates: yup.array(),
      placeId: yup.string(),
    }),
    sports: yup
      .array()
      .of(
        yup.object().shape({
          sport: yup.string().required("Required"),
          experienceLevel: yup.string().required("Required"),
          frequency: yup.object().shape({
            amount: yup
              .number()
              .min(0, "Amount must be greater than 0")
              .required("Required"),
            unit: yup.string().required("Required"),
          }),
        })
      )
      .required("Required"),
    aboutMe: yup.string(),
  });
}

export default updateUserSchema;
