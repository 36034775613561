import { Typography } from "@mui/material";
import moment from "moment";
import React from "react";

function ArticlePagePublishedDate({ article, primary, isLargeScreen }) {
  const textStyle = {
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)", // Example shadow
  };

  return (
    <Typography
      fontWeight={isLargeScreen ? "normal" : "bold"}
      variant={isLargeScreen ? "body2" : "h5"}
      color={isLargeScreen ? primary : "white"}
      style={isLargeScreen ? {} : textStyle}
    >
      {isLargeScreen ? "Published: " : null}
      {moment(article.published_at).format("MMM Do HH:MM")}
    </Typography>
  );
}

export default ArticlePagePublishedDate;
