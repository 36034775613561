import React from "react";
import { Card, CardContent, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

function SettingsLandingCard({ linkTo, imageUrl, title, description }) {
  const { palette } = useTheme();
  const primary = palette.primary.main;
  const background = palette.background.default;

  return (
    <Card
      sx={{
        flex: 1, // Takes up equal space in the flex container
        backgroundImage: "none",
        backgroundColor: background,
        borderRadius: "0.55rem",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // height: "100%", // Ensures the card stretches to fill the height
        width: "100%", // Ensures the card takes full width available in its flex slot
        maxWidth: "400px",
      }}
    >
      <Link
        to={linkTo}
        style={{ textDecoration: "none", color: "inherit", width: "100%" }}
      >
        <CardContent sx={{ width: "100%", height: "100%" }}>
          <img
            src={imageUrl}
            alt={title.toLowerCase()}
            style={{
              width: "200px",
              height: "200px",
              objectFit: "cover",
              borderRadius: "10px",
              margin: "0 auto",
              display: "block",
            }}
          />
          <Typography variant="h5" color={primary} gutterBottom>
            {title}
          </Typography>
          <Typography>{description}</Typography>
        </CardContent>
      </Link>
    </Card>
  );
}

export default SettingsLandingCard;
