import { useMediaQuery } from "@mui/material";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchBrandArticles } from "redux/actions/articleActions";
import { createBusinessPageVisit } from "redux/actions/businessPageVisitsActions";

const useBrandPageState = () => {
  const { brandId } = useParams();
  const dispatch = useDispatch();
  const debounceRef = useRef();

  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  const brandArticles = useSelector((state) => state.brandArticles);

  useEffect(() => {
    if (brandId) {
      dispatch(fetchBrandArticles(brandId));

      clearTimeout(debounceRef.current);
      debounceRef.current = setTimeout(() => {
        dispatch(createBusinessPageVisit(brandId, "brand"));
      }, 5000); // Debounce time set to 5 seconds
    }

    return () => clearTimeout(debounceRef.current); // Cleanup on unmount or brandId change
  }, [dispatch, brandId]);

  return {
    brandId,
    isLargeScreen,
    brandArticles,
  };
};

export default useBrandPageState;
