import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import React from "react";
import Navbar from "scenes/navbar";
import BrandWidget from "../../BrandPage/components/BrandWidget";
import Footer from "components/Footer";
import BottomMenu from "components/bottomMenu/BottomMenu";
import { useArticlePageState } from "./hooks/useArticlePageState";
import ArticlePageStatus from "./components/ArticlePageStatus";
import ArticlePageTitleImage from "./components/ArticlePageTitleImage";
import ArticlePageContentBlocks from "./components/ArticlePageContentBlocks";
import ArticlePageTags from "./components/ArticlePageTags";
import ArticlePageSubHeader from "./components/ArticlePageSubTitle";
import ArticlePageHeader from "./components/ArticlePageTitle";
import ArticlePageAuthor from "./components/ArticlePageAuthor";
import ArticlePagePublishedDate from "./components/ArticlePagePublishedDate";
import FlexBetween from "components/FlexBetween";
import ArticlePageBrandDeets from "./components/ArticlePageBrandDeets";
import ArticlePageLike from "./components/ArticlePageLike";
import ArticlePageAddComment from "./components/ArticlePageAddComment";
import ArticlePageComments from "./components/ArticlePageComments";
import SnackbarComponent from "components/SnackbarComponent";
import { Link } from "react-router-dom";
import NavbarPublic from "scenes/navbar/navbarPublic";
import { Helmet } from "react-helmet-async";
import ReportForm from "components/ReportForm";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

function BrandArticle({ socket }) {
  const {
    article,
    shouldDisplayContent,
    isLargeScreen,
    handlePublish,
    handleEdit,
    primary,
    primaryLight,
    secondary,
    neutralLight,
    backgroundAlt,
    brand,
    neutralMedium,
    mediumMain,
    handlePatchLike,
    isLiked,
    likeCount,
    background,
    handleCommentChange,
    handleCommentSubmit,
    comment,
    isComments,
    setIsComments,
    closeComments,
    handlePatchLikeComment,
    handleFollowToggle,
    comments,
    commentsCount,
    userId,
    handleMouseEnterLikeButton,
    likesUserNames,
    handleMouseEnterCommentLikeButton,
    commentLikesUserNames,
    isPublicPage,
    snackbarOpen,
    setSnackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    keywords,
    openReportDialog,
    handleOpenReportDialog,
    handleCloseReportDialog,
    handleReportPost,
    formData,
    formErrors,
    handleChange,
    textFieldLabel,
    dialogTitle,
    navigate,
  } = useArticlePageState({ socket });

  // get the website url
  const websiteUrl = window.location.origin;
  // get page url
  const pageUrl = `${websiteUrl}/news/article/${article._id}`;

  return (
    <>
      <Helmet>
        <title>{article.title}</title>
        <meta name="description" content={article.subtitle} />
        <meta name="keywords" content={keywords} />
        <meta
          name="author"
          content={`${article.firstName} ${article.lastName}`}
        />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:title" content={article.title} />
        <meta property="og:description" content={article.subtitle} />
        <meta property="og:image" content={article.titleImage} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content="She is Action" />
        <meta property="og:type" content="article" />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:locale:alternate" content="en_US" />
        <meta name="twitter:card" content="summary_large_image" />
        {/* <meta name="twitter:site" content="@brand" />
        <meta name="twitter:creator" content="@brand" /> */}
        <meta name="twitter:title" content={article.title} />
        <meta name="twitter:description" content={article.subtitle} />
        <meta name="twitter:image" content={article.titleImage} />
      </Helmet>

      <Box display="flex" flexDirection="column" minHeight="100vh">
        {!isPublicPage ? <Navbar socket={socket} /> : <NavbarPublic />}

        <Box
          flexGrow={1}
          overflow="auto"
          width="100%"
          padding={isLargeScreen ? "0 6%" : "0"}
          display={isLargeScreen ? "flex" : "block"}
          gap="2rem"
          justifyContent="center"
        >
          <Box flexBasis={isLargeScreen ? "60%" : undefined}>
            {shouldDisplayContent ? (
              isLargeScreen ? (
                <WidgetWrapper>
                  <ArticlePageStatus
                    article={article}
                    handlePublish={handlePublish}
                    handleEdit={handleEdit}
                    secondary={secondary}
                  />

                  <Box m="0.5rem">
                    <ArticlePageTags
                      article={article}
                      primary={primary}
                      neutralLight={neutralLight}
                    />
                    <ArticlePageHeader
                      article={article}
                      isLargeScreen={isLargeScreen}
                    />

                    <ArticlePageTitleImage
                      article={article}
                      isLargeScreen={isLargeScreen}
                    />

                    <FlexBetween>
                      <ArticlePagePublishedDate
                        article={article}
                        primary={primary}
                        isLargeScreen={isLargeScreen}
                      />
                      <ArticlePageAuthor article={article} primary={primary} />
                    </FlexBetween>

                    <ArticlePageSubHeader article={article} />
                    <ArticlePageContentBlocks article={article} />
                    <ArticlePageLike
                      article={article}
                      primary={primary}
                      mediumMain={mediumMain}
                      handlePatchLike={handlePatchLike}
                      isLiked={isLiked}
                      likeCount={likeCount}
                      handleMouseEnterLikeButton={handleMouseEnterLikeButton}
                      likesUserNames={likesUserNames}
                      userId={userId}
                      neutralMedium={neutralMedium}
                      handleOpenReportDialog={handleOpenReportDialog}
                    />

                    <ArticlePageComments
                      article={article}
                      background={background}
                      primary={primary}
                      isComments={isComments}
                      setIsComments={setIsComments}
                      closeComments={closeComments}
                      comments={comments}
                      commentsCount={commentsCount}
                      userId={userId}
                      handlePatchLikeComment={handlePatchLikeComment}
                      handleMouseEnterCommentLikeButton={
                        handleMouseEnterCommentLikeButton
                      }
                      commentLikesUserNames={commentLikesUserNames}
                      neutralMedium={neutralMedium}
                      handleOpenReportDialog={handleOpenReportDialog}
                    />
                    {!isPublicPage ? (
                      <ArticlePageAddComment
                        background={background}
                        handleCommentChange={handleCommentChange}
                        handleCommentSubmit={handleCommentSubmit}
                        comment={comment}
                      />
                    ) : (
                      <Typography variant="caption">
                        Please{" "}
                        <Typography
                          variant="span"
                          color="primary"
                          fontWeight={700}
                        >
                          <Link to="/">log in</Link>{" "}
                        </Typography>
                        to comment
                      </Typography>
                    )}
                  </Box>
                </WidgetWrapper>
              ) : (
                <>
                  <ArticlePageStatus
                    article={article}
                    handlePublish={handlePublish}
                    handleEdit={handleEdit}
                    secondary={secondary}
                  />

                  <Box position="relative">
                    <ArticlePageTitleImage
                      article={article}
                      isLargeScreen={isLargeScreen}
                    />
                    <ArrowBackIosNewIcon
                      sx={{
                        position: "absolute",
                        top: "1rem",
                        left: "1rem",
                        zIndex: 1,
                        color: primary,
                        fontSize: "2rem",
                        cursor: "pointer",
                        backgroundColor: "white",
                        borderRadius: "50%",
                        padding: "0.25rem",
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                      }}
                      onClick={() => navigate(-1)}
                    />

                    <Box m=" 0 1rem" position="absolute" bottom="50px">
                      <ArticlePageTags
                        article={article}
                        primary={primary}
                        neutralLight={neutralLight}
                      />
                      <ArticlePageHeader
                        article={article}
                        isLargeScreen={isLargeScreen}
                      />

                      <ArticlePagePublishedDate
                        article={article}
                        primary={primary}
                        isLargeScreen={isLargeScreen}
                      />
                      <ArticlePageBrandDeets
                        brand={brand}
                        primary={primary}
                        primaryLight={primaryLight}
                        handleFollowToggle={handleFollowToggle}
                      />
                    </Box>
                  </Box>
                  <Box
                    mt="-2rem"
                    p="1rem"
                    position="relative"
                    borderRadius="2rem 2rem 0 0"
                    backgroundColor={backgroundAlt}
                  >
                    <ArticlePageSubHeader article={article} />

                    <ArticlePageContentBlocks article={article} />
                    <ArticlePageAuthor article={article} primary={primary} />
                    <ArticlePageLike
                      article={article}
                      primary={primary}
                      mediumMain={mediumMain}
                      handlePatchLike={handlePatchLike}
                      isLiked={isLiked}
                      likeCount={likeCount}
                      handleMouseEnterLikeButton={handleMouseEnterLikeButton}
                      likesUserNames={likesUserNames}
                      userId={userId}
                      neutralMedium={neutralMedium}
                      handleOpenReportDialog={handleOpenReportDialog}
                    />
                    <ArticlePageComments
                      article={article}
                      background={background}
                      primary={primary}
                      isComments={isComments}
                      setIsComments={setIsComments}
                      closeComments={closeComments}
                      comments={comments}
                      commentsCount={commentsCount}
                      userId={userId}
                      handlePatchLikeComment={handlePatchLikeComment}
                      handleMouseEnterCommentLikeButton={
                        handleMouseEnterCommentLikeButton
                      }
                      commentLikesUserNames={commentLikesUserNames}
                      neutralMedium={neutralMedium}
                      handleOpenReportDialog={handleOpenReportDialog}
                    />

                    {!isPublicPage ? (
                      <ArticlePageAddComment
                        background={background}
                        handleCommentChange={handleCommentChange}
                        handleCommentSubmit={handleCommentSubmit}
                        comment={comment}
                      />
                    ) : (
                      <Typography variant="caption">
                        Please{" "}
                        <Typography
                          variant="span"
                          color="primary"
                          fontWeight={700}
                        >
                          <Link to="/">log in</Link>{" "}
                        </Typography>
                        to comment
                      </Typography>
                    )}
                  </Box>
                </>
              )
            ) : (
              <Typography variant="h4" color={secondary}>
                This article is not visible to the public.
              </Typography>
            )}
          </Box>
          <SnackbarComponent
            open={snackbarOpen}
            message={snackbarMessage}
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarSeverity}
          />
          <Dialog
            open={openReportDialog}
            onClose={handleCloseReportDialog}
            aria-labelledby="form-dialog-title"
            aria-describedby="form-dialog-description"
          >
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent
              style={{
                width: isLargeScreen ? "600px" : "300px",
              }}
            >
              <ReportForm
                formData={formData}
                formErrors={formErrors}
                handleChange={handleChange}
                textFieldLabel={textFieldLabel}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseReportDialog}>Cancel</Button>
              <Button onClick={handleReportPost} color="primary">
                Report
              </Button>
            </DialogActions>
          </Dialog>
          {isLargeScreen ? (
            <Box flexBasis={isLargeScreen ? "35%" : undefined}>
              <BrandWidget brandId={article.brand} />
            </Box>
          ) : undefined}
        </Box>
        {!isPublicPage && <Footer />}
      </Box>
      {!isLargeScreen && !isPublicPage && <BottomMenu socket={socket} />}
    </>
  );
}

export default BrandArticle;
