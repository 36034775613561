import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { getOnlineUsers } from "redux/actions/onlineUsersActions";

const useSocket = (
  socket,
  selectedChat,
  user,
  setMessages,
  setFetchAgain,
  fetchAgain
) => {
  const selectedChatRef = useRef(selectedChat);
  useEffect(() => {
    selectedChatRef.current = selectedChat;
  }, [selectedChat]);

  const [socketConnected, setSocketConnected] = useState(false);
  const [istyping, setIsTyping] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchOnlineUsers = async () => {
      try {
        const response = await dispatch(getOnlineUsers());

        // Filter only the users who are online and map to get their userIds
        const onlineUserIds = response
          .filter((userStatus) => userStatus.isOnline)
          .map((userStatus) => userStatus.userId);

        setOnlineUsers(onlineUserIds);
      } catch (error) {
        console.error("Error fetching online users:", error);
      }
    };

    fetchOnlineUsers();
  }, [dispatch]);

  useEffect(() => {
    if (socket) {
      socket.emit("setup", user);
      socket.on("connected", () => setSocketConnected(true));
      socket.on("typing", () => setIsTyping(true));
      socket.on("stop typing", () => setIsTyping(false));
      socket.on("message received", (newMessageReceived) => {
        if (selectedChatRef.current !== newMessageReceived.chat._id) return;
        setMessages((prevMessages) => [newMessageReceived, ...prevMessages]);
        setFetchAgain(!fetchAgain);
      });
      socket.on("userOnline", (userId) => {
        setOnlineUsers((prevUsers) => [...prevUsers, userId]);
      });
      socket.on("userOffline", (userId) => {
        setOnlineUsers((prevUsers) => prevUsers.filter((u) => u !== userId));
      });

      return () => {
        socket.off("connected");
        socket.off("typing");
        socket.off("stop typing");
        socket.off("message received");
        socket.off("userOnline");
        socket.off("userOffline");
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  return { socketConnected, istyping, onlineUsers };
};

export default useSocket;
