import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { IconButton, TextField, InputAdornment, Tooltip } from "@mui/material";
import { useState } from "react";

const FormEmailPassword = (props) => {
  const { handleChange, handleBlur, errors, touched, values } = props;

  const [showPassword, setShowPassword] = useState(false);

  // Function to toggle the password visibility
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Function to handle mouse down event on the icon button
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Password requirements message
  const passwordTooltip =
    "Password must be 5-25 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one of the following special characters: @$!%*?&-";

  // modified handleChange function to convert email to lowercase
  const handleEmailChange = (e) => {
    handleChange({
      target: {
        name: e.target.name,
        value: e.target.value.toLowerCase(),
      },
    });
  };

  return (
    <>
      <TextField
        label="Email"
        onBlur={handleBlur}
        onChange={handleEmailChange}
        value={values.email}
        name="email"
        error={Boolean(touched.email) && Boolean(errors.email)}
        helperText={touched.email && errors.email}
        sx={{ gridColumn: "span 4" }}
      />
      <Tooltip title={passwordTooltip} arrow placement="right-start">
        <TextField
          label="Password"
          type={showPassword ? "text" : "password"}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.password}
          name="password"
          error={Boolean(touched.password) && Boolean(errors.password)}
          helperText={touched.password && errors.password}
          sx={{ gridColumn: "span 4" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  sx={{
                    backgroundColor: "transparent",
                    paddingRight: "1rem",
                  }}
                >
                  {showPassword ? (
                    <VisibilityOffOutlined />
                  ) : (
                    <VisibilityOutlined />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Tooltip>
    </>
  );
};

export default FormEmailPassword;
