import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function TopStoryTags({ tags, neutralDark, neutralLight }) {
  return tags && tags.length > 0 ? (
    <Box mb={2}>
      {tags.map((tag, index) => (
        <Link
          key={index}
          to={`/news/${tag.name}`}
          style={{ textDecoration: "none" }}
        >
          <Box
            sx={{
              display: "inline-block",
              backgroundColor: neutralDark,
              p: "0.1rem 0.75rem",
              borderRadius: "1rem",
              mt: 1,
              mr: 1, // added margin-right for spacing between boxes
              maxWidth: "fit-content",
            }}
          >
            <Typography variant="h6" color={neutralLight} fontSize={12}>
              {tag.name}
            </Typography>
          </Box>
        </Link>
      ))}
    </Box>
  ) : null;
}

export default TopStoryTags;
