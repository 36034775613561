import { useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNotificationSettings,
  updateNotificationPreferences,
} from "redux/actions/userActions";

const notificationTypes = [
  "Comments",
  "Likes",
  "Friends Added",
  "Group Updates",
  "Messages",
  "New Articles",
  // "Article Updates",
];

const useNotificationSettingsState = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const userId = user._id;

  const userNotificationSettings = useSelector(
    (state) => state.userNotificationSettings
  );

  const { palette } = useTheme();
  const background = palette.background.default;
  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  // State to manage user preferences
  const [notificationPreferences, setNotificationPreferences] = useState(
    notificationTypes.reduce((acc, type) => {
      acc[type] = { push: true, email: true };
      return acc;
    }, {})
  );

  // State for DND, Notification Frequency, and Quiet Hours
  const [notificationFrequency, setNotificationFrequency] = useState("weekly");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  useEffect(() => {
    dispatch(fetchNotificationSettings(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (!userNotificationSettings || !userNotificationSettings.preferences)
      return;

    setNotificationFrequency(userNotificationSettings.notificationFrequency);

    const updatedPreferences = notificationTypes.reduce((acc, type) => {
      acc[type] = userNotificationSettings.preferences[type] || {
        push: true,
        email: true,
      };
      return acc;
    }, {});

    setNotificationPreferences(updatedPreferences);
  }, [userNotificationSettings]);

  if (!userNotificationSettings || !notificationPreferences) {
    return <div>Loading...</div>; // Improved check for both user settings and preferences
  }

  // Handler for saving changes (you might call this in response to a user action like clicking a "Save" button)
  const handleSaveChanges = () => {
    try {
      const preferencesToUpdate = {
        notificationFrequency,
        preferences: Object.keys(notificationPreferences).reduce(
          (acc, type) => {
            acc[type] = {
              push: notificationPreferences[type].push,
              email: notificationPreferences[type].email,
            };
            return acc;
          },
          {}
        ),
      };

      dispatch(updateNotificationPreferences(userId, preferencesToUpdate));
      setSnackbarMessage("Notification settings updated successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      setSnackbarMessage(
        errorMessage || "An error occurred while updating notification settings"
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleFrequencyChange = (event) => {
    setNotificationFrequency(event.target.value);
  };

  // Handle toggle change
  const handleToggle = (type, method) => {
    setNotificationPreferences((preference) => {
      const newPreference = {
        ...preference,
        [type]: { ...preference[type], [method]: !preference[type][method] },
      };

      // If the method is 'push' and it's being turned off, also turn off 'email'
      if (method === "push" && !newPreference[type][method]) {
        newPreference[type]["email"] = false;
      }

      // if the method is 'email' and it's being turned on, also turn on 'push'
      if (method === "email" && newPreference[type][method]) {
        newPreference[type]["push"] = true;
      }

      return newPreference;
    });
  };

  // Function to create a comma-separated string of enabled notification methods
  const enabledNotifications = (type) => {
    if (!notificationPreferences[type]) {
      console.error(`Missing notification preferences for type: ${type}`);
      return []; // Return an empty array or some default value to avoid crashing
    }
    const notifications = Object.entries(notificationPreferences[type])
      .filter(([key, enabled]) => enabled && key !== "_id")
      .map(([method]) => method.charAt(0).toUpperCase() + method.slice(1)); // Capitalize the first letter of the method
    return notifications;
  };

  function getNotificationDescription(type) {
    switch (type) {
      case "Comments":
        return "These are notifications for comments on your posts. ";
      case "Likes":
        return "These are notifications for likes on your posts and comments. ";
      case "Friends Added":
        return "These are notifications for when someone adds you as a friend. ";
      case "Group Updates":
        return "These are notifications for updates in your groups. ";
      case "Messages":
        return "These are notifications for new messages. ";
      case "New Articles":
        return "These are notifications for new articles. ";
      case "Article Updates":
        return "These are notifications for updates to articles you follow. ";
      default:
        return `These are notifications for ${type.toLowerCase()}`;
    }
  }

  return {
    notificationTypes,
    notificationPreferences,
    notificationFrequency,
    snackbarOpen,
    setSnackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    handleSaveChanges,
    handleFrequencyChange,
    handleToggle,
    enabledNotifications,
    getNotificationDescription,
    background,
    isLargeScreen,
  };
};

export default useNotificationSettingsState;
