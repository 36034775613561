import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import SnackbarComponent from "components/SnackbarComponent";
import DOMPurify from "dompurify";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSponsor,
  fetchSponsor,
  fetchSponsors,
} from "redux/actions/sponsorActions";

function SponsorGrid({ handleEdit }) {
  const { palette } = useTheme();
  const backgroundAlt = palette.background.alt;
  const primaryLight = palette.primary.light;
  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  const dispatch = useDispatch();
  const sponsors = useSelector((state) => state.sponsors) || [];
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  useEffect(() => {
    dispatch(fetchSponsors());
  }, [dispatch]);

  if (!sponsors.length) {
    return <div>Loading...</div>; // Or any other loading state representation
  }

  // Function to replace consecutive '<p><br></p>' tags
  const replaceConsecutiveBreaks = (match) => {
    // Count the number of occurrences
    const count = match.split("<p><br></p>").length - 1;
    // Replace with one fewer '<br>' than the count
    return "<br>".repeat(Math.max(count - 1, 0));
  };

  const sanitizeContent = (htmlContent) => {
    // Replace consecutive occurrences of '<p><br></p>'
    const updatedContent = DOMPurify.sanitize(htmlContent).replace(
      /(<p><br><\/p>)+/g,
      replaceConsecutiveBreaks
    );

    return {
      __html: updatedContent,
    };
  };

  const handleEditClick = async (id) => {
    const sponsorId = id;
    try {
      const sponsorData = await dispatch(fetchSponsor(sponsorId));

      handleEdit(sponsorData);
    } catch (error) {
      console.log("Error fetching sponsor:", error);
      setSnackbarMessage("Error fetching sponsor");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleDeleteClick = (id) => {
    setOpenDialog(true);
    setDeleteId(id);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await dispatch(deleteSponsor(deleteId));
      if (response.status === 200) {
        setSnackbarMessage("Sponsor deleted successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("Error deleting sponsor");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);

      console.log("Error deleting sponsor:", error);
    }
    setOpenDialog(false); // Close the dialog after confirmation
  };

  const handleCancelDelete = () => {
    setOpenDialog(false);
  };

  const columns = [
    {
      field: "name",
      headerName: "Sponsor Name",
      flex: 1,
      renderCell: (params) => {
        const avatarUrl = params.row.imagePath.endsWith("/public")
          ? params.row.imagePath.replace("/public", "/128x128")
          : `${params.row.imagePath}/128x128`;
        return (
          <Box display="flex" alignItems="center">
            <Avatar src={avatarUrl} alt={params.value} sx={{ mr: 1 }} />
            {params.value}
          </Box>
        );
      },
    },
    {
      field: "website",
      headerName: "Website",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
      renderCell: (params) => (
        <Box dangerouslySetInnerHTML={sanitizeContent(params.value)} />
      ),
    },
    {
      field: "scheduledStart",
      headerName: "Start Date",
      flex: 0.75,
      valueGetter: (params) => moment(params.value).format("MM/DD/YYYY"),
    },
    {
      field: "scheduledEnd",
      headerName: "End Date",
      flex: 0.75,
      valueGetter: (params) => moment(params.value).format("MM/DD/YYYY"),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
    },
    {
      field: "edit",
      headerName: "Edit",
      flex: 0.75,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEditClick(params.row._id)}
            sx={{
              padding: "5px 7px",
              fontSize: "0.5rem",
            }}
          >
            Edit
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      flex: 0.75,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDeleteClick(params.row._id)}
            sx={{
              padding: "5px 7px",
              fontSize: "0.5rem",
            }}
          >
            Delete
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Box
        m={isLargeScreen ? "1.5rem 2rem 2rem 3rem" : "1.5rem 0 2rem 0"}
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            // border: "none",
          },
          "& .MuiDataGrid-cell": {
            // borderBottom: "none",
            whiteSpace: "normal",
            wordBreak: "break-word",
            overflow: "visible",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: backgroundAlt,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: backgroundAlt,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: primaryLight,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {},
          "& .MuiDataGrid-row": {
            height: "auto", // This ensures the row height adapts to content
          },
        }}
      >
        <DataGrid
          getRowId={(row) => {
            return row._id;
          }}
          rows={sponsors}
          columns={columns}
          disableSelectionOnClick
        />
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this sponsor?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button onClick={handleConfirmDelete} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        severity={snackbarSeverity}
      />
    </>
  );
}

export default SponsorGrid;
