import {
  Autocomplete,
  Box,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLocationDetails,
  fetchLocations,
} from "redux/actions/locationActions";
import { debounce } from "lodash";
import FlexBetween from "components/FlexBetween";

function BrandLocationEdit({ brandData, setBrandData, formErrors }) {
  // Check if the errors for the first location exist and are an object
  const firstLocationErrors =
    formErrors["locations[0]"] && typeof formErrors["locations[0]"] === "object"
      ? formErrors["locations[0]"]
      : {};

  // Now access the individual errors
  const locationTypeErrors = firstLocationErrors.locationType;
  const addressErrors = firstLocationErrors.address;

  const dispatch = useDispatch();
  const locations = useSelector((state) => state.locations);

  const [locationInput, setLocationInput] = useState("");
  const [locationOptions, setLocationOptions] = useState([]);
  const [locationDetails, setLocationDetails] = useState(null);
  const [locationSelected, setLocationSelected] = useState(false);

  const debouncedFetchLocations = useMemo(
    () =>
      debounce(async (input) => {
        try {
          await dispatch(fetchLocations(input));
        } catch (error) {
          console.error("Error fetching locations:", error);
        }
      }, 500),
    [dispatch]
  );

  useEffect(() => {
    if (locationInput !== "") {
      debouncedFetchLocations(locationInput);
    } else {
      setLocationOptions([]);
    }
    return () => {
      if (locationInput === "") {
        debouncedFetchLocations.cancel();
      }
    };
  }, [locationInput, debouncedFetchLocations]);

  useEffect(() => {
    const formattedLocations = locations.map((location) => ({
      ...location,
      description: location.description || "Unknown location",
    }));

    setLocationOptions(formattedLocations);
  }, [locations]);

  const handleLocationChange = (event, newValue) => {
    if (newValue) {
      setBrandData({
        ...brandData,
        locations: [
          {
            ...brandData.locations[0],
            address: newValue.description,
          },
          ...brandData.locations.slice(1),
        ],
      });
      setLocationSelected(newValue);
    } else {
      // newValue is null, so clear the address field.
      setBrandData({
        ...brandData,
        locations: [
          {
            ...brandData.locations[0],
            address: "", // clearing the address field
          },
          ...brandData.locations.slice(1),
        ],
      });
      setLocationSelected(null);
    }
  };

  useEffect(() => {
    if (locationSelected && locationSelected.place_id) {
      const fetchDetails = async () => {
        const details = await dispatch(
          fetchLocationDetails(locationSelected.place_id)
        );
        setLocationDetails(details.locationDetails);
      };
      fetchDetails();
    }
  }, [locationSelected, dispatch]);

  useEffect(() => {
    if (locationDetails) {
      setBrandData((prevBrandData) => ({
        ...prevBrandData,
        locations: [
          {
            ...prevBrandData.locations[0],
            town: locationDetails.town ? locationDetails.town : "",
            country: locationDetails.country,
            coordinates: [locationDetails.lng, locationDetails.lat],
            placeId: locationDetails.placeId,

            /* if the address matches the country and town is empty,
            then locality is "National" else "Local" */
            locality:
              locationDetails.address === locationDetails.country &&
              locationDetails.town === ""
                ? "National"
                : "Local",
          },
          ...prevBrandData.locations.slice(1),
        ],
      }));
    }
  }, [locationDetails, setBrandData]);

  return (
    <Box width="100%">
      <Typography variant="h5">Locations</Typography>
      {brandData.locations && brandData.locations.length > 0 ? (
        <Box display="flex" flexDirection="column" gap="0.5rem">
          <FlexBetween gap={1}>
            <TextField
              margin="dense"
              label="Location Type"
              name="locationType"
              value={brandData.locations[0].locationType}
              onChange={(e) =>
                setBrandData({
                  ...brandData,
                  locations: [
                    { ...brandData.locations[0], locationType: e.target.value },
                    ...brandData.locations.slice(1),
                  ],
                })
              }
              fullWidth
              variant="outlined"
              error={!!locationTypeErrors}
              helperText={
                locationTypeErrors ||
                (typeof formErrors.locations === "string"
                  ? formErrors.locations
                  : "")
              }
            />
            <TextField
              margin="dense"
              label="Locality"
              name="locality"
              value={brandData.locations[0].locality}
              fullWidth
              variant="outlined"
              disabled={true}
            />
          </FlexBetween>
          <FormControl
            variant="outlined"
            fullWidth
            margin="normal"
            style={{ marginTop: "7px" }}
          >
            <Autocomplete
              id="location-autocomplete"
              options={locationOptions}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.description
              }
              value={
                brandData.locations[0].address
                  ? { description: brandData.locations[0].address }
                  : null
              }
              isOptionEqualToValue={(option, value) =>
                option.description === value.description
              } // Added custom equality check function
              onInputChange={(event, newInputValue, reason) => {
                if (reason === "input") {
                  setLocationInput(newInputValue);
                }
              }}
              onChange={handleLocationChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Address"
                  name="address"
                  variant="outlined"
                  error={!!addressErrors}
                  helperText={
                    addressErrors ||
                    (typeof formErrors.locations === "string"
                      ? formErrors.locations
                      : "")
                  }
                />
              )}
            />
          </FormControl>
          <TextField
            margin="dense"
            label="Town / City"
            name="town"
            value={brandData.locations[0].town}
            fullWidth
            variant="outlined"
            disabled={true}
          />
          <TextField
            margin="dense"
            label="Country"
            name="country"
            value={brandData.locations[0].country}
            fullWidth
            variant="outlined"
            disabled={true}
          />
        </Box>
      ) : undefined}
    </Box>
  );
}
export default BrandLocationEdit;
