import React, { useEffect, useRef } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const GOOGLE_MAP_ID = process.env.REACT_APP_GOOGLE_MAP_ID; // Add your Map ID here

const containerStyle = {
  width: "100%",
  height: "400px",
};

const libraries = ["marker"]; // Define the libraries array outside the component

const MapComponent = ({ location, brandName, zoom = 12 }) => {
  // console.log("🚀 ~ MapComponent ~ location:", location);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries, // Use the static libraries array
  });

  const mapRef = useRef(null);
  const markerRef = useRef(null);

  useEffect(() => {
    if (isLoaded && mapRef.current && location) {
      const map = mapRef.current;
      const marker = new window.google.maps.marker.AdvancedMarkerElement({
        position: location,
        map: map,
        title: brandName,
      });
      markerRef.current = marker;
    }
  }, [isLoaded, location, brandName]);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={location}
      zoom={zoom}
      options={{ disableDefaultUI: false, mapId: GOOGLE_MAP_ID }} // Include the Map ID
      onLoad={(map) => (mapRef.current = map)}
    >
      {/* No need to include <Marker> component here */}
    </GoogleMap>
  ) : (
    <div>Loading...</div>
  );
};

export default MapComponent;
