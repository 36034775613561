import { Typography } from "@mui/material";
import React from "react";

function TopStoryTitle({ title, containerStyle }) {
  return (
    <Typography variant="h5" fontWeight="500" mt={3} style={containerStyle}>
      {title}
    </Typography>
  );
}

export default TopStoryTitle;
