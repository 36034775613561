import { Box } from "@mui/material";
import Footer from "components/Footer";
import BottomMenu from "components/bottomMenu/BottomMenu";
import React from "react";
import Navbar from "scenes/navbar";
import BrandWidget from "./components/BrandWidget";
import BrandArticleCard from "./components/BrandArticleCard";
import WidgetWrapper from "components/WidgetWrapper";
import useBrandPageState from "./hooks/useBrandPageState";

const BrandPage = ({ socket }) => {
  const { brandId, isLargeScreen, brandArticles } = useBrandPageState();

  return (
    <>
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <Navbar socket={socket} />
        <Box
          flexGrow={1}
          overflow="auto"
          width="100%"
          // padding="2rem 6%"
          padding={isLargeScreen ? "0 6%" : "0"}
          display={isLargeScreen ? "flex" : "block"}
          gap="2rem"
          justifyContent="center"
        >
          <Box flexBasis={isLargeScreen ? "35%" : undefined}>
            <BrandWidget brandId={brandId} />
          </Box>
          <Box
            flexBasis={isLargeScreen ? "60%" : undefined}
            mt={isLargeScreen ? undefined : "2rem"}
          >
            <WidgetWrapper>
              {/* Brand Articles Grid */}

              <Box
                display="grid"
                gridTemplateColumns="repeat(2, minmax(0,1fr))"
                justifyContent="space-between"
                rowGap="20px"
                columnGap="1.33%"
                sx={{
                  "& > div": {
                    gridColumn: isLargeScreen ? undefined : "span 4",
                  },
                }}
              >
                {Array.isArray(brandArticles) &&
                  brandArticles.map((article) => (
                    <BrandArticleCard article={article} key={article._id} />
                  ))}
              </Box>
            </WidgetWrapper>
          </Box>
        </Box>
        <Footer />
      </Box>
      {!isLargeScreen && <BottomMenu socket={socket} />}
    </>
  );
};

export default BrandPage;
