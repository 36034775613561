import { Box, Typography } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import { BusinessProfileClick } from "helpers/businessHelpers/businessProfileClick";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

function TopStoryBottomDetail({
  brandName,
  brandLogoPath,
  brandId,
  sportName,
  publishedAt,
  neutralMedium,
  primary,
  neutralLight,
  containerStyle,
}) {
  const dispatch = useDispatch();
  return (
    <FlexBetween alignItems="center" style={containerStyle}>
      <Link
        to={`/brand/${brandId}`}
        onClick={() =>
          BusinessProfileClick(dispatch, {
            brandId: brandId,
            fromPage: "topStories",
          })
        }
      >
        <Box display="flex" alignItems="center" gap="5px">
          <img src={brandLogoPath} alt={brandName} style={{ width: "30px" }} />
          <Typography
            variant="body1"
            fontWeight="500"
            color={neutralMedium}
            fontSize={12}
          >
            {brandName}
          </Typography>
        </Box>
      </Link>

      <Box
        sx={{
          backgroundColor: primary,
          p: "0.1rem 0.75rem",
          borderRadius: "1rem",
        }}
      >
        <Typography variant="h6" fontSize={12} color={neutralLight}>
          {sportName}
        </Typography>
      </Box>
      <Typography
        variant="p"
        fontWeight="500"
        color={neutralMedium}
        fontSize={12}
      >
        {moment(publishedAt).format("MMMM Do")}
      </Typography>
    </FlexBetween>
  );
}

export default TopStoryBottomDetail;
