import { Autocomplete, Box, Chip, FormControl, TextField } from "@mui/material";

import React from "react";

function TagInput({
  tags,
  tagsList,
  newTagName,
  setNewTagName,
  handleAddTag,
  handleRemoveTag,
  error,
}) {
  return (
    <Box m="1.5rem 0">
      <FormControl fullWidth margin="normal" error={Boolean(error)}>
        <Autocomplete
          multiple
          id="tags-autocomplete"
          options={tagsList.map((option) => option.name)}
          freeSolo // Allows the user to enter a tag not in the list
          value={tags.filter(
            (tag) =>
              tagsList.map((option) => option.name).includes(tag) ||
              typeof tag === "string"
          )}
          onChange={(event, newValue) => {
            // newValue contains both existing tags and potentially a new tag entered by the user
            // Filter out any non-string values, as those would be the new tag objects
            const newTagNames = newValue.filter(
              (newTag) => typeof newTag === "string"
            );
            // Update the tags in the parent component
            handleAddTag(newTagNames);
          }}
          inputValue={newTagName}
          onInputChange={(event, newInputValue) => {
            // Handle changes when the input value changes
            setNewTagName(newInputValue);
          }}
          renderTags={(tagValue, getTagProps) =>
            Array.isArray(tagValue)
              ? tagValue.map((option, index) => (
                  <Chip
                    label={option}
                    {...getTagProps({ index })}
                    onDelete={() => handleRemoveTag(option)}
                  />
                ))
              : null
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label="Tags"
              placeholder="Add a tag"
              error={Boolean(error)}
              helperText={error}
            />
          )}
        />
      </FormControl>
    </Box>
  );
}

export default TagInput;
