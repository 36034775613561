import { Box, Button, Typography } from "@mui/material";
import React from "react";

function ArticlePageStatus({ article, handlePublish, handleEdit, secondary }) {
  return (
    <>
      {article && article.status === "draft" && (
        <>
          <Typography variant="h4" color={secondary}>
            This article is a draft and is not visible to the public.
          </Typography>
          <Box mt={2}>
            <Button variant="contained" color="primary" onClick={handlePublish}>
              Publish
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleEdit}
              style={{ marginLeft: "1rem" }}
            >
              Edit
            </Button>
          </Box>
        </>
      )}
      {article && article.status === "archived" && (
        <>
          <Typography variant="h4" color={secondary}>
            This article has been archived and is not visible to the public.
          </Typography>
          <Box mt={2}>
            <Button variant="outlined" color="primary" onClick={handleEdit}>
              Edit
            </Button>
          </Box>
        </>
      )}
    </>
  );
}

export default ArticlePageStatus;
