import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBlockedUsers,
  fetchSoftBlockedUsers,
  unblockUser,
  unsoftBlockUser,
} from "redux/actions/userActions";

const useBlockUsers = () => {
  const dispatch = useDispatch();

  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  const [blockedUsers, setBlockedUsers] = useState([]);
  const [softBlockedUsers, setSoftBlockedUsers] = useState([]);

  const blockUserCount =
    useSelector((state) => state.user.blockedUsers?.length) || 0;
  const softBlockUserCount =
    useSelector((state) => state.user.softBlockedUsers?.length) || 0;

  const user = useSelector((state) => state.user) || {};
  const userId = user._id;

  useEffect(() => {
    const fetchUsers = async () => {
      const blocked = await dispatch(fetchBlockedUsers(userId));
      const softBlocked = await dispatch(fetchSoftBlockedUsers(userId));
      setBlockedUsers(blocked || []);
      setSoftBlockedUsers(softBlocked || []);
    };

    if (userId) {
      fetchUsers();
    }
  }, [dispatch, userId]);

  const handleUnblock = async (blockedUserId) => {
    try {
      const success = await dispatch(unblockUser(userId, blockedUserId));
      if (success) {
        setBlockedUsers(
          blockedUsers.filter((user) => user._id !== blockedUserId)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUnsoftBlock = async (softBlockedUserId) => {
    try {
      const success = await dispatch(
        unsoftBlockUser(userId, softBlockedUserId)
      );
      if (success) {
        setSoftBlockedUsers(
          softBlockedUsers.filter((user) => user._id !== softBlockedUserId)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    isLargeScreen,
    blockedUsers,
    softBlockedUsers,
    handleUnblock,
    handleUnsoftBlock,
    blockUserCount,
    softBlockUserCount,
  };
};

export default useBlockUsers;
