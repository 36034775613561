import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import UserSearchModal from "../../../components/UserSearchModal";

function BrandArticleWriterEdit({ brandData, setBrandData }) {
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  const handleSave = (newCopyWriters) => {
    setBrandData({
      ...brandData,
      copyWriters: [...newCopyWriters],
    });
    setIsSearchModalOpen(false);
  };

  return (
    <Box m="1rem 0">
      <Typography variant="h5" marginBottom="-8px">
        Brand Article Writers
      </Typography>
      <Box m="1rem 0">
        {brandData.copyWriters && brandData.copyWriters.length > 0 ? (
          brandData.copyWriters.map((copyWriter, index) => (
            <Box key={index}>
              <TextField
                label="Name"
                variant="outlined"
                value={`${copyWriter.firstName} ${copyWriter.lastName}`}
                disabled
                margin="dense"
              />
              <TextField
                label="Email"
                variant="outlined"
                value={copyWriter.email}
                disabled
                margin="dense"
                style={{ width: "300px", marginLeft: "1rem" }}
              />
            </Box>
          ))
        ) : (
          <Typography variant="body1">No article writers assigned.</Typography>
        )}
      </Box>
      <Button variant="contained" onClick={() => setIsSearchModalOpen(true)}>
        Edit Article Writers
      </Button>
      <UserSearchModal
        open={isSearchModalOpen}
        onClose={() => setIsSearchModalOpen(false)}
        userData={{ users: brandData.copyWriters }}
        onSave={handleSave}
      />
    </Box>
  );
}

export default BrandArticleWriterEdit;
