import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import Header from "../components/Header";
import FlexBetween from "components/FlexBetween";
import { PlaylistAdd } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { createSport, updateSport } from "redux/actions/sportActions";
import SportForm from "./components/sportForm";
import SportGrid from "./components/sportGrid";

function Sports() {
  const dispatch = useDispatch();

  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    category: "",
    popularity: "medium",
    teamSize: "",
    equipment: [],
  });
  const [formErrors, setFormErrors] = useState({});

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required("Sport name is required.")
      .min(2, "Sport name should be at least 2 characters.")
      .max(50, "Sport name should be at most 50 characters."),
    description: yup
      .string()
      .required("Description is required.")
      .min(2, "Description should be at least 2 characters.")
      .max(500, "Description should be at most 500 characters."),
    category: yup
      .string()
      .required("Category is required.")
      .min(2, "Category should be at least 2 characters.")
      .max(50, "Category should be at most 50 characters."),
    teamSize: yup
      .string()
      .required("Team size is required.")
      .min(1, "Team size should be at least 1 character.")
      .max(100, "Team size should be at most 100 characters."),
    equipment: yup
      .array()
      .of(
        yup
          .string()
          .min(2, "Equipment should be at least 2 characters.")
          .max(100, "Equipment should be at most 100 characters.")
      ),
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "equipment") {
      // Directly set the string value to allow for comma input
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleOpen = () => {
    // reset form data
    resetForm();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const resetForm = () => {
    setFormData({
      name: "",
      description: "",
      category: "",
      popularity: "medium",
      teamSize: "",
      equipment: [],
    });
  };

  const handleSubmit = async () => {
    const equipmentAsString = Array.isArray(formData.equipment)
      ? formData.equipment.join(", ")
      : formData.equipment;

    const formDataToSubmit = {
      ...formData,
      equipment: equipmentAsString
        .split(",")
        .map((item) => item.trim())
        .filter((item) => item),
    };

    try {
      await validationSchema.validate(formDataToSubmit, { abortEarly: false });
      setFormErrors({});

      if (formDataToSubmit._id) {
        dispatch(updateSport(formDataToSubmit._id, formDataToSubmit));
      } else {
        dispatch(createSport(formDataToSubmit));
      }

      handleClose();
      resetForm();
    } catch (error) {
      const errors = error.inner.reduce((acc, err) => {
        acc[err.path] = err.message;
        return acc;
      }, {});
      setFormErrors(errors);
    }
  };

  const handleEdit = (sportData) => {
    setFormData({
      ...sportData,
      equipment: sportData.equipment.join(", "), // Convert array to string
    });
    setOpen(true);
  };

  return (
    <Box
      m={isLargeScreen ? "1.5rem 2rem 2rem 3rem" : "1.5rem 0.5rem 2rem 0.5rem"}
    >
      <FlexBetween>
        <Header title="THE SPORTS" subtitle="Directory" />
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpen}
          sx={{
            borderRadius: "10px",
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
        >
          <PlaylistAdd sx={{ mr: "0.5rem" }} />
          Add Sport
        </Button>
      </FlexBetween>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add a New Sport</DialogTitle>
        <DialogContent>
          <SportForm
            formData={formData}
            handleChange={handleChange}
            formErrors={formErrors}
            setFormData={setFormData}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Save</Button>
        </DialogActions>
      </Dialog>
      <SportGrid handleEdit={handleEdit} />
    </Box>
  );
}

export default Sports;
