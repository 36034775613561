import { setRoles, setRole } from "redux/state"; // Assuming you have these actions in your redux state
import { fetchRolesWithCount } from "./userRolesActions";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const createRole = (roleData) => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/roles`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(roleData),
    });
    const data = await response.json();
    dispatch(fetchRolesWithCount());
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchRoles = () => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/roles/roles`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    dispatch(setRoles(data));
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchRole = (roleId) => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/roles/${roleId}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    dispatch(setRole(data));
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const updateRole =
  (roleId, updatedRoleData) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      const response = await fetch(`${BASE_URL}/roles/${roleId}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedRoleData),
      });
      const data = await response.json();
      dispatch(fetchRolesWithCount());
      return data;
    } catch (error) {
      console.error(error);
    }
  };

export const deleteRole = (roleId) => async (dispatch, getState) => {
  try {
    const token = getState().token;
    await fetch(`${BASE_URL}/roles/${roleId}`, {
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch(fetchRolesWithCount());
  } catch (error) {
    console.error(error);
  }
};
