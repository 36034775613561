import { setSponsors, setCurrentSponsor } from "redux/state";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const createSponsor = (newSponsorData) => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/sponsors`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newSponsorData),
    });
    const newSponsor = await response.json();
    dispatch(setCurrentSponsor(newSponsor));
    dispatch(fetchSponsors());
  } catch (error) {
    console.log("Error creating sponsor:", error);
  }
};

export const fetchSponsors = () => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/sponsors`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    dispatch(setSponsors(data));
  } catch (error) {
    console.log("Error fetching sponsors:", error);
  }
};

export const fetchSponsor = (sponsorId) => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/sponsors/${sponsorId}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    dispatch(setCurrentSponsor(data));
    return data;
  } catch (error) {
    console.log("Error fetching sponsor:", error);
  }
};

export const updateSponsor =
  (sponsorId, updatedSponsorData) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      const response = await fetch(`${BASE_URL}/sponsors/${sponsorId}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedSponsorData),
      });
      const updatedSponsor = await response.json();
      dispatch(setCurrentSponsor(updatedSponsor));
    } catch (error) {
      console.log("Error updating sponsor:", error);
    }
  };

export const fetchRandomSponsor = () => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/sponsors/random`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const randomSponsor = await response.json();
    dispatch(setCurrentSponsor(randomSponsor));
  } catch (error) {
    console.log("Error fetching random sponsor:", error);
  }
};

export const pauseSponsor = (sponsorId) => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/sponsors/${sponsorId}/pause`, {
      method: "PATCH",
      headers: { Authorization: `Bearer ${token}` },
    });
    const pausedSponsor = await response.json();
    dispatch(setCurrentSponsor(pausedSponsor));
  } catch (error) {
    console.log("Error pausing sponsor:", error);
  }
};

export const resumeSponsor = (sponsorId) => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/sponsors/${sponsorId}/resume`, {
      method: "PATCH",
      headers: { Authorization: `Bearer ${token}` },
    });
    const resumedSponsor = await response.json();
    dispatch(setCurrentSponsor(resumedSponsor));
  } catch (error) {
    console.log("Error resuming sponsor:", error);
  }
};

export const deleteSponsor = (sponsorId) => async (dispatch, getState) => {
  try {
    const token = getState().token;

    await fetch(`${BASE_URL}/sponsors/${sponsorId}`, {
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    });

    dispatch(fetchSponsors());

    // return status;
    return { status: 200 };
  } catch (error) {
    console.log("Error deleting sponsor:", error);
  }
};

export const incrementClicks = (sponsorId) => async (dispatch, getState) => {
  try {
    const token = getState().token;
    // const response =
    await fetch(`${BASE_URL}/sponsors/${sponsorId}/incrementClicks`, {
      method: "PATCH",
      headers: { Authorization: `Bearer ${token}` },
    });
    // const data = await response.json();
    // dispatch(setCurrentSponsor(data));
  } catch (error) {
    console.log("Error incrementing clicks:", error);
  }
};

export const incrementImpressions =
  (sponsorId) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      //   const response =
      await fetch(`${BASE_URL}/sponsors/${sponsorId}/incrementImpressions`, {
        method: "PATCH",
        headers: { Authorization: `Bearer ${token}` },
      });
      //   const data = await response.json();
      //   dispatch(setCurrentSponsor(data));
    } catch (error) {
      console.log("Error incrementing impressions:", error);
    }
  };
