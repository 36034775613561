import React from "react";
import { Box, Typography } from "@mui/material";
import RecommendFriend from "components/RecommendFriend";
import SnackbarComponent from "components/SnackbarComponent";
import WidgetWrapper from "components/WidgetWrapper";
import Navbar from "scenes/navbar";
import FriendListWidget from "scenes/widgets/FriendListWidget";
import UserWidget from "scenes/widgets/UserWidget";

import DialogUpdateYourProfile from "./components/updateYourProfile";
import useFilterMembers from "./hooks/useFilterMembers";
import LocationSelector from "./components/LocationSelector";

import SportsSelector from "./components/SportsSelector";
import AgeRangeSelector from "./components/AgeRangeSelector";
import FilterMembersButton from "./components/FilterMembersButton";

import FilteredUserCards from "./components/FilteredUserCards";

import FilterAndResultsTitle from "./components/FilterAndResultsTitle";

function FilterMembers({ socket }) {
  const {
    isLargeScreen,
    primary,
    primaryDark,
    neutralLight,
    snackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    dialogOpen,
    filterValues,
    handleLocationSelect,
    handleLocationRadiusChange,
    handleSportToggle,
    handleSkillLevelChange,
    handleViewMore,
    isAnySportSelected,
    viewMoreStyle,
    underlineStyle,
    selectedMarks,
    handleSliderChange,
    handleSubmit,
    handleEditProfile,
    handleGoBack,
    handleCloseDialog,
    sliderValueToAgeRange,
    displayedSportsCount,
    selectedSports,
    ageRangeSliderValue,
    skillLevelSelection,
    userSportNames,
    skillLevels,
    userId,
    user,
    searchCriteria,
    setSnackbarOpen,
    totalPages,
    currentPage,
    handleNextPage,
    handlePreviousPage,
    totalUsers,
    users,
    background,
    handleHideResults,
    showResults,
  } = useFilterMembers();

  return (
    <Box>
      <Box height="100vh" display="flex" flexDirection="column">
        <Navbar socket={socket} />

        <Box
          flexGrow={1}
          overflow="auto"
          width="100%"
          padding={isLargeScreen ? "0 6%" : "0"}
          display={isLargeScreen ? "flex" : "block"}
          gap="2rem"
          justifyContent="center"
        >
          {isLargeScreen && (
            <Box flexBasis={isLargeScreen ? "35%" : undefined}>
              <UserWidget userId={userId} picturePath={user.picturePath} />
              <RecommendFriend />
              <FriendListWidget userId={userId} socket={socket} />
            </Box>
          )}
          <Box flexBasis={isLargeScreen ? "60%" : undefined}>
            <WidgetWrapper>
              <FilterAndResultsTitle
                showResults={showResults}
                handleHideResults={handleHideResults}
                primary={primary}
              />

              <Box
                // if showResults is true, display none, else display block
                display={showResults ? "none" : "block"}
              >
                <LocationSelector
                  searchCriteria={searchCriteria}
                  filterValues={filterValues}
                  handleLocationSelect={handleLocationSelect}
                  handleLocationRadiusChange={handleLocationRadiusChange}
                />

                <SportsSelector
                  handleSportToggle={handleSportToggle}
                  handleSkillLevelChange={handleSkillLevelChange}
                  userSportNames={userSportNames}
                  skillLevels={skillLevels}
                  selectedSports={selectedSports}
                  skillLevelSelection={skillLevelSelection}
                  displayedSportsCount={displayedSportsCount}
                  viewMoreStyle={viewMoreStyle}
                  underlineStyle={underlineStyle}
                  isAnySportSelected={isAnySportSelected}
                  handleViewMore={handleViewMore}
                />

                <AgeRangeSelector
                  ageRangeSliderValue={ageRangeSliderValue}
                  handleSliderChange={handleSliderChange}
                  selectedMarks={selectedMarks}
                  sliderValueToAgeRange={sliderValueToAgeRange}
                />

                <FilterMembersButton
                  primary={primary}
                  primaryDark={primaryDark}
                  neutralLight={neutralLight}
                  userCount={totalUsers}
                  handleSubmit={handleSubmit}
                />
              </Box>
              <Box
                display={showResults ? "block" : "none"}
                sx={{ position: "relative" }}
              >
                <FilteredUserCards
                  users={users}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handleNextPage={handleNextPage}
                  handlePreviousPage={handlePreviousPage}
                  background={background}
                  primary={primary}
                  neutralLight={neutralLight}
                  primaryDark={primaryDark}
                />
              </Box>
            </WidgetWrapper>
          </Box>
        </Box>
      </Box>
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        severity={snackbarSeverity}
      />
      <DialogUpdateYourProfile
        dialogOpen={dialogOpen}
        handleGoBack={handleGoBack}
        handleCloseDialog={handleCloseDialog}
        handleEditProfile={handleEditProfile}
        primary={primary}
      />
    </Box>
  );
}

export default FilterMembers;
