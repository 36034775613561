import React, { useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import Navbar from "scenes/navbar";
import { useSelector } from "react-redux";
import GroupCreateWidget from "scenes/groups/GroupCreate/components/GroupCreateWidget";
import CreatePreview from "./components/GroupCreatePreview";
import { GroupCreateContext } from "scenes/groups/GroupCreate/components/GroupCreateContext";
import Footer from "components/Footer"; // Import Footer if it is not imported already
import BottomMenu from "components/bottomMenu/BottomMenu";

function GroupCreate({ socket }) {
  const isLargeScreen = useMediaQuery("(min-width:1000px)");
  const _id = useSelector((state) => state.user); // get the user from the state
  const [groupName, setGroupName] = useState("");
  const [privacy, setPrivacy] = useState("public");
  const [visibility, setVisibility] = useState("visible");
  const [selectedFriends, setSelectedFriends] = useState([]);
  const [availableFriends, setAvailableFriends] = useState([]);
  const [selectedSport, setSelectedSport] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [experienceLevel, setExperienceLevel] = useState([]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        minHeight="100vh" // changed from height="100vh"
      >
        <Navbar socket={socket} />
        <Box
          flexGrow={1}
          overflow="auto"
          width="100%"
          padding={isLargeScreen ? "0 6%" : "0"}
          display={isLargeScreen ? "flex" : "block"}
          gap="2rem"
          justifyContent="center"
        >
          <GroupCreateContext.Provider
            value={{
              groupName,
              setGroupName,
              privacy,
              setPrivacy,
              visibility,
              setVisibility,
              selectedFriends,
              setSelectedFriends,
              availableFriends,
              setAvailableFriends,
              selectedSport,
              setSelectedSport,
              selectedLocation,
              setSelectedLocation,
              experienceLevel,
              setExperienceLevel,
            }}
          >
            <Box flexBasis={isLargeScreen ? "35%" : undefined}>
              <GroupCreateWidget userId={_id} socket={socket} />
            </Box>
            <Box
              flexBasis={isLargeScreen ? "60%" : undefined}
              mt={isLargeScreen ? undefined : "2rem"}
            >
              <CreatePreview />
            </Box>
          </GroupCreateContext.Provider>
        </Box>
        <Footer />
      </Box>

      {!isLargeScreen && <BottomMenu />}
    </>
  );
}

export default GroupCreate;
