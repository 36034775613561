import { TuneOutlined } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import React from "react";
import { useNavigate } from "react-router-dom";
import WidgetWrapper from "components/WidgetWrapper";

function FindMembersTitleAndBtn() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/findmembers/filter");
  };

  return (
    <WidgetWrapper>
      <FlexBetween>
        <Typography variant="h4" fontWeight={700}>
          Find Members
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{
            borderRadius: "1rem",
          }}
          onClick={handleClick}
        >
          <TuneOutlined
            sx={{
              marginRight: "0.5rem",
            }}
          />
          Filter
        </Button>
      </FlexBetween>
    </WidgetWrapper>
  );
}

export default FindMembersTitleAndBtn;
