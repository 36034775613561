import {
  ManageAccountsOutlined,
  PersonRemoveOutlined,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import SnackbarComponent from "components/SnackbarComponent";
import AvatarRoleIcons from "components/avatarRoleIcons";
import React from "react";
import { Link } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

function UserWidgetMobileName({
  isLargeScreen,
  dark,
  primaryMain,
  firstName,
  lastName,
  currentUserId,
  userId,
  milesBetweenUsers,
  kilometersBetweenUsers,
  medium,
  primaryLight,
  primaryDark,
  neutralLight,
  neutralMedium,
  user,
  isFriend,
  setSnackbarOpen,
  snackbarOpen,
  snackbarMessage,
  snackbarSeverity,
  handleReportUser,
  handlePatchFriend,
  handleClick,
  handleClose,
  handleSoftBlockUser,
  handleBlockUser,
  anchorEl,
}) {
  return !isLargeScreen ? (
    <>
      <FlexBetween gap="0.5rem" pb="1.1rem">
        <div>
          {" "}
          <Box display="flex" flexDirection="row" alignItems={"center"} gap={1}>
            <Typography variant="h3" color={dark} fontWeight="700">
              {firstName}
              <span style={{ color: primaryMain }}> {lastName}</span>
            </Typography>
            <AvatarRoleIcons user={user} avatarSize="30px" />
          </Box>
          {currentUserId !== userId ? (
            <Typography color={medium}>
              {milesBetweenUsers} miles / {kilometersBetweenUsers} km away
            </Typography>
          ) : undefined}
        </div>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={1}
        >
          {currentUserId === userId ? (
            <Link to={`/settings/profile`}>
              <ManageAccountsOutlined />
            </Link>
          ) : (
            <IconButton
              onClick={handlePatchFriend}
              sx={{
                backgroundColor: isFriend ? neutralLight : primaryLight,
                p: isFriend ? "0.6rem" : 0,
              }}
            >
              {isFriend ? (
                <PersonRemoveOutlined
                  data-testid="remove-friend-icon"
                  sx={{ color: primaryDark }}
                />
              ) : (
                <div
                  style={{
                    color: "white",
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background:
                      "linear-gradient(180deg, #9ceced 0%, #008e94 50.52%, #c85ed9 100%)",
                  }}
                >
                  <img
                    src="../assets/add-user.png"
                    alt="Add user"
                    style={{
                      width: "30px",
                      filter: "brightness(0) invert(1)",
                    }}
                    data-testid="add-friend-icon"
                  />
                </div>
              )}
            </IconButton>
          )}
          {currentUserId !== userId && (
            <div>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreHorizIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => handleReportUser(userId)}>
                  <Box>
                    <Typography variant="h6">Report User</Typography>
                    <Typography variant="subtitle2" color={neutralMedium}>
                      Report this user anonomously
                    </Typography>
                  </Box>
                </MenuItem>

                <Divider variant="middle" />
                <MenuItem onClick={() => handleSoftBlockUser(userId)}>
                  <Box>
                    <Typography variant="h6">Not For Me</Typography>
                    <Typography variant="subtitle2" color={neutralMedium}>
                      Don't show me this user
                    </Typography>
                  </Box>
                </MenuItem>

                <MenuItem onClick={() => handleBlockUser(userId)}>
                  <Box>
                    <Typography variant="h6">Block User</Typography>
                    <Typography variant="subtitle2" color={neutralMedium}>
                      Block this user
                    </Typography>
                  </Box>
                </MenuItem>

                <Divider variant="middle" />

                <MenuItem onClick={handleClose}>Close</MenuItem>
              </Menu>
            </div>
          )}
        </Box>
      </FlexBetween>
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        severity={snackbarSeverity}
      />
    </>
  ) : undefined;
}

export default UserWidgetMobileName;
