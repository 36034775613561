import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import React from "react";
import Header from "../components/Header";
import { PlaylistAdd } from "@mui/icons-material";
import SponsorForm from "./SponsorCreateForm";

import SponsorGrid from "./components/SponsorGrid";
import useSponsorCreateFormState from "./hooks/useSponsorCreateFormState";

function Sponsors() {
  const {
    isLargeScreen,
    handleEdit,
    handleFileChange,
    handleEditClick,
    handleSubmit,
    handleDateChange,
    handleClose,
    handleOpen,
    formData,
    setFormData,
    image,
    fileInputRef,
    defaultImageURL,
    formErrors,
    open,
    handleChange,
    modules,
    formats,
  } = useSponsorCreateFormState();

  return (
    <Box
      m={isLargeScreen ? "1.5rem 2rem 2rem 3rem" : "1.5rem 0.5rem 2rem 0.5rem"}
    >
      <FlexBetween>
        <Header title="DIRECTORY" subtitle="All Sponsors" />
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpen}
          sx={{
            borderRadius: "10px",
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
        >
          <PlaylistAdd sx={{ mr: "0.5rem" }} />
          Add Sponsor
        </Button>
      </FlexBetween>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add a New Sponsor</DialogTitle>
        <DialogContent>
          <SponsorForm
            formData={formData}
            image={image}
            handleEditClick={handleEditClick}
            handleFileChange={handleFileChange}
            formErrors={formErrors}
            setFormData={setFormData}
            defaultImageURL={defaultImageURL}
            fileInputRef={fileInputRef}
            handleDateChange={handleDateChange}
            handleChange={handleChange}
            modules={modules}
            formats={formats}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <SponsorGrid handleEdit={handleEdit} />
    </Box>
  );
}

export default Sponsors;
