import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PostWidget from "./PostWidget";
import {
  fetchPosts,
  fetchUserPosts,
  fetchGroupPosts,
  fetchUserGroupsPosts,
} from "redux/actions/postActions.js";

const PostsWidget = ({ userId, isProfile = false, groupId, socket }) => {
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.posts);
  const groupPosts = useSelector((state) => state.groupPosts);
  const userGroupPosts = useSelector((state) => state.userGroupPosts);
  const location = useLocation();

  const currentUserId = useSelector((state) => state.user._id);

  useEffect(() => {
    if (location.pathname === "/groups/feed") {
      dispatch(fetchUserGroupsPosts(currentUserId));
    } else if (groupId) {
      dispatch(fetchGroupPosts(groupId));
    } else if (isProfile) {
      dispatch(fetchUserPosts(userId));
    } else {
      dispatch(fetchPosts(userId));
    }
  }, [dispatch, isProfile, userId, groupId, location, currentUserId]);

  let activePosts;

  if (location.pathname === "/groups/feed") {
    activePosts = userGroupPosts;
  } else {
    activePosts = groupId ? groupPosts : posts;
  }

  const refreshPosts = () => {
    if (location.pathname === "/groups/feed") {
      dispatch(fetchUserGroupsPosts(currentUserId));
    } else if (groupId) {
      dispatch(fetchGroupPosts(groupId));
    } else if (isProfile) {
      dispatch(fetchUserPosts(userId));
    } else {
      dispatch(fetchPosts(userId));
    }
  };

  return (
    <>
      {activePosts && activePosts.length > 0 ? (
        activePosts
          .filter((post) => !post.isDeleted) // filter out deleted posts
          .map(
            ({
              _id,
              userId,
              description,
              picturePath,
              likes,
              comments,
              createdAt,
              lastEdited,
              groupId,
              taggedUsers,
              isDeleted,
              user,
            }) => {
              if (!user) return null; // Ensure user data is available

              return (
                <PostWidget
                  key={_id}
                  postId={_id}
                  postUserId={userId}
                  name={`${user?.firstName} ${user?.lastName}`}
                  description={description}
                  picturePath={picturePath}
                  userPicturePath={user?.picturePath}
                  likes={likes}
                  comments={comments}
                  createdAt={createdAt}
                  lastEdited={lastEdited}
                  socket={socket}
                  taggedUsers={taggedUsers}
                  group={groupId}
                  userId={userId}
                  refreshPosts={refreshPosts}
                  isDeleted={isDeleted}
                  user={user}
                />
              );
            }
          )
      ) : (
        <p>No posts to display</p>
      )}
    </>
  );
};

export default PostsWidget;
