import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

function ProtectedElement({ isAdminRequired, children }) {
  const roles = useSelector((state) => state.user.roles);

  const isAdmin = roles?.some((role) => role.name.toLowerCase() === "admin");

  if (isAdminRequired && !isAdmin) {
    return <Navigate to="/home" replace />;
  }

  return children;
}

export default ProtectedElement;
