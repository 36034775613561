import { TextField, MenuItem, FormHelperText, Box } from "@mui/material";

const FormAgeRange = (props) => {
  const { handleChange, handleBlur, errors, touched, values } = props;

  return (
    <Box sx={{ gridColumn: "span 2" }}>
      <TextField
        select
        label="Age Range"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.ageRange}
        name="ageRange"
        error={Boolean(touched.ageRange) && Boolean(errors.ageRange)}
        helpertext={touched.ageRange && errors.ageRange}
        fullWidth
      >
        {["18-24", "25-34", "35-44", "45-54", "55-64", "65-74", "75+"].map(
          (option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          )
        )}
      </TextField>
      {Boolean(touched.ageRange) && Boolean(errors.ageRange) && (
        <FormHelperText
          error={true}
          style={{
            margin: "4px 1rem",
          }}
        >
          {errors.ageRange}
        </FormHelperText>
      )}
    </Box>
  );
};

export default FormAgeRange;
