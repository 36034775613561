import { useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { handleImageUpload } from "redux/actions/imageUploadUtils";
import { createSponsor, updateSponsor } from "redux/actions/sponsorActions";
import * as yup from "yup";

function useSponsorCreateFormState() {
  const isLargeScreen = useMediaQuery("(min-width:1000px)");
  const [image, setImage] = useState(null);
  const [open, setOpen] = useState(false);

  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState({});

  const defaultImageURL =
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/6d0f5d8b-96b6-48d8-7bf8-ba57dbcea500/512x512";

  const [formData, setFormData] = useState({
    name: "",
    website: "",
    description: "",
    imagePath: "",
    scheduledStart: dayjs(), // today's date
    scheduledEnd: dayjs().add(7, "day"), // 7 days from today
  });

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required("Sponsor name is required.")
      .min(2, "Sponsor name should be at least 2 characters.")
      .max(100, "Sponsor name should be at most 100 characters."),
    website: yup.string().required("Website is required.").url(),
    description: yup
      .string()
      .required("Description is required.")
      .min(10, "Description should be at least 10 characters."),
    imagePath: yup.string().required("Image path is required."),
    scheduledStart: yup.date().required("Start date is required."),
    // before start of today
    //.max(dayjs().startOf("day"), "Start date can't be in the past."),
    scheduledEnd: yup
      .date()
      .required("End date is required.")
      .min(yup.ref("scheduledStart"), "End date can't be before start date."),
  });

  const handleDateChange = (name, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: dayjs(newValue), // Ensure newValue is formatted correctly
    }));
  };

  const handleEdit = (sponsorData) => {
    setFormData(sponsorData); // Assuming setFormData updates the form's state
    setImage(sponsorData.imagePath); // Set the image preview
    setOpen(true); // Open the dialog
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOpen = useCallback(() => {
    // Reset the form data to default values
    setFormData({
      name: "",
      website: "",
      description: "",
      imagePath: "",
      scheduledStart: dayjs(), // Set to today's date
      scheduledEnd: dayjs().add(7, "day"), // Set to 7 days from today
    });
    // Reset the image state if needed
    setImage(null); // Adjust according to your default state or leave it if you have a default image
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSubmit = async () => {
    try {
      // Validate the form data
      await validationSchema.validate(formData, { abortEarly: false });
      setFormErrors({}); // Reset errors if validation is successful

      // Dispatch the createSponsor/updateSponsor action with formData
      if (formData._id) {
        // If there's an ID, update the sponsor
        await dispatch(updateSponsor(formData._id, formData));
      } else {
        // If there's no ID, create a new sponsor
        await dispatch(createSponsor(formData));
      }
      handleClose(); // Close the form dialog
      resetForm(); // Reset the form
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const errors = error.inner.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.path]: curr.message,
          }),
          {}
        );
        setFormErrors(errors);
      } else {
        console.error("Submission error:", error);
      }
    }
  };

  const resetForm = () => {
    setFormData({
      name: "",
      website: "",
      description: "",
      imagePath: "",
      scheduledStart: "",
      scheduledEnd: "",
    });
  };

  const handleEditClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const uploadedImageUrl = await handleImageUpload(file);
        if (uploadedImageUrl) {
          setFormData((prevData) => ({
            ...prevData,
            imagePath: uploadedImageUrl,
          }));
          setImage(uploadedImageUrl); // Ensure image state is updated correctly
        } else {
          console.error("Image upload failed. Using default image.");
          setFormData((prevData) => ({
            ...prevData,
            imagePath: defaultImageURL,
          }));
          setImage(defaultImageURL); // Set default image in case of failure
        }
      } catch (error) {
        console.error("Image upload failed:", error);
        setFormData((prevData) => ({
          ...prevData,
          imagePath: defaultImageURL,
        }));
        setImage(defaultImageURL); // Set default image in case of failure
      }
    }
  };

  const modules = {
    toolbar: [
      // [{ size: ["small", false, "large", "huge"] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline"],
      [{ list: "bullet" }],
      [{ color: [] }],
      [{ align: [] }],
      ["clean"],
      ["link"], // add 'video' for video embedding
    ],
  };

  const formats = [
    // "size",
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "align",
  ];
  return {
    isLargeScreen,
    handleEdit,
    handleFileChange,
    handleEditClick,
    handleSubmit,
    handleDateChange,
    handleClose,
    handleOpen,
    formData,
    setFormData,
    image,
    fileInputRef,
    defaultImageURL,
    formErrors,
    open,
    handleChange,
    modules,
    formats,
  };
}

export default useSponsorCreateFormState;
