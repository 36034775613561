import {
  ManageAccountsOutlined,
  PersonRemoveOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import SnackbarComponent from "components/SnackbarComponent";
import AvatarRoleIcons from "components/avatarRoleIcons";
import React from "react";
import { Link } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

function UserWidgetDesktopAvatarNameFriends({
  isLargeScreen,
  picturePath,
  userId,
  firstName,
  lastName,
  friends,
  currentUserId,
  dark,
  primaryLight,
  neutralMedium,
  navigate,
  medium,
  milesBetweenUsers,
  kilometersBetweenUsers,
  primaryDark,
  neutralLight,
  user,
  setSnackbarOpen,
  snackbarOpen,
  snackbarMessage,
  snackbarSeverity,
  isFriend,
  handleReportUser,
  handleSoftBlockUser,
  handleBlockUser,
  handleClick,
  handleClose,
  anchorEl,
  handlePatchFriend,
  isNonUser,
}) {
  return isLargeScreen ? (
    <>
      <FlexBetween gap="0.5rem" pb="1.1rem">
        <FlexBetween gap="1rem">
          <Avatar
            src={picturePath}
            sx={{ width: "60px", height: "60px" }}
            onClick={() => navigate(`/profile/${userId}`)}
            style={{ cursor: "pointer" }}
          />

          <Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems={"center"}
              gap={1}
            >
              <Typography
                variant="h5"
                color={dark}
                fontWeight="500"
                sx={{
                  "&:hover": {
                    color: primaryLight,
                    cursor: "pointer",
                  },
                }}
                onClick={() => navigate(`/profile/${userId}`)}
              >
                {firstName} {lastName}
              </Typography>
              <AvatarRoleIcons user={user} avatarSize="20px" />
            </Box>
            <Typography color={medium}>
              {friends.length} friend
              {friends.length === 1 ? undefined : "s"}
            </Typography>
            {currentUserId !== userId ? (
              <Typography color={medium}>
                {milesBetweenUsers} miles / {kilometersBetweenUsers} km away
              </Typography>
            ) : undefined}
          </Box>
        </FlexBetween>
        {isNonUser && (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={0.5}
          >
            {currentUserId === userId ? (
              <Link to={`/settings/profile`}>
                <ManageAccountsOutlined />
              </Link>
            ) : (
              <IconButton
                onClick={handlePatchFriend}
                sx={{
                  backgroundColor: isFriend ? neutralLight : primaryLight,
                  p: isFriend ? "0.6rem" : 0,
                }}
              >
                {isFriend ? (
                  <PersonRemoveOutlined
                    data-testid="remove-friend-icon"
                    sx={{ color: primaryDark }}
                  />
                ) : (
                  <div
                    style={{
                      color: "white",
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background:
                        "linear-gradient(180deg, #9ceced 0%, #008e94 50.52%, #c85ed9 100%)",
                    }}
                  >
                    <img
                      src="../assets/add-user.png"
                      alt="Add user"
                      style={{
                        width: "30px",
                        filter: "brightness(0) invert(1)",
                      }}
                      data-testid="add-friend-icon"
                    />
                  </div>
                )}
              </IconButton>
            )}
            {currentUserId !== userId && (
              <div>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreHorizIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => handleReportUser(userId)}>
                    <Box>
                      <Typography variant="h6">Report User</Typography>
                      <Typography variant="subtitle2" color={neutralMedium}>
                        Report this user anonomously
                      </Typography>
                    </Box>
                  </MenuItem>

                  <Divider variant="middle" />

                  <MenuItem onClick={() => handleSoftBlockUser(userId)}>
                    <Box>
                      <Typography variant="h6">Not For Me</Typography>
                      <Typography variant="subtitle2" color={neutralMedium}>
                        Don't show me this user
                      </Typography>
                    </Box>
                  </MenuItem>

                  <MenuItem onClick={() => handleBlockUser(userId)}>
                    <Box>
                      <Typography variant="h6">Block User</Typography>
                      <Typography variant="subtitle2" color={neutralMedium}>
                        Block this user
                      </Typography>
                    </Box>
                  </MenuItem>

                  <Divider variant="middle" />

                  <MenuItem onClick={handleClose}>Close</MenuItem>
                </Menu>
              </div>
            )}
          </Box>
        )}
      </FlexBetween>
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        severity={snackbarSeverity}
      />
    </>
  ) : undefined;
}

export default UserWidgetDesktopAvatarNameFriends;
