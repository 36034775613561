import WidgetWrapper from "components/WidgetWrapper";
import React, { useState, useEffect } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
  Button,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { ChevronRightOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";

const navItems = [
  { text: "Your feed", header: false, path: "feed" },
  { text: "Discover", header: false, path: "discover" },
  { text: "Your groups", header: false, path: "mygroups" },
];

function GroupListWidget({ socket }) {
  const { pathname } = useLocation();
  const { palette } = useTheme();
  const dark = palette.primary.dark;
  const main = palette.primary.main;
  const light = palette.primary.light;
  const secondary = palette.secondary.main;
  const user = useSelector((state) => state.user) || []; // Get the user from the state
  const userGroups = useSelector((state) => state.user.groups) || []; // Get the user from the state

  const adminGroups = userGroups.filter((group) =>
    group.administrators.includes(user._id)
  );

  const memberGroups = userGroups.filter(
    (group) => !group.administrators.includes(user._id)
  );

  // const adminGroups = [];
  // const memberGroups = [];

  const navigate = useNavigate();
  const [active, setActive] = useState("");

  useEffect(() => {
    const pathSegments = pathname.split("/");
    const lastSegment = pathSegments[pathSegments.length - 1];
    setActive(lastSegment);
  }, [pathname]);

  const Divider = () => (
    <Box
      width="100%"
      height="1px"
      backgroundColor={light}
      opacity="0.1"
      margin="1rem 0"
    />
  );

  return (
    <WidgetWrapper component="nav">
      <Box display="flex" alignItems="center" gap="0.5rem" color={main}>
        <Typography variant="h4" fontWeight="bold">
          Groups
        </Typography>
      </Box>
      {/* <FlexBetween
        backgroundColor={neutralLight}
        borderRadius="9px"
        gap="3rem"
        padding="0.1rem 1.5rem"
        margin="1rem 0"
      >
        <InputBase placeholder="Search..." />
        <Search />
      </FlexBetween> */}
      {/* Divider */}
      <Divider />
      <List>
        {navItems.map(({ text, header, path }) => {
          if (header) {
            return (
              <Typography
                key={text}
                variant="h5"
                sx={{ m: "1rem 0 1rem 1rem", color: main }}
              >
                {text}
              </Typography>
            );
          }

          const lcPath = path.toLowerCase();
          return (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/groups/${path}`);
                  setActive(lcPath);
                }}
                sx={{
                  backgroundColor: active === lcPath ? main : "transparent",
                  color: active === lcPath ? light : dark,
                  borderRadius: "9px",
                  "&:hover": {
                    color: main,
                    "& .MuiSvgIcon-root": {
                      color: main,
                    },
                  },
                }}
              >
                <ListItemText primary={text} />
                {active === lcPath && <ChevronRightOutlined />}
              </ListItemButton>
            </ListItem>
          );
        })}
        <ListItem disablePadding>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/groups/create")}
            sx={{
              width: "100%",
              borderRadius: "9px",
              backgroundColor: secondary,
              marginTop: "1rem",
            }}
          >
            + Create New Group
          </Button>
        </ListItem>
      </List>
      {/* Divider */}
      <Divider />
      <Box>
        <Typography variant="h5" sx={{ color: main }}>
          Groups you manage
        </Typography>
        <List>
          {adminGroups.map((group, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/groups/${group._id}`);
                }}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Box
                  width="20%"
                  position="relative"
                  style={{
                    height: "50px",
                    overflow: "hidden",
                    borderRadius: "0.5rem",
                    marginRight: "10px",
                  }}
                >
                  <img
                    src={group.image}
                    alt="Group Shot"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
                <Box sx={{ width: "80%" }}>
                  <ListItemText
                    primary={group.name}
                    sx={{ color: main, fontWeight: 700 }}
                  />
                  <ListItemText
                    secondary={`${group.sport}, ${group.location.address}`}
                  />
                </Box>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
      {/* Divider */}
      <Divider />
      <Box>
        <Typography variant="h5" sx={{ color: main }}>
          Groups you're in
        </Typography>
        <List>
          {memberGroups.map((group, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/groups/${group._id}`);
                }}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Box
                  width="20%"
                  position="relative"
                  style={{
                    height: "50px",
                    overflow: "hidden",
                    borderRadius: "0.5rem",
                    marginRight: "10px",
                  }}
                >
                  <img
                    src={group.image}
                    alt="Group Shot"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
                <Box sx={{ width: "80%" }}>
                  <ListItemText
                    primary={group.name}
                    sx={{ color: main, fontWeight: 700 }}
                  />
                  <ListItemText
                    secondary={`${group.sport}, ${group.location.address}`}
                  />
                </Box>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </WidgetWrapper>
  );
}

export default GroupListWidget;
