import { Avatar, Box, Typography, useTheme } from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import ExperienceType from "components/ExperienceType";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNonFriends, patchFriend } from "redux/state";
import Flickity from "react-flickity-component";
import { useNavigate } from "react-router-dom";
import "../../../styles/nonFriendsSliderFlick.css";
import { useMediaQuery } from "@mui/material";
import SnackbarComponent from "components/SnackbarComponent";
import BrowseMembersBtn from "scenes/widgets/NonFriendsSliderFlick/components/BrowseMembersBtn";
import AvatarRoleIcons from "components/avatarRoleIcons";

const NonFriendSliderFlick = ({ nonFriends, type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { palette } = useTheme();
  const { _id } = useSelector((state) => state.user);

  const [carouselTitle, setCarouselTitle] = useState("");
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  const isLargeScreen = useMediaQuery("(min-width: 1000px)");

  const carouselStyle = {
    visibility: imagesLoaded ? "visible" : "hidden",
  };

  const imgStyles = {
    objectFit: "cover",
    height: isLargeScreen ? "300px" : "300px",
    width: isLargeScreen ? "300px" : "300px",
    borderRadius: "16px",
    margin: isLargeScreen ? "10px" : 0,
    cursor: "pointer",
    padding: "5px",
  };

  const handleAddFriend = async (nonFriendId) => {
    if (_id && nonFriendId) {
      const response = await dispatch(patchFriend(_id, nonFriendId)); // dispatch the patchFriend action

      if (response) {
        // Remove the added friend from the non-friends list
        const updatedNonFriends = nonFriends.filter(
          (nonFriend) => nonFriend._id !== nonFriendId
        );
        await dispatch(setNonFriends(updatedNonFriends)); // update the nonFriends state with the new filtered array
      }
      setSnackbarMessage("Friend added");
      setSnackbarSeverity("success");

      // Open the snackbar
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    let loadedImagesCount = 0;

    if (!nonFriends) return;
    const totalImages = nonFriends.length;

    nonFriends.forEach((nonFriend) => {
      const img = new Image();
      img.src = nonFriend.picturePath.endsWith("/public")
        ? nonFriend.picturePath.replace("/public", "/768x576")
        : `${nonFriend.PicturePath}/768x576`;

      img.onload = () => {
        loadedImagesCount++;
        if (loadedImagesCount === totalImages) {
          setImagesLoaded(true);
        }
      };
    });
  }, [nonFriends]);

  useEffect(() => {
    let title = "";
    if (type === "nearest") {
      title = "Browse members near you!";
    } else if (type === "recent") {
      title = "Connect with other members who recently joined!";
    } else if (type === "home") {
      title = "Connect with other members near you!";
    }
    setCarouselTitle(title);
  }, [type]);

  function Carousel() {
    const flickityOptions = {
      autoPlay: true, // Enable auto movement
      wrapAround: true, // Wrap around to the beginning after reaching the last slide
      initailIndex: 2, // Starting slide index
      pageDots: false, // Hide the dots that show the current slide
      arrowShape: {
        x0: 10,
        x1: 60,
        y1: 50,
        x2: 65,
        y2: 45,
        x3: 20,
      },
      imagesLoaded: true,
    };

    return nonFriends && nonFriends.length > 0 ? (
      <Flickity
        options={flickityOptions}
        style={carouselStyle}
        className={"carousel"}
        elementType={"div"} // default 'div'
        disableImagesLoaded={true} // default false
        reloadOnUpdate // default false
        static // default false
      >
        {nonFriends?.map((nonFriend) => (
          <div key={nonFriend._id} style={{ position: "relative" }}>
            <img
              src={
                nonFriend.picturePath.endsWith("/public")
                  ? nonFriend.picturePath.replace("/public", "/768x576")
                  : `${nonFriend.PicturePath}/768x576`
              }
              alt={nonFriend.firstName}
              style={imgStyles}
              onClick={() => {
                navigate(`/profile/${nonFriend._id}`);
              }}
            />

            <div
              style={{
                position: "absolute",
                bottom: isLargeScreen ? "21px" : "10px",
                left: isLargeScreen ? "15px" : "5px",
                color: "white",
                backgroundColor: "rgba(0,0,0,0.25)",

                padding: "10px",
                borderRadius: "0 10px 0 10px",
                maxWidth: "290px",
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                flexDirection={"row"}
                gap={1}
              >
                <Typography variant="h3" sx={{ fontWeight: 700 }}>
                  {nonFriend.firstName}
                </Typography>
                <AvatarRoleIcons user={nonFriend} />
              </Box>
              <Typography variant="h5">
                {nonFriend.location.town || nonFriend.location.country}
              </Typography>
              {nonFriend.sports &&
                nonFriend.sports.length > 0 &&
                nonFriend.sports[0].sport && (
                  <Typography variant="body1">
                    {nonFriend.sports[0].sport}{" "}
                    <ExperienceType
                      expertType={nonFriend.sports[0].experienceLevel}
                    />
                  </Typography>
                )}
            </div>
            <div className="carouselItemButton">
              <img
                onClick={() => handleAddFriend(nonFriend._id)}
                className="carouselItemButtonAddUser"
                src="../assets/add-user.png"
                alt="Add user"
              />
            </div>
          </div>
        ))}
      </Flickity>
    ) : null;
  }

  // Check if there are no nonFriends, and return null if that's the case
  if (!nonFriends || nonFriends.length === 0 || !imagesLoaded) {
    return null; // Or return a loader/spinner here
  }

  return (
    <WidgetWrapper sx={{ padding: "1.5rem 0" }}>
      <Typography
        color={palette.neutral.dark}
        variant={type === "home" ? "h5" : "h4"}
        fontWeight={type === "home" ? 500 : 700}
        sx={{ padding: "0 1.5rem" }}
      >
        {carouselTitle}
      </Typography>

      <Carousel />
      {type === "home" && <BrowseMembersBtn />}

      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        severity={snackbarSeverity}
      />
    </WidgetWrapper>
  );
};

export default memo(NonFriendSliderFlick);
