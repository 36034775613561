import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import Header from "../components/Header";
import RecommendedGrid from "./components/recommendedGrid";

function RecommnededUsers() {
  const isLargeScreen = useMediaQuery("(min-width:1000px)");
  return (
    <Box
      m={isLargeScreen ? "1.5rem 2rem 2rem 3rem" : "1.5rem 0.5rem 2rem 0.5rem"}
    >
      <Header
        title="Recommended Users"
        subtitle="Users that have recommended others to use the platform"
      />
      <RecommendedGrid />
    </Box>
  );
}

export default RecommnededUsers;
