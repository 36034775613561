import {
  setArticle,
  setArticles,
  setTagArticles,
  setFeedArticles,
  setBrandArticles,
  setAllBrandArticles,
  setCategories,
  setMostLikedArticle,
  setLatestFollowedBrandArticles,
} from "redux/state";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const createArticle =
  (
    userId,
    title,
    subtitle,
    titleImage,
    content,
    brandId,
    selectedCategory,
    selectedSport
  ) =>
  async (dispatch, getState) => {
    try {
      const token = getState().token;

      const response = await fetch(`${BASE_URL}/articles`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          title,
          subtitle,
          titleImage,
          content,
          brandId,
          category: selectedCategory,
          sport: selectedSport,
        }),
      });

      const responseData = await response.json(); // Get the response data

      return responseData.articleId; // Return the new article's id
    } catch (error) {
      console.error("Error while creating article:", error);
      return [false, null]; // Indicate failed article creation
    }
  };

export const updateArticle =
  (
    articleId,
    title,
    subtitle,
    titleImage,
    content,
    brandId,
    selectedCategory,
    selectedSport
  ) =>
  async (dispatch, getState) => {
    try {
      const token = getState().token;

      const response = await fetch(`${BASE_URL}/articles/${articleId}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title,
          subtitle,
          titleImage,
          content,
          brandId,
          category: selectedCategory,
          sport: selectedSport,
        }),
      });

      const responseData = await response.json(); // Get the response data
      dispatch(fetchBrandArticles(brandId));
      return responseData.articleId;
    } catch (error) {
      console.error("Error while updating article:", error);
      return [false, null]; // Indicate failed article creation
    }
  };

export const fetchArticles = (userId) => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/articles/user/${userId}/feed`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();
  dispatch(setFeedArticles(data));
};

export const fetchArticle = (articleId) => async (dispatch, getState) => {
  const token = getState().token;
  const endpoint = token
    ? `/articles/${articleId}`
    : `/articles/public/${articleId}`;
  const headers = token ? { Authorization: `Bearer ${token}` } : {};

  const response = await fetch(`${BASE_URL}${endpoint}`, {
    method: "GET",
    headers,
  });

  const data = await response.json();
  dispatch(setArticle(data));
  return data;
};

export const fetchBrandArticles = (brandId) => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/articles/brand/${brandId}`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();
  dispatch(setBrandArticles(data));
};

export const fetchAllBrandArticles =
  (brandId) => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/articles/allBrand/${brandId}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    dispatch(setAllBrandArticles(data));
  };

export const publishArticle = (articleId) => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/articles/${articleId}/publish`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const updatedArticle = await response.json();
    dispatch(setArticle(updatedArticle));
  } catch (error) {
    console.error("Error while publishing article:", error);
  }
};

export const archiveArticle = (articleId) => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/articles/${articleId}/archive`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const updatedArticle = await response.json();
    dispatch(setArticle(updatedArticle));
  } catch (error) {
    console.error("Error while archiving article:", error);
  }
};

export const draftArticle = (articleId) => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/articles/${articleId}/draft`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const updatedArticle = await response.json();
    dispatch(setArticle(updatedArticle));
  } catch (error) {
    console.error("Error while archiving article:", error);
  }
};

export const addArticleComment =
  (articleId, userId, text) => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/articles/${articleId}/comment`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId, text }),
    });
    const updatedArticle = await response.json();
    dispatch(setArticle(updatedArticle));
  };

export const likeArticle =
  (articleId, userId) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      const response = await fetch(`${BASE_URL}/articles/${articleId}/like`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId }),
      });
      const updatedArticle = await response.json();
      dispatch(setArticle(updatedArticle));
    } catch (error) {
      console.error("Error while updating like:", error);
    }
  };

export const markArticleAsRead =
  (articleId, userId) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      const response = await fetch(`${BASE_URL}/articles/${articleId}/read`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId }),
      });

      if (!response.ok) {
        // Handle non-200 responses
        const errorData = await response.json();
        console.error("Error while updating read:", errorData);
        return { success: false, data: errorData };
      }

      return { success: true };
    } catch (error) {
      console.error("Error while updating read:", error);
      return { success: false, error };
    }
  };

export const likeArticleComment =
  (articleId, commentId, userId) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      const response = await fetch(
        `${BASE_URL}/articles/${articleId}/comment/${commentId}/like`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId }),
        }
      );
      const updatedArticle = await response.json();
      dispatch(setArticle(updatedArticle));
    } catch (error) {
      console.error("Error while updating like for comment:", error);
    }
  };

export const fetchCategories = () => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/articles/categories`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    dispatch(setCategories(data));
    return data;
  } catch (error) {
    console.error("Error while fetching categories:", error);
  }
};

export const fetchMostLikedArticle = () => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/articles/mostLikedArticle`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    dispatch(setMostLikedArticle(data));
    return data;
  } catch (error) {
    console.error("Error while fetching most liked article:", error);
  }
};

export const fetchLatestFollowedBrandArticles =
  () => async (dispatch, getState) => {
    try {
      const token = getState().token;
      const response = await fetch(
        `${BASE_URL}/articles/latestFollowedBrandArticles`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = await response.json();
      dispatch(setLatestFollowedBrandArticles(data));
      return data;
    } catch (error) {
      console.error(
        "Error while fetching latest followed brand articles:",
        error
      );
    }
  };

export const fetchArticleWithLikes =
  (articleId) => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/articles/${articleId}/likes`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    return data;
  };

export const fetchCommentsWithLikes =
  (articleId, commentId) => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/articles/${articleId}/comment/${commentId}/commentLikes`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();
    return data;
  };

export const fetchArticlesByTag = (tag) => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/articles/tag/${tag}`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();
  dispatch(setTagArticles(data));
  return data;
};
