import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import React from "react";

function GroupRequest() {
  const { palette } = useTheme();
  const mainSecondaryDark = palette.secondary.dark;

  return (
    <WidgetWrapper>
      <Typography
        variant="h5"
        sx={{ color: mainSecondaryDark, fontWeight: "700" }}
      >
        Team Request Pending
      </Typography>
      <Typography variant="body1">
        Your request is still awaiting approval. You will be notified as soon as
        the approval as been completed
      </Typography>
    </WidgetWrapper>
  );
}

export default GroupRequest;
