import React, { Fragment } from "react";
import { FavoriteBorderOutlined, FavoriteOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import moment from "moment";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useDispatch } from "react-redux";
import { blockUser, softBlockUser } from "redux/actions/userActions";
import { Link } from "react-router-dom";

function ArticlePageComments({
  isComments,
  setIsComments,
  comments,
  commentsCount,
  article,
  userId,
  handlePatchLikeComment,
  closeComments,
  mediumMain,
  handleMouseEnterCommentLikeButton,
  commentLikesUserNames = [], // Ensure default value
  neutralMedium,
  handleOpenReportDialog,
}) {
  const dispatch = useDispatch();

  const showTooltip = commentLikesUserNames && commentLikesUserNames.length > 0;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReport = (commentId) => {
    handleOpenReportDialog("ArticleComment", commentId);
    handleClose();
  };

  const handleReportCommentUser = (commentAuthorId) => {
    handleOpenReportDialog("User", commentAuthorId);
    handleClose();
  };

  const handleSoftBlockUser = (softBlockId) => {
    dispatch(softBlockUser(userId, softBlockId));
    handleClose();
  };

  const handleBlockUser = (blockId) => {
    dispatch(blockUser(userId, blockId));
    handleClose();
  };

  return isComments ? (
    <Box mt="0.5rem">
      <Typography variant="h5" onClick={closeComments}>
        Comments ({commentsCount})
      </Typography>

      {comments.map((comment) => (
        <Box key={comment._id}>
          <Box
            sx={{
              borderRadius: "0.75rem",
              padding: "0.5rem",
              marginBottom: "0.5rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FlexBetween width="100%">
              <Link
                to={`/profile/${comment.authorId}`}
                style={{ textDecoration: "none" }}
              >
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Avatar
                    src={
                      comment.userPicturePath.endsWith("/public")
                        ? comment.userPicturePath.replace("/public", "/64x64")
                        : `${comment.userPicturePath}/64x64`
                    }
                    sx={{
                      width: "2rem",
                      height: "2rem",
                      marginRight: "0.5rem",
                    }}
                  />
                  <Typography variant="body1">{comment.author}</Typography>
                </Box>
              </Link>

              <Typography variant="body2">
                {moment(comment.date).fromNow()}
              </Typography>
            </FlexBetween>
            <Typography variant="body1" mt="0.5rem">
              {comment.text}
            </Typography>
            <FlexBetween>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                mt="0.5rem"
              >
                <Box display="flex" alignItems="center">
                  {showTooltip ? (
                    <Tooltip
                      title={
                        <Fragment>
                          {commentLikesUserNames.length > 0 ? (
                            commentLikesUserNames.map((name, index) => (
                              <Typography
                                key={index}
                                variant="body2"
                                style={{ whiteSpace: "pre-line" }}
                              >
                                {name}
                              </Typography>
                            ))
                          ) : (
                            <Typography variant="body2">
                              No likes yet
                            </Typography>
                          )}
                        </Fragment>
                      }
                      placement="top"
                      arrow
                      PopperProps={{
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, -10],
                            },
                          },
                        ],
                      }}
                    >
                      <IconButton
                        onClick={() =>
                          handlePatchLikeComment(
                            article._id,
                            comment._id,
                            userId
                          )
                        }
                        onMouseEnter={() =>
                          handleMouseEnterCommentLikeButton(comment._id)
                        }
                        sx={{ width: "30px", height: "30px" }}
                      >
                        {Object.keys(comment.likes).includes(userId) ? (
                          <FavoriteOutlined />
                        ) : (
                          <FavoriteBorderOutlined />
                        )}
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <IconButton
                      onClick={() =>
                        handlePatchLikeComment(article._id, comment._id, userId)
                      }
                      onMouseEnter={() =>
                        handleMouseEnterCommentLikeButton(comment._id)
                      }
                      sx={{ width: "30px", height: "30px" }}
                    >
                      {Object.keys(comment.likes).includes(userId) ? (
                        <FavoriteOutlined />
                      ) : (
                        <FavoriteBorderOutlined />
                      )}
                    </IconButton>
                  )}
                </Box>

                {comment.likes && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    ml="0.5rem"
                  >
                    <Typography
                      variant="body2"
                      color={mediumMain}
                      sx={{ width: "100px" }}
                    >
                      {Object.keys(comment.likes).length} likes
                    </Typography>
                  </Box>
                )}
              </Box>
              {userId && userId !== comment.authorId && (
                <div>
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreHorizIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={() => handleReport(comment._id)}>
                      <Box>
                        <Typography variant="h6">
                          Report Article Comment
                        </Typography>
                        <Typography variant="subtitle2" color={neutralMedium}>
                          Report this article comment anonomously
                        </Typography>
                      </Box>
                    </MenuItem>

                    <MenuItem
                      onClick={() => handleReportCommentUser(comment.authorId)}
                    >
                      <Box>
                        <Typography variant="h6">Report User</Typography>
                        <Typography variant="subtitle2" color={neutralMedium}>
                          Report this user anonomously
                        </Typography>
                      </Box>
                    </MenuItem>
                    <Divider variant="middle" />

                    <MenuItem
                      onClick={() => handleSoftBlockUser(comment.authorId)}
                    >
                      <Box>
                        <Typography variant="h6">Not For Me</Typography>
                        <Typography variant="subtitle2" color={neutralMedium}>
                          Don't show me this user
                        </Typography>
                      </Box>
                    </MenuItem>

                    <MenuItem onClick={() => handleBlockUser(comment.authorId)}>
                      <Box>
                        <Typography variant="h6">Block User</Typography>
                        <Typography variant="subtitle2" color={neutralMedium}>
                          Block this user
                        </Typography>
                      </Box>
                    </MenuItem>

                    <Divider variant="middle" />

                    <MenuItem onClick={handleClose}>Close</MenuItem>
                  </Menu>
                </div>
              )}
            </FlexBetween>
            <Divider sx={{ width: "100%", mt: "0.5rem" }} />
          </Box>
        </Box>
      ))}
    </Box>
  ) : (
    <Box mt="0.5rem">
      <Typography variant="h5" onClick={() => setIsComments(true)}>
        Comments ({commentsCount})
      </Typography>
    </Box>
  );
}

export default ArticlePageComments;
