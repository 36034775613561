import { setDashboardAdminBrands } from "redux/state";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const fetchTotalBrands = () => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/dashboardBrands/totalBrands`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();

  dispatch(setDashboardAdminBrands(data));
  return data;
};

export const fetchBrandsAtEndOfLastMonth = () => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(
    `${BASE_URL}/dashboardBrands/brandsEndOfLastMonth`,
    {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  const data = await response.json();

  dispatch(setDashboardAdminBrands(data));
  return data;
};

export const fetchTotalNumberOfFollowers = () => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(
    `${BASE_URL}/dashboardBrands/totalNumberOfFollowers`,
    {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  const data = await response.json();

  dispatch(setDashboardAdminBrands(data));
  return data;
};

export const fetchTotalNumberOfArticles = () => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(
    `${BASE_URL}/dashboardBrands/totalNumberOfArticles`,
    {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  const data = await response.json();

  dispatch(setDashboardAdminBrands(data));
  return data;
};

export const updateBrandArticles =
  (brandId, articles) => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/dashboardBrands/updateBrandArticles`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ brandId, articles }),
      }
    );
    const data = await response.json();

    dispatch(setDashboardAdminBrands(data));
    return data;
  };
