import { addNewChat, setChats, updateChatInState } from "redux/state";

const BASE_URL = process.env.REACT_APP_BASE_URL;

// create chat

export const createChat =
  (name, isGroupChat, users, admins) => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/chats`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ chatName: name, isGroupChat, users, admins }),
    });

    if (!response.ok) {
      throw new Error("Failed to create chat");
    }

    const newChat = await response.json();

    await dispatch(addNewChat(newChat));
    await dispatch(fetchChats());

    return newChat;
  };

// fetch chats

export const fetchChats = () => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/chats`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch chats");
  }

  const data = await response.json();
  dispatch(setChats({ chats: data }));
  return data;
};

// add user to chat

export const addUserToChat = (chatId, userId) => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/chats/${chatId}/users/${userId}`, {
    method: "POST",
    headers: { Authorization: `Bearer ${token}` },
  });

  if (!response.ok) {
    throw new Error("Failed to add user to chat");
  }

  const data = await response.json();
  dispatch(setChats(data));
  return data;
};

// remove user from chat

export const removeUserFromChat =
  (chatId, userId) => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/chats/${chatId}/users/${userId}`,
      {
        method: "DELETE",
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to remove user from chat");
    }

    const data = await response.json();
    dispatch(setChats(data));
    return data;
  };

// add admin to chat

export const addAdminToChat =
  (chatId, userId) => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/chats/${chatId}/admins/${userId}`,
      {
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to add admin to chat");
    }

    const data = await response.json();
    dispatch(setChats(data));
    return data;
  };

// remove admin from chat

export const removeAdminFromChat =
  (chatId, userId) => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/chats/${chatId}/admins/${userId}`,
      {
        method: "DELETE",
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to remove admin from chat");
    }

    const data = await response.json();
    dispatch(setChats(data));
    return data;
  };

// delete chat

export const deleteChat = (chatId) => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/chats/${chatId}`, {
    method: "DELETE",
    headers: { Authorization: `Bearer ${token}` },
  });

  if (!response.ok) {
    throw new Error("Failed to delete chat");
  }

  const data = await response.json();
  dispatch(setChats(data));
  return data;
};

// update chat
export const updateChat =
  (chatId, isGroupChat, chatName, users, admins) =>
  async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/chats/${chatId}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ chatName, isGroupChat, users, admins }), // now sending updated users as well
    });

    if (!response.ok) {
      throw new Error("Failed to update chat");
    }

    if (response.ok) {
      console.log("chat updated");
    }

    const updatedChat = await response.json();

    // Dispatch the updateChatInState action with the updated chat information
    await dispatch(updateChatInState(updatedChat));

    return updatedChat; // Return the updated chat data
  };

export const fetchAdmins = (chatId) => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/chats/${chatId}/admins`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch admins");
  }

  const data = await response.json();
  dispatch(setChats(data));
  return data;
};
