import React, { useState, useEffect } from "react";
import { useTheme } from "@emotion/react";
import { Search } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Avatar,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import { useDispatch, useSelector } from "react-redux";
import { fetchChats } from "redux/actions/chatActions";
import { getSender } from "../config/ChatLogic";
import GroupChatModal from "./miscellaneous/GroupChatModal";
import {
  fetchUnreadMessages,
  fetchUnreadMessagesForChat,
  markAllUserMessagesAsRead,
} from "redux/actions/messageActions";
import moment from "moment";
import useSocket from "./SingleChat/useSocket";
import { markChatNotificationsAsRead } from "redux/actions/notificationActions";
import AvatarRoleIcons from "components/avatarRoleIcons";

function ChatListItem({
  chat,
  isSelected,
  onClick,
  backgroundColor,
  latestMessage,
  sender,
  isUserOnline,
  unreadMessageCount,
}) {
  const { palette } = useTheme();
  const primary = palette.primary.main;
  const secondary = palette.secondary.main;

  const avatarSrc = chat.isGroupChat
    ? "path-to-a-default-group-avatar.png"
    : chat.picturePath;

  const src = avatarSrc
    ? avatarSrc.endsWith("/public")
      ? avatarSrc.replace("/public", "/128x128")
      : `${avatarSrc}/128x128`
    : "path-to-a-default-avatar.png"; // replace with a path to a default avatar

  const displayName = chat.isGroupChat
    ? chat.chatName
    : `${chat.firstName} ${chat.lastName}`;

  const subText =
    latestMessage && latestMessage.content ? (
      <>
        <b>{!sender ? "You" : `${sender.firstName}`}</b>:{" "}
        {latestMessage.content.length > 50
          ? latestMessage.content.substring(0, 51) + "..."
          : latestMessage.content}
      </>
    ) : null;

  const whenSent = latestMessage ? (
    <Typography sx={{ color: primary }}>
      {moment(latestMessage.createdAt).fromNow()}
    </Typography>
  ) : null;

  const hasUnreadMessages = unreadMessageCount && unreadMessageCount > 0;
  const shouldShowBorder = hasUnreadMessages && !isSelected;

  return (
    <Box
      key={chat._id}
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "0.5rem 1em",
        marginBottom: "0.5em",
        borderRadius: "8px",
        backgroundColor: backgroundColor,
        color: isSelected ? "white" : undefined,
        width: "100%", // Ensure this parent takes full width
      }}
      onClick={onClick}
    >
      <Box sx={{ position: "relative" }}>
        <Avatar
          src={src}
          alt={displayName}
          sx={{
            marginRight: "1em",
            border: shouldShowBorder ? `3px solid ${secondary}` : "none",
          }}
        />
        {isUserOnline && (
          <Box
            sx={{
              position: "absolute",
              right: "1rem",
              bottom: 0,
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              backgroundColor: "green",
              border: "2px solid white",
            }}
          ></Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <FlexBetween sx={{ marginRight: "1em" }}>
          <Box display="flex" flexDirection="row" alignItems="center" gap={0.5}>
            <Typography variant="h6">{displayName}</Typography>
            {!chat.isGroupChat && (
              <AvatarRoleIcons user={chat} avatarSize="15px" />
            )}
          </Box>
          {whenSent}
        </FlexBetween>
        {subText && <Typography variant="body2">{subText}</Typography>}
      </Box>
    </Box>
  );
}

function MyChats({
  isSidebarOpen,
  setIsSidebarOpen,
  selectedChat,
  setSelectedChat,
  fetchAgain,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const chats = useSelector((state) => state.chats);
  const { palette } = useTheme();
  const primary = palette.primary.main;
  const background = palette.background.default;
  const backgroundAlt = palette.background.alt;
  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const { onlineUsers } = useSocket();

  const unreadMessageCount = useSelector(
    (state) => state.unreadMessagesCountForChat || {}
  );

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        await dispatch(fetchChats());

        // Fetch unread messages once for all chats
        await dispatch(fetchUnreadMessages());
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAgain]);

  const handleChatClick = async (chatId) => {
    setSelectedChat(chatId);
    try {
      await Promise.all([
        dispatch(markChatNotificationsAsRead(user._id, chatId)),
        dispatch(markAllUserMessagesAsRead(chatId)),
        dispatch(fetchUnreadMessagesForChat(chatId)),
      ]);
    } catch (error) {
      console.error("Error handling chat click:", error);
    }
  };

  // loop through all chats and call fetchUnreadMessagesForChat
  useEffect(
    () => {
      if (Array.isArray(chats)) {
        chats.forEach((chat) => {
          if (chat && chat._id) {
            dispatch(fetchUnreadMessagesForChat(chat._id));
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chats]
  );

  let displayValue = isLargeScreen ? "flex" : selectedChat ? "none" : "flex";

  return (
    <Box
      display={displayValue}
      flexDirection="column"
      alignItems="center"
      width={isLargeScreen ? "31%" : "100%"}
      padding="1rem"
      borderRadius="8px"
      backgroundColor={backgroundAlt}
    >
      <Box
        sx={{
          paddingBottom: "1rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          sx={{
            marginRight: 1,
            cursor: "pointer",
          }}
        >
          <Search />
        </IconButton>
        <Typography
          variant="h6"
          color={primary}
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          sx={{
            cursor: "pointer",
          }}
        >
          Search for a user to chat with
        </Typography>
      </Box>

      <FlexBetween sx={{ width: "100%" }}>
        <Typography
          padding="1rem 2rem 1rem 0"
          variant="h5"
          color={primary}
          fontWeight="bold"
        >
          My Chats
        </Typography>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          New Group Chat +
        </Button>
        <GroupChatModal open={open} setOpen={setOpen} />
      </FlexBetween>

      <Box sx={{ width: "100%" }}>
        {Array.isArray(chats) &&
          chats.map((chat) => {
            if (!chat || !chat._id) return null;

            const chatDetails = chat.isGroupChat
              ? chat
              : getSender(user, chat.users);
            const latestMessageSender =
              chat.latestMessage && chat.latestMessage.sender
                ? getSender(user, [chat.latestMessage.sender, user])
                : null; // If there's a sender for the latest message, get it.

            const isUserOnline =
              chatDetails && chatDetails._id
                ? onlineUsers.includes(chatDetails._id)
                : false;

            if (!chatDetails) return null;

            return (
              <ChatListItem
                key={chat._id}
                chat={chatDetails}
                isUserOnline={isUserOnline}
                latestMessage={chat.latestMessage} // pass down latest message
                sender={latestMessageSender} // pass down the sender details
                isSelected={selectedChat === chat._id}
                onClick={() => handleChatClick(chat._id)}
                user={user}
                backgroundColor={
                  selectedChat === chat._id ? primary : background
                }
                selectedChat={selectedChat}
                unreadMessageCount={unreadMessageCount[chat._id]}
              />
            );
          })}
      </Box>
      {/* <Footer /> */}
    </Box>
  );
}

export default MyChats;
