import { useEffect } from "react";
import { Badge, IconButton, Box, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNotifications,
  addNotification,
} from "redux/actions/notificationActions";
import notificationLight from "assets/light/notifications.png";
import notificationDark from "assets/dark/notifications.png";

export const NotificationBadge = ({
  socket,
  isNotificationSidebarOpen,
  setIsNotificationSidebarOpen,
  unreadNotificationsCount,
}) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user._id);
  const theme = useTheme();
  const primaryLight = theme.palette.primary.light;
  const mode = useSelector((state) => state.mode);

  useEffect(() => {
    // Fetch initial notifications when the component mounts
    const fetchInitialNotifications = async () => {
      dispatch(fetchNotifications(userId));
    };

    fetchInitialNotifications();
  }, [userId, dispatch]);

  useEffect(() => {
    if (!socket) return;

    const handleNewNotification = (data) => {
      dispatch(addNotification(data));
    };

    socket.on("getNotification", handleNewNotification);

    return () => {
      socket.off("getNotification", handleNewNotification);
    };
    // eslint-disable-next-line
  }, [socket]); // only socket as dependency

  const handleClick = () => {
    setIsNotificationSidebarOpen(true);
  };

  return (
    <Box position="relative">
      <IconButton
        onClick={handleClick}
        sx={{
          backgroundColor: isNotificationSidebarOpen ? primaryLight : undefined,
        }}
      >
        <Badge badgeContent={unreadNotificationsCount} color="error">
          <img
            src={mode === "light" ? notificationLight : notificationDark}
            alt="Notification Badge"
            style={{ width: "25px", height: "25px" }}
          />
        </Badge>
      </IconButton>
    </Box>
  );
};

export default NotificationBadge;
