import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import FollowedBrand from "components/FollowedBrand";
import WidgetWrapper from "components/WidgetWrapper";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function YourFollowedBrands() {
  const isLargeScreen = useMediaQuery("(min-width: 1000px)");
  const user = useSelector((state) => state.viewingUser || state.user);
  const currentUser = useSelector((state) => state.user);
  const brandsFollowed = user?.brandFollowsDetails || [];
  const { palette } = useTheme();
  const neutralDark = palette.neutral.dark;
  const primaryDark = palette.primary.dark;

  // Convert currentUser.brandFollows to a Set for faster lookups
  const followedBrandIds = new Set(currentUser?.brandFollows || []);

  if (brandsFollowed.length === 0) {
    return null;
  }

  const displayedBrands = brandsFollowed.slice(0, isLargeScreen ? 10 : 5);

  const displayRemainingBrands = brandsFollowed.slice(isLargeScreen ? 10 : 5);

  const viewMoreCount = isLargeScreen ? 10 : 5;

  return (
    <WidgetWrapper borderRadius={!isLargeScreen ? "2rem" : "0.75rem"}>
      <Typography
        color={neutralDark}
        variant="h5"
        fontWeight="500"
        sx={{ mb: "1.5rem" }}
      >
        Followed Brands
      </Typography>

      <Box display="flex" flexDirection="column" gap="0.5rem">
        {displayedBrands.map((brand) => (
          <FollowedBrand
            key={brand._id}
            brand={brand}
            followedBrandIds={followedBrandIds}
          />
        ))}
      </Box>
      <Box mt={1} display="flex" flexDirection="center">
        <AvatarGroup max={6}>
          {displayRemainingBrands.map((brand) => (
            <Tooltip title={brand.name} key={brand._id} arrow>
              <Avatar
                alt={brand.name}
                src={brand.picturePath}
                key={brand._id}
              />
            </Tooltip>
          ))}
        </AvatarGroup>
      </Box>

      {brandsFollowed.length > viewMoreCount && (
        <Button
          variant="text"
          sx={{ color: primaryDark, textTransform: "none" }}
          component={Link}
          to={`/profile/${user._id}/brands`}
        >
          View All
        </Button>
      )}
    </WidgetWrapper>
  );
}

export default YourFollowedBrands;
