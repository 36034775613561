import React from "react";
import { Link } from "react-router-dom"; // Import Link from React Router
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { BusinessProfileClick } from "helpers/businessHelpers/businessProfileClick";
import useBrandCreateForm from "scenes/admin/directory/BrandCreateForm/hooks/useBrandCreateForm";
import BrandForm from "scenes/admin/directory/BrandCreateForm";

export default function Footer() {
  const {
    handleChange,
    handleLocationChange,
    handleLocationInputChange,
    locationOptions,
    modules,
    formats,
    sportsList,
    selectedSport,
    setSelectedSport,
    location,
    formData,
    setFormData,
    image,
    logo,
    handleEditClick,
    handleFileChange,
    handleLogoChange,
    formErrors,
    fileInputRef,
    logoInputRef,
    handleSportChange,
    defaultImageURL,
    open,
    handleOpen,
    handleClose,
    handleSubmit,
    isLargeScreen,
    googleImages,
    handleGoogleImageSelect,
    locationSelected,
    setLocationSelected,
  } = useBrandCreateForm({ isUser: true });

  const handleImageSelectClick = (url) => {
    handleGoogleImageSelect(url);
  };

  const dispatch = useDispatch();
  const theme = useTheme();

  const user = useSelector((state) => state.user); // get the user from the state
  const userId = user._id;

  const dark = theme.palette.neutral.dark;
  const light = theme.palette.neutral.light;

  const footerStyle = {
    flex: isLargeScreen ? "1" : undefined,
    padding: "20px",
    width: isLargeScreen ? "50%" : "100%", // Set the width for the first box
  };

  const linkContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start", // Align links to the start
    gap: "1rem",
  };

  return (
    <Box
      display="flex"
      flexDirection={isLargeScreen ? "row" : "column"}
      justifyContent="center"
      bgcolor={dark}
      color={light}
      padding={isLargeScreen ? "1rem 6%" : "1rem 0 5rem 0"}
    >
      <Box style={footerStyle}>
        <Box style={linkContainerStyle}>
          <Link to="/home">Homepage</Link>
          <Link to="/groups">Groups</Link>
          <Link
            to="/brand/657724d326d33c4ffe936832"
            onClick={() =>
              BusinessProfileClick(dispatch, {
                brandId: "657724d326d33c4ffe936832",
                fromPage: "footer",
              })
            }
          >
            News from the Business
          </Link>
          <Link to="/findMembers">Search Members</Link>
          <Link to="/chat">Messages</Link>
          <Link to={`/profile/${userId}`}>Your Profile</Link>
        </Box>
      </Box>

      {isLargeScreen ? (
        <Divider
          orientation="vertical"
          sx={{ backgroundColor: light, width: "1px", minHeight: "100%" }}
          style={{ width: "1px !important" }}
        />
      ) : (
        <Divider
          orientation="horizontal"
          sx={{ backgroundColor: light, height: "1px" }}
        />
      )}

      <Box style={footerStyle}>
        <Box style={linkContainerStyle}>
          <Link to={"/settings"}>Settings and Preferences</Link>
          <Link onClick={handleOpen}>Create a new Business</Link>

          {/* <Link to="">Help Center</Link>
          <Link to="">About Us</Link> */}
          {/* <Link to="">Become a Member</Link> */}

          <Link to="/contact-support">Contact Support</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/user-terms-and-conditions">Terms and Conditions</Link>
        </Box>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add a New Brand</DialogTitle>
        <DialogContent>
          <BrandForm
            handleChange={handleChange}
            handleLocationChange={handleLocationChange}
            handleLocationInputChange={handleLocationInputChange}
            locationOptions={locationOptions}
            location={location}
            modules={modules}
            formats={formats}
            sportsList={sportsList}
            selectedSport={selectedSport}
            setSelectedSport={setSelectedSport}
            formData={formData}
            setFormData={setFormData}
            image={image}
            logo={logo}
            handleEditClick={handleEditClick}
            handleFileChange={handleFileChange}
            handleLogoChange={handleLogoChange}
            formErrors={formErrors}
            fileInputRef={fileInputRef}
            logoInputRef={logoInputRef}
            handleSportChange={handleSportChange}
            defaultImageURL={defaultImageURL}
            googleImages={googleImages}
            handleGoogleImageSelect={handleImageSelectClick}
            locationSelected={locationSelected}
            setLocationSelected={setLocationSelected}
            isLargeScreen={isLargeScreen}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
