import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  addArticleComment,
  fetchArticle,
  fetchArticleWithLikes,
  fetchCommentsWithLikes,
  likeArticle,
  likeArticleComment,
  markArticleAsRead,
  publishArticle,
} from "redux/actions/articleActions";
import { fetchBrand, toggleFollowBrand } from "redux/actions/brandActions";
import { createFlag } from "redux/actions/flagActions";
import * as yup from "yup";

export const useArticlePageState = ({ socket }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { palette } = useTheme();
  const primary = palette.primary.main;
  const primaryLight = palette.primary.light;
  const secondary = palette.secondary.main;
  const neutralLight = palette.neutral.light;
  const neutralMedium = palette.neutral.medium;
  const backgroundAlt = palette.background.alt;
  const background = palette.background.default;
  const mediumMain = palette.neutral.mediumMain;
  const { articleId } = useParams();
  const article = useSelector((state) => state.article) || {};
  const brand = useSelector((state) => state.brand) || {};
  const user = useSelector((state) => state.user);

  const [loading, setLoading] = useState(true);

  let userId;
  let isPublicPage;

  if (user) {
    userId = user._id;
    isPublicPage = false;
  } else {
    isPublicPage = true;
  }

  const isLiked = article && user ? Boolean(article.likes?.[userId]) : false;
  const likeCount = Object.keys(article.likes ?? {}).length;

  const [comment, setComment] = useState("");
  const [isComments, setIsComments] = useState(false);

  const comments = article.comments || [];
  const commentsCount = comments.length;

  const [likesUserNames, setLikesUserNames] = useState([]);
  const [hasFetchedLikes, setHasFetchedLikes] = useState(false);

  const [commentLikesUserNames, setCommentLikesUserNames] = useState([]);
  const [hasFetchedCommentLikes, setHasFetchedCommentLikes] = useState(false);

  const [prevCommentId, setPrevCommentId] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  const [openReportDialog, setOpenReportDialog] = useState(false);

  const handleMouseEnterLikeButton = async () => {
    if (!hasFetchedLikes) {
      await handleGetArticleWithLikes(); // Fetch likes only if not already fetched
      setHasFetchedLikes(true); // Set flag to true after fetching
    }
  };

  useEffect(() => {
    // Fetch comment likes when prevCommentId changes and fetching is needed
    const fetchCommentLikesIfNeeded = async () => {
      if (prevCommentId && !hasFetchedCommentLikes) {
        await handleGetArticleWithCommentLikes(prevCommentId);
        setHasFetchedCommentLikes(true);
      }
    };

    fetchCommentLikesIfNeeded();
  }, [prevCommentId, hasFetchedCommentLikes]);

  const handleMouseEnterCommentLikeButton = (commentId) => {
    if (commentId !== prevCommentId) {
      setHasFetchedCommentLikes(false);
      setPrevCommentId(commentId);
    }
  };

  const handleGetArticleWithLikes = async () => {
    try {
      // Clearing previous likes user names before fetching new ones
      setLikesUserNames([]);

      const data = await dispatch(fetchArticleWithLikes(articleId));
      setLikesUserNames(data.likesUserNames);
    } catch (error) {
      console.error("An error occurred while fetching the post likes:", error);
      // Handle any errors, perhaps by setting an error message in state
    }
  };

  const handleGetArticleWithCommentLikes = async (commentId) => {
    try {
      // Clearing previous comment likes user names before fetching new ones
      setCommentLikesUserNames([]);
      const data = await dispatch(fetchCommentsWithLikes(articleId, commentId));

      setCommentLikesUserNames(data.likesUserNames);
    } catch (error) {
      console.error(
        "An error occurred while fetching the post comment likes:",
        error
      );
    }
  };

  // Check if the user is a brand user
  const isBrandUser =
    user &&
    (user.brandManagement.includes(article.brand) ||
      user.brandCopywriting.includes(article.brand));

  // Check if the user is a brand user and if the article status is not draft or archived
  const shouldDisplayContent =
    (isBrandUser && article.status === "draft") ||
    (isBrandUser && article.status === "archived") ||
    article.status === "published";

  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  // Modify the fetchData function within useEffect to update the loading state appropriately
  useEffect(() => {
    const fetchData = async () => {
      if (articleId) {
        setLoading(true); // Set loading to true at the start of data fetch
        const article = await dispatch(fetchArticle(articleId));

        if (article) {
          if (!isPublicPage) {
            dispatch(markArticleAsRead(articleId, userId));
          }
          const brandId = article.brand;
          if (brandId) {
            await dispatch(fetchBrand(brandId));
          }
        }
        setLoading(false); // Set loading to false once all data has been fetched
      }
    };
    fetchData();
  }, [dispatch, articleId, userId, isPublicPage]);

  const handlePublish = async () => {
    // handle publishing the article

    brand.followers.forEach((follower) => {
      const notificationData = {
        senderId: user._id,
        brandId: brand._id,
        brandName: brand.name,
        receiverId: follower.userId,
        name: user.firstName + " " + user.lastName,
        articleId: articleId,
        title: article.title,
        picturePath: brand.picturePath,
        timeStamp: Date.now(),
        type: "newArticle",
      };
      socket.emit("sendNotification", notificationData);
    });

    await dispatch(publishArticle(articleId));
  };

  const handleEdit = () => {
    // Your code to handle editing the article
    navigate(`/news/article/${articleId}/edit`);
  };

  const handlePatchLike = async () => {
    if (isPublicPage) {
      setSnackbarMessage("Please log in to like the Article");
      setSnackbarSeverity("info");
      setSnackbarOpen(true);
      return;
    }

    try {
      await dispatch(likeArticle(article._id, user._id));

      if (isLiked) {
        setSnackbarMessage("Article unliked");
        setSnackbarSeverity("info");
      } else {
        setSnackbarMessage("Article liked");
        setSnackbarSeverity("success");
      }
      setSnackbarOpen(true);
    } catch (error) {
      console.error("An error occurred while liking the post:", error);
      setSnackbarMessage("An error occurred while liking the post");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleFollowToggle = () => {
    if (isPublicPage) {
      setSnackbarMessage("Please log in to follow the Brand");
      setSnackbarSeverity("info");
      setSnackbarOpen(true);
      return;
    }

    dispatch(toggleFollowBrand(brand._id));
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleCommentSubmit = (event, type) => {
    event.preventDefault();
    dispatch(addArticleComment(article._id, userId, comment));
    setComment("");
  };

  const closeComments = () => {
    setIsComments(false);
  };

  const handlePatchLikeComment = async (articleId, commentId, userId) => {
    if (isPublicPage) {
      setSnackbarMessage("Please log in to like the Article Comment");
      setSnackbarSeverity("info");
      setSnackbarOpen(true);
      return;
    }
    try {
      await dispatch(likeArticleComment(articleId, commentId, userId));
    } catch (error) {
      console.log(error);
    }
  };

  // create hashed article tags
  const keywords = article.tags
    ? article.tags
        .map((tag) =>
          tag && tag.name ? `#${tag.name.replace(/\s+/g, "-")}` : ""
        )
        .join(", ")
    : "";

  /* Article and Article Comments Reporting/Flagging */

  const validationReportSchema = yup.object().shape({
    reason: yup.string().required("Reason is required"),
    details: yup.string().required("Description is required"),
  });

  const handleOpenReportDialog = (contentType, contentId) => {
    setFormData({
      ...formData,
      contentId: contentId,
      contentType: contentType,
    });
    setOpenReportDialog(true);
  };

  const handleCloseReportDialog = () => {
    setOpenReportDialog(false);
  };

  const resetReportForm = () => {
    setFormData({
      contentId: "",
      reason: "",
      flaggerId: userId,
      details: "",
      contentType: "",
    });
  };

  const handleReportPost = async () => {
    try {
      await validationReportSchema.validate(formData, { abortEarly: false });
      setFormErrors({});

      dispatch(createFlag(formData));

      setSnackbarMessage(
        "Report submitted, one of the She Is Action team will review it soon"
      );
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

      resetReportForm();

      handleCloseReportDialog();
    } catch (error) {
      console.error("An error occurred while submitting the report:", error);
      setSnackbarMessage("An error occurred while submitting the report");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      // Handle any errors, perhaps by setting an error message in state
    }
  };

  const [formData, setFormData] = useState({
    contentId: "",
    reason: "",
    flaggerId: userId,
    details: "",
    contentType: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const contentType = formData.contentType;

  // switch between content types to change the label of the text field
  let textFieldLabel = "";
  let dialogTitle = "";
  switch (contentType) {
    case "Post":
      textFieldLabel = "What is the issue with this post?";
      dialogTitle = "Report Post";
      break;
    case "PostComment":
      textFieldLabel = "What is the issue with this comment?";
      dialogTitle = "Report Comment";
      break;
    case "Article":
      textFieldLabel = "What is the issue with this article?";
      dialogTitle = "Report Article";
      break;
    case "ArticleComment":
      textFieldLabel = "What is the issue with this comment?";
      dialogTitle = "Report Comment";
      break;
    case "User":
      textFieldLabel = "What is the issue with this user?";
      dialogTitle = "Report User";
      break;
    case "Brand":
      textFieldLabel = "What is the issue with this brand?";
      dialogTitle = "Report Brand";
      break;

    default:
      textFieldLabel = "What is the issue?";
  }

  return {
    article,
    shouldDisplayContent,
    isLargeScreen,
    handlePublish,
    handleEdit,
    primary,
    primaryLight,
    secondary,
    neutralLight,
    neutralMedium,
    backgroundAlt,
    brand,
    mediumMain,
    handlePatchLike,
    isLiked,
    likeCount,
    background,
    handleCommentChange,
    handleCommentSubmit,
    comment,
    isComments,
    setIsComments,
    closeComments,
    handlePatchLikeComment,
    handleFollowToggle,
    comments,
    commentsCount,
    userId,
    handleMouseEnterLikeButton,
    likesUserNames,
    handleMouseEnterCommentLikeButton,
    commentLikesUserNames,
    isPublicPage,
    snackbarOpen,
    setSnackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    keywords,
    loading,
    openReportDialog,
    handleOpenReportDialog,
    handleCloseReportDialog,
    handleReportPost,
    formData,
    formErrors,
    handleChange,
    textFieldLabel,
    dialogTitle,
    navigate,
  };
};
