import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography, useTheme, Button } from "@mui/material";

import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { updateGroup } from "redux/actions/groupActions";
import { handleImageUpload } from "redux/actions/imageUploadUtils";

function GroupImageMain({ isAdmin }) {
  const { palette } = useTheme();
  const light = palette.neutral.light;

  // Get group data from redux
  const group = useSelector((state) => state.group);
  // eslint-disable-next-line
  const [image, setImage] = useState(null);
  const dispatch = useDispatch();

  // Destructuring the group details
  const { image: groupImage = null } = group;

  const fileInputRef = useRef(null);

  const handleEditClick = () => {
    // Programmatically click the hidden file input element
    // to open the file dialog
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file); // Still update the state if needed for other parts of your app
      await groupImageUpload(file); // Pass the file directly here
    }
  };

  // Modified handleImageUpload function to accept a file directly
  const groupImageUpload = async (file) => {
    try {
      // Request an upload URL from the server
      const imageFile = await handleImageUpload(file);

      const updatedGroup = {
        ...group,
        image: imageFile,
      };

      dispatch(updateGroup(updatedGroup));
      setImage(null);
    } catch (error) {
      console.error("An error occurred while uploading the image:", error);
    }
  };

  return (
    <Box width="100%" position="relative" marginBottom="1rem">
      <Box
        width="100%"
        position="relative"
        style={{
          height: "300px",
          overflow: "hidden",
          borderRadius: "1rem",
        }}
      >
        <img
          src={groupImage ? groupImage : "../assets/groupShot.png"}
          alt="Group Shot of sports women"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      </Box>

      {/* Positioned Edit Icon */}
      {isAdmin && (
        <Box
          position="absolute"
          right="15px"
          bottom="15px"
          display="flex"
          alignItems="center"
          color={light}
        >
          <Button onClick={handleEditClick} variant="contained">
            <Typography
              variant="body1"
              component="span"
              fontWeight={600}
              color={light}
              mr={1}
            >
              Edit
            </Typography>
            <ModeEditOutlineOutlinedIcon style={{ color: light }} />
          </Button>

          {/* Hidden file input */}
          <input
            ref={fileInputRef}
            type="file"
            accept=".jpg,.jpeg,.png"
            style={{ display: "none" }}
            onChange={handleFileChange}
            data-testid="file-input"
          />
        </Box>
      )}
    </Box>
  );
}

export default GroupImageMain;
