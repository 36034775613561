import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AvatarRoleIcons from "components/avatarRoleIcons";

function PostWidgetNameAndDetails({
  isGroupPost,
  userPicturePath,
  name,
  createdAt,
  lastEdited,
  main,
  userId,
  loggedInUser,
  neutralMedium,
  user,
  isPostAuthor,
  handleSoftBlockUser,
  handleBlockUser,
  groupImage,
  groupName,
  userProfileLink,
  groupLink,
  anchorEl,
  handleClick,
  handleClose,
  handleReportPost,
  handleReportUser,
}) {
  return (
    <FlexBetween>
      <Box display="flex" alignItems="center">
        {isGroupPost ? (
          loggedInUser ? (
            <Link to={groupLink}>
              <Avatar
                src={
                  groupImage && groupImage.endsWith("/public")
                    ? groupImage.replace("/public", "/128x128")
                    : `${groupImage}/128x128`
                }
                sx={{ width: "50px", height: "50px", borderRadius: "5px" }}
              />
            </Link>
          ) : (
            <Avatar
              src={
                groupImage && groupImage.endsWith("/public")
                  ? groupImage.replace("/public", "/128x128")
                  : `${groupImage}/128x128`
              }
              sx={{ width: "50px", height: "50px", borderRadius: "5px" }}
            />
          )
        ) : loggedInUser ? (
          <Link to={userProfileLink}>
            <Avatar
              src={
                userPicturePath && userPicturePath.endsWith("/public")
                  ? userPicturePath.replace("/public", "/128x128")
                  : `${userPicturePath}/128x128`
              }
              sx={{ width: "50px", height: "50px" }}
            />
          </Link>
        ) : (
          <Avatar
            src={
              userPicturePath && userPicturePath.endsWith("/public")
                ? userPicturePath.replace("/public", "/128x128")
                : `${userPicturePath}/128x128`
            }
            sx={{ width: "50px", height: "50px" }}
          />
        )}
        <Box ml="1rem">
          <Typography variant="h5">
            {isGroupPost ? (
              loggedInUser ? (
                <Link to={groupLink}>{groupName}</Link>
              ) : (
                groupName
              )
            ) : loggedInUser ? (
              <Link to={userProfileLink}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems={"center"}
                  gap={1}
                >
                  {name}
                  <AvatarRoleIcons user={user} avatarSize="20px" />
                </Box>
              </Link>
            ) : (
              name
            )}
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems={"center"}
            gap={0.5}
          >
            {isGroupPost && loggedInUser && (
              <>
                <Typography variant="subtitle2" color={main}>
                  <Link to={userProfileLink}>{name}</Link>
                </Typography>
                <AvatarRoleIcons user={user} avatarSize="15px" />
                <Typography variant="subtitle2" color={main}>
                  {" - "}
                </Typography>
              </>
            )}{" "}
            <Tooltip
              title={moment(createdAt).format("LLLL")}
              arrow
              placement="bottom"
            >
              <Typography variant="subtitle2" color={main}>
                {moment(createdAt).fromNow()}
              </Typography>
            </Tooltip>
            <Typography variant="subtitle2" color={main}>
              {lastEdited && (
                <>
                  {" - Edited "}
                  <Tooltip
                    title={moment(lastEdited).format("LLLL")}
                    arrow
                    placement="bottom"
                  >
                    <span>{moment(lastEdited).fromNow()}</span>
                  </Tooltip>
                </>
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
      {loggedInUser && !isPostAuthor && (
        <div>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreHorizIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleReportPost}>
              <Box>
                <Typography variant="h6">Report Post</Typography>
                <Typography variant="subtitle2" color={neutralMedium}>
                  Report this post anonomously
                </Typography>
              </Box>
            </MenuItem>
            {/* Future menu items can be added here */}
            <MenuItem onClick={handleReportUser}>
              <Box>
                <Typography variant="h6">Report User</Typography>
                <Typography variant="subtitle2" color={neutralMedium}>
                  Report this user anonomously
                </Typography>
              </Box>
            </MenuItem>

            <Divider variant="middle" />

            <MenuItem onClick={() => handleSoftBlockUser(userId)}>
              <Box>
                <Typography variant="h6">Not For Me</Typography>
                <Typography variant="subtitle2" color={neutralMedium}>
                  Don't show me this user
                </Typography>
              </Box>
            </MenuItem>

            <MenuItem onClick={() => handleBlockUser(userId)}>
              <Box>
                <Typography variant="h6">Block User</Typography>
                <Typography variant="subtitle2" color={neutralMedium}>
                  Block this user
                </Typography>
              </Box>
            </MenuItem>

            <Divider variant="middle" />

            <MenuItem onClick={handleClose}>Close</MenuItem>
          </Menu>
        </div>
      )}
    </FlexBetween>
  );
}

export default PostWidgetNameAndDetails;
