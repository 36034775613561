import React, { useEffect } from "react";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector, useDispatch } from "react-redux";
import { fetchSports } from "redux/actions/sportActions";

function SportSelection({ formProps, index, sport }) {
  const dispatch = useDispatch();
  const sportsList = useSelector((state) => state.sports);

  useEffect(() => {
    if (!sportsList.length) dispatch(fetchSports());
  }, [dispatch, sportsList.length]);

  return (
    <Autocomplete
      style={{ flex: 1 }}
      options={sportsList ? sportsList.map((s) => s.name) : []}
      value={sport.sport}
      onChange={(event, newValue) => {
        formProps.setFieldValue(`sports[${index}].sport`, newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Sport"
          onBlur={formProps.handleBlur}
          error={
            formProps.touched.sports &&
            formProps.touched.sports[index] &&
            Boolean(formProps.errors.sports?.[index]?.sport)
          }
          helperText={
            formProps.touched.sports &&
            formProps.touched.sports[index] &&
            formProps.errors.sports?.[index]?.sport
          }
          fullWidth
          margin="normal"
        />
      )}
    />
  );
}

export default SportSelection;
