import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Box,
  Modal,
  FormControl,
  Input,
  Button,
  List,
  ListItem,
  ListItemText,
  Avatar,
} from "@mui/material";
import { searchUsers } from "redux/actions/userActions";
import { useTheme } from "@emotion/react";
import { Close } from "@mui/icons-material";
import { updateChat } from "redux/actions/chatActions";
import FlexBetween from "components/FlexBetween";
import { Link } from "react-router-dom";

function UpdateGroupChatModal({
  fetchAgain,
  setFetchAgain,
  selectedChat,
  fetchChatMessages,
  openUpdateGroupChatModal,
  setOpenUpdateGroupChatModal,
}) {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const primary = palette.primary.main;
  const primaryLight = palette.primary.light;
  const primaryDark = palette.primary.dark;
  const neutralLight = palette.neutral.light;
  const neutralMedium = palette.neutral.medium;
  const neutralDark = palette.neutral.dark;
  const userId = useSelector((state) => state.user._id);
  const chat = useSelector((state) =>
    state.chats.find((chat) => chat._id === selectedChat)
  );

  // Check if the current user is an admin of the selected chat
  const isAdmin = chat && chat.admins.some((admin) => admin._id === userId);

  const [search, setSearch] = useState("");
  const [groupChatName, setGroupChatName] = useState("");

  const [searchResults, setSearchResults] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleSearch = async (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (search) {
      const limit = 5;

      dispatch(searchUsers(search, page, limit))
        .then((data) => {
          if (data && Array.isArray(data.data)) {
            setSearchResults(data.data);
            setTotalPages(Math.ceil(data.total / limit));
          } else {
            setSearchResults([]);
          }
        })
        .catch((error) => {
          setSearchResults([]);
          // Maybe show a notification or alert to the user here?
        });
    } else {
      setSearchResults([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, page]);

  useEffect(() => {
    // Reset state when the chat changes
    if (chat && chat.users) {
      setSelectedUsers(chat.users);
    } else {
      setSelectedUsers([]);
    }
    setSearch("");
    setSearchResults([]);
    // ... any other state resets as needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChat]);

  const handleUserSelection = (user) => {
    if (!selectedUsers.some((u) => u._id === user._id)) {
      setSelectedUsers([...selectedUsers, user]);
    } else {
      handleDeleteUser(user);
    }
  };

  const handleDeleteUser = (user) => {
    // Check if there are at least two users including this one
    if (selectedUsers.length <= 2) {
      alert("A group chat must have at least two members.");
      return;
    }
    // Check if the user is an admin and if they are the only admin
    if (chat.admins.some((admin) => admin._id === user._id)) {
      if (chat.admins.length === 1) {
        alert("At least one admin is required in a group chat.");
        return;
      }
    }

    // Proceed with user removal if checks pass
    setSelectedUsers(selectedUsers.filter((u) => u._id !== user._id));
  };

  const handleUpdateGroupChatModalClose = () => {
    // Reset the selectedUsers and other relevant state
    setSelectedUsers([]);
    setSearch("");
    setSearchResults([]);
    setGroupChatName("");
    setPage(1);
    setTotalPages(1);

    setOpenUpdateGroupChatModal(false);
  };

  const handleUpdateGroupChat = async () => {
    const chatName = groupChatName ? groupChatName : chat.chatName;
    const userIdsToUpdate = selectedUsers.map((user) => user._id);
    const admins = userId;

    await dispatch(
      updateChat(
        selectedChat,
        chat.isGroupChat,
        chatName,
        userIdsToUpdate,
        admins
      )
    );

    try {
      fetchChatMessages();
      handleUpdateGroupChatModalClose();
      setFetchAgain(!fetchAgain);
    } catch (error) {}
  };

  useEffect(() => {
    // load the selected chat's users into the selectedUsers state
    if (openUpdateGroupChatModal && chat && chat.users) {
      setSelectedUsers(chat.users);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat, openUpdateGroupChatModal]);

  return (
    <Modal
      open={openUpdateGroupChatModal}
      onClose={handleUpdateGroupChatModalClose}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: `2px solid ${primary}`,
          boxShadow: 24,
          p: 4,
          borderRadius: "10px",
        }}
      >
        <Typography
          variant="h4"
          display="flex"
          justifyContent="center"
          color={primary}
          paddingBottom="0.5em"
        >
          {chat && chat.chatName}
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
        >
          {/* Display current group members */}
          <Typography
            variant="h6"
            display="flex"
            justifyContent="center"
            color={neutralDark}
            paddingBottom="0.5em"
          >
            Current Group Members:
          </Typography>
          <Box width="100%" display="flex" flexWrap="wrap" marginBottom="1em">
            {chat &&
              chat.users &&
              chat.users.map((user, index) => (
                <Box
                  key={index}
                  px="5px"
                  py="3px"
                  borderRadius="5px"
                  m="5px"
                  mb="5px"
                  backgroundColor={neutralLight}
                  color={neutralDark}
                >
                  {user.firstName} {user.lastName}
                </Box>
              ))}
          </Box>
          {isAdmin && (
            <>
              <FormControl fullWidth>
                <Input
                  type="text"
                  placeholder="Group Chat Name"
                  value={groupChatName}
                  onChange={(e) => setGroupChatName(e.target.value)}
                  disableUnderline
                  sx={{
                    marginBottom: "1em",
                    border: `1px solid ${neutralMedium}`,
                    borderRadius: "5px",
                    paddingLeft: "0.5em",
                    width: "100%",
                  }}
                />
              </FormControl>
              <FormControl fullWidth>
                <Input
                  type="text"
                  placeholder="Search for users"
                  value={search}
                  onChange={handleSearch}
                  disableUnderline
                  sx={{
                    marginBottom: "1em",
                    border: `1px solid ${neutralMedium}`,
                    borderRadius: "5px",
                    paddingLeft: "0.5em",
                    width: "100%",
                  }}
                />
              </FormControl>
              <Typography
                variant="h6"
                display="flex"
                justifyContent="center"
                color={primary}
                paddingBottom="0.5em"
              >
                Chat Members
              </Typography>
              <Box width="100%" display="flex" flexWrap="wrap">
                {selectedUsers.map((user, index) => (
                  <Box
                    key={index}
                    display="flex"
                    alignItems="center"
                    px="5px"
                    py="3px"
                    borderRadius="5px"
                    m="5px"
                    mb="5px"
                    variant="filled"
                    fontSize={12}
                    // Check if user is already part of the group and style accordingly
                    backgroundColor={
                      chat.users.some((chatUser) => chatUser._id === user._id)
                        ? neutralLight
                        : primaryDark
                    }
                    color={
                      chat.users.some((chatUser) => chatUser._id === user._id)
                        ? neutralDark
                        : primaryLight
                    }
                    cursor="pointer"
                  >
                    {user.firstName} {user.lastName}
                    <Close
                      sx={{ marginLeft: "0.3em", fontSize: "1em" }}
                      onClick={() => handleDeleteUser(user)}
                    />
                  </Box>
                ))}
              </Box>

              <List sx={{ width: "100%" }}>
                {searchResults.map((user, index) => (
                  <ListItem
                    key={index}
                    onClick={() => handleUserSelection(user)}
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: neutralLight,
                      borderRadius: "10px",
                      marginBottom: "0.5em",
                      "&:hover": {
                        backgroundColor: primaryLight,
                        color: primaryDark,
                      },
                    }}
                  >
                    {/* <ListItemText primary={`${user.firstName} ${user.lastName}`} /> */}
                    <Avatar
                      src={
                        user.picturePath && user.picturePath.endsWith("/public")
                          ? user.picturePath.replace("/public", "/128x128")
                          : user.picturePath
                          ? `${user.picturePath}/128x128`
                          : "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/b4d08b7c-3eb4-46fc-dc29-44555ce40300/128x128"
                      }
                      sx={{ width: "30px", height: "30px" }}
                    />
                    <Box sx={{ flexGrow: 1, paddingLeft: "0.5rem" }}>
                      <ListItemText
                        primary={`${user.firstName} ${user.lastName}`}
                        sx={{ fontWeight: 500 }}
                      />
                    </Box>
                    {selectedUsers.some((u) => u._id === user._id) && "✔️"}
                  </ListItem>
                ))}
              </List>
              {searchResults && searchResults.length > 0 && (
                <Box display="flex" justifyContent="space-between" mt={2}>
                  <Button
                    disabled={page === 1}
                    onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                  >
                    Previous
                  </Button>
                  <Typography
                    display="flex"
                    alignItems="center"
                    color={primary}
                  >
                    {page} of {totalPages}
                  </Typography>
                  <Button
                    disabled={page === totalPages}
                    onClick={() =>
                      setPage((prev) => Math.min(prev + 1, totalPages))
                    }
                  >
                    Next
                  </Button>
                </Box>
              )}
            </>
          )}
        </Box>
        {isAdmin ? (
          <FlexBetween marginTop="1em">
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: "3em" }}
              onClick={handleUpdateGroupChat}
            >
              Update
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleUpdateGroupChatModalClose}
            >
              Cancel
            </Button>
          </FlexBetween>
        ) : (
          <>
            <Typography variant="body1" color={primary} mb={1}>
              Only admins can update group chats.
            </Typography>
            <Box mb={2}>
              <Typography variant="h6" color={primary}>
                Admins:
              </Typography>
              {chat &&
                chat.admins.map((admin, index) => (
                  <Link
                    key={index}
                    to={`/profile/${admin._id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Box display="flex" alignItems="center" mt={1}>
                      <Avatar
                        src={
                          admin.picturePath &&
                          admin.picturePath.endsWith("/public")
                            ? admin.picturePath.replace("/public", "/128x128")
                            : admin.picturePath
                            ? `${admin.picturePath}/128x128`
                            : "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/b4d08b7c-3eb4-46fc-dc29-44555ce40300/128x128"
                        }
                        sx={{ width: "30px", height: "30px", mr: 1 }}
                      />
                      <Typography variant="body2">
                        {admin.firstName} {admin.lastName}
                      </Typography>
                    </Box>
                  </Link>
                ))}
            </Box>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleUpdateGroupChatModalClose}
            >
              Close
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
}

export default UpdateGroupChatModal;
