import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import Form from "./Form";

const LoginPage = () => {
  const { palette } = useTheme();
  const isLargeScreen = useMediaQuery("(min-width: 1000px)");
  const backgroundAlt = palette.background.alt;
  return (
    <Box>
      <Box
        width="100%"
        backgroundColor={backgroundAlt}
        p="1rem 6%"
        textAlign="center"
      >
        <Typography color="primary" variant="h1">
          SHE IS ACTION
        </Typography>
      </Box>

      <Box
        width={isLargeScreen ? "50%" : "93%"}
        p="2rem"
        m="2rem auto"
        borderRadius="1.5rem"
        backgroundColor={backgroundAlt}
        data-testid="box"
      >
        <Typography fontWeight="500" variant="h5" sx={{ mb: "1.5rem" }}>
          For Bold Women Who Want to Explore Their Sporting Potential
        </Typography>
        <Form />
      </Box>
    </Box>
  );
};

export default LoginPage;
