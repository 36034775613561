import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Friend from "components/Friend";
import WidgetWrapper from "components/WidgetWrapper";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchFriends } from "redux/state";
import { createSelector } from "reselect";

// Basic selectors to get parts of the state
const selectRawFriends = (state) => state.user.friends;
const selectCurrentUser = (state) => state.user;

// Memoized selector
const selectFilteredFriends = createSelector(
  [selectRawFriends, selectCurrentUser],
  (friends, currentUser) => {
    if (Array.isArray(friends)) {
      return friends.filter((friend) => friend._id !== currentUser._id);
    }
    return [];
  }
);

const FriendListWidget = ({ userId, socket }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { palette } = useTheme();
  const isLargeScreen = useMediaQuery("(min-width: 1000px)");

  const loggedInUserId = useSelector((state) => state.user._id);
  const viewingUserFriends =
    useSelector((state) => state.viewingUserFriends) || [];
  const loggedInUserFriends = useSelector(selectFilteredFriends) || [];

  const friends = Array.isArray(
    userId === loggedInUserId ? loggedInUserFriends : viewingUserFriends
  )
    ? userId === loggedInUserId
      ? loggedInUserFriends
      : viewingUserFriends
    : [];

  // Limit the number of friends displayed to 10
  const displayedFriends = friends.slice(0, isLargeScreen ? 10 : 5);

  const displayRemainingFriends = friends.slice(isLargeScreen ? 10 : 5);

  const viewMoreCount = isLargeScreen ? 10 : 5;

  useEffect(() => {
    const isViewingUser = userId !== loggedInUserId;
    dispatch(fetchFriends(userId, isViewingUser));
  }, [dispatch, userId, loggedInUserId]);

  if (friends.length === 0) {
    return null;
  }

  return (
    <WidgetWrapper data-testid="mock-friends">
      <Typography
        color={palette.neutral.dark}
        variant="h5"
        fontWeight="500"
        sx={{ mb: "1.5rem" }}
      >
        Friend List
      </Typography>
      <Box display="flex" flexDirection="column" gap="0.5rem">
        {displayedFriends.map((friend, index) => (
          <Friend
            key={index} // Consider using friend._id if possible
            friendId={friend._id}
            name={`${friend.firstName} ${friend.lastName}`}
            userPicturePath={friend.picturePath}
            socket={socket}
            friend={friend}
            imageSize="40px"
          />
        ))}
      </Box>
      <Box mt={1} display="flex" flexDirection="center">
        <AvatarGroup max={6}>
          {displayRemainingFriends.map((friend, index) => (
            <Tooltip
              title={`${friend.firstName} ${friend.lastName}`}
              key={index}
              arrow
            >
              <Avatar
                alt={friend.firstName}
                src={friend.picturePath}
                key={friend._id}
              />
            </Tooltip>
          ))}
        </AvatarGroup>
      </Box>
      {friends && friends.length > viewMoreCount && (
        <Button
          variant="text"
          sx={{ color: palette.primary.dark, textTransform: "none" }}
          onClick={() => {
            // Navigate to the friends page
            navigate(`/profile/${userId}/friends`);
          }}
        >
          View All
        </Button>
      )}
    </WidgetWrapper>
  );
};

export default FriendListWidget;
