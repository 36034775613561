import {
  Box,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Pagination,
  Popover,
  Select,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdvancedSearchSports } from "redux/actions/sportActions";
import searchLight from "assets/light/search.png";
import searchDark from "assets/dark/search.png";
import { ArrowDropDownCircleOutlined } from "@mui/icons-material";
import SportsCard from "./sportsCard";

function GetSports() {
  const dispatch = useDispatch();

  const { palette } = useTheme();
  const neutralLight = palette.neutral.light;
  const mode = palette.mode;

  const isLargeScreen = useMediaQuery("(min-width:1000px)");
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(""); // Sort options should match with the backend
  const [recordsPerPage, setRecordsPerPage] = useState(20);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  //eslint-disable-next-line
  const [totalRecords, setTotalRecords] = useState(0);
  const sports = useSelector(
    (state) => state.advancedSearchSports.sports || []
  );
  // State and handler for the popover
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    const fetchAndUpdateSports = async () => {
      const response = await dispatch(
        fetchAdvancedSearchSports({
          page: page,
          limit: recordsPerPage,
          sort: sort,
          search: search,
        })
      );

      setTotalRecords(response.total); // Set the total records.
      setTotalPages(Math.ceil(response.total / recordsPerPage)); // Calculate total pages.
    };

    fetchAndUpdateSports();
  }, [dispatch, page, recordsPerPage, sort, search]);

  useEffect(() => {
    setPage(1);
  }, [recordsPerPage]);

  const CustomFormControl = styled(FormControl)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
      borderRadius: "9px",
      backgroundColor: neutralLight,
      borderColor: "transparent",
      "&:hover fieldset": { borderColor: "transparent" }, // Add this line to remove the border on hover
      "&.Mui-focused fieldset": { borderColor: "transparent" }, // Add this line to remove the border when focused
    },
    "& .MuiInputLabel-root": {
      marginLeft: "0.5rem",
    },
  }));

  const handleChangePage = (event, newValue) => {
    setPage(newValue);
  };

  return (
    <Box m={isLargeScreen ? "1.5rem 2rem 2rem 3rem" : "2rem 0 0 0"}>
      <Box mb={2}>
        {/* Search Box */}
        <Box
          display={isLargeScreen ? "flex" : "block"}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            display={isLargeScreen ? "flex" : "block"}
            alignItems="centre"
            gap={2}
          >
            <FlexBetween
              backgroundColor={neutralLight}
              borderRadius="9px"
              gap="3rem"
              padding="0.1rem 1.5rem"
              height="35px"
              width="200px"
            >
              <InputBase
                placeholder="Search..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <IconButton>
                <img
                  src={mode === "light" ? searchLight : searchDark}
                  alt="search"
                  style={{
                    cursor: "pointer",
                    height: "1rem",
                    width: "auto",
                  }}
                />
              </IconButton>
            </FlexBetween>

            {/* Sort Dropdown */}
            <Box m={isLargeScreen ? 0 : "0.5rem 0"}>
              <CustomFormControl size="small" sx={{ width: 150 }}>
                <InputLabel id="sort-label">Sort</InputLabel>
                <Select
                  labelId="sort-label"
                  value={sort}
                  label="Sort"
                  onChange={(e) => setSort(e.target.value)}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="name:asc">Name Ascending</MenuItem>
                  <MenuItem value="name:desc">Name Descending</MenuItem>
                  {/* Add more sorting options as needed */}
                </Select>
              </CustomFormControl>
            </Box>
          </Box>

          {/* Records Per Page, Page Count and Pagination controls */}
          <Box
            display="flex"
            flexDirection={isLargeScreen ? "row" : "column"}
            alignItems={isLargeScreen ? "center" : "flex-start"}
            gap={2}
          >
            {/* Sports Per Page and Page Count */}
            <Box display="flex" alignItems="center" gap={2}>
              <Box display="flex" alignItems="center" gap={2}>
                <span>Sports Per Page - {recordsPerPage}</span>
                <IconButton onClick={handlePopoverOpen}>
                  <ArrowDropDownCircleOutlined />
                </IconButton>
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <Box p={2}>
                    <MenuItem
                      onClick={() => {
                        setRecordsPerPage(20);
                        handlePopoverClose();
                      }}
                    >
                      20
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setRecordsPerPage(40);
                        handlePopoverClose();
                      }}
                    >
                      40
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setRecordsPerPage(60);
                        handlePopoverClose();
                      }}
                    >
                      60
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setRecordsPerPage(80);
                        handlePopoverClose();
                      }}
                    >
                      80
                    </MenuItem>
                    {/* Add more options as needed */}
                  </Box>
                </Popover>
              </Box>
              <Box>
                <span>{`Page ${page} / ${totalPages}`}</span>
              </Box>
            </Box>

            {/* Pagination controls */}
            {isLargeScreen ? (
              <Box display="flex" alignItems="center" gap={2}>
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handleChangePage}
                  showFirstButton
                  showLastButton
                />
              </Box>
            ) : (
              <Box mt={2}>
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handleChangePage}
                  showFirstButton
                  showLastButton
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {/* Sports Grid */}
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)", // small devices, 1 card per row
          sm: "repeat(2, 1fr)", // medium devices, 2 cards per row
          md: "repeat(3, 1fr)", // medium-large devices, 3 cards per row
          lg: "repeat(4, 1fr)", // large devices, 4 cards per row
          xl: "repeat(5, 1fr)", // extra large devices, 5 cards per row
        }}
        justifyContent="space-between"
        rowGap="20px"
        columnGap="1.33%"
        mt="1.5rem"
        sx={{
          "& > div": {
            gridColumn: {
              xs: "span 1",
              sm: "span 1",
              md: "span 1",
              lg: undefined,
            },
          },
        }}
      >
        {sports.map((sport) => (
          <SportsCard key={sport._id} sport={sport} />
        ))}
      </Box>
    </Box>
  );
}

export default GetSports;
