import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import Header from "../components/Header";
import RolesGrid from "./components/RolesGrid";
import useRoles from "./hooks/useRoles";
import FlexBetween from "components/FlexBetween";
import { PlaylistAdd } from "@mui/icons-material";
import RoleForm from "./components/RoleForm";

function Roles() {
  const {
    backgroundAlt,
    primaryLight,
    rolesWithCount,
    columns,
    isLargeScreen,
    image,
    open,
    formErrors,
    fileInputRef,
    defaultImageURL,
    formData,
    handleChange,
    handleOpen,
    handleClose,
    handleFileChange,
    handleSubmit,
    handleEdit,
    handleEditClick,
    setFormData,
  } = useRoles();

  return (
    <Box
      m={isLargeScreen ? "1.5rem 2rem 2rem 3rem" : "1.5rem 0.5rem 2rem 0.5rem"}
    >
      <FlexBetween>
        <Header title="ROLES" subtitle="All platform roles" />

        <Button
          variant="contained"
          color="primary"
          onClick={handleOpen}
          sx={{
            borderRadius: "10px",
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
        >
          <PlaylistAdd sx={{ mr: "0.5rem" }} />
          Add Role
        </Button>
      </FlexBetween>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add a New Role</DialogTitle>
        <DialogContent>
          <RoleForm
            formData={formData}
            handleChange={handleChange}
            image={image}
            handleEditClick={handleEditClick}
            handleFileChange={handleFileChange}
            formErrors={formErrors}
            setFormData={setFormData}
            defaultImageURL={defaultImageURL}
            fileInputRef={fileInputRef}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <RolesGrid
        rolesWithCount={rolesWithCount}
        columns={columns}
        backgroundAlt={backgroundAlt}
        primaryLight={primaryLight}
        // handleEdit={handleEdit}
      />
    </Box>
  );
}

export default Roles;
