import { useState } from "react";
import { Box, TextField, Button, Typography, Snackbar } from "@mui/material";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  baseURL: BASE_URL,
});

const PasswordResetForm = () => {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post(`${BASE_URL}/auth/initiate-password-reset`, { email });
      setSuccessMessage("Password reset email sent. Check your inbox.");
      setErrorMessage("");

      setTimeout(() => {
        setIsEmailSent(true);
      }, 4000);
    } catch (error) {
      console.error("Error sending password reset email:", error);
      setErrorMessage("Error sending password reset email.");
      setSuccessMessage("");
    }
    setSnackbarOpen(true);
  };

  if (isEmailSent) {
    return null;
  }

  // modified handleChange function to convert email to lowercase
  const handleEmailChange = (e) => {
    setEmail(e.target.value.toLowerCase());
  };

  return (
    <Box marginTop="30px">
      <Typography variant="h5">Request Password Reset</Typography>
      <TextField
        label="Email"
        type="email"
        value={email}
        onChange={handleEmailChange}
        required
        fullWidth
        margin="normal"
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        style={{ marginTop: "20px" }}
        onClick={handleSubmit}
      >
        Request Password Reset
      </Button>
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={successMessage || errorMessage}
        autoHideDuration={4000}
      />
    </Box>
  );
};

export default PasswordResetForm;
