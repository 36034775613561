import { Box, useMediaQuery, useTheme } from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import ActionButtons from "scenes/SettingsAndPrivacy/profileEdit/components/ActionButtons";
import {
  AboutMeField,
  AgeRangeField,
  EmailField,
  NameFields,
} from "scenes/SettingsAndPrivacy/profileEdit/components/CustomTextFields";
import ErrorMessage from "scenes/SettingsAndPrivacy/profileEdit/components/ErrorMessage";
import SportsField from "scenes/SettingsAndPrivacy/profileEdit/components/SportsField";
import updateUserSchema from "scenes/SettingsAndPrivacy/profileEdit/schemas/userSchema";
import { Formik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { patchUser, setLocations, setSports } from "redux/state";
import LocationAutocomplete from "scenes/loginPage/FormComponents/FormLocation";
import { useNavigate } from "react-router-dom";
import UserProfileImage from "./components/UserProfileImage";
import FlexBetween from "components/FlexBetween";

const experienceLevels = ["Beginner", "Intermediate", "Advanced", "Expert"];
const frequencyUnits = ["day", "week", "month", "year"];

function UserProfileEdit() {
  const dispatch = useDispatch(); // get the dispatch function
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.user); // get the user from the state

  const initialValues = {
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
    email: currentUser.email,
    picturePath: currentUser.picturePath,
    ageRange: currentUser.ageRange,
    aboutMe: currentUser.aboutMe,
    location: currentUser.location,
    sports: currentUser.sports || [],
    friends: currentUser.friends || [],
    following: currentUser.following || [],
  };

  const [updateError, setUpdateError] = useState("");
  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  const handleFormSubmit = async (values, onSubmitProps) => {
    try {
      await dispatch(patchUser(currentUser._id, values));

      // Clear the sports and locations in the store
      dispatch(setSports([]));
      dispatch(setLocations([]));

      onSubmitProps.resetForm();
      setUpdateError("");

      navigate(`/profile/${currentUser._id}`);
    } catch (error) {
      setUpdateError("Error updating user. Please try again.");
    }
  };

  const handleCancel = () => {
    navigate("/settings");
  };

  const theme = useTheme();

  return (
    <Box
      flexBasis={isLargeScreen ? "60%" : undefined}
      px={2}
      pb={isLargeScreen ? 0 : "4rem"}
    >
      {/* Edit User Profile */}
      <WidgetWrapper>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={updateUserSchema}
        >
          {(formProps) => (
            <form onSubmit={formProps.handleSubmit}>
              <UserProfileImage formProps={formProps} />
              <NameFields formProps={formProps} />
              <FlexBetween gap="10px">
                <EmailField formProps={formProps} />
                <AgeRangeField formProps={formProps} />
              </FlexBetween>
              <AboutMeField formProps={formProps} />
              <LocationAutocomplete
                formProps={formProps}
                setFieldValue={formProps.setFieldValue}
                handleBlur={formProps.handleBlur}
                touched={formProps.touched || {}}
                errors={formProps.errors || {}}
                values={formProps.values || {}}
              />

              <SportsField
                formProps={formProps}
                experienceLevels={experienceLevels}
                frequencyUnits={frequencyUnits}
                theme={theme}
              />
              <ErrorMessage error={updateError} />
              <ActionButtons
                isSubmitting={formProps.isSubmitting}
                onCancel={handleCancel}
              />
            </form>
          )}
        </Formik>
      </WidgetWrapper>
    </Box>
  );
}

export default UserProfileEdit;
