import {
  setUserSearchResults,
  setUserSearchCriteria,
  fetchUser,
  setFilteredMembers,
} from "redux/state";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const searchUsers =
  (searchTerm, page, limit) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      const response = await fetch(
        `${BASE_URL}/search/?searchTerm=${searchTerm}&page=${page}&limit=${limit}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const data = await response.json();
      dispatch(setUserSearchResults(data));
      return data;
    } catch (error) {
      console.log(error);
    }
  };

export const addToSearchHistory =
  (userId, itemType, itemId) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      const response = await fetch(`${BASE_URL}/search/history`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ userId, itemType, itemId }),
      });

      if (!response.ok) {
        throw new Error("Failed to add to search history");
      }
    } catch (error) {
      console.error("Error adding to search history:", error);
    }
  };

export const getSearchHistory = (userId) => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/search/history/${userId}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error getting search history:", error);
  }
};

export const removeFromSearchHistory =
  (userId, itemType, itemId) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      const response = await fetch(
        `${BASE_URL}/search/history/${userId}/${itemType}/${itemId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to remove item from search history");
      }
    } catch (error) {
      console.error("Error removing item from search history:", error);
    }
  };

export const fetchUserSearchCriteria =
  (userId) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      const response = await fetch(
        `${BASE_URL}/search/userSearchCriteria/${userId}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const data = await response.json();
      dispatch(setUserSearchCriteria({ userSearchCriteria: data }));
      return data;
    } catch (error) {
      console.error("Error fetching user search criteria:", error);
    }
  };

export const updateUserSearchCriteria =
  (userId, searchCriteria) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      const response = await fetch(
        `${BASE_URL}/search/userSearchCriteria/${userId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ searchCriteria: searchCriteria }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update user search criteria");
      }

      if (response.ok) {
        dispatch(setUserSearchCriteria({ userSearchCriteria: searchCriteria }));
        dispatch(fetchUser(userId));
      }

      return response;
    } catch (error) {
      console.error("Error updating user search criteria:", error);
    }
  };

export const fetchFilteredUsers =
  (filters, page = 1, limit = 10) =>
  async (dispatch, getState) => {
    try {
      const token = getState().token;
      const response = await fetch(
        `${BASE_URL}/search/searchUsers?page=${page}&limit=${limit} `, // This might need to adjust based on your actual API.
        {
          method: "POST", // Assuming the method should be GET for fetching data
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(filters),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message);
      }

      // Set the filtered members in the store
      dispatch(setFilteredMembers(data));

      // Return the count so that the calling function can use it
      return data;
    } catch (error) {
      console.error("Error fetching user count:", error);
      return 0; // Return 0 or some error indicator as needed
    }
  };
