import { setDashboardAdminArticles } from "redux/state";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const fetchTotalArticles = () => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(
    `${BASE_URL}/dashboardArticles/totalPublishedArticles`,
    {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  const data = await response.json();

  dispatch(setDashboardAdminArticles(data));
  return data;
};

export const fetchArticlesEndOfLastMonth = () => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(
    `${BASE_URL}/dashboardArticles/articlesEndOfLastMonth`,
    {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  const data = await response.json();

  dispatch(setDashboardAdminArticles(data));
  return data;
};

export const fetchArticleReadsThisMonth = () => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(
    `${BASE_URL}/dashboardArticles/articleReadsThisMonth`,
    {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  const data = await response.json();

  dispatch(setDashboardAdminArticles(data));
  return data;
};

export const fetchArticleReadsLastMonth = () => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(
    `${BASE_URL}/dashboardArticles/articleReadsLastMonth`,
    {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  const data = await response.json();

  dispatch(setDashboardAdminArticles(data));
  return data;
};

export const fetchTotalArticleLikesThisMonth =
  () => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/dashboardArticles/likesForArticlesPublishedThisMonth`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();

    dispatch(setDashboardAdminArticles(data));
    return data;
  };

export const fetchTotalArticleLikesLastMonth =
  () => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/dashboardArticles/likesForArticlesPublishedLastMonth`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();

    dispatch(setDashboardAdminArticles(data));
    return data;
  };

export const fetchArticleCommentsThisMonth =
  () => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/dashboardArticles/commentsThisMonth`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();

    dispatch(setDashboardAdminArticles(data));
    return data;
  };

export const fetchArticleCommentsLastMonth =
  () => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/dashboardArticles/commentsLastMonth`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();

    dispatch(setDashboardAdminArticles(data));
    return data;
  };
