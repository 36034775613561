import { IconButton, Badge } from "@mui/material";
import { MessageOutlined, Menu as MenuIcon } from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";
import NotificationBadge from "./components/NotificationBadge";
import UserDropdown from "./components/UserDropdown";
import logoLight from "assets/light/SIA_Logo.png";
import logoDark from "assets/dark/SIA_Logo.png";

import { useNavState } from "./hooks/useNavState";
import SearchBar from "./components/SearchBar";
import SearchSideDrawer from "./components/SearchSideDrawer";
import NotificatonSideDrawer from "./components/NotificaitonSideDrawer";

const Navbar = ({
  socket,
  isSideBarOpen = false,
  setIsSideBarOpen,
  isAdminPage = false,
}) => {
  const {
    user,
    navigate,
    isLargeScreen,
    theme,
    primary,
    primaryLight,
    primaryDark,
    backgroundAlt,
    neutralLight,
    mediumLight,
    alt,
    fullName,
    profilePic,
    unreadMessagesCount,
    handleProfileClick,
    handleGroupsClick,
    handleNewsClick,
    handleFindMembersClick,
    handleChatClick,
    handleBrandClick,
    handleAdminClick,
    handleBrandDirectoryClick,
    handlePostFeedPageClick,
    handleSportDirectoryClick,
    handleSettingsClick,
    isSearchSidebarOpen,
    setIsSearchSidebarOpen,
    mode,
    searchLight,
    searchDark,
    searchTerm,
    handleSearch,
    searchResults,
    page,
    setPage,
    totalPages,
    handleAddToSearchHistory,
    searchHistory,
    handleRemoveSearchHistoryItem,
    isNotificationSidebarOpen,
    setIsNotificationSidebarOpen,
    notifications,
    unreadNotificationsCount,
    handleMarkAllOtherNotificationsAsRead,
    handleLoadMoreNotifications,
  } = useNavState({ socket, isSideBarOpen, setIsSideBarOpen, isAdminPage });

  return (
    <>
      <SearchSideDrawer
        isSearchSidebarOpen={isSearchSidebarOpen}
        setIsSearchSidebarOpen={setIsSearchSidebarOpen}
        primary={primary}
        backgroundAlt={backgroundAlt}
        neutralLight={neutralLight}
        isLargeScreen={isLargeScreen}
        mode={mode}
        searchLight={searchLight}
        searchDark={searchDark}
        searchTerm={searchTerm}
        handleSearch={handleSearch}
        searchResults={searchResults}
        primaryLight={primaryLight}
        primaryDark={primaryDark}
        navigate={navigate}
        user={user}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        handleAddToSearchHistory={handleAddToSearchHistory}
        searchHistory={searchHistory}
        handleRemoveSearchHistoryItem={handleRemoveSearchHistoryItem}
      />
      <NotificatonSideDrawer
        isNotificationSidebarOpen={isNotificationSidebarOpen}
        setIsNotificationSidebarOpen={setIsNotificationSidebarOpen}
        primary={primary}
        backgroundAlt={backgroundAlt}
        neutralLight={neutralLight}
        isLargeScreen={isLargeScreen}
        mode={mode}
        primaryLight={primaryLight}
        primaryDark={primaryDark}
        navigate={navigate}
        user={user}
        notifications={notifications}
        handleMarkAllOtherNotificationsAsRead={
          handleMarkAllOtherNotificationsAsRead
        }
        unreadNotificationsCount={unreadNotificationsCount}
        handleLoadMoreNotifications={handleLoadMoreNotifications}
      />
      <FlexBetween
        padding={isAdminPage ? "1rem 6% 1rem 1%" : "1rem 6%"}
        backgroundColor={alt}
        marginBottom="1rem"
      >
        <FlexBetween gap="1.75rem">
          {isAdminPage && ( // The IconButton will only be rendered if we're on the admin page.
            <IconButton onClick={() => setIsSideBarOpen(!isSideBarOpen)}>
              <MenuIcon />
            </IconButton>
          )}
          <img
            src={theme.palette.mode === "light" ? logoLight : logoDark}
            alt="SHE IS ACTION"
            onClick={() => navigate("/home")}
            style={{
              cursor: "pointer",
              height: "40px",
              width: "auto",
            }}
          />
          {/* Saerch bar might be added later */}
          {isLargeScreen && (
            <SearchBar
              isLargeScreen={isLargeScreen}
              isSearchSidebarOpen={isSearchSidebarOpen}
              setIsSearchSidebarOpen={setIsSearchSidebarOpen}
            />
          )}
        </FlexBetween>

        {/* DESKTOP NAV */}
        {isLargeScreen ? (
          <FlexBetween gap="2rem">
            <Badge badgeContent={unreadMessagesCount} color="error">
              <MessageOutlined
                sx={{ fontSize: "25px" }}
                onClick={handleChatClick}
              />
            </Badge>

            {/* Notificaitons */}
            <NotificationBadge
              socket={socket}
              isLargeScreen={isLargeScreen}
              isNotificationSidebarOpen={isNotificationSidebarOpen}
              setIsNotificationSidebarOpen={setIsNotificationSidebarOpen}
              unreadNotificationsCount={unreadNotificationsCount}
            />

            {/* <HelpOutlineOutlined sx={{ fontSize: "25px" }} /> */}

            <UserDropdown
              profilePic={profilePic}
              fullName={fullName}
              handleGroupsClick={handleGroupsClick}
              handleProfileClick={handleProfileClick}
              handleChatClick={handleChatClick}
              handleAdminClick={handleAdminClick}
              handleNewsClick={handleNewsClick}
              handleFindMembersClick={handleFindMembersClick}
              handleBrandClick={handleBrandClick}
              handleBrandDirectoryClick={handleBrandDirectoryClick}
              handlePostFeedPageClick={handlePostFeedPageClick}
              handleSportDirectoryClick={handleSportDirectoryClick}
              handleSettingsClick={handleSettingsClick}
              primary={primary}
              neutralLight={neutralLight}
              mediumLight={mediumLight}
            />
          </FlexBetween>
        ) : (
          <FlexBetween gap="10px">
            {/* Search bar might be added later */}
            {!isLargeScreen && (
              <SearchBar
                isLargeScreen={isLargeScreen}
                isSearchSidebarOpen={isSearchSidebarOpen}
                setIsSearchSidebarOpen={setIsSearchSidebarOpen}
              />
            )}

            <NotificationBadge
              socket={socket}
              isLargeScreen={isLargeScreen}
              isNotificationSidebarOpen={isNotificationSidebarOpen}
              setIsNotificationSidebarOpen={setIsNotificationSidebarOpen}
              unreadNotificationsCount={unreadNotificationsCount}
            />

            <UserDropdown
              profilePic={profilePic}
              fullName={fullName}
              handleGroupsClick={handleGroupsClick}
              handleProfileClick={handleProfileClick}
              handleChatClick={handleChatClick}
              handleAdminClick={handleAdminClick}
              handleNewsClick={handleNewsClick}
              handleFindMembersClick={handleFindMembersClick}
              handleBrandClick={handleBrandClick}
              handleBrandDirectoryClick={handleBrandDirectoryClick}
              handlePostFeedPageClick={handlePostFeedPageClick}
              handleSportDirectoryClick={handleSportDirectoryClick}
              handleSettingsClick={handleSettingsClick}
              primary={primary}
              neutralLight={neutralLight}
              mediumLight={mediumLight}
            />
          </FlexBetween>
        )}
      </FlexBetween>
    </>
  );
};

export default Navbar;
