import WidgetWrapper from "components/WidgetWrapper";
import React from "react";
import StoriesJustForYouSegmentTitle from "./components/StoriesJustForYouSegmentTitle";
import useStoriesJustForYouState from "./hooks/useStoriesJustForYouState";
import StoriesJustForYouThreeStoryCards from "./components/StoriesJustForYouThreeStoryCards";
import StoriesJustForYouNoStories from "./components/StoriesJustForYouNoStories";
import StoriesJustForYouExploreButton from "./components/StoriesJustForYouExploreButton";

function StoriesJustForYou() {
  const storiesState = useStoriesJustForYouState();

  // If there are no stories, return nothing or a placeholder
  if (!storiesState || storiesState.latestFollowedBrandArticlesCount === 0) {
    return <StoriesJustForYouNoStories />;
  }

  const {
    latestFollowedBrandArticles,
    primary,
    primaryDark,
    neutralLight,
    neutralMedium,
    isLargeScreen,
    mode,
  } = storiesState;

  return (
    <WidgetWrapper sx={{ padding: isLargeScreen ? undefined : "1.5rem 0" }}>
      <StoriesJustForYouSegmentTitle isLargeScreen={isLargeScreen} />

      <StoriesJustForYouThreeStoryCards
        latestFollowedBrandArticles={latestFollowedBrandArticles}
        primary={primary}
        neutralLight={neutralLight}
        neutralMedium={neutralMedium}
      />
      <StoriesJustForYouExploreButton
        primary={primary}
        neutralLight={neutralLight}
        primaryDark={primaryDark}
        mode={mode}
      />
    </WidgetWrapper>
  );
}

export default StoriesJustForYou;
