import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { PlaylistAdd } from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";
import Header from "../components/Header";
import BrandForm from "./BrandCreateForm";
import BrandGrid from "./BrandGrid";
import useBrandCreateForm from "./BrandCreateForm/hooks/useBrandCreateForm";

function Directory() {
  const {
    handleChange,
    handleLocationChange,
    handleLocationInputChange,
    locationOptions,
    modules,
    formats,
    sportsList,
    selectedSport,
    setSelectedSport,
    location,
    formData,
    setFormData,
    image,
    logo,
    handleEditClick,
    handleFileChange,
    handleLogoChange,
    formErrors,
    fileInputRef,
    logoInputRef,
    handleSportChange,
    defaultImageURL,
    open,
    handleOpen,
    handleClose,
    handleSubmit,
    isLargeScreen,
    googleImages,
    handleGoogleImageSelect,
    locationSelected,
    setLocationSelected,
  } = useBrandCreateForm();

  const handleImageSelectClick = (url) => {
    handleGoogleImageSelect(url);
  };

  return (
    <Box
      m={isLargeScreen ? "1.5rem 2rem 2rem 3rem" : "1.5rem 0.5rem 2rem 0.5rem"}
    >
      <FlexBetween>
        <Header title="DIRECTORY" subtitle="All Brands" />
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpen}
          sx={{
            borderRadius: "10px",
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
        >
          <PlaylistAdd sx={{ mr: "0.5rem" }} />
          Add Brand
        </Button>
      </FlexBetween>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add a New Brand</DialogTitle>
        <DialogContent>
          <BrandForm
            handleChange={handleChange}
            handleLocationChange={handleLocationChange}
            handleLocationInputChange={handleLocationInputChange}
            locationOptions={locationOptions}
            location={location}
            modules={modules}
            formats={formats}
            sportsList={sportsList}
            selectedSport={selectedSport}
            setSelectedSport={setSelectedSport}
            formData={formData}
            setFormData={setFormData}
            image={image}
            logo={logo}
            handleEditClick={handleEditClick}
            handleFileChange={handleFileChange}
            handleLogoChange={handleLogoChange}
            formErrors={formErrors}
            fileInputRef={fileInputRef}
            logoInputRef={logoInputRef}
            handleSportChange={handleSportChange}
            defaultImageURL={defaultImageURL}
            googleImages={googleImages}
            handleGoogleImageSelect={handleImageSelectClick}
            locationSelected={locationSelected}
            setLocationSelected={setLocationSelected}
            isLargeScreen={isLargeScreen}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          {locationSelected && (
            <Button onClick={handleSubmit} color="primary">
              Add
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <BrandGrid />
    </Box>
  );
}

export default Directory;
