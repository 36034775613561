import { ModeEditOutlineOutlined } from "@mui/icons-material";
import { Box, Button, useTheme } from "@mui/material";
import React, { useRef } from "react";
import { handleImageUpload } from "redux/actions/imageUploadUtils";

function BrandLogoEdit({ brandData, setBrandData }) {
  const fileInputRef = useRef(null);

  const { palette } = useTheme();
  const neutralLight = palette.neutral.light;

  const defaultImageURL =
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/6d0f5d8b-96b6-48d8-7bf8-ba57dbcea500/512x512";

  const handleEditClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const uploadedImageUrl = await handleImageUpload(file);
        if (uploadedImageUrl) {
          setBrandData((prevData) => ({
            ...prevData,
            logoPath: uploadedImageUrl,
          }));
          // setImage(uploadedImageUrl); // This will update the displayed image
        } else {
          console.error("Image upload failed. Using default image.");
          setBrandData((prevData) => ({ ...prevData, image: defaultImageURL }));
        }
      } catch (error) {
        console.error("Image upload failed:", error);
        setBrandData((prevData) => ({ ...prevData, image: defaultImageURL }));
      }
    }
  };

  return (
    <Box marginBottom="1rem" display="flex" alignItems="center" gap={5}>
      <img
        src={brandData?.logoPath || defaultImageURL}
        alt={brandData?.name}
        style={{ width: "50px", height: "auto", borderRadius: "10px" }}
      />

      <Button
        onClick={handleEditClick}
        startIcon={<ModeEditOutlineOutlined />}
        style={{
          // position: "absolute", // Corrected from 'postion' to 'position'
          // bottom: "10px", // Adjusted values for better positioning
          // right: "10px", // Adjusted values for better positioning
          backgroundColor: neutralLight,
        }}
      >
        Edit Logo
      </Button>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </Box>
  );
}

export default BrandLogoEdit;
