import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import SnackbarComponent from "components/SnackbarComponent";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchFlags, updateFlag } from "redux/actions/flagActions";
import LaunchIcon from "@mui/icons-material/Launch";

import moment from "moment";

import DataGridCustomToolbar from "../../components/DataGridCustomToolbar";

import FlexBetween from "components/FlexBetween";
import { Link } from "react-router-dom";

function getInitailFlagState() {
  return {
    reason: "",
    reviewStatus: "",
    reviewNotes: "",
    reviewedBy: "",
    reviewedAt: "",
    resolution: "",
    resolvedNotes: "",
    resolvedBy: "",
    resolvedAt: "",
  };
}

function FlaggedGrid() {
  const dispatch = useDispatch();

  const { palette } = useTheme();
  const backgroundAlt = palette.background.alt;
  const primaryLight = palette.primary.light;

  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [sort, setSort] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [currentFlag, setCurrentFlag] = useState(getInitailFlagState());

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  const flags = useSelector((state) => state.flags.flags) || [];

  const totalRows = useSelector((state) => state.flags.total) || 0;

  const handleOpenDialog = (flag) => {
    setCurrentFlag(flag);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentFlag(getInitailFlagState());
  };

  const handleSubmit = (flag) => {
    // Update the flag
    dispatch(updateFlag(flag._id, flag)).then((data) => {
      if (data) {
        setSnackbarMessage("Flag updated successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setOpenDialog(false);
        setCurrentFlag(getInitailFlagState());
      } else {
        setSnackbarMessage("Failed to update flag");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    });
  };

  useEffect(() => {
    // Fetch flagged data
    dispatch(
      fetchFlags({
        page,
        limit: rowsPerPage,
        sort,
        search,
      })
    );
  }, [dispatch, page, rowsPerPage, sort, search]);

  if (!flags.length) {
    return <div>Loading...</div>; // Or any other loading state representation
  }

  const columns = [
    {
      field: "flagger",
      headerName: "Reporter",
      flex: 1,
      // valueGetter: (params) => params.flagger.firstName || "Loading...",
      valueGetter: (params) =>
        `${params.row.flagger.firstName} ${params.row.flagger.lastName}`,
      renderCell: (params) => {
        return (
          <Link to={`/profile/${params.row.flagger}`}>
            <Box
              display="flex"
              alignItems="center"
              sx={{ cursor: "pointer", "&:hover": { color: "red" } }}
              gap={0.5}
            >
              <Avatar
                src={params.row.flagger.picturePath}
                alt={params.value}
                sx={{ mr: 1, width: "30px", height: "30px" }}
              />
              {params.value}
              <LaunchIcon sx={{ fontSize: "1rem" }} />
            </Box>
          </Link>
        );
      },
    },

    {
      field: "contentType",
      headerName: "Content Type",
      flex: 0.75,
      renderCell: (params) => {
        let path = "/";
        switch (params.row.contentType) {
          case "Article":
            path = `/news/article/${params.row.parentContent._id}`;
            break;
          case "Post":
            path = `/post/${params.row.parentContent._id}`;
            break;
          case "ArticleComment":
            path = `/news/article/${params.row.parentContent._id}`;
            break;
          case "PostComment":
            path = `/post/${params.row.parentContent._id}`;
            break;
          case "User":
            path = `/profile/${params.row.contentId}`;
            break;
          case "Brand":
            path = `/brand/${params.row.contentId}`;
            break;
          default:
            break;
        }
        return (
          <Link to={path} style={{ textDecoration: "none", color: "inherit" }}>
            <Box
              display="flex"
              alignItems="center"
              sx={{ cursor: "pointer", "&:hover": { color: "red" } }}
              gap={0.5}
            >
              {params.value} <LaunchIcon sx={{ fontSize: "1rem" }} />
            </Box>
          </Link>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 0.75,
      valueFormatter: (params) =>
        params.value ? moment(params.value).format("Do MMM YY, HH:mm") : "",
    },
    { field: "reason", headerName: "Reason", flex: 0.75 },

    {
      field: "reviewStatus",
      headerName: "Review Status",
      flex: 0.5,
      cellClassName: (params) => {
        if (params.value === "Pending") return "status-pending";
        if (params.value === "Reviewed") return "status-reviewed";
        if (params.value === "Resolved") return "status-resolved";
        return ""; // default with no additional styling
      },
    },

    {
      field: "reviewedAt",
      headerName: "Reviewed At",
      flex: 0.75,
      valueFormatter: (params) =>
        params.value ? moment(params.value).format("Do MMM YY, HH:mm") : "",
    },
    {
      field: "resolution",
      headerName: "Resolution Status",
      flex: 0.5,
      cellClassName: (params) => {
        if (params.value === "Deleted") return "status-deleted";
        if (params.value === "Edited") return "status-edited";
        if (params.value === "Ignored") return "status-ignored";
        return ""; // default with no additional styling
      },
    },

    {
      field: "resolvedAt",
      headerName: "Resolved At",
      flex: 0.75,
      valueFormatter: (params) =>
        params.value ? moment(params.value).format("Do MMM YY, HH:mm") : "",
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      flex: 0.5,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenDialog(params.row)}
        >
          Review
        </Button>
      ),
    },
  ];

  return (
    <>
      <Box
        m={isLargeScreen ? "1.5rem 1rem 2rem 1rem" : "1.5rem 0 2rem 0"}
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            // border: "none",
          },
          "& .MuiDataGrid-cell": {
            // borderBottom: "none",
            whiteSpace: "normal",
            wordBreak: "break-word",
            overflow: "visible",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: backgroundAlt,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: backgroundAlt,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: primaryLight,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {},
          "& .MuiDataGrid-row": {
            height: "auto", // This ensures the row height adapts to content
          },
        }}
      >
        <DataGrid
          getRowId={(row) => {
            return row._id;
          }}
          rows={flags}
          columns={columns}
          pageSize={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
          page={page - 1}
          onPageChange={(newPage) => setPage(newPage + 1)}
          paginationMode="server"
          rowCount={totalRows} // You need to know the total count of records from the server for proper pagination
          // checkboxSelection
          disableSelectionOnClick
          autoHeight={true}
          density="compact"
          onSortModelChange={(model) => {
            if (model.length) {
              setSort(`${model[0].field}:${model[0].sort}`);
            } else {
              setSort("");
            }
          }}
          components={{
            Toolbar: DataGridCustomToolbar,
          }}
          componentsProps={{
            toolbar: {
              onSearch: (value) => {
                setSearch(value);
                setPage(0); // Reset page when a new search is executed
              },
              searchInput,
              setSearchInput,
              setSearch,
            },
          }}
        />
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" variant="h5">
          Review Flag
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="details"
            label="Description"
            type="text"
            fullWidth
            value={currentFlag ? currentFlag.details : ""}
            disabled
            multiline
            rows={4}
          />
          <FormControl
            fullWidth
            margin="dense"
            variant="outlined"
            sx={{
              marginBottom: "1rem",
            }}
          >
            <InputLabel id="review-status-label">
              Reason for Flagging
            </InputLabel>
            <Select
              labelId="review-status-label"
              label="Reason for Flagging"
              name="reason"
              value={currentFlag.reason || ""}
              onChange={(e) =>
                setCurrentFlag({ ...currentFlag, reason: e.target.value })
              }
            >
              <MenuItem value="Inappropriate">Inappropriate</MenuItem>
              <MenuItem value="Spam">Spam</MenuItem>
              <MenuItem value="Hate Speech">Hate Speech</MenuItem>
              <MenuItem value="Harassment">Harassment</MenuItem>
              <MenuItem value="Self-Harm">Self-Harm</MenuItem>
              <MenuItem value="Violence">Violence</MenuItem>
              <MenuItem value="False Information">False Information</MenuItem>
              <MenuItem value="Intellectual Property Violation">
                Intellectual Property Violation
              </MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>
          {/*  Reviewed Details */}
          Reviewed Details
          <TextField
            margin="dense"
            id="notes"
            label="Review Notes"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={currentFlag ? currentFlag.reviewNotes : ""}
            onChange={(e) =>
              setCurrentFlag({ ...currentFlag, reviewNotes: e.target.value })
            }
          />
          <FlexBetween gap={1} mb={2}>
            <FormControl
              fullWidth
              margin="dense"
              variant="outlined"
              sx={{ width: "50%" }}
            >
              <InputLabel id="review-status-label">Review Status</InputLabel>
              <Select
                labelId="review-status-label"
                label="Review Status"
                name="reviewStatus"
                value={currentFlag.reviewStatus || ""}
                onChange={(e) =>
                  setCurrentFlag({
                    ...currentFlag,
                    reviewStatus: e.target.value,
                  })
                }
              >
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Reviewed">Reviewed</MenuItem>
                <MenuItem value="Resolved">Resolved</MenuItem>
              </Select>
            </FormControl>

            <Box
              display="flex"
              flexDirection="column"
              gap={1}
              sx={{ width: "50%" }}
            >
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{ fontWeight: 600 }}
              >
                Reviewed At:{" "}
                {currentFlag
                  ? moment(currentFlag.reviewedAt).format("Do MMM YY, HH:mm")
                  : ""}
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{ fontWeight: 600 }}
              >
                Reviewed By:{" "}
                {/* {currentFlag ? userNames[currentFlag.reviewedBy] : ""} */}
                {currentFlag.reviewedBy.firstName &&
                currentFlag.reviewedBy.lastName
                  ? currentFlag.reviewedBy.firstName +
                    " " +
                    currentFlag.reviewedBy.lastName
                  : ""}
              </Typography>
            </Box>
          </FlexBetween>
          {/* Resolved Details */}
          Resolved Details
          <TextField
            margin="dense"
            id="resolvedNotes"
            label="Resolved Notes"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={currentFlag ? currentFlag.resolvedNotes : ""}
            onChange={(e) =>
              setCurrentFlag({ ...currentFlag, resolvedNotes: e.target.value })
            }
          />
          <FlexBetween gap={1}>
            <FormControl
              fullWidth
              margin="dense"
              variant="outlined"
              sx={{ width: "50%" }}
            >
              <InputLabel id="resolution-label">Resolution</InputLabel>
              <Select
                labelId="resolution-label"
                label="Resolution"
                name="resolution"
                value={currentFlag.resolution || ""}
                onChange={(e) =>
                  setCurrentFlag({ ...currentFlag, resolution: e.target.value })
                }
              >
                <MenuItem value="Deleted">Deleted</MenuItem>
                <MenuItem value="Edited">Edited</MenuItem>
                <MenuItem value="Ignored">Ignored</MenuItem>
              </Select>
            </FormControl>
            <Box
              display="flex"
              flexDirection="column"
              gap={1}
              sx={{ width: "50%" }}
            >
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{ fontWeight: 600 }}
              >
                Resolved At:{" "}
                {currentFlag
                  ? moment(currentFlag.resolvedAt).format("Do MMM YY, HH:mm")
                  : ""}
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{ fontWeight: 600 }}
              >
                Resolved By:{" "}
                {currentFlag.resolvedBy.firstName &&
                currentFlag.resolvedBy.lastName
                  ? currentFlag.resolvedBy.firstName +
                    " " +
                    currentFlag.resolvedBy.lastName
                  : ""}
              </Typography>
            </Box>
          </FlexBetween>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSubmit(currentFlag)} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
}

export default FlaggedGrid;
