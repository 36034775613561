import React from "react";
import WidgetWrapper from "components/WidgetWrapper";
import { Box, Typography, useTheme } from "@mui/material";
import { useGroupCreateContext } from "scenes/groups/GroupCreate/components/GroupCreateContext";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import SportsSoccerOutlinedIcon from "@mui/icons-material/SportsSoccerOutlined";
import WaterfallChartOutlinedIcon from "@mui/icons-material/WaterfallChartOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

function toProperCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function CreatePreview() {
  const { palette } = useTheme();
  const mainNeutral = palette.neutral.main;

  const InfoBox = ({ icon: Icon, children }) => (
    <Box
      gap="0.5rem"
      sx={{ display: "flex", alignItems: "center", color: mainNeutral }}
    >
      {Icon && <Icon />}
      {children}
    </Box>
  );

  const PrivacySetting = ({ icon: Icon, title, description }) => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        color: mainNeutral,
      }}
    >
      <Icon fontSize="large" />
      <Box>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body1">{description}</Typography>
      </Box>
    </Box>
  );

  const {
    groupName,
    privacy,
    visibility,
    selectedSport,
    selectedLocation,
    selectedFriends,
    experienceLevel,
  } = useGroupCreateContext();

  const imageUrl =
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/c6e6fdf2-721f-42fb-236b-8d7fd0111800/public";

  return (
    <>
      <WidgetWrapper>
        <Box width="100%">
          <Typography variant="h3" fontWeight="bold">
            Preview
          </Typography>

          <img
            src={imageUrl}
            alt="Group Shot of sports women"
            width="100%"
            height="auto"
            style={{
              borderRadius: "1rem",
              marginTop: "1rem",
              filter: "grayscale(100%)",
            }}
          />

          <InfoBox sx={{ color: mainNeutral }}>
            <Typography
              variant="h4"
              sx={{ color: mainNeutral }}
              margin="1rem 0 0.5rem 0"
            >
              {groupName.length > 0 ? groupName : "Group Name"}
            </Typography>
          </InfoBox>

          <InfoBox
            icon={privacy === "public" ? PublicOutlinedIcon : HttpsOutlinedIcon}
          >
            {`${toProperCase(privacy)} (${visibility}) group - ${
              selectedFriends.length + 1
            } member${selectedFriends.length === 0 ? "" : "s"}`}
          </InfoBox>

          {selectedSport && (
            <InfoBox icon={SportsSoccerOutlinedIcon}>
              Sport: {selectedSport}
            </InfoBox>
          )}
          {experienceLevel.length > 0 ? (
            <InfoBox icon={WaterfallChartOutlinedIcon}>
              Experience Level: {experienceLevel.join(", ")}
            </InfoBox>
          ) : undefined}
          {selectedLocation && (
            <InfoBox icon={LocationOnOutlinedIcon}>
              {selectedLocation.address}
            </InfoBox>
          )}
        </Box>
      </WidgetWrapper>

      {/* ABOUT and PRIVACY SETTINGS */}
      <WidgetWrapper margin="2rem 0">
        <Box width="100%">
          <Typography variant="h5" sx={{ color: mainNeutral }}>
            About
          </Typography>
        </Box>
        <Box width="100%">
          <Typography variant="h6" sx={{ color: mainNeutral }}>
            This is a group for people who
            {selectedSport
              ? ` take part in ${selectedSport}`
              : " are interested in sports"}
            {selectedLocation && ` in ${selectedLocation.address}`}
            {experienceLevel.length > 0 &&
              ` with ${experienceLevel.join(", ")} experience level`}
            .
          </Typography>
          {/* Privacy statement */}

          <Typography variant="h5" marginTop="15px" sx={{ color: mainNeutral }}>
            Privacy Settings
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              alignItems: "flex-start",
            }}
          >
            {privacy === "public" && (
              <>
                <PrivacySetting
                  icon={PublicOutlinedIcon}
                  title="Public"
                  description="This group is public, meaning that anyone can view the group's name, posts, and description."
                />
                <PrivacySetting
                  icon={VisibilityOutlinedIcon}
                  title="Visible"
                  description="This group will appear in search results, making it easy for people to find and join."
                />
              </>
            )}

            {privacy === "private" && (
              <>
                <PrivacySetting
                  icon={HttpsOutlinedIcon}
                  title="Private"
                  description="Membership is required to view any group content, including the group's name and description."
                />
                <PrivacySetting
                  icon={
                    visibility === "visible"
                      ? VisibilityOutlinedIcon
                      : VisibilityOffOutlinedIcon
                  }
                  title={toProperCase(visibility)}
                  description={`This group is ${
                    visibility === "visible"
                      ? "searchable by its members only"
                      : "not searchable or discoverable"
                  }.`}
                />
              </>
            )}
          </Box>
        </Box>
      </WidgetWrapper>
    </>
  );
}

export default CreatePreview;
