import { Avatar, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import { fetchUserRecommendations } from "redux/actions/userActions";

function RecommendedGrid() {
  const dispatch = useDispatch();
  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  const { palette } = useTheme();
  const backgroundAlt = palette.background.alt;
  const primaryLight = palette.primary.light;

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const userRecommendations = useSelector((state) => state.userRecommendations);
  const totalRows = useSelector((state) => state.userRecommendations.total);
  const data = userRecommendations.data;

  useEffect(() => {
    dispatch(fetchUserRecommendations(page, rowsPerPage));
  }, [dispatch, page, rowsPerPage]);

  const columns = [
    {
      field: "fullName",
      headerName: "Recommended by",
      flex: 1,
      valueGetter: (params) =>
        `${params.row.recommendedBy.firstName} ${params.row.recommendedBy.lastName}`,
      renderCell: (params) => {
        return (
          <Link to={`/profile/${params.row.recommendedBy.userId}`}>
            <Box
              display="flex"
              alignItems="center"
              sx={{ cursor: "pointer", "&:hover": { color: "red" } }}
              gap={0.5}
            >
              <Avatar
                src={params.row.recommendedBy.profilePicture}
                alt={params.value}
                sx={{ mr: 1, width: "30px", height: "30px" }}
              />
              {params.value}
              <LaunchIcon sx={{ fontSize: "1rem" }} />
            </Box>
          </Link>
        );
      },
    },
    {
      field: "recommendedEmail",
      headerName: "Recommended Email",
      flex: 1,
    },
    {
      field: "recommendedAt",
      headerName: "Recommended At",
      flex: 0.75,
      valueFormatter: (params) =>
        moment(params.value).format("Do MMM YY, HH:mm"), // For example, 'LL' will format like 'January 1, 2021'. Adjust format string as needed.
    },
    {
      field: "hasJoined",
      headerName: "Has Joined",
      flex: 0.5,
      // type: "boolean",
      valueGetter: (params) => (params.row.hasJoined ? "Yes" : "No"),
    },
    {
      field: "joinedAt",
      headerName: "When Joined",
      flex: 0.75,
      valueFormatter: (params) =>
        params.value ? moment(params.value).format("Do MMM YY, HH:mm") : "N/A",
    },
  ];

  return (
    <Box
      m={isLargeScreen ? "1.5rem 1rem 2rem 1rem" : "1.5rem 0 2rem 0"}
      mt="40px"
      height="75vh"
      sx={{
        "& .MuiDataGrid-root": {
          // border: "none",
        },
        "& .MuiDataGrid-cell": {
          // borderBottom: "none",
          whiteSpace: "normal",
          wordBreak: "break-word",
          overflow: "visible",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: backgroundAlt,
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: backgroundAlt,
        },
        "& .MuiDataGrid-footerContainer": {
          backgroundColor: primaryLight,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {},
        "& .MuiDataGrid-row": {
          height: "auto", // This ensures the row height adapts to content
        },
      }}
    >
      <DataGrid
        getRowId={(row) => `${row.recommendedBy.userId}-${row.recommendedAt}`}
        rows={data}
        columns={columns}
        pageSize={rowsPerPage}
        rowsPerPageOptions={[25, 50, 100]}
        pagination
        paginationMode="server"
        rowCount={totalRows}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
        autoHeight
      />
    </Box>
  );
}

export default RecommendedGrid;
