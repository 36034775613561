import { Button, IconButton, Box } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SnackbarComponent from "../SnackbarComponent";
import useAddComment from "./hooks/useAddComment";
import UserTagSuggestionComponent from "./components/UserTagSuggestionComponent";
import React, { useEffect } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";

const AddComment = ({
  postId,
  closeComments,
  socket,
  postUserId,
  refreshPosts,
}) => {
  const {
    comment,
    setComment,
    hover,
    setHover,
    hoverTarget,
    hoverForNewFriend,
    setHoverForNewFriend,
    hoverForNewFriendIndex,
    theme,
    hoverAddFriend,
    hoverTargetUser,
    user,
    showHandleSuggestion,
    handleCommentSubmit,
    handleSelectMentionUser,
    addFriend,
    snackbarOpen,
    setSnackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    displayUser,
    mediumMain,
    neutralLight,
    primaryLight,
    primaryDark,
    handleQuillChange,
    quillRef,
  } = useAddComment({ socket, postId, postUserId, refreshPosts });

  useEffect(() => {
    if (quillRef.current && !quillRef.current.editor) {
      quillRef.current.editor = new Quill(quillRef.current, {
        theme: "snow",
        modules: {
          toolbar: false,
        },
        placeholder: "Add a comment...",
      });

      quillRef.current.editor.on("text-change", () => {
        handleQuillChange(quillRef.current.editor.root.innerHTML);
      });

      quillRef.current.editor.root.innerHTML = comment;
    }
  }, [comment, handleQuillChange]);

  return (
    <>
      <form onSubmit={(e) => handleCommentSubmit(e, "commentOnPost")}>
        <Box
          flexGrow={1}
          sx={{
            borderRadius: "2rem",
            overflow: "hidden",
            border: "1px solid #ccc",
          }}
          mt={1}
          mb={1}
        >
          <div
            ref={quillRef}
            className="myPostWidgetQuill"
            style={{ border: "none" }}
          />
        </Box>
        {showHandleSuggestion && (
          <UserTagSuggestionComponent
            displayUser={displayUser}
            hover={hover}
            hoverTarget={hoverTarget}
            hoverTargetUser={hoverTargetUser}
            setHover={setHover}
            handleSelectMentionUser={handleSelectMentionUser}
            theme={theme}
            addFriend={addFriend}
            user={user}
            hoverForNewFriend={hoverForNewFriend}
            hoverForNewFriendIndex={hoverForNewFriendIndex}
            setHoverForNewFriend={setHoverForNewFriend}
            hoverAddFriend={hoverAddFriend}
            mediumMain={mediumMain}
            neutralLight={neutralLight}
            primaryLight={primaryLight}
            primaryDark={primaryDark}
          />
        )}
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
        <IconButton
          onClick={() => {
            closeComments();
            setComment("");
          }}
          aria-label="Close comment"
        >
          <CloseOutlinedIcon />
        </IconButton>
      </form>
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default AddComment;
