import { Box } from "@mui/material";
import React from "react";
import Header from "../components/Header";

function UserLeads() {
  return (
    <Box m="1.5rem 2rem 2rem 3rem">
      <Header title="BRAND LEADS" subtitle="User Generated Leads" />
    </Box>
  );
}

export default UserLeads;
