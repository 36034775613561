import { setUserRoles, setRolesWithCount } from "redux/state"; // Assuming you have this action in your redux state

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const assignRoleToUser =
  (userId, roleId) => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/userRoles`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId, roleId }),
    });
    const data = await response.json();
    // You can dispatch an action here if you want to update your redux state
    return data;
  };

export const fetchUserRoles = (userId) => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/${userId}/userRoles/roles`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();
  dispatch(setUserRoles(data)); // Update the roles for the user in the redux state
  return data;
};

export const removeUserRole =
  (userId, roleId) => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/userRoles/${userId}/role/${roleId}`,
      {
        method: "DELETE",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      // You can dispatch an action here to update your redux state by removing the roleId for the user
    }
    return response.json();
  };

export const fetchRolesWithCount = () => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/userRoles/rolesWithCount`, {
    // The URL should point to the backend route that returns roles with counts
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();
  dispatch(setRolesWithCount(data)); // Update the roles in the redux state
  return data;
};
