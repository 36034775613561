import { useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import SettignsAndPrivacySidebar from "../components/Sidebar";
import Navbar from "scenes/navbar";
import { Outlet } from "react-router-dom";
import BottomMenu from "components/bottomMenu/BottomMenu";

function SettingsAndPrivacy() {
  const isLargeScreen = useMediaQuery("(min-width:1000px)");
  const [isSideBarOpen, setIsSideBarOpen] = useState(
    isLargeScreen ? true : false
  );

  return (
    <Box display={isLargeScreen ? "flex" : "block"} width="100%" height="100%">
      <SettignsAndPrivacySidebar
        isLargeScreen={isLargeScreen}
        drawerWidth="250px"
        isSideBarOpen={isSideBarOpen}
        setIsSideBarOpen={setIsSideBarOpen}
      />
      <Box flexGrow={1}>
        <Navbar
          isSideBarOpen={isSideBarOpen}
          setIsSideBarOpen={setIsSideBarOpen}
          isAdminPage={true}
        />
        <Outlet />
      </Box>
      {!isLargeScreen && <BottomMenu />}
    </Box>
  );
}

export default SettingsAndPrivacy;
