import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { useSelector, useDispatch } from "react-redux"; // Import useDispatch
import {
  fetchNotifications,
  // addNotification,
} from "redux/actions/notificationActions"; // Make sure to import addNotification

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const useSocket = () => {
  const [socket, setSocket] = useState(null);
  const user = useSelector((state) => state.user) || {};
  const dispatch = useDispatch(); // Initialize useDispatch

  useEffect(() => {
    const newSocket = io(BASE_URL);
    setSocket(newSocket);
    return () => newSocket.close();
  }, []);

  useEffect(() => {
    if (user._id) {
      // Fetch existing notifications on initial load or when user changes
      dispatch(fetchNotifications(user._id));
    }
  }, [user._id, dispatch]);

  useEffect(() => {
    if (socket && user._id) {
      socket.emit("newUser", user._id);
      socket.on("getNotification", (notification) => {
        // Handle notification
        // Dispatch action to add the received notification to Redux store
        // dispatch(addNotification(notification));
      });

      return () => socket.off("getNotification");
    }
  }, [socket, user._id, dispatch]); // Add dispatch to the dependency array

  return socket;
};
