import React, { useEffect, useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserLocationsByCountry } from "redux/actions/dashboardUserActions";
import { ResponsiveChoropleth } from "@nivo/geo";
import { geoData } from "redux/state/geoData.js";
import { countryNameToCode } from "./countryNameToCode";

function Location() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const primary = theme.palette.primary.main;
  const primaryDark = theme.palette.primary.dark;
  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  const userLocations =
    useSelector((state) => state.dashboardAdminUser.userLocations) || [];

  const [transformedUserLocations, setTransformedUserLocations] = useState([]);

  useEffect(() => {
    dispatch(fetchUserLocationsByCountry());
  }, [dispatch]);

  useEffect(() => {
    const transformedData = userLocations.map((location) => ({
      id: countryNameToCode[location.country] || location.country,
      value: location.count,
    }));
    setTransformedUserLocations(transformedData);
  }, [userLocations]);

  const maxValue = transformedUserLocations.reduce(
    (max, loc) => (loc.value > max ? loc.value : max),
    0
  );

  return (
    <Box
      m={isLargeScreen ? "1.5rem 2rem 2rem 3rem" : "1.5rem 0.5rem 2rem 0.5rem"}
    >
      <Header title="LOCATIONS" subtitle="Find where your users are located" />
      <Box
        mt="40px"
        height="75vh"
        border={`1px solid ${primary}`}
        borderRadius="4px"
      >
        {transformedUserLocations.length > 0 ? (
          <ResponsiveChoropleth
            data={transformedUserLocations}
            features={geoData.features}
            theme={{ tooltip: { container: { background: primary } } }}
            margin={{ top: 0, right: 0, bottom: 0, left: -50 }}
            colors="nivo"
            domain={[0, maxValue]}
            unknownColor="#666666"
            label="properties.name"
            valueFormat=".2s"
            projectionScale={150}
            projectionTranslation={[0.45, 0.6]}
            projectionRotation={[0, 0, 0]}
            enableGraticule={true}
            graticuleLineColor="#dddddd"
            borderWidth={0.5}
            borderColor="#152538"
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "#38bcb2",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "#eed312",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
              {
                id: "gradient",
                type: "linearGradient",
                colors: [
                  {
                    offset: 0,
                    color: "#000",
                  },
                  {
                    offset: 100,
                    color: "inherit",
                  },
                ],
              },
            ]}
            legends={[
              {
                anchor: "bottom-right",
                direction: "column",
                justify: true,
                translateX: 0,
                translateY: -125,
                itemsSpacing: 0,
                itemWidth: 94,
                itemHeight: 18,
                itemDirection: "left-to-right",
                itemTextColor: primary,
                itemOpacity: 0.85,
                symbolSize: 18,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: primaryDark,
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <p>No user locations found</p>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Location;
