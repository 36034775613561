const BASE_URL = process.env.REACT_APP_BASE_URL;

// get all online users

export const getOnlineUsers = () => async (dispatch, getState) => {
  const token = getState().token;
  try {
    const response = await fetch(`${BASE_URL}/usersOnlineStatus`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch online users");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error fetching online users:", error);
  }
};
