import { Box, FormHelperText, Typography } from "@mui/material";
import React from "react";
import Dropzone from "react-dropzone";

function TitleImageDropZone({ titleImage, handleTitleImageUpload, error }) {
  return (
    <>
      <Dropzone onDrop={handleTitleImageUpload}>
        {({ getRootProps, getInputProps }) => (
          <Box
            {...getRootProps()}
            border="1px dashed"
            textAlign="center"
            height={200}
            display="flex"
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
            borderRadius="4px"
            m="1rem 0"
            style={{
              backgroundImage: `url(${titleImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <input {...getInputProps()} />
            <Typography
              variant="body2"
              sx={{
                backgroundColor: "rgba(255,255,255,0.7)",
                padding: "8px",
                borderRadius: "4px",
              }}
            >
              Drop title image here, or click to select image
            </Typography>
          </Box>
        )}
      </Dropzone>
      {error && (
        <FormHelperText error={true} style={{ margin: "8px 0" }}>
          {error}
        </FormHelperText>
      )}
    </>
  );
}

export default TitleImageDropZone;
