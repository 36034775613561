import { setDashboardBrand } from "redux/state";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const fetchTotalNumberOfFollowers =
  (brandId) => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/dashboardBrand/totalNumberOfFollowers/${brandId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();

    dispatch(setDashboardBrand(data));
    return data;
  };

export const fetchTotalNumberOfFollowersEndOfLastMonth =
  (brandId) => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/dashboardBrand/totalNumberOfFollowersEndOfLastMonth/${brandId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();

    dispatch(setDashboardBrand(data));
    return data;
  };

export const fetchTotalArticlesPublishedCount =
  (brandId) => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/dashboardBrand/totalArticlesPublishedCount/${brandId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();

    dispatch(setDashboardBrand(data));
    return data;
  };

export const fetchArticlesPublishedCountEndOfLastMonth =
  (brandId) => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/dashboardBrand/articlesPublishedCountLastMonth/${brandId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();

    dispatch(setDashboardBrand(data));
    return data;
  };

export const fetchTotalLikesForBrandArticles =
  (brandId) => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/dashboardBrand/totalArticleLikes/${brandId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();

    dispatch(setDashboardBrand(data));
    return data;
  };

export const fetchTotalLikesForBrandArticlesEndOfLastMonth =
  (brandId) => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/dashboardBrand/totalArticleLikesEndOfLastMonth/${brandId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();

    dispatch(setDashboardBrand(data));
    return data;
  };

export const fetchTotalCommentsForBrandArticles =
  (brandId) => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/dashboardBrand/totalArticleComments/${brandId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();

    dispatch(setDashboardBrand(data));
    return data;
  };

export const fetchTotalCommentsForBrandArticlesByEndOfLastMonth =
  (brandId) => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/dashboardBrand/totalArticleCommentsEndOfLastMonth/${brandId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();

    dispatch(setDashboardBrand(data));
    return data;
  };
