import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  DialogTitle,
  ButtonGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { submitQuestions, submitSports } from "redux/actions/authActions";
import FlexBetween from "components/FlexBetween";
import { fetchSports } from "redux/actions/sportActions";

const experienceLevels = ["Beginner", "Intermediate", "Advanced", "Expert"];

function VerificationEmailSent() {
  const location = useLocation();
  const userId = location.state?.userId;
  // const userId = "662b70caa9f77b58c66951b2";
  // console.log("🚀 ~ VerificationEmailSent ~ userId:", userId);

  const { palette } = useTheme();
  const backgroundAlt = palette.background.alt;
  const primary = palette.primary.main;
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery("(min-width: 1000px)");
  const dispatch = useDispatch();
  const sportsList = useSelector((state) => state.sports); // Adjust this line according to your actual state structure

  // State for opening/closing the Dialog
  const [openDialog, setOpenDialog] = useState(true);
  const [activeTab, setActiveTab] = useState("sports");

  const handleBackToLogin = () => {
    navigate("/");
  };

  // Questions array
  const questions = [
    { key: "whatIWant", question: "What I want to get out of this community?" },
    { key: "whatIOffer", question: "Something I can offer the community?" },
    { key: "adviceAbout", question: "Ask me for advice about:" },
    {
      key: "sportToTry",
      question: "A sport I'd really love to try and why I haven't yet?",
    },
    {
      key: "sportILove",
      question: "A sport I'm terrible at, but love and why?",
    },
    {
      key: "favoriteMemory",
      question: "My favourite sports or adventure memory?",
    },
    { key: "threeThings", question: "3 things you should know about me:" },
    {
      key: "funniestFail",
      question: "Let me tell you about my funniest sporting fail:",
    },
    { key: "hatedSports", question: "Things I hated about sport growing up?" },
    {
      key: "cantGoWithout",
      question: "Something I can't go anywhere without?",
    },
  ];

  // State to hold answers
  const [answers, setAnswers] = useState({
    whatIWant: "",
    whatIOffer: "",
    adviceAbout: "",
    sportToTry: "",
    sportILove: "",
    favoriteMemory: "",
    threeThings: "",
    funniestFail: "",
    hatedSports: "",
    cantGoWithout: "",
  });

  const [sportsAnswers, setSportsAnswers] = useState([
    {
      sport: "",
      experienceLevel: "Intermediate",
      frequency: { amount: 1, unit: "week" },
    },
  ]);

  const handleAnswerChange = (key, answer) => {
    setAnswers((prev) => ({
      ...prev,
      [key]: answer,
    }));
  };

  const handleSportsAnswerChange = (index, key, value) => {
    const updatedSports = sportsAnswers.map((item, i) => {
      if (i === index) {
        if (key === "frequency.amount" || key === "frequency.unit") {
          // Handle nested frequency object
          const [field, subKey] = key.split(".");
          return { ...item, [field]: { ...item[field], [subKey]: value } };
        }
        return { ...item, [key]: value };
      }
      return item;
    });
    setSportsAnswers(updatedSports);
  };

  const addSport = () => {
    setSportsAnswers(
      sportsAnswers.concat([
        {
          sport: "",
          experienceLevel: "Intermediate",
          frequency: { amount: 1, unit: "week" },
        },
      ])
    );
  };

  const removeSport = (index) => {
    setSportsAnswers(sportsAnswers.filter((_, i) => i !== index));
  };

  // Function to submit answers
  const handleSubmit = async () => {
    // Call the action to submit the answers
    dispatch(submitQuestions(userId, answers));

    if (
      sportsAnswers &&
      sportsAnswers.length > 0 &&
      sportsAnswers[0].sport !== "" &&
      sportsAnswers[0].experienceLevel !== ""
    ) {
      dispatch(submitSports(userId, sportsAnswers));
    }

    setOpenDialog(false); // Close the dialog after submission

    // Navigate to profile page or show a message upon successful submission
  };

  useEffect(() => {
    if (!sportsList.length) {
      dispatch(fetchSports());
    }
  }, [dispatch, sportsList.length]);

  // Function to render sports questions
  const renderSportsQuestions = () => (
    <>
      {sportsAnswers.map((sport, index) => (
        <Box key={index}>
          <FlexBetween gap={2}>
            <Autocomplete
              style={{ flex: 1 }}
              options={sportsList.map((s) => s.name)} // Assuming 'sportsList' is an array of objects with a 'name' property
              value={sport.sport}
              onChange={(event, newValue) => {
                handleSportsAnswerChange(index, "sport", newValue);
              }}
              renderInput={(params) => <TextField {...params} label="Sport" />}
            />

            <FormControl style={{ flex: 1 }}>
              <InputLabel
                id={`experienceLevel-label-${index}`}
                sx={{
                  backgroundColor: backgroundAlt,
                  padding: "0 5px",
                }}
              >
                Experience Level
              </InputLabel>
              <Select
                labelId={`experienceLevel-label-${index}`}
                value={sport.experienceLevel}
                onChange={(e) =>
                  handleSportsAnswerChange(
                    index,
                    "experienceLevel",
                    e.target.value
                  )
                }
                error={Boolean(sportsAnswers[index].experienceLevel === "")}
              >
                {experienceLevels.map((level) => (
                  <MenuItem key={level} value={level}>
                    {level}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FlexBetween>
          {/* Add frequency fields as necessary */}
          <Button
            onClick={() => removeSport(index)}
            color="error"
            sx={{
              marginBottom: "10px",
              padding: "2px 5px",
            }}
          >
            Remove
          </Button>
        </Box>
      ))}
      <Button onClick={addSport} color="primary">
        Add Another Sport
      </Button>
    </>
  );

  const renderPersonalQuestions = () => (
    <>
      {questions.map((questionObj) => (
        <Accordion key={questionObj.key} style={{ marginTop: "10px" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{questionObj.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              fullWidth
              multiline
              variant="outlined"
              label="Your answer"
              value={answers[questionObj.key]}
              onChange={(e) =>
                handleAnswerChange(questionObj.key, e.target.value)
              }
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );

  // Render the Dialog with Accordion
  const renderQuestionnaireDialog = () => (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      aria-labelledby="form-dialog-title"
      fullWidth
    >
      <DialogTitle>Welcome to SHE IS ACTION!</DialogTitle>
      <DialogContent dividers>
        <Typography
          gutterBottom
          variant="body1"
          style={{ marginBottom: "20px" }}
        >
          We're thrilled to have you join our community! 🎉
        </Typography>
        <Typography
          gutterBottom
          variant="body2"
          style={{ marginBottom: "20px" }}
        >
          To get the most out of{" "}
          <Typography variant="span" color={primary} fontWeight={600}>
            SHE IS ACTION
          </Typography>
          , why not share a little about yourself and the sports that you are
          interested in? This will help you connect with like-minded members and
          discover tailored content. However, don't worry if you're not ready to
          share just yet — you can always do so at a more convenient time.
        </Typography>
        <Typography gutterBottom variant="body2">
          Simply answer any of the questions below that resonate with you. We
          can't wait to get to know you better!
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          marginY="20px"
        >
          <ButtonGroup variant="text">
            <Button
              onClick={() => setActiveTab("sports")}
              color={activeTab === "sports" ? "primary" : "secondary"}
              fontWeight={activeTab === "sports" ? 700 : 400}
            >
              Sports Questions
            </Button>
            <Button
              onClick={() => setActiveTab("personal")}
              color={activeTab === "personal" ? "primary" : "secondary"}
              fontWeight={activeTab === "personal" ? 700 : 400}
            >
              Personal Questions
            </Button>
          </ButtonGroup>
        </Box>
        {activeTab === "personal"
          ? renderPersonalQuestions()
          : renderSportsQuestions()}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpenDialog(false);
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box>
      <Box
        width="100%"
        backgroundColor={backgroundAlt}
        p="1rem 6%"
        textAlign="center"
      >
        <Typography color={primary} variant="h1" gutterBottom>
          Welcome to SHE IS ACTION!
        </Typography>
        <Typography variant="h5" sx={{ mb: 2 }}>
          You've taken the first step into a community that empowers and unites
          through action.
        </Typography>
      </Box>
      <Box
        width={isLargeScreen ? "50%" : "93%"}
        p="2rem"
        m="2rem auto"
        borderRadius="1.5rem"
        backgroundColor={backgroundAlt}
        data-testid="box"
        textAlign="center"
      >
        <Typography variant="h2" color="primary">
          Verification Email Sent
        </Typography>
        <img
          width="300px"
          height="auto"
          src="https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/4be28a67-aa94-4a44-8874-370c0a072e00/public"
          alt="verification email sent"
          className="verification-image"
        />
        <Typography variant="h5" color="primary">
          A verification email has been sent to your inbox. Please follow the
          instructions in the email to verify your account.
        </Typography>
        <Box display="flex" flexDirection="column" gap={2} marginTop="20px">
          <Link to="/resend-activation">
            <Button
              variant="contained"
              color="primary"
              sx={{ width: 200, mx: "auto" }}
            >
              Resend Verification Link
            </Button>
          </Link>
          <Button
            variant="contained"
            color="secondary"
            sx={{ width: 200, mx: "auto" }}
            onClick={handleBackToLogin}
          >
            Back to Login Page
          </Button>
          <Typography variant="body2" color="primary" marginTop="50px">
            If you have any questions, please{" "}
            <Link to="/contact-support">
              <Typography variant="span" fontWeight={700}>
                contact support
              </Typography>
            </Link>
            .
          </Typography>
        </Box>
      </Box>
      {renderQuestionnaireDialog()}
    </Box>
  );
}

export default VerificationEmailSent;
