import { Box, FormControl, TextField, useTheme } from "@mui/material";
import React, { useEffect, useRef } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useSelector } from "react-redux";

const BrandCreateDetails = ({
  formData = {}, // Provide a default empty object
  formErrors = {}, // Provide a default empty object
  handleChange,
  handleBlur,
  modules,
  formats,
}) => {
  const { palette } = useTheme();
  const neutralDark = palette.neutral.dark;
  const mode = useSelector((state) => state.mode) || "light";

  const quillRef = useRef(null);
  const quillInstance = useRef(null);

  useEffect(() => {
    if (quillRef.current && !quillInstance.current) {
      quillInstance.current = new Quill(quillRef.current, {
        theme: "snow",
        modules: modules,
        formats: formats,
        placeholder: "Description",
      });

      quillInstance.current.on("text-change", () => {
        const currentContent = quillInstance.current.root.innerHTML;
        if (currentContent !== formData.description) {
          handleChange({
            target: {
              name: "description",
              value: currentContent,
            },
          });
        }
      });

      // Set initial value without triggering 'text-change' event
      quillInstance.current.clipboard.dangerouslyPasteHTML(
        formData.description
      );
    }
  }, [modules, formats, handleChange, formData.description]);

  useEffect(() => {
    if (quillInstance.current) {
      const currentContent = quillInstance.current.root.innerHTML;
      if (currentContent !== formData.description) {
        quillInstance.current.clipboard.dangerouslyPasteHTML(
          formData.description
        );
      }
    }
  }, [formData.description]);

  const handlePhoneChange = (phone) => {
    const formattedPhone = phone.startsWith("+") ? phone : `+${phone}`;
    handleChange({
      target: {
        name: "phone",
        value: formattedPhone,
      },
    });
  };

  return (
    <>
      <TextField
        autoFocus={true}
        margin="dense"
        label="Brand Name"
        name="name"
        value={formData.name || ""}
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth
        variant="outlined"
        error={!!formErrors.name}
        helperText={formErrors.name}
      />

      {/* Description */}
      <FormControl margin="dense" fullWidth variant="outlined">
        <div ref={quillRef} />
        {formErrors.description && (
          <p
            style={{
              color: "#d74343",
              fontSize: "10px",
              marginLeft: "1rem",
              fontWeight: 500,
              marginTop: "3px",
            }}
          >
            {formErrors.description}
          </p>
        )}
      </FormControl>

      {/* Phone Input Field */}
      <Box my={1}>
        <PhoneInput
          country={"gb"}
          preferredCountries={["gb", "us", "ca", "au", "nz", "ie", "za"]}
          value={formData.phone || ""}
          onChange={handlePhoneChange}
          inputProps={{
            name: "phone",
            required: true,
          }}
          inputStyle={{
            width: "100%",
            backgroundColor: mode === "light" ? undefined : "#383838",
            color: neutralDark,
            fontSize: 14,
            borderColor: formErrors.phone ? "#d74343" : undefined,
          }}
          error={!!formErrors.phone}
          helperText={formErrors.phone}
          international
          withCountryCallingCode
        />
        {formErrors.phone && (
          <p
            style={{
              color: "#d74343",
              fontSize: "10px",
              marginLeft: "1rem",
              fontWeight: 500,
              marginTop: "3px",
            }}
          >
            {formErrors.phone}
          </p>
        )}
      </Box>

      <TextField
        margin="dense"
        label="Website"
        name="website"
        value={formData.website || ""}
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth
        variant="outlined"
        error={!!formErrors.website}
        helperText={formErrors.website}
      />
    </>
  );
};

export default BrandCreateDetails;
