import { TextField } from "@mui/material";
import React from "react";

function TitleAndSubTitle({
  title,
  setTitle,
  subtitle,
  setSubtitle,
  formErrors,
}) {
  return (
    <>
      <TextField
        label="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        fullWidth
        margin="normal"
        error={Boolean(formErrors.title)}
        helperText={formErrors.title}
      />
      <TextField
        label="Subtitle"
        value={subtitle}
        onChange={(e) => setSubtitle(e.target.value)}
        fullWidth
        margin="normal"
        error={Boolean(formErrors.subtitle)}
        helperText={formErrors.subtitle}
      />
    </>
  );
}

export default TitleAndSubTitle;
