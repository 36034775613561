import {
  Avatar,
  Box,
  Button,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import SnackbarComponent from "components/SnackbarComponent";
import WidgetWrapper from "components/WidgetWrapper";
import AvatarRoleIcons from "components/avatarRoleIcons";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchMemberSuggestions } from "redux/actions/userActions";
import { patchFriend, setMemberSuggestions } from "redux/state";

function FindMembersMemberSuggestions({ socket }) {
  const userId = useSelector((state) => state.user._id);
  const dispatch = useDispatch();

  const { palette } = useTheme();
  const dark = palette.neutral.dark;
  const mainSecondaryDark = palette.secondary.dark;
  const mode = useSelector((state) => state.mode) || "light";

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  const memberSuggestions = useSelector(
    (state) => state.memberSuggestions || []
  );

  useEffect(() => {
    try {
      dispatch(fetchMemberSuggestions(userId)); // dispatch the fetchNonFriends action
    } catch (error) {
      console.error(error);
      dispatch(setMemberSuggestions([]));
      setSnackbarMessage("An error occurred, please try again later");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  }, [dispatch, userId]);

  const handleAddFriend = async (nonFriendId) => {
    try {
      if (userId && nonFriendId) {
        const response = await dispatch(patchFriend(userId, nonFriendId)); // dispatch the patchFriend action

        if (response) {
          // Filter out the added friend from memberSuggestions
          dispatch(
            setMemberSuggestions(
              memberSuggestions.filter((member) => member._id !== nonFriendId)
            )
          );
        }
      }

      setSnackbarMessage("Friend added");
      setSnackbarSeverity("success");
    } catch (error) {
      console.error(error);
      setSnackbarMessage("An error occurred");
      setSnackbarSeverity("error");
    } finally {
      // Open the snackbar
      setSnackbarOpen(true);
    }
  };

  if (memberSuggestions.length === 0) return null;

  return (
    <WidgetWrapper>
      <Typography color={dark} variant={"h4"} fontWeight={700} mb={2}>
        Members we think you might like to connect
      </Typography>
      <Box>
        {Array.isArray(memberSuggestions) ? (
          memberSuggestions.map((member, index) => (
            <Box key={member._id} margin="1rem 0">
              <FlexBetween>
                <Link to={`/profile/${member._id}`}>
                  <FlexBetween gap="1rem">
                    <Avatar
                      src={member.picturePath}
                      alt={member.firstName}
                      sx={{ width: "40px", height: "40px" }}
                    />
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      gap="0.5rem"
                    >
                      <Typography
                        color={dark}
                        variant="h6"
                        fontSize="16px"
                        fontWeight={500}
                      >
                        {member.firstName} {member.lastName}
                      </Typography>
                      <AvatarRoleIcons user={member} />
                    </Box>
                  </FlexBetween>
                </Link>

                <Button
                  onClick={() => handleAddFriend(member._id)}
                  variant="contained"
                  sx={{
                    backgroundColor: mainSecondaryDark,
                    borderRadius: "1rem",
                    padding: "0.25rem 1rem",
                  }}
                >
                  Connect
                  <img
                    src="../assets/add-user.png"
                    alt="Add user"
                    style={{
                      width: "15px",
                      marginLeft: "0.5rem",
                      filter:
                        mode === "light"
                          ? "invert(1) brightness(1.5)"
                          : "brightness(0.5) contrast(1.2)",
                    }}
                  />
                </Button>
              </FlexBetween>
              {/* DW = {member?.distanceWeight} - SW = {member?.sportsWeight} - SF={" "}
              {member?.sharedFriends} - TW= {member?.totalWeight} */}
              {index !== memberSuggestions.length - 1 && (
                <Divider sx={{ my: 2 }} />
              )}
            </Box>
          ))
        ) : (
          <Typography color={dark} variant={"h6"} fontWeight={500}>
            No matching members found
          </Typography>
        )}
      </Box>
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        severity={snackbarSeverity}
      />
    </WidgetWrapper>
  );
}

export default FindMembersMemberSuggestions;
