import React, { useEffect, useState } from "react";
import RecommendFriendPopup from "./RecommendFriendPopUp";
import SnackbarComponent from "./SnackbarComponent";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "redux/state";
import { recommendFriend } from "redux/actions/userActions";
import { Box, Button, Typography, useTheme } from "@mui/material";
import WidgetWrapper from "./WidgetWrapper";

function RecommendFriend() {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const primary = palette.primary.main;
  const dark = palette.neutral.dark;

  const [recommendPopupOpen, setRecommendPopupOpen] = useState(false);
  const { _id } = useSelector((state) => state.user);
  const firstName = useSelector((state) => state.user.firstName);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(""); // Add state to manage email error message
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  // Define email validation schema using Yup
  const emailSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const nameSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

  // Function to validate email and recommend friend if valid
  const validateAndRecommend = async (email, name) => {
    try {
      // Validate email and name separately and handle their errors individually
      await emailSchema.validate({ email });
      setEmailError(""); // Clear previous email error if validation is successful
    } catch (error) {
      setEmailError(error.message);
    }

    try {
      await nameSchema.validate({ name });
      setNameError(""); // Clear previous name error if validation is successful
    } catch (error) {
      setNameError(error.message);
    }

    // Only proceed with recommendation if both email and name are valid
    if (!emailError && !nameError) {
      handleRecommend(email, name);
      setEmail(""); // Clear email field
      setName(""); // Clear name field
    }
  };

  useEffect(() => {
    dispatch(fetchUser(_id));
  }, [dispatch, _id]);

  // Handlers for the popup
  const handleRecommend = (recommendedEmail, recommendedName) => {
    // Dispatch the recommendation action

    try {
      const success = dispatch(
        recommendFriend(_id, recommendedEmail, recommendedName)
      );
      if (success) {
        setSnackbarMessage("Recommendation sent successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage("Failed to send recommendation");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error(error);
      setSnackbarMessage("Failed to send recommendation");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }

    setRecommendPopupOpen(false);
  };

  const handlePopupClose = () => {
    // dispatch(recommendFriend(_id, "declined"));
    setRecommendPopupOpen(false);
  };

  return (
    <>
      <WidgetWrapper>
        <Typography
          color={dark}
          variant="h5"
          fontWeight="500"
          sx={{ mb: "1.5rem" }}
        >
          Invite a friend to{" "}
          <Typography variant="span" fontWeight={700} color={primary}>
            She is Action
          </Typography>
        </Typography>
        <Typography mb={2} color="text.secondary">
          <Typography variant="span" fontWeight={700} color={primary}>
            Why Invite Your Friends?
          </Typography>{" "}
          It's simple—the more, the merrier! Our platform thrives on the
          diversity and vibrancy of its members. By inviting your friends,
          you're not just offering them a gateway to an empowering and
          supportive community, but you're also enriching your own experience.
          With each new member, She Is Action becomes a more dynamic,
          informative, and inspiring space.
        </Typography>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setRecommendPopupOpen(true)}
          >
            Invite a Friend
          </Button>
        </Box>
      </WidgetWrapper>
      <RecommendFriendPopup
        open={recommendPopupOpen}
        onClose={handlePopupClose}
        onRecommend={handleRecommend}
        firstName={firstName}
        email={email}
        emailError={emailError}
        setEmail={setEmail}
        validateAndRecommend={validateAndRecommend}
        name={name}
        nameError={nameError}
        setName={setName}
      />
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        severity={snackbarSeverity}
      />
    </>
  );
}

export default RecommendFriend;
