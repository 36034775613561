import { Box, useMediaQuery } from "@mui/material";
import Footer from "components/Footer";
import WidgetWrapper from "components/WidgetWrapper";
import BottomMenu from "components/bottomMenu/BottomMenu";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchArticles,
  fetchArticlesByTag,
} from "redux/actions/articleActions";
import BrandArticleCard from "scenes/BrandPage/components/BrandArticleCard";
import Header from "scenes/admin/components/Header";
import Navbar from "scenes/navbar";

function News({ socket }) {
  const dispatch = useDispatch();
  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  const user = useSelector((state) => state.user);
  const userId = user._id;

  const { tag } = useParams();

  const articles = useSelector((state) => state.feedArticles);
  const tagArticles = useSelector((state) => state.tagArticles);

  useEffect(() => {
    if (tag) {
      dispatch(fetchArticlesByTag(tag));
    } else {
      dispatch(fetchArticles(userId));
    }
  }, [dispatch, userId, tag]);

  const displayArticles = tag ? tagArticles : articles;

  return (
    <>
      <Box
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        marginBottom="1rem"
      >
        <Navbar socket={socket} />
        <Box flexGrow={1} overflow="auto">
          <WidgetWrapper>
            <Header
              title={tag ? `News related to: "${tag}"` : "News Reel"}
              subtitle={
                tag
                  ? `Tag: ${tag}`
                  : "Everything sporting from your favourite brands"
              }
            />
            <Box
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)", // small devices, 1 card per row
                sm: "repeat(2, 1fr)", // medium devices, 2 cards per row
                md: "repeat(3, 1fr)", // medium-large devices, 3 cards per row
                lg: "repeat(4, 1fr)", // large devices, 4 cards per row
                xl: "repeat(5, 1fr)", // extra large devices, 5 cards per row
              }}
              justifyContent="space-between"
              rowGap="20px"
              columnGap="1.33%"
              mt="1.5rem"
              mb="1.5rem"
              sx={{
                "& > div": {
                  gridColumn: {
                    xs: "span 1",
                    sm: "span 1",
                    md: "span 1",
                    lg: undefined,
                  },
                },
              }}
            >
              {Array.isArray(displayArticles) &&
                displayArticles.map((article) => (
                  <BrandArticleCard article={article} key={article._id} />
                ))}
            </Box>
          </WidgetWrapper>
        </Box>
        <Footer />
      </Box>
      {!isLargeScreen && <BottomMenu />}
    </>
  );
}

export default News;
