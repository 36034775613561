import { DeleteOutlined, EditOutlined } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import React from "react";
import Dropzone from "react-dropzone";

const ImageUploadComponent = ({ neutralMedium, setImage, primary, image }) => {
  return (
    <Box
      border={`1px solid ${neutralMedium}`}
      borderRadius="5px"
      mt="1rem"
      p="1rem"
    >
      <Dropzone
        acceptedFiles=".jpg,.jpeg,.png"
        multiple={false}
        onDrop={(acceptedFiles) => setImage(acceptedFiles[0])}
      >
        {({ getRootProps, getInputProps }) => (
          <FlexBetween>
            <Box
              {...getRootProps()}
              border={`2px dashed ${primary}`}
              p="1rem"
              width="100%"
              sx={{ "&:hover": { cursor: "pointer" } }}
            >
              <input {...getInputProps()} />
              {!image ? (
                <p>Add Image Here</p>
              ) : (
                <FlexBetween>
                  <Typography>{image.name}</Typography>
                  <EditOutlined />
                </FlexBetween>
              )}
            </Box>
            {image && (
              <IconButton onClick={() => setImage(null)} sx={{ width: "15%" }}>
                <DeleteOutlined />
              </IconButton>
            )}
          </FlexBetween>
        )}
      </Dropzone>
    </Box>
  );
};

export default ImageUploadComponent;
