import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useRef, useEffect } from "react";
import Quill from "quill";
import Dropzone from "react-dropzone";
import "quill/dist/quill.snow.css";

function ContentBlocks({
  contentBlocks,
  removeBlock,
  updateBlockData,
  handleImageUpload,
  modules,
  formats,
}) {
  const quillRefs = useRef([]);
  const containerRefs = useRef([]);

  useEffect(() => {
    contentBlocks.forEach((block, index) => {
      if (
        block.type === "text" &&
        containerRefs.current[index] &&
        !quillRefs.current[index]
      ) {
        quillRefs.current[index] = new Quill(containerRefs.current[index], {
          theme: "snow",
          modules: modules,
          formats: formats,
          placeholder: "Add Your Text Content Here",
        });

        quillRefs.current[index].on("text-change", () => {
          const value = quillRefs.current[index].root.innerHTML;
          updateBlockData(index, value);
        });

        quillRefs.current[index].root.innerHTML = block.data;
      }
    });
  }, [contentBlocks, modules, formats, updateBlockData]);

  return (
    contentBlocks &&
    contentBlocks.length > 0 &&
    contentBlocks.map((block, index) => (
      <Box key={`${block.type}-${index}`} marginY={2} position="relative">
        <IconButton
          aria-label="Close"
          onClick={() => removeBlock(index)}
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            zIndex: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
        {block.type === "text" ? (
          <div ref={(el) => (containerRefs.current[index] = el)} />
        ) : (
          <Dropzone
            onDrop={async (acceptedFiles) => {
              const file = acceptedFiles[0];
              const publicImageUrl = await handleImageUpload(file);
              if (publicImageUrl) {
                updateBlockData(index, publicImageUrl);
              } else {
                console.error("Image upload failed");
              }
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <Box
                {...getRootProps()}
                border="1px dashed"
                textAlign="center"
                height={200}
                display="flex"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                borderRadius="4px"
                style={{
                  backgroundImage: `url(${block.data})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <input {...getInputProps()} />
                <Typography
                  variant="body2"
                  sx={{
                    backgroundColor: "rgba(255,255,255,0.7)",
                    padding: "8px",
                    borderRadius: "4px",
                  }}
                >
                  Drop image here, or click to select image
                </Typography>
              </Box>
            )}
          </Dropzone>
        )}
      </Box>
    ))
  );
}

export default ContentBlocks;
