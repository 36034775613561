import { Typography } from "@mui/material";
import React from "react";

function ArticlePageTitle({ article, isLargeScreen }) {
  const textStyle = {
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)", // Example shadow
  };

  return (
    <Typography
      variant="h1"
      fontWeight="bold"
      style={isLargeScreen ? {} : textStyle}
      color={isLargeScreen ? "inherit" : "white"}
    >
      {article.title}
    </Typography>
  );
}

export default ArticlePageTitle;
