import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";


function ExperienceForm({ formProps, index, experienceLevels, sport, theme }) {
  return (
    <FormControl fullWidth margin="normal" style={{ flex: 1 }}>
      <InputLabel
        id={`experienceLevel-label-${index}`}
        sx={{
          backgroundColor: theme.palette.background.alt,
          padding: "0 5px",
        }}
      >
        Experience Level
      </InputLabel>
      <Select
        labelId={`experienceLevel-label-${index}`}
        value={sport.experienceLevel}
        onChange={formProps.handleChange}
        onBlur={formProps.handleBlur}
        name={`sports[${index}].experienceLevel`}
        error={
          formProps.touched.sports &&
          formProps.touched.sports[index] &&
          Boolean(formProps.errors.sports?.[index]?.experienceLevel)
        }
        helpertext={
          formProps.touched.sports &&
          formProps.touched.sports[index] &&
          formProps.errors.sports?.[index]?.experienceLevel
        }
      >
        {experienceLevels.map((level) => (
          <MenuItem key={level} value={level}>
            {level}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default ExperienceForm;
