import { Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import WidgetWrapper from "components/WidgetWrapper";
import React from "react";
import SettingsLandingCard from "./SettingsLandingCards";

function SettingsLandingPage() {
  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  return (
    <Box
      sx={{
        flexBasis: isLargeScreen ? "60%" : "auto",
        px: 2,
        pb: isLargeScreen ? 0 : 4,
      }}
    >
      <WidgetWrapper>
        <Typography variant="h4" mb={2}>
          Find the setting you need
        </Typography>

        <Box>
          <Typography variant="h5" mb={2}>
            Manage Your Profile
          </Typography>
          <Box
            display="flex"
            flexDirection={isLargeScreen ? "row" : "column"}
            gap={2}
            justifyContent={isLargeScreen ? "space-evenly" : "center"}
            alignItems="stretch" // This ensures all children stretch to match the height of the tallest child
            sx={{
              width: "100%", // Ensures the flex container is full width
              mx: "auto", // Centers the box in the middle of the page
            }}
          >
            <SettingsLandingCard
              linkTo="/settings/profile"
              imageUrl="https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/569e79a1-afcd-446d-bf00-5b82f2fcc400/public"
              title="Profile"
              description="Stay connected and competitive by keeping your profile updated! Ensure you're matched with the right teammates and access content that resonates with your sports interests and skill level. Update your profile to reflect your latest achievements and preferences, and help us create a more tailored experience just for you."
            />

            <SettingsLandingCard
              linkTo="/settings/profile-questions"
              imageUrl="https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/513e853a-5001-4e12-ecfa-27a10bfdd200/public"
              title="Profile Questions"
              description="Helping you connect with like-minded members and discover tailored content. However, don't worry if you're not ready to share just yet — you can always do so at a more convenient time. Simply answer any of the questions that resonate with you. We can't wait to get to know you better!"
            />
          </Box>
        </Box>

        <Box>
          <Typography variant="h5" my={2}>
            More settings
          </Typography>
          <Box
            display="flex"
            flexDirection={isLargeScreen ? "row" : "column"}
            gap={2}
          >
            <SettingsLandingCard
              linkTo="/settings/notifications"
              imageUrl="https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/6085e447-927b-4b12-6ae6-d9d7a6b0d300/public"
              title="Notifications"
              description="Stay in the loop with notifications about your account, activity, and more. Choose how you want to be notified, and what you want to be notified about. You can also manage your email preferences here."
            />
            <SettingsLandingCard
              linkTo="/settings/dark-mode"
              imageUrl="https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/7cb5d981-f0fa-42a0-ed52-db6bc63c5900/public"
              title="Dark Mode"
              description="Switch to dark mode to reduce eye strain and improve your device's battery life. You can also choose to have dark mode enabled automatically at night."
            />

            <SettingsLandingCard
              linkTo="/settings/invite-friends"
              imageUrl="https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/431f12fc-515b-48ed-af33-9f320e40a300/public"
              title="Invite Friends"
              description="Do you want to be reminded to invite your friends to join She Is Action and connect with like-minded members?"
            />
            <SettingsLandingCard
              linkTo="/settings/blocked-users"
              imageUrl="https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/433c0386-09c1-4bd1-74ba-cc6d7bd96800/public"
              title="Manage Blocked users"
              description="Manage the users you have blocked or soft blocked. You can unblock them or soft block them again."
            />
          </Box>
        </Box>
      </WidgetWrapper>
    </Box>
  );
}

export default SettingsLandingPage;
