import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import SnackbarComponent from "components/SnackbarComponent";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSport,
  fetchAdvancedSearchSports,
  fetchSport,
} from "redux/actions/sportActions";
import DataGridCustomToolbar from "../../components/DataGridCustomToolbar";

function SportGrid({ handleEdit }) {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const backgroundAlt = palette.background.alt;
  const primaryLight = palette.primary.light;
  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [sort, setSort] = useState("");

  const totalRows =
    useSelector((state) => state.advancedSearchSports.total) || 0;

  const sports =
    useSelector((state) => state.advancedSearchSports.sports) || [];

  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  useEffect(() => {
    dispatch(
      fetchAdvancedSearchSports({ page, limit: rowsPerPage, sort, search })
    );
  }, [dispatch, page, rowsPerPage, sort, search]);

  if (!sports.length) {
    return <div>Loading...</div>; // Or any other loading state representation
  }

  const handleEditClick = async (id) => {
    const sportId = id;
    try {
      const sportData = await dispatch(fetchSport(sportId));

      handleEdit(sportData);
    } catch (error) {
      console.log("Error fetching sport:", error);
      setSnackbarMessage("Error fetching sport");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setOpenDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await dispatch(deleteSport(deleteId));
      setSnackbarMessage("Sponsor deleted successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setOpenDialog(false);
    } catch (error) {
      console.log("Error deleting sponsor:", error);
      setSnackbarMessage("Error deleting sponsor");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleCancelDelete = () => {
    setOpenDialog(false);
    setDeleteId(null);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
    },
    {
      field: "popularity",
      headerName: "Popularity",
      flex: 0.5,
    },
    {
      field: "teamSize",
      headerName: "Team Size",
      flex: 0.5,
    },
    {
      field: "equipment",
      headerName: "Equipment",
      flex: 2,
      // no sort for this column
      sortable: false,
    },
    {
      field: "edit",
      headerName: "Edit",
      flex: 0.5,
      sortable: false,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEditClick(params.row._id)}
            sx={{
              padding: "5px 7px",
              fontSize: "0.75rem",
            }}
          >
            Edit
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      flex: 0.5,
      sortable: false,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDeleteClick(params.row._id)}
            sx={{
              padding: "5px 7px",
              fontSize: "0.75rem",
            }}
          >
            Delete
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Box
        m={isLargeScreen ? "1.5rem 1rem 2rem 1rem" : "1.5rem 0 2rem 0"}
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            // border: "none",
          },
          "& .MuiDataGrid-cell": {
            // borderBottom: "none",
            whiteSpace: "normal",
            wordBreak: "break-word",
            overflow: "visible",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: backgroundAlt,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: backgroundAlt,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: primaryLight,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {},
          "& .MuiDataGrid-row": {
            height: "auto", // This ensures the row height adapts to content
          },
        }}
      >
        <DataGrid
          getRowId={(row) => {
            return row._id;
          }}
          rows={sports}
          columns={columns}
          pageSize={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
          page={page - 1}
          onPageChange={(newPage) => setPage(newPage + 1)}
          paginationMode="server"
          rowCount={totalRows} // You need to know the total count of records from the server for proper pagination
          // checkboxSelection
          disableSelectionOnClick
          autoHeight={true}
          onSortModelChange={(model) => {
            if (model.length) {
              setSort(`${model[0].field}:${model[0].sort}`);
            } else {
              setSort("");
            }
          }}
          components={{
            Toolbar: DataGridCustomToolbar,
          }}
          componentsProps={{
            toolbar: {
              onSearch: (value) => {
                setSearch(value);
                setPage(0); // Reset page when a new search is executed
              },
              searchInput,
              setSearchInput,
              setSearch,
            },
          }}
        />
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete this sport?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <SnackbarComponent
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}

export default SportGrid;
