import { useMediaQuery } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  archiveArticle,
  fetchArticle,
  fetchCategories,
  updateArticle,
} from "redux/actions/articleActions";
import { handleImageUpload } from "redux/actions/imageUploadUtils";
import { fetchSports } from "redux/actions/sportActions";
import { addTag, fetchAllTags, removeTag } from "redux/actions/tagActions";
import * as Yup from "yup";

export const useArticleEditState = () => {
  const { articleId } = useParams();
  const tagsList = useSelector((state) => state.tags);

  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [titleImage, setTitleImage] = useState("");
  const [brandId, setBrandId] = useState("");
  const [contentBlocks, setContentBlocks] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSport, setSelectedSport] = useState([]);

  const [tags, setTags] = useState([]);
  const [newTagName, setNewTagName] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user) || {};

  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  const categories = useSelector((state) => state.categories) || [];
  const sportsList = useSelector((state) => state.sports) || [];

  const articleData = useSelector((state) => state.article);
  const article = useMemo(() => articleData || {}, [articleData]);
  const [formErrors, setFormErrors] = useState({});

  const isBrandUser =
    user &&
    (user.brandManagement.includes(article.brand) ||
      user.brandCopywriting.includes(article.brand));

  useEffect(() => {
    if (articleId) {
      dispatch(fetchArticle(articleId));
    }
  }, [dispatch, articleId]);

  useEffect(() => {
    if (article?.tags) {
      const tagNames = article.tags.map((tag) => tag.name);
      setTags(tagNames);
    }
  }, [article]);

  useEffect(() => {
    dispatch(fetchCategories());
    if (!sportsList.length) dispatch(fetchSports());
    dispatch(fetchAllTags());
  }, [dispatch, sportsList.length]);

  useEffect(() => {
    if (article) {
      setTitle(article.title || "");
      setSubtitle(article.subtitle || "");
      setTitleImage(article.titleImage || "");
      setBrandId(article.brand || "");
      setSelectedCategory(article.category || "");
      setSelectedSport(article.sport || []);

      if (article.content) {
        setContentBlocks(article.content || []);
      }
    }
  }, [article]);

  const handleSportChange = (newValues) => {
    const selectedSports = sportsList.filter((sport) =>
      newValues.includes(sport.name)
    );
    setSelectedSport(selectedSports);
  };

  const articleEditSchema = Yup.object().shape({
    selectedCategory: Yup.string().required("Category is required"),
    selectedSport: Yup.array().min(1, "At least one sport is required"),
    title: Yup.string().required("Title is required"),
    subtitle: Yup.string().required("Subtitle is required"),
    titleImage: Yup.string()
      .url("Must be a valid URL")
      .required("Title image is required"),
    tags: Yup.array().of(Yup.string()).min(1, "At least one tag is required"),
    contentBlocks: Yup.array().min(1, "At least one content block is required"),
  });

  const handleUpdateArticle = async () => {
    try {
      await articleEditSchema.validate(
        {
          title,
          subtitle,
          titleImage,
          selectedCategory,
          selectedSport,
          contentBlocks,
          tags,
        },
        { abortEarly: false }
      );

      dispatch(
        updateArticle(
          articleId,
          title,
          subtitle,
          titleImage,
          contentBlocks,
          brandId,
          selectedCategory,
          selectedSport
        )
      ).then(() => {
        navigate(`/news/article/${articleId}`);
      });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const newErrors = error.inner.reduce((acc, curr) => {
          acc[curr.path] = curr.message;
          return acc;
        }, {});
        setFormErrors(newErrors);
      } else {
        console.error("Error in updating article: ", error);
      }
    }
  };

  const handleArchiveArticle = () => {
    dispatch(archiveArticle(articleId)).then(() => {
      navigate(`/news/article/${articleId}`);
    });
  };

  const handleTitleImageUpload = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const publicImageUrl = await handleImageUpload(file);
    if (publicImageUrl) {
      setTitleImage(publicImageUrl);
    } else {
      console.error("Image upload failed");
    }
  };

  const addTextBlock = () => {
    setContentBlocks((prevBlocks) => [
      ...prevBlocks,
      { type: "text", data: "" },
    ]);
  };

  const addImageBlock = () => {
    setContentBlocks((prevBlocks) => [
      ...prevBlocks,
      { type: "image", data: null },
    ]);
  };

  const updateBlockData = (index, data) => {
    setContentBlocks((prevBlocks) =>
      prevBlocks.map((block, idx) =>
        idx === index ? { ...block, data } : block
      )
    );
  };

  const removeBlock = (index) => {
    setContentBlocks((prevBlocks) => {
      const updatedBlocks = [...prevBlocks];
      updatedBlocks.splice(index, 1);
      return updatedBlocks;
    });
  };

  const handleAddTag = (newTagNames) => {
    newTagNames.forEach((tagName) => {
      dispatch(addTag(articleId, tagName)).then(() => {
        dispatch(fetchAllTags());
      });
    });
    setTags((currentTags) => [...new Set([...currentTags, ...newTagNames])]);
  };

  const handleRemoveTag = (tagName) => {
    dispatch(removeTag(articleId, tagName)).then(() => {
      dispatch(fetchAllTags());
    });
    setTags((currentTags) => currentTags.filter((tag) => tag !== tagName));
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline"],
      [{ header: 1 }, { header: 2 }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ color: [] }],
      [{ align: [] }],
      ["clean"],
      ["link"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "color",
    "background",
    "align",
  ];

  return {
    title,
    setTitle,
    subtitle,
    setSubtitle,
    titleImage,
    brandId,
    contentBlocks,
    selectedCategory,
    setSelectedCategory,
    selectedSport,
    setSelectedSport,
    isLargeScreen,
    categories,
    sportsList,
    article,
    isBrandUser,
    handleUpdateArticle,
    handleArchiveArticle,
    handleTitleImageUpload,
    addTextBlock,
    addImageBlock,
    updateBlockData,
    removeBlock,
    modules,
    formats,
    tags,
    tagsList,
    newTagName,
    setNewTagName,
    handleAddTag,
    handleRemoveTag,
    handleSportChange,
    formErrors,
  };
};
