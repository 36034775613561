import { Box, useMediaQuery } from "@mui/material";
import Footer from "components/Footer";
import BottomMenu from "components/bottomMenu/BottomMenu";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getGroup } from "redux/actions/groupActions";
import Navbar from "scenes/navbar";
import { GroupEditContext } from "./components/GroupEditContext";
import GroupEditWidget from "./components/GroupEditWidget";
import GroupEditPreview from "./components/GroupEditPreview";

function GroupEdit({ socket }) {
  const { groupId } = useParams();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user) || {};
  const group = useSelector((state) => state.group) || {};
  const userId = user._id || "";
  const fullName = user.firstName + " " + user.lastName || "";
  const userPicturePath = user.picturePath || "";
  const userFriends = user.friends;

  const isAdministrator = group.administrators?.includes(user._id) || false;
  const isAdmin =
    user?.roles?.some((role) => role.name.toLowerCase() === "admin") || false;

  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  const [groupName, setGroupName] = useState("");
  const [privacy, setPrivacy] = useState("public");
  const [visibility, setVisibility] = useState("visible");
  const [selectedFriends, setSelectedFriends] = useState([]);
  const [availableFriends, setAvailableFriends] = useState([]);
  const [selectedSport, setSelectedSport] = useState("");
  const [selectedLocation, setSelectedLocation] = useState({ address: "" });
  const [groupImage, setGroupImage] = useState(null);
  const [experienceLevel, setExperienceLevel] = useState([]);
  const [description, setDescription] = useState("");
  const [memberCount, setMemberCount] = useState(0);

  useEffect(() => {
    dispatch(getGroup(groupId));
  }, [dispatch, groupId]);

  useEffect(() => {
    if (group) {
      setGroupName(group.name || "");
      setPrivacy(group.isPrivate ? "private" : "public");
      setVisibility(group.isVisible ? "visible" : "hidden");
      setSelectedFriends(group.members || []);
      setSelectedSport(group.sport || "");
      setSelectedLocation(group.location || { address: "" });
      setExperienceLevel(group.experienceLevel || []);
      setDescription(group.description || "");
      setGroupImage(group.image || null);
      setMemberCount(group.members?.length || 0);
    }
  }, [group]);

  useEffect(() => {
    if (userFriends && selectedFriends) {
      // make sure both userFriends and selectedFriends are available
      const updatedAvailableFriends = userFriends.filter(
        (friend) =>
          !selectedFriends.find((selected) => selected._id === friend._id)
      );
      setAvailableFriends(updatedAvailableFriends);
    }
  }, [userFriends, selectedFriends]);

  if (!isAdministrator && !isAdmin) {
    return <p>You are not authorized to edit this group.</p>;
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        minHeight="100vh" // changed from height="100vh"
      >
        <Navbar socket={socket} />
        {/* Render Navbar only if isLargeScreen is true */}
        <Box
          flexGrow={1}
          overflow="auto"
          width="100%"
          padding={isLargeScreen ? "0 6%" : "0"}
          display={isLargeScreen ? "flex" : "block"}
          gap="2rem"
          justifyContent="center"
        >
          <GroupEditContext.Provider
            value={{
              groupName,
              setGroupName,
              privacy,
              setPrivacy,
              visibility,
              setVisibility,
              selectedFriends,
              setSelectedFriends,
              availableFriends,
              setAvailableFriends,
              selectedSport,
              setSelectedSport,
              selectedLocation,
              setSelectedLocation,
              experienceLevel,
              setExperienceLevel,
              description,
              setDescription,
              groupImage,
              setGroupImage,
              memberCount,
            }}
          >
            <Box flexBasis={isLargeScreen ? "35%" : undefined}>
              <GroupEditWidget
                socket={socket}
                userPicturePath={userPicturePath}
                userId={userId}
                fullName={fullName}
                userFriends={userFriends}
              />
            </Box>
            <Box
              flexBasis={isLargeScreen ? "60%" : undefined}
              mt={isLargeScreen ? undefined : "2rem"}
            >
              {/* <CreatePreview /> */}
              <GroupEditPreview />
            </Box>
          </GroupEditContext.Provider>
        </Box>
        <Footer />
      </Box>
      {!isLargeScreen && <BottomMenu />}
    </>
  );
}

export default GroupEdit;
