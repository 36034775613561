import React from "react";
import { Typography } from "@mui/material";

function ErrorMessage({ error }) {
  if (!error) {
    return null;
  }
  return (
    <Typography color="error" variant="body2">
      {error}
    </Typography>
  );
}

export default ErrorMessage;
