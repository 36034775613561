import { Box, useMediaQuery, styled } from "@mui/material";
import { useSelector } from "react-redux";
import UserWidget from "scenes/widgets/UserWidget";
import MyPostWidget from "scenes/widgets/myPostWidget";
import PostsWidget from "scenes/widgets/PostsWidget";
import AdvertWidget from "scenes/widgets/AdvertWidget/AdvertWidget";
import FriendListWidget from "scenes/widgets/FriendListWidget";
import Navbar from "scenes/navbar";
import { setViewingUser } from "redux/state";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Footer from "components/Footer";
import BottomMenu from "components/bottomMenu/BottomMenu";

const ContentWrapper = styled(Box)({
  display: "flex",
  gap: "0.5rem",
});

const PostFeedPage = ({ socket }) => {
  const isLargeScreen = useMediaQuery("(min-width:1000px)");
  const { _id, picturePath } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setViewingUser(null));
  }, [dispatch]);

  const contentBoxStyle = isLargeScreen
    ? { flexGrow: 1, overflow: "auto" }
    : { flexGrow: 1 };

  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      marginBottom="1rem"
    >
      <Navbar socket={socket} />

      <Box style={contentBoxStyle}>
        <ContentWrapper
          width="100%"
          padding={isLargeScreen ? "0 6%" : undefined}
          justifyContent="space-between"
        >
          {isLargeScreen && (
            <Box flexBasis="26%">
              <UserWidget userId={_id} picturePath={picturePath} />
            </Box>
          )}
          <Box flexBasis={isLargeScreen ? "42%" : undefined}>
            <MyPostWidget picturePath={picturePath} socket={socket} />
            <PostsWidget userId={_id} socket={socket} />
          </Box>
          {isLargeScreen && (
            <Box flexBasis="26%">
              <AdvertWidget />
              <FriendListWidget userId={_id} socket={socket} />
            </Box>
          )}
        </ContentWrapper>
        <Footer />
      </Box>
      {!isLargeScreen && <BottomMenu />}
    </Box>
  );
};

export default PostFeedPage;
