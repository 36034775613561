import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { useTheme } from "@emotion/react";

function OverviewChart({ isDashboard = false, data }) {
  const { palette: theme } = useTheme();
  const primaryLight = theme.primary.light;
  const primaryDark = theme.primary.dark;

  // Define the chart settings
  const lineChartData = [{ id: "users", data }];

  return (
    <ResponsiveLine
      data={lineChartData}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: primaryDark,
            },
          },
          legend: {
            text: {
              fill: primaryDark,
            },
          },
          ticks: {
            line: {
              stroke: primaryDark,
              strokeWidth: 1,
            },
            text: {
              fill: primaryDark,
            },
          },
        },
        legends: {
          text: {
            fill: primaryDark,
          },
        },
        tooltip: {
          container: {
            background: primaryLight,
          },
        },
      }}
      margin={{ top: 20, right: 50, bottom: 50, left: 70 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: 0,
        max: "auto",
        stacked: true,
        reverse: false,
      }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Month-Year",
        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Number of Users",
        legendOffset: -36,
        legendPosition: "middle",
        tickValues: 5,
      }}
      curve="catmullRom"
      enableArea={true}
      enableGridX={false}
      enableGridY={false}
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabel="y"
      pointLabelYOffset={-12}
      useMesh={true}
      // start on y-axis at 0

      legends={
        !isDashboard
          ? [
              {
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
              },
            ]
          : undefined
      }
    />
  );
}

export default OverviewChart;
