import WidgetWrapper from "components/WidgetWrapper";
import SnackbarComponent from "components/SnackbarComponent";
import usePostWidgetState from "./hooks/usePostWidgetState";
import PostWidgetNameAndDetails from "./components/PostWidgetNameAndDetails";
import PostWidgetContent from "./components/PostWidgetContent";
import PostWidgetLikeAndAddCommentButton from "./components/PostWidgetLikeAndAddCommentButton";
import PostWidgetComments from "./components/PostWidgetComments";
import PostWidgetEdit from "./components/PostWidgetEdit";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import ReportForm from "../../../components/ReportForm";

const PostWidget = ({
  postId,
  postUserId,
  name,
  description,
  picturePath,
  userPicturePath,
  likes,
  comments,
  createdAt,
  lastEdited,
  socket,
  taggedUsers,
  group,
  userId,
  refreshPosts,
  user,
}) => {
  const {
    loggedInUser,
    isLiked,
    likeCount,
    isComments,
    setIsComments,
    snackbarOpen,
    setSnackbarOpen,
    setSnackbarMessage,
    setSnackbarSeverity,
    snackbarMessage,
    snackbarSeverity,
    main,
    primary,
    primaryLight,
    secondary,
    lightBackground,
    neutralLight,
    neutralMedium,
    mediumMain,
    isGroupPost,
    handlePatchLike,
    handlePatchLikeComment,
    closeComments,
    isPostAuthor,
    isEditMode,
    handleEditPost,
    handleMarkPostAsDeleted,
    isDeleteDialogOpen,
    handleDeleteDialogClose,
    performPostDeletion,
    likesUserNames,
    handleMouseEnterLikeButton,
    commentLikesUserNames,
    handleMouseEnterCommentLikeButton,
    ShareOutlinedIcon,
    openReportDialog,
    handleCloseReportDialog,
    handleReport,
    formData,
    formErrors,
    handleChange,
    isLargeScreen,
    textFieldLabel,
    dialogTitle,
    handleSoftBlockUser,
    handleBlockUser,
    handleClick,
    handleClose,
    handleReportPost,
    handleReportUser,
    groupImage,
    groupName,
    userProfileLink,
    groupLink,
    anchorEl,
    showTooltip,
    handleReportComment,
    handleReportCommentUser,
  } = usePostWidgetState({
    socket,
    postId,
    postUserId,
    likes,
    comments,
    group,
    refreshPosts,
    userId,
  });

  return (
    <WidgetWrapper>
      <PostWidgetNameAndDetails
        isGroupPost={isGroupPost}
        userPicturePath={userPicturePath}
        name={name}
        createdAt={createdAt}
        lastEdited={lastEdited}
        main={main}
        groupDetails={group}
        userId={userId}
        loggedInUser={loggedInUser}
        neutralMedium={neutralMedium}
        user={user}
        isPostAuthor={isPostAuthor}
        handleSoftBlockUser={handleSoftBlockUser}
        handleBlockUser={handleBlockUser}
        groupImage={groupImage}
        groupName={groupName}
        userProfileLink={userProfileLink}
        groupLink={groupLink}
        anchorEl={anchorEl}
        handleClick={handleClick}
        handleClose={handleClose}
        handleReportPost={handleReportPost}
        handleReportUser={handleReportUser}
      />
      {isEditMode ? (
        <PostWidgetEdit
          initialDescription={description}
          initialPicturePath={picturePath}
          initialTaggedUsers={taggedUsers}
          postId={postId}
          primary={primary}
          primaryLight={primaryLight}
          neutralLight={neutralLight}
          mediumMain={mediumMain}
          neutralMedium={neutralMedium}
          handleEditPost={handleEditPost}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarSeverity={setSnackbarSeverity}
          setSnackbarOpen={setSnackbarOpen}
          refreshPosts={refreshPosts}
        />
      ) : (
        <PostWidgetContent
          description={description}
          picturePath={picturePath}
        />
      )}
      <PostWidgetLikeAndAddCommentButton
        isLiked={isLiked}
        likeCount={likeCount}
        comments={comments}
        setIsComments={setIsComments}
        handlePatchLike={handlePatchLike}
        secondary={secondary}
        isComments={isComments}
        isPostAuthor={isPostAuthor}
        isEditMode={isEditMode}
        handleEditPost={handleEditPost}
        handleMarkPostAsDeleted={handleMarkPostAsDeleted}
        likesUserNames={likesUserNames}
        handleMouseEnterLikeButton={handleMouseEnterLikeButton}
        ShareOutlinedIcon={ShareOutlinedIcon}
        picturePath={picturePath}
        postId={postId}
        description={description}
      />

      <PostWidgetComments
        isComments={isComments}
        comments={comments}
        postId={postId}
        closeComments={closeComments}
        socket={socket}
        postUserId={postUserId}
        lightBackground={lightBackground}
        main={main}
        primary={primary}
        secondary={secondary}
        loggedInUser={loggedInUser}
        handlePatchLikeComment={handlePatchLikeComment}
        refreshPosts={refreshPosts}
        commentLikesUserNames={commentLikesUserNames}
        handleMouseEnterCommentLikeButton={handleMouseEnterCommentLikeButton}
        neutralMedium={neutralMedium}
        handleSoftBlockUser={handleSoftBlockUser}
        handleBlockUser={handleBlockUser}
        anchorEl={anchorEl}
        showTooltip={showTooltip}
        handleReportComment={handleReportComment}
        handleReportCommentUser={handleReportCommentUser}
        handleClick={handleClick}
        handleClose={handleClose}
      />

      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        severity={snackbarSeverity}
      />

      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleDeleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this post? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              performPostDeletion();
              handleDeleteDialogClose();
            }}
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openReportDialog}
        onClose={handleCloseReportDialog}
        aria-labelledby="form-dialog-title"
        aria-describedby="form-dialog-description"
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent
          style={{
            width: isLargeScreen ? "600px" : "300px",
          }}
        >
          <ReportForm
            formData={formData}
            formErrors={formErrors}
            handleChange={handleChange}
            textFieldLabel={textFieldLabel}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReportDialog}>Cancel</Button>
          <Button onClick={handleReport} color="primary">
            Report
          </Button>
        </DialogActions>
      </Dialog>
    </WidgetWrapper>
  );
};

export default PostWidget;
