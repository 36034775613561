import { PersonRemoveOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { patchFriend } from "redux/actions/friendsActions";
import FlexBetween from "./FlexBetween";
import SnackbarComponent from "./SnackbarComponent";
import { useState } from "react";
import AvatarRoleIcons from "./avatarRoleIcons";

// Helper function to strip HTML tags and truncate text
const stripHtmlAndTruncate = (htmlString, maxLength) => {
  // Create a new div element
  const tempDiv = document.createElement("div");
  // Set the HTML content with the provided HTML string
  tempDiv.innerHTML = htmlString;
  // Retrieve the text content of the div
  const strippedStr = tempDiv.textContent || tempDiv.innerText || "";
  // Truncate the string if it exceeds the maximum length
  if (strippedStr.length > maxLength) {
    return strippedStr.substring(0, maxLength) + "...";
  }
  return strippedStr;
};

const Friend = ({
  friendId,
  name,
  userPicturePath,
  imageSize = "55px",
  socket,
  friend,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const friends = useSelector((state) => state.user.friends);

  const { palette } = useTheme();
  const primaryLight = palette.primary.light;
  const primaryDark = palette.primary.dark;
  const neutralLight = palette.neutral.light;
  const main = palette.neutral.main;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  const isFriend = friends.find((friend) => friend._id === friendId);

  let displaySubtitle = "";

  if (isFriend) {
    if (isFriend.sports && isFriend.sports.length > 0) {
      displaySubtitle = isFriend.sports[0].sport;
    }
  }

  const aboutMe = stripHtmlAndTruncate(friend.aboutMe || "", 200);

  // displaySubtitle = isFriend?.sports[0]?.sport;

  const handlePatchFriend = async () => {
    try {
      await dispatch(patchFriend(user._id, friendId));

      // Update the snackbar message and severity based on the friend status
      if (isFriend) {
        setSnackbarMessage("Friend removed");
        setSnackbarSeverity("info");
      } else {
        const notificationData = {
          senderId: user._id,
          receiverId: friendId,
          name: user.firstName + " " + user.lastName,
          picturePath: user.picturePath,
          type: "newFriend",
          timestamp: new Date(),
        };

        socket.emit("sendNotification", notificationData);

        setSnackbarMessage("Friend added");
        setSnackbarSeverity("success");
      }
      // Open the snackbar
      setSnackbarOpen(true);
    } catch (error) {
      // Handle any errors here
      setSnackbarMessage("An error occurred");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const avatarComponent = (
    <Link to={`/profile/${friendId}`}>
      <Avatar
        src={userPicturePath}
        sx={{ width: imageSize, height: imageSize }}
      />
    </Link>
  );

  return (
    <FlexBetween>
      <FlexBetween gap="1rem">
        {aboutMe ? (
          <Tooltip
            title={aboutMe}
            placement="top-start"
            arrow
            enterDelay={500}
            leaveDelay={200}
          >
            {avatarComponent}
          </Tooltip>
        ) : (
          avatarComponent
        )}
        <Box
          onClick={() => {
            navigate(`/profile/${friendId}`);
            navigate(0);
          }}
        >
          <Box display="flex" flexDirection="row" alignItems={"center"} gap={1}>
            <Typography
              color={primaryDark}
              variant="h6"
              fontWeight="500"
              sx={{
                "&:hover": {
                  color: palette.primary.light,
                  cursor: "pointer",
                },
              }}
            >
              {name}
            </Typography>
            <AvatarRoleIcons user={friend} avatarSize="15px" />
          </Box>
          <Typography color={main} fontSize="0.75rem">
            {displaySubtitle}
          </Typography>
        </Box>
      </FlexBetween>
      {user._id !== friendId && (
        <IconButton
          onClick={handlePatchFriend}
          sx={{
            backgroundColor: isFriend ? neutralLight : primaryLight,
            p: isFriend ? "0.6rem" : 0,
          }}
        >
          {isFriend ? (
            <PersonRemoveOutlined
              data-testid="remove-friend-icon"
              sx={{ color: primaryDark }}
            />
          ) : (
            <div
              style={{
                color: "white",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background:
                  "linear-gradient(180deg, #9ceced 0%, #008e94 50.52%, #c85ed9 100%)",
              }}
            >
              <img
                src="/assets/add-user.png"
                alt="Add user"
                style={{
                  width: "30px",
                  filter: "brightness(0) invert(1)",
                }}
                data-testid="add-friend-icon"
              />
            </div>
          )}
        </IconButton>
      )}
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        severity={snackbarSeverity}
      />
    </FlexBetween>
  );
};

export default Friend;
