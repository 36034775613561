import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import WidgetWrapper from "components/WidgetWrapper";

import UserBioTable from "scenes/widgets/UserWidget/components/UserBioTable";
import { useUserWidgetState } from "./hooks/useUserWidgetState";
import UserWidgetTopImage from "./components/UserWidgetMobileTopImage";
import UserWidgetMobileName from "./components/UserWidgetMobileName";
import UserWidgetDesktopAvatarNameFriends from "./components/UserWidgetDesktopAvatarNameFriends";
import UserWidgetAbountMe from "./components/UserWidgetAbountMe";
import UserWidgetSportsTable from "./components/UserWidgetSportsTable";
import UserWidgetAnsweredQuestions from "./components/UserWidgetAnsweredQuestions";
import ReportForm from "components/ReportForm";

const UserWidget = ({ userId, socket }) => {
  const {
    navigate,
    currentUserId,
    isLargeScreen,
    dark,
    medium,
    primaryMain,
    primaryDark,
    primaryLight,
    neutralLight,
    neutralMedium,
    user,
    firstName,
    lastName,
    sports,
    friends,
    picturePath,
    aboutMe,
    questionnaireAnswers,
    handleBackClick,
    milesBetweenUsers,
    kilometersBetweenUsers,
    isFriend,
    snackbarOpen,
    setSnackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    handleReportUser,
    handlePatchFriend,
    handleClick,
    handleClose,
    handleSoftBlockUser,
    handleBlockUser,
    anchorEl,
    openReportDialog,
    handleCloseReportDialog,
    handleReport,
    formData,
    formErrors,
    handleChange,
    textFieldLabel,
    dialogTitle,
    isNonUser,
  } = useUserWidgetState({ userId, socket });

  return (
    <>
      <UserWidgetTopImage
        isLargeScreen={isLargeScreen}
        primaryMain={primaryMain}
        picturePath={picturePath}
        firstName={firstName}
        lastName={lastName}
        handleBackClick={handleBackClick}
      />

      <WidgetWrapper
        marginTop={!isLargeScreen ? "-50px" : undefined}
        position={!isLargeScreen ? "relative" : undefined}
        borderRadius={!isLargeScreen ? "2rem" : "0.75rem"}
      >
        <UserWidgetMobileName
          isLargeScreen={isLargeScreen}
          picturePath={picturePath}
          userId={userId}
          firstName={firstName}
          lastName={lastName}
          friends={friends}
          currentUserId={currentUserId}
          dark={dark}
          primaryLight={primaryLight}
          neutralMedium={neutralMedium}
          navigate={navigate}
          medium={medium}
          milesBetweenUsers={milesBetweenUsers}
          kilometersBetweenUsers={kilometersBetweenUsers}
          primaryDark={primaryDark}
          neutralLight={neutralLight}
          user={user}
          setSnackbarOpen={setSnackbarOpen}
          snackbarOpen={snackbarOpen}
          snackbarMessage={snackbarMessage}
          snackbarSeverity={snackbarSeverity}
          isFriend={isFriend}
          handleReportUser={handleReportUser}
          handleSoftBlockUser={handleSoftBlockUser}
          handleBlockUser={handleBlockUser}
          handleClick={handleClick}
          handleClose={handleClose}
          anchorEl={anchorEl}
          handlePatchFriend={handlePatchFriend}
        />

        <UserWidgetDesktopAvatarNameFriends
          isLargeScreen={isLargeScreen}
          picturePath={picturePath}
          userId={userId}
          firstName={firstName}
          lastName={lastName}
          friends={friends}
          currentUserId={currentUserId}
          dark={dark}
          primaryLight={primaryLight}
          neutralMedium={neutralMedium}
          navigate={navigate}
          medium={medium}
          milesBetweenUsers={milesBetweenUsers}
          kilometersBetweenUsers={kilometersBetweenUsers}
          primaryDark={primaryDark}
          neutralLight={neutralLight}
          user={user}
          setSnackbarOpen={setSnackbarOpen}
          snackbarOpen={snackbarOpen}
          snackbarMessage={snackbarMessage}
          snackbarSeverity={snackbarSeverity}
          isFriend={isFriend}
          handleReportUser={handleReportUser}
          handleSoftBlockUser={handleSoftBlockUser}
          handleBlockUser={handleBlockUser}
          handleClick={handleClick}
          handleClose={handleClose}
          anchorEl={anchorEl}
          handlePatchFriend={handlePatchFriend}
          isNonUser={isNonUser}
        />

        <UserWidgetAbountMe aboutMe={aboutMe} primaryMain={primaryMain} />

        <UserWidgetAnsweredQuestions
          questionnaireAnswers={questionnaireAnswers}
          primaryMain={primaryMain}
          primaryDark={primaryDark}
        />

        <Box p="1rem 0 0.5rem 0">
          <UserBioTable user={user} />
        </Box>

        <UserWidgetSportsTable
          sports={sports}
          dark={dark}
          primaryMain={primaryMain}
        />
      </WidgetWrapper>
      <Dialog
        open={openReportDialog}
        onClose={handleCloseReportDialog}
        aria-labelledby="form-dialog-title"
        aria-describedby="form-dialog-description"
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent
          style={{
            width: isLargeScreen ? "600px" : "300px",
          }}
        >
          <ReportForm
            formData={formData}
            formErrors={formErrors}
            handleChange={handleChange}
            textFieldLabel={textFieldLabel}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReportDialog}>Cancel</Button>
          <Button onClick={handleReport} color="primary">
            Report
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserWidget;
