import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Box,
  Modal,
  FormControl,
  Input,
  Button,
  List,
  ListItem,
  ListItemText,
  Avatar,
  useTheme,
} from "@mui/material";
import { searchUsers } from "redux/actions/userActions";

import { Close } from "@mui/icons-material";
import { createChat } from "redux/actions/chatActions";
import FlexBetween from "components/FlexBetween";

function GroupChatModal({ open, setOpen }) {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const user = useSelector((state) => state.user);
  const primary = palette.primary.main;
  const primaryLight = palette.primary.light;
  const primaryDark = palette.primary.dark;
  const neutralLight = palette.neutral.light;
  const neutralMedium = palette.neutral.medium;

  const [search, setSearch] = useState("");
  const [groupChatName, setGroupChatName] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleClose = () => {
    setSearch("");
    setSearchResults([]);
    setSelectedUsers([]);
    setGroupChatName("");
    setPage(1);
    setTotalPages(1);

    setOpen(false);
  };

  const handleSearch = async (e) => {
    setSearch(e.target.value);
  };

  // add the creating user to the selected users list
  useEffect(() => {
    if (open && !selectedUsers.some((u) => u._id === user._id)) {
      setSelectedUsers([...selectedUsers, user]);
    }
  }, [open, user, selectedUsers]);

  useEffect(() => {
    if (search) {
      const limit = 5;

      dispatch(searchUsers(search, page, limit))
        .then((data) => {
          if (data && Array.isArray(data.data)) {
            setSearchResults(data.data);
            // Assuming the API sends a total count of results in data.total
            setTotalPages(Math.ceil(data.total / limit));
          } else {
            console.warn(
              "searchUsers did not return the expected format",
              data
            );
            setSearchResults([]);
          }
        })
        .catch((error) => {
          console.error("Failed to search users:", error);
          setSearchResults([]);
        });
    } else {
      setSearchResults([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, page]);

  const handleUserSelection = (user) => {
    if (!selectedUsers.some((u) => u._id === user._id)) {
      setSelectedUsers([...selectedUsers, user]);
    } else {
      handleDeleteUser(user);
    }
  };

  const handleDeleteUser = (user) => {
    setSelectedUsers(selectedUsers.filter((u) => u._id !== user._id));
  };

  const handleSubmit = async () => {
    if (!groupChatName || !selectedUsers.length) {
      console.warn("Missing required fields");
      return;
    }

    try {
      setLoading(true);
      // Pass the modified list of user IDs (excluding the creator's ID)
      await dispatch(
        createChat(groupChatName, true, selectedUsers, [user._id])
      );
      // Add any additional logic if needed after the chat creation
    } catch (error) {
      console.error("Failed to create chat:", error);
    } finally {
      setLoading(false);
    }

    handleClose();
  };

  const groupChatButton = (
    <Button
      variant="contained"
      color="primary"
      onClick={() => handleSubmit(groupChatName, selectedUsers)}
      disabled={groupChatName.trim().length === 0 || selectedUsers.length < 2}
    >
      Create
    </Button>
  );

  const cancelButton = (
    <Button variant="contained" color="secondary" onClick={handleClose}>
      Cancel
    </Button>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: `2px solid ${primary}`,
          boxShadow: 24,
          p: 4,
          borderRadius: "10px",
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h4"
          display="flex"
          justifyContent="center"
          color={primary}
          paddingBottom="0.5em"
        >
          Create Group Chat
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
        >
          <FormControl fullWidth>
            <Input
              type="text"
              placeholder="Chat Name"
              value={groupChatName}
              onChange={(e) => setGroupChatName(e.target.value)}
              disableUnderline
              sx={{
                marginBottom: "1em",
                border: `1px solid ${neutralMedium}`,
                borderRadius: "5px",
                paddingLeft: "0.5em", // Optional: to provide some spacing inside the input

                width: "100%", // Full width
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <Input
              type="text"
              placeholder="Search for users"
              value={search}
              onChange={handleSearch}
              disableUnderline
              sx={{
                marginBottom: "1em",
                border: `1px solid ${neutralMedium}`,
                borderRadius: "5px",
                paddingLeft: "0.5em", // Optional: to provide some spacing inside the input
                width: "100%", // Full width
              }}
            />
          </FormControl>
          <Typography
            variant="h6"
            display="flex"
            justifyContent="center"
            color={primary}
            paddingBottom="0.5em"
          >
            Selected Users
          </Typography>
          <Box width="100%" display="flex" flexWrap="wrap">
            {selectedUsers.map((selectedUser) => (
              <Box
                key={selectedUser._id}
                display="flex"
                alignItems="center"
                px="5px"
                py="3px"
                borderRadius="5px"
                m="5px"
                mb="5px"
                variant="filled"
                fontSize={12}
                backgroundColor={primaryDark}
                color={primaryLight}
                cursor="pointer"
              >
                {selectedUser.firstName} {selectedUser.lastName}
                {/* if the user is the creator of the chat, show a crown icon */}
                {selectedUser._id === user._id ? (
                  <Box
                    component="span"
                    sx={{
                      marginLeft: "0.5em",
                      fontSize: "12px",
                      color: primary,
                    }}
                  >
                    🥇
                  </Box>
                ) : (
                  <Close
                    sx={{ marginLeft: "0.3em", fontSize: "12px" }}
                    onClick={() => handleDeleteUser(user)}
                  />
                )}
              </Box>
            ))}
          </Box>

          <List sx={{ width: "100%" }}>
            {searchResults.map((resultUser) => (
              <ListItem
                key={resultUser._id}
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: neutralLight,
                  borderRadius: "10px",
                  marginBottom: "0.5em",
                  "&:hover": {
                    backgroundColor: primaryLight,
                    color: primaryDark,
                  },
                }}
                onClick={() => handleUserSelection(resultUser)}
              >
                <Avatar
                  src={
                    resultUser.picturePath.endsWith("/public")
                      ? resultUser.picturePath.replace("/public", "/128x128")
                      : `${resultUser.picturePath}/128x128`
                  }
                  sx={{ width: "30px", height: "30px" }}
                />

                <Box sx={{ flexGrow: 1, paddingLeft: "0.5rem" }}>
                  <ListItemText
                    primary={`${resultUser.firstName} ${resultUser.lastName}`}
                    sx={{ fontWeight: 500 }}
                  />
                </Box>
                {selectedUsers.some((u) => u._id === resultUser._id) && "✔️"}
              </ListItem>
            ))}
          </List>
          {searchResults && searchResults.length > 0 && (
            <Box display="flex" justifyContent="space-between" mt={2}>
              <Button
                disabled={page === 1}
                onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
              >
                Previous
              </Button>
              <Typography display="flex" alignItems="center" color={primary}>
                {page} of {totalPages}
              </Typography>
              <Button
                disabled={page === totalPages}
                onClick={() =>
                  setPage((prev) => Math.min(prev + 1, totalPages))
                }
              >
                Next
              </Button>
            </Box>
          )}
        </Box>
        <FlexBetween>
          {groupChatButton}
          {cancelButton}
        </FlexBetween>
      </Box>
    </Modal>
  );
}

export default GroupChatModal;
