import { Autocomplete, FormControl, TextField } from "@mui/material";
import React from "react";

function SportsSelect({ sportsList, selectedSport, setSelectedSport, error }) {
  // Function to return the sport object that matches the selectedSport value

  return (
    <FormControl fullWidth margin="normal" error={Boolean(error)}>
      <Autocomplete
        multiple
        fullWidth
        margin="normal"
        options={sportsList.map((s) => s.name)}
        getOptionLabel={(option) => option}
        value={selectedSport}
        onChange={(event, newValue) => {
          setSelectedSport(newValue); // Update the entire array
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Sport"
            error={Boolean(error)}
            helperText={error}
          />
        )}
      />
    </FormControl>
  );
}

export default SportsSelect;
