import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { resendActivationLink } from "redux/actions/authActions"; // Adjust import as necessary
import { Link, useNavigate } from "react-router-dom";

function ResendActivationEmail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { palette } = useTheme();
  const backgroundAlt = palette.background.alt;
  const primary = palette.primary.main;

  const isLargeScreen = useMediaQuery("(min-width: 1000px)");

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleBackToLogin = () => {
    navigate("/");
  };

  const handleResendEmail = async () => {
    // Reset message
    setMessage("");
    if (email) {
      try {
        // Dispatch the action to resend the activation link
        const response = await dispatch(resendActivationLink(email));
        if (response) {
          setMessage(
            "A new verification email has been sent. Please check your inbox."
          );
        } else {
          setMessage(
            "Failed to resend the verification email. Please try again."
          );
        }
      } catch (error) {
        setMessage("An error occurred. Please try again later.");
      }
    } else {
      setMessage("Please enter your email address.");
    }
  };

  return (
    <Box>
      <Box
        width="100%"
        backgroundColor={backgroundAlt}
        p="1rem 6%"
        textAlign="center"
      >
        <Typography color={primary} variant="h1" gutterBottom>
          Welcome to SHE IS ACTION!
        </Typography>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Let's get you started. Please verify your email address.
        </Typography>
      </Box>
      <Box
        width={isLargeScreen ? "50%" : "93%"}
        p="2rem"
        m="2rem auto"
        borderRadius="1.5rem"
        backgroundColor={backgroundAlt}
        data-testid="box"
        textAlign="center"
      >
        <Typography variant="h2" color="primary">
          Resend Activation Email
        </Typography>
        <img
          width="300px"
          height="auto"
          src="https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/4be28a67-aa94-4a44-8874-370c0a072e00/public"
          alt="verification email sent"
          className="verification-image"
        />
        <Typography variant="h5" color="primary" sx={{ mb: 2 }}>
          Enter your email address below to resend the verification email. If
          you don't see the email, check your spam folder.
        </Typography>
        <Box display="flex" flexDirection="column" gap={2} marginTop="20px">
          <TextField
            label="Email Address"
            variant="outlined"
            type="email"
            value={email}
            onChange={handleEmailChange}
            fullWidth
            // sx={{ mb: 2 }}
          />
          {message && (
            <Typography color="secondary" mt={2}>
              {message}
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleResendEmail}
            sx={{ width: 200, mx: "auto" }}
          >
            Resend Email
          </Button>
          <Button
            variant="contained"
            color="secondary"
            sx={{ width: 200, mx: "auto" }}
            onClick={handleBackToLogin}
          >
            Back to Login Page
          </Button>
          <Typography variant="body2" color="primary" marginTop="50px">
            If you have any questions, please{" "}
            <Link to="/contact-support">
              <Typography variant="span" fontWeight={700}>
                contact support
              </Typography>
            </Link>
            .
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default ResendActivationEmail;
