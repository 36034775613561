import { Button } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import React from "react";

function UpdateArchiveButtons({ handleUpdateArticle, handleArchiveArticle }) {
  return (
    <FlexBetween sx={{ margin: "2rem 0" }}>
      <Button variant="contained" color="primary" onClick={handleUpdateArticle}>
        Update Article
      </Button>
      <Button variant="contained" color="error" onClick={handleArchiveArticle}>
        Archive Article
      </Button>
    </FlexBetween>
  );
}

export default UpdateArchiveButtons;
