import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WidgetWrapper from "components/WidgetWrapper";

import SnackbarComponent from "components/SnackbarComponent";
import useNotificationSettingsState from "./hooks/useNotificationSettingsState";

function NotificationSettings() {
  const {
    notificationTypes,
    notificationPreferences,
    notificationFrequency,
    snackbarOpen,
    setSnackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    handleSaveChanges,
    handleFrequencyChange,
    handleToggle,
    enabledNotifications,
    getNotificationDescription,
    background,
    isLargeScreen,
  } = useNotificationSettingsState();

  return (
    <>
      <Box
        flexBasis={isLargeScreen ? "60%" : undefined}
        px={2}
        pb={isLargeScreen ? 0 : "4rem"}
      >
        <WidgetWrapper>
          <Typography variant="h3" gutterBottom>
            Notification Settings
          </Typography>
          <Typography variant="h5" gutterBottom>
            Occasionally we may still send you important notifications about
            your account and content outside of your preferred notification
            settings.
          </Typography>

          <Box backgroundColor={background} borderRadius={2} p={3} mb={2}>
            <Typography mb={3} variant="h5" gutterBottom>
              Email Notification Settings
            </Typography>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box display="flex" flexDirection="column">
                  <Typography variant="h6">
                    Email Notification Frequency
                  </Typography>
                  <Typography variant="body2">
                    {notificationFrequency === "none"
                      ? "You not receive notifications by email."
                      : notificationFrequency === "daily"
                      ? "You will receive a daily digest of notifications."
                      : "You will receive a weekly digest of notifications."}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  select
                  label="Frequency"
                  value={notificationFrequency}
                  onChange={handleFrequencyChange}
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="none">None</MenuItem>
                  <MenuItem value="daily">Daily Digest</MenuItem>
                  <MenuItem value="weekly">Weekly Digest</MenuItem>
                </TextField>
              </AccordionDetails>
            </Accordion>

            <Typography my={3} variant="h5" gutterBottom>
              What you will get notified about
            </Typography>

            {/* Loop through each notification type to display toggles */}
            {notificationTypes.map((type, index) => (
              <Accordion key={type + index}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="h6">{type}</Typography>
                    <Typography variant="body2">
                      {enabledNotifications(type).join(", ")}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1" gutterBottom>
                    {getNotificationDescription(type)}
                  </Typography>

                  {/* Display warning if Email Notification Frequency is 'None' but email is selected */}
                  {notificationFrequency === "none" &&
                    notificationPreferences[type].email && (
                      <Typography variant="body2" color="error" gutterBottom>
                        Warning: Email Notification Frequency is set to 'None'.
                        You will not receive email notifications despite having
                        them enabled for this type.
                      </Typography>
                    )}

                  <Typography variant="body2" gutterBottom>
                    {notificationPreferences[type].push &&
                      notificationPreferences[type].email &&
                      "You will receive in-application notifications as they happen and email notifications in a digest."}
                    {notificationPreferences[type].push &&
                      !notificationPreferences[type].email &&
                      "You will receive in-application notifications as they happen. "}
                    {!notificationPreferences[type].push &&
                      "You will not receive any notifications for this notification type. "}
                  </Typography>

                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={notificationPreferences[type].push}
                          onChange={() => handleToggle(type, "push")}
                        />
                      }
                      label="Push"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={notificationPreferences[type].email}
                          onChange={() => handleToggle(type, "email")}
                        />
                      }
                      label="Email"
                    />
                    {/* Additional notification methods can be added here */}
                  </FormGroup>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveChanges}
            mb={isLargeScreen ? 0 : "10rem"}
          >
            Save Changes
          </Button>
        </WidgetWrapper>
      </Box>
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        severity={snackbarSeverity}
      />
    </>
  );
}

export default NotificationSettings;
