import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import * as yup from "yup";

import { fetchBrand, updateBrand } from "redux/actions/brandActions";
import { fetchSports } from "redux/actions/sportActions";
import { patchUser } from "redux/state";

const useBrandEdit = ({ selectedBrandId }) => {
  /* Hook Declarations */
  const dispatch = useDispatch();
  // Use selectedBrandId if it's passed in, otherwise fall back to the one from the URL
  const { brandId: urlBrandId } = useParams();
  const isLargeScreen = useMediaQuery("(min-width: 1000px)");

  /* State Declareations */

  const [brandData, setBrandData] = useState();

  const [formErrors, setFormErrors] = useState({});
  const [originalManagers, setOriginalManagers] = useState([]);
  const [originalCopyWriters, setOriginalCopyWriters] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  /* Selectors */

  const brandId = selectedBrandId || urlBrandId;
  const isSelectedBrandId = selectedBrandId ? true : false;
  const brand = useSelector((state) => state.brand);
  const sportsList = useSelector((state) => state.sports);

  /* Validation Schema */

  const brandValidationSchema = yup.object().shape({
    name: yup.string().required("Brand name is required."),
    description: yup.string().test(
      "is-empty-html",
      "Description is required.",
      (value) => stripHtml(value).trim().length > 0 // Check if the text content is not empty
    ),
    locations: yup
      .array()
      .of(
        yup.object().shape({
          locationType: yup.string().required("Location type is required."),
          address: yup.string().required("Address is required."),
        })
      )
      .test(
        "first-location-type-required",
        "Location type is required",
        (locations) => {
          if (!locations || locations.length === 0) return true; // No error if no locations
          const firstLocation = locations[0];
          return !!firstLocation.locationType; // Check if the first location has a locationType
        }
      )
      .test(
        "first-location-address-required",
        "Address is required",
        (locations) => {
          if (!locations || locations.length === 0) return true; // No error if no locations
          const firstLocation = locations[0];
          return !!firstLocation.address; // Check if the first location has an address
        }
      ),
    phone: yup.string(),
    website: yup
      .string()
      .url("Invalid website URL")
      .required("Website is required."),
    sportsPromoted: yup.array().min(1, "A sport is required."),
    socialMedia: yup.object().shape({
      facebook: yup.string().url("Invalid URL"),
      twitter: yup.string().url("Invalid URL"),
      instagram: yup.string().url("Invalid URL"),
      linkedIn: yup.string().url("Invalid URL"),
      youtube: yup.string().url("Invalid URL"),
      tiktok: yup.string().url("Invalid URL"),
    }),
    // Add other fields as needed
  });

  /* Custom functions */

  const restructureErrors = (errors) => {
    const formattedErrors = {};
    Object.keys(errors).forEach((key) => {
      const parts = key.split(".");
      if (parts.length > 1) {
        if (!formattedErrors[parts[0]]) {
          formattedErrors[parts[0]] = {};
        }
        formattedErrors[parts[0]][parts[1]] = errors[key];
      } else {
        formattedErrors[key] = errors[key];
      }
    });
    return formattedErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Validate brandData with Yup
      await brandValidationSchema.validate(brandData, { abortEarly: false });
      if (brandData) {
        // Update brand
        await dispatch(updateBrand(brandId, brandData));

        // Update brandManagement field of managers
        await updateManagerBrands(brandId, brandData.managers);

        // Update brandArticleWriting field of copyWriters
        await updateCopyWriterBrands(brandId, brandData.copyWriters);
      }
      setSnackbarMessage("Brand updated successfully");
      setSnackbarSeverity("success");
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const errors = {};
        error.inner.forEach((e) => {
          errors[e.path] = e.message;
        });
        const nestedErrors = restructureErrors(errors);
        setFormErrors(nestedErrors);
        return;
      }

      console.error("Error updating brand:", error);
      setSnackbarMessage("Error updating brand");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const updateManagerBrands = async (brandId, managers) => {
    try {
      const managerIds = managers.map((manager) => manager._id);
      const originalManagerIds = originalManagers.map((manager) => manager._id);

      const managersToAdd = managers.filter(
        (manager) => !originalManagerIds.includes(manager._id)
      );
      const managersToRemove = originalManagers.filter(
        (manager) => !managerIds.includes(manager._id)
      );

      await Promise.all([
        ...managersToAdd.map((manager) =>
          dispatch(
            patchUser(manager._id, {
              $addToSet: { brandManagement: brandId },
            })
          )
        ),
        ...managersToRemove.map((manager) =>
          dispatch(
            patchUser(manager._id, {
              $pull: { brandManagement: brandId },
            })
          )
        ),
      ]);
      setSnackbarMessage("Managers updated successfully");
      setSnackbarSeverity("success");
    } catch (error) {
      console.error("Error updating manager brands:", error);
      setSnackbarMessage("Error updating manager brands");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const updateCopyWriterBrands = async (brandId, copyWriters) => {
    try {
      const copyWriterIds = copyWriters.map((copyWriter) => copyWriter._id);

      const originalCopyWriterIds = originalCopyWriters.map(
        (copyWriter) => copyWriter._id
      );

      const copyWritersToAdd = copyWriters.filter(
        (copyWriter) => !originalCopyWriterIds.includes(copyWriter._id)
      );

      const copyWritersToRemove = originalCopyWriters.filter(
        (copyWriter) => !copyWriterIds.includes(copyWriter._id)
      );

      await Promise.all([
        ...copyWritersToAdd.map((copyWriter) =>
          dispatch(
            patchUser(copyWriter._id, {
              $addToSet: { brandCopywriting: brandId },
            })
          )
        ),
        ...copyWritersToRemove.map((copyWriter) =>
          dispatch(
            patchUser(copyWriter._id, {
              $pull: { brandCopywriting: brandId },
            })
          )
        ),
      ]);
      setSnackbarMessage("Copywriters updated successfully");
      setSnackbarSeverity("success");
    } catch (error) {
      console.error("Error updating copywriter brands:", error);
      setSnackbarMessage("Error updating copywriter brands");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleSportChange = (newValues) => {
    setBrandData((prevData) => ({
      ...prevData,
      sportsPromoted: newValues,
    }));
  };

  // Function to strip HTML tags
  const stripHtml = (htmlString) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = htmlString;
    return tmp.textContent || tmp.innerText || "";
  };

  /* Effects */

  useEffect(() => {
    if (brandId) {
      // Fetch the brand data
      dispatch(fetchBrand(brandId))
        .then((brand) => {
          // Update brandData with the fetched brand data
          if (brand) {
            setBrandData(brand);
            setOriginalManagers(brand.managers);
            setOriginalCopyWriters(brand.copyWriters);
          }
        })
        .catch((error) => {
          console.error("Error fetching brand:", error);
          setSnackbarMessage("Error fetching brand");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        });
    }
  }, [dispatch, brandId]);

  useEffect(() => {
    if (!sportsList.length) dispatch(fetchSports());
  }, [dispatch, sportsList.length]);

  return {
    brand,
    brandData,
    setBrandData,
    formErrors,
    isLargeScreen,
    handleSubmit,
    sportsList,
    handleSportChange,
    isSelectedBrandId,
    snackbarOpen,
    setSnackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    brandValidationSchema,
  };
};

export default useBrandEdit;
