import { Autocomplete, FormControl, TextField } from "@mui/material";
import React from "react";

function BrandSportSelectionEdit({
  brandData,
  formErrors,
  sportsList,
  handleSportChange,
}) {
  return (
    <FormControl
      variant="outlined"
      fullWidth
      margin="normal"
      style={{ marginTop: "-5px" }}
    >
      <Autocomplete
        multiple={brandData.verified} // Set multiple to true if brand is verified
        options={sportsList ? sportsList.map((s) => s.name) : []}
        // if brandData.verified is true, then the selectedSport will be an array of sports else use the first sportPromoted in the array
        value={
          brandData.verified
            ? brandData.sportsPromoted || []
            : brandData.sportsPromoted
            ? [brandData.sportsPromoted[0]]
            : []
        }
        // value={brandData.sportsPromoted || []} // Use sportsPromoted instead of selectedSport
        onChange={(event, newValues) => {
          handleSportChange(newValues);
        }}
        getOptionLabel={(option) => (option ? String(option) : "")}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Sport"
            error={!!formErrors.sportsPromoted}
            helperText={formErrors.sportsPromoted}
            fullWidth
            margin="normal"
          />
        )}
      />
    </FormControl>
  );
}

export default BrandSportSelectionEdit;
