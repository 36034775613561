import { Box, useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Navbar from "scenes/navbar/index.jsx";
import FriendListWidget from "scenes/widgets/FriendListWidget";
import MyPostWidget from "scenes/widgets/myPostWidget";
import PostsWidget from "scenes/widgets/PostsWidget";
import UserWidget from "scenes/widgets/UserWidget";
import { fetchUser } from "redux/state"; // import fetchUser action
import Footer from "components/Footer";
import BottomMenu from "components/bottomMenu/BottomMenu";
import YourFollowedBrands from "scenes/widgets/YourFollowedBrands";
import UserGroupsFollowed from "scenes/widgets/UserGroupsFollowed";

const ProfilePage = ({ socket }) => {
  const { userId } = useParams();
  const dispatch = useDispatch(); // get the dispatch function
  const isLargeScreen = useMediaQuery("(min-width:1000px)");
  const user = useSelector((state) => state.user); // get the user from the state
  const isLoggedInUser = userId === user._id;

  useEffect(() => {
    dispatch(fetchUser(userId)); // dispatch the fetchUser action
  }, [dispatch, userId]); // add dispatch and userId as dependencies

  if (!user) return null;

  return (
    <>
      <Box display="flex" flexDirection="column" minHeight="100vh">
        {isLargeScreen && <Navbar socket={socket} />}{" "}
        {/* Render Navbar only if isLargeScreen is true */}
        <Box
          flexGrow={1}
          overflow="auto"
          width="100%"
          padding={isLargeScreen ? "0 6%" : "0"}
          display={isLargeScreen ? "flex" : "block"}
          gap="2rem"
          justifyContent="center"
        >
          <Box flexBasis={isLargeScreen ? "35%" : undefined}>
            <UserWidget userId={userId} socket={socket} />
            <Box m="1rem 0" />
            <FriendListWidget userId={userId} socket={socket} />
            <YourFollowedBrands />
            <UserGroupsFollowed />
          </Box>
          <Box
            flexBasis={isLargeScreen ? "60%" : undefined}
            mt={isLargeScreen ? undefined : "2rem"}
          >
            {isLoggedInUser && (
              <MyPostWidget picturePath={user.picturePath} isProfile={true} />
            )}
            <PostsWidget userId={userId} isProfile={true} socket={socket} />
          </Box>
        </Box>
        <Footer />
      </Box>

      {!isLargeScreen && <BottomMenu />}
    </>
  );
};

export default ProfilePage;
