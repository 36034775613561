import {
  setMessages,
  addNewMessage,
  unreadMessagesCount,
  resetUnreadMessagesCount,
  unreadMessagesCountForChat,
} from "redux/state";

const BASE_URL = process.env.REACT_APP_BASE_URL;

// Fetch messages for a specific chat
export const fetchMessages =
  (chatId, page = 1, limit = 50) =>
  async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/messages/${chatId}?page=${page}&limit=${limit}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch messages");
    }

    const data = await response.json();
    dispatch(setMessages(data));
    return data;
  };

// Create a new message
export const createMessage =
  (chatId, sender, content) => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/messages/${chatId}/messages`, {
      // Adjust URL if needed
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ chat: chatId, sender, content }),
    });

    if (!response.ok) {
      throw new Error("Failed to create message");
    }

    // Assuming the server responds with the newly created message:
    const newMessage = await response.json();

    dispatch(addNewMessage(newMessage));

    return newMessage;
  };

// Fetch unread messages for a user
export const fetchUnreadMessages = () => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/messages/messages/unread`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch unread messages");
  }

  const data = await response.json();

  // Assuming data is an array of unread messages
  dispatch(unreadMessagesCount(data.length)); // Updating the unread messages count

  return data.length;
};

// Fetch unread messages for a specific chat
export const fetchUnreadMessagesForChat =
  (chatId) => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/messages/messages/unread/${chatId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch unread messages");
    }

    const data = await response.json();

    // Assuming data is an array of unread messages
    dispatch(
      unreadMessagesCountForChat({ chatId: chatId, count: data.length })
    ); // Updating the unread messages count

    return data.length;
  };

// Mark a message as read
export const markAsRead = (messageId) => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/messages/${messageId}`, {
    method: "PUT",
    headers: { Authorization: `Bearer ${token}` },
  });

  if (!response.ok) {
    throw new Error("Failed to mark message as read");
  }

  const data = await response.json();

  dispatch(resetUnreadMessagesCount()); // Decrement or reset the unread messages count. Adjust based on your logic.

  return data;
};

export const markAllUserMessagesAsRead =
  (chatId) => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/messages/markAllRead/${chatId}`, {
      method: "PUT",
      headers: { Authorization: `Bearer ${token}` },
    });

    if (!response.ok) {
      throw new Error("Failed to mark messages as read");
    }

    const data = await response.json();

    return data;
  };
