import { useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addNotification,
  fetchNotifications,
  markAllOtherNotificationsAsRead,
} from "redux/actions/notificationActions";
import searchLight from "assets/light/search.png";
import searchDark from "assets/dark/search.png";
import {
  addToSearchHistory,
  getSearchHistory,
  removeFromSearchHistory,
  searchUsers,
} from "redux/actions/searchActions";

export const useNavState = ({ socket }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const isLargeScreen = useMediaQuery("(min-width: 1000px)");

  const theme = useTheme();
  const primary = theme.palette.primary.main;
  const primaryLight = theme.palette.primary.light;
  const primaryDark = theme.palette.primary.dark;
  const alt = theme.palette.background.alt;
  const backgroundAlt = theme.palette.background.alt;
  const neutralLight = theme.palette.neutral.light;
  const mediumLight = theme.palette.neutral.mediumLight;
  const mode = theme.palette.mode;

  const fullName = user ? `${user.firstName} ${user.lastName}` : "";
  const profilePic = user?.picturePath || "";

  const userId = user?._id || null;

  const unreadMessagesCount = useSelector((state) => state.unreadMessagesCount);

  const notifications = useSelector((state) => state.notifications) || [];

  const unreadNotificationsCount = notifications.totalUnread || 0;

  const [isNotificationSidebarOpen, setIsNotificationSidebarOpen] =
    useState(false);
  const [isSearchSidebarOpen, setIsSearchSidebarOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchHistory, setSearchHistory] = useState([]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleAddToSearchHistory = (userId, itemType, itemId) => {
    dispatch(addToSearchHistory(userId, itemType, itemId));
  };

  const handleRemoveSearchHistoryItem = (userId, itemType, itemId) => {
    dispatch(removeFromSearchHistory(userId, itemType, itemId));

    const updatedSearchHistory = searchHistory.filter(
      (item) => item.refId._id !== itemId
    );

    setSearchHistory(updatedSearchHistory);
  };

  useEffect(() => {
    if (isSearchSidebarOpen && userId) {
      dispatch(getSearchHistory(userId))
        .then((historyData) => {
          if (historyData) {
            setSearchHistory(historyData);
          }
        })
        .catch((error) => {
          console.error("Error fetching search history:", error);
        });
    }
  }, [isSearchSidebarOpen, userId, dispatch]);

  useEffect(() => {
    if (searchTerm) {
      const limit = 8;

      dispatch(searchUsers(searchTerm, page, limit))
        .then((response) => {
          // if (response && Array.isArray(response.data)) {
          if (response && response.data) {
            setSearchResults(response.data);
            setTotalPages(response.totalPages);
          } else {
            console.warn(
              "searchUsers did not return the expected format",
              response
            );
            setSearchResults([]);
          }
        })

        .catch((error) => {
          console.log("An error occurred while searching:", error);
        });
    } else {
      setSearchResults([]);
    }
  }, [searchTerm, dispatch, user._id, page]);

  useEffect(() => {
    // Fetch initial notifications when the component mounts
    const fetchInitialNotifications = async () => {
      dispatch(fetchNotifications(userId, page, 25));
    };

    fetchInitialNotifications();
  }, [userId, dispatch, page]);

  const handleLoadMoreNotifications = () => {
    const nextPage = page + 1;
    setPage(nextPage); // Update the page state to trigger the useEffect
  };

  const handleMarkAllOtherNotificationsAsRead = () => {
    dispatch(markAllOtherNotificationsAsRead(userId));
  };

  const handleProfileClick = () => {
    navigate(`/profile/${userId}`);
  };

  const handleGroupsClick = () => {
    navigate("/groups");
  };

  const handleNewsClick = () => {
    navigate("/news");
  };

  const handleFindMembersClick = () => {
    navigate("/findMembers");
  };

  const handleChatClick = () => {
    navigate("/chat");
  };

  const handleBrandClick = () => {
    navigate("/brand");
  };

  const handleAdminClick = () => {
    navigate("/admin");
  };

  const handleBrandDirectoryClick = () => {
    navigate("/brand-directory");
  };

  const handleSportDirectoryClick = () => {
    navigate("/sports");
  };

  const handlePostFeedPageClick = () => {
    navigate("/post");
  };

  const handleSettingsClick = () => {
    navigate("/settings");
  };

  useEffect(() => {
    if (!socket) return;
    socket.on("message", (newMessage) => {
      dispatch(addNotification(newMessage));
    });
    return () => {
      socket.off("message");
    };

    // eslint-disable-next-line
  }, [socket]);

  return {
    user,
    navigate,
    isLargeScreen,
    theme,
    primary,
    primaryLight,
    primaryDark,
    backgroundAlt,
    neutralLight,
    mediumLight,
    alt,
    fullName,
    profilePic,
    unreadMessagesCount,
    handleProfileClick,
    handleGroupsClick,
    handleNewsClick,
    handleFindMembersClick,
    handleChatClick,
    handleBrandClick,
    handleAdminClick,
    handleBrandDirectoryClick,
    handlePostFeedPageClick,
    handleSportDirectoryClick,
    handleSettingsClick,
    isSearchSidebarOpen,
    setIsSearchSidebarOpen,
    mode,
    searchLight,
    searchDark,
    searchTerm,
    handleSearch,
    searchResults,
    page,
    setPage,
    totalPages,
    handleAddToSearchHistory,
    searchHistory,
    handleRemoveSearchHistoryItem,
    isNotificationSidebarOpen,
    setIsNotificationSidebarOpen,
    notifications,
    unreadNotificationsCount,
    handleMarkAllOtherNotificationsAsRead,
    handleLoadMoreNotifications,
  };
};
