import { Box, useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Navbar from "scenes/navbar/index.jsx";
import PostWidget from "scenes/widgets/PostWidget";
import UserWidget from "scenes/widgets/UserWidget";
import { fetchPost } from "redux/state";
import NavbarPublic from "scenes/navbar/navbarPublic";

const PostPage = ({ socket }) => {
  const { postId } = useParams();
  const dispatch = useDispatch(); // get the dispatch function
  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  useEffect(() => {
    dispatch(fetchPost(postId)); // dispatch the fetchPost action
  }, [dispatch, postId]); // add dispatch and postId as dependencies

  const post = useSelector((state) => state.post); // get the post from the state

  const isDeleted = useSelector((state) => state.post?.isDeleted) || false;

  const user = useSelector((state) => state.user);

  let isPublicPage;
  let userId;

  if (user) {
    userId = user?._id;
    isPublicPage = false;
  } else {
    isPublicPage = true;
  }

  if (!post) return null;

  return (
    <Box height="100vh" display="flex" flexDirection="column">
      {!isPublicPage ? <Navbar socket={socket} /> : <NavbarPublic />}

      {/* Render Navbar only if isLargeScreen is true */}
      <Box
        flexGrow={1}
        overflow="auto"
        width="100%"
        padding={isLargeScreen ? "0 6%" : "0"}
        display={isLargeScreen ? "flex" : "block"}
        gap="2rem"
        justifyContent="center"
      >
        {!isPublicPage && (
          <Box flexBasis={isLargeScreen ? "26%" : undefined}>
            <UserWidget userId={post.userId} />
          </Box>
        )}
        <Box
          flexBasis={isLargeScreen ? "42%" : undefined}
          mt={isLargeScreen ? undefined : "2rem"}
        >
          {isDeleted ? (
            <Box
              textAlign="center"
              fontSize="1.5rem"
              color="secondary.main"
              fontWeight="bold"
            >
              This post has been deleted
            </Box>
          ) : (
            <PostWidget
              postId={postId}
              postUserId={post.userId}
              name={`${post.firstName} ${post.lastName}`}
              description={post.description}
              picturePath={post.picturePath}
              userPicturePath={post.userPicturePath}
              likes={post.likes}
              comments={post.comments}
              createdAt={post.createdAt}
              lastEdited={post.lastEdited}
              socket={socket}
              taggedUsers={post.taggedUsers}
              group={post.group}
              userId={userId}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PostPage;
