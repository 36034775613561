import { Avatar, Box, useMediaQuery, useTheme } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBrands } from "redux/actions/brandActions";
import DataGridCustomToolbar from "../components/DataGridCustomToolbar";
import { DataGrid } from "@mui/x-data-grid";
import { EditOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import parsePhoneNumberFromString from "libphonenumber-js";
import { BusinessProfileClick } from "helpers/businessHelpers/businessProfileClick";

function BrandGrid() {
  const { palette } = useTheme();
  const backgroundAlt = palette.background.alt;
  const primaryLight = palette.primary.light;
  const primaryDark = palette.primary.dark;

  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");

  const [searchInput, setSearchInput] = useState("");

  const dispatch = useDispatch();
  const brands = useSelector((state) => state.brands) || [];
  const totalBrands = useSelector((state) => state.totalBrands) || 0;

  const navigate = useNavigate();

  const handleEditClick = (brandId) => {
    navigate(`/brand/${brandId}/edit`);
    BusinessProfileClick(dispatch, { brandId, fromPage: "brandGrid" });
  };

  useEffect(() => {
    dispatch(fetchBrands(page + 1, pageSize, sort, search));
  }, [page, pageSize, sort, search, dispatch]);

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 0.75,
    },
    {
      field: "name",
      headerName: "Brand Name",
      flex: 1,
      renderCell: (params) => {
        const avatarUrl = params.row.picturePath.endsWith("/public")
          ? params.row.picturePath.replace("/public", "/128x128")
          : // : `${params.row.picturePath}/128x128`;
            params.row.picturePath;
        return (
          <Box display="flex" alignItems="center">
            <Avatar src={avatarUrl} alt={params.value} sx={{ mr: 1 }} />
            {params.value}
          </Box>
        );
      },
    },
    {
      field: "website",
      headerName: "Website",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Telephone",
      flex: 1,
      renderCell: (params) => {
        try {
          const phoneNumber = parsePhoneNumberFromString(params.value);
          return phoneNumber ? phoneNumber.formatInternational() : params.value;
        } catch (error) {
          // In case of an error, return the original value
          return params.value;
        }
      },
      sortable: false,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      // valueGetter: (params) => moment(params.value).format("DD/MM/YYYY"),
      renderCell: (params) => moment(params.value).format("DD/MM/YYYY"),
    },
    {
      field: "verified",
      headerName: "Verified",
      flex: 0.75,
      valueGetter: (params) => (params.value ? "Yes" : "No"),
    },
    {
      field: "edit",
      headerName: "Edit",
      flex: 0.75,
      renderCell: (params) => {
        const brandId = params.row._id;
        return (
          <button
            style={{ backgroundColor: "transparent", border: "none" }}
            onClick={() => handleEditClick(brandId)}
          >
            <EditOutlined sx={{ color: primaryDark }} />
          </button>
        );
      },
      sortable: false,
    },
  ];

  return (
    <Box
      m={isLargeScreen ? "1.5rem 2rem 2rem 3rem" : "1.5rem 0 2rem 0"}
      mt="40px"
      height="75vh"
      sx={{
        "& .MuiDataGrid-root": {
          // border: "none",
        },
        "& .MuiDataGrid-cell": {
          // borderBottom: "none",
          whiteSpace: "normal",
          wordBreak: "break-word",
          overflow: "visible",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: backgroundAlt,
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: backgroundAlt,
        },
        "& .MuiDataGrid-footerContainer": {
          backgroundColor: primaryLight,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {},
        "& .MuiDataGrid-row": {
          height: "auto", // This ensures the row height adapts to content
        },
      }}
    >
      <DataGrid
        getRowId={(row) => {
          return row._id;
        }}
        rows={brands}
        columns={columns}
        page={page}
        pageSize={pageSize}
        rowCount={totalBrands}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        paginationMode="server"
        onPageChange={(params) => {
          return setPage(params);
        }}
        onPageSizeChange={(newPageSize) => {
          setPage(0);
          setPageSize(newPageSize);
        }}
        onSortModelChange={(model) => {
          if (model.length) {
            setSort(`${model[0].field}:${model[0].sort}`);
          } else {
            setSort("");
          }
        }}
        components={{
          Toolbar: DataGridCustomToolbar,
        }}
        rowHeight={50}
        autoHeight={true}
        componentsProps={{
          toolbar: {
            onSearch: (value) => {
              setSearch(value);
              setPage(0); // Reset page when a new search is executed
            },
            searchInput,
            setSearchInput,
            setSearch,
          },
        }}
      />
    </Box>
  );
}

export default BrandGrid;
