import { setLocations } from "redux/state";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const fetchLocations = (input) => async (dispatch, getState) => {
  const token = getState().token;
  try {
    const response = await fetch(`${BASE_URL}/location/search?input=${input}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!response.ok) {
      throw new Error("Server returned an error: " + response.statusText);
    }
    const data = await response.json();

    dispatch(setLocations({ locations: data })); // dispatching an action to set locations
  } catch (error) {
    console.error("Error fetching data from server:", error);
  }
};

export const fetchLocationDetails =
  (place_id) => async (dispatch, getState) => {
    const token = getState().token;
    try {
      const response = await fetch(
        `${BASE_URL}/location/details?place_id=${place_id}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (!response.ok) {
        throw new Error("Server returned an error: " + response.statusText);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data from server:", error);
    }
  };
