import { useTheme } from "@emotion/react";
import {
  ChevronLeft,
  ChevronRightOutlined,
  DrawOutlined,
  FeedOutlined,
  Groups2Outlined,
  HomeOutlined,
} from "@mui/icons-material";
import {
  Box,
  ClickAwayListener,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchBrand, fetchUserBrands } from "redux/actions/brandActions";

const navItems = [
  { text: "Dashboard", icon: <HomeOutlined /> },
  { text: "Articles", icon: null },
  { text: "Create", icon: <DrawOutlined /> },
  { text: "History", icon: <FeedOutlined /> },
  { text: "Administration", icon: null },
  { text: "Landing", icon: <Groups2Outlined /> },
];

function BrandSidebar({
  drawerWidth,
  isSideBarOpen,
  setIsSideBarOpen,
  isLargeScreen,
  setSelectedBrandId,
  selectedBrandId,
}) {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [brandId, setBrandId] = useState("");
  const userBrands = useSelector((state) => state.userBrands);
  const userId = user?._id;
  const dispatch = useDispatch();

  const { palette } = useTheme();
  const primary = palette.primary.main;
  const backgroundColor = palette.background.alt;
  const dark = palette.neutral.dark;

  useEffect(() => {
    const lastPath = pathname.split("/").pop();
    setActive(lastPath);
  }, [pathname]);

  useEffect(() => {
    const fetchBrands = async () => {
      await dispatch(fetchUserBrands(userId));
      setLoading(false);
    };
    fetchBrands();
  }, [dispatch, userId]);

  useEffect(() => {
    if (!loading && !userBrands.length) {
      navigate("/home");
    }
  }, [loading, userBrands, navigate]);

  // Initial load: set brandId and selectedBrandId to the first user's brand
  useEffect(() => {
    if (userBrands.length > 0) {
      setBrandId(userBrands[0]._id);
      setSelectedBrandId(userBrands[0]._id); // Initial synchronization
    }
  }, [userBrands, setSelectedBrandId]);

  // Synchronize selectedBrandId with brandId, but avoid infinite loop
  useEffect(() => {
    if (brandId !== selectedBrandId) {
      setSelectedBrandId(brandId);
    }
  }, [brandId, selectedBrandId, setSelectedBrandId]);

  useEffect(() => {
    if (selectedBrandId) {
      dispatch(fetchBrand(selectedBrandId));
    }
  }, [dispatch, selectedBrandId]);

  const handleBrandChange = (e) => {
    const newBrandId = e.target.value;
    if (newBrandId !== brandId) {
      setBrandId(newBrandId);
    }
  };

  const DrawerContent = (
    <Box component="nav">
      {isSideBarOpen && (
        <Drawer
          open={isSideBarOpen}
          onClose={() => setIsSideBarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: primary,
              backgroundColor: backgroundColor,
              boxSizing: "border-box",
              borderWidth: isLargeScreen ? 0 : "2px",
              width: drawerWidth,
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 2rem 2rem 3rem">
              <FlexBetween color={primary}>
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <Typography variant="h5" fontWeight="bold">
                    BRAND PORTAL
                  </Typography>
                </Box>
                {!isLargeScreen && (
                  <IconButton onClick={() => setIsSideBarOpen(!isSideBarOpen)}>
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>

            <FormControl
              fullWidth
              margin="normal"
              sx={{
                marginRight: "1rem",
              }}
            >
              <InputLabel id="brand-label">Brand</InputLabel>
              <Select
                labelId="brand-label"
                value={brandId}
                onChange={handleBrandChange}
              >
                {userBrands.map((brand) => (
                  <MenuItem value={brand._id} key={brand._id}>
                    {brand.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <List>
              {navItems.map(({ text, icon }) => {
                if (!icon) {
                  return (
                    <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                      {text}
                    </Typography>
                  );
                }

                const lcText = text.toLowerCase();
                return (
                  <ListItem key={text} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        navigate(`${lcText}`);
                        setActive(lcText);
                      }}
                      sx={{
                        backgroundColor:
                          active === lcText ? primary : "transparent",
                        color: active === lcText ? backgroundColor : dark,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ml: "2rem",
                          color: active === lcText ? backgroundColor : dark,
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                      {active === lcText && (
                        <ChevronRightOutlined sx={{ ml: "auto" }} />
                      )}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Drawer>
      )}
    </Box>
  );

  return !isLargeScreen && isSideBarOpen ? (
    <ClickAwayListener onClickAway={() => setIsSideBarOpen(false)}>
      {DrawerContent}
    </ClickAwayListener>
  ) : (
    DrawerContent
  );
}

export default BrandSidebar;
