import React from "react";
import { useOutletContext } from "react-router-dom";
import BrandEdit from "scenes/admin/directory/BrandEdit";

function BrandLandingPage() {
  const { selectedBrandId } = useOutletContext();

  return <BrandEdit selectedBrandId={selectedBrandId} />;
}

export default BrandLandingPage;
