import React from "react";
import { Typography } from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import FlexBetween from "components/FlexBetween";
import useAdvertWidgetState from "./hooks/useAdvertWidgetState";

const AdvertWidget = () => {
  const {
    currentSponsor,
    dark,
    medium,
    main,
    formatWebsiteAddress,
    sanitizeContent,
    sponsorProfileClick,
  } = useAdvertWidgetState();

  if (
    !currentSponsor.imagePath ||
    !currentSponsor.name ||
    !currentSponsor.website ||
    !currentSponsor.description
  ) {
    return null;
  }

  return (
    <WidgetWrapper>
      <FlexBetween>
        <Typography color={dark} variant="h6" fontWeight="500">
          Trusted Partners
        </Typography>
        {/* <Typography color={medium}>Create Ad</Typography> */}
      </FlexBetween>
      <img
        src={currentSponsor.imagePath}
        alt="Sponsor"
        style={{
          width: "100%", // This makes the image use the full width
          height: "auto", // This ensures the image maintains its aspect ratio

          // height: "250px", // Adjusts the height automatically to maintain aspect ratio

          //display: "block", // Ensures the image is treated as a block-level element, removing any space below it
          borderRadius: "0.75rem",
          margin: "0.75rem 0",
          objectFit: "cover", // This is the key to maintaining aspect ratio
        }}
      />
      <FlexBetween>
        <Typography color={main}>{currentSponsor.name}</Typography>
        <Typography
          color={main}
          onClick={() => sponsorProfileClick({ fromPage: "widget" })}
        >
          <a
            href={currentSponsor.website}
            target="_blank"
            rel="noopener noreferrer"
          >
            {formatWebsiteAddress(currentSponsor.website)}
          </a>
        </Typography>
      </FlexBetween>
      <Typography
        color={medium}
        m="0.5rem 0"
        dangerouslySetInnerHTML={sanitizeContent(currentSponsor.description)}
      />
    </WidgetWrapper>
  );
};

export default AdvertWidget;
