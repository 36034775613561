import {
  Box,
  FormControlLabel,
  Switch,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import React from "react";
import { useDispatch } from "react-redux";
import { setMode } from "redux/state";

function DarkMode() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const mode = theme.palette.mode;
  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  const handleModeChange = () => {
    dispatch(setMode());
  };

  return (
    <Box flexBasis={isLargeScreen ? "60%" : undefined} px={2}>
      <WidgetWrapper>
        <Box
          component="h3"
          sx={{
            fontSize: "1.25rem",
            fontWeight: 500,
            mb: 2,
          }}
        >
          Dark mode
        </Box>
        <Box
          component="p"
          sx={{
            fontSize: "1rem",
            color: "text.secondary",
            mb: 2,
          }}
        >
          Dark mode is a setting that changes the color scheme of the app to a
          dark background. This setting is useful for when you are in a low
          light environment.
        </Box>
        <Box
          component="p"
          sx={{
            fontSize: "1rem",
            color: "text.secondary",
            mb: 2,
          }}
        >
          You can change the color scheme of the app by toggling the switch
          below.
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Switch
                checked={mode === "dark"}
                onChange={handleModeChange}
                name="mode"
                color="primary"
              />
            }
            label={mode === "dark" ? "Dark mode" : "Light mode"}
          />
        </Box>
      </WidgetWrapper>
    </Box>
  );
}

export default DarkMode;
