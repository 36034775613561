import { FavoriteBorderOutlined, FavoriteOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import AddComment from "components/addComment";
import DOMPurify from "dompurify";
import moment from "moment";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

function PostWidgetComments({
  isComments,
  comments,
  postId,
  closeComments,
  socket,
  postUserId,
  lightBackground,
  main,
  primary,
  secondary,
  loggedInUser,
  handlePatchLikeComment,
  refreshPosts,
  commentLikesUserNames,
  handleMouseEnterCommentLikeButton,
  neutralMedium,
  handleSoftBlockUser,
  handleBlockUser,
  anchorEl,
  showTooltip,
  handleClick,
  handleClose,
  handleReportComment,
  handleReportCommentUser,
}) {
  return (
    isComments && (
      <Box mt="0.5rem">
        {comments.map((comment, i) => (
          <Box key={comment._id}>
            <Box
              sx={{
                backgroundColor: lightBackground,
                borderRadius: "0.75rem",
                padding: "0.5rem",
                marginBottom: "0.5rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              {loggedInUser ? (
                <Link
                  to={`/profile/${comment.authorId}`}
                  style={{ textDecoration: "none" }}
                >
                  <Avatar
                    src={
                      comment.userPicturePath.endsWith("/public")
                        ? comment.userPicturePath.replace("/public", "/128x128")
                        : `${comment.userPicturePath}/128x128`
                    }
                    sx={{ width: "40px", height: "40px" }}
                  />
                </Link>
              ) : (
                <Avatar
                  src={
                    comment.userPicturePath.endsWith("/public")
                      ? comment.userPicturePath.replace("/public", "/128x128")
                      : `${comment.userPicturePath}/128x128`
                  }
                  sx={{ width: "40px", height: "40px" }}
                />
              )}
              <FlexBetween
                sx={{
                  width: "100%",
                }}
              >
                <Box>
                  <div
                    style={{ color: main, margin: "0", paddingLeft: "1rem" }}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(comment.text),
                    }}
                  />

                  <Typography sx={{ color: primary, m: "0", pl: "1rem" }}>
                    <small>
                      By{" "}
                      {loggedInUser ? (
                        <Link
                          to={`/profile/${comment.authorId}`}
                          style={{ textDecoration: "none" }}
                        >
                          {comment.author}
                        </Link>
                      ) : (
                        comment.author
                      )}{" "}
                      {moment(comment.date).fromNow()}
                    </small>
                  </Typography>
                </Box>
                {loggedInUser && loggedInUser._id !== comment.authorId && (
                  <div>
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => handleReportComment(comment._id)}
                      >
                        <Box>
                          <Typography variant="h6">
                            Report Post Comment
                          </Typography>
                          <Typography variant="subtitle2" color={neutralMedium}>
                            Report this post comment anonomously
                          </Typography>
                        </Box>
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          handleReportCommentUser(comment.authorId)
                        }
                      >
                        <Box>
                          <Typography variant="h6">Report User</Typography>
                          <Typography variant="subtitle2" color={neutralMedium}>
                            Report this user anonomously
                          </Typography>
                        </Box>
                      </MenuItem>

                      <Divider variant="middle" />

                      <MenuItem
                        onClick={() => handleSoftBlockUser(comment.authorId)}
                      >
                        <Box>
                          <Typography variant="h6">Not For Me</Typography>
                          <Typography variant="subtitle2" color={neutralMedium}>
                            Don't show me this user
                          </Typography>
                        </Box>
                      </MenuItem>

                      <MenuItem
                        onClick={() => handleBlockUser(comment.authorId)}
                      >
                        <Box>
                          <Typography variant="h6">Block User</Typography>
                          <Typography variant="subtitle2" color={neutralMedium}>
                            Block this user
                          </Typography>
                        </Box>
                      </MenuItem>

                      <Divider variant="middle" />

                      <MenuItem onClick={handleClose}>Close</MenuItem>
                    </Menu>
                  </div>
                )}
              </FlexBetween>
            </Box>
            <Box display="flex" alignItems="center" marginTop="-15px">
              {showTooltip ? (
                <Tooltip
                  title={
                    <Fragment>
                      {commentLikesUserNames.map((name, index) => (
                        <Typography
                          key={index}
                          variant="body2"
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {name}
                        </Typography>
                      ))}
                    </Fragment>
                  }
                  placement="top"
                  arrow
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -10],
                        },
                      },
                    ],
                  }}
                >
                  <IconButton
                    onClick={() =>
                      handlePatchLikeComment(
                        comment._id,
                        comment.authorId,
                        "likeComment"
                      )
                    }
                    onMouseEnter={() =>
                      handleMouseEnterCommentLikeButton(comment._id)
                    }
                  >
                    {Object.keys(comment.likes).includes(loggedInUser?._id) ? (
                      <FavoriteOutlined sx={{ color: secondary }} />
                    ) : (
                      <FavoriteBorderOutlined />
                    )}
                  </IconButton>
                </Tooltip>
              ) : (
                <IconButton
                  onClick={() =>
                    handlePatchLikeComment(
                      comment._id,
                      comment.authorId,
                      "likeComment"
                    )
                  }
                  onMouseEnter={() =>
                    handleMouseEnterCommentLikeButton(comment._id)
                  }
                >
                  {Object.keys(comment.likes).includes(loggedInUser?._id) ? (
                    <FavoriteOutlined sx={{ color: secondary }} />
                  ) : (
                    <FavoriteBorderOutlined />
                  )}
                </IconButton>
              )}
              <Typography>{Object.keys(comment.likes).length}</Typography>
            </Box>
          </Box>
        ))}
        {loggedInUser ? (
          <AddComment
            postId={postId}
            closeComments={closeComments}
            socket={socket}
            postUserId={postUserId}
            refreshPosts={refreshPosts}
          />
        ) : (
          <Typography variant="caption">
            Please{" "}
            <Typography variant="span" color="primary" fontWeight={700}>
              <Link to="/">log in</Link>{" "}
            </Typography>
            to comment
          </Typography>
        )}
      </Box>
    )
  );
}

export default PostWidgetComments;
