import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Footer from "components/Footer";
import BottomMenu from "components/bottomMenu/BottomMenu";
import React from "react";
import Navbar from "scenes/navbar";

function PrivacyPolicy({ socket }) {
  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      marginBottom="1rem"
    >
      <Navbar socket={socket} />
      <Box
        width="100%"
        padding={isLargeScreen ? "0 6%" : "0 1rem"}
        display="flex"
        flexDirection="column"
        gap="1rem"
        mb={5}
      >
        <Box>
          <Typography variant="h4">Privacy Policy</Typography>
          <Typography
            variant="body1"
            fontWeight={700}
            style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}
            mt={2}
          >
            Important information and who we are
          </Typography>
          <Typography mt={2}>
            The She Is Action website (“site”) is provided by She Is Action Ltd
            (
            <Typography component="span" fontWeight={700} fontStyle="italic">
              "we"
            </Typography>
            ,{" "}
            <Typography component="span" fontWeight={700} fontStyle="italic">
              "us"
            </Typography>
            , or{" "}
            <Typography component="span" fontWeight={700} fontStyle="italic">
              "our"
            </Typography>
            ) we are committed to protecting and respecting your privacy and
            Personal Data in compliance with the United Kingdom General Data
            Protection Regulation (
            <Typography component="span" fontWeight={700} fontStyle="italic">
              "GDPR"
            </Typography>
            ), the Data Protection Act 2018 and all other mandatory laws and
            regulations of the United Kingdom.
          </Typography>
          <Typography mt={2}>
            For the purpose of data protection legislation in the UK, the data
            controller is She Is Action Ltd., company registration no. 14962459,
            registered office The Annexe, 1 Moat Farm Cottages, East Ruston
            Road, Lessingham, NR12 0DW.
          </Typography>
          <Typography mt={4}>
            This Privacy Policy explains how we collect, process and keep your
            data safe. The Privacy Policy will tell you about your privacy
            rights, how the law protects you, and inform our employees and staff
            members of all their obligations and protocols when processing data.
          </Typography>
          <Typography mt={2}>
            The individuals from which we may gather and use data can include:
          </Typography>
          <Typography component="ul" mt={2}>
            <li>Customers</li>
            <li>Suppliers</li>
            <li>Business Contacts</li>
            <li>Employees/Staff Members</li>
            <li>Third parties connected to your customers</li>
          </Typography>
          <Typography mt={2}>
            and any other people that the organisation has a relationship with
            or may need to contact.
          </Typography>
          <Typography mt={4}>
            This Privacy Policy applies to all our employees and staff members
            and all Personal Data processed at any time by us.
          </Typography>
          <Typography
            variant="body1"
            fontWeight={700}
            style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}
            mt={2}
          >
            Your Data Controller
          </Typography>
          <Typography mt={2}>
            She Is Action Ltd is your Data Controller and responsible for your
            Personal Data. We are not obliged by the GDPR to appoint a data
            protection officer and have not voluntarily appointed one at this
            time. Therefore, any inquiries about your data should either be sent
            to us by email to hello@sheisaction.com or by post to The Annexe, 1
            Moat Farm Cottages, East Ruston Road, Lessingham, NR12 0DW, United
            Kingdom.
          </Typography>
          <Typography mt={2}>
            You have the right to make a complaint at any time to the
            Information Commissioner’s Office (ICO), the UK supervisory
            authority for data protection issues (www.ico.org.uk). We would,
            however, appreciate the chance to deal with your concerns before you
            approach the ICO so please contact us in the first instance.
          </Typography>
          <Typography
            variant="body1"
            fontWeight={700}
            style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}
            mt={2}
          >
            Processing data on behalf of a Controller and processors’
            responsibility to you
          </Typography>
          <Typography mt={2}>
            In discharging our responsibilities as a Data Controller we have
            employees who will deal with your data on our behalf (known as
            <Typography component="span" fontWeight={700} fontStyle="italic">
              “Processors”
            </Typography>
            ). The responsibilities below may be assigned to an individual or
            may be taken to apply to the organisation as a whole. The Data
            Controller and our Processors have the following responsibilities:
          </Typography>
          <Typography component="ul" mt={2}>
            <li>
              Ensure that all processing of Personal Data is governed by one of
              the legal bases laid out in the GDPR (see 2.2 below for more
              information);
            </li>
            <li>
              Ensure that Processors authorised to process Personal Data have
              committed themselves to confidentiality or are under an
              appropriate statutory obligation of confidentiality;
            </li>
            <li>
              Implement appropriate technical and organisational measures to
              ensure a level of security appropriate to the risk associated with
              the processing of Personal Data;
            </li>
            <li>
              Obtain the prior specific or general authorisation of the
              Controller before engaging another Processor;
            </li>
            <li>
              Assist the Controller in the fulfilment of the Controller’s
              obligation to respond to requests for exercising the data
              subject’s rights;
            </li>
            <li>
              Make available to the Controller all information necessary to
              demonstrate compliance with the obligations laid down in the GDPR
              and allow for and contribute to audits, including inspections,
              conducted by the Controller or another auditor mandated by the
              Controller;
            </li>
            <li>
              Maintain a record of all categories of processing activities
              carried out on behalf of a Controller;
            </li>
            <li>
              Cooperate, on request, with the supervisory authority in the
              performance of its tasks;
            </li>
            <li>
              Ensure that any person acting under the authority of the Processor
              who has access to Personal Data does not process Personal Data
              except on instructions from the Controller; and
            </li>
            <li>
              Notify the Controller without undue delay after becoming aware of
              a Personal Data Breach.
            </li>
          </Typography>
          <Typography
            variant="h6"
            fontWeight={700}
            style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}
            mt={2}
          >
            LEGAL BASIS FOR DATA COLLECTION
          </Typography>
          <Typography
            mt={2}
            variant="body1"
            fontWeight={700}
            style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}
          >
            Types of data / Privacy policy scope
          </Typography>
          <Typography mt={2}>
            <Typography component="span" fontWeight={700} fontStyle="italic">
              “Personal Data”
            </Typography>{" "}
            means any information about an individual from which that person can
            be identified. It does not include data where the identity has been
            removed (anonymous data).
          </Typography>
          <Typography mt={2}>
            We may collect, use, store and transfer different kinds of Personal
            Data about you which we have grouped together below. Not all of the
            following types of data will necessarily be collected from you but
            this is the full scope of data that we collect and when we collect
            it from you:
          </Typography>
          <Typography component="ul" mt={2}>
            <li>
              Profile/Identity Data: This is data relating to your first name,
              last name, gender, date of birth.
            </li>
            <li>
              Contact Data: This is data relating to your phone number,
              addresses, email addresses, phone numbers.
            </li>
            <li>
              Marketing and Communications Data: This is your preferences in
              receiving marketing information and other information from us.
            </li>
            <li>
              Billing Data: This is information relating to your debit and
              credit card information such as the name attached to your payment
              details and your billing address.
            </li>
            <li>
              Transactional Data: This is information of details and records of
              all payments you have made for our services or products.
            </li>
            <li>
              Technical Data: This is your IP address, browser type and version,
              time zone setting and location, operating system and platform, and
              other technology on the devices you use to engage with us.
            </li>
            <li>
              Customer Support Data: This includes feedback and survey
              responses.
            </li>
            <li>
              Usage Data: information about how you use our website, products
              and services.
            </li>
            <li>
              sporting interests and preferences: this is data relating to your
              content and resource needs on the platform
            </li>
          </Typography>
          <Typography mt={2}>
            We also collect, use and share Aggregated Data such as We also
            collect, use and share Aggregated Data such as type of content
            customers engage with, demographics (such as age, location,
            employment etc).. Aggregated Data could be derived from your
            Personal Data but is not considered Personal Data in law as this
            data will not directly or indirectly reveal your identity. However,
            if we combine or connect Aggregated Data with your Personal Data so
            that it can directly or indirectly identify you, we treat the
            combined data as Personal Data which will be used in accordance with
            this Privacy Policy.
          </Typography>
          <Typography mt={2}>
            We may also aggregate data to enable research or analysis so that we
            can better understand and serve you and others. For example, we may
            conduct research on your demographics and usage. Although this
            aggregated data may be based in part on Personal Data, it does not
            identify you personally. We may share this type of anonymous data
            with others, including service providers, our affiliates, agents and
            current and prospective business partners.
          </Typography>
          <Typography mt={2}>
            We do not collect any Special Categories of Personal Data about you
            (this includes details about your race or ethnicity, religious or
            philosophical beliefs, sex life, sexual orientation, political
            opinions, trade union membership, information about your health, and
            genetic and biometric data). Nor do we collect any information about
            criminal convictions and offences.
          </Typography>
          <Typography
            variant="body1"
            fontWeight={700}
            style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}
            mt={2}
          >
            The Legal Basis for Collecting That Data
          </Typography>
          <Typography mt={2}>
            There are a number of justifiable reasons under the GDPR that allow
            collection and processing of Personal Data. The main avenues we rely
            on are:
          </Typography>
          <Typography component="ul" mt={2}>
            <li>
              <Typography component="span" fontWeight={700} fontStyle="italic">
                “Consent”
              </Typography>
              : Certain situations allow us to collect your Personal Data, such
              as when you tick a box that confirms you are happy to receive
              email newsletters from us, or ‘opt in’ to a service.
            </li>
            <li>
              <Typography component="span" fontWeight={700} fontStyle="italic">
                “Contractual Obligations”
              </Typography>
              : We may require certain information from you in order to fulfil
              our contractual obligations and provide you with the promised
              service.
            </li>
            <li>
              <Typography component="span" fontWeight={700} fontStyle="italic">
                “Legal Compliance”
              </Typography>
              : We’re required by law to collect and process certain types of
              data, such as fraudulent activity or other illegal actions.
            </li>
            <li>
              <Typography component="span" fontWeight={700} fontStyle="italic">
                “Legitimate Interest”
              </Typography>
              : We might need to collect certain information from you to be able
              to meet our legitimate interests – this covers aspects that can be
              reasonably expected as part of running our business, that will not
              have a material impact on your rights, freedom or interests.
              Examples could be your address, so that we know where to deliver
              something to, or your name, so that we have a record of who to
              contact moving forwards.
            </li>
          </Typography>
          <Typography
            variant="h6"
            fontWeight={700}
            style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}
            mt={2}
          >
            HOW WE USE YOUR PERSONAL DATA
          </Typography>
          <Typography
            variant="body1"
            fontWeight={700}
            style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}
            mt={2}
          >
            Our data uses
          </Typography>
          <Typography mt={2}>
            We will only use your Personal Data when the law allows us to.
          </Typography>
          <Typography mt={2}>
            Set out below is a table containing the different types of Personal
            Data we collect and the lawful basis for processing that data.
            Please refer to section 2.2 for more information on the lawful basis
            listed in the table below.
          </Typography>
          <Typography mt={2}>
            Examples provided in the table below are indicative in nature and
            the purposes for which we use your data may be broader than
            described but we will never process your data without a legal basis
            for doing so and it is for a related purpose. For further inquiries
            please contact us.
          </Typography>
          <TableContainer component={Paper} sx={{ marginTop: "2rem" }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "15%" }}>Activity</TableCell>
                  <TableCell sx={{ width: "35%" }}>Type of data</TableCell>
                  <TableCell sx={{ width: "20%" }}>
                    Legal Justification
                  </TableCell>
                  <TableCell sx={{ width: "40%" }}>
                    Lawful basis for processing data
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    When a customer signs up
                  </TableCell>
                  <TableCell>
                    Profile/Identity Data
                    <br />
                    Contact Data
                    <br />
                    Marketing and Communications Data
                    <br />
                    Billing Data
                  </TableCell>
                  <TableCell>
                    Consent
                    <br />
                    Legitimate Interest
                    <br />
                    Necessity
                  </TableCell>
                  <TableCell>
                    We need to process this data so we know who is signing up as
                    a member, where to charge them for their membership costs,
                    and what their needs are for information and resource
                    matching in the platform.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Typography
            variant="body1"
            fontWeight={700}
            style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}
            mt={2}
          >
            Marketing and content updates
          </Typography>
          <Typography mt={2}>
            You will receive marketing and new content communications from us if
            you have created an account and chosen to opt into receiving those
            communications. From time to time we may make suggestions and
            recommendations to you about goods or services that may be of
            interest to you.
          </Typography>
          <Typography
            variant="body1"
            fontWeight={700}
            style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}
            mt={2}
          >
            Change of purpose
          </Typography>
          <Typography mt={2}>
            We will only use your Personal Data for the purposes for which we
            collected it, unless we reasonably consider that we need to use it
            for another reason and that reason is compatible with the original
            purpose. If you wish to get an explanation as to how the processing
            for the new purpose is compatible with the original purpose, please
            contact us.
          </Typography>
          <Typography mt={2}>
            If we need to use your Personal Data for an unrelated purpose, we
            will notify you and we will explain the legal basis which allows us
            to do so.
          </Typography>
          <Typography mt={2}>
            Please note that we may process your Personal Data without your
            knowledge or consent, in compliance with the above rules, where this
            is required or permitted by law.
          </Typography>
          <Typography
            variant="h6"
            fontWeight={700}
            style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}
            mt={2}
          >
            YOUR RIGHTS AND HOW YOU ARE PROTECTED BY US
          </Typography>
          <Typography
            variant="body1"
            fontWeight={700}
            style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}
            mt={2}
          >
            Your legal rights
          </Typography>
          <Typography mt={2}>
            Under certain circumstances, you have the following rights under
            data protection laws in relation to your personal data:
          </Typography>
          <Typography component="ul" mt={2}>
            <li>
              <Typography component="span" fontWeight={700} fontStyle="italic">
                Right to be informed Right to be informed.
              </Typography>{" "}
              You have a right to be informed about our purposes for processing
              your personal data, how long we store it for, and who it will be
              shared with. We have provided this information to you in this
              policy.
            </li>
            <li>
              <Typography component="span" fontWeight={700} fontStyle="italic">
                Right of access.
              </Typography>{" "}
              This enables you to receive a copy of the personal data we hold
              about you and to check that we are lawfully processing it (also
              known as a “data subject access request”). See section 4.5 below
              for more details on how you can make a data subject access
              request.
            </li>
            <li>
              <Typography component="span" fontWeight={700} fontStyle="italic">
                Right to rectification.
              </Typography>{" "}
              You have a right to request correction of the personal data that
              we hold about you. This enables you to have any incomplete or
              inaccurate data we hold about you corrected, though we may need to
              verify the accuracy of the new data you provide to us.
            </li>
            <li>
              <Typography component="span" fontWeight={700} fontStyle="italic">
                Right to erasure.
              </Typography>{" "}
              You have the right to ask us to delete or remove personal data
              where there is no good reason for us continuing to process it,
              where you have successfully exercised your right to object to
              processing (see below), where we may have processed your
              information unlawfully or where we are required to erase your
              personal data to comply with local law. Note, however, that we may
              not always be able to comply with your request of erasure for
              specific legal reasons which will be notified to you, if
              applicable, at the time of your request.
            </li>
            <li>
              <Typography component="span" fontWeight={700} fontStyle="italic">
                Right to object.
              </Typography>{" "}
              You can object to the processing of personal data we hold about
              you. This effectively allows you to stop or prevent us from
              processing your personal data. Note that this is not an absolute
              right and it only applies in certain circumstances, for example:
              <ol>
                <li>
                  Where we are processing your personal data for direct
                  marketing purposes.
                </li>
                <li>
                  Where we are relying on a legitimate interest (or those of a
                  third party) and there is something about your particular
                  situation which makes you want to object to processing on this
                  ground as you feel it impacts on your fundamental rights and
                  freedoms.
                </li>
                <li>
                  In some cases, we may continue processing your data if we can
                  demonstrate that we have compelling legitimate grounds to
                  process your information which override your rights and
                  freedoms.
                </li>
              </ol>
            </li>

            <li>
              <Typography component="span" fontWeight={700} fontStyle="italic">
                Right to restrict processing.
              </Typography>{" "}
              You have the right to request the restriction or suppression of
              their personal data. Note that this is not an absolute right and
              it only applies in certain circumstances:
              <ol>
                <li>If you want us to establish the data’s accuracy.</li>
                <li>
                  Where our use of the data is unlawful but you do not want us
                  to erase it.
                </li>
                <li>
                  Where you need us to hold the data even if we no longer
                  require it as you need it to establish, exercise or defend
                  legal claims.
                </li>
                <li>
                  You have objected to our use of your data but we need to
                  verify whether we have overriding legitimate grounds to use
                  it.
                </li>
              </ol>
            </li>
            <li>
              <Typography component="span" fontWeight={700} fontStyle="italic">
                Right to data portability.
              </Typography>{" "}
              You have the right to request the transfer of your personal data
              to you or to a third party. If you make such a request, we will
              provide to you, or a third party you have chosen, your personal
              data in a structured, commonly used, machine-readable format. Note
              that this right only applies to automated information which you
              initially provided consent for us to use or where we used the
              information to perform a contract with you.
            </li>
          </Typography>

          <Typography mt={2}>
            If you wish to make a request under any of these rights, please
            contact us at{" "}
            <a href="mailto:hello@sheisaction.com">hello@sheisaction.com</a>.
          </Typography>

          <Typography
            variant="body1"
            fontWeight={700}
            style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}
            mt={2}
          >
            Your control over She Is Action Ltd’s use of your Personal Data
          </Typography>

          <Typography mt={2}>
            You may delete your account at any time – this will remove your
            account page from our systems and our related software.
          </Typography>

          <Typography mt={2}>
            We guarantee this will delete all stored data.
          </Typography>

          <Typography mt={2}>
            You can access information associated with your account by logging
            into your account you created with us.
          </Typography>

          <Typography mt={2}>
            Your account information will be protected by a password for your
            privacy and security. You need to prevent unauthorized access to
            your account and personal information by selecting and protecting
            your password appropriately and limiting access to your computer or
            device and by signing off after you have finished accessing your
            account.
          </Typography>

          <Typography mt={2}>
            California Privacy Rights: Under California Civil Code sections
            1798.83-1798.84, California residents are entitled to ask us for a
            notice identifying the categories of personal customer information
            which we share with our affiliates and/or third parties for
            marketing purposes, and providing contact information for such
            affiliates and/or third parties. If you are a California resident
            and would like a copy of this notice, please submit a written
            request to{" "}
            <a href="mailto:hello@sheisaction.com">hello@sheisaction.com</a>.
          </Typography>

          <Typography
            variant="body1"
            fontWeight={700}
            style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}
            mt={2}
          >
            How She Is Action Ltd protects customers’ Personal Data
          </Typography>

          <Typography mt={2}>
            We are concerned with keeping your data secure and protecting it
            from inappropriate disclosure. Any Personal Data collected by us is
            only accessible by a limited number of employees who have special
            access rights to such systems and are bound by obligations of
            confidentiality. If and when we use subcontractors to store your
            data, we will not relinquish control of your Personal Data or expose
            it to security risks that would not have arisen had the data
            remained in our possession. However, unfortunately no transmission
            of data over the internet is guaranteed to be completely secure. It
            may be possible for third parties not under the control of She Is
            Action Ltd to intercept or access transmissions or private
            communications unlawfully. While we strive to protect your Personal
            Data, we cannot ensure or warrant the security of any Personal Data
            you transmit to us. Any such transmission is done at your own risk.
            If you believe that your interaction with us is no longer secure,
            please contact us.
          </Typography>

          <Typography
            variant="body1"
            fontWeight={700}
            style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}
            mt={2}
          >
            Opting out of marketing promotions
          </Typography>

          <Typography mt={2}>
            You can ask us to stop sending you marketing messages at any time by
            You can unsubscribe from the marketing communications at any time by
            using the link at the bottom of the marketing emails. It is also
            possible to ask us to stop sending marketing materials by emailing
            us at hello@sheisaction.com..
          </Typography>

          <Typography mt={2}>
            Where you opt out of receiving these marketing messages, we will
            continue to retain other Personal Data provided to us as a result of
            interactions with us not related to your marketing preferences.
          </Typography>

          <Typography
            variant="body1"
            fontWeight={700}
            style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}
            mt={2}
          >
            How to request your data and the process for obtaining it
          </Typography>

          <Typography mt={2}>
            You will not have to pay a fee to access your Personal Data (or to
            exercise any of the other rights). However, if your request is
            clearly unfounded, we could refuse to comply with your request.
          </Typography>

          <Typography mt={2}>
            We may need to request specific information from you to help us
            confirm your identity and ensure you have the right to access your
            Personal Data (or to exercise any of your other rights). This is a
            security measure to ensure that Personal Data is not disclosed to
            any person who has no right to receive it. We may also contact you
            to ask you for further information in relation to your request to
            speed up our response.
          </Typography>

          <Typography
            variant="h6"
            fontWeight={700}
            style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}
            mt={2}
          >
            YOUR DATA AND THIRD PARTIES
          </Typography>

          <Typography
            variant="body1"
            fontWeight={700}
            style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}
            mt={2}
          >
            Sharing your data with third parties
          </Typography>

          <Typography mt={2}>
            We may share non-Personal Data with third parties. We may share your
            Personal Data with subcontractors or affiliates, subject to
            confidentiality obligations to use it only for the purposes for
            which we disclose it to them and pursuant to our instructions.
          </Typography>

          <Typography mt={2}>
            We may also share Personal Data with interested parties in the event
            that She Is Action Ltd anticipates a change in control or the
            acquisition of all or part of our business or assets or with
            interested parties in connection with the licensing of our
            technology.
          </Typography>

          <Typography mt={2}>
            If She Is Action Ltd is sold or makes a sale or transfer, we may, in
            our sole discretion, transfer, sell or assign your Personal Data to
            a third party as part of or in connection with that transaction.
            Upon such transfer, the Privacy Policy of the acquiring entity may
            govern the further use of your Personal Data. In all other
            situations your data will still remain protected in accordance with
            this Privacy Policy (as amended from time to time).
          </Typography>

          <Typography mt={2}>
            We may share your Personal Data at any time if required for legal
            reasons or in order to enforce our terms or this Privacy Policy.
          </Typography>

          <Typography
            variant="body1"
            fontWeight={700}
            style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}
            mt={2}
          >
            Third-Party Links
          </Typography>

          <Typography mt={2}>
            This Site may include links to third-party websites, plug-ins and
            applications. Clicking on those links or enabling those connections
            may allow third parties to collect or share data about you. We do
            not control these third-party websites and are not responsible for
            their privacy statements. When you leave our Site, we encourage you
            to read the privacy policy of every website you visit.
          </Typography>

          <Typography
            variant="body1"
            fontWeight={700}
            style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}
            mt={2}
          >
            HOW LONG WE RETAIN YOUR DATA
          </Typography>

          <Typography mt={2}>
            We will only retain your Personal Data for as long as reasonably
            necessary to fulfil the purposes we collected it for. We may retain
            your Personal Data for a longer period than usual in the event of a
            complaint or if we reasonably believe there is a prospect of
            litigation in respect to our relationship with you.
          </Typography>

          <Typography
            variant="body1"
            fontWeight={700}
            style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}
            mt={2}
          >
            AGE LIMIT FOR OUR USERS
          </Typography>

          <Typography mt={2}>
            You must not use She Is Action Ltd unless you are aged 18 or older.
            If you are under 18 and you access She Is Action Ltd by lying about
            your age, you must immediately stop using She Is Action Ltd.
          </Typography>

          <Typography mt={2}>
            This website is not intended for children and we do not knowingly
            collect data relating to children.
          </Typography>
          <Typography
            variant="body1"
            fontWeight={700}
            style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}
            mt={2}
          >
            INTERNATIONAL TRANSFER OF DATA
          </Typography>

          <Typography mt={2}>
            Your information may be stored and processed in the US or other
            countries or jurisdictions outside the US where She Is Action Ltd
            has facilities. By using She Is Action Ltd, you are permitting and
            consenting to the transfer of information, including Personal Data,
            outside of the US.
          </Typography>

          <Typography
            variant="body1"
            fontWeight={700}
            style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}
            mt={2}
          >
            NOTIFICATION OF CHANGES AND ACCEPTANCE OF POLICY
          </Typography>

          <Typography
            variant="body1"
            fontWeight={700}
            style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}
            mt={2}
          >
            We keep our Privacy Policy under review and will place any updates
            here. This version is dated 17 April 2023.
          </Typography>

          <Typography mt={2}>
            By using She Is Action Ltd, you consent to the collection and use of
            data by us as set out in this Privacy Policy. Continued access or
            use of She Is Action Ltd will constitute your express acceptance of
            any modifications to this Privacy Policy.
          </Typography>

          <Typography
            variant="body1"
            fontWeight={700}
            style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}
            mt={2}
          >
            INTERPRETATION
          </Typography>

          <Typography mt={2}>
            All uses of the word “including” mean “including but not limited to”
            and the enumerated examples are not intended to in any way limit the
            term which they serve to illustrate. Any email addresses set out in
            this policy may be used solely for the purpose for which they are
            stated to be provided, and any unrelated correspondence will be
            ignored. Unless otherwise required by law, we reserve the right to
            not respond to emails, even if they relate to a legitimate subject
            matter for which we have provided an email address. You are more
            likely to get a reply if your request or question is polite,
            reasonable and there is no relatively obvious other way to deal with
            or answer your concern or question (e.g. FAQs, other areas of our
            website, etc.).
          </Typography>

          <Typography mt={2}>
            Our staff are not authorised to contract on behalf of She Is Action
            Ltd, waive rights or make representations (whether contractual or
            otherwise). If anything contained in an email from a She Is Action
            Ltd address contradicts anything in this policy, our terms or any
            official public announcement on our website, or is inconsistent with
            or amounts to a waiver of any She Is Action Ltd rights, the email
            content will be read down to grant precedence to the latter. The
            only exception to this is genuine correspondence expressed to be
            from the She Is Action Ltd legal department
          </Typography>
        </Box>
      </Box>
      <Footer />
      {!isLargeScreen && <BottomMenu />}
    </Box>
  );
}

export default PrivacyPolicy;
