import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import FollowedBrand from "components/FollowedBrand";
import WidgetWrapper from "components/WidgetWrapper";
import React from "react";
import { useSelector } from "react-redux";

function YourFollowedBrandsAll() {
  const isLargeScreen = useMediaQuery("(min-width: 1000px)");
  const currentUser = useSelector((state) => state.user);
  const user = useSelector((state) => state.viewingUser || state.user);
  const brandsFollowed = user?.brandFollowsDetails || [];
  const { palette } = useTheme();
  const neutralDark = palette.neutral.dark;

  // Convert currentUser.brandFollows to a Set for faster lookups
  const followedBrandIds = new Set(currentUser?.brandFollows || []);

  if (brandsFollowed.length === 0) {
    return null;
  }

  return (
    <WidgetWrapper borderRadius={!isLargeScreen ? "2rem" : "0.75rem"}>
      <Typography
        color={neutralDark}
        variant="h5"
        fontWeight="500"
        sx={{ mb: "1.5rem" }}
      >
        Followed Brands
      </Typography>

      <Box display="flex" flexDirection="column" gap="0.5rem">
        {brandsFollowed.map((brand) => (
          <FollowedBrand
            key={brand._id}
            brand={brand}
            followedBrandIds={followedBrandIds}
          />
        ))}
      </Box>
    </WidgetWrapper>
  );
}

export default YourFollowedBrandsAll;
