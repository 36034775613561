import { Box, Typography } from "@mui/material";
import React from "react";
import UserSportsTable from "scenes/widgets/UserWidget/components/UserSportsTable";

function UserWidgetSportsTable({ sports, primaryMain }) {
  return (
    sports &&
    sports.length > 0 && (
      <Box p="1rem 0">
        <Box mt="0.5rem">
          <Typography
            variant="h5"
            fontSize="1rem"
            color={primaryMain}
            fontWeight="500"
            mb="1rem"
          >
            Favourite Sports
          </Typography>

          <Box>
            <UserSportsTable sports={sports} />
          </Box>
        </Box>
      </Box>
    )
  );
}

export default UserWidgetSportsTable;
