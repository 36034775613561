// UserSearchModal.js
import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  Input,
  List,
  ListItem,
  ListItemText,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { searchUsers } from "redux/actions/userActions";
import { Close } from "@mui/icons-material";

function UserSearchModal({ open, onClose, userData, onSave }) {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const primary = palette.primary.main;
  const primaryLight = palette.primary.light;
  const primaryDark = palette.primary.dark;
  const neutralMedium = palette.neutral.medium;
  const neutralLight = palette.neutral.light;
  const neutralDark = palette.neutral.dark;

  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleSearch = async (e) => {
    setSearch(e.target.value);
  };

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (search) {
      const limit = 5;

      dispatch(searchUsers(search, page, limit))
        .then((data) => {
          if (data && Array.isArray(data.data)) {
            setSearchResults(data.data);
            // Assuming the API sends a total count of results in data.total
            setTotalPages(Math.ceil(data.total / limit));
          } else {
            console.warn(
              "searchUsers did not return the expected format",
              data
            );
            setSearchResults([]);
          }
        })
        .catch((error) => {
          console.error("Failed to search users:", error);
          setSearchResults([]);
        });
    } else {
      setSearchResults([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, page]);

  const handleUserSelection = (user) => {
    if (!selectedUsers.some((u) => u._id === user._id)) {
      setSelectedUsers([...selectedUsers, user]);
    } else {
      handleDeleteUser(user);
    }
  };

  const handleDeleteUser = (user) => {
    const newSelectedUsers = selectedUsers.filter((u) => u._id !== user._id);

    setSelectedUsers(newSelectedUsers);
  };

  useEffect(() => {
    // Load the existing users into the selectedUsers state
    if (userData && userData.users) {
      setSelectedUsers(userData.users);
    }
  }, [userData]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: `2px solid ${primary}`,
          boxShadow: 24,
          p: 4,
          borderRadius: "10px",
        }}
      >
        <Typography
          variant="h6"
          display="flex"
          justifyContent="center"
          color={neutralDark}
          paddingBottom="0.5em"
        >
          Current Group Members:
        </Typography>

        <Box width="100%" display="flex" flexWrap="wrap" marginBottom="1em">
          {userData &&
            userData.users &&
            userData.users.map((user, index) => (
              <Box
                key={user._id || index}
                px="5px"
                py="3px"
                borderRadius="5px"
                m="5px"
                mb="5px"
                backgroundColor={neutralLight}
                color={neutralDark}
              >
                {user.firstName} {user.lastName}
              </Box>
            ))}
        </Box>

        <FormControl fullWidth>
          <Input
            type="text"
            placeholder="Search for users"
            value={search}
            onChange={handleSearch}
            disableUnderline
            sx={{
              marginBottom: "1em",
              border: `1px solid ${neutralMedium}`,
              borderRadius: "5px",
              paddingLeft: "0.5em", // Optional: to provide some spacing inside the input
              width: "100%", // Full width
            }}
          />
        </FormControl>

        <Box width="100%" display="flex" flexWrap="wrap">
          {selectedUsers.map((user, index) => (
            <Box
              key={user._id || index}
              display="flex"
              alignItems="center"
              px="5px"
              py="3px"
              borderRadius="5px"
              m="5px"
              mb="5px"
              variant="filled"
              fontSize={12}
              backgroundColor={
                userData.users?.some((u) => u._id === user._id)
                  ? primaryLight
                  : neutralLight
              }
              color={
                userData.users?.some((u) => u._id === user._id)
                  ? primaryDark
                  : neutralDark
              }
              cursor="pointer"
            >
              {user.firstName} {user.lastName}
              <Close
                sx={{ marginLeft: "0.3em", fontSize: "12px" }}
                onClick={() => handleDeleteUser(user)}
              />
            </Box>
          ))}
        </Box>

        <List sx={{ width: "100%" }}>
          {searchResults.map((resultUser) => (
            <ListItem
              key={resultUser._id}
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                backgroundColor: neutralLight,
                borderRadius: "10px",
                marginBottom: "0.5em",
                "&:hover": {
                  backgroundColor: primaryLight,
                  color: primaryDark,
                },
              }}
              onClick={() => handleUserSelection(resultUser)}
            >
              <Avatar
                src={
                  resultUser.picturePath.endsWith("/public")
                    ? resultUser.picturePath.replace("/public", "/128x128")
                    : `${resultUser.picturePath}/128x128`
                }
                sx={{ width: "30px", height: "30px" }}
              />

              <Box sx={{ flexGrow: 1, paddingLeft: "0.5rem" }}>
                <ListItemText
                  primary={`${resultUser.firstName} ${resultUser.lastName}`}
                  sx={{ fontWeight: 500 }}
                />
              </Box>
              {selectedUsers?.some((u) => u._id === resultUser._id) && "✔️"}
            </ListItem>
          ))}
        </List>
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button
            disabled={page === 1}
            onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
          >
            Previous
          </Button>
          <Typography display="flex" alignItems="center" color={primary}>
            {page} of {totalPages}
          </Typography>
          <Button
            disabled={page === totalPages}
            onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))}
          >
            Next
          </Button>
        </Box>

        <Button
          variant="contained"
          color="primary"
          onClick={() => onSave(selectedUsers)}
        >
          Save
        </Button>
      </Box>
    </Modal>
  );
}

export default UserSearchModal;
