import { Verified } from "@mui/icons-material";
import { Avatar, Box, Button, Tooltip, Typography } from "@mui/material";
import { BusinessProfileClick } from "helpers/businessHelpers/businessProfileClick";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

function ArticlePageBrandDeets({
  brand,
  primary,
  primaryLight,
  handleFollowToggle,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user) || {};
  const textStyle = {
    textShadow: "2px 2px 4px rgba(0, 0, 0, 1)", // Example shadow
  };

  // is the user following brand?
  const isFollowingBrand =
    user && user.brandFollows && user.brandFollows.includes(brand._id);

  return (
    <Box display="flex" alignItems="center" gap="1rem" mt="1rem" mb="2rem">
      <Link
        to={`/brand/${brand._id}`}
        onClick={() =>
          BusinessProfileClick(dispatch, {
            brandId: brand._id,
            fromPage: "article",
          })
        }
      >
        <Avatar
          src={brand.logoPath}
          alt={brand.name}
          sx={{ width: 42, height: 42 }}
        />
      </Link>
      <Link
        to={`/brand/${brand._id}`}
        onClick={() =>
          BusinessProfileClick(dispatch, {
            brandId: brand._id,
            fromPage: "article",
          })
        }
      >
        <Typography
          variant="p"
          color="white"
          fontWeight="500"
          fontSize={14}
          style={textStyle}
        >
          {brand.name}
        </Typography>
      </Link>
      {brand.verified ? (
        <Tooltip title="She is Action - Verified" placement="top-start" arrow>
          <Verified fontSize="large" sx={{ color: primary, textStyle }} />
        </Tooltip>
      ) : undefined}
      <Typography
        variant="p"
        color="white"
        fontWeight="500"
        fontSize={14}
        style={textStyle}
      >
        {brand.followers?.length} follower
        {brand.followers?.length === 1 ? undefined : "s"}
      </Typography>
      {/* Following Brand? */}

      <Tooltip
        title={
          isFollowingBrand ? `Unfollow ${brand.name}` : `Follow ${brand.name}`
        }
        placement="top"
        arrow
      >
        <Button
          onClick={() => handleFollowToggle(brand._id)}
          variant={isFollowingBrand ? "outlined" : "contained"}
          fontWeight="500"
          fontSize={14}
          sx={{
            color: "white",
            borderColor: "white",
            borderRadius: "1rem",
            textShadow: isFollowingBrand
              ? "2px 2px 4px rgba(0, 0, 0, 1)"
              : "none",
            padding: "0.25rem 1rem",
            "&:hover": {
              color: primaryLight,
              // borderColor: secondaryDark,
            },
          }}
        >
          {isFollowingBrand ? "Following" : "Follow"}
        </Button>
      </Tooltip>
    </Box>
  );
}

export default ArticlePageBrandDeets;
