import DOMPurify from "dompurify";
import React from "react";

function PostWidgetContent({ description, picturePath }) {
  // Function to replace consecutive '<p><br></p>' tags
  const replaceConsecutiveBreaks = (match) => {
    // Count the number of occurrences
    const count = match.split("<p><br></p>").length - 1;
    // Replace with one fewer '<br>' than the count
    return "<br>".repeat(Math.max(count - 1, 0));
  };

  // Replace consecutive occurrences of '<p><br></p>'
  const sanitizedDescription = DOMPurify.sanitize(description).replace(
    /(<p><br><\/p>)+/g,
    replaceConsecutiveBreaks
  );

  return (
    <>
      <div
        dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
        style={{ margin: "0", padding: "0.5rem 0" }}
      />
      {picturePath && (
        <img
          width="100%"
          height="auto"
          alt="post"
          style={{ borderRadius: "0.75rem", marginBottom: "0.75rem" }}
          src={
            picturePath.endsWith("/public")
              ? picturePath.replace("/public", "/768x576")
              : `${picturePath}/768x576`
          }
        />
      )}
    </>
  );
}

export default PostWidgetContent;
