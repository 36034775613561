import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  archiveArticle,
  draftArticle,
  fetchAllBrandArticles,
  publishArticle,
} from "redux/actions/articleActions";

function BrandArticleCard({ article, isBrandAdmin, socket }) {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const background = palette.background.default;
  const primaryDark = palette.primary.dark;
  const primary = palette.primary.main;

  const user = useSelector((state) => state.user);

  const isPublished = article.status === "published";
  const isDraft = article.status === "draft";
  const isArchived = article.status === "archived";

  const brand = useSelector((state) => state.brand);

  // Define colors for different statuses
  const statusColors = {
    published: "green",
    draft: "orange",
    archived: "darkgrey",
  };

  // Convert status to proper case (e.g. "published" to "Published")
  const formatStatus = (status) => {
    if (!status) return "";
    return status[0].toUpperCase() + status.slice(1);
  };

  // Get the color for the current article status
  const getStatusColor = (status) => {
    return statusColors[status.toLowerCase()] || primary;
  };

  // Find the first image block in the article content
  const firstImageBlock = article?.content?.find(
    (block) => block.type === "image"
  );
  // If an image block is found, use its data as the image URL, otherwise use null
  const firstImageURL = firstImageBlock ? firstImageBlock.data : null;

  const handlePublish = async () => {
    await dispatch(publishArticle(article._id));
    await dispatch(fetchAllBrandArticles(article.brand._id)); // re-fetch articles after publish

    brand.followers.forEach((follower) => {
      const notificationData = {
        senderId: user._id,
        brandId: brand._id,
        brandName: brand.name,
        receiverId: follower.userId,
        name: user.firstName + " " + user.lastName,
        articleId: article._id,
        title: article.title,
        picturePath: brand.picturePath,
        timeStamp: Date.now(),
        type: "newArticle",
      };
      socket.emit("sendNotification", notificationData);
    });
  };

  const handleArchiveArticle = async () => {
    await dispatch(archiveArticle(article._id));
    await dispatch(fetchAllBrandArticles(article.brand._id)); // re-fetch articles after archive
  };

  const handleEdit = async () => {
    // Your code to handle editing the article
    await dispatch(draftArticle(article._id));
    navigate(`/news/article/${article._id}/edit`);
  };

  return (
    <Card
      key={article._id}
      sx={{
        backgroundImage: "none",
        backgroundColor: background,
        borderRadius: "0.55rem",
      }}
    >
      <CardContent>
        <Box>
          <Typography variant="h5" gutterBottom>
            {article.brand.name}
          </Typography>
          <Link
            to={`/news/article/${article._id}`}
            style={{ display: "block" }}
          >
            <img
              // if brand.src is null, use a default image
              src={article.titleImage || firstImageURL}
              alt={article.title}
              style={{
                width: "100%",
                height: "200px",
                objectFit: "cover",
                borderRadius: "10px",
              }}
            />
          </Link>
        </Box>
        <Box mt="0.5rem">
          <Typography variant="h5" gutterBottom color={primary}>
            {article.title}
          </Typography>
          <Typography variant="body2">{article.subtitle}</Typography>

          <FlexBetween sx={{ marginTop: "0.5rem", color: primaryDark }}>
            <Typography variant="body2">
              By: {article.firstName} {article.lastName}
            </Typography>

            <Typography variant="body2">
              Published: {moment(article.published_at).format("MMM Do")}
            </Typography>
          </FlexBetween>

          {isBrandAdmin && (
            <>
              <Box mt="1rem">
                <Typography variant="h5" color={primary}>
                  Article Status:{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                      color: getStatusColor(article.status),
                    }}
                  >
                    {formatStatus(article.status)}
                  </span>
                </Typography>
              </Box>
              <FlexBetween mt={2}>
                {!isPublished && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePublish}
                  >
                    Publish
                  </Button>
                )}
                {!isDraft && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleEdit}
                  >
                    Edit
                  </Button>
                )}
                {!isArchived && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleArchiveArticle}
                  >
                    Archive
                  </Button>
                )}
              </FlexBetween>
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

export default BrandArticleCard;
