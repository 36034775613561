import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import {
  isLastMessage,
  isSameSender,
  isSameSenderMargin,
  isSameUser,
} from "../../config/ChatLogic";
import { useSelector } from "react-redux";
import SingleMessage from "./SingleMessage";

const ScrollableChat = ({ messages, onLoadOlder, hasMore, messageBoxRef }) => {
  const user = useSelector((state) => state.user);

  return (
    <div
      style={{
        height: "100vh",
        overflow: "auto",
        display: "flex",
        flexDirection: "column-reverse",
      }}
      id="scrollableDiv"
      ref={messageBoxRef}
    >
      <InfiniteScroll
        dataLength={messages?.length} // This is important to reset it on every new fetch
        next={onLoadOlder} // Call to fetch older messages
        hasMore={hasMore} // Add a mechanism to know when you've fetched all data. Set to true for now.
        loader={<h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          // overflow: "visible",
        }} //To put endMessage and loader to the top.
        inverse={true} // This ensures that newer items are at the bottom
        scrollableTarget="scrollableDiv"
      >
        {messages &&
          messages.map((message, i) => (
            <SingleMessage
              key={message._id}
              message={message}
              i={i}
              messages={messages}
              user={user}
              isSameSender={isSameSender}
              isLastMessage={isLastMessage}
              isSameSenderMargin={isSameSenderMargin}
              isSameUser={isSameUser}
            />
          ))}
      </InfiniteScroll>
    </div>
  );
};

export default ScrollableChat;
