import { Box, Typography } from "@mui/material";
import DOMPurify from "dompurify";
import React from "react";

function UserWidgetAbountMe({ aboutMe, primaryMain }) {
  // const sanitizedDescription = DOMPurify.sanitize(aboutMe);

  // Function to replace consecutive '<p><br></p>' tags
  const replaceConsecutiveBreaks = (match) => {
    // Count the number of occurrences
    const count = match.split("<p><br></p>").length - 1;
    // Replace with one fewer '<br>' than the count
    return "<br>".repeat(Math.max(count - 1, 0));
  };

  // Replace consecutive occurrences of '<p><br></p>'
  const sanitizedDescription = DOMPurify.sanitize(aboutMe).replace(
    /(<p><br><\/p>)+/g,
    replaceConsecutiveBreaks
  );

  return (
    aboutMe && (
      <Box p="1rem 0 0.5rem 0">
        <Typography
          variant="h5"
          fontSize="1rem"
          color={primaryMain}
          fontWeight="500"
          mb="1rem"
        >
          About
        </Typography>
        <div dangerouslySetInnerHTML={{ __html: sanitizedDescription }} />
      </Box>
    )
  );
}

export default UserWidgetAbountMe;
