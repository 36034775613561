import { setAdvancedSearchSports, setSports, setSport } from "redux/state";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const fetchSports = () => async (dispatch) => {
  try {
    const response = await fetch(`${BASE_URL}/sports`);
    const data = await response.json();
    dispatch(setSports(data));
  } catch (error) {
    console.error("Failed to fetch sports", error);
  }
};

export const fetchSport = (id) => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/sports/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    dispatch(setSport(data));
    return data;
  } catch (error) {
    console.error("Failed to fetch sport", error);
  }
};

export const fetchAdvancedSearchSports =
  ({ page, limit, sort, search }) =>
  async (dispatch, getState) => {
    try {
      const token = getState().token;
      // Construct the query string from parameters

      let url = `${BASE_URL}/sports/advanced-search?page=${page}&limit=${limit}`;

      if (sort) {
        url += `&sort=${sort}`;
      }

      if (search) {
        url += `&search=${search}`;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();

      // Assuming you have a separate reducer action for setting advanced search results
      dispatch(setAdvancedSearchSports(data));
      return data;
    } catch (error) {
      console.error("Failed to fetch advanced sports", error);
    }
  };

export const createSport = (sportData) => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/sports`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(sportData),
    });
    const data = await response.json();
    dispatch(fetchAdvancedSearchSports(1, 10));
    return data;
  } catch (error) {
    console.error("Failed to create sport", error);
  }
};

export const updateSport = (id, sportData) => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/sports/${id}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(sportData),
    });
    const data = await response.json();
    dispatch(fetchAdvancedSearchSports(1, 10));
    return data;
  } catch (error) {
    console.error("Failed to update sport", error);
  }
};

export const deleteSport = (id) => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/sports/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    dispatch(fetchAdvancedSearchSports(1, 10));
    return data;
  } catch (error) {
    console.error("Failed to delete sport", error);
  }
};
