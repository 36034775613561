import { Box, TextField } from "@mui/material";
import React from "react";

function BrandSocialMediaLinksEdit({
  brandData,
  setBrandData,
  formErrors,
  isLargeScreen,
}) {
  // Access nested social media errors
  const socialMediaErrors = formErrors.socialMedia || {};

  return (
    <>
      <Box
        marginBottom="1rem"
        marginTop="1rem"
        display={isLargeScreen ? "flex" : "block"}
        gap="1rem"
        justifyContent={isLargeScreen ? "space-between" : "flex-start"}
      >
        <TextField
          margin="dense"
          label="Facebook"
          name="facebook"
          value={brandData?.socialMedia?.facebook || ""}
          onChange={(e) =>
            setBrandData({
              ...brandData,
              socialMedia: {
                ...brandData.socialMedia,
                facebook: e.target.value,
              },
            })
          }
          fullWidth
          variant="outlined"
          disabled={!brandData.verified} // Disable if brand is not verified
          error={!!socialMediaErrors.facebook}
          helperText={socialMediaErrors.facebook}
        />

        <TextField
          margin="dense"
          label="Twitter"
          name="twitter"
          value={brandData?.socialMedia?.twitter || ""}
          onChange={(e) =>
            setBrandData({
              ...brandData,
              socialMedia: {
                ...brandData.socialMedia,
                twitter: e.target.value,
              },
            })
          }
          fullWidth
          variant="outlined"
          disabled={!brandData.verified} // Disable if brand is not verified
          error={!!socialMediaErrors.twitter}
          helperText={socialMediaErrors.twitter}
        />

        <TextField
          margin="dense"
          label="Instagram"
          name="instagram"
          value={brandData?.socialMedia?.instagram || ""}
          onChange={(e) =>
            setBrandData({
              ...brandData,
              socialMedia: {
                ...brandData.socialMedia,
                instagram: e.target.value,
              },
            })
          }
          fullWidth
          variant="outlined"
          disabled={!brandData.verified} // Disable if brand is not verified
          error={!!socialMediaErrors.instagram}
          helperText={socialMediaErrors.instagram}
        />
      </Box>
      <Box
        marginBottom="1rem"
        marginTop="1rem"
        display={isLargeScreen ? "flex" : "block"}
        gap="1rem"
        justifyContent={isLargeScreen ? "space-between" : "flex-start"}
      >
        <TextField
          margin="dense"
          label="Youtube"
          name="youtube"
          value={brandData?.socialMedia?.youtube || ""}
          onChange={(e) =>
            setBrandData({
              ...brandData,
              socialMedia: {
                ...brandData.socialMedia,
                youtube: e.target.value,
              },
            })
          }
          fullWidth
          variant="outlined"
          disabled={!brandData.verified} // Disable if brand is not verified
          error={!!socialMediaErrors.youtube}
          helperText={socialMediaErrors.youtube}
        />

        <TextField
          margin="dense"
          label="LinkedIn"
          name="linkedIn"
          value={brandData?.socialMedia?.linkedIn || ""}
          onChange={(e) =>
            setBrandData({
              ...brandData,
              socialMedia: {
                ...brandData.socialMedia,
                linkedIn: e.target.value,
              },
            })
          }
          fullWidth
          variant="outlined"
          disabled={!brandData.verified} // Disable if brand is not verified
          error={!!socialMediaErrors.linkedIn}
          helperText={socialMediaErrors.linkedIn}
        />
        <TextField
          margin="dense"
          label="Tiktok"
          name="tiktok"
          value={brandData?.socialMedia?.tiktok || ""}
          onChange={(e) =>
            setBrandData({
              ...brandData,
              socialMedia: {
                ...brandData.socialMedia,
                tiktok: e.target.value,
              },
            })
          }
          fullWidth
          variant="outlined"
          disabled={!brandData.verified} // Disable if brand is not verified
          error={!!socialMediaErrors.tiktok}
          helperText={socialMediaErrors.tiktok}
        />
      </Box>
    </>
  );
}

export default BrandSocialMediaLinksEdit;
