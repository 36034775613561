import { setTags } from "redux/state"; // Assuming you have an action for setting tags in your state

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const addTag = (articleId, tagName) => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/tags`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ articleId, tagName }),
    });
    const updatedArticle = await response.json();
    dispatch(fetchTags()); // Refresh the list of tags after adding
    return updatedArticle;
  } catch (error) {
    console.error("Error while adding tag:", error);
  }
};

export const removeTag = (articleId, tagName) => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/tags`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ articleId, tagName }),
    });
    const result = await response.json();
    dispatch(fetchTags()); // Refresh the list of tags after removing
    return result;
  } catch (error) {
    console.error("Error while removing tag:", error);
  }
};

export const fetchTags = () => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/tags`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const tags = await response.json();
    dispatch(setTags(tags));
  } catch (error) {
    console.error("Error while fetching tags:", error);
  }
};

// get all tags

export const fetchAllTags = () => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/tags/all`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const tags = await response.json();
    dispatch(setTags(tags));
  } catch (error) {
    console.error("Error while fetching tags:", error);
  }
};
