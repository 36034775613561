import { Box, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import BrandSidebar from "../components/BrandSidebar";
import Navbar from "scenes/navbar";
import { Outlet } from "react-router-dom";
import BottomMenu from "components/bottomMenu/BottomMenu";

function BrandLayout() {
  const isLargeScreen = useMediaQuery("(min-width:1000px)");
  const [isSideBarOpen, setIsSideBarOpen] = useState(isLargeScreen);
  const [selectedBrandId, setSelectedBrandId] = useState(null);

  return (
    <Box display={isLargeScreen ? "flex" : "block"} width="100%" height="100%">
      <BrandSidebar
        isLargeScreen={isLargeScreen}
        drawerWidth="250px"
        isSideBarOpen={isSideBarOpen}
        setIsSideBarOpen={setIsSideBarOpen}
        setSelectedBrandId={setSelectedBrandId}
        selectedBrandId={selectedBrandId}
      />
      <Box flexGrow={1}>
        <Navbar
          isSideBarOpen={isSideBarOpen}
          setIsSideBarOpen={setIsSideBarOpen}
          isAdminPage={true}
        />
        <Outlet context={{ selectedBrandId, setSelectedBrandId }} />
      </Box>
      {!isLargeScreen && <BottomMenu />}
    </Box>
  );
}

export default BrandLayout;
