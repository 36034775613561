import {
  setUser,
  setViewingUser,
  setUserSearchResults,
  setUsers,
  setTotalUsers,
  setDistanceBetweenUsers,
  setDistanceBetweenUserAndBrand,
  setMemberSuggestions,
  setBrandDirectory,
  setUserNotificationSettings,
  setUserRecommendations,
  setBlockedUsers,
  setSoftBlockedUsers,
} from "redux/state";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const fetchUser =
  (userId, isProfilePage = false) =>
  async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/users/${userId}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    const currentUser = getState().user;

    if (isProfilePage) {
      dispatch(setUser(data));
    } else if (currentUser && currentUser._id === userId) {
      dispatch(setUser(data));
      dispatch(setViewingUser(data));
    } else {
      dispatch(setViewingUser(data));
    }
    return data;
  };

export const fetchUsers =
  (page, pageSize, sort, search) => async (dispatch, getState) => {
    try {
      const token = getState().token;

      // Construct URL with query parameters
      let url = `${BASE_URL}/users?page=${page}&limit=${pageSize}`;

      if (sort) {
        url += `&sort=${sort}`;
      }
      if (search) {
        url += `&search=${search}`;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      dispatch(setUsers(data.data));
      dispatch(setTotalUsers(data.total));

      return data;
      // return data;
    } catch (error) {
      console.log(error);
    }
  };

export const patchUser =
  (userId, updatedUser) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      const response = await fetch(`${BASE_URL}/users/${userId}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedUser),
      });
      const currentUser = getState().user;
      const data = await response.json();
      if (currentUser && currentUser._id === userId) {
        dispatch(setUser(data));
      }
      return data;
    } catch (error) {
      console.log(error);
    }
  };

export const setStripeCustomerId = (userId) => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/users/${userId}/stripeCustomerId`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    dispatch(fetchUser(userId));
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const searchUsers =
  (searchTerm, page, limit) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      const response = await fetch(
        `${BASE_URL}/users/search?searchTerm=${searchTerm}&page=${page}&limit=${limit}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const data = await response.json();
      // console.log("🚀 ~ data:", data);
      dispatch(setUserSearchResults(data));
      return data;
    } catch (error) {
      console.log(error);
    }
  };

export const fetchDistanceBetweenUsers =
  (userId1, userId2) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      const response = await fetch(
        `${BASE_URL}/users/${userId1}/distance/${userId2}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (!response.ok) {
        throw new Error(`Error fetching distance: ${response.status}`);
      }

      const data = await response.json();

      // Convert meters to miles and kilometers
      const distanceInMeters = data.distance;
      const distanceInKilometers = distanceInMeters / 1000;
      const distanceInMiles = distanceInMeters / 1609.34;

      // Round to nearest 5 kilometers or miles
      const roundToNearest5 = (num) => Math.round(num / 5) * 5;
      data.distanceInKilometers = roundToNearest5(distanceInKilometers);
      data.distanceInMiles = roundToNearest5(distanceInMiles);

      // Dispatch action to store the distance in Redux state (if needed)
      dispatch(setDistanceBetweenUsers(data));

      return data; // This will return the distance data with conversions and rounding
    } catch (error) {
      console.error("Error fetching distance between users:", error);
      throw error; // Propagate the error to be handled by the caller
    }
  };

export const fetchDistanceBetweenUserAndBrand =
  (userId, brandId) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      const response = await fetch(
        `${BASE_URL}/users/${userId}/distanceToBrand/${brandId}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (!response.ok) {
        throw new Error(`Error fetching distance: ${response.status}`);
      }

      const data = await response.json();

      // Convert meters to miles and kilometers
      const distanceInMeters = data.distance;
      const distanceInKilometers = distanceInMeters / 1000;
      const distanceInMiles = distanceInMeters / 1609.34;

      // Round to nearest 5 kilometers or miles
      const roundToNearest5 = (num) => Math.round(num / 1) * 1;
      data.distanceInKilometers = roundToNearest5(distanceInKilometers);
      data.distanceInMiles = roundToNearest5(distanceInMiles);

      // Dispatch action to store the distance in Redux state (if needed)
      dispatch(setDistanceBetweenUserAndBrand(data));

      return data; // This will return the distance data with conversions and rounding
    } catch (error) {
      console.error("Error fetching distance between user and brand:", error);
      throw error; // Propagate the error to be handled by the caller
    }
  };

export const fetchMemberSuggestions =
  (userId) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      const response = await fetch(
        `${BASE_URL}/users/${userId}/memberSuggestions`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = await response.json();
      dispatch(setMemberSuggestions(data));
      return data;
    } catch (error) {
      console.log(error);
    }
  };

export const fetchClosestBrandsNotFollowed =
  () => async (dispatch, getState) => {
    try {
      const token = getState().token;
      const response = await fetch(
        `${BASE_URL}/users/brands/closestNotFollowed`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = await response.json();
      dispatch(setBrandDirectory({ closestBrandsNotFollowed: data }));
      return data;
    } catch (error) {
      console.log(error);
    }
  };

export const fetchBrandsFriendsFollowing = () => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/users/brands/friendsFollowing`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    dispatch(setBrandDirectory({ brandsFriendsFollowing: data }));
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchBrandsMatchingSports = () => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/users/brands/matchingSports`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    dispatch(setBrandDirectory({ brandsMatchingSports: data }));
    return data;
  } catch (error) {
    console.log(error);
  }
};

// Action to fetch notification settings for a user
export const fetchNotificationSettings =
  (userId) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      const response = await fetch(
        `${BASE_URL}/users/notificationSettings/${userId}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch notification settings");
      }

      const data = await response.json();
      // Dispatch an action to save the fetched notification settings to the Redux store
      dispatch(setUserNotificationSettings(data));
    } catch (error) {
      console.error("Error fetching notification settings:", error);
    }
  };

// Action to update notification preferences
export const updateNotificationPreferences =
  (userId, preferences) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      const response = await fetch(
        `${BASE_URL}/users/notificationPreferences/${userId}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(preferences),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update notification preferences");
      }

      const updatedSettings = await response.json();
      // Dispatch an action to update the Redux store with the new notification settings
      dispatch(setUserNotificationSettings(updatedSettings));
    } catch (error) {
      console.error("Error updating notification preferences:", error);
    }
  };

export const recommendFriend =
  (userId, recommendedEmail, recommendedName) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      const response = await fetch(`${BASE_URL}/users/recommendFriend`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, recommendedEmail, recommendedName }),
      });

      const data = await response.json();

      dispatch(fetchUser(userId));
      return data;
    } catch (error) {
      console.log(error);
    }
  };

// Action to remind friends to invite other friends
export const toggleFriendsInviteReminder =
  (userId) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      const response = await fetch(
        `${BASE_URL}/users/toggleFriendsInviteReminder`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId }),
        }
      );
      const data = await response.json();
      dispatch(fetchUser(userId));
      return data;
    } catch (error) {
      console.log(error);
    }
  };

export const fetchUserRecommendations =
  (page, limit) => async (dispatch, getState) => {
    try {
      const token = getState().token;

      // Construct the request URL with pagination parameters
      const url = `${BASE_URL}/users/recommendations?page=${page}&limit=${limit}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`, // Use the user's auth token
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(
          `Failed to fetch recommendations: ${response.statusText}`
        );
      }

      const data = await response.json();

      dispatch(setUserRecommendations(data));

      return data; // Return the fetched data
    } catch (error) {
      console.error("Error fetching user recommendations:", error);
      throw error; // Propagate the error to be handled by the caller
    }
  };

export const blockUser = (userId, blockId) => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/users/blockUser`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId, blockId }),
    });
    const data = await response.json();
    dispatch(fetchBlockedUsers(userId));
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const unblockUser = (userId, blockId) => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/users/unblockUser`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId, blockId }),
    });
    const data = await response.json();
    dispatch(fetchBlockedUsers(userId));
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const softBlockUser =
  (userId, softBlockId) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      const response = await fetch(`${BASE_URL}/users/softBlockUser`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, softBlockId }),
      });
      const data = await response.json();
      dispatch(fetchSoftBlockedUsers(userId));
      return data;
    } catch (error) {
      console.log(error);
    }
  };

export const unsoftBlockUser =
  (userId, softBlockId) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      const response = await fetch(`${BASE_URL}/users/unsoftBlockUser`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, softBlockId }),
      });
      const data = await response.json();
      dispatch(fetchBlockedUsers(userId));
      return data;
    } catch (error) {
      console.log(error);
    }
  };

export const fetchBlockedUsers = (userId) => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/users/${userId}/blockedUsers`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    dispatch(setBlockedUsers(data));
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchSoftBlockedUsers = (userId) => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/users/${userId}/softBlockedUsers`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();
    dispatch(setSoftBlockedUsers(data));
    return data;
  } catch (error) {
    console.log(error);
  }
};
