import React, { useState, useEffect } from "react";
import WidgetWrapper from "components/WidgetWrapper";
import {
  Box,
  Typography,
  useTheme,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Avatar,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import SportSelection from "scenes/SettingsAndPrivacy/profileEdit/components/SportSelection";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import FormLocation from "scenes/loginPage/FormComponents/FormLocation";
import { useGroupCreateContext } from "scenes/groups/GroupCreate/components/GroupCreateContext";
import { createGroup, joinGroup } from "redux/actions/groupActions";
import { patchUser, setLocations, setSports } from "redux/state";

function GroupCreateWidget({ socket }) {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const main = palette.primary.main;
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  const fullName = `${user.firstName} ${user.lastName}`;
  const picturePath = user.picturePath;
  const userId = user._id;
  const userFriends = user.friends;

  const dark = palette.neutral.dark;
  const medium = palette.neutral.medium;

  const [description, setDescription] = useState(``);

  const handleCreateGroup = async () => {
    try {
      const payload = {
        name: groupName,
        description: description,
        sport: selectedSport,
        experienceLevel: experienceLevel,
        location: selectedLocation,
        isPrivate: privacy === "public" ? false : true,
        isVisible: visibility === "visible" ? true : false,
        invitations: selectedFriends.map((friend) => friend._id),
        administrators: userId,
        members: userId,
        image:
          "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/c6e6fdf2-721f-42fb-236b-8d7fd0111800/public",
      };

      // Call the createGroup action function with the payload
      const newGroup = await dispatch(createGroup(payload));

      //map through the selected friends and send them a notification
      selectedFriends.forEach((friend) => {
        const notificationData = {
          senderId: userId,
          receiverId: friend._id,
          name: fullName,
          groupName: groupName,
          groupId: newGroup._id,
          picturePath: newGroup.image,
          timeStamp: Date.now(),
          type: "groupInvite",
        };

        socket.emit("sendNotification", notificationData);
      });

      // Handle success or show success message
      console.log("Group created successfully!");
      // Navigate to the group page
      if (newGroup && newGroup._id) {
        navigate(`/groups/${newGroup._id}`);
      }

      dispatch(joinGroup(newGroup._id, userId));
      // Update the user with the new group
      const updatedUser = {
        ...user,
        groupsJoined: [...user.groupsJoined, newGroup._id],
      };
      // Update the user in the redux store
      dispatch(patchUser(userId, updatedUser));
      dispatch(setLocations([]));
      dispatch(setSports([]));
    } catch (error) {
      console.error("Error creating group:", error);
      // Show some error message to the user
    }
  };

  const {
    groupName,
    setGroupName,
    privacy,
    setPrivacy,
    visibility,
    setVisibility,
    selectedFriends,
    setSelectedFriends,
    availableFriends,
    setAvailableFriends,
    selectedSport,
    setSelectedSport,
    selectedLocation,
    setSelectedLocation,
    experienceLevel,
    setExperienceLevel,
  } = useGroupCreateContext();

  useEffect(() => {
    if (userFriends && selectedFriends) {
      // make sure both userFriends and selectedFriends are available
      const updatedAvailableFriends = userFriends.filter(
        (friend) =>
          !selectedFriends.find((selected) => selected._id === friend._id)
      );
      setAvailableFriends(updatedAvailableFriends);
    }
  }, [userFriends, selectedFriends]);

  // Update description when dependencies change
  useEffect(() => {
    const newDescription = `This is a group for people who${
      selectedSport
        ? ` take part in ${selectedSport}`
        : " are interested in sports"
    }${selectedLocation ? ` in ${selectedLocation.address}` : ""}${
      experienceLevel.length > 0
        ? ` with ${experienceLevel.join(", ")} experience level`
        : ""
    }.`;

    setDescription(newDescription);
  }, [selectedSport, selectedLocation, experienceLevel]);

  return (
    <WidgetWrapper component="nav">
      <Box width="100%">
        {/* breadcrumb trail */}
        <Box
          display="flex"
          alignItems="center"
          gap="0.5rem"
          color={palette.neutral.medium}
        >
          <Link
            to="/groups"
            style={{ textDecoration: "none", cursor: "pointer" }}
            onMouseOver={(e) =>
              (e.currentTarget.style.textDecoration = "underline")
            }
            onMouseOut={(e) => (e.currentTarget.style.textDecoration = "none")}
          >
            <Typography variant="body1">Groups</Typography>
          </Link>
          <Typography variant="body1">&gt;</Typography>
          <Typography variant="body1" color={main}>
            Create Group
          </Typography>
        </Box>

        <Box>
          <Typography variant="h5" color={main}>
            Create Group
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          gap="1rem"
          color={main}
          mt="0.5rem"
        >
          <Avatar
            src={picturePath}
            sx={{ width: "60px", height: "60px" }}
            onClick={() => navigate(`/profile/${userId}`)}
            style={{ cursor: "pointer" }}
          />
          <Box>
            <Typography
              variant="h5"
              color={dark}
              fontWeight="500"
              sx={{
                "&:hover": {
                  color: palette.primary.light,
                  cursor: "pointer",
                },
              }}
              onClick={() => navigate(`/profile/${userId}`)}
            >
              {fullName}
            </Typography>
            <Typography color={medium}>Admin</Typography>
          </Box>
        </Box>

        {/* Group Name */}

        <Box mt="1rem">
          <Box>
            <TextField
              label="Group Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            />

            {/* Add SportSelection */}

            <FormControl
              variant="outlined"
              fullWidth
              margin="normal"
              style={{ marginTop: "-5px" }}
            >
              <SportSelection
                formProps={{
                  setFieldValue: (fieldPath, newValue) => {
                    setSelectedSport(newValue);
                  },
                  handleBlur: () => {}, // you can add a function to handle blur events
                  touched: {}, // you can update this to reflect the form's touched state
                  errors: {}, // you can update this to reflect the form's error state
                }}
                index={0} // Replace with appropriate index if you have multiple sports
                sport={{ sport: selectedSport }}
              />
            </FormControl>

            {/* Experience Level */}

            <FormControl
              variant="outlined"
              fullWidth
              margin="normal"
              style={{ marginTop: "3px" }}
            >
              <InputLabel id="experience-level-label">
                Experience Level (optional)
              </InputLabel>
              <Select
                labelId="experience-level-label"
                id="experience-level"
                multiple
                value={experienceLevel}
                onChange={(e) => setExperienceLevel(e.target.value)}
                label="Experience Level (optional)"
              >
                <MenuItem value="Beginner">Beginner</MenuItem>
                <MenuItem value="Intermediate">Intermediate</MenuItem>
                <MenuItem value="Advanced">Advanced</MenuItem>
                <MenuItem value="Expert">Expert</MenuItem>
              </Select>
            </FormControl>

            {/* Add FormLocation component */}

            <FormControl
              variant="outlined"
              fullWidth
              margin="normal"
              style={{ marginTop: "12px" }}
            >
              <FormLocation
                setFieldValue={(fieldPath, newValue) => {
                  setSelectedLocation(newValue);
                }}
                handleBlur={() => {}}
                touched={{}}
                errors={{}}
              />
            </FormControl>

            {/* Privacy */}

            <FormControl
              variant="outlined"
              fullWidth
              margin="normal"
              style={{ marginTop: "12px" }}
            >
              <InputLabel htmlFor="privacy-select">Privacy</InputLabel>
              <Select
                labelId="privacy-select"
                value={privacy}
                onChange={(e) => setPrivacy(e.target.value)}
                label="Privacy"
              >
                <MenuItem value="public">Public</MenuItem>
                <MenuItem value="private">Private</MenuItem>
              </Select>
            </FormControl>
            {privacy === "private" && (
              <>
                <Typography sx={{ color: medium }}>
                  This is a private group. Only members can see the group's
                  name, description, and posts.
                </Typography>
                <FormControl variant="outlined" fullWidth margin="normal">
                  <InputLabel htmlFor="visibility-select">
                    Visibility
                  </InputLabel>
                  <Select
                    labelId="visibility-select"
                    value={visibility}
                    onChange={(e) => setVisibility(e.target.value)}
                    label="Visibility"
                  >
                    <MenuItem value="visible">Visible</MenuItem>
                    <MenuItem value="hidden">Hidden</MenuItem>
                  </Select>
                </FormControl>
              </>
            )}

            {(availableFriends && availableFriends.length > 0) |
              (selectedFriends && selectedFriends.length > 0) && (
              <Autocomplete
                style={{ marginTop: "-5px" }}
                multiple
                id="invite-friends"
                options={availableFriends}
                value={selectedFriends}
                getOptionLabel={(option) =>
                  `${option.firstName} ${option.lastName}`
                }
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                onChange={(event, newValue) => {
                  // Map over the newValue and ensure they are references from userFriends
                  setSelectedFriends((prevSelectedFriends) => {
                    const newSelectedFriends = newValue.map((val) =>
                      userFriends.find((friend) => friend._id === val._id)
                    );
                    setAvailableFriends(
                      userFriends.filter(
                        (friend) =>
                          !newSelectedFriends.find(
                            (selected) => selected._id === friend._id
                          )
                      )
                    );
                    return newSelectedFriends;
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Invite Friends (optional)"
                    margin="normal"
                  />
                )}
              />
            )}
          </Box>

          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateGroup}
            disabled={!groupName || !selectedSport || !selectedLocation}
          >
            Create
          </Button>
        </Box>
      </Box>
    </WidgetWrapper>
  );
}

export default GroupCreateWidget;
