import React from "react";
import { Box, FormHelperText, Switch } from "@mui/material";
import Header from "scenes/admin/components/Header";
import { handleImageUpload } from "redux/actions/imageUploadUtils";
import ArticlePreview from "../articlePreview";
import WidgetWrapper from "components/WidgetWrapper";
import { useArticlesState } from "./hooks/useArticleState";
import CategorySelect from "./components/CategorySelect";
import SportsSelect from "./components/SportsSelect";
import TitleImageDropZone from "./components/TitleImageDropZone";
import ContentBlocks from "./components/ContentBlocks";
import TagInput from "./components/TagInput";
import FlexBetween from "components/FlexBetween";
import TitleAndSubTitle from "./components/TitleAndSubTitle";
import FormActionButtons from "./components/FormActionButtons";

function ArticleCreate({ socket }) {
  const {
    title,
    subtitle,
    titleImage,
    tags,
    contentBlocks,
    selectedCategory,
    selectedSport,
    newTagName,
    addTextBlock,
    addImageBlock,
    removeBlock,
    updateBlockData,
    handleAddTag,
    handleRemoveTag,
    handleCreateArticle,
    setTitle,
    setSubtitle,
    setSelectedCategory,
    setSelectedSport,
    setNewTagName,
    handleTitleImageUpload,
    sportsList,
    categories,
    user,
    modules,
    formats,
    tagsList,
    isLargeScreen,
    showPreview,
    setShowPreview,
    formErrors,
    selectedBrandId,
  } = useArticlesState({ socket });

  const defaultImageURL =
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/6d0f5d8b-96b6-48d8-7bf8-ba57dbcea500/512x512";

  if (!selectedBrandId) {
    return (
      <Box
        m={isLargeScreen ? "1.5rem 2rem 2rem 3rem" : "0.5rem 0 1rem 0"}
        pb={isLargeScreen ? 0 : "4rem"}
      >
        <WidgetWrapper
          maxWidth={isLargeScreen ? "1500px" : "100%"}
          // align in teh center of the page
          style={{
            margin: "0 auto",
          }}
        >
          <Header title="CREATE ARTICLE" subtitle="Create a new article" />
          <FormHelperText error style={{ margin: "4px 0" }}>
            Please select a brand to create an article
          </FormHelperText>
        </WidgetWrapper>
      </Box>
    );
  }

  return (
    <Box
      m={isLargeScreen ? "1.5rem 2rem 2rem 3rem" : "0.5rem 0 1rem 0"}
      pb={isLargeScreen ? 0 : "4rem"}
    >
      <WidgetWrapper
        maxWidth={isLargeScreen ? "1500px" : "100%"}
        // align in teh center of the page
        style={{
          margin: "0 auto",
        }}
      >
        <FlexBetween>
          <Header title="CREATE ARTICLE" subtitle="Create a new article" />
          <div>
            <label>
              {showPreview ? "Hide" : "Show"} Preview
              <Switch
                checked={showPreview}
                onChange={() => setShowPreview(!showPreview)}
              />
            </label>
          </div>
        </FlexBetween>
        <FlexBetween>
          <CategorySelect
            categories={categories}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            error={formErrors.selectedCategory}
          />

          <SportsSelect
            sportsList={sportsList}
            selectedSport={selectedSport}
            setSelectedSport={setSelectedSport}
            error={formErrors.selectedSport}
          />
        </FlexBetween>

        <Box component="form" noValidate autoComplete="off">
          <TitleAndSubTitle
            title={title}
            setTitle={setTitle}
            subtitle={subtitle}
            setSubtitle={setSubtitle}
            formErrors={formErrors}
          />

          <TitleImageDropZone
            handleTitleImageUpload={handleTitleImageUpload}
            titleImage={titleImage}
            defaultImageURL={defaultImageURL}
            error={formErrors.titleImage}
          />

          <ContentBlocks
            contentBlocks={contentBlocks}
            removeBlock={removeBlock}
            updateBlockData={updateBlockData}
            handleImageUpload={handleImageUpload}
            defaultImageURL={defaultImageURL}
            modules={modules}
            formats={formats}
          />

          {/* Display error message for contentBlocks when there are no blocks */}
          {contentBlocks.length === 0 && formErrors.contentBlocks && (
            <FormHelperText error style={{ margin: "4px 0" }}>
              {formErrors.contentBlocks}
            </FormHelperText>
          )}

          <TagInput
            tags={tags}
            newTagName={newTagName}
            setNewTagName={setNewTagName}
            handleAddTag={handleAddTag}
            handleRemoveTag={handleRemoveTag}
            tagsList={tagsList}
            error={formErrors.tags}
          />

          <FormActionButtons
            addTextBlock={addTextBlock}
            addImageBlock={addImageBlock}
            handleCreateArticle={handleCreateArticle}
          />
        </Box>
      </WidgetWrapper>
      {showPreview && (
        <ArticlePreview
          title={title}
          subtitle={subtitle}
          contentBlocks={contentBlocks}
          firstName={user.firstName}
          lastName={user.lastName}
          titleImage={titleImage}
          published_at={new Date()}
          category={selectedCategory}
          tags={tags}
          user={user}
          selectedBrandId={selectedBrandId}
        />
      )}
    </Box>
  );
}

export default ArticleCreate;
