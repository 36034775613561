import { Box } from "@mui/material";
import React from "react";
import FormFields from "./FormFields";
import ImageUploadAndPreview from "scenes/admin/directory/BrandCreateForm/components/ImageUploadAndPreview";

function RoleForm({
  image,
  handleFileChange,
  fileInputRef,
  defaultImageURL,
  handleEditClick,
  handleChange,
  formData,
  formErrors,
}) {
  return (
    <Box>
      <ImageUploadAndPreview
        image={image}
        handleFileChange={handleFileChange}
        fileInputRef={fileInputRef}
        defaultImageURL={defaultImageURL}
        handleEditClick={handleEditClick}
      />
      <FormFields
        handleChange={handleChange}
        formData={formData}
        formErrors={formErrors}
      />
    </Box>
  );
}

export default RoleForm;
