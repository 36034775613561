import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material";
import ExperienceType from "../../../../components/ExperienceType";

const UserSportsTable = ({ sports }) => {
  const { palette } = useTheme();
  const backgroundDark = palette.background.dark;
  const textLight = palette.primary.light;

  const tableStylesSport = {
    boxShadow: "none", // Remove drop shadow
    backgroundColor: backgroundDark,
    borderRadius: "1rem",
    "& thead th": {
      borderBottom: "none", // Remove horizontal lines in header cells
      color: textLight,
      fontWeight: "700",
    },
    "& tbody td": {
      borderBottom: "none", // Remove horizontal lines in body cells
      color: textLight,
      paddingTop: "0",
    },
  };

  return (
    <TableContainer component={Paper} sx={tableStylesSport}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Sport</TableCell>
            <TableCell>Frequency</TableCell>
            <TableCell>Level</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sports.map((sport) => (
            <TableRow key={sport._id}>
              <TableCell>{sport.sport}</TableCell>
              <TableCell>
                {`${sport.frequency.amount}x a ${sport.frequency.unit}`}
              </TableCell>
              <TableCell>
                <ExperienceType expertType={sport.experienceLevel} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserSportsTable;
