import WidgetWrapper from "components/WidgetWrapper";
import Navbar from "scenes/navbar";
import Header from "../../components/Header";
import { Box, Button } from "@mui/material";
import BrandImageEdit from "./components/BrandImageEdit";
import BrandInfoFormEdit from "./components/BrandInfoFormEdit";
import BrandSocialMediaLinksEdit from "./components/BrandSocialMediaLinksEdit";
import BrandSportSelectionEdit from "./components/BrandSportSelectionEdit";
import BrandDescriptionEdit from "./components/BrandDescriptionEdit";
import BrandVerificationEdit from "./components/BrandVerificationEdit";
import BrandLocationEdit from "./components/BrandLocationEdit";
import BrandManagerEdit from "./components/BrandManagerEdit";
import BrandArticleWriterEdit from "./components/BrandArticleWriterEdit";

import BrandLogoEdit from "./components/BrandLogoEdit";
import useBrandEdit from "./hooks/useBrandEdit";
import SnackbarComponent from "components/SnackbarComponent";

function BrandEdit({ socket, selectedBrandId }) {
  const {
    brand,
    brandData,
    setBrandData,
    formErrors,
    sportsList,
    handleSportChange,
    handleSubmit,
    isSelectedBrandId,
    isLargeScreen,
    snackbarOpen,
    setSnackbarOpen,
    snackbarMessage,
    snackbarSeverity,
  } = useBrandEdit({ selectedBrandId });
  return (
    <Box pb={isLargeScreen ? 0 : "4rem"}>
      {!selectedBrandId && <Navbar socket={socket} />}

      <WidgetWrapper
        m="0 1rem"
        maxWidth={isLargeScreen ? "1500px" : "100%"}
        // align in teh center of the page
        style={{
          margin: "0 auto",
        }}
      >
        <Header title="BRAND ADMINISTRATION" subtitle={brand?.name} />
        {brandData ? (
          <>
            <BrandImageEdit
              brandData={brandData}
              setBrandData={setBrandData}
              isLargeScreen={isLargeScreen}
            />
            <BrandLogoEdit brandData={brandData} setBrandData={setBrandData} />

            <form onSubmit={handleSubmit}>
              <Box
                display="flex"
                flexDirection={isLargeScreen ? "row" : "column"}
                gap="1rem"
                width={isLargeScreen ? "100%" : "auto"}
              >
                <Box width="100%">
                  <BrandInfoFormEdit
                    brandData={brandData}
                    setBrandData={setBrandData}
                    formErrors={formErrors}
                    isLargeScreen={isLargeScreen}
                  />

                  {/* Verification */}
                  <BrandVerificationEdit
                    brandData={brandData}
                    setBrandData={setBrandData}
                    isSelectedBrandId={isSelectedBrandId}
                  />
                </Box>
                {/* Locations */}
                <BrandLocationEdit
                  brandData={brandData}
                  setBrandData={setBrandData}
                  formErrors={formErrors}
                />
              </Box>

              <Box
                display="flex"
                flexDirection={isLargeScreen ? "row" : "column"}
                gap="1rem"
                width={isLargeScreen ? "100%" : "auto"}
                style={{
                  justifyContent: "space-between", // Adjust this to space-between to ensure the columns are spaced apart
                }}
              >
                {/* Column for Brand Manager */}
                <Box
                  display="flex"
                  flexDirection="column"
                  width={isLargeScreen ? "50%" : "100%"}
                  style={{
                    alignItems: "flex-start", // Align to the left
                  }}
                >
                  {/* Brand Manager */}
                  <BrandManagerEdit
                    brandData={brandData}
                    setBrandData={setBrandData}
                    isLargeScreen={isLargeScreen}
                  />
                </Box>

                {/* Column for Brand Article Writer */}
                <Box
                  display="flex"
                  flexDirection="column"
                  width={isLargeScreen ? "50%" : "100%"}
                  style={{
                    alignItems: "flex-start", // Align to the left
                  }}
                >
                  {/* Brand Article Writer */}
                  <BrandArticleWriterEdit
                    brandData={brandData}
                    setBrandData={setBrandData}
                    isLargeScreen={isLargeScreen}
                  />
                </Box>
              </Box>

              {/* Descripition */}
              <BrandDescriptionEdit
                brandData={brandData}
                setBrandData={setBrandData}
                formErrors={formErrors}
              />

              {/* Sport */}
              <BrandSportSelectionEdit
                brandData={brandData}
                formErrors={formErrors}
                sportsList={sportsList}
                handleSportChange={handleSportChange}
              />

              {/* Social Media Links */}
              <BrandSocialMediaLinksEdit
                brandData={brandData}
                setBrandData={setBrandData}
                formErrors={formErrors}
                isLargeScreen={isLargeScreen}
              />

              {/* ... other form fields ... */}
              <Button type="submit" variant="contained" color="primary">
                Update
              </Button>
              <SnackbarComponent
                open={snackbarOpen}
                message={snackbarMessage}
                onClose={() => setSnackbarOpen(false)}
                severity={snackbarSeverity}
              />
            </form>
          </>
        ) : (
          <div>Loading...</div>
        )}
      </WidgetWrapper>
    </Box>
  );
}

export default BrandEdit;
