import { ModeEditOutlineOutlined } from "@mui/icons-material";
import { Box, Button, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { handleImageUpload } from "redux/actions/imageUploadUtils";

function BrandLogoEditRegister({
  formData,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  setSnackbarMessage,
  setSnackbarSeverity,
  setSnackbarOpen,
}) {
  const fileInputRef = useRef(null);

  const { palette } = useTheme();
  const neutralLight = palette.neutral.light;

  const [imageLogo, setImageLogo] = useState("");

  const defaultImageURL =
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/6d0f5d8b-96b6-48d8-7bf8-ba57dbcea500/512x512";

  const handleEditClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const uploadedImageUrl = await handleImageUpload(file);
        if (uploadedImageUrl) {
          setImageLogo(uploadedImageUrl); // This will update the displayed image
          setSnackbarMessage("Image uploaded successfully.");
          setSnackbarSeverity("success");
        } else {
          console.error("Image upload failed. Using default image.");
          setImageLogo(defaultImageURL);
          setSnackbarMessage("Image upload failed. Using default image.");
          setSnackbarSeverity("error");
        }
      } catch (error) {
        console.error("Image upload failed:", error);
        setImageLogo(defaultImageURL);
        setSnackbarMessage(`Image upload failed: ${error.message}`);
        setSnackbarSeverity("error");
      }
    }
    setSnackbarOpen(true);
  };

  useEffect(() => {
    if (imageLogo) {
      setFieldValue("businessForm.logoPath", imageLogo);
    } else {
      setFieldValue("businessForm.logoPath", defaultImageURL);
    }
  }, [imageLogo, setFieldValue]);

  return (
    <Box marginBottom="1rem" display="flex" alignItems="center" gap={5}>
      <img
        src={imageLogo || defaultImageURL}
        alt={formData?.businessForm?.name}
        style={{ width: "50px", height: "auto", borderRadius: "10px" }}
      />

      <Button
        onClick={handleEditClick}
        startIcon={<ModeEditOutlineOutlined />}
        style={{
          backgroundColor: neutralLight,
        }}
      >
        Edit Logo
      </Button>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </Box>
  );
}

export default BrandLogoEditRegister;
