import { useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import * as yup from "yup";
import {
  fetchFilteredUsers,
  updateUserSearchCriteria,
} from "redux/actions/searchActions";

const skillLevels = ["Beginner", "Intermediate", "Advanced", "Expert"];

// Function to map slider values to your age range categories
const sliderValueToAgeRange = (value) => {
  const ageRanges = [
    "18-24",
    "25-34",
    "35-44",
    "45-54",
    "55-64",
    "65-74",
    "75+",
  ];
  return ageRanges[value];
};

const ageRanges = ["18-24", "25-34", "35-44", "45-54", "55-64", "65-74", "75+"];

// Function to find indices of age ranges
const findAgeRangeIndices = (ranges) => {
  return ranges
    .map((range) => ageRanges.indexOf(range))
    .filter((index) => index !== -1);
};

const filterMembersSchema = yup.object().shape({
  location: yup.object().shape({
    coordinates: yup.array().of(yup.number()), //.length(2),
    address: yup.string(), //.required("Address is required.").min(3).max(255),
  }),
  locationRadius: yup.number(),
  sports: yup.array().of(
    yup.object().shape({
      sport: yup.string(),
      experienceLevel: yup.array().of(yup.string().oneOf(skillLevels)), // This should be an array of strings
    })
  ),
  ageRanges: yup
    .array()
    .of(
      yup
        .string()
        .oneOf(["18-24", "25-34", "35-44", "45-54", "55-64", "65-74", "75+"])
    ),
});

const useFilterMembers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { palette } = useTheme();
  const primary = palette.primary.main;
  const primaryDark = palette.primary.dark;
  const neutralLight = palette.neutral.light;
  const background = palette.background.default;

  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  const [dialogOpen, setDialogOpen] = useState(false);

  const [showResults, setShowResults] = useState(false);

  const filteredMembers = useSelector((state) => state.filteredMembers);

  const [currentPage, setCurrentPage] = useState(
    filteredMembers.currentPage || 1
  );
  const [totalPages, setTotalPages] = useState(filteredMembers.totalPages || 1);
  const [totalUsers, setTotalUsers] = useState(filteredMembers.totalUsers || 0);
  const [users, setUsers] = useState(filteredMembers.users || []);

  const { user, searchCriteria } = useSelector((state) => ({
    user: state.user,
    searchCriteria: state.user.searchCriteria || {
      location: { coordinates: [0, 0], address: "" },
      locationRadius: 10,
      sports: [],
      ageRanges: [],
    },
  }));

  const userId = user._id;

  const [filterValues, setFilterValues] = useState(() => {
    const defaultLocation =
      searchCriteria.location && searchCriteria.location.address
        ? searchCriteria.location
        : user.location && user.location.address
        ? {
            coordinates: user.location.coordinates,
            address: user.location.address,
          }
        : { coordinates: [0, 0], address: "" };

    return {
      ...searchCriteria,
      location: defaultLocation,
    };
  });

  /* LOCATION */

  const handleLocationSelect = useCallback((locationDetails) => {
    setFilterValues((prevValues) => ({
      ...prevValues,
      location: {
        coordinates:
          locationDetails.coordinates || prevValues.location.coordinates,
        address: locationDetails.address || prevValues.location.address,
      },
    }));
  }, []);

  // When handling the location radius change:
  const handleLocationRadiusChange = (event) => {
    const newRadius = event.target.value;
    setFilterValues((prevValues) => ({
      ...prevValues,
      locationRadius: newRadius,
    }));
  };

  /* SPORTS */

  const userSportNames = user.sports.map((sport) => sport.sport);
  const [displayedSportsCount, setDisplayedSportsCount] = useState(8);

  const [selectedSports, setSelectedSports] = useState(() => {
    return searchCriteria.sports.reduce((acc, curr) => {
      if (user.sports.some((sport) => sport.sport === curr.sport)) {
        acc[curr.sport] = true;
      }
      return acc;
    }, {});
  });

  const [skillLevelSelection, setSkillLevelSelection] = useState(() => {
    return searchCriteria.sports.reduce((acc, curr) => {
      if (user.sports.some((sport) => sport.sport === curr.sport)) {
        acc[curr.sport] = curr.experienceLevel;
      }
      return acc;
    }, {});
  });

  const updateFilterValuesSports = useCallback(() => {
    const sportsArray = Object.entries(selectedSports).reduce(
      (acc, [sport, isSelected]) => {
        if (isSelected) {
          const experienceLevel = skillLevelSelection[sport] || [];
          acc.push({ sport, experienceLevel });
        }
        return acc;
      },
      []
    );

    setFilterValues((prev) => ({
      ...prev,
      sports: sportsArray,
    }));
  }, [selectedSports, skillLevelSelection]);

  // Handler for when a sport is toggled
  const handleSportToggle = (sport) => {
    setSelectedSports((prev) => {
      const isSportSelected = !prev[sport];
      const updatedSports = { ...prev, [sport]: isSportSelected };

      // Set skill levels based on whether the sport is selected or not
      setSkillLevelSelection((prevLevels) => ({
        ...prevLevels,
        [sport]: isSportSelected ? [...skillLevels] : [],
      }));

      return updatedSports;
    });
  };

  // Handler for when a skill level is toggled
  const handleSkillLevelChange = (sport, level) => {
    setSkillLevelSelection((prev) => {
      const prevSelections = prev[sport] || [];
      const newSelections = prevSelections.includes(level)
        ? prevSelections.filter((lvl) => lvl !== level)
        : [...prevSelections, level];
      return { ...prev, [sport]: newSelections };
    });
  };

  useEffect(() => {
    updateFilterValuesSports();
  }, [updateFilterValuesSports]); // Dependencies array includes all variables that trigger the update

  useEffect(() => {
    setSelectedSports((prev) => {
      const updatedSports = {};
      user.sports.forEach(({ sport }) => {
        updatedSports[sport] = prev[sport] || false;
      });
      return updatedSports;
    });

    setSkillLevelSelection((prev) => {
      const updatedSkillLevels = {};
      user.sports.forEach(({ sport }) => {
        if (prev[sport]) {
          updatedSkillLevels[sport] = prev[sport];
        }
      });
      return updatedSkillLevels;
    });
  }, [user.sports]);

  // if there are more than 8 sports, display the "View more" button
  const handleViewMore = () => {
    // Increase the count or set it to show all sports
    setDisplayedSportsCount(userSportNames.length);
  };

  // Determine if any sport is selected
  const isAnySportSelected = Object.values(selectedSports).some(
    (value) => value
  );

  const viewMoreStyle = {
    position: "relative",
    display: "inline-block",
    cursor: "pointer",
    textDecoration: "none",
  };

  const underlineStyle = {
    position: "absolute",
    bottom: "-0.5rem", // Adjust this to position the underline correctly
    left: 0,
    right: 0,
    height: "4px",
    background:
      "linear-gradient(180deg, rgba(156, 236, 237, 0.5) 0%, rgba(0, 142, 148, 0.5) 50.52%, rgba(200, 94, 217, 0.5) 100%)",
  };

  /* AGE RANGE */

  const initialAgeRangeIndices = useMemo(() => {
    return findAgeRangeIndices(searchCriteria.ageRanges);
  }, [searchCriteria.ageRanges]);

  const [ageRangeSliderValue, setAgeRangeSliderValue] = useState(() => {
    if (initialAgeRangeIndices.length === 2) {
      return initialAgeRangeIndices;
    }
    return [0, ageRanges.length - 1]; // Default to full range if no specific ranges are set
  });

  const handleSliderChange = (event, newValue) => {
    setAgeRangeSliderValue(newValue);
    const newAgeRanges = ageRanges.slice(newValue[0], newValue[1] + 1);
    setFilterValues((prev) => ({ ...prev, ageRanges: newAgeRanges }));
  };

  // Marks for the slider
  const selectedMarks = ageRangeSliderValue.map((value) => ({
    value,
    label: ageRanges[value],
  }));

  /* BUTTON */

  const updateUserCount = useCallback(
    async (filters) => {
      const data = await dispatch(fetchFilteredUsers(filters, 1, 10));
      setTotalPages(data.totalPages);
      setTotalUsers(data.totalUsers);
      setUsers(data.users);
    },
    [dispatch]
  );

  useEffect(() => {
    updateUserCount(filterValues);
  }, [filterValues, updateUserCount]);

  useEffect(() => {
    // Check if the user has sports in their profile when the component mounts
    if (user.sports.length === 0) {
      setDialogOpen(true);
    }
  }, [user.sports]);

  const handleEditProfile = () => {
    navigate("/settings/profile");
  };

  const handleGoBack = () => {
    navigate("/findMembers");
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    handleGoBack();
  };

  // Handler for when the submit button is clicked
  const handleSubmit = async () => {
    try {
      // Filter only selected sports and map them to the required format
      const sportsArray = userSportNames
        .filter((sport) => selectedSports[sport])
        .map((sport) => ({
          sport,
          experienceLevel: skillLevelSelection[sport] || [],
        }))
        .filter((sportItem) => sportItem.experienceLevel.length > 0);

      // Prepare the updated criteria
      const criteriaForValidation = {
        location: filterValues.location,
        locationRadius: filterValues.locationRadius,
        sports: sportsArray,
        ageRanges: ageRanges.slice(
          ageRangeSliderValue[0],
          ageRangeSliderValue[1] + 1
        ),
      };

      // Validate against the Yup schema
      await filterMembersSchema.validate(criteriaForValidation);

      // Dispatch the update action
      dispatch(updateUserSearchCriteria(userId, criteriaForValidation));

      // Fetch filtered users
      const data = await dispatch(
        fetchFilteredUsers(criteriaForValidation, 1, 10)
      ); // Start from page 1 with limit 10
      setTotalUsers(data.totalUsers);
      setTotalPages(data.totalPages);
      setUsers(data.users);

      // Success message
      setSnackbarMessage("Search criteria updated successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

      handleShowResults();
    } catch (err) {
      // Handle errors
      console.error("Validation failed:", err);
      setSnackbarMessage(err.message);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleNextPage = async () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      const data = await dispatch(
        fetchFilteredUsers(filterValues, currentPage + 1, 10)
      );
      setUsers(data.users);
    }
  };

  const handlePreviousPage = async () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      const data = await dispatch(
        fetchFilteredUsers(filterValues, currentPage - 1, 10)
      );
      setUsers(data.users);
    }
  };

  const handleShowResults = () => {
    setShowResults(true);
  };

  const handleHideResults = () => {
    setShowResults(false);
  };

  //   console.log(filterValues);

  //   console.log(userSportNames);

  return {
    isLargeScreen,
    primary,
    primaryDark,
    neutralLight,
    snackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    dialogOpen,
    filterValues,
    handleLocationSelect,
    handleLocationRadiusChange,
    handleSportToggle,
    handleSkillLevelChange,
    handleViewMore,
    isAnySportSelected,
    viewMoreStyle,
    underlineStyle,
    selectedMarks,
    handleSliderChange,
    handleSubmit,
    handleEditProfile,
    handleGoBack,
    handleCloseDialog,
    sliderValueToAgeRange,
    displayedSportsCount,
    selectedSports,
    ageRangeSliderValue,
    skillLevelSelection,
    userSportNames,
    skillLevels,
    userId,
    user,
    searchCriteria,
    setSnackbarOpen,
    totalPages,
    currentPage,
    handleNextPage,
    handlePreviousPage,
    totalUsers,
    users,
    background,
    handleHideResults,
    showResults,
  };
};

export default useFilterMembers;
