import React, { useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import BrandCreateDetails from "scenes/admin/directory/BrandCreateForm/components/BrandCreateDetails";
import BrandCreateSport from "scenes/admin/directory/BrandCreateForm/components/BrandCreateSport";
import FormLocation from "./FormLocation";
import { useDispatch, useSelector } from "react-redux";
import { fetchSports } from "redux/actions/sportActions";
import BrandLogoEditRegister from "scenes/loginPage/FormComponents/BrandLogoEditRegister";
import BrandImageEditRegister from "./BrandImageEditRegister";

const BusinessForm = ({
  formData = {}, // Provide a default empty object
  handleBlur,
  handleChange,
  errors = {}, // Provide a default empty object
  touched = {}, // Provide a default empty object
  setFieldValue,
  setSnackbarMessage,
  setSnackbarSeverity,
  setSnackbarOpen,
}) => {
  const dispatch = useDispatch();
  const sportsList = useSelector((state) => state.sports);
  const { palette } = useTheme();

  const handleChangeDetails = (event) => {
    const { name, value } = event.target;
    setFieldValue(`businessForm.${name}`, value);
  };

  useEffect(() => {
    dispatch(fetchSports());
  }, [dispatch]);

  return (
    <Box>
      <BrandImageEditRegister
        formData={formData}
        handleBlur={handleBlur}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarSeverity={setSnackbarSeverity}
        setSnackbarOpen={setSnackbarOpen}
      />

      <BrandLogoEditRegister
        formData={formData}
        handleBlur={handleBlur}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarSeverity={setSnackbarSeverity}
        setSnackbarOpen={setSnackbarOpen}
      />

      <BrandCreateDetails
        handleChange={handleChangeDetails}
        handleBlur={handleBlur}
        formData={formData || {}}
        formErrors={touched?.businessForm ? errors.businessForm || {} : {}}
      />

      <BrandCreateSport
        selectedSport={formData.sportsPromoted || []}
        handleSportChange={(newSport) =>
          setFieldValue("businessForm.sportsPromoted", newSport)
        }
        formErrors={touched?.businessForm ? errors.businessForm || {} : {}}
        sportsList={sportsList}
        setSelectedSport={(newSport) =>
          setFieldValue("businessForm.sportsPromoted", newSport)
        }
        touched={touched?.businessForm?.sportsPromoted || {}} // Ensure touched is passed
      />

      <FormLocation
        palette={palette}
        touched={touched?.businessForm?.location || {}}
        errors={errors?.businessForm?.location || {}}
        values={formData.location || {}}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        fieldName="businessForm.location"
      />
    </Box>
  );
};

export default BusinessForm;
