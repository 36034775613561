import { Box, styled } from "@mui/material";
import UserWidget from "scenes/widgets/UserWidget";
import AdvertWidget from "scenes/widgets/AdvertWidget/AdvertWidget";
import FriendListWidget from "scenes/widgets/FriendListWidget";
import NonFriendsSliderFlick from "scenes/widgets/NonFriendsSliderFlick";
import Welcome from "scenes/widgets/Welcome";
import Navbar from "scenes/navbar";
import Footer from "components/Footer";
import BottomMenu from "components/bottomMenu/BottomMenu";
import TopStories from "scenes/widgets/TopStories";
import StoriesJustForYou from "scenes/widgets/StoriesJustForYou";
import { createSelector } from "reselect";
import YourGroups from "scenes/widgets/YourGroups";
import RecommendFriendPopup from "components/RecommendFriendPopUp";
import SnackbarComponent from "components/SnackbarComponent";
import useHomePageState from "./hooks/useHomePageState";
import YourFollowedBrands from "scenes/widgets/YourFollowedBrands";

const ContentWrapper = styled(Box)({
  display: "flex",
  gap: "0.5rem",
});

const selectNonFriends = createSelector(
  (state) => state.user.nonFriends,
  (state) => state.user._id,
  (nonFriends, userId) => nonFriends?.filter((friend) => friend._id !== userId)
);

const HomePage = ({ socket }) => {
  const {
    isLargeScreen,
    _id,
    picturePath,
    nonFriends,
    recommendPopupOpen,
    firstName,
    snackbarOpen,
    setSnackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    contentBoxStyle,
    handleRecommend,
    handlePopupClose,
    email,
    emailError,
    setEmail,
    validateAndRecommend,
    name,
    nameError,
    setName,
  } = useHomePageState({ selectNonFriends });

  return (
    <>
      <Box
        height="100vh"
        display="flex"
        flexDirection="column"
        marginBottom="1rem"
      >
        <Navbar socket={socket} />

        <Box style={contentBoxStyle}>
          <ContentWrapper
            width="100%"
            padding={isLargeScreen ? "0 6%" : undefined}
            justifyContent="space-between"
          >
            {isLargeScreen && (
              <Box flexBasis="26%">
                <UserWidget userId={_id} picturePath={picturePath} />
              </Box>
            )}
            <Box flexBasis={isLargeScreen ? "42%" : undefined}>
              <Welcome />
              <NonFriendsSliderFlick nonFriends={nonFriends} type="home" />
              <TopStories />
              <StoriesJustForYou />
              <YourGroups />
            </Box>
            {isLargeScreen && (
              <Box flexBasis="26%">
                <AdvertWidget />
                <FriendListWidget userId={_id} socket={socket} />
                <YourFollowedBrands />
              </Box>
            )}
          </ContentWrapper>
          <Footer />
        </Box>
        {!isLargeScreen && <BottomMenu />}
      </Box>
      <RecommendFriendPopup
        open={recommendPopupOpen}
        onClose={handlePopupClose}
        onRecommend={handleRecommend}
        firstName={firstName}
        email={email}
        emailError={emailError}
        setEmail={setEmail}
        validateAndRecommend={validateAndRecommend}
        name={name}
        nameError={nameError}
        setName={setName}
      />
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default HomePage;
