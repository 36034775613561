import { Box, useMediaQuery } from "@mui/material";
import BottomMenu from "components/bottomMenu/BottomMenu";
import React, { useEffect } from "react";
import Navbar from "scenes/navbar";
import Footer from "components/Footer";
import UserWidget from "scenes/widgets/UserWidget";
import FriendListWidget from "scenes/widgets/FriendListWidget";
import { useDispatch, useSelector } from "react-redux";
import FindMembersTitleAndBtn from "./components/FindMembersTitleAndBtn";

import NonFriendsSliderFlick from "scenes/widgets/NonFriendsSliderFlick";
import { createSelector } from "reselect";
import { fetchNonFriends, fetchRecentNonFriends } from "redux/state";
import FindMembersMemberSuggestions from "./components/FindMembersMemberSuggestions";
import FindMembersConnected from "./components/FindMembersConnected";
import RecommendFriend from "components/RecommendFriend";

const selectNonFriends = createSelector(
  (state) => state.user.nonFriends,
  (state) => state.user._id,
  (nonFriends, userId) => nonFriends?.filter((friend) => friend._id !== userId)
);

function FindMembers({ socket }) {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user); // get the user from the state
  const userId = user._id;

  useEffect(() => {
    dispatch(fetchNonFriends(userId)); // dispatch the fetchNonFriends action
    dispatch(fetchRecentNonFriends(userId)); // dispatch the fetchNonFriends action
  }, [dispatch, userId]);

  const nonFriends = useSelector(selectNonFriends) || [];
  const recentNonFriends =
    useSelector((state) => state.user.recentNonFriends) || [];

  const isLargeScreen = useMediaQuery("(min-width:1000px)");
  return (
    <>
      <Box height="100vh" display="flex" flexDirection="column">
        <Navbar socket={socket} />
        <Box
          flexGrow={1}
          overflow="auto"
          width="100%"
          // padding="2rem 6%"
          padding={isLargeScreen ? "0 6%" : "0"}
          display={isLargeScreen ? "flex" : "block"}
          gap="2rem"
          justifyContent="center"
        >
          {isLargeScreen && (
            <Box flexBasis={isLargeScreen ? "35%" : undefined}>
              <UserWidget userId={userId} picturePath={user.picturePath} />
              <RecommendFriend />
              <FriendListWidget userId={userId} socket={socket} />
            </Box>
          )}
          <Box
            flexBasis={isLargeScreen ? "60%" : undefined}
            // mt={isLargeScreen ? undefined : "2rem"}
          >
            {/* <GetUsers /> */}
            <FindMembersTitleAndBtn />
            {/* Closets non friends */}
            <NonFriendsSliderFlick
              nonFriends={nonFriends}
              type="nearest"
              socket={socket}
            />
            {/* New non friends */}
            <NonFriendsSliderFlick
              nonFriends={recentNonFriends}
              type="recent"
              socket={socket}
            />
            <FindMembersMemberSuggestions socket={socket} />
            {!isLargeScreen && <RecommendFriend />}
            <FindMembersConnected socket={socket} />
          </Box>
        </Box>
      </Box>
      <Footer />
      {!isLargeScreen && <BottomMenu />}
    </>
  );
}

export default FindMembers;
