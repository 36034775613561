import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import UserGroupJoined from "components/UserGroupJoined";
import WidgetWrapper from "components/WidgetWrapper";
import React from "react";
import { useSelector } from "react-redux";

function UserGroupsFollowedAll() {
  const isLargeScreen = useMediaQuery("(min-width: 1000px)");
  const user = useSelector((state) => state.viewingUser || state.user);
  const groupsFollowed = user?.groupsJoinedDetails || [];
  const { palette } = useTheme();
  const neutralDark = palette.neutral.dark;

  if (groupsFollowed.length === 0) {
    return null;
  }
  return (
    <WidgetWrapper borderRadius={!isLargeScreen ? "2rem" : "0.75rem"}>
      <Typography
        color={neutralDark}
        variant="h5"
        fontWeight="500"
        sx={{ mb: "1.5rem" }}
      >
        Groups Joined
      </Typography>

      <Box display="flex" flexDirection="column" gap="0.5rem">
        {groupsFollowed.map((group) => (
          <UserGroupJoined key={group._id} group={group} />
        ))}
      </Box>
    </WidgetWrapper>
  );
}

export default UserGroupsFollowedAll;
