import { Box, Button, Typography } from "@mui/material";
import React from "react";
import UserCard from "./UserCard";

function FilteredUserCards({
  users,
  currentPage,
  totalPages,
  handleNextPage,
  handlePreviousPage,
  background,
  primary,
  neutralLight,
  primaryDark,
}) {
  return (
    <>
      <Box
        sx={{
          marginTop: 2,
          // display: "flex",
          justifyContent: "space-between",
        }}
        display={totalPages > 1 && users.length > 0 ? "flex" : "none"}
      >
        <Button onClick={handlePreviousPage} disabled={currentPage <= 1}>
          Previous
        </Button>
        <Typography>
          Page {currentPage} of {totalPages}
        </Typography>
        <Button onClick={handleNextPage} disabled={currentPage >= totalPages}>
          Next
        </Button>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)", // small devices, 1 card per row
          sm: "repeat(2, 1fr)", // medium devices, 2 cards per row
          md: "repeat(2, 1fr)", // medium-large devices, 3 cards per row
          lg: "repeat(3, 1fr)", // large devices, 4 cards per row
          xl: "repeat(4, 1fr)", // extra large devices, 5 cards per row
        }}
        justifyContent="space-between"
        rowGap="20px"
        columnGap="1.33%"
        mt="1.5rem"
        sx={{
          "& > div": {
            gridColumn: {
              xs: "span 1",
              sm: "span 1",
              md: "span 1",
              lg: undefined,
            },
          },
        }}
      >
        {users.map((user) => (
          <UserCard
            key={user._id}
            user={user}
            background={background}
            primary={primary}
            neutralLight={neutralLight}
            primaryDark={primaryDark}
          />
        ))}
      </Box>

      <Box
        sx={{
          marginTop: 2,
          // display: "flex",
          justifyContent: "space-between",
        }}
        display={totalPages > 1 && users.length > 0 ? "flex" : "none"}
      >
        <Button onClick={handlePreviousPage} disabled={currentPage <= 1}>
          Previous
        </Button>
        <Typography>
          Page {currentPage} of {totalPages}
        </Typography>
        <Button onClick={handleNextPage} disabled={currentPage >= totalPages}>
          Next
        </Button>
      </Box>
    </>
  );
}

export default FilteredUserCards;
