import { Box, Button, useMediaQuery } from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import React from "react";
import Header from "scenes/admin/components/Header";
import Navbar from "scenes/navbar";
import GetBrands from "./components/getBrands";
import Footer from "components/Footer";
import BottomMenu from "components/bottomMenu/BottomMenu";
import { PlaylistAdd } from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   fetchBrandsFriendsFollowing,
//   fetchBrandsMatchingSports,
//   fetchClosestBrandsNotFollowed,
// } from "redux/actions/userActions";
// import BrandCarousel from "./components/BrandCarousel";

function BrandDirectory({ socket }) {
  // const dispatch = useDispatch();
  const isLargeScreen = useMediaQuery("(min-width: 1000px)");

  // const closestBrandsNotFollowed = useSelector(
  //   (state) => state.brandDirectory.closestBrandsNotFollowed
  // );
  // const brandsFriendsFollowing = useSelector(
  //   (state) => state.brandDirectory.brandsFriendsFollowing
  // );
  // const brandsMatchingSports = useSelector(
  //   (state) => state.brandDirectory.brandsMatchingSports
  // );

  // useEffect(() => {
  //   dispatch(fetchClosestBrandsNotFollowed());
  //   dispatch(fetchBrandsFriendsFollowing());
  //   dispatch(fetchBrandsMatchingSports());
  // }, [dispatch]);

  return (
    <Box height="100vh">
      <Navbar socket={socket} />
      <WidgetWrapper>
        <FlexBetween>
          <Box m={isLargeScreen ? "1.5rem 2rem 2rem 3rem" : undefined}>
            <Header title="THE BRAND" subtitle="DIRECTORY" />
          </Box>
          {/* <Button
            variant="contained"
            color="primary"
            // onClick={handleOpen}
            sx={{
              borderRadius: "10px",
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <PlaylistAdd sx={{ mr: "0.5rem" }} />
            Add Brand
          </Button> */}
        </FlexBetween>
        {/* <Box mx={isLargeScreen ? undefined : "-3rem"}>
          {closestBrandsNotFollowed && closestBrandsNotFollowed.length > 0 && (
            <BrandCarousel
              brands={closestBrandsNotFollowed}
              title="Businesses close to you"
              isLargeScreen={isLargeScreen}
            />
          )}
          {brandsFriendsFollowing && brandsFriendsFollowing.length > 0 && (
            <BrandCarousel
              brands={brandsFriendsFollowing}
              title="Followed by your friends"
              isLargeScreen={isLargeScreen}
            />
          )}

          {brandsMatchingSports && brandsMatchingSports.length > 0 && (
            <BrandCarousel
              brands={brandsMatchingSports}
              title="Business that cover your sports"
              isLargeScreen={isLargeScreen}
            />
          )}
        </Box> */}

        <GetBrands />
      </WidgetWrapper>
      <Footer />
      {!isLargeScreen && <BottomMenu />}
    </Box>
  );
}

export default BrandDirectory;
