import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  ClickAwayListener,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import {
  ChevronLeft,
  ChevronRightOutlined,
  DarkModeOutlined,
} from "@mui/icons-material";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import EditNotificationsOutlinedIcon from "@mui/icons-material/EditNotificationsOutlined";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";

const navItems = [
  { text: "Manage Profile", icon: null },
  { text: "Profile", icon: <ManageAccountsIcon /> },
  { text: "Profile Questions", icon: <DynamicFormIcon /> },
  { text: "Settings", icon: null },
  { text: "Notifications", icon: <EditNotificationsOutlinedIcon /> },
  { text: "Dark mode", icon: <DarkModeOutlined /> },
  { text: "Invite Friends", icon: <Diversity1Icon /> },
  { text: "Privacy", icon: null },
  { text: "Blocked Users", icon: <PersonOffOutlinedIcon /> },
];

function SettignsAndPrivacySidebar({
  isLargeScreen,
  drawerWidth,
  isSideBarOpen,
  setIsSideBarOpen,
}) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [active, setActive] = useState("");

  const { palette } = useTheme();
  const primary = palette.primary.main;
  const light = palette.primary.light;
  const backgroundColor = palette.background.alt;
  const dark = palette.neutral.dark;

  useEffect(() => {
    const lastPath = pathname.split("/").pop();
    setActive(lastPath);
  }, [pathname]);

  const Divider = () => (
    <Box height="1px" backgroundColor={light} opacity="0.1" margin="1rem" />
  );

  const drawerContent = (
    <Box component="nav">
      {isSideBarOpen && (
        <Drawer
          open={isSideBarOpen}
          onClose={() => setIsSideBarOpen(false)}
          variant="permanent"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: primary,
              backgroundColor: backgroundColor,
              boxSizing: "border-box",
              borderWidth: isLargeScreen ? 0 : "2px",
              width: drawerWidth,
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 2rem 2rem 3rem">
              <FlexBetween color={primary}>
                <Link to="/settings">
                  <Box display="flex" alignItems="center" gap="0.5rem">
                    <Typography variant="h5" fontWeight="bold">
                      Your Settings
                    </Typography>
                  </Box>
                </Link>
                {!isLargeScreen && (
                  <IconButton onClick={() => setIsSideBarOpen(!isSideBarOpen)}>
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            <List>
              {navItems.map(({ text, icon }) => {
                if (!icon) {
                  return (
                    <Box key={text}>
                      <Divider />
                      <Typography sx={{ m: "2.25rem 0 1rem 3rem" }}>
                        {text}
                      </Typography>
                    </Box>
                  );
                }

                const lcText = text.toLowerCase().replace(" ", "-");

                return (
                  <ListItem key={text} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        navigate(`${lcText}`);
                        setActive(lcText);
                      }}
                      sx={{
                        backgroundColor:
                          active === lcText ? primary : "transparent",
                        color: active === lcText ? backgroundColor : dark,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ml: "2rem",
                          color: active === lcText ? backgroundColor : dark,
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                      {active === lcText && (
                        <ChevronRightOutlined sx={{ ml: "auto" }} />
                      )}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Drawer>
      )}
    </Box>
  );
  return !isLargeScreen && isSideBarOpen ? (
    <ClickAwayListener onClickAway={() => setIsSideBarOpen(false)}>
      {drawerContent}
    </ClickAwayListener>
  ) : (
    drawerContent
  );
}

export default SettignsAndPrivacySidebar;
