import {
  Avatar,
  Box,
  Button,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import SnackbarComponent from "components/SnackbarComponent";
import WidgetWrapper from "components/WidgetWrapper";
import AvatarRoleIcons from "components/avatarRoleIcons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchFriends, patchFriend } from "redux/state";

function FindMembersConnected({ socket }) {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const dark = palette.neutral.dark;

  const userId = useSelector((state) => state.user._id);

  const friends = useSelector((state) => state.user.friends) || [];

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  // State to track hover for each friend
  const [hoveredFriendId, setHoveredFriendId] = useState(null);

  // Event handlers for mouse enter and leave
  const handleMouseEnter = (id) => {
    setHoveredFriendId(id);
  };

  const handleMouseLeave = () => {
    setHoveredFriendId(null);
  };

  useEffect(() => {
    dispatch(fetchFriends(userId));
  }, [dispatch, userId]);

  const handleAddFriend = async (nonFriendId) => {
    try {
      if (userId && nonFriendId) {
        await dispatch(patchFriend(userId, nonFriendId)); // dispatch the patchFriend action
      }

      setSnackbarMessage("Friend removed");
      setSnackbarSeverity("info");
    } catch (error) {
      console.error(error);
      setSnackbarMessage("An error occurred");
      setSnackbarSeverity("error");
    } finally {
      // Open the snackbar
      setSnackbarOpen(true);
    }
  };

  if (friends.length === 0) {
    return null;
  }

  return (
    <WidgetWrapper>
      <Typography color={dark} variant={"h4"} fontWeight={700} mb={2}>
        Your connections
      </Typography>
      <Box>
        {friends &&
          friends.map((friend, index) => (
            <Box key={friend._id} margin="1rem 0">
              <FlexBetween>
                <Link to={`/profile/${friend._id}`}>
                  <FlexBetween gap="1rem">
                    <Avatar
                      src={friend.picturePath}
                      alt={`${friend.firstName} ${friend.lastName}`}
                      sx={{ width: "40px", height: "40px" }}
                    />
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      gap="0.5rem"
                    >
                      <Typography
                        color={dark}
                        variant="h6"
                        fontSize="16px"
                        fontWeight={500}
                      >
                        {friend.firstName} {friend.lastName}
                      </Typography>
                      <AvatarRoleIcons user={friend} />
                    </Box>
                  </FlexBetween>
                </Link>

                <Button
                  onMouseEnter={() => handleMouseEnter(friend._id)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => handleAddFriend(friend._id)}
                  variant="outlined"
                  sx={{
                    borderRadius: "1rem",
                    padding: "0.25rem 1rem",
                  }}
                >
                  {hoveredFriendId === friend._id ? "Remove?" : "Connected"}
                </Button>
              </FlexBetween>
              {index !== friends.length - 1 && <Divider sx={{ my: 2 }} />}
            </Box>
          ))}
      </Box>
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        severity={snackbarSeverity}
      />
    </WidgetWrapper>
  );
}

export default FindMembersConnected;
