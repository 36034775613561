import { Box, useTheme } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsersCountByAgeRange } from "redux/actions/dashboardUserActions";

function UserAgeRangeBreakdownChart({ isDashboard = false }) {
  const { palette } = useTheme();
  const primary = palette.primary.main;
  const secondary = palette.secondary.main;
  const primaryDark = palette.primary.dark;
  const secondaryDark = palette.secondary.dark;
  const primaryLight = palette.primary.light;

  const dispatch = useDispatch();

  const ageRangeCount = useSelector(
    (state) => state.dashboardAdminUser.ageRangeCount
  );

  useEffect(() => {
    dispatch(fetchUsersCountByAgeRange());
  }, [dispatch]);

  // Use useMemo to transform the data only when ageRangeCount changes
  const chartData = useMemo(() => {
    return ageRangeCount.map((range) => {
      let color;
      switch (range._id) {
        case "18-24":
          color = primary;
          break;
        case "25-34":
          color = secondary;
          break;
        case "35-44":
          color = primaryDark;
          break;
        case "45-54":
          color = secondaryDark;
          break;
        case "55-64":
          color = primaryLight;
          break;
        case "65-74":
          color = primary;
          break;
        case "75+":
          color = secondary;
          break;
        default:
          color = "#999999"; // A default color for any other age ranges
      }

      return {
        id: range._id,
        label: range._id,
        value: range.count,
        color: color,
      };
    });
  }, [
    ageRangeCount,
    primary,
    secondary,
    primaryDark,
    secondaryDark,
    primaryLight,
  ]);

  return (
    <Box
      height={isDashboard ? "375px" : "100%"}
      width={undefined}
      minHeight={isDashboard ? "325px" : undefined}
      minWidth={isDashboard ? "325px" : undefined}
      position="relative"
    >
      <ResponsivePie
        data={chartData}
        margin={
          isDashboard
            ? { top: 20, right: 30, bottom: 0, left: 30 }
            : { top: 40, right: 80, bottom: 80, left: 80 }
        }
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        colors={(datum) => datum.data.color}
        borderWidth={1}
        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
        radialLabelsSkipAngle={10}
        radialLabelsTextXOffset={6}
        radialLabelsTextColor="#333333"
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={16}
        radialLabelsLinkHorizontalLength={24}
        radialLabelsLinkStrokeWidth={1}
        radialLabelsLinkColor={{ from: "color" }}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#333333"
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
    </Box>
  );
}

export default UserAgeRangeBreakdownChart;
