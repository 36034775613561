const BASE_URL = process.env.REACT_APP_BASE_URL;

export const sendEmail = (values) => async (dispatch) => {
  try {
    const response = await fetch(`${BASE_URL}/mailers/send-email`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    });
    const responseData = await response.json();
    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData.error);
    }
  } catch (err) {
    console.error("Error while sending support email:", err);
    return null;
  }
};
