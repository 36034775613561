import React from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import FlexBetween from "components/FlexBetween";
import logoLight from "assets/light/SIA_Logo.png";
import logoDark from "assets/dark/SIA_Logo.png";

function NavbarPublic() {
  const navigate = useNavigate();
  const theme = useTheme();
  const alt = theme.palette.background.alt;

  return (
    <FlexBetween padding={"1rem 6%"} backgroundColor={alt} marginBottom="1rem">
      <img
        src={theme.palette.mode === "light" ? logoLight : logoDark}
        alt="SHE IS ACTION"
        onClick={() => navigate("/home")}
        style={{
          cursor: "pointer",
          height: "40px",
          width: "auto",
        }}
      />
    </FlexBetween>
  );
}

export default NavbarPublic;
