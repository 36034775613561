import { useTheme } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleImageUpload } from "redux/actions/imageUploadUtils";
import { createPost, taggedUserPost } from "redux/actions/postActions";
import { patchFriend, setNonFriends } from "redux/state";

const usePostWidget = ({ groupId, socket, isProfile }) => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.user);

  const { palette } = useTheme();
  const mediumMain = palette.neutral.mediumMain;
  const neutralMedium = palette.neutral.medium;
  const neutralLight = palette.neutral.light;
  const primary = palette.primary.main;
  const primaryLight = palette.primary.light;
  const primaryDark = palette.primary.dark;

  const [hover, setHover] = useState(false);
  const [hoverTarget, setHoverTarget] = useState();
  const [hoverForNewFriend, setHoverForNewFriend] = useState();
  const [hoverForNewFriendIndex, setHoverForNewFriendIndex] = useState();

  const [isImage, setIsImage] = useState(false);
  const [image, setImage] = useState(null);

  const [post, setPost] = useState("");

  const [showHandleSuggestion, setHandleTaggedSuggestion] = useState(
    false && post.includes("@")
  );

  const [usersTagged, setUsersTagged] = useState([]);
  const [mentionedName, setMentionedName] = useState("");
  const [usersUntagged, setUsersUntagged] = useState([]);

  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const quillRef = useRef(null);

  const hoverAddFriend = (index) => {
    setHoverForNewFriend(true);
    setHoverForNewFriendIndex(index);
  };

  const hoverTargetUser = (index) => {
    setHoverTarget(index);
    setHover(true);
  };

  const displayUser = usersUntagged.filter((option) => {
    return (
      option.firstName &&
      option.lastName &&
      (option.firstName.toLowerCase().includes(mentionedName) ||
        option.lastName.toLowerCase().includes(mentionedName))
    );
  });

  const handlePost = async () => {
    const trimmedPost = post.trim();

    if (
      !trimmedPost ||
      /^<p>(<br>|\s)*<\/p>(<p>(<br>|\s)*<\/p>)*$/.test(trimmedPost)
    ) {
      setSnackbarMessage("Post cannot be empty");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);

    const imageFile = await handleImageUpload(image);

    const postCreated = await dispatch(
      createPost(loggedInUser._id, post, imageFile, groupId, isProfile)
    );

    if (postCreated[0]) {
      setImage(null);
      setPost("");
      setSnackbarMessage("You have successfully created a post");
      setSnackbarSeverity("success");
      setSnackbarOpen(true); // Show the snackbar
      resetPostContent();
    }

    if (usersTagged) {
      await dispatch(
        taggedUserPost(loggedInUser._id, usersTagged, postCreated[1])
      );

      usersTagged.forEach((user) => {
        const notificationData = {
          senderId: loggedInUser._id,
          receiverId: user._id,
          name: loggedInUser.firstName + " " + loggedInUser.lastName,
          postId: postCreated[1],
          picturePath: loggedInUser.picturePath,
          timeStamp: Date.now(),
          type: "mentionedOnPost",
        };

        socket.emit("sendNotification", notificationData);
      });
    }

    setHandleTaggedSuggestion(false);
    setUsersTagged([]);
    setUsersUntagged([]);
    setLoading(false);
  };

  const resetPostContent = () => {
    setPost("");
    if (quillRef.current && quillRef.current.editor) {
      quillRef.current.editor.setContents([]);
    }
  };

  const handleSelectMentionUser = (user) => {
    if (!quillRef.current) return;
    const quill = quillRef.current.editor;
    const range = quill.getSelection(true);

    if (range) {
      const mentionText = `${user.firstName} ${user.lastName}`;
      const mentionTriggerIndex = quill
        .getText(0, range.index)
        .lastIndexOf("@");

      if (mentionTriggerIndex !== -1 && mentionTriggerIndex < range.index) {
        quill.deleteText(
          mentionTriggerIndex,
          range.index - mentionTriggerIndex
        );

        quill.insertText(mentionTriggerIndex, mentionText);
        quill.formatText(mentionTriggerIndex, mentionText.length, {
          color: primary,
        });

        quill.insertText(mentionTriggerIndex + mentionText.length, " ");
        quill.formatText(mentionTriggerIndex + mentionText.length, 1, {
          color: false,
        });

        quill.setSelection(mentionTriggerIndex + mentionText.length + 1);
      }
    }

    setUsersTagged((prevState) => [...prevState, user]);
    setUsersUntagged((prevState) =>
      prevState.filter((untaggedUser) => untaggedUser._id !== user._id)
    );
    setMentionedName("");
    setHandleTaggedSuggestion(false);
  };

  const handleQuillChange = useCallback(
    (value) => {
      setPost(value);

      if (!quillRef.current) return;
      const quill = quillRef.current.editor;
      const range = quill.getSelection();
      if (range && range.index > 0) {
        const textBeforeCursor = quill.getText(0, range.index);
        const mentionTriggerIndex = textBeforeCursor.lastIndexOf("@");

        if (mentionTriggerIndex > -1) {
          const mentionText = textBeforeCursor.slice(mentionTriggerIndex + 1);
          setMentionedName(mentionText.toLowerCase());
          setUsersUntagged(loggedInUser.friends);
          setHandleTaggedSuggestion(true);
        } else {
          setHandleTaggedSuggestion(false);
        }
      }
    },
    [
      setPost,
      setMentionedName,
      setHandleTaggedSuggestion,
      loggedInUser.friends,
      quillRef,
    ]
  );

  const addFriend = async (nonFriendId) => {
    if (loggedInUser._id && nonFriendId) {
      await dispatch(patchFriend(loggedInUser._id, nonFriendId));

      const updatedNonFriends = loggedInUser.nonFriends.filter(
        (nonFriend) => nonFriend._id !== nonFriendId
      );
      dispatch(setNonFriends(updatedNonFriends));
    }
  };

  return {
    hover,
    setHover,
    hoverTarget,
    hoverForNewFriend,
    setHoverForNewFriend,
    hoverForNewFriendIndex,
    hoverAddFriend,
    isImage,
    setIsImage,
    image,
    setImage,
    post,
    showHandleSuggestion,
    loading,
    snackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    setSnackbarOpen,
    displayUser,
    handlePost,
    handleSelectMentionUser,
    addFriend,
    neutralLight,
    hoverTargetUser,
    loggedInUser,
    primary,
    primaryLight,
    primaryDark,
    mediumMain,
    neutralMedium,
    handleQuillChange,
    quillRef,
  };
};

export default usePostWidget;
