import React, { useEffect, useState } from "react";
import { GoogleMap, InfoWindow, useJsApiLoader } from "@react-google-maps/api";
import { Box, Typography, Link, useMediaQuery } from "@mui/material";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const GOOGLE_MAP_ID = process.env.REACT_APP_GOOGLE_MAP_ID; // Add your Map ID here

const containerStyle = {
  width: "100%",
  height: "400px",
};

const libraries = ["marker"];

const MapComponentMulti = ({ locations, zoom = 12 }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);

  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  useEffect(() => {
    if (isLoaded && map && locations.length) {
      // Clear previous markers
      markers.forEach((marker) => marker.setMap(null));

      const newMarkers = [];
      const bounds = new window.google.maps.LatLngBounds();

      locations.forEach(
        ({ location, brandName, website, simpleUrl, address, logoPath }) => {
          const marker = new window.google.maps.marker.AdvancedMarkerElement({
            position: location,
            map: map,
            title: brandName,
          });

          marker.addListener("click", () => {
            setSelectedMarker({
              location,
              brandName,
              website,
              simpleUrl,
              address,
              logoPath,
            });
          });

          newMarkers.push(marker);
          bounds.extend(marker.position);
        }
      );

      setMarkers(newMarkers);
      map.fitBounds(bounds);
    }
  }, [isLoaded, map, locations]);

  useEffect(() => {
    // Inject custom CSS to remove padding/margin from the InfoWindow
    const style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = `
      .gm-style-iw-d {
        overflow: hidden !important;
      }
      .gm-style-iw-chr {
        display: none;
      }
      .gm-style-iw-c {
        padding: 0 !important;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  useEffect(() => {
    if (map) {
      map.addListener("click", () => {
        setSelectedMarker(null);
      });
    }
  }, [map]);

  useEffect(() => {
    if (selectedMarker === null && map && locations.length) {
      const bounds = new window.google.maps.LatLngBounds();
      locations.forEach(({ location }) => {
        bounds.extend(location);
      });
      map.fitBounds(bounds);
    }
  }, [selectedMarker, map, locations]);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={locations.length ? locations[0].location : { lat: 0, lng: 0 }}
      zoom={zoom}
      options={{
        disableDefaultUI: !isLargeScreen, // disable default map UI if screen size is small
        mapId: GOOGLE_MAP_ID,
      }}
      onLoad={(map) => setMap(map)}
    >
      {selectedMarker && (
        <InfoWindow
          position={selectedMarker.location}
          onCloseClick={() => setSelectedMarker(null)}
        >
          <Box maxWidth="300px" p={2}>
            <Typography variant="h5" gutterBottom>
              {selectedMarker.brandName}
            </Typography>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <img
                src={selectedMarker.logoPath}
                alt="Brand Logo"
                style={{
                  width: "75px",
                  height: "75px",
                  marginRight: "10px",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
              />
              <Box>
                <Typography variant="body1">
                  <Link
                    href={selectedMarker.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    {selectedMarker.simpleUrl}
                  </Link>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {selectedMarker.address}
                </Typography>
              </Box>
            </Box>
          </Box>
        </InfoWindow>
      )}
    </GoogleMap>
  ) : (
    <div>Loading...</div>
  );
};

export default MapComponentMulti;
