import { Typography } from "@mui/material";
import React from "react";

function ArticlePageAuthor({ article, primary }) {
  return (
    <Typography variant="body2" color={primary}>
      By: {article.firstName} {article.lastName}
    </Typography>
  );
}

export default ArticlePageAuthor;
