import {
  Box,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  styled,
  Switch,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBrands } from "redux/actions/brandActions";
import FlexBetween from "components/FlexBetween";
import searchLight from "assets/light/search.png";
import searchDark from "assets/dark/search.png";
import BrandCard from "./brandCard";
import Popover from "@mui/material/Popover";
import { ArrowDropDownCircleOutlined } from "@mui/icons-material";
import MapComponentMulti from "components/MapComponentMulti";

function GetBrands() {
  const allBrands = useSelector((state) => state.brands) || [];
  const isLargeScreen = useMediaQuery("(min-width:1000px)");
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("name:asc"); // Sort options should match with the backend
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [mapView, setMapView] = useState(false); // State for map view toggle

  const { palette } = useTheme();
  const neutralLight = palette.neutral.light;
  const mode = palette.mode;

  // State and handler for the popover
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    const fetchAndUpdateBrands = async () => {
      const response = await dispatch(
        fetchBrands(page, recordsPerPage, sort, search)
      );
      setTotalRecords(response.total); // Set the total records.
      setTotalPages(Math.ceil(response.total / recordsPerPage)); // Calculate total pages.
    };
    fetchAndUpdateBrands();
  }, [dispatch, page, recordsPerPage, sort, search]);

  useEffect(() => {
    setPage(1);
  }, [recordsPerPage, sort, search]);

  const CustomFormControl = styled(FormControl)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
      borderRadius: "9px",
      backgroundColor: neutralLight,
      borderColor: "transparent",
      "&:hover fieldset": { borderColor: "transparent" },
      "&.Mui-focused fieldset": { borderColor: "transparent" },
    },
    "& .MuiInputLabel-root": {
      marginLeft: "0.5rem",
    },
  }));

  const handleChangePage = (event, newValue) => {
    setPage(newValue);
  };

  // Function to remove http://www. or https://www. from urls and truncate if longer than 20 characters
  const formatWebsiteAddress = (websiteUrl) => {
    let simpleUrl = websiteUrl.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");

    simpleUrl = simpleUrl.replace(
      /(.*?\.(?:com|co\.uk|org|net|io|info))(\/|\?|#).*/i,
      "$1"
    );

    return simpleUrl;
  };

  // Extract locations from the brand data and filter out invalid coordinates
  const locations = allBrands
    .map((brand) => ({
      location: {
        lat: brand.locations[0]?.coordinates[1],
        lng: brand.locations[0]?.coordinates[0],
      },
      brandName: brand.name,
      website: brand.website,
      simpleUrl: formatWebsiteAddress(brand.website),
      address: brand.locations[0]?.address,
      logoPath: brand.logoPath ? brand.logoPath : brand.picturePath,
    }))
    .filter((loc) => !isNaN(loc.location.lat) && !isNaN(loc.location.lng));

  return (
    <Box m={isLargeScreen ? "1.5rem 2rem 2rem 3rem" : "2rem 0 0 0"}>
      <Box mb="2rem">
        {/* Search Box */}
        <Box
          display="flex"
          flexDirection={isLargeScreen ? "row" : "column"}
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <Box display="flex" alignItems="center" gap={2}>
            <FlexBetween
              backgroundColor={neutralLight}
              borderRadius="9px"
              gap="3rem"
              padding="0.1rem 1.5rem"
              height="35px"
              width="200px"
            >
              <InputBase
                placeholder="Search..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <IconButton>
                <img
                  src={mode === "light" ? searchLight : searchDark}
                  alt="search"
                  style={{
                    cursor: "pointer",
                    height: "1rem",
                    width: "auto",
                  }}
                />
              </IconButton>
            </FlexBetween>

            {/* Sort Dropdown */}
            <Box>
              <CustomFormControl size="small" sx={{ width: 150 }}>
                <InputLabel id="sort-label">Sort</InputLabel>
                <Select
                  labelId="sort-label"
                  value={sort}
                  label="Sort"
                  onChange={(e) => setSort(e.target.value)}
                >
                  <MenuItem value="name:asc">Name Ascending</MenuItem>
                  <MenuItem value="name:desc">Name Descending</MenuItem>
                  <MenuItem value="createdAt:desc">Newest First</MenuItem>
                  <MenuItem value="createdAt:asc">Oldest First</MenuItem>
                  <MenuItem value="distance:asc">Nearest First</MenuItem>
                </Select>
              </CustomFormControl>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            {/* Map View Toggle */}
            <Box display="flex" alignItems="center">
              <Switch
                checked={mapView}
                onChange={() => setMapView((prev) => !prev)}
              />
              <span>Show Map View</span>
            </Box>
          </Box>

          {/* Records Per Page, Page Count and Pagination controls */}
          <Box display="flex" alignItems="center" gap={2}>
            {/* Brands Per Page and Page Count */}
            <Box display="flex" alignItems="center" gap={2}>
              <Box display="flex" alignItems="center" gap={2}>
                <span>Brands Per Page - {recordsPerPage}</span>
                <IconButton onClick={handlePopoverOpen}>
                  <ArrowDropDownCircleOutlined />
                </IconButton>
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <Box p={2}>
                    <MenuItem
                      onClick={() => {
                        setRecordsPerPage(20);
                        handlePopoverClose();
                      }}
                    >
                      20
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setRecordsPerPage(40);
                        handlePopoverClose();
                      }}
                    >
                      40
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setRecordsPerPage(60);
                        handlePopoverClose();
                      }}
                    >
                      60
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setRecordsPerPage(80);
                        handlePopoverClose();
                      }}
                    >
                      80
                    </MenuItem>
                  </Box>
                </Popover>
              </Box>
              <Box>
                <span>{`Page ${page} / ${totalPages}`}</span>
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            {/* Pagination controls */}
            <Box display="flex" alignItems="center" gap={2}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handleChangePage}
                showFirstButton
                showLastButton
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Conditionally render the Map Component based on mapView state */}
      {mapView && <MapComponentMulti key={mapView} locations={locations} />}

      {/* Brands Grid */}
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)", // small devices, 1 card per row
          sm: "repeat(2, 1fr)", // medium devices, 2 cards per row
          md: "repeat(3, 1fr)", // medium-large devices, 3 cards per row
          lg: "repeat(4, 1fr)", // large devices, 4 cards per row
          xl: "repeat(5, 1fr)", // extra large devices, 5 cards per row
        }}
        justifyContent="space-between"
        rowGap="20px"
        columnGap="1.33%"
        mt="1.5rem"
        sx={{
          "& > div": {
            gridColumn: {
              xs: "span 1",
              sm: "span 1",
              md: "span 1",
              lg: undefined,
            },
          },
        }}
      >
        {allBrands.map((brand) => (
          <BrandCard key={brand._id} brand={brand} />
        ))}
      </Box>
    </Box>
  );
}

export default GetBrands;
