import axios from "axios";
import moment from "moment";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  baseURL: BASE_URL,
});

export const handleImageUpload = async (file) => {
  // console.log("🚀 ~ handleImageUpload ~ file:", file);
  try {
    let imageFile = file;
    if (imageFile && imageFile instanceof File) {
      const newImageName = `${moment().format("YYYYMMDDHHmmssSSS")}_${
        imageFile.name
      }`;
      imageFile = new File([imageFile], newImageName, {
        type: imageFile.type,
      });

      const {
        data: { uploadUrl },
      } = await api.post("/getUploadLink", {
        fileName: imageFile.name,
        contentType: imageFile.type,
      });

      // console.log("🚀 ~ handleImageUpload ~ uploadUrl:", uploadUrl);

      let formData = new FormData();
      formData.append("file", imageFile);

      const res = await fetch(uploadUrl, {
        method: "POST",
        body: formData,
      });

      if (!res.ok) {
        throw new Error("File upload failed");
      }

      const resData = await res.json();
      // console.log("🚀 ~ handleImageUpload ~ resData:", resData);

      const publicImageUrl = resData.result.variants.find((url) =>
        url.includes("/public")
      );

      if (!publicImageUrl) {
        throw new Error("Public image URL not found");
      }

      // console.log("🚀 ~ handleImageUpload ~ publicImageUrl:", publicImageUrl);

      return publicImageUrl;
    }
  } catch (error) {
    console.error("An error occurred while uploading the image:", error);
    return { error: error.message, publicImageUrl: null };
  }
};
