import { Box, useMediaQuery } from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import BottomMenu from "components/bottomMenu/BottomMenu";
import React from "react";
import Header from "scenes/admin/components/Header";
import Navbar from "scenes/navbar";
import GetSports from "./components/getSports";
import Footer from "components/Footer";

function SportsDirectoy({ socket }) {
  const isLargeScreen = useMediaQuery("(min-width:1000px)");
  return (
    <>
      <Box minHeight="100vh">
        <Navbar socket={socket} />
        <WidgetWrapper>
          <Box m={isLargeScreen ? "1.5rem 2rem 2rem 3rem" : undefined}>
            <Header title="THE SPORTS" subtitle="DIRECTORY" />
          </Box>
          <GetSports />
        </WidgetWrapper>
      </Box>
      {!isLargeScreen && <BottomMenu />}
      <Footer />
    </>
  );
}

export default SportsDirectoy;
