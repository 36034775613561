import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";

function CategorySelect({
  categories,
  selectedCategory,
  setSelectedCategory,
  error,
}) {
  return (
    <FormControl
      fullWidth
      margin="normal"
      sx={{ marginRight: "1rem" }}
      error={Boolean(error)}
    >
      <InputLabel id="category-label">Category</InputLabel>
      <Select
        labelId="category-label"
        value={selectedCategory}
        onChange={(e) => setSelectedCategory(e.target.value)}
      >
        {categories.map((category, index) => (
          <MenuItem value={category} key={index}>
            {category}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

export default CategorySelect;
