import { ModeEditOutlineOutlined } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { handleImageUpload } from "redux/actions/imageUploadUtils";

function BrandImageEditRegister({
  formData,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  setSnackbarMessage,
  setSnackbarSeverity,
  setSnackbarOpen,
}) {
  const fileInputRef = useRef(null);
  const [image, setImage] = useState("");

  const defaultImageURL =
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/6d0f5d8b-96b6-48d8-7bf8-ba57dbcea500/512x512";

  const handleEditClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const uploadedImageUrl = await handleImageUpload(file);
        if (uploadedImageUrl) {
          setImage(uploadedImageUrl); // This will update the displayed image
          setSnackbarMessage("Image uploaded successfully.");
          setSnackbarSeverity("success");
        } else {
          console.error("Image upload failed. Using default image.");
          setImage(defaultImageURL);
          setSnackbarMessage("Image upload failed. Using default image.");
          setSnackbarSeverity("error");
        }
      } catch (error) {
        console.error("Image upload failed:", error);
        setImage(defaultImageURL);
        setSnackbarMessage(`Image upload failed: ${error.message}`);
        setSnackbarSeverity("error");
      }
    }
    setSnackbarOpen(true);
  };

  useEffect(() => {
    if (image) {
      setFieldValue("businessForm.picturePath", image);
    } else {
      setFieldValue("businessForm.picturePath", defaultImageURL);
    }
  }, [image, setFieldValue]);

  return (
    <Box mb="1rem" position="relative">
      <Box
        width="100%"
        position="relative"
        style={{
          height: "250px",
          overflow: "hidden",
          borderRadius: "1rem",
          marginBottom: "1rem",
          border: "1px dashed gray",
        }}
      >
        <img
          src={image || defaultImageURL}
          alt={formData?.businessForm?.name}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      </Box>
      <Button onClick={handleEditClick} startIcon={<ModeEditOutlineOutlined />}>
        {image ? "Edit Image" : "Add Image"}
      </Button>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </Box>
  );
}

export default BrandImageEditRegister;
