import { Box, Slider, Typography } from "@mui/material";
import React from "react";

function AgeRangeSelector({
  ageRangeSliderValue,
  handleSliderChange,
  selectedMarks,
  sliderValueToAgeRange,
}) {
  return (
    <Box mt={2}>
      <Typography variant="h5" fontWeight={700}>
        Age Range
      </Typography>

      <Slider
        value={ageRangeSliderValue}
        onChange={handleSliderChange}
        valueLabelDisplay="off"
        aria-labelledby="range-slider"
        getAriaValueText={sliderValueToAgeRange}
        marks={selectedMarks}
        step={1}
        min={0}
        max={6}
        valueLabelFormat={(value) => sliderValueToAgeRange(value)}
        sx={{
          marginTop: "1rem",
          "& .MuiSlider-thumb": {
            height: 24,
            width: 24,
            backgroundColor: "#fff",
            border: "3px solid currentColor",
            "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
              boxShadow: "inherit",
            },
            "&::before": {
              display: "none",
            },
          },
        }}
      />
    </Box>
  );
}

export default AgeRangeSelector;
