import { TextField } from "@mui/material";
import React from "react";

function FormFields({ formData, handleChange, formErrors }) {
  return (
    <div>
      <TextField
        autoFocus={true}
        margin="dense"
        label="Role Name"
        name="name"
        value={formData.name}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
        fullWidth
        variant="outlined"
        error={!!formErrors.name}
        helperText={formErrors.name}
      />
      <TextField
        margin="dense"
        label="Description"
        name="description"
        value={formData.description}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
        fullWidth
        variant="outlined"
        error={!!formErrors.description}
        helperText={formErrors.description}
        multiline
      />
      <TextField
        margin="dense"
        label="Icon Tooltip"
        name="toolTip"
        value={formData.toolTip}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
        fullWidth
        variant="outlined"
      />
    </div>
  );
}

export default FormFields;
