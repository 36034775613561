import { Box, Typography } from "@mui/material";
import React from "react";

function UserWidgetAnsweredQuestions({
  primaryMain,
  primaryDark,
  questionnaireAnswers,
}) {
  // Questions array
  const questions = [
    { key: "whatIWant", question: "What I want to get out of this community?" },
    { key: "whatIOffer", question: "Something I can offer the community?" },
    { key: "adviceAbout", question: "Ask me for advice about:" },
    {
      key: "sportToTry",
      question: "A sport I'd really love to try and why I haven't yet?",
    },
    {
      key: "sportILove",
      question: "A sport I'm terrible at, but love and why?",
    },
    {
      key: "favoriteMemory",
      question: "My favourite sports or adventure memory?",
    },
    { key: "threeThings", question: "3 things you should know about me:" },
    {
      key: "funniestFail",
      question: "Let me tell you about my funniest sporting fail:",
    },
    { key: "hatedSports", question: "Things I hated about sport growing up?" },
    {
      key: "cantGoWithout",
      question: "Something I can't go anywhere without?",
    },
  ];

  // If userAnswers is null or undefined, return null
  if (!questionnaireAnswers) return null;

  // Filter answers to check if there's at least one non-whitespace answer
  const hasValidAnswers = questions.some(({ key }) =>
    questionnaireAnswers[key]?.trim()
  );

  // If no valid answers, don't render the component
  if (!hasValidAnswers) return null;

  return (
    <Box p="0.5rem 0">
      <Typography
        variant="h5"
        fontSize="1rem"
        color={primaryMain}
        fontWeight="500"
        mb="1rem"
      >
        A little more about me
      </Typography>
      {questions.map(({ key, question }) => {
        const answer = questionnaireAnswers[key]?.trim();
        return (
          answer && (
            <Box key={key} mb="1rem">
              <Typography variant="h6" color={primaryDark} fontWeight="500">
                Q: {question}
              </Typography>
              <Typography variant="body1">{answer}</Typography>
            </Box>
          )
        );
      })}
    </Box>
  );
}

export default UserWidgetAnsweredQuestions;
