import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import UserGroupJoined from "components/UserGroupJoined";
import WidgetWrapper from "components/WidgetWrapper";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function UserGroupsFollowed() {
  const isLargeScreen = useMediaQuery("(min-width: 1000px)");
  const user = useSelector((state) => state.viewingUser || state.user);
  const groupsJoinedDetails = user?.groupsJoinedDetails || [];

  const { palette } = useTheme();
  const neutralDark = palette.neutral.dark;
  const primaryDark = palette.primary.dark;

  if (groupsJoinedDetails.length === 0) {
    return null;
  }

  const displayedGroups = groupsJoinedDetails.slice(0, isLargeScreen ? 10 : 5);

  const displayRemainingGroups = groupsJoinedDetails.slice(
    isLargeScreen ? 10 : 5
  );

  const viewMoreCount = isLargeScreen ? 10 : 5;

  return (
    <WidgetWrapper borderRadius={!isLargeScreen ? "2rem" : "0.75rem"}>
      <Typography
        color={neutralDark}
        variant="h5"
        fontWeight="500"
        sx={{ mb: "1.5rem" }}
      >
        Groups Joined
      </Typography>

      <Box display="flex" flexDirection="column" gap="0.5rem">
        {displayedGroups.map((group) => (
          <UserGroupJoined key={group._id} group={group} />
        ))}
      </Box>

      <Box mt={1} display="flex" flexDirection="center">
        <AvatarGroup max={6}>
          {displayRemainingGroups.map((group) => (
            <Tooltip key={group._id} title={group.name}>
              <Avatar
                src={group.image}
                alt={group.name}
                sx={{ width: "40px", height: "40px" }}
              />
            </Tooltip>
          ))}
        </AvatarGroup>
      </Box>

      {groupsJoinedDetails.length > viewMoreCount && (
        <Button
          variant="text"
          sx={{ color: primaryDark, textTransform: "none" }}
          component={Link}
          to={`/profile/${user._id}/groups`}
        >
          View All
        </Button>
      )}
    </WidgetWrapper>
  );
}

export default UserGroupsFollowed;
