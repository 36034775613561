// actions/brands.js

import { data } from "flickity";
import {
  setBrands,
  setBrand,
  setTotalBrands,
  setUserBrands,
  fetchUser,
  setBrandSubscriptionPrices,
} from "redux/state"; // Assuming you have these actions in your redux state

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const createBrand =
  (brandData, isUser) => async (dispatch, getState) => {
    const token = getState().token;
    try {
      const response = await fetch(`${BASE_URL}/brand`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ brandData, isUser }),
      });
      const data = await response.json();
      // Dispatch action to update redux state if needed
      if (response.ok) {
        return data; // Return the newly created brand
      } else {
        // Handle error
        console.error("Failed to create brand:", data);
      }
    } catch (error) {
      console.error("Failed to create brand:", error);
    }
  };

export const fetchBrands =
  (page, pageSize, sort, search) => async (dispatch, getState) => {
    try {
      const token = getState().token;

      // Construct URL with query parameters
      let url = `${BASE_URL}/brand?page=${page}&limit=${pageSize}`;

      if (sort) {
        url += `&sort=${sort}`;
      }

      if (search) {
        url += `&search=${search}`;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      dispatch(setBrands(data.data));
      dispatch(setTotalBrands(data.total));
      return data;
    } catch (error) {
      console.log(error);
    }
  };

export const fetchBrand = (brandId) => async (dispatch, getState) => {
  const token = getState().token;
  try {
    const response = await fetch(`${BASE_URL}/brand/${brandId}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    dispatch(setBrand(data));
    return data;
  } catch (error) {
    console.error("Failed to fetch brand:", error);
  }
};

export const fetchUserBrands = (userId) => async (dispatch, getState) => {
  try {
    const token = getState().token;

    // Construct URL to hit the new endpoint
    const url = `${BASE_URL}/brand/user/${userId}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();

    if (response.ok) {
      // Assuming you have a setUserBrands action to update your redux state
      dispatch(setUserBrands(data));
      return data; // Optionally return the data for further processing
    } else {
      // Handle error
      console.error("Failed to fetch user brands:", data);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateBrand =
  (brandId, updatedBrandData) => async (dispatch, getState) => {
    const token = getState().token;
    try {
      const response = await fetch(`${BASE_URL}/brand/${brandId}`, {
        method: "PUT", // Use PUT for full resource updates
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedBrandData),
      });
      const data = await response.json();
      // Dispatch action to update redux state if needed
      return data;
    } catch (error) {
      console.error("Failed to update brand:", error);
    }
  };

export const deleteBrand = (brandId) => async (dispatch, getState) => {
  const token = getState().token;

  try {
    await fetch(`${BASE_URL}/brand/${brandId}`, {
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    console.error("Failed to delete brand:", error);
  }
};

export const toggleFollowBrand =
  (brandId, isProfilePage = false) =>
  async (dispatch, getState) => {
    const userId = getState().user._id; // Assuming you have a user object in your state
    const token = getState().token;

    try {
      const response = await fetch(`${BASE_URL}/brand/${brandId}/follow`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId }),
      });

      const data = await response.json();

      if (response.ok) {
        // dispatch(fetchBrand(brandId)); // Refresh the brand data
        dispatch(fetchUser(userId, isProfilePage)); // Refresh the user data
      } else {
        console.error("Failed to follow/unfollow brand:", data);
      }
    } catch (error) {
      console.error("Failed to follow/unfollow brand:", error);
    }
  };

export const migrateFollowers = () => async (dispatch, getState) => {
  const token = getState().token;
  try {
    const response = await fetch(`${BASE_URL}/brand/migrate-followers`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    if (!response.ok) {
      console.error("Failed to migrate followers:", data);
    }
  } catch (error) {
    console.error("Failed to migrate followers:", error);
  }
};

export const fetchBrandSubscriptionsPrices =
  (id) => async (dispatch, getState) => {
    const token = getState().token;
    try {
      const response = await fetch(`${BASE_URL}/brand/${id}/prices`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      // Dispatch action to update redux state if needed
      dispatch(setBrandSubscriptionPrices(data));
      return data;
    } catch (error) {
      console.error("Failed to fetch brand subscription prices:", error);
    }
  };

export const createBrandSubscription =
  (priceId, selectedBrandId) => async (dispatch, getState) => {
    const brandId = selectedBrandId;
    const token = getState().token;

    try {
      const response = await fetch(`${BASE_URL}/brand/subscribe`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ priceId, brandId }),
      });

      data = await response.json();

      // Dispatch action to update redux state if needed
      return data;
    } catch (error) {
      console.error("Failed to create brand subscription:", error);
    }
  };

export const fetchSubscriptionStatus =
  (brandId) => async (dispatch, getState) => {
    const token = getState().token;
    try {
      const response = await fetch(
        `${BASE_URL}/brand/${brandId}/subscription-status`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();

      dispatch(updateBrand(brandId, data));

      return data;
    } catch (error) {
      console.error("Failed to fetch subscription status:", error);
    }
  };

export const fetchSubscriptions = (brandId) => async (dispatch, getState) => {
  const token = getState().token;
  try {
    const response = await fetch(`${BASE_URL}/brand/${brandId}/subscriptions`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to fetch subscriptions:", error);
  }
};

export const fetchCustomerPortal = () => async (dispatch, getState) => {
  const token = getState().token;
  try {
    const response = await fetch(`${BASE_URL}/brand/customer-portal`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to fetch customer portal:", error);
  }
};
