import { Box, useMediaQuery } from "@mui/material";
import Footer from "components/Footer";
import BottomMenu from "components/bottomMenu/BottomMenu";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchUser } from "redux/state";
import Navbar from "scenes/navbar";
import UserGroupsFollowedAll from "scenes/widgets/UserGroupsFollowedAll";
import UserWidget from "scenes/widgets/UserWidget";

function UserGroupsPage({ socket }) {
  const { userId } = useParams();
  const dispatch = useDispatch(); // get the dispatch function
  const isLargeScreen = useMediaQuery("(min-width:1000px)");
  const user = useSelector((state) => state.user); // get the user from the state

  useEffect(() => {
    dispatch(fetchUser(userId)); // dispatch the fetchUser action
  }, [dispatch, userId]);

  if (!user) return null;

  return (
    <>
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <Navbar socket={socket} />
        <Box
          flexGrow={1}
          overflow="auto"
          width="100%"
          // padding="2rem 6%"
          padding={isLargeScreen ? "0 6%" : "0"}
          display={isLargeScreen ? "flex" : "block"}
          gap="2rem"
          justifyContent="center"
        >
          <Box flexBasis={isLargeScreen ? "35%" : undefined}>
            <UserWidget userId={userId} />
          </Box>
          <Box
            flexBasis={isLargeScreen ? "60%" : undefined}
            mt={isLargeScreen ? undefined : "2rem"}
          >
            <UserGroupsFollowedAll />
          </Box>
        </Box>
        <Footer />
      </Box>
      {!isLargeScreen && <BottomMenu />}
    </>
  );
}

export default UserGroupsPage;
