import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import Dropzone from "react-dropzone";
import { useEffect, useState } from "react";

const FormUserImg = (props) => {
  const {
    values,
    setFieldValue,
    setSnackbarMessage,
    setSnackbarSeverity,
    setSnackbarOpen,
    handleImageUpload,
    setIsLoading,
  } = props;

  const { palette } = useTheme();
  const medium = palette.neutral.medium;
  const main = palette.primary.main;
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const profileImages = [
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/977e5e86-b3e6-42df-f85c-410d81d10600/public",
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/e5cbaa9a-62cf-4111-9ab5-a9c6cee32500/public",
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/72d500fd-6bcb-4c10-9f6a-b7890504bc00/public",
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/65a9bcf0-b4cc-410d-a46a-2cb3da733700/public",
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/7f0189d6-6f74-4f6a-b4d5-530581bfe400/public",
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/0dc15ebb-2a04-4f30-d8d8-526205c60300/public",
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/e37e17ff-0c33-4678-5ddf-7ef67df7a200/public",
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/de18bf8f-f0b4-4443-491b-a36b4d18ac00/public",
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/2cabe27a-0d01-4e03-91ec-7b30cb039700/public",
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/68eb157e-b25e-46c8-7500-fbfc94c5d200/public",
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/1e309a26-d8d6-4e6a-2f22-06c75b7cbe00/public",
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/ccfb97b8-0fac-478a-b84a-3c6593467900/public",
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/5d7a859a-d57a-499a-41bd-b110edf4dc00/public",
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/9f7b2bfa-d5a6-4aa4-06fd-7414a7ea1300/public",
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/e5c6254e-3b8a-4450-13ab-d20b492fbc00/public",
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/fa1275e1-60a6-4b75-f12f-86018e6d1400/public",
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/3480e4ea-91fe-4625-a8e7-2fba0c6cf300/public",
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/89a8dc55-9922-47cb-af7a-873c04ba7e00/public",
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/c0b1beb4-e4e8-4547-e70a-c46faece2200/public",
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/4800d7f5-caab-45d8-9cff-101e44ae4c00/public",
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/393f05ad-9f71-4da2-d8c9-757b6909b500/public",
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/1441a580-fb01-4abd-e719-185bdb3e4500/public",
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/7dc3f3d6-055b-4fc0-e730-1397de08fa00/public",
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/c97ceeb7-8b57-4e05-8c4e-d9cf19f36c00/public",
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/a3efc384-862b-4c9a-0dd4-c55122df5000/public",
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/192c3dd8-0778-4189-fe18-1b182fe44d00/public",
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/e5960ecc-f7f0-44de-77be-42a2a6e29500/public",
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/46efbd96-56f9-4caa-cbdb-ae9f8d016400/public",
  ];

  useEffect(() => {
    if (!values.picture && selectedImageIndex === null) {
      const randomIndex = Math.floor(Math.random() * profileImages.length);
      setFieldValue("picture", profileImages[randomIndex]);
      setSelectedImageIndex(randomIndex);
    }
  }, [values.picture, profileImages, setFieldValue]); // eslint-disable-line react-hooks/exhaustive-deps

  // Function to create a URL for the uploaded image
  const createImageURL = (file) => {
    if (file && file instanceof File) {
      return URL.createObjectURL(file);
    }
    return null;
  };

  const onDrop = async (acceptedFiles) => {
    setIsLoading(true);
    const file = acceptedFiles[0];
    if (file && file instanceof File) {
      try {
        const uploadResult = await handleImageUpload(file);
        if (uploadResult && !uploadResult.error) {
          setFieldValue("picture", uploadResult);
          setSelectedImageIndex(null);
          setSnackbarMessage("Successfully uploaded file");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
          setIsLoading(false);
        } else {
          setSnackbarMessage(uploadResult.error);
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error uploading file:", error.message);
        setSnackbarMessage("Error uploading file:", error.message);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  };

  return (
    <Box
      gridColumn="span 4"
      border={`1px solid ${medium}`}
      borderRadius="5px"
      p="1rem"
      display="flex"
      flexDirection="column"
      // alignItems="center"
      // justifyContent="center"
    >
      <Dropzone
        acceptedFiles=".jpg,.jpeg,.png"
        multiple={false}
        onDrop={onDrop}
      >
        {({ getRootProps, getInputProps }) => (
          <Box
            {...getRootProps()}
            border={`2px dashed ${main}`}
            p="1px"
            sx={{ "&:hover": { cursor: "pointer" } }}
            data-testid="dropzone"
            borderRadius="50%"
            width="60%"
            margin="0 auto"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <input {...getInputProps()} />
            {typeof values.picture === "string" ? (
              <img
                src={values.picture}
                alt="Selected profile"
                style={{
                  width: "100%",
                  aspectRatio: "1 / 1",
                  margin: "0 auto",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  objectFit: "cover",
                }}
              />
            ) : (
              values.picture && (
                <img
                  src={createImageURL(values.picture)}
                  alt="Uploaded profile"
                  style={{
                    width: "100%",
                    aspectRatio: "1 / 1",
                    margin: "0 auto",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    objectFit: "cover",
                  }}
                />
              )
            )}
            <Typography
              variant="body1"
              textAlign="center"
              position="absolute"
              sx={{
                backgroundColor: "rgba(255,255,255,0.7)",
                padding: "8px",
                borderRadius: "4px",
                position: "absolute",
              }}
            >
              Click or drop profile picture here
            </Typography>
          </Box>
        )}
      </Dropzone>

      <Typography variant="body1" mt={2}>
        Or choose a profile picture:
      </Typography>
      <Grid container spacing={1} mt={1}>
        {profileImages.map((img, index) => (
          <Grid item xs={4} sm={3} md={2} key={index}>
            <IconButton
              onClick={() => {
                setFieldValue("picture", img);
                setSelectedImageIndex(index);
              }}
            >
              <Avatar
                src={
                  img.endsWith("/public")
                    ? img.replace("/public", "/128x128")
                    : `${img}/128x128`
                }
                variant="rounded"
                sx={{
                  width: 80,
                  height: 80,
                  border:
                    index === selectedImageIndex ? "4px solid blue" : "none",
                }}
              />
            </IconButton>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FormUserImg;
