import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import {
  fetchLocationDetails,
  fetchLocations,
} from "redux/actions/locationActions";
import { fetchSports } from "redux/actions/sportActions";
import { useMediaQuery } from "@mui/material";
import * as yup from "yup";
import { createBrand } from "redux/actions/brandActions";
import { handleImageUpload } from "redux/actions/imageUploadUtils";
import { addLocation } from "redux/actions/brandLocationsActions";

const useBrandCreateForm = ({ isUser = false } = {}) => {
  const dispatch = useDispatch();
  const sportsList = useSelector((state) => state.sports);
  const locations = useSelector((state) => state.locations) || [];
  const [selectedSport, setSelectedSport] = useState([]);
  const [location, setLocation] = useState("");
  const [locationOptions, setLocationOptions] = useState([]);
  const [locationInput, setLocationInput] = useState("");
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [logo, setLogo] = useState(null);
  const fileInputRef = useRef(null);
  const logoInputRef = useRef(null);
  const [formErrors, setFormErrors] = useState({});
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locationDetails, setLocationDetails] = useState(null);
  const [googleImages, setGoogleImages] = useState([]);
  const [locationSelected, setLocationSelected] = useState(false);

  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  const defaultImageURL =
    "https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/6d0f5d8b-96b6-48d8-7bf8-ba57dbcea500/public";

  const debouncedFetchLocations = useMemo(
    () =>
      debounce(async (input) => {
        try {
          await dispatch(fetchLocations(input));
        } catch (error) {
          console.error("Error fetching locations:", error);
        }
      }, 500),
    [dispatch]
  );

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    phone: "",
    website: "",
    sportsPromoted: [],
  });

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required("Brand name is required.")
      .min(2, "Brand name should be at least 2 characters.")
      .max(100, "Brand name should be at most 100 characters."),
    description: yup
      .string()
      .required("Description is required.")
      .min(10, "Description should be at least 10 characters."),
    phone: yup.string(),
    website: yup.string().required("Website is required.").url(),
    sportsPromoted: yup.array().min(1, "A sport is required."),
  });

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSportChange = (newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      sportsPromoted: newValue ? newValue : [],
    }));
  };

  const handleSubmit = async () => {
    const aggregatedErrors = {};

    try {
      await validationSchema.validate(formData, { abortEarly: false });
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        err.inner.forEach((error) => {
          aggregatedErrors[error.path] = error.message;
        });
      }
    }

    if (
      !locationDetails ||
      !locationDetails.locationDetails ||
      !locationDetails.locationDetails.address
    ) {
      aggregatedErrors["locationDetails"] = "Location address required.";
    }

    if (Object.keys(aggregatedErrors).length > 0) {
      setFormErrors(aggregatedErrors);
      return;
    }

    try {
      const brandData = {
        ...formData,
        picturePath: image || defaultImageURL,
        logoPath: logo || defaultImageURL,
        sportsPromoted: formData.sportsPromoted,
      };

      const newBrand = await dispatch(createBrand(brandData, isUser));

      if (newBrand && newBrand._id) {
        if (selectedLocation) {
          const locationData = {
            type: "Point",
            locationType: "primary",
            address: locationDetails.locationDetails.address,
            town: locationDetails.locationDetails.town,
            coordinates: [
              locationDetails.locationDetails.lng,
              locationDetails.locationDetails.lat,
            ],
            country: locationDetails.locationDetails.country,
            placeId: locationDetails.locationDetails.placeId,
            locality:
              locationDetails.locationDetails.address ===
                locationDetails.locationDetails.country &&
              locationDetails.locationDetails.town === ""
                ? "National"
                : "Local",
          };
          await dispatch(addLocation(newBrand._id, locationData));
        }
      }

      resetForm();
      handleClose();
    } catch (err) {
      console.error("Submission Error:", err);
    }
  };

  const resetForm = () => {
    setFormData({
      name: "",
      description: "",
      phone: "",
      website: "",
      sportsPromoted: [],
      locationDetails: null,
    });
    setImage(null);
    setLogo(null);
    setSelectedLocation(null);
    setLocationDetails(null);
    setFormErrors({});
    setLocationSelected(false);
  };

  const handleEditClick = (ref) => {
    ref.current.click();
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const uploadedImageUrl = await handleImageUpload(file);
        if (uploadedImageUrl) {
          setFormData((prevData) => ({ ...prevData, image: uploadedImageUrl }));
          setImage(uploadedImageUrl);
        } else {
          console.error("Image upload failed. Using default image.");
          setFormData((prevData) => ({ ...prevData, image: defaultImageURL }));
        }
      } catch (error) {
        console.error("Image upload failed:", error);
        setFormData((prevData) => ({ ...prevData, image: defaultImageURL }));
      }
    }
  };

  const handleLogoChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const uploadedImageUrl = await handleImageUpload(file);
        if (uploadedImageUrl) {
          setFormData((prevData) => ({ ...prevData, logo: uploadedImageUrl }));
          setLogo(uploadedImageUrl);
        } else {
          console.error("Image upload failed. Using default image.");
          setFormData((prevData) => ({ ...prevData, logo: defaultImageURL }));
        }
      } catch (error) {
        console.error("Image upload failed:", error);
        setFormData((prevData) => ({ ...prevData, logo: defaultImageURL }));
      }
    }
  };

  const handleGoogleImageSelect = useCallback((url) => {
    setImage(url);
    setFormData((prevData) => ({
      ...prevData,
      picturePath: url,
    }));
  }, []);

  useEffect(() => {
    if (locationInput !== "") {
      debouncedFetchLocations(locationInput);
    } else {
      setLocationOptions([]);
    }
    return () => {
      debouncedFetchLocations.cancel();
    };
  }, [locationInput, debouncedFetchLocations]);

  useEffect(() => {
    if (locations.length > 0) {
      const formattedLocations = locations.map((location) => ({
        ...location,
        description: location.description || "Unknown location",
      }));
      setLocationOptions(formattedLocations);
    }
  }, [locations]);

  useEffect(() => {
    const fetchLocationDetails = async () => {
      if (location) {
        try {
          const details = await getLocationDetails(location.place_id);
          setLocationDetails(details);
          setGoogleImages(details.locationDetails.photos || []);

          if (
            details.locationDetails.name &&
            details.locationDetails.name !== "N/A"
          ) {
            setFormData((prevData) => ({
              ...prevData,
              name: details.locationDetails.name,
            }));

            if (
              details.locationDetails.website &&
              details.locationDetails.website !== "N/A"
            ) {
              setFormData((prevData) => ({
                ...prevData,
                website: details.locationDetails.website,
              }));
            }

            if (
              details.locationDetails.IntPhone &&
              details.locationDetails.IntPhone !== "N/A"
            ) {
              setFormData((prevData) => ({
                ...prevData,
                phone: details.locationDetails.IntPhone,
              }));
            }

            if (
              details.locationDetails.summary &&
              details.locationDetails.summary !== "N/A"
            ) {
              setFormData((prevData) => ({
                ...prevData,
                description: details.locationDetails.summary,
              }));
            }
          }
        } catch (error) {
          console.error("Error fetching location details:", error);
        }
      } else {
        setLocationDetails(null);
      }
    };

    fetchLocationDetails();
  }, [location]);

  useEffect(() => {
    dispatch(fetchSports());
  }, [dispatch]);

  const getLocationDetails = async (placeId) => {
    if (!placeId) return;
    try {
      const locationDetails = await dispatch(fetchLocationDetails(placeId));
      return locationDetails;
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));

    if (name === "sport") {
      setSelectedSport(value);
      handleSportChange(value);
    }
  };

  const handleLocationChange = (event, newValue) => {
    setLocation(newValue);
    setSelectedLocation(newValue);
    if (newValue && newValue.description) {
      setLocationInput(newValue.description);
    }
  };

  const handleLocationInputChange = (event, newInputValue, reason) => {
    if (reason === "input") {
      setLocationInput(newInputValue);
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline"],
      [{ list: "bullet" }],
      [{ color: [] }],
      [{ align: [] }],
      ["clean"],
      ["link"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "align",
  ];

  return {
    handleChange,
    handleLocationChange,
    handleLocationInputChange,
    locationOptions,
    modules,
    formats,
    sportsList,
    selectedSport,
    setSelectedSport,
    location,
    formData,
    setFormData,
    image,
    logo,
    handleEditClick,
    handleFileChange,
    handleLogoChange,
    formErrors,
    fileInputRef,
    logoInputRef,
    handleSportChange,
    defaultImageURL,
    open,
    handleOpen,
    handleClose,
    handleSubmit,
    isLargeScreen,
    googleImages,
    handleGoogleImageSelect,
    locationSelected,
    setLocationSelected,
  };
};

export default useBrandCreateForm;
