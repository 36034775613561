import { setFlags, setFlag } from "redux/state";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const fetchFlags =
  ({ page = 1, limit = 10, sort = "", search = "" }) =>
  async (dispatch, getState) => {
    try {
      const token = getState().token;
      let url = `${BASE_URL}/flags?page=${page}&limit=${limit}`;

      if (sort) {
        url += `&sort=${sort}`;
      }

      if (search) {
        url += `&search=${search}`;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });

      const data = await response.json();
      dispatch(setFlags(data));
      return data;
    } catch (error) {
      console.log(error);
    }
  };

export const fetchFlag = (id) => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/flags/${id}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await response.json();
    dispatch(setFlag(data));
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const createFlag = (flag) => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/flags`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(flag),
    });

    const data = await response.json();
    dispatch(setFlag(data));
    dispatch(fetchFlags());
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const updateFlag = (id, flag) => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/flags/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(flag),
    });

    const data = await response.json();
    dispatch(setFlag(data));
    dispatch(
      fetchFlags({
        page: 1,
        limit: 25,
        sort: "",
        search: "",
      })
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteFlag = (id) => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/flags/${id}`, {
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await response.json();
    // dispatch(setFlag(data));
    dispatch(fetchFlags());
    return data;
  } catch (error) {
    console.log(error);
  }
};
