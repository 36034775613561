import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import OverviewChart from "../components/OverviewChart";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import {
  fetchAllVerifiedUsers,
  fetchAllUsers,
  fetchUsersAtEndOfLastMonth,
  fetchUsersCreatedByMonth,
  fetchVerifiedUsersCreatedByMonth,
  fetchVerifiedUsersAtEndOfLastMonth,
  fetchVerifiedUsersCreatedLastMonth,
  fetchUsersCreatedLastMonth,
} from "redux/actions/dashboardUserActions";
import {
  fetchPostsCreatedLastMonth,
  fetchPostsCreatedThisMonth,
  fetchTotalPostLikesLastMonth,
  fetchTotalPostLikesThisMonth,
} from "redux/actions/dashboardPostsActions";
import {
  fetchBrandsAtEndOfLastMonth,
  fetchTotalBrands,
  fetchTotalNumberOfFollowers,
} from "redux/actions/dashboardBrandsActions";
import {
  fetchTotalArticles,
  fetchArticleReadsLastMonth,
  fetchArticleReadsThisMonth,
  fetchArticlesEndOfLastMonth,
  fetchTotalArticleLikesLastMonth,
  fetchTotalArticleLikesThisMonth,
} from "redux/actions/dashboardAdminArticles";
import UserAgeRangeBreakdownChart from "../components/UserAgeRangeBreakdownChart";
import StatBox from "../components/StatBox";
import UserGrid from "../users/UserGrid";
import UserSportsBreakdownChart from "../components/UserSportsBreakdownChart";

function Dashboard() {
  const dispatch = useDispatch();

  const { palette } = useTheme();
  const backgroundAlt = palette.background.alt;
  const primaryDark = palette.primary.dark;
  const isLargeScreen = useMediaQuery("(min-width:1000px)");
  const isDashboard = true;

  const peopleAltOutlinedIcon = (
    <PeopleAltOutlinedIcon style={{ color: primaryDark }} />
  );

  const dynamicFeedIcon = <DynamicFeedIcon style={{ color: primaryDark }} />;

  const businessOutlinedIcon = (
    <BusinessOutlinedIcon style={{ color: primaryDark }} />
  );

  const articleOutlinedIcon = (
    <ArticleOutlinedIcon style={{ color: primaryDark }} />
  );

  // all the users (inc non verified) created by month
  const usersPerMonth = useSelector(
    (state) => state.dashboardAdminUser.usersPerMonth
  );

  // sum of the verified users from the verifiedUsersPerMonth array
  const totalVerifiedUsers = useSelector(
    (state) => state.dashboardAdminUser.totalVerifiedUsers
  );

  // sum of the users from the usersPerMonth array
  const totalUsers = useSelector(
    (state) => state.dashboardAdminUser.totalUsers
  );

  // sum of the users from the usersPerMonth array (needs to be here as there maybe no users for the last month)
  const totalUsersAtEndOfLastMonth = useSelector(
    (state) => state.dashboardAdminUser.totalUsersAtEndOfLastMonth
  );

  // sum of the verified users from the verifiedUsersPerMonth array (needs to be here as there maybe no users for the last month)
  const totalVerifiedUsersAtEndOfLastMonth = useSelector(
    (state) => state.dashboardAdminUser.totalVerifiedUsersAtEndOfLastMonth
  );

  // percentage increase in users
  const increase = Math.round(
    ((totalUsers - totalUsersAtEndOfLastMonth) / totalUsersAtEndOfLastMonth) *
      100
  );

  // percentage increase in verified users
  const verifiedIncrease = Math.round(
    ((totalVerifiedUsers - totalVerifiedUsersAtEndOfLastMonth) /
      totalVerifiedUsersAtEndOfLastMonth) *
      100
  );

  const chartData = useMemo(() => {
    if (!usersPerMonth) return [];

    return usersPerMonth.map((item) => ({
      x: `${item._id.month}-${item._id.year}`,
      y: item.count,
    }));
  }, [usersPerMonth]);

  // POSTS Constants
  const totalPostsThisMonth = useSelector(
    (state) => state.dashboardAdminPosts.totalPostsThisMonth
  );

  const totalPostsLastMonth = useSelector(
    (state) => state.dashboardAdminPosts.totalPostsLastMonth
  );

  const totalPostLikesThisMonth = useSelector(
    (state) => state.dashboardAdminPosts.totalLikesThisMonth
  );

  const totalPostLikesLastMonth = useSelector(
    (state) => state.dashboardAdminPosts.totalLikesLastMonth
  );

  const postsIncreasePercentage = Math.round(
    ((totalPostsThisMonth - totalPostsLastMonth) / totalPostsLastMonth) * 100
  );

  const postsLikesIncreasePercentage = Math.round(
    ((totalPostLikesThisMonth - totalPostLikesLastMonth) /
      totalPostLikesLastMonth) *
      100
  );

  const totalBrands = useSelector(
    (state) => state.dashboardAdminBrands.totalBrands
  );

  const totalBrandsAtEndOfLastMonth = useSelector(
    (state) => state.dashboardAdminBrands.totalBrandsAtEndOfLastMonth
  );

  const increaseBrands = Math.round(
    ((totalBrands - totalBrandsAtEndOfLastMonth) /
      totalBrandsAtEndOfLastMonth) *
      100
  );

  const totalNumberOfFollowers = useSelector(
    (state) => state.dashboardAdminBrands.totalNumberOfFollowers
  );

  const averageNumberOfFollowers = (
    totalNumberOfFollowers / totalBrands
  ).toFixed(2);

  const totalArticles = useSelector(
    (state) => state.dashboardAdminArticles.totalPublishedArticles
  );

  const articlesEndOfLastMonth = useSelector(
    (state) =>
      state.dashboardAdminArticles.totalArticlesPublishedAtEndOfLastMonth
  );

  const increaseArticles = Math.round(
    ((totalArticles - articlesEndOfLastMonth) / articlesEndOfLastMonth) * 100
  );

  const articleReadsThisMonth = useSelector(
    (state) => state.dashboardAdminArticles.totalArticleReadsThisMonth
  );

  const articleReadsLastMonth = useSelector(
    (state) => state.dashboardAdminArticles.totalArticleReadsLastMonth
  );

  const increaseArticleReads = Math.round(
    ((articleReadsThisMonth - articleReadsLastMonth) / articleReadsLastMonth) *
      100
  );

  useEffect(() => {
    // fetch user data
    dispatch(fetchAllVerifiedUsers());
    dispatch(fetchAllUsers());
    dispatch(fetchVerifiedUsersCreatedLastMonth());
    dispatch(fetchVerifiedUsersAtEndOfLastMonth());
    dispatch(fetchVerifiedUsersCreatedByMonth());
    dispatch(fetchUsersAtEndOfLastMonth());
    dispatch(fetchUsersCreatedByMonth());
    dispatch(fetchUsersCreatedLastMonth());
    // fetch post data
    dispatch(fetchPostsCreatedThisMonth());
    dispatch(fetchPostsCreatedLastMonth());
    dispatch(fetchTotalPostLikesThisMonth());
    dispatch(fetchTotalPostLikesLastMonth());
    // fetch brand data
    dispatch(fetchTotalBrands());
    dispatch(fetchBrandsAtEndOfLastMonth());
    dispatch(fetchTotalNumberOfFollowers());
    dispatch(fetchArticlesEndOfLastMonth());
    // fetch article data
    dispatch(fetchTotalArticles());
    dispatch(fetchArticleReadsThisMonth());
    dispatch(fetchArticleReadsLastMonth());
    dispatch(fetchTotalArticleLikesThisMonth());
    dispatch(fetchTotalArticleLikesLastMonth());
  }, [dispatch]);

  return (
    <Box
      m={isLargeScreen ? "1.5rem 2rem 2rem 3rem" : "1.5rem 0.5rem 2rem 0.5rem"}
    >
      <Box>
        <Header title="DASHBOARD" subtitle="Welcome to the Admin Dashboard" />
      </Box>
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isLargeScreen ? undefined : "span 12" },
        }}
      >
        {/* Row 1 */}

        <StatBox
          title="Verified Users"
          title2="Total Users"
          icon={peopleAltOutlinedIcon}
          valuePrimary={totalVerifiedUsers}
          increasePrimary={
            verifiedIncrease > 0
              ? `+${verifiedIncrease}%`
              : `${verifiedIncrease}%`
          }
          valueSeconday={totalUsers}
          increaseSecondary={increase > 0 ? `+${increase}%` : `${increase}%`}
        />

        <StatBox
          title={`Posts Created`}
          title2={`Posts Liked`}
          icon={dynamicFeedIcon}
          valuePrimary={totalPostsThisMonth}
          increasePrimary={
            postsIncreasePercentage > 0
              ? `+${postsIncreasePercentage}%`
              : `${postsIncreasePercentage}%`
          }
          valueSeconday={totalPostLikesThisMonth}
          increaseSecondary={
            postsLikesIncreasePercentage > 0
              ? `+${postsLikesIncreasePercentage}%`
              : `${postsLikesIncreasePercentage}%`
          }
        />

        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={backgroundAlt}
          p="1rem"
          borderRadius="0.55rem"
        >
          <OverviewChart isDashboard={isDashboard} data={chartData} />
        </Box>

        <StatBox
          title="Businesses"
          title2="Followers / average"
          icon={businessOutlinedIcon}
          valuePrimary={totalBrands}
          increasePrimary={
            increaseBrands > 0 ? `+${increaseBrands}%` : `${increaseBrands}%`
          }
          valueSeconday={totalNumberOfFollowers}
          increaseSecondary={averageNumberOfFollowers}
        />

        <StatBox
          title="Articles"
          title2="Read"
          icon={articleOutlinedIcon}
          valuePrimary={totalArticles}
          increasePrimary={
            increaseArticles > 0
              ? `+${increaseArticles}%`
              : `${increaseArticles}%`
          }
          valueSeconday={articleReadsThisMonth}
          increaseSecondary={
            increaseArticleReads > 0
              ? `+${increaseArticleReads}%`
              : `${increaseArticleReads}%`
          }
        />
      </Box>

      {/* UserGrid */}

      <Box
        gridColumn="span 12" // This makes UserGrid take the entire row
        backgroundColor={backgroundAlt}
        p="1.5rem"
        m="1.5rem 0"
        borderRadius="0.55rem"
      >
        <UserGrid isDashboard={isDashboard} />
      </Box>

      {/* User Charts */}
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="auto"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isLargeScreen ? undefined : "span 12" },
        }}
      >
        <Box
          gridColumn={isLargeScreen ? "span 6" : "span 12"} // Adjusting for the UserAgeRangeBreakdownChart similarly
          backgroundColor={backgroundAlt}
          p="1.5rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h6" sx={{ color: primaryDark, mb: 2 }}>
            Users By Age Range
          </Typography>
          <UserAgeRangeBreakdownChart isDashboard={isDashboard} />
          <Typography
            p="0 0.6rem"
            fontSize="0.8rem"
            sx={{ color: primaryDark }}
            mt={2}
          >
            Breakdown of all users (verified and unverified) by age range
          </Typography>
        </Box>

        <Box
          gridColumn={isLargeScreen ? "span 6" : "span 12"} // This will span 6 columns for large screens and full width for smaller screens
          backgroundColor={backgroundAlt}
          p="1.5rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h6" sx={{ color: primaryDark, mb: 2 }}>
            Sports Breakdown
          </Typography>
          <UserSportsBreakdownChart isDashboard={isDashboard} />
          <Typography
            p="0 0.6rem"
            fontSize="0.8rem"
            sx={{ color: primaryDark }}
            mt={2}
          >
            Breakdown of all users (verified and unverified) by sports interest
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Dashboard;
