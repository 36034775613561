import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import SingleChat from "./SingleChat/SingleChat";

function ChatBox({
  selectedChat,
  setSelectedChat,
  fetchAgain,
  setFetchAgain,
  socket,
}) {
  const chat = useSelector((state) =>
    state.chats.find((chat) => chat._id === selectedChat)
  );

  const { palette } = useTheme();
  const backgroundAlt = palette.background.alt;

  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  let displayValue;
  if (isLargeScreen) {
    displayValue = "flex";
  } else {
    displayValue = selectedChat ? "flex" : "none";
  }

  return (
    <Box
      display={displayValue}
      alignItems="center"
      flexDirection="column"
      width={isLargeScreen ? "68%" : "100%"}
      padding={
        isLargeScreen ? "0.5rem 1rem 0.75rem 1rem" : "1rem 0.5rem 0 0.5rem"
      }
      backgroundColor={backgroundAlt}
      borderRadius="0.75rem"
    >
      <SingleChat
        chat={chat}
        fetchAgain={fetchAgain}
        setFetchAgain={setFetchAgain}
        selectedChat={selectedChat}
        setSelectedChat={setSelectedChat}
        socket={socket}
      />
    </Box>
  );
}

export default ChatBox;
