import { Button } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import React from "react";

function FormActionButtons({ addTextBlock, addImageBlock }) {
  return (
    <>
      <FlexBetween>
        <Button variant="contained" onClick={addTextBlock}>
          Add Text Block
        </Button>
        <Button variant="contained" onClick={addImageBlock}>
          Add Image Block
        </Button>
      </FlexBetween>
    </>
  );
}

export default FormActionButtons;
