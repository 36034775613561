import { useTheme } from "@emotion/react";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser, patchUser } from "redux/state";
import {
  acceptJoinRequest,
  declineJoinRequest,
  getGroup,
} from "redux/actions/groupActions";

function GroupRequestsToJoin({ socket }) {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const mainSecondaryDark = palette.secondary.dark;
  const primaryMain = palette.primary.main;
  const user = useSelector((state) => state.user);
  const group = useSelector((state) => state.group);
  const requestingUsersId = useSelector((state) => state.group.joinRequests);
  const [requestingUsers, setRequestingUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  useEffect(() => {
    if (requestingUsersId) {
      setLoading(true);
      Promise.all(
        requestingUsersId.map((requestingUserId) =>
          dispatch(fetchUser(requestingUserId))
        )
      )
        .then((requestingUsers) => {
          setRequestingUsers(requestingUsers);
          setLoading(false);
        })
        .catch((err) => {
          setError(err);
          setLoading(false);
        });
    }
  }, [dispatch, requestingUsersId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleAccept = async (groupId, requestingUser) => {
    const result = await dispatch(
      acceptJoinRequest(groupId, requestingUser._id)
    );

    if (result.success) {
      const updatedUser = {
        ...requestingUser,
        groupsJoined: requestingUser.groupsJoined.concat(groupId),
      };
      dispatch(getGroup(groupId));

      dispatch(patchUser(requestingUser._id, updatedUser));

      group.members.forEach((memberId) => {
        const notificationData = {
          senderId: user._id,
          receiverId: memberId,
          name: requestingUser.firstName + " " + requestingUser.lastName,
          senderName: user.firstName + " " + user.lastName,
          groupId: group._id,
          groupName: group.name,
          picturePath: group.image,
          timeStamp: Date.now(),
          type: "acceptedJoinRequestGroupMembers",
        };

        socket.emit("sendNotification", notificationData);
      });

      const notificationData = {
        senderId: user._id,
        receiverId: requestingUser._id,
        name: user.firstName + " " + user.lastName,
        groupId: group._id,
        groupName: group.name,
        picturePath: group.image,
        timeStamp: Date.now(),
        type: "acceptedJoinRequest",
      };

      socket.emit("sendNotification", notificationData);
    } else {
      // alert(result.message); // Replace this with your error handling logic
    }
  };

  const handleDecline = async (groupId, requestingUser) => {
    const result = await dispatch(
      declineJoinRequest(groupId, requestingUser._id)
    );
    dispatch(getGroup(groupId));

    if (result.success) {
      const notificationData = {
        senderId: user._id,
        receiverId: requestingUser._id,
        name: user.firstName + " " + user.lastName,
        groupId: group._id,
        groupName: group.name,
        picturePath: group.image,
        timeStamp: Date.now(),
        type: "declinedJoinRequest",
      };

      socket.emit("sendNotification", notificationData);
    } else {
      // alert(result.message); // Replace this with your error handling logic
    }
  };

  // Check if there are no requesting users
  if (requestingUsers.length === 0) {
    return null; // Or return ""; either will work
  }

  return (
    <WidgetWrapper>
      <Typography
        variant="h5"
        sx={{ color: mainSecondaryDark, fontWeight: "700" }}
      >
        Users requesting access to this group
      </Typography>
      <Typography variant="body1">
        Make sure you know the user before approving their request. You can view
        their profile by clicking on their name.
      </Typography>

      {/* horizontal dividing line */}
      <Box
        sx={{
          width: "100%",
          height: "1px",
          backgroundColor: "#e0e0e0",
          margin: "1rem 0",
        }}
      />

      <Box display="flex" flexWrap="wrap" justifyContent="space-between">
        {requestingUsers.map((requestingUser) => (
          <Box
            key={requestingUser._id}
            style={{
              flexBasis: isLargeScreen ? "48%" : "100%",
              marginBottom: "20px",
            }}
          >
            <FlexBetween>
              <Box
                width="100%"
                position="relative"
                style={{
                  height: "100px",
                  overflow: "hidden",
                  borderRadius: "0.5rem",
                }}
                flex={1}
              >
                <img
                  src={requestingUser.picturePath}
                  alt="Profile"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </Box>
              <Box flex={4} sx={{ padding: "0 5px", marginLeft: "5px" }}>
                <Typography
                  variant="h5"
                  sx={{ color: primaryMain }}
                  marginBottom="0.5rem"
                >
                  {requestingUser.firstName} {requestingUser.lastName}
                </Typography>
                <Typography variant="body1">
                  {requestingUser.location.address
                    ? requestingUser.location.address
                    : "This user has not added a location yet"}
                </Typography>
                {/* accept or decline requestingUser */}
                <Box display="flex" gap="1rem" marginTop="1rem">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleAccept(group._id, requestingUser)}
                  >
                    Accept
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleDecline(group._id, requestingUser)}
                  >
                    Decline
                  </Button>
                </Box>
              </Box>
            </FlexBetween>
          </Box>
        ))}
      </Box>
    </WidgetWrapper>
  );
}

export default GroupRequestsToJoin;
