const BASE_URL = process.env.REACT_APP_BASE_URL;

export const createBusinessProfileClick =
  (businessId, fromPage) => async (dispatch, getState) => {
    const token = getState().token;
    try {
      const response = await fetch(`${BASE_URL}/businessProfileClick/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ businessProfileId: businessId, fromPage }),
      });

      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
    }
  };

export const fetchBusinessProfileClicksThisMonthCount =
  (businessId) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      fetch(`${BASE_URL}/businessProfileClick/thisMonthCount`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ businessProfileId: businessId }),
      });
    } catch (error) {
      console.error(error);
    }
  };

export const fetchBusinessProfileClicksLastMonth =
  (businessId) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      fetch(`${BASE_URL}/businessProfileClick/lastMonth`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ businessProfileId: businessId }),
      });
    } catch (error) {
      console.error(error);
    }
  };

export const fetchBusinessProfileClicksDateRange =
  (businessId, startDate, endDate) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      fetch(`${BASE_URL}/businessProfileClick/dateRange`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          businessProfileId: businessId,
          startDate,
          endDate,
        }),
      });
    } catch (error) {
      console.error(error);
    }
  };
