import { useTheme } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";

const UserBioTable = ({ user }) => {
  const { palette } = useTheme();
  const medium = palette.neutral.dark;

  /* BIO TABLE STYLING */

  const tableStylesBio = {
    boxShadow: "none", // Remove drop shadow
    "& thead th": {
      borderBottom: "none", // Remove horizontal lines in header cells
      color: medium,
      paddingTop: "5px",
      paddingBottom: "0",
    },
    "& tbody td": {
      borderBottom: "none", // Remove horizontal lines in body cells
      paddingBottom: "5px",
    },
  };

  const cellStylesBio = {
    borderRight: `1px solid ${medium}`,
    width: "35%",
  };

  const cellStylesBio2 = {
    borderRight: `1px solid ${medium}`,
    width: "25%",
  };

  const cellStylesBio3 = {
    width: "40%",
  };

  const { location, ageRange, createdAt } = user;

  const startDate = moment(createdAt).format("MMM, YYYY");

  return (
    <TableContainer component={Paper} sx={tableStylesBio}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center" style={cellStylesBio}>
              Hometown
            </TableCell>
            <TableCell align="center" style={cellStylesBio2}>
              Age
            </TableCell>
            <TableCell align="center" style={cellStylesBio3}>
              Member Since
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="center" style={cellStylesBio}>
              {location?.town || location?.address}
            </TableCell>
            <TableCell align="center" style={cellStylesBio2}>
              {ageRange}
            </TableCell>
            <TableCell align="center" style={cellStylesBio3}>
              {startDate}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserBioTable;
