import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { activateAccount } from "redux/actions/authActions";
import VerificationExpired from "./verificationExpired";
import VerificationSuccess from "./verificationSuccess";

const ActivatePage = () => {
  const [loading, setLoading] = useState(true);

  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const activation = useSelector((state) => state.activation);
  const activationStatus = activation?.success;
  const activationMessage = activation?.message;

  useEffect(() => {
    dispatch(activateAccount(token))
      .then(() => setLoading(false))
      .catch((err) => {
        setLoading(false);
        console.error("Activation failed:", err);
      });
  }, [token, dispatch]);

  useEffect(() => {
    if (!loading && activationStatus === true) {
      // Uncomment this line if you want to automatically redirect to login page
      // navigate("/login");
    }
  }, [loading, activationStatus, navigate]);

  return (
    <Box textAlign="center" my={5}>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : activationStatus === true ? (
        <VerificationSuccess />
      ) : (
        <VerificationExpired activationMessage={activationMessage} />
      )}
    </Box>
  );
};

export default ActivatePage;
