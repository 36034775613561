import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom"; // Import Link from React Router
import WidgetWrapper from "components/WidgetWrapper";

function VerificationExpired({ activationMessage }) {
  return (
    <WidgetWrapper>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="100vh" // Ensures content is centered vertically
      >
        <Typography variant="h1" color="primary">
          Email verification failed.
        </Typography>
        <img
          width="400px"
          height="auto"
          src="https://imagedelivery.net/wSVPsV14kSq2yM6HcOxqrA/4be28a67-aa94-4a44-8874-370c0a072e00/public"
          alt="verification failed"
          className="verification-image"
        />
        <Typography variant="h5" color="primary">
          {activationMessage === "jwt expired"
            ? "Looks like the verification link has expired. Not to worry, we can send the link again."
            : activationMessage || "Activation failed."}
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          textAlign="center"
          marginTop="20px"
        >
          <Link to="/resend-activation">
            <Button
              variant="contained"
              color="primary"
              sx={{ width: 200, mx: "auto" }}
            >
              Resend Verification Link
            </Button>
          </Link>
          <Typography variant="body2" color="primary" marginTop="50px">
            If you have any questions, please{" "}
            <Link to="/contact-support">
              <Typography variant="span" fontWeight={700}>
                contact support
              </Typography>
            </Link>
            .
          </Typography>
        </Box>
      </Box>
    </WidgetWrapper>
  );
}

export default VerificationExpired;
