import { FavoriteBorderOutlined, FavoriteOutlined } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import React, { Fragment, useState } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";

import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

function ArticlePageLike({
  primary,
  mediumMain,
  handlePatchLike,
  isLiked,
  likeCount,
  handleMouseEnterLikeButton,
  likesUserNames,
  article,
  userId,
  neutralMedium,
  handleOpenReportDialog,
}) {
  const showTooltip = likesUserNames.length > 0;

  // get the website url
  const websiteUrl = window.location.origin;
  // get page url
  const pageUrl = `${websiteUrl}/news/article/${article._id}`;

  const articleTitle = article.title;
  const articleSubTitle = article.subtitle;

  // State to manage the visibility of the share options
  const [shareAnchorEl, setShareAnchorEl] = useState(null);

  // Function to handle clicking the share button
  const handleShareClick = (event) => {
    setShareAnchorEl(event.currentTarget);
  };

  // Function to close the share options popover
  const handleShareClose = () => {
    setShareAnchorEl(null);
  };

  const open = Boolean(shareAnchorEl);
  const id = open ? "simple-popover" : undefined;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReport = () => {
    handleOpenReportDialog("Article", article._id);
    handleClose();
  };

  return (
    <FlexBetween>
      <Box display="flex" flexDirection="column" alignItems="center" width={45}>
        {showTooltip ? (
          <Tooltip
            title={
              <Fragment>
                {likesUserNames.map((name, index) => (
                  <Typography
                    key={index}
                    variant="body2"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {name}
                  </Typography>
                ))}
              </Fragment>
            }
            placement="top"
            arrow
            PopperProps={{
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -10],
                  },
                },
              ],
            }}
          >
            <IconButton
              onClick={handlePatchLike}
              onMouseEnter={handleMouseEnterLikeButton}
            >
              {isLiked ? (
                <FavoriteOutlined sx={{ color: primary, fontSize: 35 }} />
              ) : (
                <FavoriteBorderOutlined sx={{ color: primary, fontSize: 35 }} />
              )}
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton
            onClick={handlePatchLike}
            onMouseEnter={handleMouseEnterLikeButton}
          >
            {isLiked ? (
              <FavoriteOutlined sx={{ color: primary, fontSize: 35 }} />
            ) : (
              <FavoriteBorderOutlined sx={{ color: primary, fontSize: 35 }} />
            )}
          </IconButton>
        )}
        <Typography fontSize={11} marginTop={-1.1} color={mediumMain}>
          {likeCount}
        </Typography>
      </Box>
      <Box display={"flex"} flexDirection={"row"}>
        <IconButton onClick={handleShareClick}>
          <ShareOutlinedIcon />
        </IconButton>
        {/* Popover for sharing options */}
        <Popover
          id={id}
          open={open}
          anchorEl={shareAnchorEl}
          onClose={handleShareClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box p={2}>
            <Typography variant="body2" mb={1}>
              Share on:
            </Typography>
            <FacebookShareButton
              url={pageUrl}
              title={`Check this out on She is Action - ${articleTitle}`}
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton
              url={pageUrl}
              title={`Check this out on She is Action - ${articleTitle}`}
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <LinkedinShareButton
              url={pageUrl}
              title={`Check this out on She is Action - ${articleTitle}`}
              summary={articleSubTitle}
            >
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
          </Box>
        </Popover>
        {userId && (
          <div>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleReport}>
                <Box>
                  <Typography variant="h6">Report Article</Typography>
                  <Typography variant="subtitle2" color={neutralMedium}>
                    We won't tell anyone who reported it
                  </Typography>
                </Box>
              </MenuItem>
              {/* Future menu items can be added here */}
            </Menu>
          </div>
        )}
      </Box>
    </FlexBetween>
  );
}

export default ArticlePageLike;
