import { Avatar, Box, Tooltip, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { markNotificationAsRead } from "redux/actions/notificationActions";

const DisplayNotification = ({
  _id,
  senderId,
  name,
  senderName,
  groupName,
  postId,
  groupId,
  picturePath,
  timeStamp,
  type,
  isRead,
  chatId,
  articleId,
  brandName,
  brandId,
}) => {
  const { palette } = useTheme();
  const dark = palette.neutral.dark;
  const primary = palette.primary.main;

  const navigate = useNavigate();

  const dispatch = useDispatch();

  let notificationType = "";
  let link = "";

  if (type === "likePost") {
    notificationType = "liked your post";
    link = `/post/${postId}`;
  } else if (type === "commentOnPost") {
    notificationType = "commented on your post";
    link = `/post/${postId}`;
  } else if (type === "likeComment") {
    notificationType = "liked your comment";
    link = `/post/${postId}`;
  } else if (type === "mentionedOnPost") {
    notificationType = "mentioned you in a post";
    link = `/post/${postId}`;
  } else if (type === "mentionedOnComment") {
    notificationType = "mentioned you in a comment";
    link = `/post/${postId}`;
  } else if (type === "groupInvite") {
    notificationType = (
      <>
        invited you to the{" "}
        <span
          style={{
            color: palette.primary.main, // Set the color to Primary
            fontWeight: 700, // Set the font weight to 700
          }}
        >
          {groupName}
        </span>{" "}
        group
      </>
    );
    link = "/groups/mygroups";
  } else if (type === "joinGroup") {
    notificationType = (
      <>
        joined the{" "}
        <span
          style={{
            color: palette.primary.main, // Set the color to Primary
            fontWeight: 700, // Set the font weight to 700
          }}
        >
          {groupName}
        </span>{" "}
        group
      </>
    );
    link = `/groups/${groupId}`;
  } else if (type === "joinGroupMembers") {
    notificationType = (
      <>
        joined your{" "}
        <span
          style={{
            color: palette.primary.main, // Set the color to Primary
            fontWeight: 700, // Set the font weight to 700
          }}
        >
          {groupName}
        </span>{" "}
        group
      </>
    );
    link = `/groups/${groupId}`;
  } else if (type === "leftGroup") {
    notificationType = (
      <>
        left the{" "}
        <span
          style={{
            color: palette.primary.main, // Set the color to Primary
            fontWeight: 700, // Set the font weight to 700
          }}
        >
          {groupName}
        </span>{" "}
        group
      </>
    );
    link = `/groups/${groupId}`;
  } else if (type === "leftGroupMembers") {
    notificationType = (
      <>
        left your{" "}
        <span
          style={{
            color: palette.primary.main, // Set the color to Primary
            fontWeight: 700, // Set the font weight to 700
          }}
        >
          {groupName}
        </span>{" "}
        group
      </>
    );
    link = `/groups/${groupId}`;
  } else if (type === "requestToJoinGroup") {
    notificationType = (
      <>
        requested to join the{" "}
        <span
          style={{
            color: palette.primary.main, // Set the color to Primary
            fontWeight: 700, // Set the font weight to 700
          }}
        >
          {groupName}
        </span>{" "}
        group
      </>
    );
    link = `/groups/${groupId}`;
  } else if (type === "declinedJoinRequest") {
    notificationType = (
      <>
        declined your request to join the{" "}
        <span
          style={{
            color: palette.primary.main, // Set the color to Primary
            fontWeight: 700, // Set the font weight to 700
          }}
        >
          {groupName}
        </span>{" "}
        group
      </>
    );
    link = `/groups/${groupId}`;
  } else if (type === "acceptedJoinRequestGroupMembers") {
    notificationType = (
      <>
        accepted by{" "}
        <span
          style={{
            color: palette.primary.main, // Set the color to Primary
            fontWeight: 700, // Set the font weight to 700
          }}
        >
          {senderName}
        </span>{" "}
        request to join the{" "}
        <span
          style={{
            color: palette.primary.main, // Set the color to Primary
            fontWeight: 700, // Set the font weight to 700
          }}
        >
          {groupName}
        </span>{" "}
        group
      </>
    );
    link = `/groups/${groupId}`;
  } else if (type === "acceptedJoinRequest") {
    notificationType = (
      <>
        accepted your request to join the{" "}
        <span
          style={{
            color: palette.primary.main, // Set the color to Primary
            fontWeight: 700, // Set the font weight to 700
          }}
        >
          {groupName}
        </span>{" "}
        group
      </>
    );
    link = `/groups/${groupId}`;
  } else if (type === "inviteToGroup") {
    notificationType = (
      <>
        invited you to the{" "}
        <span
          style={{
            color: palette.primary.main, // Set the color to Primary
            fontWeight: 700, // Set the font weight to 700
          }}
        >
          {groupName}
        </span>{" "}
        group
      </>
    );
    link = "/groups/mygroups";
  } else if (type === "inviteToGroupMembers") {
    notificationType = (
      <>
        invited by{" "}
        <span
          style={{
            color: palette.primary.main, // Set the color to Primary
            fontWeight: 700, // Set the font weight to 700
            cursor: "pointer",
          }}
          onClick={() => navigate(`/profile/${senderId}`)} // Navigate to the sender's profile
        >
          {senderName}
        </span>{" "}
        to the{" "}
        <span
          style={{
            color: palette.primary.main, // Set the color to Primary
            fontWeight: 700, // Set the font weight to 700
            cursor: "pointer",
          }}
          onClick={() => navigate(`/groups/${groupId}`)} // Navigate to the group
        >
          {groupName}
        </span>{" "}
        group
      </>
    );
    link = `/groups/${groupId}`;
  } else if (type === "acceptedInviteToGroup") {
    notificationType = (
      <>
        accepted your invitation to the{" "}
        <span
          style={{
            color: palette.primary.main, // Set the color to Primary
            fontWeight: 700,
          }}
        >
          {groupName}
        </span>{" "}
        group
      </>
    );
    link = `/groups/${groupId}`;
  } else if (type === "declinedInviteToGroup") {
    notificationType = (
      <>
        declined your invitation to the{" "}
        <span
          style={{
            color: palette.primary.main, // Set the color to Primary
            fontWeight: 700,
          }}
        >
          {groupName}
        </span>{" "}
        group
      </>
    );
    link = `/groups/${groupId}`;
  } else if (type === "message") {
    notificationType = "sent you a message";
    link = `/chat`;
  } else if (type === "newFriend") {
    notificationType = "added you as a friend";
    link = `/profile/${senderId}`;
  } else if (type === "newArticle") {
    notificationType = "posted a new article";
    link = `/news/article/${articleId}`;

    // } else if (type === "groupAccept") {
    //   notificationType = "accepted your group request";
    // } else if (type === "groupRemove") {
    //   notificationType = "removed you from the group";
    // } else if (type === "groupPost") {
    //   notificationType = "posted in the group";
    // } else if (type === "groupComment") {
    //   notificationType = "commented in the group";
    // } else if (type === "groupLikePost") {
    //   notificationType = "liked your group post";
    // } else if (type === "groupLikeComment") {
    //   notificationType = "liked your group comment";
    // } else if (type === "groupCommentOnPost") {
    //   notificationType = "commented on your group post";
  }

  const handleClicked = async () => {
    // Mark the notification as read
    await dispatch(markNotificationAsRead(_id));

    navigate(link);
  };

  return (
    <Box onClick={handleClicked} style={{ cursor: "pointer" }}>
      <FlexBetween gap="1rem">
        <Avatar
          src={
            picturePath.endsWith("/public")
              ? picturePath.replace("/public", "/128x128")
              : `${picturePath}/128x128`
          }
          sx={{ width: "40px", height: "40px" }}
          flex={1}
        />
        <Box flex={1}>
          <Typography>
            <span
              style={{
                color: dark,
                fontWeight: 700,
                cursor: "pointer",
              }}
              onClick={handleClicked}
            >
              {brandName ? brandName : name}
            </span>{" "}
            {notificationType}
          </Typography>
          <Tooltip title={moment(timeStamp).format("MMMM Do YYYY, h:mm:ss a")}>
            <Typography style={{ color: primary }}>
              {moment(timeStamp).fromNow()}
            </Typography>
          </Tooltip>
        </Box>
        {isRead ? null : (
          <Box
            m={0.5}
            sx={{
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              backgroundColor: primary,
              alignSelf: "center",
            }}
          />
        )}
      </FlexBetween>
    </Box>
  );
};

export default DisplayNotification;
