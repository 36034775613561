import {
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { patchUser } from "redux/state";

function ProfileQuestionnaire() {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const userId = user._id;
  const userAnswers = useSelector((state) => state.user.questionnaireAnswers);

  const isLargeScreen = useMediaQuery("(min-width: 1000px)");

  const { palette } = useTheme();
  const primary = palette.primary.main;

  // Questions array
  const questions = [
    { key: "whatIWant", question: "What I want to get out of this community?" },
    { key: "whatIOffer", question: "Something I can offer the community?" },
    { key: "adviceAbout", question: "Ask me for advice about:" },
    {
      key: "sportToTry",
      question: "A sport I'd really love to try and why I haven't yet?",
    },
    {
      key: "sportILove",
      question: "A sport I'm terrible at, but love and why?",
    },
    {
      key: "favoriteMemory",
      question: "My favourite sports or adventure memory?",
    },
    { key: "threeThings", question: "3 things you should know about me:" },
    {
      key: "funniestFail",
      question: "Let me tell you about my funniest sporting fail:",
    },
    { key: "hatedSports", question: "Things I hated about sport growing up?" },
    {
      key: "cantGoWithout",
      question: "Something I can't go anywhere without?",
    },
  ];

  const [answers, setAnswers] = useState(
    questions.reduce(
      (acc, { key }) => ({ ...acc, [key]: userAnswers[key] || "" }),
      {}
    )
  );

  // Sync state with userAnswers from the redux store
  useEffect(() => {
    setAnswers(
      questions.reduce(
        (acc, { key }) => ({ ...acc, [key]: userAnswers[key] || "" }),
        {}
      )
    );
  }, [userAnswers]);

  const handleInputChange = (key, event) => {
    const value = event.target.value;
    setAnswers({ ...answers, [key]: value });
  };

  const handleSubmit = async () => {
    const updatedUser = { ...user, questionnaireAnswers: answers };

    try {
      // Use the state directly, as it already contains the updated answers
      await dispatch(patchUser(userId, updatedUser));
    } catch (error) {
      console.error("Error updating user questionnaire answers", error);
    }
  };

  return (
    <Box
      flexBasis={isLargeScreen ? "60%" : undefined}
      px={2}
      pb={isLargeScreen ? 0 : "4rem"}
    >
      <WidgetWrapper>
        <Box
          component="h3"
          sx={{
            fontSize: "1.25rem",
            fontWeight: 500,
            mb: 2,
          }}
        >
          Tell us a bit more about yourself
        </Box>
        <Typography mb={2} fontSize="1rem" color="text.secondary">
          Hey{" "}
          <Typography variant="span" color={primary} fontWeight={600}>
            {user.firstName}
          </Typography>
          , to get the most out of{" "}
          <Typography variant="span" color={primary} fontWeight={600}>
            SHE IS ACTION
          </Typography>
          , why not share a little about yourself? This will help you connect
          with like-minded members and discover tailored content. However, don't
          worry if you're not ready to share just yet — you can always do so at
          a more convenient time.
        </Typography>
        <Typography mb={2} fontSize="1rem" color="text.secondary">
          Simply answer any of the questions below that resonate with you. We
          can't wait to get to know you better!
        </Typography>

        {questions.map(({ key, question }) => (
          <Box key={key} mb={2}>
            <Typography variant="h6" fontWeight={500} mb={1}>
              {question}
            </Typography>
            <TextField
              fullWidth
              multiline
              //   rows={4}
              value={answers[key]}
              onChange={(e) => handleInputChange(key, e)}
            />
          </Box>
        ))}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ mt: 2 }}
        >
          Update Answers
        </Button>
      </WidgetWrapper>
    </Box>
  );
}

export default ProfileQuestionnaire;
