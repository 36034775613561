import { Typography } from "@mui/material";
import moment from "moment";
import React from "react";

const SegmentTitleAndDate = ({ neutralMedium }) => {
  return (
    <>
      <Typography variant="h3" fontWeight="bold" pb={1}>
        Top Stories
      </Typography>
      <Typography variant="h5" color={neutralMedium} fontWeight="600">
        {moment().format("dddd MMMM Do")}
      </Typography>
    </>
  );
};

export default SegmentTitleAndDate;
