import { Box, Typography } from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import React from "react";
import Header from "scenes/admin/components/Header";

function UnauthorisedAccess() {
  return (
    <Box m="1.5rem 2rem 2rem 3rem">
      <WidgetWrapper>
        <Header title="EDIT ARTICLE" subtitle="Edit an existing article" />
        <Typography variant="h4" color="secondary">
          You do not have permission to edit this article.
        </Typography>
      </WidgetWrapper>
    </Box>
  );
}

export default UnauthorisedAccess;
