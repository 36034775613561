import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import Header from "../components/Header";
import GroupGrid from "./GroupGrid";

function AdminGroups() {
  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  return (
    <Box
      m={isLargeScreen ? "1.5rem 2rem 2rem 3rem" : "1.5rem 0.5rem 2rem 0.5rem"}
    >
      <Header title="GROUPS" subtitle="Manage Groups" />
      <GroupGrid />
    </Box>
  );
}

export default AdminGroups;
