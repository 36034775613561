import React from "react";
import { Box, FormHelperText } from "@mui/material";
import Header from "scenes/admin/components/Header";
import { handleImageUpload } from "redux/actions/imageUploadUtils";
import ArticlePreview from "../../BrandPortal/createArticle/articlePreview";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import Navbar from "scenes/navbar";
import BottomMenu from "components/bottomMenu/BottomMenu";
import Footer from "components/Footer";
import { useArticleEditState } from "./hooks/useArticleEditState";
import UpdateArchiveButtons from "./components/UpdateArchiveButtons";
import CategorySelect from "./components/CategorySelect";
import SportSelect from "./components/SportSelect";
import TitleAndSubTitle from "./components/TitleAndSubTitle";
import TitleImageDropZone from "./components/TitleImageDropZone";
import FormActionButtons from "./components/FormActionButtons";
import UnauthorisedAccess from "./components/UnauthorisedAccess";
import TagInput from "./components/TagInput";
import ContentBlocks from "./components/ContentBlocks";

function ArticleEdit({ socket }) {
  const {
    title,
    setTitle,
    subtitle,
    setSubtitle,
    titleImage,
    brandId,
    contentBlocks,
    selectedCategory,
    setSelectedCategory,
    selectedSport,
    setSelectedSport,
    isLargeScreen,
    categories,
    sportsList,
    article,
    isBrandUser,
    handleUpdateArticle,
    handleArchiveArticle,
    handleTitleImageUpload,
    addTextBlock,
    addImageBlock,
    updateBlockData,
    removeBlock,
    modules,
    formats,
    tags,
    tagsList,
    newTagName,
    setNewTagName,
    handleAddTag,
    handleRemoveTag,
    handleSportChange,
    formErrors,
  } = useArticleEditState();

  return isBrandUser ? (
    <>
      <Navbar />
      <Box m="1.5rem auto 2rem auto" maxWidth={1000}>
        <WidgetWrapper>
          <Header title="EDIT ARTICLE" subtitle="Edit an existing article" />
          <UpdateArchiveButtons
            handleUpdateArticle={handleUpdateArticle}
            handleArchiveArticle={handleArchiveArticle}
          />
          <FlexBetween>
            <CategorySelect
              categories={categories}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              error={formErrors.selectedCategory}
            />

            <SportSelect
              sportsList={sportsList}
              selectedSport={selectedSport}
              setSelectedSport={setSelectedSport}
              handleSportChange={handleSportChange}
              error={formErrors.selectedSport}
            />
          </FlexBetween>
          <TitleAndSubTitle
            title={title}
            setTitle={setTitle}
            subtitle={subtitle}
            setSubtitle={setSubtitle}
            formErrors={formErrors}
          />
          <TitleImageDropZone
            handleTitleImageUpload={handleTitleImageUpload}
            titleImage={titleImage}
            defaultImageURL={article.titleImage}
            error={formErrors.titleImage}
          />

          <ContentBlocks
            contentBlocks={contentBlocks}
            removeBlock={removeBlock}
            updateBlockData={updateBlockData}
            handleImageUpload={handleImageUpload}
            modules={modules}
            formats={formats}
          />
          {contentBlocks.length === 0 && formErrors.contentBlocks && (
            <FormHelperText error style={{ margin: "4px 0" }}>
              {formErrors.contentBlocks}
            </FormHelperText>
          )}

          <TagInput
            tags={tags}
            newTagName={newTagName}
            setNewTagName={setNewTagName}
            handleAddTag={handleAddTag}
            handleRemoveTag={handleRemoveTag}
            tagsList={tagsList}
            error={formErrors.tags}
          />
          <FormActionButtons
            addTextBlock={addTextBlock}
            addImageBlock={addImageBlock}
            handleCreateArticle={handleUpdateArticle}
          />
        </WidgetWrapper>

        <ArticlePreview
          title={title}
          subtitle={subtitle}
          contentBlocks={contentBlocks}
          titleImage={titleImage}
          firstName={article.firstName}
          lastName={article.lastName}
          published_at={article.published_at}
          category={selectedCategory}
          selectedBrandId={brandId}
        />
      </Box>
      <Footer />
      {!isLargeScreen && <BottomMenu socket={socket} />}
    </>
  ) : (
    <UnauthorisedAccess />
  );
}

export default ArticleEdit;
