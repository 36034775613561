import { Box, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import Navbar from "scenes/navbar";
import SideDrawer from "./components/miscellaneous/SideDrawer";
import MyChats from "./components/MyChats";
import ChatBox from "./components/ChatBox";
import Footer from "components/Footer";
import BottomMenu from "components/bottomMenu/BottomMenu";

function ChatPage({ socket }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState();
  const [selectedChat, setSelectedChat] = useState(null);
  const [fetchAgain, setFetchAgain] = useState(false);
  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  return (
    <>
      <Navbar socket={socket} />
      <Box sx={{ width: "100%" }}>
        <SideDrawer
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          setSelectedChat={setSelectedChat}
        />
        <Box
          display="flex"
          justifyContent="space-between"
          // minHeight="400px"
          minHeight={
            isLargeScreen ? "calc(100vh - 64px)" : "calc(100vh - 56px)"
          }
          padding="0.5rem"
          width="100%"
        >
          <MyChats
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
            selectedChat={selectedChat}
            setSelectedChat={setSelectedChat}
            fetchAgain={fetchAgain}
            socket={socket}
          />
          <ChatBox
            selectedChat={selectedChat}
            setSelectedChat={setSelectedChat}
            fetchAgain={fetchAgain}
            setFetchAgain={setFetchAgain}
            socket={socket}
          />
        </Box>
      </Box>
      <Footer />
      {!isLargeScreen && <BottomMenu />}
    </>
  );
}

export default ChatPage;
