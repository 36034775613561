import { Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import { useSelector } from "react-redux";

const Welcome = () => {
  const { palette } = useTheme();
  const neutralDark = palette.neutral.dark;
  const primaryMain = palette.primary.main;

  const user = useSelector((state) => state.user);

  return (
    <WidgetWrapper>
      <FlexBetween>
        <Typography color={neutralDark} variant="h3" fontWeight={700}>
          Welcome Back,&nbsp;
          <span style={{ color: primaryMain }}>{user.firstName}</span>
        </Typography>
      </FlexBetween>
    </WidgetWrapper>
  );
};

export default Welcome;
