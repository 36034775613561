import React, { useCallback, useEffect, useState } from "react";
import home from "../../assets/light/home.png";
import homeDark from "../../assets/dark/home.png";
import homeActive from "../../assets/light/home_active.png";
import news from "../../assets/light/news.png";
import newsDark from "../../assets/dark/news.png";
import newsActive from "../../assets/light/news_active.png";
import findMembers from "../../assets/light/searchUsers.png";
import findMembersDark from "../../assets/dark/searchUsers.png";
import findMembersActive from "../../assets/light/searchUsers_active.png";
import messages from "../../assets/light/messages.png";
import messagesDark from "../../assets/dark/messages.png";
import messagesActive from "../../assets/light/messages_active.png";
import { Link, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material";
import { useSelector } from "react-redux";

export default function BottomMenu() {
  const location = useLocation();
  const theme = useTheme();

  const backgroundColor = theme.palette.background.default;
  const darkTextColor = theme.palette.neutral.dark;
  const primary = theme.palette.primary.main;

  const unreadMessagesCount = useSelector((state) => state.unreadMessagesCount);

  const mode = useSelector((state) => state.mode);

  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlVisibility = useCallback(() => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY && window.scrollY > 50) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
      setLastScrollY(window.scrollY);
    }
  }, [lastScrollY]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlVisibility);

      return () => {
        window.removeEventListener("scroll", controlVisibility);
      };
    }
  }, [controlVisibility]);

  const linkStyle = (isActive) => ({
    borderRadius: "100px",
    backgroundColor: isActive ? "#BB2649" : "transparent",
    color: isActive ? "white" : darkTextColor,
    textAlign: "center",
    display: "flex",
    flex: "1",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    padding: "5px 0",
  });

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "transparent",
        zIndex: "100",
        position: "fixed",
        bottom: isVisible ? "0" : "-100px", // Adjust this value based on the height of your menu
        padding: "0 20px",
        transition: "bottom 0.3s ease-in-out", // Smooth transition for the bottom property
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: mode === "light" ? backgroundColor : "#1C1F2D",
          borderRadius: "100px",
          margin: "10px 10px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.5)", // This is the line added for drop shadow
        }}
      >
        <Link to="/home" style={linkStyle(location.pathname === "/home")}>
          <div>
            <img
              src={
                location.pathname === "/home"
                  ? homeActive
                  : mode === "light"
                  ? home
                  : homeDark
              }
              alt="Home Icon"
              style={{ width: "35px", height: "35px" }}
            />
            <div
              style={{
                fontSize: "10px",
                color: linkStyle(location.pathname === "/home").color,
              }}
            >
              Home
            </div>
          </div>
        </Link>
        <Link to="/news" style={linkStyle(location.pathname === "/news")}>
          <div>
            <img
              src={
                location.pathname === "/news"
                  ? newsActive
                  : mode === "light"
                  ? news
                  : newsDark
              }
              alt="News Icon"
              style={{ width: "35px", height: "35px" }}
            />
            <div
              style={{
                fontSize: "10px",
                color: linkStyle(location.pathname === "/news").color,
              }}
            >
              News
            </div>
          </div>
        </Link>
        <Link
          to="/findMembers"
          style={linkStyle(location.pathname === "/findMembers")}
        >
          <div>
            <img
              src={
                location.pathname === "/findMembers"
                  ? findMembersActive
                  : mode === "light"
                  ? findMembers
                  : findMembersDark
              }
              alt="Members Icon"
              style={{ width: "35px", height: "35px" }}
            />
            <div
              style={{
                fontSize: "10px",
                color: linkStyle(location.pathname === "/findMembers").color,
              }}
            >
              Browse
            </div>
          </div>
        </Link>
        <Link to="/chat" style={linkStyle(location.pathname === "/chat")}>
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "6px",
            }}
          >
            <img
              src={
                location.pathname === "/chat"
                  ? messagesActive
                  : mode === "light"
                  ? messages
                  : messagesDark
              }
              alt="Messages Icon"
              style={{ width: "35px", height: "35px" }}
            />
            <div
              style={{
                fontSize: "10px",
                color: linkStyle(location.pathname === "/chat").color,
              }}
            >
              Chat
            </div>
            {unreadMessagesCount > 0 && (
              <div
                style={{
                  position: "absolute",
                  top: "-4px", // Adjust these values as needed
                  right: "-8px", // Adjust these values as needed
                  backgroundColor:
                    location.pathname === "/chat" ? primary : "red",
                  color: "white",
                  borderRadius: "50%",
                  padding: "0 5px",
                  fontSize: "12px",
                  minWidth: "20px", // Ensure the badge is circular even for single-digit numbers
                  height: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {unreadMessagesCount}
              </div>
            )}
          </div>
        </Link>
      </div>
    </div>
  );
}
