import React from "react";
import { Modal, Box, Typography, useTheme } from "@mui/material";
import { getSender } from "scenes/chatPage/config/ChatLogic";
import AvatarRoleIcons from "components/avatarRoleIcons";

const UserDetailsModal = ({ open, handleClose, chat, user }) => {
  const { palette } = useTheme();
  const primary = palette.primary.main;

  const sender = chat ? getSender(user, chat.users) : {};

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: `2px solid ${primary}`,
          boxShadow: 24,
          p: 4,
          borderRadius: "10px",
        }}
      >
        <Typography
          variant="h4"
          component="h2"
          color={primary}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box mr={0.5}>
            {sender.firstName} {sender.lastName}
          </Box>
          {!chat?.isGroupChat && (
            <AvatarRoleIcons user={sender} avatarSize="22px" />
          )}
        </Typography>
        <Box
          component="img"
          src={sender.picturePath}
          alt={`${sender.firstName} ${sender.lastName}`}
          sx={{
            width: "100%",
            maxHeight: "200px",
            objectFit: "cover",
            mt: 2,
            borderRadius: "10px",
          }}
        />
        {/* Uncomment if location details are needed */}
        {/* <Typography variant="body1" mt={2}>
          Location: {sender.location?.town}, {sender.location?.country}
        </Typography> */}
      </Box>
    </Modal>
  );
};

export default UserDetailsModal;
