import { Autocomplete, FormControl, TextField } from "@mui/material";
import React from "react";

function SportSelect({ sportsList, selectedSport, handleSportChange, error }) {
  return (
    <FormControl
      variant="outlined"
      fullWidth
      margin="normal"
      style={{ marginTop: "5px" }}
      error={Boolean(error)}
    >
      <Autocomplete
        multiple
        options={sportsList.map((s) => s.name)}
        value={selectedSport.map((s) => s.name)} // Map the selectedSport objects to an array of names
        onChange={(event, newValue) => {
          handleSportChange(newValue); // newValue will be an array of sport names
        }}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Sport"
            fullWidth
            margin="normal"
            error={Boolean(error)}
            helperText={error}
          />
        )}
      />
    </FormControl>
  );
}

export default SportSelect;
