import { ImageOutlined } from "@mui/icons-material";
import { Divider, Typography, Button, Avatar, Box } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import SnackbarComponent from "components/SnackbarComponent.jsx";
import UserTagSuggestionComponent from "./components/UserTagSuggestionComponent.jsx";
import usePostWidget from "./hooks/usePostWidget.js";
import ImageUploadComponent from "./components/ImageUploadComponent.jsx";
import React, { useEffect } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import "../../../styles/index.css";

const MyPostWidget = ({ picturePath, groupId, socket, isProfile = false }) => {
  const {
    hover,
    setHover,
    hoverTarget,
    hoverForNewFriend,
    setHoverForNewFriend,
    hoverForNewFriendIndex,
    hoverAddFriend,
    isImage,
    setIsImage,
    image,
    setImage,
    post,
    showHandleSuggestion,
    loading,
    snackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    setSnackbarOpen,
    displayUser,
    handlePost,
    handleSelectMentionUser,
    addFriend,
    neutralLight,
    hoverTargetUser,
    loggedInUser,
    primary,
    primaryLight,
    primaryDark,
    mediumMain,
    neutralMedium,
    handleQuillChange,
    quillRef,
  } = usePostWidget({ socket, groupId, isProfile });

  useEffect(() => {
    if (quillRef.current && !quillRef.current.editor) {
      quillRef.current.editor = new Quill(quillRef.current, {
        theme: "snow",
        modules: {
          toolbar: false,
        },
        placeholder: "What's on your mind...",
      });

      quillRef.current.editor.on("text-change", () => {
        handleQuillChange(quillRef.current.editor.root.innerHTML);
      });

      quillRef.current.editor.root.innerHTML = post;
    }
  }, [post, handleQuillChange, quillRef]);

  return (
    <>
      <WidgetWrapper>
        <Box display="flex" alignItems="center" gap="1.5rem">
          <Avatar
            src={
              picturePath.endsWith("/public")
                ? picturePath.replace("/public", "/128x128")
                : `${picturePath}/128x128`
            }
            sx={{ width: "60px", height: "60px" }}
          />
          <Box
            flexGrow={1}
            sx={{
              borderRadius: "2rem",
              overflow: "hidden",
              border: "1px solid #ccc",
            }}
          >
            <div
              ref={quillRef}
              className="myPostWidgetQuill"
              style={{
                border: "none",
              }}
            />
          </Box>
        </Box>

        {isImage && (
          <ImageUploadComponent
            medium={neutralMedium}
            setImage={setImage}
            image={image}
            primary={primary}
          />
        )}

        {showHandleSuggestion && (
          <UserTagSuggestionComponent
            displayUser={displayUser}
            hover={hover}
            hoverTarget={hoverTarget}
            hoverForNewFriend={hoverForNewFriend}
            hoverForNewFriendIndex={hoverForNewFriendIndex}
            hoverAddFriend={hoverAddFriend}
            setHover={setHover}
            setHoverForNewFriend={setHoverForNewFriend}
            hoverTargetUser={hoverTargetUser}
            handleSelectMentionUser={handleSelectMentionUser}
            loggedInUser={loggedInUser}
            addFriend={addFriend}
            primaryLight={primaryLight}
            primaryDark={primaryDark}
            mediumMain={mediumMain}
            neutralLight={neutralLight}
          />
        )}
        <Divider sx={{ margin: "1.25rem 0" }} />

        <FlexBetween>
          <FlexBetween gap="0.25rem" onClick={() => setIsImage(!isImage)}>
            <ImageOutlined sx={{ color: mediumMain }} />
            <Typography
              color={mediumMain}
              sx={{ "&:hover": { cursor: "pointer", color: neutralMedium } }}
            >
              Image
            </Typography>
          </FlexBetween>

          <Button
            disabled={loading}
            onClick={handlePost}
            sx={{
              borderRadius: "3rem",
            }}
            type="submit"
            variant="contained"
            color="primary"
          >
            {loading ? "...loading" : "POST"}
          </Button>
        </FlexBetween>
      </WidgetWrapper>
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default MyPostWidget;
