import { Box, FormControl, Typography } from "@mui/material";
import React, { useRef, useEffect } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";

function BrandDescriptionEdit({ brandData, setBrandData, formErrors }) {
  const quillRef = useRef(null);
  const containerRef = useRef(null);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline"],
      [{ list: "bullet" }],
      [{ color: [] }],
      [{ align: [] }],
      ["clean"],
      ["link"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "align",
  ];

  useEffect(() => {
    if (containerRef.current && !quillRef.current) {
      quillRef.current = new Quill(containerRef.current, {
        theme: "snow",
        modules: modules,
        formats: formats,
        placeholder: "Description",
      });

      quillRef.current.on("text-change", () => {
        const content = quillRef.current.root.innerHTML;
        setBrandData((prevBrandData) => ({
          ...prevBrandData,
          description: content,
        }));
      });

      quillRef.current.root.innerHTML = brandData.description;
    }
  }, [modules, formats, setBrandData, brandData.description]);

  return (
    <Box my={2}>
      <Typography variant="h5">Description</Typography>
      <FormControl margin="dense" fullWidth variant="outlined">
        <div id="description-input" ref={containerRef} />
        {formErrors.description && (
          <p
            style={{
              color: "#d74343",
              fontSize: "10px",
              marginLeft: "1rem",
              fontWeight: 500,
              marginTop: "3px",
            }}
          >
            {formErrors.description}
          </p>
        )}
      </FormControl>
    </Box>
  );
}

export default BrandDescriptionEdit;
