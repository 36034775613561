import { Box, Typography, useMediaQuery } from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { fetchAllBrandArticles } from "redux/actions/articleActions";
import BrandArticleCard from "scenes/BrandPage/components/BrandArticleCard";
import Header from "scenes/admin/components/Header";

function BrandPreviousArticles({ socket }) {
  // eslint-disable-next-line no-unused-vars
  const { selectedBrandId } = useOutletContext();
  const dispatch = useDispatch();
  const allBrandArticles = useSelector((state) => state.allBrandArticles);

  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  useEffect(() => {
    if (selectedBrandId) {
      // fetch articles
      dispatch(fetchAllBrandArticles(selectedBrandId));
    }
  }, [dispatch, selectedBrandId]);

  return (
    <Box
      m={isLargeScreen ? "1.5rem 2rem 2rem 3rem" : "0.5rem 0 1rem 0"}
      pb={isLargeScreen ? 0 : "4rem"}
    >
      <WidgetWrapper>
        <Header
          title="PREVIOUS ARTICLES"
          subtitle="View and update your articles"
        />
        <Box
          display="grid"
          gridTemplateColumns="repeat(3, minmax(0,1fr))"
          justifyContent="space-between"
          rowGap="20px"
          columnGap="1.33%"
          mt="1.5rem"
          sx={{
            "& > div": {
              gridColumn: isLargeScreen ? undefined : "span 3",
            },
          }}
        >
          {allBrandArticles && allBrandArticles.length > 0 ? (
            allBrandArticles.map((article) => (
              <BrandArticleCard
                article={article}
                key={article._id}
                isBrandAdmin={true}
                socket={socket}
              />
            ))
          ) : (
            <Box height="100%">
              <Box>
                <Typography variant="h3" color="textSecondary">
                  No articles yet
                </Typography>
                <Typography variant="h6" color="textSecondary">
                  Once you publish an article, it will appear here
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </WidgetWrapper>
    </Box>
  );
}

export default BrandPreviousArticles;
