import React, { useEffect, useMemo } from "react";
import WidgetWrapper from "components/WidgetWrapper";
import { Box, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { fetchUser } from "redux/actions/userActions";
import { Link } from "react-router-dom";

function toProperCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function GroupPrivacy({ groupId, isMember, isInvited, isSheIsActionAdmin }) {
  const { palette } = useTheme();
  const main = palette.primary.main;
  const mainNeutral = palette.neutral.main;
  const [adminUsers, setAdminUsers] = React.useState([]);
  const dispatch = useDispatch();
  // Get group data from redux
  const group = useSelector((state) => state.group);

  const groupAdministrators = useMemo(() => {
    return group.administrators || [];
  }, [group.administrators]);

  useEffect(() => {
    const getAdminUsers = async () => {
      let tempAdminUsers = [];
      for (let adminId of groupAdministrators) {
        const adminDetails = await dispatch(fetchUser(adminId));
        tempAdminUsers.push(adminDetails);
      }
      setAdminUsers(tempAdminUsers);
    };
    getAdminUsers();
  }, [groupAdministrators, dispatch]);

  // Destructuring the group details
  const { isPrivate = null, isVisible = null } = group;

  const visibility = isVisible ? "visible" : "hidden";

  const PrivacySetting = ({ icon: Icon, title, description }) => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        color: mainNeutral,
      }}
    >
      <Icon fontSize="large" />
      <Box>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body1">{description}</Typography>
      </Box>
    </Box>
  );

  return (
    <>
      {/* ABOUT and PRIVACY SETTINGS */}
      <WidgetWrapper>
        <Box width="100%">
          <Typography variant="h5" sx={{ color: main }}>
            Description
          </Typography>
        </Box>
        <Box width="100%">
          <Typography variant="h6" sx={{ color: mainNeutral }}>
            {!isPrivate ||
            isMember ||
            isSheIsActionAdmin ||
            (isPrivate && isInvited) ? (
              group.description
            ) : (
              <i>Only members can see the group's description.</i>
            )}
          </Typography>
          <Typography variant="h5" sx={{ color: main }} paddingTop="15px">
            Group Adminstrators
          </Typography>
          {adminUsers.map((adminUser) => (
            <Box key={adminUser._id}>
              <Typography variant="body1" sx={{ color: mainNeutral }}>
                <Link to={`/profile/${adminUser._id}`}>
                  {adminUser.firstName} {adminUser.lastName}
                </Link>
              </Typography>
            </Box>
          ))}

          {/* Privacy statement */}

          <Typography variant="h5" marginTop="15px" sx={{ color: main }}>
            Privacy Settings
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              alignItems: "flex-start",
              marginTop: "5px",
            }}
          >
            {isPrivate ? (
              <>
                <PrivacySetting
                  icon={HttpsOutlinedIcon}
                  title="Private"
                  description="Only members can see the group's name, description, and posts."
                />
                <PrivacySetting
                  icon={
                    isVisible
                      ? VisibilityOutlinedIcon
                      : VisibilityOffOutlinedIcon
                  }
                  title={toProperCase(visibility)}
                  description={`${
                    isVisible ? "Anyone" : "Only members"
                  } can find this group.`}
                />
              </>
            ) : (
              <>
                <PrivacySetting
                  icon={PublicOutlinedIcon}
                  title="Public"
                  description="This is a public group. Anyone can see the group's name, description, and posts."
                />
                <PrivacySetting
                  icon={VisibilityOutlinedIcon}
                  title="Visible"
                  description="Anyone can find this group."
                />
              </>
            )}
          </Box>
        </Box>
      </WidgetWrapper>
    </>
  );
}

export default GroupPrivacy;
