import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, useTheme } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import { fecthSportCount } from "redux/actions/dashboardUserActions";

function UserSportsBreakdownChart({ isDashboard = false }) {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const primary = palette.primary.main;
  const secondary = palette.secondary.main;
  const primaryDark = palette.primary.dark;
  const secondaryDark = palette.secondary.dark;
  const primaryLight = palette.primary.light;
  const secondaryLight = palette.secondary.light;

  const colors = [
    primary,
    secondary,
    primaryDark,
    secondaryDark,
    primaryLight,
    secondaryLight,
  ];

  const userSports = useSelector(
    (state) => state.dashboardAdminUser.sportsCount || []
  );

  useEffect(() => {
    dispatch(fecthSportCount());
  }, [dispatch]);

  const chartData = useMemo(() => {
    return userSports.map((sport, index) => {
      return {
        id: sport._id,
        label: sport._id,
        value: sport.count,
        color: colors[index % colors.length], // Cycle through colors for each sport
      };
    });
  }, [userSports, colors]);

  return (
    <Box
      height={isDashboard ? "375px" : "100%"}
      width={undefined}
      minHeight={isDashboard ? "325px" : undefined}
      minWidth={isDashboard ? "325px" : undefined}
      position="relative"
    >
      <ResponsivePie
        data={chartData}
        margin={{ top: 20, right: 30, bottom: 30, left: 30 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        colors={(datum) => datum.data.color}
        borderWidth={1}
        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
    </Box>
  );
}

export default UserSportsBreakdownChart;
