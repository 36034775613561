import React from "react";
import FlexBetween from "./FlexBetween";
import { Link } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { toggleFollowBrand } from "redux/actions/brandActions";

// Helper function to strip HTML tags and truncate text
const stripHtmlAndTruncate = (htmlString, maxLength) => {
  // Create a new div element
  const tempDiv = document.createElement("div");
  // Set the HTML content with the provided HTML string
  tempDiv.innerHTML = htmlString;
  // Retrieve the text content of the div
  const strippedStr = tempDiv.textContent || tempDiv.innerText || "";
  // Truncate the string if it exceeds the maximum length
  if (strippedStr.length > maxLength) {
    return strippedStr.substring(0, maxLength) + "...";
  }
  return strippedStr;
};

function FollowedBrand({ brand, followedBrandIds }) {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const primaryDark = palette.primary.dark;
  const main = palette.neutral.main;

  const handleFollowToggle = (brandId) => {
    dispatch(toggleFollowBrand(brandId, true));
  };

  const description = stripHtmlAndTruncate(brand.description, 200);

  return (
    <FlexBetween key={brand._id} alignItems="center">
      <Tooltip
        title={description}
        placement="top-start"
        arrow
        enterDelay={500}
        leaveDelay={200}
      >
        <Link to={`/brand/${brand._id}`}>
          <Avatar
            src={brand.picturePath}
            sx={{ width: "40px", height: "40px" }}
          />
        </Link>
      </Tooltip>

      <Box
        flex="1"
        mx="1rem"
        overflow="hidden"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        sx={{ minWidth: 0 }} // This ensures the Box shrinks as needed
      >
        <Link to={`/brand/${brand._id}`} style={{ textDecoration: "none" }}>
          <Typography
            color={primaryDark}
            variant="h6"
            fontWeight="500"
            sx={{
              "&:hover": {
                color: palette.primary.light,
                cursor: "pointer",
              },
              wordWrap: "break-word", // Allow wrapping
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "normal", // Allow text to wrap
            }}
          >
            {brand.name}
          </Typography>
        </Link>
        <Typography
          color={main}
          fontSize="0.75rem"
          sx={{
            wordWrap: "break-word", // Allow wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "normal", // Allow text to wrap
          }}
        >
          {brand.locations[0].town
            ? brand.locations[0].town
            : brand.locations[0].country
            ? brand.locations[0].country
            : brand.locations[0].address}{" "}
          - {brand.sportsPromoted[0]}
        </Typography>
      </Box>

      <Tooltip
        title={
          followedBrandIds.has(brand._id)
            ? `Unfollow ${brand.name}`
            : `Follow ${brand.name}`
        }
        placement="top"
        arrow
      >
        <Button
          onClick={() => handleFollowToggle(brand._id)}
          variant={followedBrandIds.has(brand._id) ? "outlined" : "contained"}
          color={followedBrandIds.has(brand._id) ? "primary" : "secondary"}
          sx={{
            borderRadius: "1rem",
            padding: "0.125rem 0.5rem",
            fontSize: "9px",
            whiteSpace: "nowrap",
          }}
        >
          {followedBrandIds.has(brand._id) ? "Following" : "Follow"}
        </Button>
      </Tooltip>
    </FlexBetween>
  );
}

export default FollowedBrand;
