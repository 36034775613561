import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import DataGridCustomToolbar from "../../components/DataGridCustomToolbar";

function RolesGrid({ rolesWithCount, columns, backgroundAlt, primaryLight }) {
  return (
    <Box
      mt="40px"
      height="75vh"
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: backgroundAlt,
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: backgroundAlt,
        },
        "& .MuiDataGrid-footerContainer": {
          backgroundColor: primaryLight,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {},
      }}
    >
      <DataGrid
        getRowId={(row) => {
          return row._id;
        }}
        rows={rolesWithCount}
        columns={columns}
        autoHeight={true}
        components={{
          Toolbar: DataGridCustomToolbar,
        }}
        componentsProps={{
          toolbar: {
            showSearchBar: false, // This will hide the search bar in the toolbar
            searchPlaceholder: "Search roles...",
          },
        }}
      />
    </Box>
  );
}

export default RolesGrid;
