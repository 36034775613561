import { Box, Button, Typography } from "@mui/material";
import FlexBetween from "components/FlexBetween";

const FormBtns = (props) => {
  const {
    palette,
    values,
    isLogin,
    resetForm,
    setPageType,
    error,
    errorMessage,
    pageType,
    isLoading,
  } = props;

  // Disable condition for the button
  const disableButton = isLogin
    ? isLoading || !values.email || !values.password
    : isLoading;

  return (
    <>
      <Button
        fullWidth
        type="submit"
        disabled={disableButton}
        sx={{
          m: "2rem 0",
          p: "1rem",
          backgroundColor: disableButton
            ? palette.primary.light
            : palette.primary.main,
          color: palette.background.alt,
          "&:hover": {
            backgroundColor: disableButton
              ? palette.primary.light
              : palette.primary.main,
            color: palette.primary.main,
          },
        }}
      >
        {isLogin || pageType === "forgotPassword" ? "LOGIN" : "REGISTER"}
      </Button>
      <FlexBetween>
        <Typography
          onClick={() => {
            setPageType(isLogin ? "register" : "login");
            resetForm();
          }}
          sx={{
            textDecoration: "underline",
            color: palette.primary.main,
            "&:hover": {
              cursor: "pointer",
              color: palette.primary.light,
            },
          }}
        >
          {isLogin || pageType === "forgotPassword"
            ? "Don't have an account? Sign Up here."
            : "Already have an account? Login here."}
        </Typography>

        {isLogin && pageType !== "forgotPassword" && (
          <Typography
            onClick={() => {
              setPageType("forgotPassword");
              resetForm();
            }}
            sx={{
              textDecoration: "underline",
              color: palette.primary.main,
              "&:hover": {
                cursor: "pointer",
                color: palette.primary.light,
              },
            }}
          >
            Forgot Password?
          </Typography>
        )}
      </FlexBetween>
      <Box
        data-testid="error-message"
        style={{ visibility: error ? "visible" : "hidden" }}
      >
        <Typography
          sx={{
            color: "red",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {error ? errorMessage : ""}
        </Typography>
      </Box>
    </>
  );
};

export default FormBtns;
