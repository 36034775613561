import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createFlag } from "redux/actions/flagActions";
import {
  blockUser,
  fetchDistanceBetweenUsers,
  softBlockUser,
} from "redux/actions/userActions";
import { fetchUser, patchFriend, setMode } from "redux/state";
import * as yup from "yup";

export const useUserWidgetState = ({ userId, socket }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery("(min-width: 1000px)");
  const currentUser = useSelector((state) => state.user) || {};
  const currentUserId = currentUser._id;
  const currentUserPicturePath = currentUser.picturePath;
  const currentUserFirstName = currentUser.firstName;
  const currentUserLastName = currentUser.lastName;

  const user = useSelector((state) => state.viewingUser || state.user);

  const isNonUser = user?._id;

  const isAdmin =
    currentUser?.roles?.some(
      (role) => role.name && role.name.toLowerCase() === "admin"
    ) || false;
  const milesBetweenUsers = useSelector(
    (state) => state.distanceBetweenUsers?.distanceInMiles || 0
  );
  const kilometersBetweenUsers = useSelector(
    (state) => state.distanceBetweenUsers?.distanceInKilometers || 0
  );

  const { palette } = useTheme();
  const dark = palette.neutral.dark;
  const medium = palette.neutral.medium;
  const primaryMain = palette.primary.main;
  const primaryLight = palette.primary.light;
  const primaryDark = palette.primary.dark;
  const neutralLight = palette.neutral.light;
  const neutralMedium = palette.neutral.medium;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  const [anchorEl, setAnchorEl] = useState(null);

  const [formData, setFormData] = useState({
    contentId: "",
    reason: "",
    flaggerId: currentUserId,
    details: "",
    contentType: "",
  });

  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    dispatch(fetchUser(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (!user._id || !currentUserId) {
      return;
    }
    const fetchData = async () => {
      try {
        if (user && currentUserId && user._id !== currentUserId) {
          await dispatch(fetchDistanceBetweenUsers(user._id, currentUserId));
        }
      } catch (error) {
        console.error("Error fetching distance between users:", error);
      }
    };

    fetchData();
  }, [dispatch, user, currentUserId]); // Ensure these dependencies are correctly triggering the effect

  if (!user) {
    return null;
  }

  const handleModeChange = () => {
    dispatch(setMode());
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const {
    firstName,
    lastName,
    sports,
    friends = [],
    picturePath,
    aboutMe,
    questionnaireAnswers,
  } = user;

  const isFriend = friends.includes(currentUserId);

  const handlePatchFriend = async () => {
    try {
      await dispatch(patchFriend(userId, currentUserId, true));

      // Update the snackbar message and severity based on the friend status
      if (isFriend) {
        setSnackbarMessage("Friend removed");
        setSnackbarSeverity("info");
      } else {
        const notificationData = {
          senderId: currentUserId,
          receiverId: userId,
          name: currentUserFirstName + " " + currentUserLastName,
          picturePath: currentUserPicturePath,
          type: "newFriend",
          timestamp: new Date(),
        };

        socket.emit("sendNotification", notificationData);
        setSnackbarMessage("Friend added");
        setSnackbarSeverity("success");
      }
      // Open the snackbar
      setSnackbarOpen(true);
    } catch (error) {
      // Handle any errors here
      setSnackbarMessage("An error occurred");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  /* Post, Post Comment and User Reporting */

  const validationReportSchema = yup.object().shape({
    reason: yup.string().required("Reason is required"),
    details: yup.string().required("Description is required"),
  });

  const handleOpenReportDialog = (contentType, contentId) => {
    setFormData({
      ...formData,
      contentId: contentId,
      contentType: contentType,
    });
    setOpenReportDialog(true);
  };

  const handleCloseReportDialog = () => {
    setOpenReportDialog(false);
  };

  const resetReportForm = () => {
    setFormData({
      contentId: "",
      reason: "",
      flaggerId: currentUserId,
      details: "",
      contentType: "",
    });
  };

  const handleReport = async () => {
    try {
      await validationReportSchema.validate(formData, { abortEarly: false });
      setFormErrors({});

      dispatch(createFlag(formData));

      setSnackbarMessage(
        "Report submitted, one of the She Is Action team will review it soon"
      );
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

      resetReportForm();

      handleCloseReportDialog();
    } catch (error) {
      console.error("An error occurred while submitting the report:", error);
      setSnackbarMessage("An error occurred while submitting the report");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      // Handle any errors, perhaps by setting an error message in state
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const contentType = formData.contentType;

  // switch between content types to change the label of the text field
  let textFieldLabel = "";
  let dialogTitle = "";
  switch (contentType) {
    case "Post":
      textFieldLabel = "What is the issue with this post?";
      dialogTitle = "Report Post";
      break;
    case "PostComment":
      textFieldLabel = "What is the issue with this comment?";
      dialogTitle = "Report Comment";
      break;
    case "Article":
      textFieldLabel = "What is the issue with this article?";
      dialogTitle = "Report Article";
      break;
    case "ArticleComment":
      textFieldLabel = "What is the issue with this comment?";
      dialogTitle = "Report Comment";
      break;
    case "User":
      textFieldLabel = "What is the issue with this user?";
      dialogTitle = "Report User";
      break;
    case "Brand":
      textFieldLabel = "What is the issue with this brand?";
      dialogTitle = "Report Brand";
      break;

    default:
      textFieldLabel = "What is the issue?";
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSoftBlockUser = async (softBlockId) => {
    await dispatch(softBlockUser(currentUserId, softBlockId));
    handleClose(); // This line will close the menu
  };

  const handleBlockUser = async (blockId) => {
    await dispatch(blockUser(currentUserId, blockId));
    handleClose(); // This line will close the menu
  };

  const handleReportUser = (userId) => {
    handleOpenReportDialog("User", userId);
    handleClose();
  };

  return {
    dispatch,
    navigate,
    currentUserId,
    isLargeScreen,
    dark,
    medium,
    primaryMain,
    primaryDark,
    neutralLight,
    neutralMedium,
    user,
    handleModeChange,
    firstName,
    lastName,
    sports,
    friends,
    picturePath,
    aboutMe,
    questionnaireAnswers,
    primaryLight,
    palette,
    handleBackClick,
    isAdmin,
    milesBetweenUsers,
    kilometersBetweenUsers,
    currentUserPicturePath,
    currentUserFirstName,
    currentUserLastName,
    isFriend,
    snackbarOpen,
    setSnackbarOpen,
    snackbarMessage,
    setSnackbarMessage,
    snackbarSeverity,
    setSnackbarSeverity,
    handlePatchFriend,
    handleClick,
    handleClose,
    handleSoftBlockUser,
    handleBlockUser,
    anchorEl,
    handleReportUser,
    openReportDialog,
    handleCloseReportDialog,
    handleReport,
    formData,
    formErrors,
    handleChange,
    textFieldLabel,
    dialogTitle,
    isNonUser,
  };
};
