import {
  setPost,
  setPosts,
  setGroupPosts,
  setUserGroupPosts,
  setMentionsPosts,
  setMentionsComments,
} from "redux/state";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const createPost =
  (userId, post, imageFile, groupId, isProfile) =>
  async (dispatch, getState) => {
    try {
      const formData = new FormData();
      formData.append("userId", userId);
      formData.append("description", post);
      if (imageFile && typeof imageFile === "string") {
        formData.append("picturePath", imageFile);
      } else {
        formData.append("picture", imageFile);
      }

      if (groupId) {
        console.log("groupId", groupId);
        formData.append("groupId", groupId);
      }

      const token = getState().token;

      const response = await fetch(`${BASE_URL}/posts`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
        body: formData,
      });

      let posts = await response.json();

      // Sort the posts by timestamp in descending order
      posts = posts.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      if (groupId) {
        dispatch(fetchGroupPosts(groupId));
      } else if (isProfile) {
        dispatch(fetchUserPosts(userId));
      } else {
        dispatch(fetchPosts(userId));
      }
      return [true, posts[0]._id]; // Indicate successful post creation
    } catch (error) {
      return false; // Indicate post creation failure
    }
  };

export const updatePost =
  (postId, updatedData) => async (dispatch, getState) => {
    try {
      const token = getState().token;

      // Construct the request headers and body
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const body = JSON.stringify(updatedData);

      const response = await fetch(`${BASE_URL}/posts/${postId}`, {
        method: "PATCH",
        headers,
        body,
      });

      if (!response.ok) {
        // Handle non-200 responses
        const errorData = await response.json();
        throw new Error(errorData.message || "Error updating post");
      }

      const updatedPost = await response.json();

      // Dispatch an action to update the post in the Redux state
      dispatch(setPost({ post: updatedPost }));

      return true; // Indicate success
    } catch (error) {
      console.error("Error while updating post:", error);
      return false; // Indicate failure
    }
  };

export const markPostAsDeleted = (postId) => async (dispatch, getState) => {
  try {
    const token = getState().token;

    // Construct the request headers and body
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const response = await fetch(`${BASE_URL}/posts/${postId}/markAsDeleted`, {
      method: "PATCH",
      headers,
    });

    if (!response.ok) {
      // Handle non-200 responses
      const errorData = await response.json();
      throw new Error(errorData.message || "Error deleting post");
    }

    const updatedPost = await response.json();

    // Dispatch an action to update the post in the Redux state
    dispatch(setPost({ post: updatedPost }));

    return true; // Indicate success
  } catch (error) {
    console.error("Error while deleting post:", error);
    return false; // Indicate failure
  }
};

export const addComment =
  (postId, loggedInUserId, comment, usersTagged) =>
  async (dispatch, getState) => {
    try {
      const token = getState().token;
      const response = await fetch(`${BASE_URL}/posts/${postId}/comment`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: loggedInUserId,
          text: comment,
          taggedUsers: usersTagged,
        }),
      });

      if (!response.ok) {
        // If the response is not OK, throw an error
        const errorData = await response.json();
        throw new Error(errorData.message || "Error while adding comment");
      }

      const updatedPost = await response.json();
      dispatch(setPost({ post: updatedPost }));
      return updatedPost.comments[updatedPost.comments.length - 1]._id;
    } catch (error) {
      console.error("Error while adding comment:", error);
      throw error; // Rethrow the error so it can be caught in the component
    }
  };

export const patchLike = (postId, userId) => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/posts/${postId}/like`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId }),
    });
    const updatedPost = await response.json();
    dispatch(setPost({ post: updatedPost }));
    // dispatch(setPost(updatedPost));
  } catch (error) {
    console.error("Error while updating like:", error);
  }
};

export const patchLikeComment =
  (postId, commentId, userId) => async (dispatch, getState) => {
    try {
      const token = getState().token;
      const response = await fetch(
        `${BASE_URL}/posts/${postId}/comment/${commentId}/like`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId }),
        }
      );
      const updatedPost = await response.json();
      dispatch(setPost({ post: updatedPost }));
    } catch (error) {
      console.error("Error while updating like for comment:", error);
    }
  };

export const fetchPost = (postId) => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/posts/${postId}`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();
  dispatch(setPost({ post: data }));
};

export const fetchPosts = (userId) => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/posts/${userId}/feed`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();
  dispatch(setPosts({ posts: data }));
};

export const fetchUserPosts = (userId) => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/posts/${userId}/posts`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();
  dispatch(setPosts({ posts: data }));
};

export const fetchGroupPosts = (groupId) => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/posts/group/${groupId}`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();
  dispatch(setGroupPosts({ groupPosts: data }));
};

export const fetchUserGroupsPosts = (id) => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/posts/group/${id}/posts`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await response.json();
  dispatch(setUserGroupPosts({ userGroupPosts: data }));
};

export const taggedUserPost =
  (userId, usersTagged, postId) => async (dispatch, getState) => {
    try {
      const taggedUsersID = usersTagged.map((user) => user._id);

      taggedUsersID.map(async (userTag) => {
        try {
          const token = getState().token;

          const postData = await fetch(
            `${BASE_URL}/users/${userTag}/mentionsPosts`,
            {
              method: "PATCH",
              headers: {
                Authorization: ` Bearer ${token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                postId: postId,
                mentionedBy: userId,
              }),
            }
          );

          // Handling potential non-200 responses from the server:
          if (!postData.ok) {
            throw new Error(`Boink! error! Status: ${postData.status}`);
          }

          const mentions = await postData.json();

          dispatch(setMentionsPosts(mentions.mentionsPosts));
          // return mentions;
        } catch (innerError) {
          console.error(`Error for userTag ${userTag}:, innerError`);
          return null; // Return null or any default value for this userTag in case of error
        }
      });

      // Optionally handle allMentions here, e.g., dispatch another action or perform other side effects.
    } catch (error) {
      console.error("Overall error:", error);
    }
  };

export const taggedUserComment =
  (userId, usersTagged, commentCreatedId) => async (dispatch, getState) => {
    try {
      const taggedUsersID = usersTagged.map((user) => user._id);

      taggedUsersID.map(async (userTag) => {
        try {
          const token = getState().token;

          const postData = await fetch(
            `${BASE_URL}/users/${userTag}/mentionsComments`,
            {
              method: "PATCH",
              headers: {
                Authorization: ` Bearer ${token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                postId: commentCreatedId,
                mentionedBy: userId,
              }),
            }
          );

          // Handling potential non-200 responses from the server:
          if (!postData.ok) {
            throw new Error(`Boink! error! Status: ${postData.status}`);
          }

          const mentions = await postData.json();

          dispatch(setMentionsComments(mentions.mentionsComments));
          // return mentions;
        } catch (innerError) {
          console.error(`Error for userTag ${userTag}:, innerError`);
          return null; // Return null or any default value for this userTag in case of error
        }
      });

      // Optionally handle allMentions here, e.g., dispatch another action or perform other side effects.
    } catch (error) {
      console.error("Overall error:", error);
    }
  };

export const fetchPostWithLikes = (postId) => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/posts/${postId}/likes`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();
  return data;
};

export const fetchPostCommentWithLikes =
  (postId, commentId) => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/posts/${postId}/comment/${commentId}/CommentLikes`,

      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();
    return data;
  };
