import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";

function BrandVerificationEdit({ brandData, setBrandData, isSelectedBrandId }) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={brandData.verified || false}
          onChange={(e) =>
            setBrandData({ ...brandData, verified: e.target.checked })
          }
          name="verified"
          color="primary"
        />
      }
      label="Verified"
      disabled={isSelectedBrandId}
    />
  );
}

export default BrandVerificationEdit;
