import { Box, Typography, useTheme } from "@mui/material";
import Friend from "components/Friend";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "redux/state";

function GroupUserListWidget({ groupId, socket }) {
  const dispatch = useDispatch();
  const [groupMembers, setGroupMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const groupMembersId = useSelector((state) => state.group.members);

  const { palette } = useTheme();

  useEffect(() => {
    if (!groupMembersId) return;

    setLoading(true);
    Promise.all(groupMembersId.map((member) => dispatch(fetchUser(member._id))))
      .then((members) => {
        setGroupMembers(members);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, [dispatch, groupMembersId]);

  if (!groupMembersId) {
    return null;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <WidgetWrapper>
      <Typography
        color={palette.neutral.dark}
        variant="h5"
        fontWeight="500"
        sx={{ mb: "1.5rem" }}
      >
        Team Sheet
      </Typography>
      <Box display="flex" flexDirection="column" gap="1.5rem">
        {groupMembers.map((member) => (
          <Friend
            key={member._id} // Using member._id as the key
            friendId={member._id}
            name={`${member.firstName} ${member.lastName}`}
            userPicturePath={member.picturePath}
            imageSize="40px"
            socket={socket}
            friend={member}
          />
        ))}
      </Box>
    </WidgetWrapper>
  );
}

export default GroupUserListWidget;
