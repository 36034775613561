import { Close } from "@mui/icons-material";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchRoles } from "redux/actions/roleActions";
import {
  assignRoleToUser,
  removeUserRole,
} from "redux/actions/userRolesActions";
import { fetchUser } from "redux/state";

function UserSideDrawer({
  userId,
  selectedUserRoles,
  isSidebarOpen,
  setIsSidebarOpen,
  setSelectedUserRoles,
  onRoleUpdated,
}) {
  const dispatch = useDispatch();

  const { palette } = useTheme();
  const backgroundAlt = palette.background.alt;
  const primaryDark = palette.primary.dark;
  const primary = palette.primary.main;

  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  const [user, setUser] = useState(null);
  const [availableRoles, setAvailableRoles] = useState([]);

  useEffect(() => {
    if (!userId) return;
    const getUser = async () => {
      const user = await dispatch(fetchUser(userId));
      setUser(user);
    };
    getUser();
  }, [dispatch, userId]);

  useEffect(() => {
    const getRoles = async () => {
      const roles = await dispatch(fetchRoles());
      setAvailableRoles(roles);
    };
    getRoles();
  }, [dispatch]);

  return (
    <Drawer
      anchor="right"
      open={isSidebarOpen}
      onClose={() => setIsSidebarOpen(false)}
      sx={{
        width: isLargeScreen ? "350px" : "100%",
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: "50%",
          boxSizing: "border-box",
          background: backgroundAlt,
          //   color: primaryLight,
          borderRight: `1px solid ${primaryDark}`,
        },
      }}
    >
      <Box width="100%">
        <Box
          margin="1.5rem 2rem 1rem 3rem"
          style={{ margin: "1.5rem 2rem 1rem 3rem" }}
        >
          <FlexBetween>
            <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
              <Close />
            </IconButton>
            <Box display="flex" alignItems="center" gap="0.5rem">
              <Typography variant="h3" fontWeight="bold">
                Update User -{" "}
                <span style={{ color: primary }}>
                  {" "}
                  {user?.firstName} {user?.lastName}{" "}
                </span>
              </Typography>
            </Box>
          </FlexBetween>
        </Box>
        <Divider />
        <Box
          margin="0 1rem"
          style={{
            margin: "1rem",
            border: `1px solid ${primaryDark}`,
            padding: "1rem",
            borderRadius: "5px",
          }}
        >
          <Typography variant="h5" color={primary}>
            User Roles:{" "}
            <select
              defaultValue=""
              onChange={async (e) => {
                if (e.target.value) {
                  await dispatch(assignRoleToUser(userId, e.target.value));
                  setSelectedUserRoles([
                    ...selectedUserRoles,
                    availableRoles.find((role) => role._id === e.target.value),
                  ]);
                }
              }}
            >
              <option value="" disabled>
                Add Role
              </option>
              {availableRoles.map((role) => (
                <option key={role._id} value={role._id}>
                  {role.name}
                </option>
              ))}
            </select>
          </Typography>
          {selectedUserRoles && Array.isArray(selectedUserRoles)
            ? selectedUserRoles.map((role) => (
                <Box key={role._id} display="flex" alignItems="center">
                  <Typography>{role.name}</Typography>
                  <IconButton
                    onClick={async () => {
                      await dispatch(removeUserRole(userId, role._id));
                      setSelectedUserRoles(
                        selectedUserRoles.filter(
                          (selectedRole) => selectedRole._id !== role._id
                        )
                      );
                    }}
                  >
                    <Close />
                  </IconButton>
                </Box>
              ))
            : null}
        </Box>
      </Box>
    </Drawer>
  );
}

export default UserSideDrawer;
