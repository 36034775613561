import {
  setLogin,
  activateAccountSuccess,
  activateAccountFail,
} from "redux/state";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const registerUser = (values) => async (dispatch) => {
  try {
    const formData = new FormData();
    for (let key in values) {
      if (key === "location") {
        // Stringify the location object before appending
        formData.append(key, JSON.stringify(values[key]));
      } else if (key === "picture") {
        // Append the picture as a file
        formData.append("picturePath", values.picture);
      } else if (key === "businessForm") {
        // Stringify the businessForm object before appending
        formData.append(key, JSON.stringify(values[key]));
      } else {
        // Append other fields as they are
        formData.append(key, values[key]);
      }
    }

    const savedUserResponse = await fetch(`${BASE_URL}/auth/register`, {
      method: "POST",
      body: formData,
    });
    const savedUser = await savedUserResponse.json();

    return savedUser;
  } catch (error) {
    // Handle any error that might occur during the registration process
    console.error("Error while registering user:", error);
    return null;
  }
};

export const activateAccount = (token) => async (dispatch) => {
  try {
    const response = await fetch(`${BASE_URL}/auth/activate`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token }),
    });
    const responseData = await response.json();
    if (response.ok) {
      dispatch(activateAccountSuccess(responseData.message));
    } else {
      throw new Error(responseData.error);
    }
  } catch (err) {
    const errorMessage = err.message || "Something went wrong.";
    dispatch(activateAccountFail(errorMessage));
  }
};

export const resendActivationLink = (email) => async () => {
  try {
    const response = await fetch(`${BASE_URL}/auth/resend-activation`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email }),
    });
    const responseData = await response.json();
    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData.error);
    }
  } catch (err) {
    console.error("Error while resending activation link:", err);
    return null;
  }
};

export const loginUser = (values) => async (dispatch) => {
  try {
    const loggedInResponse = await fetch(`${BASE_URL}/auth/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    });
    const loggedIn = await loggedInResponse.json();

    if (loggedIn) {
      dispatch(
        setLogin({
          user: loggedIn.user,
          token: loggedIn.token,
        })
      );
    }

    return loggedIn;
  } catch (error) {
    // Handle any error that might occur during the login process
    console.error("Error while logging in:", error);
    return null;
  }
};

export const submitQuestions = (userId, answers) => async () => {
  try {
    const response = await fetch(`${BASE_URL}/auth/update-questionnaire`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ userId, answers }),
    });
    const responseData = await response.json();
    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData.error);
    }
  } catch (err) {
    console.error("Error while submitting questions:", err);
    return null;
  }
};

export const submitSports = (userId, sports) => async () => {
  try {
    const response = await fetch(`${BASE_URL}/auth/update-sports`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ userId, sports }),
    });
    const responseData = await response.json();
    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData.error);
    }
  } catch (err) {
    console.error("Error while submitting sports:", err);
    return null;
  }
};
