// actions/brandLocationActions.js

import { setBrandLocation, setBrandLocations } from "redux/state"; // Assuming you have these actions in your redux state

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const fetchLocations = (brandId) => async (dispatch, getState) => {
  const token = getState().token;
  const response = await fetch(`${BASE_URL}/brandLocations/${brandId}`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();
  dispatch(setBrandLocations(data));
  return data;
};

export const fetchLocation =
  (brandId, locationId) => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/brandLocations/${brandId}/locations/${locationId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();
    dispatch(setBrandLocation(data));
    return data;
  };

export const addLocation =
  (brandId, locationData) => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(`${BASE_URL}/brandLocations/${brandId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(locationData),
    });
    const data = await response.json();
    // Dispatch action to update redux state if needed
    return data;
  };

export const updateLocation =
  (brandId, locationId, updatedLocationData) => async (dispatch, getState) => {
    const token = getState().token;
    const response = await fetch(
      `${BASE_URL}/brandLocations/${brandId}/${locationId}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedLocationData),
      }
    );
    const data = await response.json();
    // Dispatch action to update redux state if needed
    return data;
  };

export const deleteLocation =
  (brandId, locationId) => async (dispatch, getState) => {
    const token = getState().token;
    await fetch(
      `${BASE_URL}/brandLocations/${brandId}/locations/${locationId}`,
      {
        method: "DELETE",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    // Dispatch action to remove the location from redux state if needed
  };
