import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import moment from "moment";
import DOMPurify from "dompurify";
import { useDispatch, useSelector } from "react-redux";
import { Verified } from "@mui/icons-material";
import { fetchBrand } from "redux/actions/brandActions";

function ArticlePreview({
  title,
  subtitle,
  contentBlocks,
  titleImage,
  firstName,
  lastName,
  published_at,
  tags,
  selectedBrandId,
}) {
  const dispatch = useDispatch();

  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  const { palette } = useTheme();
  const primary = palette.primary.main;
  const secondaryDark = palette.secondary.dark;
  const backgroundAlt = palette.background.alt;

  const user = useSelector((state) => state.user) || {};
  const brand = useSelector((state) => state.brand) || {};
  const isTitleImage = titleImage ? true : false;

  // is the user following brand?
  const isFollowingBrand =
    user && user.brandFollows && user.brandFollows.includes(selectedBrandId);

  const [mobileView, setMobileView] = useState(false);

  // Function to replace consecutive '<p><br></p>' tags
  const replaceConsecutiveBreaks = (match) => {
    const count = match.split("<p><br></p>").length - 1;
    return "<br>".repeat(Math.max(count - 1, 0));
  };

  const sanitizeContent = (htmlContent) => {
    const updatedContent = DOMPurify.sanitize(htmlContent).replace(
      /(<p><br><\/p>)+/g,
      replaceConsecutiveBreaks
    );

    return {
      __html: updatedContent,
    };
  };

  useEffect(() => {
    if (selectedBrandId) dispatch(fetchBrand(selectedBrandId));
  }, [dispatch, selectedBrandId]);

  const textStyle = {
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)", // Example shadow
  };

  const containerStyle = {
    position: "relative",
    borderRadius: !mobileView ? "10px" : "0",
    overflow: "hidden", // Ensures the overlay is contained within the border radius
  };

  const imageStyle = {
    height: !mobileView ? undefined : "600px",
    width: "100%",
    objectFit: "cover",
    borderRadius: !mobileView ? "10px" : "0",
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.2)", // Black with 20% opacity
    pointerEvents: "none", // Ensures the overlay doesn't interfere with user interaction
  };

  return (
    <WidgetWrapper
      sx={{ margin: "1rem auto" }}
      width={isLargeScreen ? "60%" : "100%"}
    >
      <div>
        <label>
          Mobile view
          <Switch
            checked={mobileView}
            onChange={() => setMobileView(!mobileView)}
          />
        </label>
      </div>

      {!mobileView ? (
        <Box>
          <Box mb={2}>
            {tags &&
              tags.length > 0 &&
              tags.map((tag, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "inline-block",
                    backgroundColor: primary,
                    p: "0.25rem 1rem",
                    borderRadius: "1rem",
                    mt: 1,
                    mr: 1, // added margin-right for spacing between boxes
                  }}
                >
                  <Typography variant="h6" color="white" fontSize={14}>
                    {tag}
                  </Typography>
                </Box>
              ))}
          </Box>

          <Typography variant="h1" fontWeight="bold">
            {title}
          </Typography>

          <div style={containerStyle}>
            <img src={titleImage} alt={title} style={imageStyle} />
          </div>

          <FlexBetween>
            <Typography fontWeight="normal" variant="body2" color={primary}>
              "Published: "{moment(published_at).format("MMM Do HH:MM")}
            </Typography>
            <Typography variant="body2" color={primary}>
              By: {firstName} {lastName}
            </Typography>
          </FlexBetween>

          <Typography variant="h5" gutterBottom mt={1}>
            {subtitle}
          </Typography>
          {contentBlocks.map((block, index) => (
            <Box key={index} marginY={2}>
              {block.type === "text" ? (
                <div dangerouslySetInnerHTML={sanitizeContent(block.data)} />
              ) : (
                <img
                  src={block.data}
                  alt="Article content"
                  style={{ width: "100%", borderRadius: "10px" }}
                />
              )}
            </Box>
          ))}
        </Box>
      ) : (
        <>
          <Box position="relative">
            <div style={containerStyle}>
              <img src={titleImage} alt={title} style={imageStyle} />
              <div style={overlayStyle}></div>
            </div>
            {isTitleImage && (
              <Box m="0 1rem" position="absolute" bottom="50px">
                <Box mb={2}>
                  {tags &&
                    tags.length > 0 &&
                    tags.map((tag, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "inline-block",
                          backgroundColor: primary,
                          p: "0.25rem 1rem",
                          borderRadius: "1rem",
                          mt: 1,
                          mr: 1, // added margin-right for spacing between boxes
                        }}
                      >
                        <Typography variant="h6" color="white" fontSize={14}>
                          {tag}
                        </Typography>
                      </Box>
                    ))}
                </Box>
                <Typography
                  variant="h1"
                  fontWeight="bold"
                  style={textStyle}
                  color="white"
                >
                  {title}
                </Typography>
                <Typography
                  fontWeight="bold"
                  variant="h5"
                  color="white"
                  style={textStyle}
                >
                  {moment(published_at).format("MMM Do HH:MM")}
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  gap="1rem"
                  mt="1rem"
                  mb="2rem"
                >
                  <img
                    src={brand.logoPath}
                    alt={brand.name}
                    style={{ width: "30px" }}
                  />
                  <Typography
                    variant="p"
                    color="white"
                    fontWeight="500"
                    fontSize={14}
                    style={textStyle}
                  >
                    {brand.name}
                  </Typography>

                  <Tooltip
                    title="She is Action - Verified"
                    placement="top-start"
                    arrow
                  >
                    <Verified
                      fontSize="large"
                      sx={{ color: primary, textStyle }}
                    />
                  </Tooltip>
                  <Typography
                    variant="p"
                    color="white"
                    fontWeight="500"
                    fontSize={14}
                    style={textStyle}
                  >
                    {brand.followers?.length} follower
                    {brand.followers?.length === 1 ? undefined : "s"}
                  </Typography>
                  {/* Following Brand? */}

                  <Tooltip
                    title={
                      isFollowingBrand
                        ? `Unfollow ${brand.name}`
                        : `Follow ${brand.name}`
                    }
                    placement="top"
                    arrow
                  >
                    <Button
                      variant={isFollowingBrand ? "outlined" : "contained"}
                      fontWeight="500"
                      fontSize={14}
                      sx={{
                        color: "white",
                        borderColor: "white",
                        borderRadius: "1rem",
                        textShadow: isFollowingBrand
                          ? "2px 2px 4px rgba(0, 0, 0, 1)"
                          : "none",
                        padding: "0.25rem 1rem",
                        "&:hover": {
                          color: secondaryDark,
                          borderColor: secondaryDark,
                        },
                      }}
                    >
                      {isFollowingBrand ? "Following" : "Follow"}
                    </Button>
                  </Tooltip>
                </Box>
              </Box>
            )}
          </Box>
          <Box
            mt="-2rem"
            p="1rem"
            position="relative"
            borderRadius="2rem 2rem 0 0"
            backgroundColor={backgroundAlt}
          >
            <Typography variant="h5" gutterBottom mt={1}>
              {subtitle}
            </Typography>
            {contentBlocks &&
              contentBlocks.map((block, index) => (
                <Box key={index} marginY={2}>
                  {block.type === "text" ? (
                    <div
                      dangerouslySetInnerHTML={sanitizeContent(block.data)}
                    />
                  ) : (
                    <img
                      src={block.data}
                      alt="Article content"
                      style={{ width: "100%", borderRadius: "10px" }}
                    />
                  )}
                </Box>
              ))}
            <Typography variant="body2" color={primary}>
              By: {firstName} {lastName}
            </Typography>
          </Box>
        </>
      )}
    </WidgetWrapper>
  );
}

export default ArticlePreview;
