import {
  AdminPanelSettingsOutlined,
  ChevronLeft,
  ChevronRightOutlined,
  Diversity1Outlined,
  FolderOpenOutlined,
  Groups2Outlined,
  HomeOutlined,
  InventoryOutlined,
  PublicOutlined,
  SportsHandballOutlined,
  FeaturedVideoOutlined,
  ReportProblemOutlined,
  Recommend,
} from "@mui/icons-material";
import {
  Box,
  ClickAwayListener,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const navItems = [
  { text: "Dashboard", icon: <HomeOutlined /> },
  { text: "All Users", icon: null },
  { text: "Users", icon: <Groups2Outlined /> },
  { text: "Roles", icon: <AdminPanelSettingsOutlined /> },
  { text: "Groups", icon: <Diversity1Outlined /> },
  { text: "Locations", icon: <PublicOutlined /> },
  { text: "Flagged", icon: <ReportProblemOutlined /> },
  { text: "Recommended", icon: <Recommend /> },
  { text: "Available Sports", icon: null },
  { text: "Sports", icon: <SportsHandballOutlined /> },
  { text: "Brands", icon: null },
  { text: "Directory", icon: <FolderOpenOutlined /> },
  { text: "Leads", icon: <InventoryOutlined /> },
  { text: "Adverts and Sponsors", icon: null },
  { text: "Sponsors", icon: <FeaturedVideoOutlined /> },
];

function Sidebar({
  drawerWidth,
  isSideBarOpen,
  setIsSideBarOpen,
  isLargeScreen,
}) {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();

  const { palette } = useTheme();
  const primary = palette.primary.main;
  const backgroundColor = palette.background.alt;
  const dark = palette.neutral.dark;

  useEffect(() => {
    const lastPath = pathname.split("/").pop();
    setActive(lastPath);
  }, [pathname]);

  const drawerContent = (
    <Box component="nav">
      {isSideBarOpen && (
        <Drawer
          open={isSideBarOpen}
          onClose={() => setIsSideBarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: primary,
              backgroundColor: backgroundColor,
              boxSizing: "border-box",
              borderWidth: isLargeScreen ? 0 : "2px",
              width: drawerWidth,
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 2rem 2rem 3rem">
              <FlexBetween color={primary}>
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <Typography variant="h5" fontWeight="bold">
                    ADMIN PORTAL
                  </Typography>
                </Box>
                {!isLargeScreen && (
                  <IconButton onClick={() => setIsSideBarOpen(!isSideBarOpen)}>
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            <List>
              {navItems.map(({ text, icon }) => {
                if (!icon) {
                  return (
                    <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                      {text}
                    </Typography>
                  );
                }

                const lcText = text.toLowerCase();
                return (
                  <ListItem key={text} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        navigate(`${lcText}`);
                        setActive(lcText);
                      }}
                      sx={{
                        backgroundColor:
                          active === lcText ? primary : "transparent",
                        color: active === lcText ? backgroundColor : dark,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ml: "2rem",
                          color: active === lcText ? backgroundColor : dark,
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                      {active === lcText && (
                        <ChevronRightOutlined sx={{ ml: "auto" }} />
                      )}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Drawer>
      )}
    </Box>
  );

  return !isLargeScreen && isSideBarOpen ? (
    <ClickAwayListener onClickAway={() => setIsSideBarOpen(false)}>
      {drawerContent}
    </ClickAwayListener>
  ) : (
    drawerContent
  );
}

export default Sidebar;
