import { Button, Typography } from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import React from "react";
import { Link } from "react-router-dom";

function GroupAdminUpdateGroupBtn({ groupId }) {
  return (
    <WidgetWrapper>
      <Typography variant="h5">Update Group</Typography>
      <Link to={`/groups/${groupId}/edit`}>
        <Button
          variant="contained"
          color="secondary"
          sx={{ width: "100%", padding: "0.5rem 0", marginTop: "1rem" }}
        >
          Update Group
        </Button>
      </Link>
    </WidgetWrapper>
  );
}

export default GroupAdminUpdateGroupBtn;
