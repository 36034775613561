import React, { useEffect } from "react";
import { Box, useMediaQuery } from "@mui/material";
import Navbar from "scenes/navbar";
import { useDispatch, useSelector } from "react-redux";
import GroupListWidget from "scenes/groups/Groupshome/GroupListWidget";
import { Outlet } from "react-router-dom";
import { getGroupsByParams, getJoinedGroups } from "redux/actions/groupActions";
import Footer from "components/Footer";
import BottomMenu from "components/bottomMenu/BottomMenu";

// Groups Component: Represents the Groups page in the application
function Groups({ socket }) {
  const isLargeScreen = useMediaQuery("(min-width:1000px)");
  const dispatch = useDispatch();
  const joinedGroups = useSelector((state) => state.user.groupsJoined); // Get the joined groups of the user from the state

  useEffect(() => {
    if (joinedGroups) {
      dispatch(getJoinedGroups(joinedGroups));
    }
  }, [dispatch, joinedGroups]);

  useEffect(() => {
    const params = {
      groupIds: joinedGroups,
    };
    dispatch(getGroupsByParams(params));
  }, [dispatch, joinedGroups]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        minHeight="100vh"
        marginBottom="1rem"
      >
        <Navbar socket={socket} />
        <Box
          flexGrow={1}
          overflow="auto"
          width="100%"
          padding={isLargeScreen ? "0 6%" : "0"}
          display={isLargeScreen ? "flex" : "block"}
          gap="2rem"
          justifyContent="center"
        >
          <Box flexBasis={isLargeScreen ? "26%" : undefined}>
            <GroupListWidget joinedGroups={joinedGroups} socket={socket} />
          </Box>
          <Box
            flexBasis={isLargeScreen ? "60%" : undefined}
            mt={isLargeScreen ? undefined : "2rem"}
          >
            <Outlet socket={socket} />
          </Box>
        </Box>
        <Footer />
      </Box>
      {!isLargeScreen && <BottomMenu />}
    </>
  );
}

export default Groups;
