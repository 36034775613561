import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  acceptInvitation,
  declineInvitation,
  getGroupsByParams,
} from "redux/actions/groupActions";
import { patchUser } from "redux/state";

function toProperCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function GroupsInvited({ socket }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user); // get the user from the state
  const userId = user._id; // get the userId from the user
  const groupsInvited = useSelector((state) => state.user.groupsInvited); // get the invitedGroups from the state

  const { palette } = useTheme();
  const main = palette.primary.main;
  const mainNeutral = palette.neutral.main;
  const light = palette.primary.light;

  const navigate = useNavigate();

  const isLargeScreen = useMediaQuery("(min-width:1000px)");

  useEffect(() => {
    const fetchGroups = async () => {
      const params = {
        invitations: user._id,
      };
      await dispatch(getGroupsByParams(params));
    };
    fetchGroups();
  }, [dispatch, user._id]);

  const handleAccept = async (groupId, userId) => {
    try {
      const result = await dispatch(acceptInvitation(groupId, userId));

      if (result.success) {
        const updatedUser = {
          ...user,
          groupsJoined: user.groupsJoined.concat(groupId),
        };
        await dispatch(patchUser(userId, updatedUser));
        const params = {
          invitations: user._id,
        };
        await dispatch(getGroupsByParams(params));

        // Fetch the group from groupsInvited
        const groupInfo = groupsInvited.find((group) => group._id === groupId);

        groupInfo.members.forEach((memberId) => {
          const notificationData = {
            senderId: user._id,
            receiverId: memberId,
            name: user.firstName + " " + user.lastName,
            groupId: groupInfo._id,
            groupName: groupInfo.name,
            picturePath: groupInfo.image,
            timeStamp: Date.now(),
            type: "acceptedInviteToGroup",
          };

          socket.emit("sendNotification", notificationData);
        });
      } else {
        console.log("Error accepting invitation");
      }
    } catch (error) {
      console.error("An error occurred while accepting the invitation:", error);
    }
  };

  const handleDecline = async (groupId, userId) => {
    try {
      const result = await dispatch(declineInvitation(groupId, userId));

      if (result.success) {
        const params = {
          invitations: user._id,
        };
        await dispatch(getGroupsByParams(params));

        const groupInfo = groupsInvited.find((group) => group._id === groupId);

        groupInfo.members.forEach((memberId) => {
          const notificationData = {
            senderId: user._id,
            receiverId: memberId,
            name: user.firstName + " " + user.lastName,
            groupId: groupInfo._id,
            groupName: groupInfo.name,
            picturePath: groupInfo.image,
            timeStamp: Date.now(),
            type: "declinedInvitationGroupMembers",
          };

          socket.emit("sendNotification", notificationData);
        });
      } else {
        console.log("Error declining invitation");
      }
    } catch (error) {
      console.error("An error occurred while declining the invitation:", error);
    }
  };

  const InfoBox = ({ icon: Icon, children }) => (
    <Box
      gap="0.5rem"
      sx={{ display: "flex", alignItems: "center", color: mainNeutral }}
    >
      {Icon && <Icon />}
      {children}
    </Box>
  );

  const Divider = () => (
    <Box
      width="100%"
      height="1px"
      backgroundColor={light}
      opacity="0.1"
      margin="1rem 0"
    />
  );
  // if groupsInvited is null, return null
  if (!groupsInvited) {
    return null;
  }
  return (
    groupsInvited.length > 0 && (
      <Box>
        <Typography
          variant="h5"
          margin="1rem"
          sx={{ fontWeight: 600, color: main }}
        >
          Your Group Invitations
        </Typography>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: isLargeScreen ? "space-between" : "center",
          }}
        >
          {groupsInvited.map((group, index) => {
            const { _id, name, members, isPrivate, isVisible, image } = group;

            const privacy = isPrivate ? "private" : "public";
            const visibility = isVisible ? "visible" : "hidden";

            return (
              <WidgetWrapper
                key={index}
                style={{
                  flexBasis: isLargeScreen ? "48%" : "100%", // or use calc()
                  marginBottom: "20px",
                }}
              >
                <FlexBetween>
                  <Box
                    width="100%"
                    position="relative"
                    style={{
                      height: "120px",
                      overflow: "hidden",
                      borderRadius: "0.5rem",
                    }}
                    flex={1}
                  >
                    <img
                      src={image ? image : "../assets/groupShot.png"}
                      alt="Group Shot of sports women"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                  <Box flex={4} sx={{ padding: "0 5px", marginLeft: "5px" }}>
                    <InfoBox sx={{ color: main }}>
                      <Typography
                        variant="h6"
                        sx={{ color: main }}
                        marginBottom="0.5rem"
                      >
                        {name.length > 0 ? name : "Group Name"}
                      </Typography>
                    </InfoBox>

                    {/* <InfoBox icon={Icon}> */}
                    <InfoBox>
                      {`${toProperCase(privacy)} (${visibility}) group`}
                    </InfoBox>
                    <Typography sx={{ color: main }}>
                      {`${members.length} member${
                        members.length === 1 ? "" : "s"
                      }`}
                    </Typography>
                    <Box mt={2}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleAccept(_id, userId)}
                        style={{ marginLeft: "10px" }}
                      >
                        Accept
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleDecline(_id, userId)}
                        style={{ marginLeft: "10px" }}
                      >
                        Decline
                      </Button>
                    </Box>
                  </Box>
                </FlexBetween>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ width: "100%", padding: "0.5rem 0", marginTop: "1rem" }}
                  // navigate to group page
                  onClick={() => {
                    navigate(`/groups/${_id}`);
                  }}
                >
                  View Group
                </Button>
              </WidgetWrapper>
            );
          })}
        </div>
        {/* Divider */}
        <Divider />
      </Box>
    )
  );
}

export default GroupsInvited;
