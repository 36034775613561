import {
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";

function SportsCard({ sport }) {
  const { palette } = useTheme();

  const background = palette.background.default;
  const primaryDark = palette.primary.dark;

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Card
      key={sport._id}
      sx={{
        backgroundImage: "none",
        backgroundColor: background,
        borderRadius: "0.55rem",
      }}
    >
      <CardContent>
        <Typography variant="h5" sx={{ color: primaryDark, fontWeight: "700" }}>
          {sport.name}
        </Typography>
        <Typography variant="body1">{sport.description}</Typography>
      </CardContent>

      <CardActions width="100%">
        <Button
          varient="primary"
          size="small"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          SEE MORE
        </Button>
      </CardActions>

      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography variant="body2">Category: {sport.category}</Typography>
          <Typography variant="body2">
            Popularity: {sport.popularity}
          </Typography>
          <Typography variant="body2">Team Size: {sport.teamSize}</Typography>
          <Typography variant="body2">
            Equipment: {sport.equipment.join(", ")}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default SportsCard;
